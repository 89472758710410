import { useState } from 'react'

export type FiltersType = {
  companySize: string
  jobTitles: string[]
  jobTitleRoles: string[]
  jobTitleSubroles: string[]
  jobTitleLevels: string[]
  industries: string[]
  countries: string[]
}

type ResponseType = { scroll_token: string; total: number; error?: string }

export type PeopleType = {
  id: string
  email: string
  data: { [key: string]: any }[]
  filters?: FiltersType
} & ResponseType

export const useSimilarPeople = () => {
  const [similar, setSimilar] = useState<PeopleType | null>(null)
  const [similarWidget, setSimilarWidget] = useState<PeopleType | null>(null)

  const getPersonDetails = (name: string, email?: string) => {
    return [...(similar?.data ? similar.data : []), ...(similarWidget?.data ? similarWidget.data : [])].find(
      (item: { [key: string]: any }) => item.full_name === name || (email && item.work_email === email)
    )
  }

  return {
    similar,
    similarWidget,
    setSimilar,
    setSimilarWidget,
    getPersonDetails
  }
}
