import React, { useEffect } from 'react'

import Repeater from '_core/components/lists/Repeater'
import PhoneNumber from '_core/components/PhoneNumber'
import TitleDescription from '_core/components/TitleDescription'

import { dateToStringWithTodayComparing } from 'utils/Utils'

type InfoPhonesProps = {
  loading: boolean
  total: number
  items: { [key: string]: any }[]
  person: { name: string; id: string }
  setTotalNumbers: (val: number) => void
  style: { [key: string]: any }
}

const InfoPhones = ({ loading, setTotalNumbers, person, items, ...props }: InfoPhonesProps) => {
  useEffect(() => {
    if (typeof props.total === 'number') {
      setTotalNumbers(props.total)
    }
  }, [setTotalNumbers, props.total])

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 4, loading }}
      addprops={{ style: { paddingLeft: 5, ...props.style } }}
      items={items?.map((phone: any) => ({
        title: '',
        description: <PhoneNumber phoneType={phone.PhoneType} number={phone.StandardizedPhoneNumber} person={person} style={{ paddingBottom: 8 }} />,
        blurb: phone.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(phone.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No phone numbers were found"
    />
  )
}

export default InfoPhones
