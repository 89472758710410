import { useContext, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const usePrivilegedPage = () => {
  const [total, setTotal] = useState<number>()
  const [reload, setReload] = useState(false)
  const { payloads } = useSidepanelPayloads()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const { rowsPerPage = '20', keyword } = queryParams
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Restricted companies')
  }, [setMobileHeader])

  useEffect(() => {
    if (!search) {
      updateQuery({ rowsPerPage })
    }
  }, [search, rowsPerPage])

  useEffect(() => {
    if (payloads && payloads.action === 'RELOAD_LIST' && payloads.value === 'privileged-companies') {
      setReload(true)
    }
  }, [payloads])

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const handleReload = () => setReload(true)

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    result && setTotal(result.total_item_count)
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage })
  }

  return {
    keyword,
    rowsPerPage,
    loading: typeof total !== 'number',
    reload,
    onLoading,
    onPageSizeChange,
    handleReload
  }
}

export default usePrivilegedPage
