import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider } from '@mui/material'
import moment, { Moment as MomentType } from 'moment'
import { useHistory } from 'react-router-dom'

import Avatar from '_shared/Avatar'
import { Button, IconButton } from '_shared/buttons'
import { CardContent } from '_shared/Card'
import Typography from '_shared/Typography'

import ContactPickerDialog from '_core/components/dialogs/ContactPicker'
import { PersonOptionType } from '_core/components/dialogs/CreatePersonOptionDialog'
import IntroducerPickerDialog from '_core/components/dialogs/IntroducerPicker'
import { withoutIntroducerStatuses } from '_core/components/dialogs/IntroductionActivity'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useDialog from '_core/hooks/useDialog'
import { useStyles } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { uuid } from 'utils/demoUtils'
import { del, post } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type Person = Pick<PersonOptionType, 'name' | 'email'>

type AddParticipantsProps = {
  loading: boolean
  request: IntroductionRequestResp
  statusOptions: { id: string; label: string; value: string }[]
}

type Introduction = {
  id: string
  contact: Person
  introducer: Person
  memo: string
  status: string
  eventTime: MomentType | null
}

const defaultStatus = 'IntroductionSuccess'

const createIntroduction = () => [
  {
    id: uuid(),
    contact: {
      name: '',
      email: ''
    },
    introducer: {
      name: '',
      email: ''
    },
    status: defaultStatus,
    memo: '',
    eventTime: null
  }
]

const AddParticipantsForm = ({ request, statusOptions, ...props }: AddParticipantsProps) => {
  const [introductions, setIntroductions] = useState<Introduction[]>(createIntroduction)
  const [loading, setLoading] = useState(false)
  const [activeId, setActiveId] = useState<string>('')
  const handleClose = useSidepanelClose(Paths._introductions)
  const history = useHistory()
  const { updateParent } = useSidepanelPayloads()
  const plan = request?.plan || {}
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { dialogContentProps: openedDialog, closeDialog, openDialog, successMode } = useDialog<'introducerPicker' | 'contactPicker' | null>(null)
  const { fetchWithAbort } = useAbortableFetch<{ users: { [key: string]: any }[] }[]>()
  const { classes } = useStyles()

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  useEffect(() => {
    setSubHeader('Pick contact/introducer pairs')
  }, [setSubHeader])

  const restoreIntroductions = async (urls: { url: string }[]) => {
    setLoading(true)
    const resp = await fetchWithAbort(urls)
    const introducersDetails = resp?.flatMap(({ users }) => users)
    if (introducersDetails && request) {
      const data = request.contacts.map((contact) => {
        const event = request.contactEvents.find((event) => event.contactEmail === contact.contactEmail)
        const introducer = introducersDetails.find(
          (user) => user.emailAddress.toLowerCase() === contact.selectedIntroducer?.introducerEmail?.toLowerCase()
        )
        return {
          id: contact.contactMd5,
          contact: {
            name: contact.displayAs,
            email: contact.contactEmail
          },
          introducer: {
            name: introducer?.displayName,
            email: introducer?.emailAddress
          },
          status: contact.targetingStatus,
          memo: event ? event.eventMemo : '',
          eventTime: event ? moment(event.eventTime) : null
        }
      })
      setIntroductions(data)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (request?.contacts?.length) {
      const introducersEmails: string[] = request.contacts.map((contact) => contact.selectedIntroducer?.introducerEmail)
      const urls = [...new Set(introducersEmails)].map((email: string) => ({ url: `/teams/principals?searchTerm=${email}` }))
      restoreIntroductions(urls)
    }
  }, [request?.contacts?.length])

  const addIntroduction = () => {
    const [introduction] = createIntroduction()
    setIntroductions((prevState) => [...prevState, introduction])
  }

  const contactsEmails = introductions.map((item) => item.contact.email).filter((email) => !!email)
  const introducersEmails = introductions.map((item) => item.introducer.email).filter((email) => !!email)
  const load = props.loading || loading

  const save = async () => {
    setLoading(true)
    if (request?.contacts?.length) {
      const persistedContacts: string[] = request.contacts.map((contact) => contact.contactEmail)
      const currentContacts = introductions.map((introduction) => introduction.contact.email)

      const shouldBeRemoved = persistedContacts
        .filter((email) => !currentContacts.includes(email))
        .map((email) => ({
          planUid: plan.planUid,
          identifier: email
        }))

      if (shouldBeRemoved.length) {
        await Promise.all(shouldBeRemoved.map((options) => del('/prospecting/targetcontact', options)))
      }
    }

    const contactsData: { contactName: string; contactEmail: string }[] = []
    const introductionData: {
      planUid: string
      introducerEmail: string
      contactIdentifier: string
      eventMemo: string
      status: string
      eventTime?: MomentType
      reachedOut?: MomentType
      fullyConnected?: MomentType
    }[] = []
    const date = getLocal()
    introductions.forEach(({ contact, introducer, memo, status, eventTime }) => {
      if (contact.email && introducer.email) {
        contactsData.push({
          contactName: contact.name,
          contactEmail: contact.email
        })

        introductionData.push({
          planUid: plan.planUid,
          introducerEmail: introducer.email,
          contactIdentifier: contact.email,
          eventMemo: memo,
          status,
          ...(eventTime ? { eventTime } : {}),
          ...(status === 'IntroducerReachedOut' ? { reachedOut: date } : {}),
          ...(status === 'IntroductionSuccess' ? { fullyConnected: date } : {})
        })
      }
    })

    await post<IntroductionContactResp[]>('/prospecting/targetedcontacts', {
      planUid: plan.planUid,
      requests: contactsData
    })
    await post<IntroEventResp[]>('/prospecting/withintroducer', introductionData)
    setLoading(false)
    updateParent({ action: 'RELOAD_LIST', value: 'introductions' })
    history.push(`${Paths._introductions}/${plan.planUid}/outcome`)
  }

  const handleOpenModal = (introductionId: string, modalType: 'contactPicker' | 'introducerPicker') => {
    setActiveId(introductionId)
    openDialog(modalType)
  }

  const handleCloseModal = () => {
    setActiveId('')
    closeDialog()
  }

  const removeIntroduction = (removalId: string) => {
    setIntroductions((prevState) => prevState.filter(({ id }) => id !== removalId))
  }

  const activeIntroduction = introductions.find(({ id }) => id === activeId)

  const handleContactChange = (contact: Person | null, id: string) => {
    const updated = introductions.map((introduction) => {
      if (introduction.id === id) {
        return {
          ...introduction,
          contact: {
            name: contact?.name || '',
            email: contact?.email || ''
          }
        }
      } else {
        return introduction
      }
    })
    setIntroductions(updated)
  }

  const handleIntroducerChange = (introducer: Person | null, status: string, eventTime: MomentType | null, memo: string, id: string) => {
    const updated = introductions.map((introduction) => {
      if (introduction.id === id) {
        return {
          ...introduction,
          introducer: {
            name: introducer?.name || '',
            email: introducer?.email || ''
          },
          status,
          eventTime,
          memo
        }
      } else {
        return introduction
      }
    })
    setIntroductions(updated)
  }

  const status = statusOptions?.find((status) => status.value === defaultStatus)
  const options = statusOptions?.filter((option) => !withoutIntroducerStatuses.includes(option.value))

  return (
    <>
      <Widget scope="card" className={classes.wrapper}>
        <Repeater
          variant="card"
          empty=" "
          component={CardItem}
          skeleton={{ size: 1, loading: load }}
          items={introductions.map(({ contact, introducer, id, memo }) => ({
            memo,
            contactName: contact.name,
            contactEmail: contact.email,
            introducerName: introducer.name,
            introducerEmail: introducer.email,
            handleRemove: () => removeIntroduction(id),
            openContactPicker: () => handleOpenModal(id, 'contactPicker'),
            openIntroducerPicker: () => handleOpenModal(id, 'introducerPicker')
          }))}
        />
        <Button
          onClick={addIntroduction}
          variant="link"
          endIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 10 }} />}
          disabled={load}
          disablePL
          className={classes.card}
        >
          Add introduction
        </Button>
      </Widget>
      {activeIntroduction && (
        <IntroducerPickerDialog
          planUid={plan.planUid}
          isOpened={openedDialog === 'introducerPicker'}
          close={handleCloseModal}
          successMode={successMode}
          requester={plan.requesterEmail}
          introducerEmail={activeIntroduction.introducer.email}
          contactEmail={activeIntroduction.contact.email}
          handleSave={(introducer, memo, date, status) => {
            status && handleIntroducerChange(introducer, status, date, memo, activeIntroduction.id)
            handleCloseModal()
          }}
          memo={activeIntroduction.memo}
          statusOptions={options}
          status={status}
        />
      )}
      {activeIntroduction && (
        <ContactPickerDialog
          isOpened={openedDialog === 'contactPicker'}
          close={handleCloseModal}
          contact={activeIntroduction.contact}
          handleChange={(contact) => handleContactChange(contact, activeIntroduction.id)}
          filterOptions={(options: PersonOptionType[]) =>
            options.filter((option) => option.name && option.email && !contactsEmails.includes(option.email))
          }
        />
      )}
      <Box className={classes.actionButtons}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePL>
          Close
        </Button>
        <Button onClick={save} disabled={load || !introducersEmails.length || !contactsEmails.length} variant="text" disablePR>
          Next
        </Button>
      </Box>
    </>
  )
}

type CardItemProps = {
  memo: string
  introducerName: string
  introducerEmail: string
  contactName: string
  contactEmail: string
  openIntroducerPicker: () => void
  openContactPicker: () => void
  handleRemove: () => void
}

const CardItem = ({
  introducerName,
  introducerEmail,
  contactName,
  contactEmail,
  openIntroducerPicker,
  openContactPicker,
  memo,
  handleRemove
}: CardItemProps) => {
  const { classes } = useStyles()

  return (
    <CardContent>
      <Box display="grid" gridTemplateColumns="auto minmax(0px, 1fr) auto" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography color="text.secondary" className={classes.avatarTitle}>
            Contact
          </Typography>
          {contactName || !openContactPicker ? (
            <Avatar
              name={contactName}
              link={`${Paths._people}/${contactEmail}?name=${contactName}&email=${contactEmail}`}
              userKey={contactEmail}
              nameMode="multiline"
              size="md"
            />
          ) : (
            <>
              <Avatar name="Not selected" size="md" nameMode="multiline" hideName>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </Avatar>
              <Box pt={1}>
                <ContactPickerDialog.TriggerEl open={openContactPicker} />
              </Box>
            </>
          )}
        </Box>
        <Divider classes={{ root: classes.line }} />
        <Box display="flex">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="text.secondary" className={classes.avatarTitle}>
              Introducer
            </Typography>
            {introducerName || !openIntroducerPicker ? (
              <Avatar name={introducerName} link={`${Paths._people}/${introducerEmail}`} userKey={introducerEmail} nameMode="multiline" size="md" />
            ) : (
              <>
                <Avatar name="Not selected" size="md" nameMode="multiline" hideName>
                  <FontAwesomeIcon icon={['fas', 'user-tie']} />
                </Avatar>
                <Box pt={1}>
                  <IntroducerPickerDialog.TriggerEl open={openIntroducerPicker} />
                </Box>
              </>
            )}
          </Box>
          <IconButton
            disabled={!handleRemove}
            onClick={handleRemove}
            icon={['far', 'times']}
            color="primary"
            size="small"
            hint="Remove the pair"
            className={classes.cardAction}
            disablePY
            disablePR
          />
        </Box>
      </Box>
      {memo && (
        <Box display="flex" alignItems="center" mt={1.5}>
          <FontAwesomeIcon icon={['fas', 'file']} className={classes.icon} />
          <Typography>{memo}</Typography>
        </Box>
      )}
    </CardContent>
  )
}

export default AddParticipantsForm
