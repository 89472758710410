import { useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

import { mergeUrlWithParams } from 'utils/httpUtils'

const useProspectingManager = () => {
  const { result } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { fetchWithAbort, result: managers } = useAbortableFetch<{ isValid: boolean }[]>()
  const isManager = managers?.[0]?.isValid

  const checkIfManagerExists = async (searchTerm: string) => {
    const url = mergeUrlWithParams('/prospecting/managers', {
      role: 'ProspectingManager',
      searchTerm
    })

    fetchWithAbort({ url })
  }

  useEffect(() => {
    if (result?.results) {
      checkIfManagerExists(result.results.UserKey)
    }
  }, [result?.results])

  return isManager
}

export default useProspectingManager
