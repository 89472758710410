import React, { useEffect, useState, useContext, useMemo, ReactNode } from 'react'

import { useMsal } from '@azure/msal-react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountCircle, ImageSearch, Menu as MenuIcon } from '@mui/icons-material'
import { useMediaQuery, Box, AppBar, Container, Grid, IconButton, List, Drawer, SelectChangeEvent, ListItemButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useHistory, Link, useLocation, matchPath } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { GeneralSettingsContext } from '_core/context/GeneralSettings'
import { TeamContext } from '_core/context/TeamContext'

import Select, { OptionType } from '_shared/forms/Select'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import AboutAppDialog from '_core/components/dialogs/AboutApp'
import TokenDialog from '_core/components/dialogs/Token'
import { Wide, Narrow, useWide, WideStrict, Middle } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import { useTeamList } from '_core/hooks/useTeam'

import { checkOutlook } from '_core/helpers/outlook'

import { logout } from 'Auth'
import MobileNavBar from 'Layout/MobileNavBar'
import AccountMenu from 'SharedComponents/AccountMenu'
import TabSet from 'SharedComponents/TabSet'

import Paths from 'Paths'
import useAvatarImage from '_core/hooks/useAvatarImage'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'
import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'

const useStyles = makeStyles()((theme) => ({
  list: {
    width: 250,
    [theme.breakpoints.down('sidepanel')]: {
      marginBottom: 20
    }
  },
  logo: {
    flex: 1,
    display: 'flex',
    '& > a': {
      display: 'flex',
      '& > img': {
        paddingTop: 14.5,
        paddingBottom: 14.5
      }
    }
  },
  header: {
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      position: 'sticky',
      zIndex: 100,
      top: checkOutlook() ? '28px' : '0px'
    },
    backgroundColor: theme.palette.background.darker,
    height: 60
  },
  nameHeading: {
    margin: theme.spacing(2),
    '& h4': {
      marginBottom: 0
    }
  },
  typography: {
    maxWidth: '210px',
    marginBottom: 0,
    lineHeight: '22px'
  },
  leadsIcon: {
    marginLeft: theme.spacing(2)
  },
  pic: {
    width: '42px',
    height: '42px',
    borderRadius: '100%',
    marginRight: theme.spacing(2)
  },
  profile: {
    transform: 'scale(1.2)',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2.5),
    marginTop: theme.spacing(0.5)
  },
  searchIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  container: {
    padding: `0 ${theme.spacing(2)}`
  },
  navBarIcons: {
    display: 'flex'
  },
  rotate: {
    transform: 'rotate(180deg)'
  }
}))

const Label = ({ label, icon, forceNarrow, className }: { label: string; icon: IconProp; forceNarrow?: boolean; className?: string }) => {
  return (
    <>
      <WideStrict forceNarrow={forceNarrow}>{label}</WideStrict>
      <Middle forceNarrow={forceNarrow}>
        <Tooltip title={label}>
          <Box width="42px">
            <FontAwesomeIcon size="lg" icon={icon} className={className} />
          </Box>
        </Tooltip>
      </Middle>
      <Narrow forceNarrow={forceNarrow}>
        <Box display="flex" alignItems="center">
          <Box width="20px" pr={2} display="flex" alignItems="center" justifyContent="center">
            <FontAwesomeIcon size="1x" icon={icon} className={className} />
          </Box>
          <Typography color="inherit">{label}</Typography>
        </Box>
      </Narrow>
    </>
  )
}

function NavBar({ children }: { children?: ReactNode }) {
  const { pathname } = useLocation<any>()
  const history = useHistory()
  const { classes } = useStyles()
  const theme = useTheme()
  const upMobile = useMediaQuery(theme.breakpoints.up('md'))
  const { hiddenNavbarItems, hiddenNavbarItemsLoading } = useNavBarMenuItemsShown()
  const teamContext = useContext(TeamContext)
  const teamNumber = teamContext.teamContextValue.teamNumber
  const { payloads } = useSidepanelPayloads()
  const [drawer, setDrawer] = useState(false)
  const [teams, setTeams] = useState<Team[]>()
  const [currentUser, setCurrentUser] = useState<{ [key: string]: any }>()
  const { admin, curator } = useAdminOrCuratorCheck()
  const [removedTeamList, setRemovedTeamList] = useState<number[]>()
  const [activeTab, setActiveTab] = useState('')
  const { isExact: hideLinks } = matchPath(pathname, { path: [Paths._welcome] }) || {}
  const matchLaptopWidth = useWide('laptop')
  const wide = useWide()
  const { getTeamsList } = useTeamList()
  const { isDialogOpened: openedAboutDialog, openDialog: openAboutDialog, closeDialog: closeAboutDialog } = useDialog()
  const { isDialogOpened: openedTokenDialog, openDialog: openTokenDialog, closeDialog: closeTokenDialog } = useDialog()
  const { accounts } = useMsal()
  const filteredTeams = useMemo(() => teams?.filter((team) => !removedTeamList?.includes(team.id)), [teams, removedTeamList])
  const { logo } = useContext(GeneralSettingsContext)
  const profilePic = useAvatarImage(currentUser?.name, '../me')

  const { setHomePage } = useContext(GeneralSettingsContext)
  const resetHomePage = hiddenNavbarItems?.includes('events')

  useEffect(() => {
    if (resetHomePage) {
      setHomePage('')
    }
  }, [hiddenNavbarItems, setHomePage])

  useEffect(() => {
    if (accounts.length) {
      setCurrentUser(accounts[0])
    }
  }, [accounts.length])

  const setCallback = (item: any) => ({
    ...item,
    callback: () => (item.callback ? item.callback() : changeTab(item.id))
  })

  const menuItems = [
    {
      id: 'events',
      label: <Label label="Events" icon={['far', 'calendar-alt']} />,
      routesMatch: [Paths._events],
      condition: !hideLinks && hiddenNavbarItems && !hiddenNavbarItems.includes('events'),
      className: 'events-onboarding'
    },
    {
      id: 'activities',
      label: <Label label="Activities" icon={['far', 'wave-pulse']} />,
      routesMatch: [Paths._activities],
      condition: !hideLinks && hiddenNavbarItems && !hiddenNavbarItems.includes('activities')
    },
    {
      id: 'companies',
      label: <Label label="Companies" icon={['far', 'building']} />,
      routesMatch: [Paths._companies],
      condition: !hideLinks
    },
    {
      id: 'people',
      label: <Label label="People" icon={['far', 'address-book']} />,
      routesMatch: [Paths._people],
      condition: !hideLinks
    },
    {
      id: 'teams',
      label: <Label label="Teams" icon={['fas', 'users-class']} />,
      routesMatch: [Paths._teams],
      condition: !hideLinks
    },
    {
      id: 'introductions',
      label: <Label label="Introductions" icon={['far', 'users']} />,
      routesMatch: [Paths._introductions],
      condition: !hideLinks && hiddenNavbarItems && !hiddenNavbarItems.includes('introductions')
    },
    {
      id: 'deals',
      label: <Label label="Deals" icon={['far', 'handshake']} />,
      routesMatch: [Paths._deals],
      condition: !hideLinks
    }
  ]
    .filter((menuItem) => menuItem.condition)
    .map(setCallback)

  const options = [
    {
      id: 'api_console',
      label: <Label label="API console" icon={['fas', 'chart-network']} forceNarrow />,
      routesMatch: [],
      condition: !hideLinks && wide && hiddenNavbarItems && !hiddenNavbarItems.includes('apiConsole'),
      callback: openTokenDialog
    },
    {
      id: 'data_sources',
      label: <Label label="Data sources" icon={['far', 'database']} forceNarrow />,
      routesMatch: [Paths.dataSources],
      condition: !hideLinks
    },
    {
      id: 'manual_edits',
      label: <Label label="Manual edits" icon={['far', 'align-right']} className={classes.rotate} forceNarrow />,
      routesMatch: [Paths._manualEdits, `${Paths._manualEdits}/companies`, `${Paths._manualEdits}/people`],
      condition: !hideLinks
    },
    {
      id: 'apps',
      label: <Label label="Apps" icon={['far', 'window-restore']} forceNarrow />,
      routesMatch: [Paths._apps],
      condition: !hideLinks
    },
    {
      id: 'settings',
      label: <Label label="Settings" icon={['far', 'cog']} forceNarrow />,
      routesMatch: [Paths._settings],
      condition: !hideLinks
    },
    {
      id: 'admin_settings',
      label: <Label label="Admin settings" icon={['fal', 'tools']} forceNarrow />,
      routesMatch: [Paths._adminSettings],
      condition: admin && !hideLinks
    },
    {
      id: 'data_administration',
      label: <Label label="Data administration" icon={['far', 'sliders-h']} forceNarrow />,
      routesMatch: [Paths._dataAdmin],
      condition: (admin || curator) && !hideLinks
    },
    {
      id: 'analyze_leads',
      label: <Label label="Analyze leads" icon={['far', 'th-list']} forceNarrow />,
      routesMatch: [Paths._analyzeLeads],
      condition: !hideLinks && !wide
    },
    {
      id: 'help_center',
      label: <Label label="Help center" icon={['far', 'question-circle']} forceNarrow />,
      routesMatch: [],
      condition: !hideLinks,
      callback: () => window.open('https://help.dotalign.com')
    },
    {
      id: 'about',
      label: <Label label="About" icon={['far', 'info-circle']} forceNarrow />,
      routesMatch: [],
      condition: !hideLinks,
      callback: openAboutDialog
    },
    {
      id: 'log_out',
      label: <Label label="Log out" icon={['far', 'sign-out']} forceNarrow />,
      routesMatch: [],
      condition: true,
      callback: async () => {
        try {
          await logout()
        } catch (error) {
          console.error('error during logout', error)
        }
      }
    }
  ]
    .filter((optItem) => optItem.condition)
    .map(setCallback)

  const items = [...menuItems, ...options]

  const getActiveTab = () => {
    let id = ''
    items.forEach((value) => {
      for (const path of value.routesMatch) {
        if (
          matchPath(pathname, {
            path,
            exact: true,
            strict: false
          })
        ) {
          id = value.id
        }
      }
    })
    return id
  }

  const getRouteByTabIndex = (index: number) => items[index].routesMatch[0]

  const getTeams = async () => {
    const teams = await getTeamsList()
    setTeams(teams)
  }

  useEffect(() => {
    getTeams()
  }, [teamContext.teamFetchCounter])

  useEffect(() => {
    if (payloads && payloads.action === 'EDIT_TEAM') {
      const { id, name } = payloads.value

      setTeams((prevState?: Team[]) => {
        if (prevState) {
          const objIndex = prevState.findIndex((team: Team) => team.id === id)
          if (objIndex === -1) {
            return prevState
          } else {
            const teamsList = [...prevState]
            teamsList[objIndex] = { ...teamsList[objIndex], name }
            return teamsList
          }
        }
      })
    }
  }, [payloads])

  useEffect(() => {
    const removedTeams = JSON.parse(localStorage.getItem('removed_teams') || '[]')
    setRemovedTeamList(removedTeams)

    if (teams?.length) {
      const ids = teams.map((team) => team.id)

      if (removedTeams.length) {
        const updated = removedTeams.filter((prevId: number) => ids.includes(prevId))
        localStorage.setItem('removed_teams', JSON.stringify(updated))
      }
    }

    if (!teams?.length && removedTeams.length) {
      localStorage.setItem('removed_teams', JSON.stringify([]))
    }
  }, [teams])

  useEffect(() => {
    if (!hiddenNavbarItemsLoading && typeof admin === 'boolean') {
      setActiveTab(getActiveTab())
    }
  }, [pathname, hiddenNavbarItemsLoading, admin])

  useEffect(() => {
    // this code allows user to have some team initially picked
    // if he can't see the team that specified as initial in context
    // to do make the context to define the initial team on launch
    if (filteredTeams && !filteredTeams.filter((team) => team.id === teamNumber).length) {
      teamContext.setTeamContext({ teamNumber: filteredTeams[0]?.id || 0 })
    }
  }, [filteredTeams])

  const handleTeamChange = (event: SelectChangeEvent<unknown>) => {
    teamContext.setTeamContext({
      teamNumber: event.target.value as number
    })
  }

  const selectedTeam =
    teamContext.teamContextValue != null ? filteredTeams?.find((team) => team.id === teamNumber) : null

  const tabChanged = (tabIndex: number) => {
    const path = getRouteByTabIndex(tabIndex)
    history.push(path)
  }

  const changeTab = (newValue: string) => {
    setActiveTab(newValue)
    tabChanged(items.findIndex((item) => item.id === newValue))
  }

  const toggleDrawer = () => {
    setDrawer(!drawer)
  }

  const sideList = () => (
    <>
      {currentUser && (
        <Box display="flex" className={classes.nameHeading}>
          <Box>
            {profilePic ? (
              <img src={profilePic} className={classes.pic} alt="Profile" />
            ) : (
              <AccountCircle fontSize="large" color="secondary" className={classes.profile} />
            )}
          </Box>
          <Box>
            <Typography
              semiBold
              ellipsis
              variant="h3"
              className={classes.typography}
              title={currentUser.given_name ? `${currentUser.given_name} ${currentUser.family_name}` : currentUser.name}
            >
              {currentUser.given_name ? `${currentUser.given_name} ${currentUser.family_name} ` : currentUser.name}
            </Typography>
            <Typography className={classes.typography} variant="body1" color="text.secondary" ellipsis>
              {currentUser.username}
            </Typography>
          </Box>
        </Box>
      )}
      {hideLinks || (
        <Select
          options={filteredTeams?.map(({ id: value, name }): OptionType => ({ value, label: name })) || []}
          onChange={handleTeamChange}
          value={selectedTeam?.id}
          fullWidth
          className={classes.container}
        />
      )}
      <div className={classes.list} role="presentation" onClick={toggleDrawer}>
        <List>
          {items.map((item: any) => (
            <ListItemButton key={item.id} onClick={() => item.callback()} selected={activeTab === item.id}>
              {item.label}
            </ListItemButton>
          ))}
        </List>
      </div>
    </>
  )

  return (
    <>
      <AppBar position="sticky" color="default" elevation={0} className={classes.header}>
        <Narrow>
          <Drawer open={drawer} onClose={toggleDrawer}>
            {sideList()}
          </Drawer>
        </Narrow>
        <Container maxWidth="lg" disableGutters={!upMobile}>
          <Grid container direction="row" alignItems="center">
            <Narrow>
              <Grid item style={{ width: '100%' }}>
                <MobileNavBar
                  leftComponent={
                    <IconButton onClick={toggleDrawer}>
                      <MenuIcon />
                    </IconButton>
                  }
                  rightComponent={
                    <>
                      {hideLinks || (
                        <>
                          {children}
                          <Tooltip title="Search">
                            <IconButton
                              onClick={() => pathname !== Paths._search && history.push(Paths._search)}
                              classes={{ root: classes.searchIcon }}
                              color={pathname === Paths._search ? 'primary' : 'secondary'}
                            >
                              <FontAwesomeIcon icon={['far', 'search']} style={{ fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </>
                  }
                />
              </Grid>
            </Narrow>

            <Grid item className={classes.logo}>
              <Link to={Paths._home}>
                <Wide>{logo && <img src={logo} alt="logo" style={{ height: matchLaptopWidth ? 30 : 25 }} />}</Wide>
              </Link>
            </Grid>
            <Wide>
              <>
                {hideLinks || (
                  <>
                    <Grid item>
                      <IconButton
                        onClick={() => pathname !== '/search' && history.push('/search')}
                        color={pathname === Paths._search ? 'primary' : 'secondary'}
                        classes={{ root: classes.searchIcon }}
                      >
                        <FontAwesomeIcon icon={['far', 'search']} style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                    <TabSet
                      tabIndex={menuItems.findIndex((item) => item.id === activeTab)}
                      setTabIndex={(index: number) => setActiveTab(menuItems[index].id)}
                      tabs={menuItems.map((i) => ({ label: i.label, className: i.className, disabled: !!i.disabled }))}
                      isNavBar={true}
                      onChange={tabChanged}
                    />
                    <Box ml={1.5}>
                      <Select
                        options={filteredTeams?.map(({ id: value, name }): OptionType => ({ value, label: name })) || []}
                        onChange={handleTeamChange}
                        value={selectedTeam?.id}
                        size="small"
                      />
                    </Box>
                    <Grid item>
                      <SidepanelLink linkProps={{ to: Paths._analyzeLeads }} sidepanel={true}>
                        <Tooltip title="Analyze a list of people or company leads">
                          <IconButton classes={{ root: classes.leadsIcon }} color="secondary">
                            <ImageSearch />
                          </IconButton>
                        </Tooltip>
                      </SidepanelLink>
                    </Grid>
                  </>
                )}
              </>
              <Grid item>
                <AccountMenu options={options} currentUser={currentUser} profilePic={profilePic} activeTab={activeTab} />
              </Grid>
            </Wide>
          </Grid>
        </Container>
      </AppBar>
      <TokenDialog opened={openedTokenDialog} close={closeTokenDialog} />
      <AboutAppDialog opened={openedAboutDialog} close={closeAboutDialog} />
    </>
  )
}

export default NavBar
