import React, { useContext, useEffect, useMemo } from 'react'

import { Box } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import SyncSettings from '_core/components/SqlSync'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { useTabs } from '_core/hooks/useTabs'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'
import TabSet from 'SharedComponents/TabSet'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  heading: {
    width: '100%',
    zIndex: 1,
    marginBottom: theme.spacing(2),
    background: theme.palette.background.light,
    [theme.breakpoints.up('md')]: {
      background: 'inherit',
      position: 'inherit'
    }
  },
  tabSet: {
    padding: 0
  }
}))

const baseUrl = DataAdminSubPaths.sync

const tabs = [
  {
    label: 'Settings',
    routesMatch: [`${baseUrl}/settings`]
  }
]

const Heading = () => {
  const { tabIndex, setTabIndex, tabChanged } = useTabs(tabs)
  const { classes } = useStyles()

  const Tabs = <TabSet className={classes.tabSet} tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={tabChanged} />

  return (
    <>
      <Wide>
        <Typography variant="h1" className={classes.heading}>
          Sync
        </Typography>
        {Tabs}
      </Wide>
      <Narrow>
        <Topbar nativeBack={Paths._dataAdmin} />
        <Widget>
          <Box mb={-2}>{Tabs}</Box>
        </Widget>
      </Narrow>
    </>
  )
}

const SyncPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Sync')
  }, [setMobileHeader])

  const syncUrls = useMemo(
    () => [
      { key: 'settings', url: '/adminsettings/sqlServerSync' },
      { key: 'teams', url: '/teams' }
    ],
    []
  )

  return (
    <Page>
      <Heading />
      <Switch>
        <Route exact path={baseUrl}>
          <Redirect to={`${baseUrl}/settings`} />
        </Route>
        <Route path={`${baseUrl}/settings`} render={() => <DynamicEntity urls={syncUrls} component={SyncSettings} id="sync" />} />
      </Switch>
    </Page>
  )
}

export default SyncPage
