import React, { ReactElement, useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'

import Popover from '_shared/Popover'
import Typography from '_shared/Typography'

import IntroducerPickerDialog from '_core/components/dialogs/IntroducerPicker'
import IntroductionActivityDialog from '_core/components/dialogs/IntroductionActivity'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import ActivityItem from '_core/components/introductions/ActivityItem'
import ParticipantsWidget from '_core/components/introductions/ParticipantsWidget'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import useActiveDirectoryPersonData from '_core/hooks/useActiveDirectoryPersonData'
import useDialog from '_core/hooks/useDialog'
import { useStyles } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'
import { ellipsisText } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type ActivityItemType = {
  date: string
  label: string | ReactElement
  key: string
  status: string
}

export type IntroductionProps = {
  introduction: { data: IntroductionContactResp[] }
  statuses: { label: string; value: string }[]
  loading: boolean
}

function compareByDate(a: ActivityItemType, b: ActivityItemType) {
  const dateA = a.date
  const dateB = b.date

  if (dateA > dateB) {
    return -1
  } else if (dateA < dateB) {
    return 1
  } else {
    return 0
  }
}

const mockedEvents = [
  {
    date: '',
    label: 'Placeholder for label',
    key: 'mocked-0',
    status: '',
    introducer: '',
    author: '',
    memo: ''
  },
  {
    date: '',
    label: 'Placeholder for label',
    key: 'mocked-1',
    status: '',
    introducer: '',
    author: '',
    memo: ''
  }
]

const IntroductionDetails = ({ statuses, ...props }: IntroductionProps) => {
  const { contactId } = useParams<{ id: string; contactId: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const {
    dialogContentProps: openedDialog,
    closeDialog,
    openDialog,
    openSuccess,
    successMode
  } = useDialog<'introducerPicker' | 'addActivity' | null>(null)
  const introduction = props.introduction?.data?.[0] || {}
  const { displayAs, contactEmail, selectedIntroducer, requesterName, requesterEmail, querierIsLtnManager, planUid } = introduction
  const { introducerEmail } = selectedIntroducer || {}
  const { person: introducer, loading: introducerLoading, setPerson: setIntroducer } = useActiveDirectoryPersonData(introducerEmail)
  const [events, setEvents] = useState<IntroductionEventType[]>()
  const [loading, setLoading] = useState(false)
  const { updateParent } = useSidepanelPayloads()
  const { classes } = useStyles()

  useEffect(() => {
    setMobileHeader(displayAs, !displayAs)
  }, [setMobileHeader, displayAs])

  useEffect(() => {
    if (introduction.introEvents && contactId) {
      const introEvents = introduction.introEvents
        .filter((contact) => contact.contactEmail === contactId)
        .map((event) => ({
          date: event.introEventTime,
          author: event.introEventAuthorEmail,
          introducer: event.targetingStatusAfter === 'IntroducerSelected' ? event.introducerEmail : '',
          memo: event.introEventMemo,
          status: event.targetingStatusAfter,
          key: event.rowNumber.toString()
        }))

      setEvents(introEvents)
    }
  }, [introduction.introEvents?.length, contactId])

  const activityEvents = events
    ? events.map((event) => {
        const statusLabel = statuses?.find((status) => status.value === event.status)?.label
        return {
          date: event.date,
          key: event.key,
          status: event.status,
          label: (
            <Box display="flex" flexDirection="column">
              {event.introducer && (
                <>
                  <SidepanelLink linkProps={{ to: `${Paths._people}/${event.introducer}` }}>
                    <Typography color="primary" ellipsis>
                      {event.introducer}
                    </Typography>
                  </SidepanelLink>
                  has been selected as introducer
                </>
              )}
              {statusLabel && !event.introducer && <Typography semiBold>{statusLabel}</Typography>}
              {event.memo && (
                <Popover placement="top-start" triggerElement={<Typography color="text.secondary">{ellipsisText(event.memo, 60)}</Typography>}>
                  <Box maxHeight="50vh" overflow="auto" maxWidth={500}>
                    <Typography variant="h4" semiBold>
                      Memo
                    </Typography>
                    <Box pt={1}>
                      <Typography>{event.memo}</Typography>
                    </Box>
                  </Box>
                </Popover>
              )}
              <SidepanelLink linkProps={{ to: `${Paths._people}/${event.author}` }}>
                <Typography color="primary" ellipsis>
                  {event.author}
                </Typography>
              </SidepanelLink>
            </Box>
          )
        }
      })
    : mockedEvents

  const historyActivity: ActivityItemType[] = activityEvents.sort(compareByDate)
  const targetingStatus = historyActivity.length ? historyActivity[0].status : introduction.targetingStatus
  const openIntroducerPicker = () => openDialog('introducerPicker')
  const openAddActivity = () => openDialog('addActivity')

  const handleSave = async (introducer: { name: string; email: string }, memo: string, eventTime: MomentType | null) => {
    if (planUid && contactEmail) {
      setLoading(true)
      const resp = await post<IntroEventResp[]>('/prospecting/withintroducer', [
        {
          planUid,
          introducerEmail: introducer.email,
          contactIdentifier: contactEmail,
          eventMemo: memo,
          status: 'IntroducerSelected',
          ...(eventTime ? { eventTime } : {}),
          setFullyConnectedExplicitlyNull: true,
          setReachedOutExplicitlyNull: true
        }
      ])
      if (resp) {
        const response = resp[0]
        const addedIntroducer = {
          emailAddress: introducer.email,
          displayName: introducer.name
        }
        const addedEvent = {
          date: response.introEventTime,
          author: response.introEventAuthorEmail,
          introducer: response.introducerEmail,
          memo: response.introEventMemo,
          status: response.targetingStatus,
          key: response.introEventTime
        }
        setIntroducer(addedIntroducer)
        setEvents((prevState) => [...(prevState ? prevState : []), addedEvent])
        updateParent({
          action: 'UPDATE_INTRODUCTION_DETAILS',
          value: {
            planUid: response.planUid,
            contactId: response.contactEmail,
            fieldName: 'introducer',
            value: {
              name: response.introducerEmail,
              email: response.introducerEmail,
              link: `${Paths._people}/${response.introducerEmail}`
            }
          }
        })
      }
      openSuccess()
      setLoading(false)
    }
  }

  return (
    <Box className={classes.wrapper}>
      <ParticipantsWidget
        loading={props.loading || introducerLoading}
        querierIsLtnManager={querierIsLtnManager}
        contactName={displayAs}
        contactEmail={contactEmail}
        introducerName={introducer.displayName}
        introducerEmail={introducer.emailAddress}
        requesterEmail={requesterEmail}
        requesterName={requesterName}
        openIntroducerPicker={openIntroducerPicker}
      />
      <Widget>
        <Heading title="Timeline" icon={['far', 'clock-rotate-left']} />
        {querierIsLtnManager && introducer.emailAddress && (
          <Box display="flex" justifyContent="center">
            <IntroductionActivityDialog.TriggerEl open={openAddActivity} />
          </Box>
        )}
        {!historyActivity.length && (
          <Empty title="No activity" icon={<FontAwesomeIcon size="3x" icon={['fal', 'clock-rotate-left']} style={{ color: '#A7A7A7' }} />} />
        )}
        {!!historyActivity.length &&
          historyActivity.map((activity, i) => (
            <ActivityItem
              key={activity.key}
              date={activity.date}
              label={activity.label}
              status={activity.status}
              hideConnector={i === historyActivity.length - 1}
              loading={props.loading || !planUid}
            />
          ))}
      </Widget>
      <IntroductionActivityDialog
        isOpened={openedDialog === 'addActivity'}
        introducer={introducer.emailAddress}
        close={closeDialog}
        openSuccess={openSuccess}
        successMode={successMode}
        setEvents={setEvents}
        status={targetingStatus}
      />
      <IntroducerPickerDialog
        planUid={planUid}
        contactEmail={contactEmail}
        isOpened={openedDialog === 'introducerPicker'}
        close={closeDialog}
        successMode={successMode}
        requester={requesterEmail}
        introducerEmail={introducer.emailAddress}
        handleSave={handleSave}
        loading={loading}
      />
    </Box>
  )
}

export default IntroductionDetails
