import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type IntroductionVisibility = 'Off' | 'AdminsAndNetworkTeam' | 'AllUsers'
export type IntroductionAccessData = [{ visibility: IntroductionVisibility }, ProfileType, { emailAddress: string }[]]

const useIntroductionsAccess = () => {
  const { fetchWithAbort, result, setResult } = useAbortableFetch<IntroductionAccessData>()
  const [userSettings, profile, managers] = result || []

  const checkWhetherVisible = (visibilityLevel: IntroductionVisibility) => {
    if (visibilityLevel === 'Off') {
      return false
    }

    const { IsAdmin, UserKey: userEmail } = profile || {}

    const isNetworkManager = !!managers?.find((manager) => manager.emailAddress === userEmail)
    return visibilityLevel === 'AllUsers' || (visibilityLevel === 'AdminsAndNetworkTeam' && (IsAdmin || isNetworkManager))
  }

  const userAccess = userSettings ? checkWhetherVisible(userSettings.visibility) : userSettings

  const getUserAccess = async () => {
    fetchWithAbort([{ url: '/usersettings/introductions' }, { url: '/me/profile' }, { url: '/prospecting/managers?role=ProspectingManager' }])
  }

  const updateUserIntroductionsAccess = (introductionsAccessUpdateData: IntroductionAccessData) => {
    setResult(introductionsAccessUpdateData)
  }

  return {
    introductionsAccessResult: result,
    isUserIntroductionsVisible: userAccess,
    getUserIntroductionsAccess: getUserAccess,
    updateUserIntroductionsAccess
  }
}

export default useIntroductionsAccess
