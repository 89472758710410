import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Bar from '_core/components/Bar'

import useOnboarding from '_core/hooks/useOnboarding'
import useSidebar from '_core/hooks/useSidebar'

import { checkOutlook } from '_core/helpers/outlook'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()(() => ({
  closeButton: {
    minWidth: 0,
    padding: '8px 12px'
  },
  explode: {
    minWidth: 0,
    padding: '8px 12px'
  },
  goButton: {
    minWidth: 0,
    padding: '8px 12px'
  }
}))

type SidepanelName = 'sidepanel' | 'preview'

const sidepanelTypesMap: { type: SidepanelType; condition: boolean }[] = [
  { type: true, condition: window.name === 'sidepanel' },
  { type: 'preview', condition: window.name === 'preview' }
]

export const { type: sidepanelType } = sidepanelTypesMap.find(({ condition }) => !!condition) || {}

export const isSidepanel = (name?: SidepanelName) => (name ? window.name === name : window.name === 'sidepanel' || window.name === 'preview')

const pagesNotExpandable = [
  Paths._salesforce,
  Paths._merge,
  Paths._analyzeLeads,
  Paths._messages,
  Paths._events,
  `${Paths._activities}/event`,
  `${Paths._activities}/message`,
  Paths._introductions,
  DataAdminSubPaths.privilegedCompanies,
  '/upload',
  '/add',
  '/edit',
  '/similar',
  '/audit',
  '/c-suite',
  '/market-data',
  `/phoneSources`,
  '/emailSources',
  '/aliasSources',
  '/domainSources',
  '/tags',
  '/info/phones',
  '/info/emails',
  '/activities'
]

const Sidebar = () => {
  const {
    cx,
    classes: { closeButton, explode, goButton }
  } = useStyles()
  const { close, back, forward, backActive, forwardActive, currentPath } = useSidebar()
  const { startOnboarding } = useOnboarding()

  const isNotExpandable = !!(currentPath && pagesNotExpandable.filter((slug: any) => currentPath.indexOf(slug) > -1).length > 0)

  useEffect(() => {
    if (currentPath && !isNotExpandable) {
      startOnboarding('sidebar')
    }
  }, [currentPath, isNotExpandable])

  const handleExplode = () => {
    if (currentPath) {
      window.parent.postMessage(`expand:${currentPath}`, '*')
      close()
    }
  }

  const handleExpand = () => {
    window.open(window.location.href, '_blank')
  }

  return (
    <Bar>
      <div>
        <Button variant="text" color="inherit" onClick={back} className={goButton} disabled={!backActive}>
          <FontAwesomeIcon icon={['fas', 'arrow-left']} style={{ fontSize: 13 }} />
        </Button>
        <Button variant="text" color="inherit" onClick={forward} className={goButton} disabled={!forwardActive}>
          <FontAwesomeIcon icon={['fas', 'arrow-right']} style={{ fontSize: 13 }} />
        </Button>
      </div>
      <div>
        {isSidepanel() && (
          <Button
            variant="text"
            color="inherit"
            onClick={handleExplode}
            className={cx(explode, 'expand-sidebar-onboarding')}
            disabled={isNotExpandable}
          >
            <FontAwesomeIcon icon={['fad', 'up-right-and-down-left-from-center']} style={{ fontSize: 12 }} />
          </Button>
        )}
        {checkOutlook() && (
          <Button variant="text" color="inherit" onClick={handleExpand} className={cx(explode, 'expand-sidebar-onboarding')}>
            <FontAwesomeIcon icon={['far', 'expand']} style={{ fontSize: 12 }} />
          </Button>
        )}
        {isSidepanel('sidepanel') && (
          <Button variant="text" color="inherit" onClick={close} className={closeButton}>
            <FontAwesomeIcon icon={['far', 'times']} style={{ fontSize: 13 }} />
          </Button>
        )}
      </div>
    </Bar>
  )
}

export default Sidebar
