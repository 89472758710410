import { RefObject } from 'react'

import { OptionType } from '_shared/forms/Select'

export type AnalyzeLeadsProps = {
  className?: string
  isOpen?: boolean
  hideIcon?: boolean
}

export enum LeadTypeEnum {
  company = 'Company',
  person = 'Person',
  companies = 'Companies',
  people = 'People'
}

export const leadTypes: OptionType[] = [
  {
    value: LeadTypeEnum.company,
    label: LeadTypeEnum.companies
  },
  {
    value: LeadTypeEnum.person,
    label: LeadTypeEnum.people
  }
]

export interface IAnalyzeLeadsProps {
  leadsRef: RefObject<HTMLTextAreaElement>
}

export const personPlaceholder = `John Doe
jane.doe@globex.com
jsmith@gmail.com
Juan Perez
kovacs.janos@hooli.co`

export const companyPlaceholder = `Globex
rainvest.com
Watson & Wilson Pty Ltd
Initech
Nakatomi Trading Corp.`
