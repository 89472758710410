import * as _ from 'lodash'

export const updateQuerystring = (uri, key, value) => {
  const re = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i')
  if (value === undefined) {
    if (uri.match(re)) {
      return uri.replace(re, '$1$2')
    } else {
      return uri
    }
  } else {
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      let hash = ''
      if (uri.indexOf('#') !== -1) {
        hash = uri.replace(/.*#/, '#')
        uri = uri.replace(/#.*/, '')
      }
      const separator = uri.indexOf('?') !== -1 ? '&' : '?'
      return uri + separator + key + '=' + value + hash
    }
  }
}

export const getQuerystring = (name, url = window.location.href) => {
  name = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const useQuery = (search) => new URLSearchParams(search)

export const parseUrl = (search, candidatesToTransform = []) => {
  const query = new URLSearchParams(search)
  const params = {}
  for (const key of query.keys()) {
    params[key] = candidatesToTransform.includes(key) ? query.get(key).split(',') : query.get(key)
  }
  return params
}

export const stringifyUrl = (pathname, search) => {
  const isEmpty = (value) =>
    _.isUndefined(value) ||
    _.isNull(value) ||
    _.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)

  const encodedSearchParams = Object.keys(search).reduce((acc, key) => {
    const getEncoded = (val) => (Array.isArray(val) ? val : val.split(',')).map((val) => encodeURIComponent(val))

    return { ...acc, [key]: search[key] ? getEncoded(search[key]) : search[key] }
  }, {})
  const sParams = decodeURIComponent(new URLSearchParams(_.omitBy(encodedSearchParams, isEmpty)))
  return `${pathname}${sParams ? `?${sParams}` : ''}`
}

export const getLanguage = () => (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || 'en-US'

export const getBrowser = () => {
  const ua = navigator.userAgent
  let tem = navigator.userAgent
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: +tem[1] || 0 }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: +tem[2] }
  }
  M = M[2] ? [M[1], M[2]] : [ua.appName, ua.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return { name: M[0], version: +M[1] }
}
