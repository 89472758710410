import React, { useState, MouseEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, List, ListItemText, IconButton, ListItemSecondaryAction, ListItemButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { del } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  root: {
    minHeight: 'auto',
    borderBottom: '1px solid #f2f2f2',
    [theme.breakpoints.up('md')]: {
      minHeight: 201,
      boxSizing: 'border-box'
    }
  },
  item: {
    maxWidth: '100%'
  },
  text: {
    opacity: 0.42,
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 0
    }
  }
}))

const RecentItem = ({ value, remove }: { value: string; remove: (val: string) => void }) => {
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const { updateQuery } = useSearchQuery()

  const handleClick = () => {
    updateQuery({ keyword: value }, { allowSearchUpdate: true })
  }

  const removeSearchItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setLoading(true)
    del(`/savedsearches/${value}`)
      .then(() => {
        remove(value)
        enqueueSnackbar(`'${value}' was removed from your recent searches`, {
          variant: 'default'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <ListItemButton onClick={handleClick} disableRipple component="div">
      <ListItemText
        primary={
          <Tooltip title={value}>
            <Typography ellipsis classes={{ root: classes.item }}>
              <Skeleton condition={loading || !value} width="100%">
                {!loading ? value : ''}
              </Skeleton>
            </Typography>
          </Tooltip>
        }
      />

      {value && !loading && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="remove-recent-search" onClick={removeSearchItem}>
            <FontAwesomeIcon icon={['far', 'times']} style={{ width: 13, height: 13 }} />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  )
}

const RecentSearches = ({ items }: any) => {
  const { classes } = useStyles()
  const [searches, setSearches] = useState(items?.searchHistory || new Array(5).fill(''))

  const remove = (value: string) => {
    setSearches((prevState: string[]) => prevState.filter((val) => val !== value))
  }

  return (
    <Box py={1} className={classes.root}>
      {!searches.length && (
        <Box px={1}>
          <Typography classes={{ root: classes.text }}>No recent searches</Typography>
        </Box>
      )}
      {!!searches.length && (
        <List dense disablePadding>
          {searches?.map((value: string, i: number) => <RecentItem key={i} value={value} remove={remove} />)}
        </List>
      )}
    </Box>
  )
}

export default RecentSearches
