import { useEffect, useState, useRef, ReactElement, useCallback, useMemo } from 'react'

import _ from 'lodash'

import { sidepanelType } from '_pages/sidebar'

import { RenamedCategory, RenamedTag } from '_core/components/ManageTags'

import { RemoteTagsListItem, TaggableType } from '_core/hooks/useLookup'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { uuid } from 'utils/demoUtils'
import { post } from 'utils/httpUtils'

type RenamedTagType = {
  categoryKey: string
  tagKey: string
  oldTagName: string
  newTagName: string
}

export const groupTags = (tags: UngroupedTag[]) => {
  return tags
    .reduce((acc: GroupedTag[], curr: UngroupedTag) => {
      const categorizedTag = acc.find((obj) => obj.categoryKey === curr.categoryName)
      if (categorizedTag) {
        categorizedTag.tagNames.push({ tagKey: curr.tagName, tagName: curr.tagName })
      } else {
        acc.push({ categoryKey: curr.categoryName, categoryName: curr.categoryName, tagNames: [{ tagKey: curr.tagName, tagName: curr.tagName }] })
      }
      return acc
    }, [])
    .filter((el: GroupedTag) => el.tagNames.length)
}

export const ungroupTags = (tags: GroupedTag[]) => {
  return tags.flatMap(({ categoryName, categoryKey, tagNames }) =>
    tagNames.flatMap(({ tagKey, tagName }) => ({ categoryKey, categoryName, tagKey, tagName }))
  )
}

const useTagsManager = (items: RemoteTagsListItem[], loading: boolean) => {
  const { updateParent } = useSidepanelPayloads(sidepanelType)

  const initialTags = useRef<Modify<GroupedTag, { tagNames: Modify<GroupedTag['tagNames'][number], { isDuplicated: boolean }>[] }>[]>()

  const [tags, setTags] = useState<Modify<GroupedTag, { tagNames: Modify<GroupedTag['tagNames'][number], { isDuplicated: boolean }>[] }>[]>()
  const [isSaving, setIsSaving] = useState(false)

  const duplicatedCategories =
    tags
      ?.reduce<string[]>((acc, { categoryName }) => [...acc, categoryName.toLowerCase()], [])
      .filter((item, index, self) => self.indexOf(item) !== index) || []

  const tagsMap = tags?.reduce(
    (acc, { categoryKey, categoryName, tagNames }) => ({
      ...acc,
      [categoryKey]: {
        categoryName,
        tagNames: tagNames.reduce((acc, { tagKey, tagName }) => ({ ...acc, [tagKey]: tagName }), {} as { [tagKey: string]: string })
      }
    }),
    {} as { [categoryKey: string]: { categoryName: string; tagNames: { [tagKey: string]: string } } }
  )

  const tagsReady = !!tags
  const initialTagsMap = useMemo(
    () =>
      initialTags.current?.reduce(
        (acc, { categoryKey, categoryName, tagNames }) => ({
          ...acc,
          [categoryKey]: {
            categoryName,
            tagNames: tagNames.reduce((acc, { tagKey, tagName }) => ({ ...acc, [tagKey]: tagName }), {} as { [tagKey: string]: string })
          }
        }),
        {} as { [categoryKey: string]: { categoryName: string; tagNames: { [tagKey: string]: string } } }
      ),
    // trigger update on initialTags set
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsReady]
  )

  const flatTags = tags ? ungroupTags(tags) : []
  const flatInitialTags = useMemo(
    () =>
      Object.keys(initialTagsMap || {}).flatMap((categoryKey) =>
        Object.keys(initialTagsMap?.[categoryKey]?.tagNames || {}).map((tagKey) => ({
          categoryKey,
          tagKey
        }))
      ),
    [initialTagsMap]
  )

  const getRemovals = useCallback(
    (tags: GroupedTag[]) => {
      return flatInitialTags.filter(({ categoryKey: initialCategoryKey, tagKey: initialTagKey }) => {
        const foundCategory = tags.find(({ categoryKey }) => initialCategoryKey === categoryKey)
        return !foundCategory || !foundCategory.tagNames.find((tagData) => tagData.tagKey === initialTagKey)
      })
    },
    [flatInitialTags]
  )

  const creations = flatTags.filter(
    ({ categoryKey, tagKey }) => !(categoryKey in (initialTagsMap || {})) || !(tagKey in (initialTagsMap?.[categoryKey]?.tagNames || {}))
  )

  const { categoriesRenames, tagsRenames } = flatInitialTags.reduce(
    (acc, { categoryKey, tagKey }) => {
      if (tagsMap && initialTagsMap) {
        const initialCategory = initialTagsMap[categoryKey]
        const updatedCategory = tagsMap[categoryKey]

        const categoryRenamed = categoryKey in tagsMap && initialCategory?.categoryName !== updatedCategory?.categoryName
        if (categoryRenamed && !acc.categoriesRenames.includes(categoryKey)) {
          acc.categoriesRenames.push(categoryKey)
        }

        const tagRenamed = tagKey in (updatedCategory?.tagNames || {}) && initialCategory?.tagNames?.[tagKey] !== updatedCategory.tagNames[tagKey]
        if (tagRenamed) {
          acc.tagsRenames.push({
            categoryKey,
            tagKey,
            oldTagName: initialCategory?.tagNames?.[tagKey],
            newTagName: updatedCategory.tagNames[tagKey]
          })
        }
      }
      return acc
    },
    { categoriesRenames: [], tagsRenames: [] } as { categoriesRenames: string[]; tagsRenames: RenamedTagType[] }
  )

  const isDirty = !_.isEqual(initialTagsMap, tagsMap)
  const isValid =
    !duplicatedCategories.length &&
    !tags?.find(({ categoryName, tagNames }) => !categoryName || !tagNames.length || !!tagNames.find(({ isDuplicated }) => isDuplicated))

  useEffect(() => {
    if (!loading) {
      const groppedTags = groupTags(items.map(({ category: categoryName, name: tagName }) => ({ categoryName, tagName })))
      const modifiedTags = groppedTags.map(({ tagNames, ...categoryData }) => ({
        ...categoryData,
        tagNames: tagNames.map((tagName) => ({ ...tagName, isDuplicated: false }))
      }))
      initialTags.current = modifiedTags
      setTags(modifiedTags)
    }
  }, [items, loading])

  const removeCategory = useCallback((removeCategoryKey: string) => {
    setTags((prevState = []) => {
      return prevState.filter(({ categoryKey }) => removeCategoryKey !== categoryKey)
    })
  }, [])

  const checkWhetherCategoryMatchRemoved = useCallback(
    (tags: GroupedTag[], categoryName: string) => {
      return getRemovals(tags).find(({ categoryKey }) => categoryKey.trim().toLowerCase() === categoryName.trim().toLowerCase())
    },
    [getRemovals]
  )

  const checkWhetherTagMatchRemoved = useCallback(
    (tags: GroupedTag[], categoryKey: string, tagName: string) => {
      return getRemovals(tags).find((removed) => removed.categoryKey === categoryKey && removed.tagKey === tagName)
    },
    [getRemovals]
  )

  const editCategory = useCallback(
    (categoryKey: string, newCategoryName: string) => {
      setTags((prevState = []) => {
        const isNewCategoryMatchRemoved = checkWhetherCategoryMatchRemoved(prevState, newCategoryName)
        const foundCategoryIndex = prevState.findIndex((pState) => pState.categoryKey === categoryKey)
        const cKey = isNewCategoryMatchRemoved ? newCategoryName : categoryKey

        return [
          ...prevState.slice(0, foundCategoryIndex),
          {
            ...prevState[foundCategoryIndex],
            categoryKey: cKey,
            categoryName: newCategoryName,
            tagNames: prevState[foundCategoryIndex].tagNames.map(({ tagKey, tagName, isDuplicated }) => {
              const isTagNameMatchRemoved = checkWhetherTagMatchRemoved(prevState, cKey, tagName)
              return {
                tagKey: isTagNameMatchRemoved ? tagName : tagKey,
                tagName,
                isDuplicated
              }
            })
          },
          ...prevState.slice(foundCategoryIndex + 1)
        ]
      })
    },
    [checkWhetherCategoryMatchRemoved, checkWhetherTagMatchRemoved]
  )

  const createCategoryTagPair = () => {
    setTags((prevState = []) => [...prevState, { categoryKey: uuid(), categoryName: '', isDuplicated: false, tagNames: [] }])
  }

  const removeTag = useCallback((categoryKey: string, tagKey: string) => {
    setTags((prevState = []) => {
      const foundCategoryIndex = prevState.findIndex((pState) => pState.categoryKey === categoryKey)
      return [
        ...prevState.slice(0, foundCategoryIndex),
        { ...prevState[foundCategoryIndex], tagNames: prevState[foundCategoryIndex].tagNames.filter((pTagName) => pTagName.tagKey !== tagKey) },
        ...prevState.slice(foundCategoryIndex + 1)
      ]
    })
  }, [])

  const editTag = useCallback(
    (categoryKey: string, tagKey: string, newTagName: string) => {
      setTags((prevState = []) => {
        const isNewTagNameMatchRemoved = checkWhetherTagMatchRemoved(prevState, categoryKey, newTagName)
        const foundCategoryIndex = prevState.findIndex((pState) => pState.categoryKey === categoryKey)
        const { tagNames } = prevState[foundCategoryIndex]
        const foundTagIndex = tagNames.findIndex((pState) => pState.tagKey === tagKey)
        return [
          ...prevState.slice(0, foundCategoryIndex),
          {
            ...prevState[foundCategoryIndex],
            tagNames: [
              ...tagNames.slice(0, foundTagIndex),
              {
                ...tagNames[foundTagIndex],
                tagKey: isNewTagNameMatchRemoved ? newTagName : tagKey,
                tagName: newTagName,
                isDuplicated: !!prevState[foundCategoryIndex].tagNames.find(
                  ({ tagName }) => newTagName.trim().toLowerCase() === tagName.trim().toLowerCase()
                )
              },
              ...tagNames.slice(foundTagIndex + 1)
            ]
          },
          ...prevState.slice(foundCategoryIndex + 1)
        ]
      })
    },
    [checkWhetherTagMatchRemoved]
  )

  const addTag = useCallback(
    (categoryKey: string, newTagName: string) => {
      setTags((prevState = []) => {
        const isNewTagNameMatchRemoved = checkWhetherTagMatchRemoved(prevState, categoryKey, newTagName)
        const foundCategoryIndex = prevState.findIndex((pState) => pState.categoryKey === categoryKey)
        const { tagNames } = prevState[foundCategoryIndex]
        return [
          ...prevState.slice(0, foundCategoryIndex),
          {
            ...prevState[foundCategoryIndex],
            tagNames: [...tagNames, { tagKey: isNewTagNameMatchRemoved ? newTagName : uuid(), tagName: newTagName, isDuplicated: false }]
          },
          ...prevState.slice(foundCategoryIndex + 1)
        ]
      })
    },
    [checkWhetherTagMatchRemoved]
  )

  const resetChanges = () => {
    setTags(initialTags.current)
  }

  const saveTags = async (taggableType: keyof typeof TaggableType) => {
    if (tags && tagsMap && initialTagsMap) {
      setIsSaving(true)

      const payload = {
        categoriesRenames: categoriesRenames.map((categoryKey) => ({
          oldCategoryName: initialTagsMap[categoryKey].categoryName,
          newCategoryName: tagsMap[categoryKey].categoryName
        })),
        tagsRenames: tagsRenames.map(({ categoryKey, oldTagName, newTagName }) => ({
          oldCategoryName: initialTagsMap[categoryKey].categoryName,
          oldTagName,
          newTagName
        })),
        deprecations: initialTagsMap
          ? getRemovals(tags).map(({ categoryKey, tagKey }) => ({
              categoryName: initialTagsMap[categoryKey].categoryName,
              tagName: initialTagsMap[categoryKey].tagNames[tagKey]
            }))
          : []
      }

      await post('/tags/admintagset', {
        creations: creations.map(({ categoryKey, tagKey }) => ({
          categoryName: tagsMap[categoryKey].categoryName,
          tagName: tagsMap[categoryKey].tagNames[tagKey],
          taggableType: TaggableType[taggableType]
        })),
        renames: initialTagsMap
          ? [
              ...categoriesRenames.map((categoryKey) => ({
                oldCategoryName: initialTagsMap[categoryKey].categoryName,
                newCategoryName: tagsMap[categoryKey].categoryName,
                oldTagName: null,
                newTagName: null,
                taggableType: TaggableType[taggableType]
              })),
              ...tagsRenames.map(({ categoryKey, oldTagName, newTagName }) => ({
                oldCategoryName: tagsMap[categoryKey].categoryName,
                newCategoryName: tagsMap[categoryKey].categoryName,
                oldTagName,
                newTagName,
                taggableType: TaggableType[taggableType]
              }))
            ]
          : [],
        deprecations: payload.deprecations.map((deprecation) => ({ ...deprecation, taggableType: TaggableType[taggableType] }))
      })
      updateParent({ action: 'SYNC_TAGS', value: { ...payload, taggableType } })
      setIsSaving(false)
    }
  }

  const grouppedRenamesAcc: {
    categoryKey: string
    categoryName: string | ReactElement
    tagNames: { tagKey: string; tagName: string | ReactElement }[]
  }[] =
    initialTagsMap && tagsMap
      ? categoriesRenames.map((categoryKey) => ({
          categoryKey,
          categoryName: (
            <RenamedCategory oldCategoryName={initialTagsMap[categoryKey].categoryName} newCategoryName={tagsMap[categoryKey].categoryName} />
          ),
          tagNames: []
        }))
      : []

  const grouppedRenames = tagsMap
    ? tagsRenames.reduce((acc, curr: RenamedTagType) => {
        const { categoryKey, tagKey, oldTagName, newTagName } = curr

        const categorizedTag = acc.find((obj) => obj.categoryKey === categoryKey)
        const isTagNameRenamed = oldTagName !== newTagName

        if (categorizedTag) {
          if (isTagNameRenamed) {
            categorizedTag.tagNames.push({
              tagKey,
              tagName: <RenamedTag oldTagName={oldTagName} newTagName={newTagName} />
            })
          }
        } else {
          acc.push({
            categoryKey,
            categoryName: tagsMap[categoryKey].categoryName,
            tagNames: isTagNameRenamed
              ? [
                  {
                    tagKey,
                    tagName: <RenamedTag oldTagName={oldTagName} newTagName={newTagName} />
                  }
                ]
              : []
          })
        }

        return acc
      }, grouppedRenamesAcc)
    : []

  return {
    tags,
    initialTagsMap,
    creations: tagsMap
      ? groupTags(
          creations.map(({ categoryKey, tagKey }) => {
            const { categoryName, tagNames } = tagsMap[categoryKey]
            return { categoryName: categoryName, tagName: tagNames[tagKey] }
          })
        )
      : [],
    removals: tags
      ? groupTags(
          getRemovals(tags).map(({ categoryKey, tagKey }) => {
            const { categoryName = '', tagNames = {} } = initialTagsMap?.[categoryKey] || {}
            return { categoryName: categoryName, tagName: tagNames[tagKey] }
          })
        )
      : [],
    renames: grouppedRenames,
    duplicatedCategories,
    isDirty,
    isValid,
    isSaving,
    saveTags,
    removeCategory,
    editCategory,
    createCategoryTagPair,
    removeTag,
    editTag,
    addTag,
    resetChanges
  }
}

export default useTagsManager
