import { useEffect, useRef, useState } from 'react'

import { PersonOption } from '_core/components/introductions/options'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { ActiveDirLookup } from '_core/hooks/useLookup'
import { useTeamList } from '_core/hooks/useTeam'

type Access = {
  canRead: boolean
  canWrite: boolean
}

export type Team = { teamNumber: number; teamName: string; teamMembers: number } & Access
export type Colleague = PersonOption & Access

const useCollaboratorsAccess = (
  colleagues: IntroductionRequestResp['appUserAccesses'],
  teams: IntroductionRequestResp['teamAccesses'],
  fetching: boolean
) => {
  const [appUserAccesses, setAppUserAccesses] = useState<Colleague[]>()
  const [teamAccesses, setTeamAccesses] = useState<Team[]>()
  const initUserAccesses = useRef<string[]>([])
  const initTeamsAccesses = useRef<number[]>([])
  const { fetchWithAbort } = useAbortableFetch<ActiveDirLookup[]>()
  const { getTeamsList } = useTeamList()
  const colleaguesKeys = colleagues?.map((user) => user.dotAppUserKey)
  const teamsKeys = teams?.map((team) => team.teamNumber)

  useEffect(() => {
    if (colleaguesKeys && !appUserAccesses && !fetching) {
      if (colleaguesKeys.length) {
        initUserAccesses.current = colleaguesKeys
        const urls = colleaguesKeys.map((key: string) => ({ url: `/teams/principals?searchTerm=${key}` }))
        ;(async () => {
          const res = await fetchWithAbort(urls)
          if (res) {
            const fullData = res.flatMap((r) => r.users)
            const transformed = colleagues.map((user) => ({
              email: user.dotAppUserKey,
              name: fullData.find((person) => person.emailAddress.toLowerCase() === user.dotAppUserKey.toLowerCase())?.displayName || '',
              canRead: user.canRead,
              canWrite: user.canWrite
            }))
            setAppUserAccesses(transformed)
          }
        })()
      } else {
        setAppUserAccesses([])
      }
    }
  }, [colleaguesKeys?.length, appUserAccesses?.length, fetching])

  useEffect(() => {
    if (teamsKeys && !teamAccesses && !fetching) {
      if (teamsKeys.length) {
        initTeamsAccesses.current = teamsKeys
        ;(async () => {
          const fullData = await getTeamsList()
          if (fullData) {
            const transformed = teams.map((team) => {
              const current = fullData.find((item) => item.id === team.teamNumber)
              return {
                teamNumber: team.teamNumber,
                teamName: current?.name || '',
                teamMembers: current?.membersTotal || 0,
                canRead: team.canRead,
                canWrite: team.canWrite
              }
            })
            setTeamAccesses(transformed)
          }
        })()
      } else {
        setTeamAccesses([])
      }
    }
  }, [teamsKeys?.length, teamAccesses?.length, fetching])

  return {
    teamAccesses,
    appUserAccesses,
    setTeamAccesses,
    setAppUserAccesses,
    initUserAccesses,
    initTeamsAccesses
  }
}

export default useCollaboratorsAccess
