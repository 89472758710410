import React, { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'

import Paths from 'Paths'

const TouchpointRelations = (
  stats: Partial<StatsType<{ CompanyMd5: string; UserKeyMd5?: string } | { ContactMd5: string }>>,
  key: 'First' | 'Last' | 'NextFuture'
) =>
  stats &&
  (
    [
      stats[`${key}Meeting`] && {
        title: 'Meeting',
        date: stats[`${key}Meeting`],
        description: (
          <>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
            <NameLink url={`${Paths._people}/${stats[`${key}MeetingUserKeyMd5`]}`} name={stats[`${key}MeetingUserName`] || ''} />
            <NameLink
              url={`${Paths._people}/${stats[`${key}MeetingContactMd5`]}`}
              name={stats[`${key}MeetingContactName`] || ''}
              preposition={`${key === 'NextFuture' ? 'is meeting' : 'met'}`}
            />
          </>
        ),
        blurb: moment(stats[`${key}Meeting`]).format('DD MMMM YYYY')
      },
      key !== 'NextFuture' &&
        stats[`${key}InboundMsg`] && {
          title: 'Message received',
          date: stats[`${key}InboundMsg`],
          description: (
            <>
              <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink url={`${Paths._people}/${stats[`${key}InboundMsgUserKeyMd5`]}`} name={stats?.[`${key}InboundMsgUserName`] || ''} />
              <NameLink
                url={`${Paths._people}/${stats[`${key}InboundMsgContactMd5`]}`}
                name={stats?.[`${key}InboundMsgContactName`] || ''}
                preposition="from"
              />
            </>
          ),
          blurb: moment(stats[`${key}InboundMsg`]).format('DD MMMM YYYY')
        },
      key !== 'NextFuture' &&
        stats[`${key}OutboundMsg`] && {
          title: 'Message sent',
          date: stats[`${key}OutboundMsg`],
          description: (
            <>
              <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink url={`${Paths._people}/${stats[`${key}OutboundMsgUserKeyMd5`]}`} name={stats?.[`${key}OutboundMsgUserName`] || ''} />
              <NameLink
                url={`${Paths._people}/${stats[`${key}OutboundMsgContactMd5`]}`}
                name={stats?.[`${key}OutboundMsgContactName`] || ''}
                preposition="to"
              />
            </>
          ),
          blurb: moment(stats[`${key}OutboundMsg`]).format('DD MMMM YYYY')
        }
    ].filter((key) => key) as { title: string; date: string; description: ReactElement; blurb: string }[]
  ).sort((a, b) => ((key === 'Last' ? a.date < b.date : a.date > b.date) ? 1 : -1))

export default TouchpointRelations
