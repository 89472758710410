import { Button } from '_shared/buttons'
import IconButton, { IconButtonProps } from '_shared/buttons/Icon'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

type AuditValidDialogProps = {
  data: AuditIdentifier | null
  submit: (data: AuditIdentifier) => void
  close: () => void
}

const TriggerEl = ({ open, classes }: { open: () => void; classes: IconButtonProps['classes'] }) => (
  <IconButton classes={classes} disablePR onClick={open} icon={['far', 'times']} hint="Mark current identifier as valid" />
)

const AuditValidDialog = (props: AuditValidDialogProps) => {
  const { data, submit, close } = props

  const submitValid = () => {
    if (!data) return
    submit(data)
  }

  return (
    <Dialog open={!!data} onClose={close} title={<DialogTitle title={data?.text || ''} titleOverflow="ellipsis" />}>
      <DialogContent>
        <Typography>Mark {data?.text} as valid?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close}>
          No
        </Button>
        <Button variant="text" onClick={submitValid} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AuditValidDialog.TriggerEl = TriggerEl

export default AuditValidDialog
