import React, { useContext, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import { getBinary } from 'utils/httpUtils'
import { getLocal, triggerDownloadXl } from 'utils/Utils'

import { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(auto-fit, minmax(248px, 1fr))',
    alignItems: 'stretch',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    gap: theme.spacing(2)
  }
}))

const downloadMap: {
  [key in DataQualityEntities]: {
    url: string
    fileName: string
  }
} = {
  people: {
    url: '/dataquality/peopleriskreportxl',
    fileName: 'people-risk-report'
  },
  companies: {
    url: '/dataquality/companiesriskreportxl',
    fileName: 'companies-risk-report'
  }
}

const DownloadReport = ({ entity }: { entity: DataQualityEntities }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const teamNumber = teamContextValue.teamNumber.toString()
  const { url, fileName } = downloadMap[entity]

  const download = async () => {
    setLoading(true)
    enqueueSnackbar('Download should begin shortly.', {
      variant: 'default',
      autoHideDuration: 4000
    })

    try {
      const report = await getBinary(url, { teamNumber })
      if (report) {
        const currentDateAndTime = getLocal().format('YYYYMMDD-hhmmss')
        triggerDownloadXl(report, `${fileName}-${currentDateAndTime}`)
      } else {
        setTimeout(() => {
          enqueueSnackbar('No file found', {
            variant: 'default'
          })
        }, 5000)
      }
    } catch (error) {
      enqueueSnackbar('Encountered error during downloading', {
        variant: 'error'
      })
      console.log('error during download', error)
    }
    setLoading(false)
  }

  return (
    <Button
      onClick={download}
      disabled={loading}
      variant="widget"
      color="primary"
      startIcon={<FontAwesomeIcon icon={['far', 'file-download']} style={{ fontSize: 14 }} />}
    >
      Download report
    </Button>
  )
}

const UploadReport = ({ link }: { link: string }) => {
  return (
    <SidepanelLink sidepanel={true} linkProps={{ to: link }}>
      <Button variant="widget" color="primary" startIcon={<FontAwesomeIcon icon={['far', 'cloud-arrow-up']} style={{ fontSize: 14 }} />} fullWidth>
        Upload fixes
      </Button>
    </SidepanelLink>
  )
}

const baseUrl = DataAdminSubPaths.dataQuality
const items: {
  label: string
  icon: IconProp
  entity: DataQualityEntities
  description: string
}[] = [
  {
    label: 'People',
    icon: ['far', 'address-book'],
    description: 'Manage people related entity alignment issues.',
    entity: 'people'
  },
  {
    label: 'Companies',
    icon: ['far', 'building'],
    description: 'Manage companies related entity alignment issues.',
    entity: 'companies'
  }
]

const DataQualityActions = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      {items.map((item) => (
        <Widget scope="outlined" key={item.label}>
          <Box p={2}>
            <Heading title={item.label} icon={item.icon} underlined />
            {item.description && <Typography color="text.secondary">{item.description}</Typography>}
            <Box className={classes.buttons}>
              <DownloadReport entity={item.entity} />
              <UploadReport link={`${baseUrl}/${item.entity}/upload`} />
            </Box>
          </Box>
        </Widget>
      ))}
    </Box>
  )
}

export default DataQualityActions
