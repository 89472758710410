import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Empty from '_core/components/Empty'

const useStyles = makeStyles()(() => ({
  content: {
    maxWidth: 540,
    margin: '0 auto'
  }
}))

const FeatureNoAccess = () => {
  const { classes } = useStyles()

  const reachTeamBtn = (
    <Button<'a'> href={`mailto:team@dotalign.com`} disablePadding component="a" variant="link" bold={false}>
      team@dotalign.com
    </Button>
  )

  return (
    <Empty
      className={classes.content}
      icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
      title="The feature is not enabled"
      subTitle={<>Please reach out to {reachTeamBtn} to get further assistance.</>}
    />
  )
}

export default FeatureNoAccess
