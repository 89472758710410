import React, { useContext, useEffect, useState } from 'react'

import DataSources, { extractDataSourceStats } from '_pages/data-sources/DataSources'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { get } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const DataSourcesPage = () => {
  const [userKey, setUserKey] = useState<string>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { queryParams } = useSearchQuery<any>()

  setMobileHeader('Data Sources')

  useEffect(() => {
    get<ProfileType>('/me/profile').then((resp) => {
      if (resp) {
        setUserKey(resp.UserKey)
      }
    })
  }, [])

  return <>{!!userKey && <DataSourcesHttpComponent url={`/users/${userKey}/processingstats`} initOpen={queryParams?.initOpen === 'true'} />}</>
}

export default DataSourcesPage

const DataSourcesHttpComponent = (props: { url: string; initOpen: boolean }) => {
  const [reload, setReload] = useState(false)
  const { result, loading } = useEntityEndpoint(!reload ? props.url : null)

  useEffect(() => {
    if (reload) {
      setReload(false)
    }
  }, [reload])

  return <DataSources data={extractDataSourceStats(result?.results)} initOpen={props.initOpen} loading={loading} reload={setReload} />
}
