import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FloatingButton from '_shared/buttons/FloatingButton'

import ReportIncorrectDataDialog from '_core/components/dialogs/ReportIncorrectMarketData'

import useDialog from '_core/hooks/useDialog'

const ReportIncorrectData = ({ marketData }: { marketData: string }) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)

  const handleDialogOpen = () => {
    openDialog(true)
  }

  return (
    <>
      <FloatingButton onClick={handleDialogOpen}>
        <FontAwesomeIcon icon={['far', 'message-exclamation']} size="lg" />
      </FloatingButton>
      <ReportIncorrectDataDialog opened={isDialogOpened} close={closeDialog} marketData={JSON.stringify(marketData)} />
    </>
  )
}

export default ReportIncorrectData
