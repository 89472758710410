import React, { useContext, useEffect } from 'react'

import { Redirect, useHistory } from 'react-router-dom'

import RequestForm, { defaultReason } from '_core/components/introductions/RequestForm'

import { ReasonType } from '_core/hooks/useIntroductionReason'
import { IntroductionRequest } from '_core/hooks/useIntroductionRequestForm'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { put } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export type EditRequestProps = {
  request: IntroductionRequestResp
  reasons: ReasonType[]
  loading: boolean
}

const EditRequestForm = ({ reasons, ...props }: EditRequestProps) => {
  const plan = props.request?.plan || {}
  const { setMobileHeader } = useContext(LayoutContext)
  const { updateParent } = useSidepanelPayloads()
  const history = useHistory()
  const { queryParams } = useSearchQuery<{ nextStep: string }>()

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  const request = {
    planUid: plan.planUid,
    summary: plan.planSummary,
    description: plan.planDescription || '',
    reason: reasons?.find((reason) => reason.value === plan.reason) ?? defaultReason,
    requester: {
      name: plan.requesterName,
      email: plan.requesterEmail,
      md5: plan.requesterMd5
    },
    beneficiaryCompany: {
      name: plan.beneficiaryCompanyName,
      website: plan.beneficiaryCompanyUrl,
      md5: plan.beneficiaryCompanyMd5
    },
    beneficiaryPerson: {
      name: plan.beneficiaryPersonName,
      email: plan.beneficiaryPersonEmail,
      md5: plan.beneficiaryPersonMd5
    }
  }

  if (plan.planUid && !props.request.querierHasWriteAccess) {
    return <Redirect to={`${Paths._introductions}/${plan.planUid}`} />
  }

  const handleSuccess = (res: IntroductionPlanResp) => {
    const beneficiaryPrevType = plan.beneficiaryCompanyUrl ? 'company' : 'person'
    const beneficiaryUpdatedType = res.beneficiaryCompanyUrl ? 'company' : 'person'
    const companyBeneficiaryChanged =
      beneficiaryPrevType === 'company' && beneficiaryUpdatedType === 'company' && plan.beneficiaryCompanyUrl !== res.beneficiaryCompanyUrl
    const personBeneficiaryChanged =
      beneficiaryPrevType === 'person' && beneficiaryUpdatedType === 'person' && plan.beneficiaryPersonEmail !== res.beneficiaryPersonEmail

    if (plan.planSummary !== res.planSummary) {
      updateParent({ action: 'UPDATE_INTRODUCTION_DETAILS', value: { planUid: res.planUid, fieldName: 'summary', value: res.planSummary } })
    }

    if (plan.requesterEmail !== res.requesterEmail) {
      updateParent({
        action: 'UPDATE_INTRODUCTION_DETAILS',
        value: {
          planUid: res.planUid,
          fieldName: 'requester',
          value: { name: res.requesterName, email: res.requesterEmail, link: `${Paths._people}/${res.requesterMd5}` }
        }
      })
    }

    if (companyBeneficiaryChanged || personBeneficiaryChanged || beneficiaryPrevType !== beneficiaryUpdatedType) {
      const updatedValue = {
        name: beneficiaryUpdatedType === 'company' ? res.beneficiaryCompanyName : res.beneficiaryPersonName,
        url: beneficiaryUpdatedType === 'company' ? res.beneficiaryCompanyUrl : res.beneficiaryPersonEmail,
        link:
          beneficiaryUpdatedType === 'company' ? `${Paths._companies}/${res.beneficiaryCompanyMd5}` : `${Paths._people}/${res.beneficiaryPersonMd5}`
      }
      updateParent({
        action: 'UPDATE_INTRODUCTION_DETAILS',
        value: {
          planUid: res.planUid,
          fieldName: 'beneficiary',
          value: updatedValue
        }
      })
    }
    history.push(queryParams.nextStep || `${Paths._introductions}/${res.planUid}`)
  }

  const save = async (formValues: IntroductionRequest) => {
    const { requester, summary, description, reason, beneficiaryPerson, beneficiaryCompany, ...data } = formValues
    const res = await put<IntroductionPlanResp>('/prospecting/editplan', {
      planSummary: summary,
      planDescription: description,
      reason: reason.value,
      requesterEmail: requester.email,
      requesterName: requester.name,
      requesterMd5: requester.md5,
      beneficiaryCompanyUrl: beneficiaryCompany.website,
      beneficiaryCompanyName: beneficiaryCompany.name,
      beneficiaryCompanyMd5: beneficiaryCompany.md5,
      beneficiaryPersonEmail: beneficiaryPerson.email,
      beneficiaryPersonName: beneficiaryPerson.name,
      beneficiaryPersonMd5: beneficiaryPerson.md5,
      setBeneficiaryPersonToNull: Boolean(plan.beneficiaryPersonName && !beneficiaryPerson.name),
      setBeneficiaryCompanyToNull: Boolean(plan.beneficiaryCompanyName && !beneficiaryCompany.name),
      setDescriptionToNull: Boolean(plan.planDescription && !description),
      ...data
    })

    if (res) {
      handleSuccess(res)
    }
  }

  return (
    <RequestForm
      request={plan.planUid ? request : undefined}
      loading={props.loading}
      save={save}
      actionLabel={queryParams.nextStep ? 'Next' : 'Save'}
    />
  )
}

export default EditRequestForm
