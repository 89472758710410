import { useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { transformResult } from '_core/hooks/useActiveDirectoryPersonData'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { put } from 'utils/httpUtils'

import Paths from 'Paths'

const initState = {
  displayName: '',
  emailAddress: ''
}

export type AssigneeType = typeof initState

const useAssignee = (planUid: string, assigneeEmail?: string) => {
  const [loading, setLoading] = useState(false)
  const [assignee, setAssignee] = useState(initState)
  const { fetchWithAbort } = useAbortableFetch<{ users: { [key: string]: any }[] }>()
  const { updateParent } = useSidepanelPayloads()

  useEffect(() => {
    if (!assigneeEmail && !planUid && assignee.displayName) {
      setAssignee(initState)
    }
  }, [assigneeEmail, assignee.displayName, planUid])

  const fetchAssigneeDetails = async (email: string) => {
    // assignee may not be in the managers list
    // (e.g. manager has been assigned but then removed from the list but he still an assignee)
    // use the same search endpoint as for add managers to the list (admin setting)
    const res = await fetchWithAbort({ url: `/teams/principals?searchTerm=${email}` })
    if (res) {
      const details = transformResult(res.users)
      setAssignee(details)
    }
  }

  useEffect(() => {
    if (assigneeEmail) {
      ;(async () => {
        setLoading(true)
        await fetchAssigneeDetails(assigneeEmail)
        setLoading(false)
      })()
    }
  }, [assigneeEmail])

  const assign = async () => {
    setLoading(true)
    await put<IntroductionPlanResp>('/prospecting/editplan', {
      planUid,
      assigneeEmail: assignee.emailAddress
    })
    updateParent({
      action: 'UPDATE_INTRODUCTION_DETAILS',
      value: {
        planUid,
        fieldName: 'assignee',
        value: {
          name: assignee.displayName,
          email: assignee.emailAddress,
          link: `${Paths._people}/${assignee.emailAddress}`
        }
      }
    })
    setLoading(false)
  }

  const removeAssignee = async () => {
    setLoading(true)
    await put<IntroductionPlanResp>('/prospecting/editplan', {
      planUid,
      setAssigneeToNull: true
    })
    updateParent({
      action: 'UPDATE_INTRODUCTION_DETAILS',
      value: {
        planUid,
        fieldName: 'assignee',
        value: {
          name: '',
          email: '',
          link: ''
        }
      }
    })
    setLoading(false)
  }

  return {
    assignee,
    setAssignee,
    loading,
    assign,
    removeAssignee
  }
}

export default useAssignee
