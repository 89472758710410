import React, { useState } from 'react'

import { Box, Popover } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import { DisablePaddingType } from '_shared/buttons/Button'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  popover: {
    padding: '2px 4px',
    overflow: 'hidden'
  },
  icon: {
    fontSize: 13
  }
}))

const CopyButton = ({ text, ...disablePaddings }: { text: string } & { [key in DisablePaddingType]?: boolean }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    classes: { popover, icon }
  } = useStyles()

  const copyToClipboard = (event: any) => {
    setAnchorEl(event.currentTarget)
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setTimeout(handleClose, 1000)
        })
        .catch((err) => console.log(err))
    } else {
      console.log('navigator.clipboard is undefined')
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'text-is-copied-to-clipboard' : undefined

  return (
    <Box display="inline-block">
      <IconButton
        {...disablePaddings}
        onClick={copyToClipboard}
        hint="Copy to clipboard"
        icon={['fal', 'copy']}
        classes={{ root: icon }}
        size="small"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography className={popover} color="success.main" variant="h5" semiBold>
          Copied!
        </Typography>
      </Popover>
    </Box>
  )
}

export default CopyButton
