import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Page from '_shared/Page'

import ReportRequestForm from '_core/components/introductions/ReportRequestForm'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useProspectingManager from '_core/hooks/useProspectingManager'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const ReportRequestPage = () => {
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const isManager = useProspectingManager()
  const history = useHistory()

  useEffect(() => {
    setMobileHeader('Report request')
  }, [setMobileHeader])

  useEffect(() => {
    setSubHeader('New')
  }, [setSubHeader])

  if (isManager === false) {
    history.replace(`${Paths._introductions}`)
  }

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <ReportRequestForm loading={!isManager} />
      </Narrow>
    </Page>
  )
}

export default ReportRequestPage
