import { useEffect, useState, ReactElement, ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { evtsIncludeOptions } from '_core/context/ParamsValidation'

import AvatarGroup from '_shared/AvatarGroup'
import { Button } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'

import { Repeated, UpdateStatusPopup, RemovePopup } from '_core/components/dialogs/EventConfirm'
import Empty from '_core/components/Empty'
import { Main, IconsBlock, TimeMessage } from '_core/components/Event'
import Heading from '_core/components/Heading'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { InternalTagPopover, ShowAllTagsLink, AddTagTriggerEl, ExtraTagsPopover } from '_core/components/InternalTag'
import { useWide, Column, Columns } from '_core/components/layout'
import Timeline, { TimeContainer, TimelineList } from '_core/components/lists/Timeline'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import Widget from '_core/components/Widget'

import useEventTimes, { EventTimeType } from '_core/hooks/useEventTimes'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanel from '_core/hooks/useSidepanel'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import { groupTags } from '_core/hooks/useTagsManager'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { formatTime, getLocal, formatDate } from 'utils/Utils'

import Paths from 'Paths'

const today = getLocal()

const useStyles = makeStyles<{ shiftSeparatorDown?: boolean; cropTimeline?: boolean } | void>()((theme, props) => ({
  allDayCard: {
    '& .MuiCardContent-root': {
      paddingRight: 0,
      paddingLeft: 0,
      '& > .MuiBox-root:last-child > .MuiBox-root:not(:last-child)': {
        paddingBottom: theme.spacing(1)
      },
      '& > .MuiBox-root:last-child > .MuiBox-root:not(:first-of-type)': {
        borderTop: '1px solid #ECEEF0'
      }
    }
  },
  allDayTimeline: {
    marginBottom: `${theme.spacing(2)} !important`,
    '& .MuiCardContent-root': {
      paddingTop: theme.spacing(2)
    },
    '& .MuiTimelineItem-root': {
      '&:last-child': {
        marginBottom: 0,
        '& .MuiTimelineSeparator-root': {
          marginBottom: props?.shiftSeparatorDown ? -66 : -36
        },
        '& .MuiTimelineConnector-root': {
          display: props?.cropTimeline ? 'none' : 'block'
        }
      }
    }
  },
  selected: {
    background: theme.palette.hover.secondary
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiTypography-root:first-of-type': {
      marginTop: theme.spacing(0.25),
      fontSize: 17,
      lineHeight: '21px'
    }
  },
  icons: {
    marginTop: theme.spacing(1.5),
    '& div': {
      padding: 0
    }
  },
  timeRange: {
    position: 'absolute',
    paddingLeft: 49,
    width: 120,
    height: 20,
    left: 12
  },
  action: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  timeMessage: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1)
  }
}))

enum KeyName {
  seriesMasterKey = 'seriesMasterKey',
  sourceKey = 'sourceKey'
}

const AllDayEvent = (props: ModifiedEventListItem) => {
  const { classes, cx } = useStyles()
  const { openedUrl } = useSidepanel('preview')
  const selected = openedUrl.indexOf(`${Paths._events}/${props.sourceKey}`) > -1
  const matchPhoneWidth = useWide('phone')

  const isAppointment = props.participantsWithPeople?.length === 1 && props.organizer?.PersonMd5 === props.participantsWithPeople?.[0].PersonMd5
  const editStatusEnabled = !!(props.startsIn && props.startsIn > 0)

  const openRemovePopup = () => {
    props.openPopup('removeEvent', { item: props })
  }

  const openStatusPopup = (value: EventStatusKeys) => {
    props.openPopup('statusUpdate', { items: props, value })
  }

  return (
    <Box px={1} className={cx({ [classes.selected]: selected })}>
      <SidepanelLink linkProps={{ to: `${Paths._events}/${props.sourceKey}` }} sidepanel="preview">
        <Columns>
          <Column xs={matchPhoneWidth ? 8 : 12} md={8}>
            <Main
              className={classes.main}
              loading={false}
              subject={props.subject}
              location={props.location}
              onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
              organizer={props.organizer}
            />
          </Column>
          {matchPhoneWidth && (
            <Column xs={4} md={4}>
              <AvatarGroup
                alignRight
                max={3}
                skeleton={{ size: 3, loading: false }}
                hideName={true}
                sidepanel="preview"
                size="xs"
                seeAllLink={`${Paths._events}/${props.sourceKey}/participants`}
                items={props.participantsWithPeople?.map((item) => ({
                  name: item.PersonNameText,
                  userKey: item.BestEmailAddrText,
                  link: item.link
                }))}
              />
              <Box mt={1.25}>
                <AvatarGroup
                  alignRight
                  max={3}
                  skeleton={{ size: 3, loading: false }}
                  hideName={true}
                  sidepanel="preview"
                  size="xs"
                  seeAllLink={`${Paths._events}/${props.sourceKey}/companies`}
                  items={props.companies?.map((item: { [key: string]: string }) => ({
                    name: item.corpLevelCompanyName,
                    userKey: item.companyMd5,
                    logoUrl: item.matchedUrlText,
                    link: item.link
                  }))}
                />
              </Box>
            </Column>
          )}
        </Columns>
        {!!props.tags?.length && (
          <Box mt={1}>
            <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
              items={props.tags || []}
              tagComponent={InternalTagPopover}
              renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                <ExtraTagsPopover
                  triggerElement={
                    <ShowAllTagsLink
                      sidepanel="preview"
                      extraTagsAmount={extraTagsAmount}
                      link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags`, {
                        name: props.subject,
                        appointmentUID: props.appointmentUID
                      })}
                    />
                  }
                  items={props.tags ? groupTags(props.tags) : []}
                />
              )}
              addTagTriggerEl={
                <AddTagTriggerEl
                  sidepanel="preview"
                  link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags/edit`, {
                    name: props.subject,
                    appointmentUID: props.appointmentUID
                  })}
                  hasAny={!!props.tags?.length}
                />
              }
              max={3}
            />
          </Box>
        )}
        <IconsBlock
          loading={!props.sourceKey}
          sourceKey={props.sourceKey}
          appointmentUID={props.appointmentUID}
          subject={props.subject}
          organizer={props.organizer}
          isAppointment={isAppointment}
          editStatusEnabled={editStatusEnabled}
          selectedStatus={props.status}
          participants={props.participants}
          onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
          openRemovePopup={openRemovePopup}
          openStatusPopup={openStatusPopup}
        />
      </SidepanelLink>
    </Box>
  )
}

type ModifiedEventListItem = Modify<
  EventListItem,
  {
    index: number
    companies: (EventListItem['companies'][number] & { link: string })[]
    joinUrl: EventListItem['onlineMeetingJoinUrl']
    link: string
    sidepanel: SidepanelType
    component: ReactElement
    openPopup: (kind: EventPopupKind, payloads: { [key: string]: any }) => void
  } & EventTimeType
>

const AllDay = (props: ModifiedEventListItem[]) => {
  const { classes } = useStyles()

  const items = Object.values(props)
  return (
    <Card className={classes.allDayCard} round variant="outlined">
      <CardContent>
        <Skeleton condition={!items[0]?.sourceKey} width={120} height={28}>
          <Box mb={3.5}>
            <TimeContainer className={classes.timeRange}>
              <span>All day</span>
            </TimeContainer>
          </Box>
        </Skeleton>
        <Box>
          {items.map((item) => (
            <AllDayEvent key={item.sourceKey} {...item} />
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

const Event = (props: Partial<ModifiedEventListItem>) => {
  const { openedUrl } = useSidepanel('preview')
  const { classes, cx } = useStyles()
  const matchPhoneWidth = useWide('phone')

  const isAppointment = props.participantsWithPeople?.length === 1 && props.organizer?.PersonMd5 === props.participantsWithPeople[0].PersonMd5
  const editStatusEnabled = !!(props.startsIn && props.startsIn > 0)
  const loading = !props.sourceKey

  const openRemovePopup = () => {
    if (props.openPopup) {
      props.openPopup('removeEvent', { item: props })
    }
  }

  const openStatusPopup = (value: EventStatusKeys) => {
    if (props.openPopup) {
      props.openPopup('statusUpdate', { item: props, value })
    }
  }

  const selected = openedUrl.indexOf(`${Paths._events}/${props.sourceKey}`) > -1

  return (
    <Card className={cx({ [classes.selected]: selected })} round variant="outlined">
      <CardContent>
        <TimeMessage
          isCancelled={props.isCancelled || false}
          startTimeUTC={props.startTimeUTC || ''}
          endTimeUTC={props.endTimeUTC || ''}
          className={classes.timeMessage}
        />
        <Columns spacing={0}>
          <Column xs={matchPhoneWidth ? 8 : 12} md={8}>
            <Skeleton condition={loading} width={120} height={28}>
              <Box mb={3.5}>
                <TimeContainer className={classes.timeRange}>
                  <span>{formatTime(props.startTimeUTC)}</span> - <span>{formatTime(props.endTimeUTC)}</span>
                </TimeContainer>
              </Box>
            </Skeleton>
            <Main
              className={classes.main}
              loading={loading}
              subject={props.subject}
              location={props.location}
              onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
              organizer={props.organizer}
            />
          </Column>
          {matchPhoneWidth && (
            <Column xs={4} md={4}>
              <AvatarGroup
                alignRight
                max={3}
                skeleton={{ size: 3, loading }}
                hideName={true}
                sidepanel="preview"
                size="xs"
                seeAllLink={`${Paths._events}/${props.sourceKey}/participants`}
                items={
                  props.participantsWithPeople?.map((item) => ({
                    name: item.PersonNameText,
                    userKey: item.BestEmailAddrText,
                    link: item.link
                  })) || []
                }
              />
              <Box mt={1.25}>
                <AvatarGroup
                  alignRight
                  max={3}
                  skeleton={{ size: 3, loading }}
                  hideName={true}
                  sidepanel="preview"
                  size="xs"
                  seeAllLink={`${Paths._events}/${props.sourceKey}/companies`}
                  items={
                    props.companies?.map((item) => ({
                      name: item.corpLevelCompanyName,
                      userKey: item.companyMd5,
                      logoUrl: item.matchedUrlText,
                      link: item.link
                    })) || []
                  }
                />
              </Box>
            </Column>
          )}
        </Columns>
        {!!props.tags?.length && (
          <Box mt={1}>
            <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
              items={props.tags || []}
              tagComponent={InternalTagPopover}
              renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                <ExtraTagsPopover
                  triggerElement={
                    <ShowAllTagsLink
                      sidepanel="preview"
                      extraTagsAmount={extraTagsAmount}
                      link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags`, {
                        name: props.subject,
                        appointmentUID: props.appointmentUID
                      })}
                    />
                  }
                  items={props.tags ? groupTags(props.tags) : []}
                />
              )}
              addTagTriggerEl={
                <AddTagTriggerEl
                  sidepanel="preview"
                  link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags/edit`, {
                    name: props.subject,
                    appointmentUID: props.appointmentUID
                  })}
                  hasAny={!!props.tags?.length}
                />
              }
              max={3}
            />
          </Box>
        )}
        <IconsBlock
          className={classes.icons}
          loading={loading}
          sourceKey={props.sourceKey}
          appointmentUID={props.appointmentUID}
          subject={props.subject}
          organizer={props.organizer}
          isAppointment={isAppointment}
          editStatusEnabled={editStatusEnabled}
          selectedStatus={props.status || 'None'}
          participants={props.participants}
          onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
          openRemovePopup={openRemovePopup}
          openStatusPopup={openStatusPopup}
        />
      </CardContent>
    </Card>
  )
}

export const TogglePast = ({ showPast, toggle, disabled }: { showPast: boolean; disabled: boolean; toggle: () => void }) => {
  return (
    <Button
      size="small"
      disabled={disabled}
      variant="link"
      color="primary"
      onClick={toggle}
      bold={false}
      disablePY
      endIcon={<FontAwesomeIcon icon={['far', showPast ? 'chevron-up' : 'chevron-down']} style={{ fontSize: 10 }} />}
    >
      {showPast ? 'Hide' : 'Show'} past
    </Button>
  )
}

export const TodayEmpty = (props: { total: number; items?: EventListItem[]; toggleHide: () => void; clearFilters: () => void }) => {
  const { queryParams, updateQuery } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked: IncludeEvents[] }] }>(['checked'])
  const { checked = [], includeTags, excludeTags } = queryParams
  const showPast = JSON.parse(queryParams.showPast || 'false')
  const { total, items, toggleHide, clearFilters } = props

  const viewTomorrows = () => {
    updateQuery({ date: formatDate(getLocal(today).add(1, 'days')) })
  }

  const hasUpcomping = !showPast && total && items && items?.length > 0
  const filtersApplied = (checked.length && checked.length !== evtsIncludeOptions.length) || includeTags || excludeTags

  return !hasUpcomping && !filtersApplied && total > 0 ? (
    <Empty
      title="Hurray! You're done with all your events for today"
      icon={<FontAwesomeIcon size="5x" icon={['fat', 'party-horn']} style={{ color: '#A7A7A7' }} />}
      action={
        <Button variant="link" onClick={toggleHide} color="primary" disablePT>
          view today&apos;s events
        </Button>
      }
      close={false}
    />
  ) : (
    <Empty
      title={!filtersApplied ? 'Lucky you! You have no events today' : 'No events matching your filters were found'}
      icon={<FontAwesomeIcon size="5x" icon={['fat', !filtersApplied ? 'thumbs-up' : 'calendar-clock']} style={{ color: '#A7A7A7' }} />}
      action={
        !filtersApplied ? (
          <Button variant="link" onClick={viewTomorrows} color="primary" disablePT>
            view tomorrow&apos;s events
          </Button>
        ) : (
          <Button variant="link" onClick={clearFilters} color="primary" disablePT>
            clear all filters
          </Button>
        )
      }
      close={false}
    />
  )
}

export const OtherDayEmpty = (props: { total: number; clearFilters: () => void }) => {
  const { queryParams, updateQuery } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked: IncludeEvents[] }] }>(['checked'])
  const { checked = [] } = queryParams
  const { total, clearFilters } = props

  const viewTodays = () => {
    updateQuery({ date: formatDate(today) })
  }

  const allChecked = !checked.length || checked.length === evtsIncludeOptions.length

  return (
    <Empty
      title={!total && allChecked ? 'No events were found for this day' : 'No events matching your filters were found'}
      icon={<FontAwesomeIcon size="5x" icon={['fat', 'calendar-clock']} style={{ color: '#A7A7A7' }} />}
      action={
        !total && allChecked ? (
          <Button variant="link" onClick={viewTodays} color="primary" disablePT>
            view today&apos;s events
          </Button>
        ) : (
          <Button variant="link" onClick={clearFilters} color="primary" disablePT>
            clear all filters
          </Button>
        )
      }
      close={false}
    />
  )
}

type EventsProps = {
  items: EventListItem[] | undefined
  update: (index: number, updated: Partial<EventListItem>) => void
  empty: ReactElement
  action?: ReactElement | null
}

const EventsList = (props: EventsProps) => {
  const wide = useWide()
  const { openedUrl, openUrl } = useSidepanel('preview')
  const { payloads, updateSidepanel } = useSidepanelPayloads('preview')
  const [open, setOpen] = useState<{ kind: EventPopupKind; payloads?: { [key: string]: any } }>()
  const [selectedStatus, setSelectedStatus] = useState<EventStatusKeys>()
  const [selectedRepeated, setSelectedRepeated] = useState<Repeated>(Repeated.one)
  const activeItem = open?.payloads?.item
  const cropTimeline = !props.items?.find((item) => !item.isAllDay)

  const eventTimes = useEventTimes(props.items)
  const { classes } = useStyles({ cropTimeline, shiftSeparatorDown: !cropTimeline && !!eventTimes?.find((item) => item.className) })
  const openPopup = (kind: EventPopupKind, payloads: { [key: string]: any }) => {
    setOpen({ kind, payloads })
  }

  const items = eventTimes?.map((item, index) => ({
    ...item,
    index,
    subject: item.subject || '(No subject)',
    companies: item.companies?.map((item) => ({ ...item, link: `${Paths._companies}/${item.companyMd5}` })),
    joinUrl: item.onlineMeetingJoinUrl,
    link: `${Paths._events}/${item.sourceKey}`,
    sidepanel: 'preview',
    component: 'div',
    openPopup
  }))

  useEffect(() => {
    reset()
  }, [openedUrl])

  useEffect(() => {
    if (!open?.kind) {
      reset()
    }
  }, [open?.kind])

  useEffect(() => {
    if (items && selectedStatus && activeItem) {
      const keyName = selectedRepeated === Repeated.all && activeItem.seriesMasterKey ? KeyName.seriesMasterKey : KeyName.sourceKey
      props.update(
        items.findIndex((item) => item[keyName] === activeItem[keyName]),
        { status: selectedStatus }
      )
      if (wide) {
        updateSidepanel({ action: 'UPDATE_STATUS', value: selectedStatus })
        if (selectedStatus === 'Declined') {
          openUrl('/blank')
        }
      }
    }
  }, [selectedStatus])

  useEffect(() => {
    if (payloads && payloads.action === 'OPEN_POPUP') {
      const { kind, data } = payloads.value
      openPopup(kind, data)
    }
  }, [payloads])

  const reset = () => {
    setSelectedStatus(undefined)
    setSelectedRepeated(Repeated.one)
  }

  const closePopup = () => {
    setOpen(undefined)
  }

  const updateStatus = (value: EventStatusKeys) => {
    openPopup('statusUpdate', { item: activeItem, value })
  }

  return (
    <>
      <Widget scope="none">
        <Heading
          underlined
          title="Events"
          action={
            <Box className={classes.action}>
              <InteractionsPrivacyMsg />
              <span>{props.action}</span>
            </Box>
          }
          icon={['far', 'calendar-alt']}
          count={!items ? -1 : items?.length}
        />

        {items && !items.length ? (
          props.empty
        ) : (
          <>
            {!!items?.filter((item) => item.isAllDay).length && (
              <Timeline className={classes.allDayTimeline} scope="widget" loading={!items}>
                <TimelineList
                  items={items ? [items.filter((item) => item.isAllDay)] : []}
                  skeleton={{ size: 1, loading: !items }}
                  component={AllDay}
                />
              </Timeline>
            )}
            <Timeline scope="widget" loading={!items}>
              <TimelineList items={items?.filter((item) => !item.isAllDay) || []} skeleton={{ size: 3, loading: !items }} component={Event} />
            </Timeline>
          </>
        )}
      </Widget>

      <UpdateStatusPopup
        isOpen={open?.kind === 'statusUpdate'}
        item={activeItem}
        repeated={selectedRepeated}
        status={open?.payloads?.value as EventStatusKeys}
        confirmStatus={setSelectedStatus}
        updateStatus={updateStatus}
        setRepeated={setSelectedRepeated}
        close={closePopup}
      />
      <RemovePopup isOpen={open?.kind === 'removeEvent'} item={activeItem} confirmStatus={setSelectedStatus} close={closePopup} />
    </>
  )
}

export default EventsList
