import { Box } from '@mui/material'

import Checkbox from '_shared/forms/Checkbox'

import Sort, { SortProps, sortMap } from './index'

export type CompaniesSortProps = {
  items: {
    label: string
    field: string
  }[]
  value?: ScoreType | StatSortType
  update: (val: ScoreType | StatSortType) => void
  toggleExclude: () => void
  excludeEmpty: boolean
} & Omit<SortProps, 'children'>

const CompaniesSort = (props: CompaniesSortProps) => {
  const appliedSort = Object.keys(sortMap).find((key) => sortMap[key].asc === props.value || sortMap[key].desc === props.value)

  const handleClick = (field: string) => {
    const newSort = props.value === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc
    if (newSort) props.update(newSort)
  }

  return (
    <Sort className={props.className} collapsed={props.collapsed}>
      {props.items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={props.value === sortMap[item.field].desc}
          onClick={() => handleClick(item.field)}
        />
      ))}
      <Box width={1}>
        {appliedSort && sortMap[appliedSort].excludeEmpty && (
          <Checkbox checked={props.excludeEmpty} name="exclude_nulls" onChange={props.toggleExclude} label="Hide where no future meeting" />
        )}
      </Box>
    </Sort>
  )
}

export { Controller } from './index'
export default CompaniesSort
