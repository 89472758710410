import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import CollaboratorsList from '_core/components/introductions/CollaboratorsList'
import { Narrow } from '_core/components/layout'

import DynamicEntity from '_core/DynamicEntity'

const RequestCollaboratorsPage = () => {
  const { id } = useParams<{ id: string }>()
  const memoUrls = useMemo(() => [{ key: 'request', url: `/prospecting/plandetail?planUid=${id}` }], [id])

  return (
    <Page>
      <Narrow>
        <DynamicEntity urls={memoUrls} id="collaborators" component={CollaboratorsList} keepMounted />
      </Narrow>
    </Page>
  )
}

export default RequestCollaboratorsPage
