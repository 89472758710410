import React, { ReactElement, useEffect } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { AccountCircle } from '@mui/icons-material'

export const useStyles = makeStyles()((theme) => ({
    wrapper: {
      width: 42,
      height: 42,
      marginRight: theme.spacing(1.5)
    },
    pic: {
      width: 42,
      height: 42,
      borderRadius: '100%',
      marginRight: theme.spacing(1.5)
    },
    picButton: {
      width: 35,
      height: 35,
      borderRadius: '100%'
    },
    profile: {
      transform: 'scale(1.5)',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2.5)
    },
    icon: {
      marginLeft: theme.spacing(1.2),
      color: '#36383C'
    },
    iconButton: {
      margin: `-${theme.spacing(1.5)}`,
      [theme.breakpoints.up('sm')]: {
        margin: 'unset'
      }
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 160
    },
    value: {
      maxWidth: 200,
      marginBottom: theme.spacing(0.5),
      opacity: 0.7,
    },
    item: {
      padding: '12px 16px'
    },
    paper: {
      width: 240
   }
  })
)
export interface IOption {
  id: string
  label: ReactElement | string
  callback?: () => void
  icon?: (arg: { style: React.CSSProperties; fixedWidth: boolean }) => void
  addprops?: any
}

const Avatar = (props: RouteComponentProps & { id: string; profilePic?: any; options: IOption[] , activeTab?: string}) => {
  const { classes } = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [pic, setPic] = React.useState<string | undefined>()
  const open = Boolean(anchorEl)

  useEffect(() => {
    setPic(props.profilePic || '')
  }, [props.profilePic])

  const accountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box display="inline">
      <IconButton onClick={accountMenuClick} classes={{ root: classes.iconButton }}>
        {pic ? (
          <img src={pic} className={classes.picButton} alt="Profile" onError={() => setPic('')} />
        ) : (
          <AccountCircle fontSize="large" color="secondary" />
        )}
      </IconButton>
      <Menu
        id={props.id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{paper: classes.paper}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {props.options.map((option, i) => (
          <MenuItem
            key={typeof option?.label === 'string' ? option.label : i}
            selected={props.activeTab === option?.id}
            onClick={() => {
              option?.callback && option.callback()
              handleClose()
            }}
            className={classes.item}
            style={i === 0 ? { borderBottom: '1px #eee solid', marginBottom: 8 } : {}}
            {...option?.addprops}
          >
            {option?.icon && option.icon({ style: { marginRight: 20 }, fixedWidth: true })}
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default withRouter(Avatar)
