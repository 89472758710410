import { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import RemoveTeamGroup from '_core/components/dialogs/RemoveTeamGroup'
import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { del } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  settingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  externalWrapper: {
    display: 'inline-grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 30px'
  },
  groupName: {
    maxWidth: '100%'
  },
  icon: {
    padding: theme.spacing(1.5),
    margin: `-${theme.spacing(1.5)}`,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    fontSize: '15px',
    width: '15px',
    color: '#979797',
    '&:hover': {
      color: '#2E9CBD',
      backgroundColor: 'rgba(27, 149, 187, 0.04)'
    }
  }
}))

interface IGroupItem {
  id: string
  displayName: string
  lastSyncDateTime: string
  action: JSX.Element | null
  isAccessProhibited: boolean
  teamId: number
}

interface IGroup {
  credentialType: string
  displayName: string
  id: string
  isAccessProhibited: boolean
  lastSyncDateTime: string
  roleType: Role
}

interface IGroupWidgetProps {
  team?: Team
  items: {
    data: IGroup[]
    teamNumber: number
  }
  admin?: boolean
  handleChange: () => void
  loading: boolean
}

const GroupItem = ({ displayName, lastSyncDateTime, action, isAccessProhibited }: IGroupItem) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.settingsRow}>
      <Box>
        <Skeleton variant="text" width="100%" height="21px" condition={!displayName}>
          <Box className={classes.externalWrapper}>
            <Tooltip title={displayName}>
              <Typography className={classes.groupName} ellipsis>
                {displayName}
              </Typography>
            </Tooltip>
            <FontAwesomeIcon
              title={!isAccessProhibited ? "This group has access to the team's data" : "This group has no access to the team's data"}
              icon={!isAccessProhibited ? ['fas', 'circle-check'] : ['far', 'ban']}
              style={{ fontSize: 16, color: '#979797', paddingLeft: 8 }}
            />
          </Box>
        </Skeleton>
        <Skeleton variant="text" width="100%" height="21px" condition={!displayName && !lastSyncDateTime}>
          <Typography variant="body1" color="text.secondary">
            {lastSyncDateTime ? `synced ${moment(lastSyncDateTime).fromNow()}` : `sync pending`}
          </Typography>
        </Skeleton>
      </Box>
      {action}
    </Box>
  )
}

const GroupsWidget = ({ team, items, admin, handleChange, loading }: IGroupWidgetProps) => {
  const { dialogContentProps: openedDialog, openDialog, closeDialog } = useDialog<IGroup | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [load, setLoad] = useState(false)
  const wide = useWide()
  const { payloads } = useSidepanelPayloads()
  const { classes } = useStyles()

  useEffect(() => {
    if (loading && load) {
      setLoad(false)
    }
  }, [loading, load])

  useEffect(() => {
    if (payloads && payloads.action === 'RELOAD_LIST' && payloads.value === 'associations') {
      handleChange()
    }
  }, [payloads])

  const removeGroup = (group: IGroup | null) => {
    if (group && team?.id) {
      setLoad(true)
      del(`/teams/${team.id}/membershipcredentials/${group.id}`).then(() => {
        enqueueSnackbar(`"${group.displayName}" has been flagged for removal from the team`, {
          variant: 'default'
        })
        closeDialog()
        handleChange()
      })
    }
  }

  const C = () => <FontAwesomeIcon icon={['far', 'ellipsis-v']} className={classes.icon} />

  const getActions = (group: IGroup) => [
    { name: 'Edit', icon: ['far', 'edit'], link: `${Paths._teams}/${team?.id}/associations/group/${group.id}/edit`, sidepanel: true },
    { name: 'Remove', icon: ['far', 'times'], action: () => openDialog(group) },
    {
      name: 'Go to group',
      icon: ['far', 'external-link'],
      externalLink: `https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${group.id}`
    }
  ]

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'} style={{ borderRadius: 0 }}>
        <Heading title="Contributing groups" icon={['far', 'users']} count={loading ? -1 : items?.data.length} />
        <Box marginTop={{ md: -1.5 }}>
          <Repeater
            direction="vertical"
            component={GroupItem}
            skeleton={{ size: 1, loading: loading || load }}
            items={items?.data?.map((group: IGroup) => ({
              id: group.id,
              displayName: group.displayName,
              lastSyncDateTime: group.lastSyncDateTime,
              isAccessProhibited: group.isAccessProhibited,
              teamId: team?.id,
              action: admin ? (
                <Item
                  component={C}
                  addprops={{ style: { minWidth: 'auto', padding: '0 0 0 12px' } }}
                  disabled={loading || load}
                  item={{
                    menu: {
                      actions: getActions(group)
                    }
                  }}
                />
              ) : null
            }))}
          />
        </Box>
      </Widget>

      {team && (
        <RemoveTeamGroup
          group={openedDialog}
          team={team}
          isOpen={!!openedDialog}
          close={closeDialog}
          remove={() => removeGroup(openedDialog)}
          loading={loading || load}
        />
      )}
    </>
  )
}

export default GroupsWidget
