import { useState, ChangeEvent } from 'react'

import { Box } from '@mui/material'

import { IconButton } from '_shared/buttons'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const DataAnalyticsSettings = (props: any) => {
  const [settingsData, setSettingsData] = useState<any>(props.items)

  const { isSaving, save: saveSettings } = useSaveSettings()

  const [error, setError] = useState<{
    pointsForFirstMeeting: boolean
    pointsAssignedToSubsequentMeetings: boolean
    pointsAssignedToAnEmailInteraction: boolean
  }>({
    pointsForFirstMeeting: false,
    pointsAssignedToSubsequentMeetings: false,
    pointsAssignedToAnEmailInteraction: false
  })
  const { classes } = useStyles()
  const { icon, field, filtersContent, subItem, subItemWrapper } = classes

  const errorMessage = {
    pointsForFirstMeeting: 'The value must be within this range: 1 - 25',
    pointsAssignedToSubsequentMeetings: 'The value must be within this range: 1 - 10',
    pointsAssignedToAnEmailInteraction: 'The value must be within this range: 1 - 5'
  }

  const save = (action: SaveAction) => saveSettings('/adminsettings/dataanalytics', settingsData, action, 'Data analytics settings')

  const handleFistMeetingPointsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || parseFloat(e.target.value) < 1 || parseFloat(e.target.value) > 25) {
      setError((prevState) => ({ ...prevState, pointsForFirstMeeting: true }))
    } else {
      setError((prevState) => ({ ...prevState, pointsForFirstMeeting: false }))
    }
    setSettingsData({
      ...settingsData,
      pointsForFirstMeeting: e.target.value
    })
  }

  const handleEmailMessageInteractionPointsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || parseFloat(e.target.value) < 1 || parseFloat(e.target.value) > 5) {
      setError((prevState) => ({ ...prevState, pointsAssignedToAnEmailInteraction: true }))
    } else {
      setError((prevState) => ({ ...prevState, pointsAssignedToAnEmailInteraction: false }))
    }
    setSettingsData({
      ...settingsData,
      pointsAssignedToAnEmailInteraction: e.target.value
    })
  }

  const handleSubsequentMeetingPointsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || parseFloat(e.target.value) < 1 || parseFloat(e.target.value) > 10) {
      setError((prevState) => ({ ...prevState, pointsAssignedToSubsequentMeetings: true }))
    } else {
      setError((prevState) => ({ ...prevState, pointsAssignedToSubsequentMeetings: false }))
    }
    setSettingsData({
      ...settingsData,
      pointsAssignedToSubsequentMeetings: e.target.value
    })
  }

  return (
    <Settings
      isSaving={isSaving}
      save={save}
      initialLoading={props.loading}
      saveDisabled={error.pointsAssignedToAnEmailInteraction || error.pointsForFirstMeeting || error.pointsAssignedToSubsequentMeetings}
    >
      <Box width="100%">
        <Box display="flex" alignItems="center">
          <Typography>Relationship score customization</Typography>
          <IconButton
            classes={{ root: icon }}
            icon={['far', 'question-circle']}
            hint="You can customize the relationship scores that DotAlign computes by setting your desired values for the following."
          />
        </Box>
        <Box className={filtersContent}>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to the first meeting</Typography>
              <TextField
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
                value={settingsData?.pointsForFirstMeeting}
                onChange={handleFistMeetingPointsChange}
              />
              <IconButton classes={{ root: icon }} hint="The value must be within this range: 1 - 25" icon={['far', 'question-circle']} />
            </Box>
            <Typography className={classes.error}>{error.pointsForFirstMeeting && errorMessage.pointsForFirstMeeting}</Typography>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to subsequent meetings</Typography>
              <TextField
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
                value={settingsData?.pointsAssignedToSubsequentMeetings}
                onChange={handleSubsequentMeetingPointsChange}
              />
              <IconButton classes={{ root: icon }} hint="The value must be within this range: 1 - 10" icon={['far', 'question-circle']} />
            </Box>
            <Typography className={classes.error}>
              {error.pointsAssignedToSubsequentMeetings && errorMessage.pointsAssignedToSubsequentMeetings}
            </Typography>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to a reciprocated email message</Typography>
              <TextField
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
                value={settingsData?.pointsAssignedToAnEmailInteraction}
                onChange={handleEmailMessageInteractionPointsChange}
              />
              <IconButton classes={{ root: icon }} hint="The value must be within this range: 1 - 5" icon={['far', 'question-circle']} />
            </Box>
            <Typography className={classes.error}>
              {error.pointsAssignedToAnEmailInteraction && errorMessage.pointsAssignedToAnEmailInteraction}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Settings>
  )
}

export default DataAnalyticsSettings
