import { MouseEvent, memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { getSkeletonSize } from '_core/helpers/skeleton'

const useStyles = makeStyles<{ fullWidth?: EntityType['fullWidth'] }>()((theme, { fullWidth }) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: fullWidth ? 'column' : 'row'
  },
  button: {
    background: theme.palette.background.light,
    border: `1px solid #797979`,
    color: '#797979',
    '& .MuiIconButton-root': {
      background: '#797979'
    },
    '&:hover': {
      background: `rgba(46, 156, 189, 0.04)`,
      border: `1px solid #2E9CBD`,
      color: '#2E9CBD',
      '& .MuiIconButton-root': {
        background: theme.palette.primary.main
      }
    }
  },
  outlinedPrimary: {
    background: `rgba(46, 156, 189, 0.04)`,
    border: `1px solid #2E9CBD`,
    color: '#2E9CBD',
    '& .MuiIconButton-root': {
      background: theme.palette.primary.main
    }
  },
  heading: {
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    height: 40,
    margin: theme.spacing(1),
    position: 'relative',
    width: `calc(${fullWidth ? '100%' : '50%'} - ${theme.spacing(2)})`
  },
  icon: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    position: 'absolute',
    top: -8,
    right: -8,
    width: 18,
    height: 18,
    padding: theme.spacing(0.5)
  }
}))

type EntityType = {
  id: string
  name: string
  active: boolean
  primary: boolean
  startIcon: JSX.Element | null
  setActive?: EntitiesListProps['setActive']
  remove?: EntitiesListProps['remove']
  fullWidth: boolean
}

const Entity = memo(({ id, name, startIcon, active, fullWidth, setActive, primary, remove }: EntityType) => {
  const { classes } = useStyles({ fullWidth })

  const setAsActive = () => {
    setActive && setActive(id)
  }
  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    remove && remove(id)
  }

  return (
    <Button
      component="div"
      variant="outlined"
      color={active ? 'primary' : 'secondary'}
      classes={{ root: classes.item, outlined: classes.button, outlinedPrimary: classes.outlinedPrimary }}
      onClick={setAsActive}
      startIcon={startIcon}
    >
      <Typography component="span" color="inherit" ellipsis semiBold>
        {name}
      </Typography>
      {!primary && remove && (
        <IconButton onClick={handleRemove} className={classes.icon}>
          <FontAwesomeIcon icon={['far', 'times']} style={{ color: '#fff', fontSize: 13 }} />
        </IconButton>
      )}
    </Button>
  )
})

export type EntitiesListProps = {
  items: (Omit<AuditEntityState, 'identifiers'> & { isActive: boolean; isPrimary: boolean })[]
  loading?: boolean
  remove?: (entityId: string) => void
  setActive?: (entityId: string) => void
  fullWidth: boolean
}

export const EntitiesList = (props: EntitiesListProps) => {
  const { classes } = useStyles({ fullWidth: props.fullWidth })

  return (
    <Box className={classes.list}>
      {props.loading &&
        getSkeletonSize(4).map((_, index) => (
          <Skeleton key={index} condition={true} className={classes.item}>
            {null}
          </Skeleton>
        ))}
      {!props.loading &&
        props.items?.map((item) => (
          <Entity
            key={item.id}
            id={item.id}
            name={item.name}
            active={item.isActive}
            primary={item.isPrimary}
            setActive={props.setActive}
            remove={props.remove}
            startIcon={item.name === 'Invalid' ? <FontAwesomeIcon icon={['far', 'ban']} /> : null}
            fullWidth={props.fullWidth}
          />
        ))}
    </Box>
  )
}

type AuditEntitiesProps = {
  loading: boolean
  openSelect: () => void
} & Required<Pick<EntitiesListProps, 'items' | 'setActive' | 'remove' | 'fullWidth'>>

const AuditEntities = memo((props: AuditEntitiesProps) => {
  const { classes } = useStyles({ fullWidth: props.fullWidth })
  const { loading, openSelect, items, setActive, remove, fullWidth } = props

  const open = () => {
    openSelect()
  }

  return (
    <Box className={classes.root}>
      <Box p={1}>
        <Typography classes={{ root: classes.heading }}>
          <Skeleton condition={loading}>
            <span> Entities to which you can assign identifiers. </span>
          </Skeleton>
          <Skeleton condition={loading}>
            <Button disablePT variant="link" onClick={open}>
              Pick or add another
            </Button>
          </Skeleton>
        </Typography>
        <EntitiesList loading={loading} items={items} setActive={setActive} remove={remove} fullWidth={fullWidth} />
      </Box>
    </Box>
  )
})

export default AuditEntities
