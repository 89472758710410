let loginDialog: Office.Dialog
const dialogLoginUrlPrefix: string =
  window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')

export const closeDialog = () => {
  if (loginDialog) loginDialog.close()
}

export const signInO365 = async (url: string, setState: (x: any) => void, setToken: (x: string) => void, displayError: (x: string) => void) => {
  setState({ authStatus: 'loginInProcess' })

  await Office.context.ui.displayDialogAsync(dialogLoginUrlPrefix + url, { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`)
    } else {
      loginDialog = result.value
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage)
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent)
    }
  })

  const processLoginMessage = (arg: { message: string; type: string } | any) => {
    const messageFromDialog = JSON.parse(arg.message)
    if (messageFromDialog.status === 'success') {
      // We now have a valid access token.
      loginDialog.close()
      setToken(messageFromDialog.result)
      setState({
        authStatus: 'loggedIn',
        headerMessage: 'Get Data'
      })
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close()
      displayError(messageFromDialog.result)
    }
  }

  const processLoginDialogEvent = (arg: any) => {
    processDialogEvent(arg, setState, displayError)
  }
}

let logoutDialog: Office.Dialog

export const logoutFromO365 = async (setState: (x: any) => void, displayError: (x: string) => void) => {
  Office.context.ui.displayDialogAsync(dialogLoginUrlPrefix + '/logout', { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`)
    } else {
      logoutDialog = result.value
      logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage)
      logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent)
    }
  })

  const processLogoutMessage = () => {
    logoutDialog.close()
    setState({
      authStatus: 'notLoggedIn',
      headerMessage: 'Welcome'
    })
  }

  const processLogoutDialogEvent = (arg: any) => {
    processDialogEvent(arg, setState, displayError)
  }
}

const processDialogEvent = (arg: { error: number; type: string }, setState: (x: any) => void, displayError: (x: string) => void) => {
  switch (arg.error) {
    case 12002:
      displayError('The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.')
      break
    case 12003:
      displayError('The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.')
      break
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setState({
        authStatus: 'notLoggedIn',
        headerMessage: 'Welcome'
      })
      break
    default:
      displayError('Unknown error in dialog box.')
      break
  }
}
