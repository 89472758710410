import { useState, useEffect, useRef, useMemo } from 'react'

import moment from 'moment'
import { makeStyles } from 'tss-react/mui'

import { hour } from '_core/data/time'

import { getLocal } from 'utils/Utils'

export type EventTimeType = {
  startsIn: number | null
  finishIn: number
  startedToday: boolean
  className?: string
}

type EventTimes = Partial<EventListItem> & EventTimeType

const useStyles = makeStyles()(() => ({
  shiftSeparatorDown: {
    '& .MuiTimelineSeparator-root': {
      marginBottom: -58
    }
  },
  shiftDotDown: {
    '& .MuiTimelineSeparator-root': {
      marginTop: 58
    }
  }
}))

const useEventTimes = (events: Partial<EventListItem>[] | undefined) => {
  const { classes, cx } = useStyles()

  const items = useMemo(
    () =>
      events?.map((event) => ({
        ...event,
        startsIn: null,
        finishIn: !event.isCancelled ? Math.ceil(getLocal(event.endTimeUTC).diff(getLocal())) : -1,
        startedToday: getLocal().startOf('day').diff(getLocal(event.startTimeUTC).startOf('day'), 'days') === 0
      })),
    [events]
  )

  const [eventTimes, setEventTimes] = useState<EventTimes[]>()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (items) {
      const updateEventTimes = (items: EventTimes[]) => {
        setEventTimes(() => {
          return items.reduce((acc, { className, ...event }, index) => {
            const modifiedItem =
              event.finishIn < 0
                ? event
                : {
                    ...event,
                    startsIn: moment
                      .utc(moment.duration(getLocal(event.startTimeUTC).diff(getLocal(), 'seconds'), 'seconds').asMilliseconds())
                      .unix(),
                    finishIn: !event.isCancelled ? Math.ceil(getLocal(event.endTimeUTC).diff(getLocal())) : -1,
                    startedToday: getLocal().startOf('day').diff(getLocal(event.startTimeUTC).startOf('day'), 'days') === 0
                  }

            const timeMessageIsShown =
              typeof modifiedItem.startsIn === 'number' && modifiedItem.startedToday && modifiedItem.startsIn < hour && modifiedItem.finishIn > 0

            if (timeMessageIsShown) {
              return [
                ...(index > 0
                  ? [
                      ...acc.slice(0, acc.length - 1),
                      { ...acc[acc.length - 1], className: cx(acc[acc.length - 1].className, classes.shiftSeparatorDown) }
                    ]
                  : acc),
                { ...modifiedItem, className: classes.shiftDotDown }
              ]
            }

            return [...acc, modifiedItem]
          }, [] as EventTimes[])
        })
      }

      updateEventTimes(items)
      intervalRef.current = setInterval(() => updateEventTimes(items), 1000)

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }
    }
  }, [items])

  return eventTimes
}

export default useEventTimes
