import { HTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'

import InternalTag from '_core/components/InternalTag'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import { actionButtonColWidth } from '_core/hooks/useActivitiesSlidesToShow'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%'
  },
  tags: {
    '& > div': {
      '&:first-of-type': {
        paddingLeft: theme.spacing(0)
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(0)
      },
      maxWidth: 'fit-content'
    }
  }
}))

const TriggerEl = ({
  open,
  disabled,
  icon,
  text,
  isIcon
}: {
  open: () => void
  disabled?: boolean
  icon: IconProp
  text: string
  isIcon: boolean
}) => {
  const { classes } = useStyles()

  return (
    <Box width={isIcon ? actionButtonColWidth.miniWidth : actionButtonColWidth.fullWidth} my={1}>
      {!isIcon && (
        <Box className={classes.buttonContainer}>
          <Button
            onClick={open}
            variant="outlined"
            color="primary"
            disabled={disabled}
            fullWidth
            startIcon={<FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} />}
          >
            {text}
          </Button>
        </Box>
      )}
      {isIcon && <IconButton size="small" disabled={disabled} icon={icon} hint={text} color="primary" onClick={open} />}
    </Box>
  )
}

type Option = {
  name: string
  emailAddress?: string
  urlText?: string
  md5: string
}

type ActivitiesPickerDialogProps = {
  isOpened: boolean
  close: () => void
  add: (value: string[]) => void
  title: string
  loadOptions: (searchTerm?: string, skip?: string[]) => Promise<Option[] | undefined>
  tags?: Option[] | null
  forceAbort: () => void
}

const ActivitiesPickerDialog = (props: ActivitiesPickerDialogProps) => {
  const { classes } = useStyles()
  const { isOpened, close, add, title, loadOptions, tags, forceAbort } = props

  const [picked, setPicked] = useState<Option[]>([])

  const pickedMd5s = useMemo(() => picked.map(({ md5 }) => md5), [picked])

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox({
    loadOptions: useCallback((searchTerm?: string) => loadOptions(searchTerm, pickedMd5s), [pickedMd5s, loadOptions]),
    forceAbort
  })

  useEffect(() => {
    if (!isOpened) {
      setPicked([])
    }
  }, [isOpened])

  const submit = () => {
    add(pickedMd5s)
    close()
  }

  const handleSelect = (e: SyntheticEvent, value: Option[]) => {
    setPicked((prevState) => [...prevState, ...value.filter(({ md5 }) => !prevState.find((pStateItem) => pStateItem.md5 === md5))])
  }

  const handleDeletePicked = (e: SyntheticEvent, md5: Option['md5']) => {
    e.stopPropagation()
    setPicked((prevState) => prevState.filter((picked) => picked.md5 !== md5))
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: Option) => (
    <li {...props}>
      <ProfileItem name={value.name} userKey={value.emailAddress} logoUrl={value.urlText} byline={value.emailAddress || value.urlText} />
    </li>
  )

  const tagsList = tags?.filter(({ md5 }) => !pickedMd5s.includes(md5))

  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title={title} />} maxWidth={500}>
      <DialogContent>
        <Combobox<Option, true>
          multiple
          autoFocus
          open={open}
          inputValue={inputValue}
          loading={optionsLoading}
          options={options}
          value={picked}
          placeholder="Search"
          onChange={handleSelect}
          onInputChange={handleInputChange}
          onClose={handleClose}
          onOpen={handleOpen}
          onFocus={handleFocus}
          renderOption={renderOption}
          getOptionLabel={(option: Option) => option.name}
          filterOptions={filterOptions}
        />

        {(!tagsList || tagsList.length > 0) && (
          <Repeater
            skeleton={{ loading: !tags, size: 3 }}
            direction="horizontal"
            className={classes.tags}
            items={
              tagsList?.map((tag) => ({
                label: <div key={tag.md5}>{tag.name}</div>,
                onClick: (e: SyntheticEvent) => handleSelect(e, [tag])
              })) || []
            }
            component={InternalTag}
          />
        )}

        {picked.length > 0 && (
          <Repeater
            variant="list"
            component={ProfileItem}
            items={picked.map(({ md5, name, emailAddress, urlText }) => ({
              name: name,
              userKey: emailAddress,
              logoUrl: urlText,
              byline: emailAddress || urlText,
              icons: (
                <IconButton
                  icon={['far', 'times']}
                  size="small"
                  onClick={(e) => {
                    handleDeletePicked(e, md5)
                  }}
                />
              )
            }))}
            skeleton={{ loading: false, size: 0 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disablePR disabled={!picked.length}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ActivitiesPickerDialog.TriggerEl = TriggerEl

export default ActivitiesPickerDialog
