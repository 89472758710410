import { Moment as MomentType } from 'moment'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { getLocal, dateFormatURLQuery } from 'utils/Utils'

const now = getLocal()
const useDealsPeriodEndpointParams = () => {
  const { queryParams } = useSearchQuery<DealsPageParams>()
  const { where, period, from, to, days } = queryParams

  const nDaysAgo = days && now.clone().subtract(+days, 'd')

  const fromFormatted = getLocal(from, dateFormatURLQuery).startOf('day')
  const toFormatted = getLocal(to, dateFormatURLQuery).endOf('day')

  const periodData: { [key in Lowercase<PeriodOptionsType | 'Custom'>]: { oldest?: MomentType; newest?: MomentType } } = {
    within: {
      oldest: nDaysAgo,
      newest: now
    },
    after: {
      newest: nDaysAgo
    },
    custom: {
      oldest: fromFormatted,
      newest: toFormatted
    }
  }

  const dealsPeriodParams = [
    {
      name: `Oldest`,
      value: period ? periodData[period.toLowerCase() as keyof typeof periodData].oldest?.toISOString() : ''
    },
    {
      name: `Newest`,
      value: period ? periodData[period.toLowerCase() as keyof typeof periodData].newest?.toISOString() : ''
    },
    { name: 'DateFilterType', value: from || to || days ? where : '' }
  ]

  return dealsPeriodParams
}

export default useDealsPeriodEndpointParams
