import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'inherit'
    }
  }
}))

const AnalyzeLeadsHeader = ({ buttonUndo }: any) => {
  const { classes } = useStyles()

  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="space-between" mb={1} px={{ xs: 1, md: 0 }}>
        <Box display="flex" alignItems="center">
          <Typography classes={{ root: classes.title }} variant="h2">
            Analyze Leads
          </Typography>
          {buttonUndo}
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyzeLeadsHeader
