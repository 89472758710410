import { useEffect, useState, createContext, ReactNode } from 'react'

import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'

import { get } from 'utils/httpUtils'

import Paths from 'Paths'

export enum PageNames {
  Events,
  People,
  Comapnies,
  Teams
}

type ContextType = {
  homePage: string
  setHomePage: (val: string) => void
  logo: string
  setLogo: (val: string) => void
}

export const GeneralSettingsContext = createContext({} as ContextType)

const defaultLogo = process.env.REACT_APP_LOGO || '/image/logo.png'

const GeneralSettingsProvider = (props: { children: ReactNode }) => {
  const [homePage, setHomePage] = useState<string>('')
  const [logo, setLogo] = useState<string>('')

  const location = useLocation()
  const wide = useWide()

  useEffect(() => {
    if (!homePage) {
      const detectingInitialPage = async () => {
        const settings = await get<{ homePage: PageNames }>('/usersettings/general')
        if (settings) {
          const { homePage } = settings
          setHomePage(`/${homePage}`.toLowerCase())
        }
      }
      if (location.pathname === Paths._home) {
        detectingInitialPage()
      }
    }
  }, [homePage, location.pathname])

  useEffect(() => {
    if (wide && !logo) {
      ; (async () => {
        try {
          const settings = await get<{ useCustomLogo: boolean }>('/anon/general')
          const customLogo = settings?.useCustomLogo ? await get<{ imageData: string; fileExtension: string }>('/app/customLogo') : null
          setLogo(customLogo ? `data:image/${customLogo.fileExtension.substring(1)};base64,${customLogo.imageData}` : defaultLogo)
        } catch (error) {
          setLogo(defaultLogo)
        }
      })()
    }
  }, [wide, logo])

  const ctx = {
    homePage,
    setHomePage: (val: string) => setHomePage(val.toLowerCase()),
    logo,
    setLogo
  }

  return <GeneralSettingsContext.Provider value={ctx}>{props.children}</GeneralSettingsContext.Provider>
}

export default GeneralSettingsProvider
