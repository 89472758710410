import React, { useState } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Button from '_shared/buttons/Button'
import TextField from '_shared/forms/TextField'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Column, Columns } from '_core/components/layout'
import Widget from '_core/components/Widget'

import { post } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
    borderBottom: `1px ${theme.palette.text.disabled} solid`,
    paddingBottom: 8,
    margin: 0
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h5, & p': {
      margin: 0,
      fontSize: '14px'
    },
    '& h5': {
      marginBottom: 0
    }
  },
  subsection: {
    color: theme.palette.text.secondary,
    width: '100%',
    margin: `${theme.spacing(1)} 0`,
    '& label': {
      width: 130,
      display: 'inline-block',
      textAlign: 'right',
      marginRight: 8
    }
  }
}))

const EmailParserPage = () => {
  const {
    classes: { title, section, subsection }
  } = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [code, setCode] = useState<any>()
  const [name, setName] = useState<any>()
  const [email, setEmail] = useState<any>()
  const [body, setBody] = useState<any>()
  const [headers, setHeaders] = useState<any>()
  const [result, setResult] = useState<string>(``)
  const handleRequest = () => {
    setLoading(true)
    setResult('')
    post(`/Parser/email?Code=${code}`, { senderName: name, senderEmail: email, body, transportHeaders: headers }).then((resp: any) => {
      setLoading(false)
      setResult(JSON.stringify(resp, null, 2))
    })
  }

  return (
    <Page>
      <Columns>
        <Column two>
          <Widget scope="stack">
            <Typography variant="h4" className={title}>
              Email Parser
            </Typography>
            <Box my={2} className={section}>
              <Box className={subsection}>
                <TextField id="code" label="Code" disabled={loading} onChange={(e: any) => e.target.value} style={{ width: 100 }} />
              </Box>
              <Box className={subsection}>
                <TextField id="name" label="Sender name" disabled={loading} onChange={(e: any) => setName(e.target.value)} />
              </Box>
              <Box className={subsection}>
                <TextField id="email" label="Sender email" disabled={loading} onChange={(e: any) => setEmail(e.target.value)} />
              </Box>
              <Box className={subsection}>
                <TextField
                  rows="10"
                  multiline
                  id="body"
                  label="Body"
                  disabled={loading}
                  onChange={(e: any) => setBody(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Box>
              <Box className={subsection}>
                <TextField
                  rows="10"
                  multiline
                  id="headers"
                  label="Transport Headers"
                  disabled={loading}
                  onChange={(e: any) => setHeaders(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
          </Widget>

          <Widget scope="none">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {loading && <CircularProgress size="24px" style={{ marginTop: 16, marginBottom: 16 }} />}
              <Button variant="contained" color="primary" disabled={loading} onClick={handleRequest}>
                Request
              </Button>
            </Box>
          </Widget>
        </Column>
        <Column two>
          <Widget scope="none">
            <Typography variant="h4" className={title}>
              Result
            </Typography>
            {!result && (
              <Typography variant="h4" style={{ marginTop: 16, marginBottom: 16 }}>
                Initiate a request to show a result
              </Typography>
            )}
            {result && <pre style={{ marginTop: 16, marginBottom: 16, fontSize: 20 }}>{result}</pre>}
          </Widget>
        </Column>
      </Columns>
    </Page>
  )
}

export default EmailParserPage
