import React, { ComponentProps, HTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'

import PickSuggestionsList from '_core/components/PickSuggestionsList'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpCompanyIntroducers, useLookUpPersonIntroducers, useLookUpUserCompanies, useLookUpUserContacts } from '_core/hooks/useLookup'

const useStyles = makeStyles()((theme) => ({
  pickerButton: {
    background: '#E7F6FF',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

const TriggerEl = (props: { open: () => void; disabled: boolean }) => {
  const {
    classes: { pickerButton }
  } = useStyles()
  const { open, disabled } = props
  return (
    <IconButton onClick={open} className={pickerButton} color="primary" disabled={disabled}>
      <FontAwesomeIcon icon={['far', 'search']} style={{ fontSize: 16 }} />
    </IconButton>
  )
}

const RelationshipPickerDialog = ({
  isOpened,
  title,
  children: content,
  close
}: {
  isOpened: boolean
  title: string
  children: ReactNode
  close: () => void
}) => {
  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title={title || ''} />}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close} disablePR>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const PickAColleagueRelationshipDialog = ({
  isOpened,
  close
}: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookUpCompanyIntroducers: lookUpCompanyIntroducersOptions, forceAbort } = useLookUpCompanyIntroducers()
  const {
    lookUpCompanyIntroducers: lookUpCompanyIntroducersSuggestions,
    reset: resetSuggestions,
    result: suggestionsResult,
    loading: suggestionsLoading
  } = useLookUpCompanyIntroducers()

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      const { data } = (await lookUpCompanyIntroducersOptions(to, searchTerm)) || {}
      return data?.filter(({ UserKeyMd5 }) => UserKeyMd5 !== from)
    },
    [lookUpCompanyIntroducersOptions, to, from]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions,
    clearInputValue
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const suggestionsList = suggestionsResult?.data.filter(({ UserKeyMd5 }) => UserKeyMd5 !== from)

  const handlePick = (id: string) => {
    close()
    history.push(`/relationships/${id}/companies/${to}`)
  }

  useEffect(() => {
    if (isOpened) {
      clearInputValue()
      resetSuggestions()
      lookUpCompanyIntroducersSuggestions(to, '', 5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, to, from, lookUpCompanyIntroducersSuggestions, resetSuggestions])

  useEffect(() => {
    if (value) {
      handlePick(value.UserKeyMd5)
    }
  }, [value])

  const getOptionLabel = (option: CompanyIntroducerListItem) => option.UserName

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, introducer: CompanyIntroducerListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={introducer.UserName} byline={introducer.UserBestJobTitleText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick a colleague" close={close}>
      <Combobox<CompanyIntroducerListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name or email"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={suggestionsLoading}
        suggestions={suggestionsList?.map(({ UserKeyMd5, UserName, UserBestJobTitleText, UserBestEmailAddressText }) => ({
          name: UserName,
          userKey: UserBestEmailAddressText,
          byline: UserBestJobTitleText,
          onClick: () => handlePick(UserKeyMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

export const PickAContributorRelationshipDialog = ({
  isOpened,
  close
}: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookUpPersonIntroducers: lookUpPersonIntroducersOptions, forceAbort } = useLookUpPersonIntroducers()
  const {
    lookUpPersonIntroducers: lookUpPersonIntroducersSuggestions,
    reset,
    result: suggestionsResult,
    loading: suggestionsLoading
  } = useLookUpPersonIntroducers()

  const suggestionsList = suggestionsResult?.data.filter(({ UserKeyMd5 }) => UserKeyMd5 !== from)

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      const { data } = (await lookUpPersonIntroducersOptions({ personMd5: to, searchTerm })) || {}
      return data?.filter(({ UserKeyMd5 }) => UserKeyMd5 !== from)
    },
    [to, from, lookUpPersonIntroducersOptions]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions,
    clearInputValue
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const handlePick = (id: string) => {
    close()
    history.push(`/relationships/${id}/people/${to}`)
  }

  useEffect(() => {
    if (isOpened) {
      clearInputValue()
      reset()
      lookUpPersonIntroducersSuggestions({ personMd5: to, take: 5 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, to, lookUpPersonIntroducersSuggestions, reset])

  useEffect(() => {
    if (value) {
      handlePick(value.UserKeyMd5)
    }
  }, [value])

  const getOptionLabel = (option: PersonIntroducerListItem) => option.UserName

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, introducer: PersonIntroducerListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={introducer.UserName} byline={introducer.UserBestJobTitleText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick an introducer" close={close}>
      <Combobox<PersonIntroducerListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name or email"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={suggestionsLoading}
        suggestions={suggestionsList?.map(({ UserKeyMd5, UserName, UserBestEmailAddressText, UserBestJobTitleText }) => ({
          name: UserName,
          userKey: UserBestEmailAddressText,
          byline: UserBestJobTitleText,
          onClick: () => handlePick(UserKeyMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

export const PickACompanyRelationshipDialog = ({ isOpened, close }: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookupUserCompanies: lookupUserCompaniesOptions, forceAbort } = useLookUpUserCompanies()

  const {
    lookupUserCompanies: lookupUserCompaniesSuggestions,
    reset: resetSuggestions,
    result: suggestionsResult,
    loading: suggestionsLoading
  } = useLookUpUserCompanies()

  const suggestionsList = suggestionsResult?.data.filter(({ CompanyMd5 }) => CompanyMd5 !== to)

  useEffect(() => {
    if (isOpened) {
      clearInputValue()
      resetSuggestions()
      lookupUserCompaniesSuggestions(from, '', 5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, lookupUserCompaniesSuggestions, isOpened, resetSuggestions])

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      const result = await lookupUserCompaniesOptions(from, searchTerm)
      return result?.data.filter(({ CompanyMd5 }) => CompanyMd5 !== to)
    },
    [from, lookupUserCompaniesOptions, to]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions,
    clearInputValue
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const handlePick = (id: string) => {
    close()
    history.push(`/relationships/${from}/companies/${id}`)
  }

  useEffect(() => {
    if (value) {
      handlePick(value.CompanyMd5)
    }
  }, [value])

  const getOptionLabel = (option: UserCompaniesListItem) => option.CompanyNameText

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, company: UserCompaniesListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={company.CompanyNameText} byline={company.BestUrlText} logoUrl={company.BestUrlText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick a company" close={close}>
      <Combobox<UserCompaniesListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={suggestionsLoading}
        suggestions={suggestionsList?.map(({ CompanyMd5, CompanyNameText, BestUrlText }) => ({
          name: CompanyNameText,
          logoUrl: BestUrlText,
          byline: BestUrlText,
          onClick: () => handlePick(CompanyMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

export const PickAContactRelationshipDialog = ({ isOpened, close }: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookupUserContacts: lookupUserContactsOptions, forceAbort } = useLookUpUserContacts()

  const { lookupUserContacts: lookupUserContactsSuggestions, result: suggestionsResult, reset: resetSuggestions } = useLookUpUserContacts()

  const suggestionsList = suggestionsResult?.data.filter(({ PersonMd5, BestEmailAddrText }) => PersonMd5 !== to && BestEmailAddrText !== to)

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      const result = await lookupUserContactsOptions(from, searchTerm)
      return result?.data.filter(({ PersonMd5, BestEmailAddrText }) => PersonMd5 !== to && BestEmailAddrText !== to)
    },
    [from, to, lookupUserContactsOptions]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions,
    clearInputValue
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  useEffect(() => {
    if (isOpened) {
      clearInputValue()
      resetSuggestions()
      lookupUserContactsSuggestions(from, '', 5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, lookupUserContactsSuggestions, resetSuggestions, isOpened])

  const handlePick = (id: string) => {
    close()
    history.push(`/relationships/${from}/people/${id}`)
  }

  useEffect(() => {
    if (value) {
      handlePick(value.PersonMd5)
    }
  }, [value])

  const getOptionLabel = (option: UserPeopleListItem) => option.PersonNameText

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, person: UserPeopleListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={person.PersonNameText} byline={person.BestEmailAddrText} userKey={person.BestEmailAddrText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick a contact" close={close}>
      <Combobox<UserPeopleListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={!suggestionsList}
        suggestions={suggestionsList?.map(({ PersonMd5, PersonNameText, BestEmailAddrText }) => ({
          name: PersonNameText,
          userKey: BestEmailAddrText,
          byline: BestEmailAddrText,
          onClick: () => handlePick(PersonMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

PickAColleagueRelationshipDialog.TriggerEl = TriggerEl
PickACompanyRelationshipDialog.TriggerEl = TriggerEl

PickAContributorRelationshipDialog.TriggerEl = TriggerEl
PickAContactRelationshipDialog.TriggerEl = TriggerEl

export default RelationshipPickerDialog
