import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DefaultSuccessAction, DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    flex: 1,
    minHeight: '52px',
    marginLeft: theme.spacing(2)
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18
  }
}))

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => {
  const { classes } = useStyles()
  return (
    <Tooltip title="Remove selected" disabled={disabled}>
      <IconButton disabled={disabled} color="primary" onClick={open} style={{ width: '42px' }}>
        <FontAwesomeIcon className={classes.icon} icon={['far', 'user-minus']} />
      </IconButton>
    </Tooltip>
  )
}

const RemoveSelectedDialog = ({
  isOpened,
  close,
  successClose,
  handleRemove,
  success,
  content
}: {
  isOpened: boolean
  close: () => void
  successClose: () => void
  handleRemove: () => Promise<void>
  success: boolean
  content: string
}) => {
  const [loading, setLoading] = useState(false)

  const closeHandler = () => {
    close()
    setLoading(false)
  }

  const remove = async () => {
    setLoading(true)
    await handleRemove()
    setLoading(false)
  }

  return (
    <Dialog open={isOpened} onClose={success ? successClose : close} loading={loading} success={success} title={<DialogTitle title="Remove selected?" />}>
      <DialogContent successContent={<Typography>The removal was successful</Typography>}>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={successClose} />}>
        <Button variant="text" disabled={loading} onClick={closeHandler} color="secondary">
          Cancel
        </Button>
        <Button variant="text" disabled={loading} onClick={remove} disablePR>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveSelectedDialog.TriggerEl = TriggerEl

export default RemoveSelectedDialog
