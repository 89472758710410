import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import { Button } from '_shared/buttons'

import Empty from '_core/components/Empty'

const Login = ({ handleLogin, name, className }: { handleLogin: () => void; name?: string; className?: any }) => (
  <Empty
    className={className}
    icon={<FontAwesomeIcon size="5x" style={{ color: '#00A1E0' }} icon={['fab', 'salesforce']} />}
    title="Login to Salesforce"
    subTitle={name ? `to see more information about ${name}` : 'You will be directed to the Salesforce login page when you click the Login button.'}
    action={
      <Box display="flex" justifyContent="center" mt={2}>
        <Button onClick={handleLogin} startIcon={<FontAwesomeIcon icon={['fas', 'arrow-right-to-bracket']} style={{ fontSize: 14 }} />}>
          Login
        </Button>
      </Box>
    }
  />
)

export default Login
