import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

const Content = <Typography style={{ maxWidth: '125px' }}>Click here to expand the profile to full view.</Typography>

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Expand sidepanel',
    content: Content,
    target: '.expand-sidebar-onboarding'
  }
]

export default steps
