import { FilterOptionsState } from '@mui/material'

type DefaultOption = { value: string; label: string }

const defaultTransformOption = (inputValue: string): DefaultOption => ({
  value: inputValue,
  label: `Add "${inputValue}"`
})

type SuggestOptionProps<T, M = undefined> = {
  loading: boolean
} & (M extends undefined
  ? { transformOption?: never; filterFn?: (options: T[], state: FilterOptionsState<T>) => (T | DefaultOption)[] }
  : { transformOption: (inputValue: string) => M; filterFn?: (options: T[], state: FilterOptionsState<T>) => (T | M)[] })

function useSuggestOptionCreation<T>(params: {
  loading: boolean
  filterFn?: (options: T[], state: FilterOptionsState<T>) => (T | DefaultOption)[]
}): (options: T[], state: FilterOptionsState<T>) => (DefaultOption | T)[]

function useSuggestOptionCreation<T, M>(params: {
  loading: boolean
  transformOption: (inputValue: string) => M
  filterFn?: (options: T[], state: FilterOptionsState<T>) => (T | M)[]
}): (options: T[], state: FilterOptionsState<T>) => (T | M)[]

function useSuggestOptionCreation<T, M = undefined>({ loading, filterFn, transformOption }: SuggestOptionProps<T, M>) {
  const filterWithSuggest = (options: T[], state: FilterOptionsState<T>) => {
    if (state.inputValue !== '' && !loading) {
      if (transformOption) {
        const filtered: (M | T)[] = filterFn ? filterFn(options, state) : options
        const option = transformOption(state.inputValue)
        filtered.push(option)
        return filtered
      } else {
        const filtered: (DefaultOption | T)[] = filterFn ? filterFn(options, state) : options
        const option = defaultTransformOption(state.inputValue)
        filtered.push(option)
        return filtered
      }
    } else {
      return filterFn ? filterFn(options, state) : options
    }
  }

  return filterWithSuggest
}

export default useSuggestOptionCreation
