import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton, Select } from '_shared/buttons'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import Audit from '_core/components/Audit'
import ReportIncorrectDataDialog from '_core/components/dialogs/ReportIncorrectMarketData'
import { IsPrivateRelationshipBudge, MarkPrivateDialog, MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import DrawerMenu from '_core/components/DrawerMenu'
import { Middle, Narrow, useWide, Wide, WideStrict } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import { MDByLine, MDPersonByLine2 } from '_core/components/MarketDataSummary'
import ProfileSummary from '_core/components/ProfileSummary'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import Paths from 'Paths'

const companyBaseUrl = Paths._companies
const salesForceUrl = Paths._salesforce

const useStyles = makeStyles()((theme) => ({
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px',
    minWidth: '16px'
  },
  line: {
    maxWidth: '100%'
  },
  line2: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '230px'
    }
  }
}))

const PersonHeader = ({ isEnabled: isSalesforceEnabled, ...props }: any) => {
  const [privateRelationship, setPrivateRelationship] = useState<boolean | undefined>(undefined)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [tagsList, setTagsList] = useState<UngroupedTag[]>()

  const { id } = useParams<{ id: string }>()

  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<'private' | 'public' | null>(null)
  const { isDialogOpened: openedReportDialog, openDialog: openReportDialog, closeDialog: closeReportDialog } = useDialog(false)

  const { enabled: enabledMarketData, showSimilarPeople } = props.marketDataIntegration || {}

  const { JobIsFormer: BestJobIsFormer, JobCurrentAsOf: BestJobCurrentAsOf } = props.Jobs?.data?.[0] || {}

  const { payloads } = useSidepanelPayloads()

  useEffect(() => {
    setPrivateRelationship(props?.Privacy?.TurnedOffKeepPrivate ? false : !!props?.Privacy?.KeepPrivate)
  }, [props?.Privacy])

  useEffect(() => {
    const tagNames = props.Tags?.[0].TagNames
    if (!tagsList && tagNames) {
      setTagsList(
        tagNames.map(({ Category, Tag }: { Category: string; Tag: string } & { Md5: string }) => ({ categoryName: Category, tagName: Tag }))
      )
    }
  }, [props.Tags])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const { identifier, tags } = payloads.value[0]
        if (identifier === id) {
          setTagsList(tags)
        }
      }
    }
    if (tagsList) {
      if (payloads && payloads.action === 'SYNC_TAGS') {
        const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
        if (taggableType === 'people') {
          const nonDeprecated = tagsList.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          const renamed = nonDeprecated.map(({ categoryName, tagName }) => {
            const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

            const { newTagName = tagName } =
              tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
            return { categoryName: newCategoryName, tagName: newTagName }
          })
          setTagsList(renamed)
        }
      }
    }
  }, [payloads])

  const renderButtons =
    typeof isSalesforceEnabled === 'boolean' && typeof privateRelationship === 'boolean' && typeof enabledMarketData === 'boolean' && props.PersonMd5

  const upMiddleView = useWide()

  const marketDataActions = useMemo(
    () => [
      {
        name: 'Similar People',
        icon: ['fas', 'diagram-venn'],
        link: `/people/${id}/similar?clearSearch=true&name=${props.PersonNameText}`
      },
      {
        name: 'Report incorrect data',
        icon: ['far', 'message-exclamation'],
        action: openReportDialog
      },
      {
        name: 'Learn more',
        icon: ['far', 'question-circle'],
        externalLink: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data'
      }
    ],
    [id, props.PersonNameText]
  )

  const handlePrivateOpen = () => {
    openDialog('private')
  }

  const handlePublicOpen = () => {
    openDialog('public')
  }

  const MarketDataButton = useCallback(
    () => (
      <Select
        disableRipple
        component="span"
        color="primary"
        variant="outlined"
        endIcon={<FontAwesomeIcon style={{ width: 8 }} color="#979797" icon={['far', 'chevron-down']} />}
      >
        Market Data
      </Select>
    ),
    []
  )

  const MarkPublicIconButton = (
    <MarkPublicDialog.TriggerIcon
      hint={`Mark your relationship ${props.PersonNameText ? `with ${props.PersonNameText}` : ''} as shared`}
      style={{ fontSize: 14, maxWidth: 38 }}
      onClick={handlePublicOpen}
    />
  )

  const MarkPrivateIconButton = (
    <MarkPrivateDialog.TriggerIcon
      style={{ fontSize: 14, maxWidth: 38 }}
      hint={`Mark your relationship ${props.PersonNameText ? `with ${props.PersonNameText}` : ''} as private`}
      onClick={handlePrivateOpen}
    />
  )

  const buttonsMap = [
    {
      name: 'Edit',
      icon: ['far', 'edit'],
      link: props.PersonMd5 ? `/people/${props.PersonMd5}/edit` : '',
      condition: !upMiddleView || (upMiddleView && renderButtons),
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: 'Merge',
      icon: ['far', 'merge'],
      link: props.PersonMd5 ? `${Paths._merge}/people?ids=${props.PersonMd5}` : '',
      condition: !upMiddleView || (upMiddleView && renderButtons),
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: 'Audit',
      component: <Audit name={props.PersonNameText} type="people" menuItem={enabledMarketData} />,
      condition: !upMiddleView || (upMiddleView && renderButtons),
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: 'Save to Salesforce',
      icon: ['far', 'cloud-upload-alt'],
      link: props.PersonMd5 ? `${salesForceUrl}/contact/${props.PersonMd5}` : '',
      condition: isSalesforceEnabled && renderButtons,
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: `Mark your relationship ${props.PersonNameText ? `with ${props.PersonNameText}` : ''} as shared`,
      icon: ['far', 'lock-open'],
      action: enabledMarketData ? handlePublicOpen : null,
      component: enabledMarketData ? null : MarkPublicIconButton,
      condition: !upMiddleView && privateRelationship,
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: `Mark your relationship ${props.PersonNameText ? `with ${props.PersonNameText}` : ''} as private`,
      icon: ['far', 'lock'],
      action: enabledMarketData ? handlePrivateOpen : null,
      component: enabledMarketData ? null : MarkPrivateIconButton,
      condition: !upMiddleView && !privateRelationship && renderButtons,
      menuItem: enabledMarketData && !upMiddleView
    },
    {
      name: 'Mark as public',
      component: (
        <>
          <Middle>{MarkPublicIconButton}</Middle>
          <WideStrict>
            <MarkPublicDialog.TriggerButton onClick={handlePublicOpen} />
          </WideStrict>
        </>
      ),
      condition: upMiddleView && privateRelationship
    },
    {
      name: 'Mark as private',
      component: (
        <>
          <Middle>{MarkPrivateIconButton}</Middle>
          <WideStrict>
            <MarkPrivateDialog.TriggerButton onClick={handlePrivateOpen} />
          </WideStrict>
        </>
      ),
      condition: upMiddleView && !privateRelationship && renderButtons
    }
  ].filter((button) => button.condition)

  const toggleDrawer = () => setDrawerOpen((prevState: boolean) => !prevState)

  const Buttons = (
    <>
      {enabledMarketData && !showSimilarPeople && (
        <Wide>
          <Item component={MarketDataButton} item={{ menu: { actions: marketDataActions } }} disablePadding />
        </Wide>
      )}
      {buttonsMap
        .filter((el) => !el.menuItem)
        .map((button: { [key: string]: any }) => {
          if (button.component) {
            return <Box key={button.name}>{button.component}</Box>
          }

          return (
            <SidepanelLink linkProps={{ to: button.link }} sidepanel={true} key={button.name}>
              <WideStrict>
                <Button color="primary" startIcon={<FontAwesomeIcon icon={button.icon} style={{ fontSize: 14 }} />}>
                  {button.name}
                </Button>
              </WideStrict>
              <Middle>
                <IconButton color="primary" hint={button.name} loading={!props.PersonMd5} icon={button.icon} size="small" />
              </Middle>
              <Narrow>
                <IconButton color="primary" hint={button.name} loading={!props.PersonMd5} icon={button.icon} size="small" />
              </Narrow>
            </SidepanelLink>
          )
        })}
      {enabledMarketData && (
        <Narrow>
          <Box mr={-1}>
            <IconButton
              color="primary"
              icon={['far', 'ellipsis-v']}
              hint="Menu"
              size="small"
              loading={!props.PersonMd5}
              disablePX
              onClick={toggleDrawer}
            />
          </Box>
        </Narrow>
      )}
      <MarkPrivateDialog
        opened={openedDialog === 'private'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={props.BestEmailAddrText ? [props.BestEmailAddrText] : []}
        userName={props.PersonNameText}
        setPrivateRelationship={setPrivateRelationship}
      />
      <MarkPublicDialog
        opened={openedDialog === 'public'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={props.BestEmailAddrText ? [props.BestEmailAddrText] : []}
        userName={props.PersonNameText}
        setPrivateRelationship={setPrivateRelationship}
      />
      <ReportIncorrectDataDialog opened={openedReportDialog} close={closeReportDialog} marketData={JSON.stringify(props.marketData)} />
    </>
  )

  const { linkedin_url, github_url } = props.marketData || {}

  const actions = [
    (props.loading || (!props.loading && props.BestPhoneText)) && (
      <IconButton
        icon={['far', 'phone']}
        color="primary"
        hint={`Make a call to ${props.BestPhoneText}`}
        disablePadding
        component="a"
        href={`tel:${props.BestPhoneText?.substring(2)}`}
        loading={!props.BestPhoneText}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    ),
    (props.loading || (!props.loading && props.BestEmailAddrText)) && (
      <IconButton
        icon={['far', 'envelope']}
        color="primary"
        hint={`Send an email to ${props.BestEmailAddrText}`}
        disablePadding
        component="a"
        href={`mailto:${props.BestEmailAddrText}`}
        loading={!props.BestEmailAddrText}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    ),
    (props.loading || (!props.loading && props.PersonNameText)) && (
      <IconButton
        icon={['fab', 'linkedin']}
        color="primary"
        hint="Show Linkedin profile"
        component="a"
        disablePadding
        href={linkedin_url ? `https://${linkedin_url}` : `https://www.linkedin.com/search/results/people/?keywords=${props.PersonNameText}`}
        loading={!props.PersonNameText}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    ((enabledMarketData && !props.marketData) || (!props.loading && github_url)) && (
      <IconButton
        icon={['fab', 'github']}
        color="primary"
        hint="Show Github profile"
        component="a"
        disablePadding
        href={`https://${github_url}`}
        loading={!github_url}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    )
  ].filter((action): action is ReactElement => !!action)

  return (
    <>
      <Widget scope="none">
        <ProfileSummary
          title={props.PersonNameText}
          loading={props.loading}
          budge={privateRelationship ? <IsPrivateRelationshipBudge userName={props.PersonNameText} /> : null}
          editTagsLink={`${Paths._people}/${id}/tags/edit?name=${props.PersonNameText}`}
          showAllTagsLink={`${Paths._people}/${id}/tags?name=${props.PersonNameText}`}
          score={props.Score}
          userKey={props.MyUserKeyMd5 ? props.BestEmailAddrText : ''}
          introducers={!props.MyUserKeyMd5 && props.Introducers ? props.Introducers : null}
          tags={tagsList}
          buttons={Buttons}
          actions={actions}
          {...(enabledMarketData
            ? {
                enabledMarketData: true,
                byline: <MDByLine marketData={!!props.marketData} locationName={props.marketData?.location_name} />,
                byline2: (
                  <MDPersonByLine2
                    marketData={props.marketData}
                    bestJobMatchedUrlText={props.BestJobMatchedUrlText}
                    internalCompanyName={props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                    bestJobTitleText={props.BestJobTitleText}
                    bestJobCompanyMd5={props.BestJobCompanyMd5}
                    bestJobIsFormer={BestJobIsFormer}
                    bestJobCurrentAsOf={BestJobCurrentAsOf}
                  />
                )
              }
            : {
                enabledMarketData: false,
                byline: props.BestJobTitleText,
                byline2: (
                  <Tooltip
                    title={props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                    placement="bottom-start"
                    disabled={upMiddleView}
                  >
                    <Typography variant="body1" ellipsis color="primary.main">
                      <Link to={`${companyBaseUrl}/${props.BestJobCompanyMd5}`}>
                        {props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                      </Link>
                    </Typography>
                  </Tooltip>
                )
              })}
        />
      </Widget>
      {enabledMarketData && (
        <Narrow>
          <DrawerMenu open={drawerOpen} toggleDrawer={toggleDrawer} items={showSimilarPeople ? buttonsMap : [...buttonsMap, ...marketDataActions]} />
        </Narrow>
      )}
    </>
  )
}

export default PersonHeader
