import React, { useState } from 'react'

import { Box, SelectChangeEvent } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'
import Select from '_shared/forms/Select'

import Settings, { useStyles as useSettingsStyles } from '_core/components/settings'

import { ActivitiesVisibility, ActivitiesVisibilityLevel } from '_core/hooks/useActivitiesAccess'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

import { put } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    paddingBottom: 0
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `-${theme.spacing(1)}`
  }
}))

const options = [
  { value: 'AdminsOnly', label: 'For admins only' },
  { value: 'AllUsers', label: 'For all users' }
]

const ActivitiesSettings = ({ loading, settings, profile }: { loading: boolean; settings: ActivitiesVisibility; profile: ProfileType }) => {
  const { updateUserActivitiesAccess } = useNavBarMenuItemsShown()

  const [isSaving, setSaving] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const {
    control,
    reset,
    getValues,
    formState: { isDirty: isVisibilityChanged }
  } = useForm<ActivitiesVisibility>({
    defaultValues: loading
      ? { statsWidgetVisibility: 'Off', detailedActivityFromStatsWidgetVisibility: 'Off', activityTabVisibility: 'Off' }
      : settings
  })
  const {
    control: enabledControl,
    reset: resetEnabled,
    watch,
    formState: { isDirty: isEnabledChanged }
  } = useForm<{ enabled: boolean }>({
    defaultValues: loading ? { enabled: false } : { enabled: !!Object.values(settings || {}).find((val) => val !== 'Off') }
  })
  const { cx, classes } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })
  const { classes: settingsStyles } = useSettingsStyles()

  const enabled = watch('enabled')

  const getIntialVisibility = (field: keyof ActivitiesVisibility) => (getValues()[field] === 'Off' ? 'AdminsOnly' : getValues()[field])

  const [localLevelSettings, setLocalLevelSettings] = useState<ActivitiesVisibility>({
    statsWidgetVisibility: getIntialVisibility('statsWidgetVisibility'),
    detailedActivityFromStatsWidgetVisibility: getIntialVisibility('detailedActivityFromStatsWidgetVisibility'),
    activityTabVisibility: getIntialVisibility('activityTabVisibility')
  })

  const save = async () => {
    setSaving(true)

    const offValue: Extract<ActivitiesVisibilityLevel, 'Off'> = 'Off'

    const visibility = enabled
      ? getValues()
      : {
          statsWidgetVisibility: offValue,
          detailedActivityFromStatsWidgetVisibility: offValue,
          activityTabVisibility: offValue
        }

    if (isVisibilityChanged || isEnabledChanged) {
      await put('/adminsettings/activities', visibility)
      reset({}, { keepValues: true })
      resetEnabled({}, { keepValues: true })
    }
    updateUserActivitiesAccess([visibility, profile])
    enqueueSnackbar('Activities settings have been updated')
    setSaving(false)
  }

  const handleLevelChange = (onChange: (...event: any[]) => void) => (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: keyof ActivitiesVisibility; value: Omit<ActivitiesVisibilityLevel, 'Off'> }
    onChange(value)
    setLocalLevelSettings((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={loading} saveDisabled={loading}>
      <Controller
        name="enabled"
        control={enabledControl}
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={value} onChange={onChange} disabled={isSaving} classes={{ root: classes.root }} label="Turn on activities" />
        )}
      />

      <Box ml={4} mb={2} display="flex" flexDirection="column">
        <Controller
          name="statsWidgetVisibility"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box className={cx(classes.wrapper, settingsStyles.itemWrapper)}>
              <Checkbox
                checked={value !== 'Off'}
                onChange={(e) => onChange(e.target.checked ? localLevelSettings.statsWidgetVisibility : 'Off')}
                disabled={isSaving || !enabled}
                classes={{ ...checkboxClasses, root: checkboxClasses.root }}
                label="Show activity stats widget in profile and relationship details screens"
              />
              <Select
                size="small"
                value={localLevelSettings.statsWidgetVisibility}
                name="statsWidgetVisibility"
                options={options}
                disabled={isSaving || !enabled || value === 'Off'}
                onChange={handleLevelChange(onChange)}
              />
            </Box>
          )}
        />
        <Controller
          name="detailedActivityFromStatsWidgetVisibility"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box className={cx(classes.wrapper, settingsStyles.itemWrapper)}>
              <Checkbox
                checked={value !== 'Off'}
                onChange={(e) => onChange(e.target.checked ? localLevelSettings.detailedActivityFromStatsWidgetVisibility : 'Off')}
                disabled={isSaving || !enabled}
                classes={{ ...checkboxClasses, root: checkboxClasses.root }}
                label="Allows access to detailed activity list from activity stats widget"
              />
              <Select
                size="small"
                value={localLevelSettings.detailedActivityFromStatsWidgetVisibility}
                name="detailedActivityFromStatsWidgetVisibility"
                options={options}
                disabled={isSaving || !enabled || value === 'Off'}
                onChange={handleLevelChange(onChange)}
              />
            </Box>
          )}
        />
        <Controller
          name="activityTabVisibility"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box className={cx(classes.wrapper, settingsStyles.itemWrapper)}>
              <Checkbox
                checked={value !== 'Off'}
                onChange={(e) => onChange(e.target.checked ? localLevelSettings.activityTabVisibility : 'Off')}
                disabled={isSaving || !enabled}
                classes={{ ...checkboxClasses, root: checkboxClasses.root }}
                label="Show activities tab"
              />
              <Select
                size="small"
                value={localLevelSettings.activityTabVisibility}
                name="activityTabVisibility"
                options={options}
                disabled={isSaving || !enabled || value === 'Off'}
                onChange={handleLevelChange(onChange)}
              />
            </Box>
          )}
        />
      </Box>
    </Settings>
  )
}

export default ActivitiesSettings
