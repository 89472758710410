import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import { Score } from '_shared/Badge'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  connection: {
    display: 'flex',
    alignItems: 'center',
    '& div:nth-of-type(2)': {
      marginLeft: '-12px'
    }
  },
  name: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(-0.5),
    marginBottom: 0,
    fontSize: 14,
    padding: `0 ${theme.spacing(1)}`,
    overflow: 'hidden'
  },
  avatar: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
    boxShadow: `0 0 0 1px ${theme.palette.background.light}`,
    '&:last-child': {
      marginLeft: theme.spacing(-1)
    }
  }
}))

const Relationship = ({ name1, name2, score, userKey1, userKey2 }: RelationshipProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.connection}>
        <Avatar name={name1} userKey={userKey1} size="sm" hideName />
        <Avatar name={name2} userKey={userKey2} size="sm" hideName />
      </div>
      <Skeleton condition={!name1 || !name2}>
        <Typography variant="body1" className={classes.name}>
          {name1 && name2 ? `${name1} via ${name2}` : `Contact Name via Introducer Name`}
        </Typography>
      </Skeleton>
      <Score value={score} size="sm" />
    </div>
  )
}

export default Relationship

type RelationshipProps = {
  name1: string
  name2: string
  score: number | string | undefined
  loading?: boolean
  link?: string
  userKey1?: string
  userKey2?: string
}
