import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, BoxProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.secondary.main
  },
  wrapper: {},
  contained: {
    borderRadius: '100%',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sm: {
    minWidth: theme.spacing(3),
    maxWidth: theme.spacing(3),
    minHeight: theme.spacing(3),
    maxHeight: theme.spacing(3)
  },
  lg: {
    minWidth: theme.spacing(4.25),
    minHeight: theme.spacing(4.25),
    maxWidth: theme.spacing(4.25),
    maxHeight: theme.spacing(4.25)
  },
  xl: {
    minWidth: theme.spacing(5),
    minHeight: theme.spacing(5),
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(5)
  }
}))

const Icon = (
  props: {
    icon: IconProp
    contained?: boolean
    size?: Extract<SizeProp, 'sm' | 'lg' | 'xl'>
    containerSize?: Extract<SizeProp, 'sm' | 'lg' | 'xl'>
    className?: string
  } & Pick<BoxProps, 'mr' | 'ml'>
) => {
  const { icon, size = 'sm', containerSize = 'sm', contained, className, mr, ml } = props
  const { classes, cx } = useStyles()

  return (
    <Box className={cx([classes[containerSize] || classes[size]], { [classes.contained]: contained }, className)} mr={mr} ml={ml}>
      <FontAwesomeIcon icon={icon} size={size} className={classes.icon} />
    </Box>
  )
}

export default Icon
