import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams, useRouteMatch, Switch, Route, Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import MergeFlow, { Params } from '_core/components/MergeFlow'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import usePicker from '_core/hooks/usePicker'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    marginTop: theme.spacing(2)
  }
}))

const getUrls = (entity: Entities, teamNumber: number, ids?: string) => {
  if (ids) {
    const keys = ids.split(',')
    return keys.map((id: string) => ({ key: id, url: `/${entity}/${id}/?teamNumber=${teamNumber}` }))
  }
  return null
}

const CompaniesMergePage = () => {
  const { entity } = useParams<Params>()
  const { queryParams } = useSearchQuery<MergePageParams>()
  const { teamContextValue } = useContext(TeamContext)
  const urls = getUrls(entity, teamContextValue.teamNumber, queryParams.ids)

  return <DynamicEntity urls={urls} component={MergeFlow} nativeBack id="company_merge" />
}

const PeopleMergePage = () => {
  const { entity } = useParams<Params>()
  const { queryParams } = useSearchQuery<MergePageParams>()
  const { teamContextValue } = useContext(TeamContext)
  const urls = getUrls(entity, teamContextValue.teamNumber, queryParams.ids)

  return <DynamicEntity urls={urls} component={MergeFlow} nativeBack id="person_merge" />
}

const Select = () => {
  const { entity } = useParams<Params>()
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader(`Pick or add ${entity === 'companies' ? 'company' : 'person'}  `)
  }, [])

  const { Picker } = usePicker({
    entity,
    getLink: (md5: string) => `${Paths._merge}/${entity}?ids=${md5}`
  })
  return (
    <>
      <Narrow>
        <Topbar nativeBack={true} />
      </Narrow>
      {Picker}
    </>
  )
}

const MergePage = () => {
  const matchCompanies = useRouteMatch(`${Paths._merge}${Paths._companies}`)
  const matchPeople = useRouteMatch(`${Paths._merge}${Paths._people}`)
  const { entity } = useParams<Params>()
  const { queryParams } = useSearchQuery<MergePageParams>()
  const id = queryParams.ids?.[0]
  const handleClose = useSidepanelClose(`/${entity}/${id}`)
  const { classes } = useStyles()

  const Component = () => (
    <>
      {matchCompanies && <CompaniesMergePage />}
      {matchPeople && <PeopleMergePage />}
    </>
  )

  return (
    <Page>
      <Switch>
        <Route path={`${Paths._merge}/:entity/success`}>
          <Success>
            <Box display="flex" justifyContent="center" flexWrap="wrap" mt={-3} mx={2}>
              <span>You can view edits</span>
              <span>
                &nbsp;on the&nbsp;
                <Link style={{ color: '#2E9CBD', fontWeight: 600 }} to={{ pathname: `${Paths._manualEdits}?sidepanel=false` }} target="_blank">
                  Manual Edits
                </Link>
                &nbsp;page.
              </span>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={4}>
              <Link to={`${Paths._merge}/${entity}/find`}>
                <Button startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}>
                  Choose another {entity === 'companies' ? 'Company' : 'Person'}
                </Button>
              </Link>
              <Button variant="text" onClick={handleClose} color="secondary" className={classes.closeButton}>
                Close
              </Button>
            </Box>
          </Success>
        </Route>
        <Route path={`${Paths._merge}/:entity/find`} component={Select} />
        <Route component={Component} />
      </Switch>
    </Page>
  )
}

export default MergePage
