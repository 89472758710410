import React, { memo, useRef, useEffect, ReactElement } from 'react'

import { Box } from '@mui/material'
import { areEqual, VariableSizeList as List } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import { SkeletonProps } from '_shared/Skeleton'

import EmptyText from '_core/components/EmptyText'

import useVirtualized from '_core/hooks/useVirtualized'

import Item, { ItemVariant } from './Item'

const useStyles = makeStyles()((theme) => ({
  list: {
    counterReset: 'list-number'
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  horizontal: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: `${theme.spacing(1)} 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)} 0`
    }
  },
  vspacing: {
    marginTop: theme.spacing(1)
  },
  hspacing: {
    marginTop: theme.spacing(1)
  }
}))

const VItem = memo((props: any) => {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const { index, style: virtualizedStyle, data } = props
  const { setSize, variant, direction, renderItems, component, addprops, disablePadding } = data

  useEffect(() => {
    if (setSize && rootRef.current) {
      setSize(index, rootRef.current.clientHeight)
    }
  }, [setSize, index])

  return (
    <Box style={virtualizedStyle}>
      <Item
        ref={rootRef}
        disablePadding={disablePadding}
        virtualized
        variant={variant}
        direction={direction}
        addprops={addprops}
        index={index}
        item={renderItems[index] || {}}
        component={component}
      />
    </Box>
  )
}, areEqual)

const Repeater = (props: {
  items: any[]
  direction?: 'vertical' | 'horizontal'
  component: React.FC<any>
  skeleton: SkeletonProps
  variant?: ItemVariant
  addprops?: any
  empty?: string | ReactElement
  className?: string
  virtualized?: boolean
  disablePadding?: boolean
}) => {
  const { skeleton, items, direction, variant, empty, addprops, disablePadding } = props
  const { classes, cx } = useStyles()
  const { list, vertical, vspacing, horizontal, hspacing } = classes
  const wrapper = direction === 'horizontal' ? horizontal : vertical
  const spacing = direction === 'horizontal' ? hspacing : vspacing
  const renderItems = skeleton.loading ? new Array(skeleton.size).fill({}) : items
  const virtualizedData = useVirtualized(true, { renderItems, direction, variant, disablePadding, addprops, component: props.component })

  if (!skeleton.loading && (!items || items.length === 0)) return empty && typeof empty !== 'string' ? empty : <EmptyText text={empty} />

  return (
    <Box className={cx(props.className, list, wrapper, variant !== 'homepage' || spacing)}>
      {(!props.virtualized || skeleton.loading) &&
        renderItems.map((item, i) => (
          <Item
            key={i}
            variant={variant}
            disablePadding={disablePadding}
            direction={direction}
            addprops={addprops}
            item={item}
            index={i}
            component={props.component}
          />
        ))}
      {props.virtualized && !skeleton.loading && (
        <List itemCount={renderItems.length} height={window.innerHeight} width={window.innerWidth} overscanCount={5} {...virtualizedData}>
          {VItem}
        </List>
      )}
    </Box>
  )
}

export default Repeater
