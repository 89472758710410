import React, { ChangeEvent, useCallback, useContext, useState } from 'react'

import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDropzone } from 'react-dropzone'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import UploadingContent, { Dragzone } from '_core/components/upload/UploadingContent'
import { FILE_UPLOADING_STATUS } from '_core/components/upload/UploadingProgressBar'

import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { getBinary, postRaw } from 'utils/httpUtils'
import { triggerDownloadXl } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  link: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    '&, &:hover': {
      textDecoration: 'none'
    }
  },
  linkBtn: {
    padding: 0,
    fontSize: 'inherit',
    lineHeight: 1.43
  },
  input: {
    whiteSpace: 'pre-wrap',
    overflowY: 'auto'
  }
}))

const agentsMap = {
  contacts: {
    templateEndpoint: '/people/contactstemplatexl',
    supsertxlEndpoint: '/people/contactsupsertxl',
    fileName: 'ContactsUploader'
  },
  companies: {
    templateEndpoint: '/companies/accountstemplatexl',
    supsertxlEndpoint: '/companies/accountsupsertxl',
    fileName: 'CompaniesUploader'
  },
  deals: {
    templateEndpoint: '/ourdeals/ourdealstemplatexl',
    supsertxlEndpoint: '/ourdeals/dealsupsertxl',
    fileName: 'DealsUploader'
  },
  peopleDataQuality: {
    supsertxlEndpoint: '/dataquality/peopledecisionsxl',
    templateEndpoint: '',
    fileName: ''
  },
  companiesDataQuality: {
    supsertxlEndpoint: '/dataquality/companiesdecisionsxl',
    templateEndpoint: '',
    fileName: ''
  }
}

export type UploadAgentsType = keyof typeof agentsMap

const UploadAgent = ({ agent, noAdditionalFields }: { agent: UploadAgentsType; noAdditionalFields?: boolean }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<FILE_UPLOADING_STATUS>()
  const [error, setError] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const { updateParent } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()
  const { teamContextValue } = useContext(TeamContext)
  const handleClose = useSidepanelClose()
  const { classes } = useStyles()

  const sidepanel = isSidepanel('sidepanel')
  const { templateEndpoint, supsertxlEndpoint, fileName } = agentsMap[agent]
  const teamNumber = teamContextValue.teamNumber.toString()

  const getTemplate = async () => {
    const message = 'Downloading template...'
    if (sidepanel) {
      updateParent({ action: 'START_FILE_TRANSFER', value: { message } })
    } else {
      enqueueSnackbar(message, {
        autoHideDuration: 4000
      })
    }

    try {
      const fetchedTemplate = await getBinary(templateEndpoint, { teamNumber })
      triggerDownloadXl(fetchedTemplate, fileName)
    } catch (error) {
      console.log('error during download', error)
      const errorMsg = 'Encountered error during downloading'
      if (sidepanel) {
        updateParent({ action: 'START_FILE_TRANSFER', value: { message: errorMsg, variant: 'error' } })
      } else {
        enqueueSnackbar(errorMsg, {
          variant: 'error'
        })
      }
    }
  }

  const accessibleFormats = ['xlsx']

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setDescription(value)
  }

  const handleLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setLabel(value)
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(function (file: File) {
      setFile(file)
      setError('')

      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

      if (accessibleFormats.indexOf(fileExtension) === -1) {
        setUploadStatus(FILE_UPLOADING_STATUS.FILE_HAS_ERROR)
        setError(`The file type is wrong (${fileExtension}). We supports the following file name extensions: ${accessibleFormats.join(', ')}.`)
      } else {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          setUploadStatus(FILE_UPLOADING_STATUS.FILE_IS_READY_FOR_UPLOAD)
        }
        reader.readAsBinaryString(file)
      }
    })
  }, [])

  const dragZoneState = useDropzone({ onDrop })

  async function sendFile(payload: { file: File; description: string; label: string }, callback: (response: any) => void) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')

    const params = { teamNumber, ...(noAdditionalFields ? {} : { description, label }) }

    postRaw(supsertxlEndpoint, formData, params)
      .then(callback)
      .catch((error) => {
        console.log('error during uploading', error)
        const errorMsg = 'Encountered error during uploading'
        if (sidepanel) {
          updateParent({ action: 'START_FILE_TRANSFER', value: { message: errorMsg, variant: 'error' } })
        } else {
          enqueueSnackbar(errorMsg, {
            variant: 'error'
          })
        }
      })
  }

  const handleUploadButton = () => {
    if (file) {
      setUploadStatus(FILE_UPLOADING_STATUS.FILE_IS_UPLOADING)
      sendFile({ file, description, label }, () => {
        setFile(null)
        setUploadStatus(FILE_UPLOADING_STATUS.FILE_HAD_UPLOADED)
        history.replace(`${match.url}/success`)
      })
    }
  }

  const uploadButtonIsDisabled = !!error || !file

  const additionalFields = (
    <Box minHeight={250}>
      <Box mt={2}>
        <TextField
          label="Title"
          value={label}
          onChange={handleLabelChange}
          placeholder="A title for this upload"
          inputProps={{ className: classes.input }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          label="Description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Optional description of the upload"
          inputProps={{ className: classes.input }}
          fullWidth
          multiline
          rows={3}
        />
      </Box>
    </Box>
  )

  const alertTemplateTitle = (
    <Typography>
      The uploaded file must be formatted as per the predefined template. Please{' '}
      <Button variant="link" className={classes.linkBtn} onClick={getTemplate}>
        download
      </Button>{' '}
      the template first.
    </Typography>
  )

  return (
    <UploadingContent
      {...{
        handleUploadButton,
        uploadButtonIsDisabled,
        error,
        close: handleClose,
        ...(templateEndpoint ? { alertTitle: alertTemplateTitle } : {})
      }}
    >
      <Dragzone
        uploadStatus={uploadStatus}
        error={error}
        file={file}
        dragZoneState={dragZoneState}
        accessibleFormats={accessibleFormats}
        addContent={noAdditionalFields ? null : additionalFields}
      />
    </UploadingContent>
  )
}

export default UploadAgent
