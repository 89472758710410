import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  select: {
    maxWidth: 200
  },
  period: {
    flexWrap: 'wrap',
    display: 'flex'
  },
  periodLabel: {
    marginRight: theme.spacing(1)
  },
  radioGroup: {
    marginRight: `-${theme.spacing(2)}`
  },
  dates: {
    flexGrow: 1,
    '& > div': {
      '&:first-of-type': {
        marginBottom: theme.spacing(1)
      },
      '& > *': {
        maxWidth: 250,
        width: '100%'
      }
    }
  }
}))

export default useStyles
