import { PropsWithChildren } from 'react'

import { Box, Collapse } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

type ContainerProps = PropsWithChildren<{
  title?: string
  collapsed: boolean
  className?: string
}>

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%'
  },
  border: {
    borderBottom: `2px solid ${theme.palette.background.darker}`
  }
}))

const CollapseContainer = ({ title, collapsed, children, className }: ContainerProps) => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <Collapse in={!collapsed}>
        <Box className={cx(classes.border, className)}>
          {title && <Typography bold>{title}</Typography>}
          {children}
        </Box>
      </Collapse>
    </div>
  )
}

export default CollapseContainer
