import React, { useEffect, useRef, useState } from 'react'

import { Snackbar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '_shared/Dialog'
import Typography from '_shared/Typography'

import { getToken } from 'Auth'

const useStyles = makeStyles()((theme) => ({
  tokenContainer: {
    overflowY: 'scroll',
    padding: 4,
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary,
    wordBreak: 'normal',
    wordWrap: 'break-word',
    boxSizing: 'border-box'
  } as any
}))

const TokenModal = ({ opened, close }: { opened: boolean; close: () => void }) => {
  const selection = window?.getSelection()

  const [showSnackbar, setShowSnackbar] = useState(false)
  const tokenRef = useRef(null)

  const { classes } = useStyles()

  const copyToken = () => {
    if (tokenRef != null && tokenRef.current != null) {
      const range = document.createRange()
      range.selectNode(tokenRef.current)

      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
      document.execCommand('copy')

      setShowSnackbar(true)
    }
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false)
  }

  const goToSwagger = () => {
    window.open('/swagger/index.html', '_blank')
  }

  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Authorization token" />} maxWidth={630}>
      <DialogContent>
        <Typography variant="body1" className={classes.tokenContainer} ref={tokenRef}>
          <RenderToken />
        </Typography>
      </DialogContent>
      <DialogActions>
        {selection && (
          <Button variant="text" onClick={copyToken}>
            COPY TOKEN
          </Button>
        )}
        <Button variant="text" onClick={goToSwagger} disablePR>
          OPEN API CONSOLE
        </Button>
      </DialogActions>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key="token-snackbar"
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message="Toked copied in Clipboard"
      />
    </Dialog>
  )
}

export default TokenModal

const RenderToken = () => {
  const [token, setToken] = useState('')

  useEffect(() => {
    async function get() {
      getToken().then((resp: any) => setToken(resp.accessToken))
    }
    get()
  }, [])

  return <>Bearer {token}</>
}
