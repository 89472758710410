import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'

import { Button } from '_shared/buttons'
import DatePicker from '_shared/DatePicker'
import Dialog, { DialogTitle, DialogActions, DialogContent, DefaultSuccessAction } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import { renderStatusOption, StatusOption } from '_core/components/introductions/options'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

const TriggerEl = (props: { open: () => void }) => (
  <Button onClick={props.open} startIcon={<FontAwesomeIcon icon={['far', 'edit']} style={{ fontSize: 14 }} />} variant="link" disablePT>
    Change stage
  </Button>
)

const unusedStatuses = ['IntroducerSelected']
export const withoutIntroducerStatuses = ['AssigneeAskingIntroducers', 'AssigneeIntroIdeasSought']

const saveWithIntroducer = async (
  planUid: string,
  contactId: string,
  introducer: string,
  eventMemo: string,
  eventTime: MomentType | null,
  status: string,
  setEvents: DialogProps['setEvents']
) => {
  const date = getLocal()
  const resp = await post<IntroEventResp[]>('/prospecting/withintroducer', [
    {
      planUid,
      status,
      eventMemo,
      contactIdentifier: contactId,
      introducerEmail: introducer,
      ...(eventTime ? { eventTime } : {}),
      ...(status === 'IntroducerReachedOut' ? { reachedOut: date } : {}), //If the introducer reached out to the contact, then set IntroducerReachedOut to now.
      ...(status === 'IntroductionSuccess' ? { fullyConnected: date } : {}), //If the requester is in touch with the contact (either thanks to direct or an introducer) then set FullyConnected. If it happened via an introducer, then I believe that should be IntroductionSuccess.
      ...(status !== 'IntroductionSuccess' ? { setFullyConnectedExplicitlyNull: true } : {}),
      ...(status === 'IntroducerSelected' ? { setReachedOutExplicitlyNull: true } : {})
    }
  ])
  if (resp) {
    const response = resp[0]
    setEvents((prevState) => [
      ...(prevState ? prevState : []),
      {
        date: response.introEventTime,
        author: response.introEventAuthorEmail,
        memo: response.introEventMemo,
        status: response.targetingStatusAfter,
        introducer: response.targetingStatusAfter === 'IntroducerSelected' ? response.introducerEmail : '',
        key: response.introEventTime
      }
    ])

    return response.targetingStatusAfter
  }
}

type DialogProps = {
  introducer: string
  close: () => void
  isOpened: boolean
  openSuccess: () => void
  successMode: boolean
  setEvents: Dispatch<SetStateAction<IntroductionEventType[] | undefined>>
  status?: string
}

const today = getLocal()

const IntroductionActivityDialog = ({ introducer, close, isOpened, openSuccess, successMode, setEvents, ...props }: DialogProps) => {
  const { id: planId, contactId } = useParams<{ id: string; contactId: string }>()
  const [isSaving, setSaving] = useState<boolean>(false)
  const [memo, setMemo] = useState('')
  const [date, setDate] = useState<MomentType | null>(null)
  const [status, setStatus] = useState<StatusOption | null>(null)
  const { fetchWithAbort, loading: statusesLoading, result: statuses } = useAbortableFetch<StatusOption[]>()
  const { updateParent } = useSidepanelPayloads()

  const loading = isSaving || statusesLoading

  useEffect(() => {
    fetchWithAbort({ url: '/prospecting/introducerstatuses' })
  }, [fetchWithAbort])

  useEffect(() => {
    if (isOpened) {
      if (props.status && statuses?.length) {
        setStatus(statuses.find(({ value }) => value === props.status) || null)
      }

      return () => {
        setMemo('')
        setDate(null)
        setStatus(null)
      }
    }
  }, [isOpened, props.status, statuses?.length])

  const onMemoChange = (e: any) => setMemo(e.target.value)

  const save = async () => {
    if (planId && contactId && status?.value) {
      setSaving(true)
      const updatedStatus = await saveWithIntroducer(planId, contactId, introducer, memo, date, status.value, setEvents)
      if (updatedStatus) {
        updateParent({
          action: 'UPDATE_INTRODUCTION_DETAILS',
          value: {
            planUid: planId,
            contactId: contactId,
            fieldName: 'targetingStatus',
            value: status
          }
        })
      }
      openSuccess()
      setSaving(false)
    }
  }

  const updateStatus = (e: ChangeEvent<{}>, newValue: typeof status) => {
    setStatus(newValue)
  }

  const options = useMemo(() => statuses?.filter((o) => ![...unusedStatuses, ...withoutIntroducerStatuses].includes(o.value)), [introducer, statuses])

  const onDateChange = (date: MomentType | null) => {
    const currentTime = getLocal()
    const updatedDate = date ? date.hour(currentTime.hour()).minute(currentTime.minute()).second(currentTime.second()) : null
    setDate(updatedDate)
  }

  return (
    <Dialog open={isOpened} onClose={close} loading={loading} title={<DialogTitle title="Pick stage" />} success={successMode}>
      <DialogContent successContent={<Typography>The timeline has been updated</Typography>}>
        <Box mb={2}>
          <Combobox<typeof status, false, boolean>
            onChange={updateStatus}
            value={status}
            fullWidth
            options={options || []}
            renderOption={renderStatusOption}
            disableClearable={!!status}
            filterOptions={(options) => options.filter((option) => status && option && status.value !== option.value)}
          />
        </Box>
        <Box mb={2}>
          <DatePicker label="Date" value={date || today} onChange={onDateChange} ActionBar={null} size="medium" fullWidth disableFuture />
        </Box>
        <TextField label="Memo (optional)" disabled={loading} value={memo} onChange={onMemoChange} multiline rows={3} fullWidth />
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={close} />}>
        <Button variant="text" color="secondary" onClick={close} disablePL>
          Cancel
        </Button>
        <Button variant="text" onClick={save} disabled={!status || props.status === status.value} disablePR>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IntroductionActivityDialog.TriggerEl = TriggerEl

export default IntroductionActivityDialog
