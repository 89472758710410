import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField , TextFieldProps} from '@mui/material'
import * as _ from "lodash";

type Props = {
  textFieldProps: TextFieldProps;
  max: number;
  text?: string | JSX.Element;
  hint?: string | JSX.Element;
  reset?: boolean | undefined;
  setValue?: (value: any) => void;
  seed: (value: string) => number;
  trim?: boolean | undefined;
};

export default function TextFieldHelper(props: Props) {

  const { max, text, hint, setValue, reset, seed, trim } = props
  const { onChange, ...textFieldProps } = props.textFieldProps;

  const [counter, setCounter] = useState(0);

  const handleInput = (e: any) => {

    if (!trim)
      return;

    const { target } = e
    const { value } = target

    if (setValue)
      setValue(value.trim());

    setCounter(seed(value.trim()));

  };

  //
  // useCallback prevents the debounce event from being
  // recreated on every update, essencially every key press
  //

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleInput = useCallback(_.debounce(handleInput, 4000), []);

  const handleChange = (e: any) => {

    const { target } = e
    const { value } = target

    setCounter(seed(value))

    e.persist()
    debounceHandleInput(e)
    if(onChange) {
      onChange(e)
    }
  }

  useEffect(() => {
    if (reset) {
      if (setValue)
        setValue('');
      setCounter(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, JSON.stringify(setValue)]);

  return (
    <TextField
      {...textFieldProps}
      onChange={handleChange}
      error={counter > max}
      helperText={
        <Box component="span" display="flex" justifyContent="space-between">
          <span>{counter > max ? (text ? text : hint) : hint}</span>
          <span>{counter} / {max}</span>
        </Box>}
    />
  );
}
