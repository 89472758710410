import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'

import { latestTouchpointKey, firstTouchpointKey, nextTouchpointKey } from '_core/helpers/touchpoints'

import Paths from 'Paths'

const TouchpointRelationSummary = (stats: any) => {
  const lastkey = stats ? latestTouchpointKey(stats) : ''
  const firstkey = stats ? firstTouchpointKey(stats) : ''
  const nextkey = stats ? nextTouchpointKey(stats) : ''

  return (
    stats &&
    [
      nextkey && {
        title: 'Upcoming',
        description: (
          <>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
            <NameLink url={`${Paths._people}/${stats[`${nextkey}${'UserKeyMd5'}`]}`} name={stats[`${nextkey}${'UserName'}`]} preposition="with" />
          </>
        ),
        blurb: moment(stats[nextkey]).format('DD MMMM YYYY')
      },
      lastkey && {
        title: 'Latest',
        description: (
          <>
            <FontAwesomeIcon
              icon={lastkey === 'LastMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
              size="sm"
              color="#1B95BB"
              style={{ marginRight: '8px' }}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'ContactMd5' : 'UserKeyMd5'}`]}`}
              name={stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'ContactName' : 'UserName'}`]}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'UserKeyMd5' : 'ContactMd5'}`]}`}
              name={stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'UserName' : 'ContactName'}`]}
              preposition={lastkey === 'LastMeeting' ? 'met' : 'to'}
            />
          </>
        ),
        blurb: moment(stats[lastkey]).format('DD MMMM YYYY')
      },
      firstkey && {
        title: 'First',
        description: (
          <>
            <FontAwesomeIcon
              icon={firstkey === 'FirstMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
              size="sm"
              color="#1B95BB"
              style={{ marginRight: '8px' }}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'ContactMd5' : 'UserKeyMd5'}`]}`}
              name={stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'ContactName' : 'UserName'}`]}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'UserKeyMd5' : 'ContactMd5'}`]}`}
              name={stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'UserName' : 'ContactName'}`]}
              preposition={firstkey === 'FirstMeeting' ? 'met' : 'to'}
            />
          </>
        ),
        blurb: moment(stats[firstkey]).format('DD MMMM YYYY')
      }
    ].filter((key) => key)
  )
}

export default TouchpointRelationSummary
