import { makeStyles } from 'tss-react/mui'

import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import theme from 'AppTheme'

const useStyles = makeStyles()(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  }
}))

const ActivitiesStatsCard = ({ header, amount, loading }: { header: string; amount: number; loading: boolean }) => {
  const { classes } = useStyles()

  return (
    <Card variant="outlined">
      <CardContent classes={{ root: classes.content }}>
        <Skeleton condition={loading} width={20}>
          <Typography semiBold variant="h3">
            {amount}
          </Typography>
        </Skeleton>

        <Typography color="text.secondary">{header}</Typography>
      </CardContent>
    </Card>
  )
}

export default ActivitiesStatsCard
