import React, { useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import UploadAgent, { UploadAgentsType } from '_core/components/UploadAgent'
import UploadSuccess from '_core/components/UploadSuccess'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import { DataAdminSubPaths } from 'Paths'

const baseUrl = DataAdminSubPaths.dataQuality
const agentsMap: {
  [key in DataQualityEntities]: {
    agent: UploadAgentsType
    successPath: string
    header: string
  }
} = {
  people: {
    agent: 'peopleDataQuality',
    successPath: `${baseUrl}/people/upload/success`,
    header: 'Upload people report'
  },
  companies: {
    agent: 'companiesDataQuality',
    successPath: `${baseUrl}/companies/upload/success`,
    header: 'Upload companies report'
  }
}

const UploadDataQualityPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { entity } = useParams<{ entity: DataQualityEntities }>()

  const { agent, successPath, header } = agentsMap[entity]

  useEffect(() => {
    setMobileHeader(header)
  }, [setMobileHeader, header])

  return (
    <Narrow>
      <Topbar nativeBack />
      <Box height="calc(100vh - 88px)">
        <Switch>
          <Route path={successPath}>
            <UploadSuccess agent="" text="Your upload was successfully queued for processing" />
          </Route>
          <Route>
            <UploadAgent agent={agent} noAdditionalFields />
          </Route>
        </Switch>
      </Box>
    </Narrow>
  )
}

export default UploadDataQualityPage
