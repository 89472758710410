import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import PersonData from '_core/components/market-data/PersonData'
import ReportIncorrectData from '_core/components/market-data/Report'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

interface PersonMarketDataProps {
  person: { [key: string]: any } | null
  error?: string
  urlType?: 'people' | 'companies'
}

const PersonMarketData = ({ error, person, urlType }: PersonMarketDataProps) => (
  <>
    {!error && (
      <>
        <Widget>
          <UnknownProfileSummary title={person?.full_name} userKey={person?.work_email} loading={!person?.full_name} />
        </Widget>
        <Widget>
          <Heading underlined title="Details" icon={['fas', 'chart-column']} count={0} />
          <PersonData
            urlType={urlType}
            id={person?.id}
            fullName={person?.full_name}
            industry={person?.industry}
            locationName={person?.location_name}
            twitterUrl={person?.twitter_url}
            linkedinUrl={person?.linkedin_url}
            jobTitle={person?.job_title}
            jobCompanyMd5={person?.jobCompanyMd5}
            jobCompanyName={person?.job_company_name}
            jobCompanyWebsite={person?.job_company_website}
            jobCompanySize={person?.job_company_size}
            jobCompanyLocationName={person?.job_company_location_name}
            jobCompanyIndustry={person?.job_company_industry}
            jobCompanyFacebookUrl={person?.job_company_facebook_url}
            jobCompanyTwitterUrl={person?.job_company_twitter_url}
            jobCompanyLinkedinUrl={person?.job_company_linkedin_url}
            workEmail={person?.work_email}
            skills={person?.skills}
            interests={person?.interests}
            education={person?.education}
            experience={person?.experience}
            phoneNumbers={person?.phone_numbers}
            mobilePhone={person?.mobile_phone}
          />
        </Widget>
      </>
    )}
    {error && (
      <Widget>
        <Empty title={error} />
      </Widget>
    )}
    <ReportIncorrectData marketData={JSON.stringify(person)} />
  </>
)

export default PersonMarketData
