import { useRef, useContext, useMemo } from 'react'

import { ParamsValidationContext } from '_core/context/ParamsValidation'

import { stringifyUrl, parseUrl } from '_core/helpers/browser'

type ModifiedVariants =
  | IncludeEvents[]
  | IncludePeopleType[]
  | Extract<IncludeInteractions, 'inbound' | 'outbound'>[]
  | Entities[]
  | IncludeDealsType[]
  | IncludeDeals[]

type Modified<C extends { modifyProps: ModifiedVariants }> = C['modifyProps'][number]
type ParamsType<T, C> = C extends { modifyProps: any[] }
  ? Omit<T, keyof C['modifyProps'][number]> & { [key in keyof Modified<C>]?: Modified<C>[key] }
  : T
type Props<C> = C extends { modifyProps: unknown[] } ? (keyof C['modifyProps'][number])[] : never

const useSearchQuery = <T, C extends { modifyProps?: Partial<{ [key in keyof T]: ModifiedVariants }>[] } = {}>(candidatesToTransform?: Props<C>) => {
  const { queryParams, updateQuery } = useContext(ParamsValidationContext)
  const { current: memoCandidates } = useRef<Props<C> | undefined>(candidatesToTransform)

  const rQueryParams = useMemo(() => parseUrl(stringifyUrl('', queryParams), memoCandidates) as ParamsType<T, C>, [queryParams, memoCandidates])

  return {
    queryParams: rQueryParams,
    updateQuery: updateQuery as (params: ParamsType<T, C>, state?: { [key: string]: any } | undefined) => void
  }
}

export default useSearchQuery
