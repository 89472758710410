import { useState, useCallback, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { formatDate, getLocal } from 'utils/Utils'

type InitialParams = Modify<ActivitiesPageParams, { customList?: string[]; isOpened: boolean }>

type CustomLists = Pick<ActivitiesInit, 'companiesCustomList' | 'contactsCustomList' | 'contributorsList'> | undefined

const today = getLocal()

const activitiesSaveData = {
  endpoint: '/usersettings/activitiesfilter',
  getData: (queryParams: InitialParams, getCustomLists: (queryParams: InitialParams) => CustomLists): ActivitiesInit => {
    const { companiesCustomList = [], contactsCustomList = [], contributorsList = [] } = getCustomLists(queryParams) || {}
    const { interaction: touchpointType = 'Any', view = 'Top', groupBy = 'None', isOpened } = queryParams

    return {
      touchpointType,
      view,
      groupBy,
      companiesCustomList,
      contactsCustomList,
      contributorsList,
      isOpened
    }
  }
}

const useActivitiesUserSettings = () => {
  const wide = useWide()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { view, groupBy } = queryParams
  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams] = useState<ActivitiesInit>()
  const [isOpened, setOpened] = useState<boolean>(false)

  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<ActivitiesInit>()

  const getCustomListQParam = useCallback((params: ActivitiesInit) => {
    const { groupBy, view, contactsCustomList, companiesCustomList, contributorsList } = params
    return [
      { list: contactsCustomList, condition: view === 'Custom' && groupBy === 'Contact' },
      { list: companiesCustomList, condition: view === 'Custom' && groupBy === 'Company' },
      { list: contributorsList, condition: groupBy === 'Contributor' }
    ].find(({ condition }) => !!condition)?.list
  }, [])

  const getSaveDataCustomLists = (
    updates: typeof queryParams
  ): Pick<ActivitiesInit, 'companiesCustomList' | 'contactsCustomList' | 'contributorsList'> | undefined => {
    if (params) {
      const listsParamsToSave: { key: keyof ActivitiesInit; condition: boolean }[] = [
        { key: 'contactsCustomList', condition: view === 'Custom' && groupBy === 'Contact' },
        { key: 'companiesCustomList', condition: view === 'Custom' && groupBy === 'Company' },
        { key: 'contributorsList', condition: groupBy === 'Contributor' }
      ]

      return listsParamsToSave.reduce(
        (acc, { key, condition }) => ({
          ...acc,
          [key]: condition && 'customList' in updates ? updates.customList : params[key]
        }),
        {} as Pick<ActivitiesInit, 'companiesCustomList' | 'contactsCustomList' | 'contributorsList'>
      )
    }
  }

  const getInitialParams = useCallback(
    (data: ActivitiesInit): InitialParams => {
      const { touchpointType, groupBy, view, isOpened } = data

      return {
        interaction: touchpointType === 'Any' ? undefined : touchpointType,
        groupBy: groupBy === 'None' ? undefined : groupBy,
        view,
        customList: getCustomListQParam(data),
        isOpened
      }
    },
    [getCustomListQParam]
  )

  const handleChange = (updates: typeof queryParams, additionalParams?: { [key in 'isOpened']?: boolean } | undefined) => {
    if (params) {
      const { isOpened = params?.isOpened } = additionalParams || {}
      const newParams = activitiesSaveData.getData({ ...queryParams, ...updates, isOpened }, getSaveDataCustomLists)
      save({ ...activitiesSaveData, getData: () => newParams }, { ...queryParams, ...updates, customList: getCustomListQParam(newParams) })
      setParams(newParams)
    }
  }

  useEffect(() => {
    if (!search && params) {
      const { isOpened, ...initialParams } = getInitialParams(params)
      if (wide) {
        setOpened(isOpened)
      }
      updateQuery({ ...initialParams, date: formatDate(today) })
    }
  }, [search, updateQuery, params, getInitialParams, wide])

  const setInitial = (data: ActivitiesInit) => {
    if (wide) {
      setOpened(data.isOpened)
    }
    setParams(data)
    setLoading(false)
  }

  const toggleOpen = () => {
    const newState = !isOpened
    setOpened(newState)
    if (wide) {
      setParams((prevState) => (prevState ? { ...prevState, isOpened: newState } : prevState))
      handleChange({}, { isOpened: newState })
    }
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/activitiesfilter' })

    if (defaultData) {
      if (wide) {
        setOpened(defaultData.isOpened)
      }
      setParams(defaultData)
      const { isOpened, ...defaultParams } = getInitialParams(defaultData)
      const newParams = activitiesSaveData.getData(getInitialParams(defaultData), getSaveDataCustomLists)
      await save({ ...activitiesSaveData, getData: () => newParams }, { ...defaultParams, date: formatDate(today) })
      setLoading(false)
    }
  }

  return {
    setInitial,
    reset,
    handleChange,
    toggleOpen,
    loading,
    opened: isOpened
  }
}

export default useActivitiesUserSettings
