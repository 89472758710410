import { useState } from 'react'

import { Box } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

import { IconButton } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import TextField, { URLTextField } from '_shared/forms/TextField'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

import { addMissedProtocol, getRidOfProtocol, validUrl } from '_core/helpers/string'

const MarketDataSettings = (props: {
  loading: boolean
  marketDataIntegration?: {
    enabled: boolean
    showSimilarPeople: boolean
    showSimilarCompanies: boolean
  }
  dotAlignCloudHub?: {
    url: string
    apiKey: string
  }
}) => {
  const {
    classes: { itemWrapper }
  } = useStyles()

  const [isApiKeyShown, setApiKeyShown] = useState<boolean>(false)
  const { isSaving, save: saveSettings } = useSaveSettings()

  const {
    register,
    watch,
    setValue,
    control,
    formState: { dirtyFields },
    reset
  } = useForm<{ url?: string; apiKey?: string; enabled?: boolean; showSimilarPeople?: boolean; showSimilarCompanies?: boolean }>({
    mode: 'onChange',
    defaultValues: props.loading
      ? {}
      : { ...{ ...props.dotAlignCloudHub, url: getRidOfProtocol(props.dotAlignCloudHub?.url) }, ...props.marketDataIntegration } || {}
  })

  const { url, apiKey, enabled, showSimilarCompanies, showSimilarPeople } = watch()

  const save = async (action: SaveAction) => {
    const promises = [
      {
        endpoint: '/adminsettings/marketDataIntegration',
        payload: { enabled, showSimilarCompanies, showSimilarPeople },
        condition: dirtyFields['enabled'] || dirtyFields['showSimilarCompanies'] || dirtyFields['showSimilarPeople']
      },
      {
        endpoint: '/adminsettings/dotAlignCloudHub',
        payload: { url: addMissedProtocol(url), apiKey },
        condition: dirtyFields['url'] || dirtyFields['apiKey']
      }
    ].filter(({ condition }) => !!condition)

    try {
      await Promise.all(promises.map(({ endpoint, payload }) => saveSettings(endpoint, payload)))
      action('Market data settings')
      reset(watch())
    } catch {
      action('Market data settings', 'error')
    }
  }

  const toggleApiKeyShow = () => {
    setApiKeyShown((prevState) => !prevState)
  }

  const handleEnabledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    callback?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  ) => {
    if (!checked) {
      setValue('showSimilarCompanies', false)
      setValue('showSimilarPeople', false)
    }
    if (callback) {
      callback(e, checked)
    }
  }

  const disabled = isSaving || !enabled

  const isInvalid = !!(url && dirtyFields?.url && !validUrl(url))
  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading} saveDisabled={isInvalid}>
      <Controller
        name="enabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={!!value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleEnabledChange(event, checked, onChange)}
            disabled={isSaving}
            label="Enable market data integration"
          />
        )}
      />
      <Box pl={4} width={1} boxSizing="border-box">
        <URLTextField
          {...register('url')}
          label="Hub URL"
          placeholder="Enter Hub URL"
          size="small"
          disabled={disabled}
          fullWidth
          errorMessage={isInvalid ? 'This field is invalid' : ''}
        />
        <Box className={itemWrapper}>
          <TextField
            {...register('apiKey')}
            label="API key"
            type={isApiKeyShown ? 'text' : 'password'}
            placeholder="Enter Hub API key"
            size="small"
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <IconButton
                  disablePadding
                  hint={`${isApiKeyShown ? 'Hide' : 'Show'} API key`}
                  icon={['far', isApiKeyShown ? 'eye-slash' : 'eye']}
                  onClick={toggleApiKeyShow}
                  size="small"
                  disabled={disabled}
                />
              )
            }}
          />
        </Box>
      </Box>
      <Box ml={4} display="flex" flexDirection="column" className={itemWrapper}>
        <Controller
          name="showSimilarCompanies"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox checked={!!value} onChange={onChange} disabled={disabled} label="Show similar companies and C-Suite inside company profile" />
          )}
        />
        <Controller
          name="showSimilarPeople"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox checked={!!value} onChange={onChange} disabled={disabled} label="Show similar people inside people profile" />
          )}
        />
      </Box>
    </Settings>
  )
}

export default MarketDataSettings
