import React, { HTMLAttributes, useCallback, useRef, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import Combobox from '_shared/forms/Combobox'
import Radio from '_shared/forms/Radio'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { renderPersonOption } from '_core/components/introductions/options'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Settings from '_core/components/settings'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { IntroductionVisibility } from '_core/hooks/useIntroductionsAccess'
import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

import { post, put } from 'utils/httpUtils'

type ManagerActionType = {
  icon: IconProp
  onClick: () => void
  label: string
  hint: string
  condition: boolean
  color: 'primary' | 'secondary' | 'success'
}

type Manager = {
  displayName: string
  emailAddress: string
}

const useStyles = makeStyles()((theme) => ({
  managerItem: {
    [theme.breakpoints.up('md')]: {
      '& > div': {
        gridTemplateColumns: '48px minmax(0, 2fr) minmax(0, 5fr)'
      }
    }
  },
  subtitle: {
    paddingBottom: 0
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `-${theme.spacing(1)}`
  }
}))

const IntroductionsSettings = ({
  loading,
  list,
  defaultManager,
  settings,
  profile
}: {
  loading: boolean
  list: Manager[]
  defaultManager: { userKey?: string }
  settings: { visibility: IntroductionVisibility }
  profile: ProfileType
}) => {
  const { updateUserIntroductionsAccess } = useNavBarMenuItemsShown()
  const [isSaving, setSaving] = useState(false)
  const [defManager, setDefManager] = useState<string | undefined>(defaultManager?.userKey)
  const initList = useRef(list)
  const { enqueueSnackbar } = useSnackbar()
  const {
    watch,
    control,
    reset,
    getValues,
    formState: { isDirty: isVisibilityChanged }
  } = useForm<{ level: IntroductionVisibility; enabled: boolean }>({
    defaultValues: loading
      ? { level: 'AdminsAndNetworkTeam', enabled: false }
      : { level: settings.visibility === 'Off' ? 'AdminsAndNetworkTeam' : settings.visibility, enabled: settings.visibility !== 'Off' }
  })
  const { classes } = useStyles()

  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    updateValue: updateManagers
  } = useAsyncCombobox<Manager, true>({
    initialValue: list,
    loadOptions: useCallback(
      async (searchTerm) => {
        const result = await lookUpActiveDirectoryMembers(searchTerm)
        if (result) {
          return result.users.map((user) => user)
        }
      },
      [lookUpActiveDirectoryMembers]
    ),
    forceAbort
  })

  const managers = value || []
  const enabled = watch('enabled')

  const removeManager = async (email: string) => {
    updateManagers(managers.filter(({ emailAddress }) => emailAddress !== email))
  }

  const saveManagersUpdates = async () => {
    await post('/prospecting/defaultmanager', {
      userKey: defManager,
      role: 'ProspectingManager'
    })

    const removedManagers = initList.current.filter(({ emailAddress }) => !managers.find((manager) => manager.emailAddress === emailAddress))
    if (removedManagers.length) {
      await post('/prospecting/managers', {
        userKeys: removedManagers.map((manager) => manager.emailAddress),
        role: 'ProspectingManager',
        unassign: true
      })
    }

    if (managers.length) {
      await post('/prospecting/managers', {
        userKeys: managers.map((manager) => manager.emailAddress),
        role: 'ProspectingManager'
      })
    }

    initList.current = managers
  }

  const save = async () => {
    setSaving(true)
    const { level } = getValues()
    const visibility = enabled ? level : 'Off'

    if (isVisibilityChanged) {
      await put('/adminsettings/introductions', { visibility })
      reset({}, { keepValues: true })
    }
    await saveManagersUpdates()
    updateUserIntroductionsAccess([{ visibility }, profile, managers])
    enqueueSnackbar('Introductions settings have been updated')
    setSaving(false)
  }

  const filterOptions = (options: Manager[]) =>
    options.filter((option) => !managers.find((item: Manager) => item.emailAddress === option.emailAddress))
  const clearDefaultManager = () => setDefManager('')

  const managerActions = (manager: { displayName: string; emailAddress: string }, isDefault: boolean): ManagerActionType[] => [
    {
      icon: ['fas', 'check-circle'],
      onClick: clearDefaultManager,
      label: 'Default',
      hint: 'Clear default',
      color: 'success',
      condition: isDefault
    },
    {
      icon: ['fas', 'check-circle'],
      onClick: () => setDefManager(manager.emailAddress),
      label: 'Default',
      hint: 'Set as default',
      color: 'secondary',
      condition: !isDefault
    },
    {
      icon: ['far', 'times'],
      onClick: () => removeManager(manager.emailAddress),
      label: 'Remove',
      hint: 'Remove manager',
      color: 'primary',
      condition: true
    }
  ]

  const items = managers?.map((manager) => {
    const isDefault = defManager === manager.emailAddress
    const actions = managerActions(manager, isDefault).filter((action) => action.condition)

    return {
      variant: 'expandable',
      name: manager.displayName,
      userKey: manager.emailAddress,
      byline: manager.emailAddress,
      className: classes.managerItem,
      icons: (
        <>
          <Wide>
            <Box width="100vw" display="flex" alignItems="center" justifyContent="end" gap={2}>
              {actions.map((action) => (
                <Button
                  key={action.label}
                  startIcon={<FontAwesomeIcon icon={action.icon} style={{ fontSize: 14 }} />}
                  disabled={isSaving}
                  onClick={action.onClick}
                  color={action.color}
                >
                  {action.label}
                </Button>
              ))}
            </Box>
          </Wide>
          <Narrow>
            {actions.map((action, i) => {
              const lastAction = i == actions.length - 1
              return (
                <IconButton
                  key={action.label}
                  hint={action.hint}
                  icon={action.icon}
                  disabled={isSaving}
                  onClick={action.onClick}
                  color={action.color}
                  size={lastAction ? 'small' : 'medium'}
                  disablePR={lastAction}
                />
              )
            })}
          </Narrow>
        </>
      )
    }
  })

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={loading} saveDisabled={loading}>
      <Heading title="Visibility settings" className={classes.subtitle} />
      <Controller
        name="enabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} classes={{ root: classes.root }} label="Turn on introductions" />
        )}
      />
      <Box ml={4} mb={2} display="flex" flexDirection="column">
        <Controller
          name="level"
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioGroup onChange={onChange} value={value}>
              <Radio value="AdminsAndNetworkTeam" disabled={isSaving || !enabled} label="For admins and network managers" />
              <Radio value="AllUsers" disabled={isSaving || !enabled} label="For all users" />
            </RadioGroup>
          )}
        />
      </Box>

      <Heading title="Network managers" className={classes.subtitle} />
      <Combobox<Manager, true, boolean>
        multiple
        autoFocus
        disableClearable={!managers}
        open={open}
        inputValue={inputValue}
        loading={optionsLoading}
        options={options}
        value={managers || []}
        filterOptions={filterOptions}
        placeholder="Add network manager"
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        onClose={handleClose}
        onOpen={handleOpen}
        onFocus={handleFocus}
        getOptionLabel={(option) => option.displayName}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, value: Manager) =>
          renderPersonOption(props, { email: value.emailAddress, name: value.displayName })
        }
      />
      <Box width="100%">
        <Repeater
          direction="vertical"
          variant="card"
          component={ProfileItem}
          skeleton={{ size: 2, loading }}
          items={items}
          empty={
            <Box mt={2}>
              <Empty subTitle="No managers" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} />
            </Box>
          }
        />
      </Box>
    </Settings>
  )
}

export default IntroductionsSettings
