import { makeStyles } from 'tss-react/mui'

import Avatar, { Size, AvatarProps } from '_shared/Avatar'
import { SkeletonProps } from '_shared/Skeleton'

import SidepanelLink from '_core/components/SidepanelLink'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    '& > *:not(:first-of-type)': {
      marginLeft: theme.spacing(1.5)
    }
  },
  avatar: {
    border: 0
  },
  alignRight: {
    justifyContent: 'flex-end'
  }
}))

type GroupProps = {
  max: number
  items: AvatarProps[]
  total?: number
  className?: string
  seeAllLink?: string
  skeleton: SkeletonProps
  sidepanel: SidepanelType
  hideName: boolean
  empty?: JSX.Element
  alignRight?: boolean
} & Size

const Group = (props: GroupProps) => {
  const { classes, cx } = useStyles()
  const { sidepanel, size, skeleton, seeAllLink, className, hideName, empty, total, alignRight } = props
  const max = props.max < 2 ? 2 : props.max
  const items = skeleton.loading ? new Array(skeleton.size).fill({}) : props.items
  const amount = total || items.length

  const extraAvatars = amount > max ? amount - max + 1 : 0
  const shape = extraAvatars > 99 ? 'ellipse' : 'circle'

  if (!skeleton.loading && props.items.length === 0 && empty) return <>{empty}</>

  return (
    <div className={cx(classes.root, { [classes.alignRight]: alignRight }, className)}>
      {items.slice(0, amount - extraAvatars).map(({ link, ...item }, i: number) => (
        <SidepanelLink key={i} linkProps={{ to: link }} sidepanel={sidepanel}>
          <Avatar name={item.name} logoUrl={item.logoUrl} userKey={item.userKey} hideName={hideName} size={size} />
        </SidepanelLink>
      ))}
      {!!extraAvatars && sidepanel && seeAllLink && (
        <SidepanelLink linkProps={{ to: seeAllLink }} sidepanel={sidepanel}>
          <Avatar name={`+${extraAvatars}`} hideName={hideName} size={size} shape={shape}>
            {`+${extraAvatars}`}
          </Avatar>
        </SidepanelLink>
      )}
      {!!extraAvatars && !sidepanel && (
        <Avatar name={`+${extraAvatars}`} hideName={hideName} size={size} shape={shape}>
          {`+${extraAvatars}`}
        </Avatar>
      )}
    </div>
  )
}

export default Group
