import React, { useEffect, ReactElement } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
      height: '100%',
      boxSizing: 'border-box',
      padding: `${theme.spacing(2)} 0`,
      borderRadius: '4px',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.light
    },
    placeholder: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      overflow: 'hidden'
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      padding: `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
      color: theme.palette.text.hint,
      fontSize: '18px',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(5)} ${theme.spacing(5)} 0 ${theme.spacing(5)}`
      }
    },
    title: {
      color: theme.palette.text.hint,
      textAlign: 'center',
      '&, & > *': {
        fontSize: '14px',
        verticalAlign: 'unset',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px !important'
        }
      }
    },
    subTitle: {
      color: theme.palette.text.hint,
      fontWeight: 100,
      textAlign: 'center',
      wordBreak: 'break-word',
      '&, & *': {
        fontSize: '14px',
        verticalAlign: 'unset',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px !important'
        }
      }
    },
    imageStub: {
      height: '91px',
      transform: 'scale(0.7, 0.7)',
      [theme.breakpoints.up('md')]: {
        transform: 'none'
      }
    }
  }
})

const Empty = (props: {
  title?: string | ReactElement
  subTitle?: string | ReactElement
  close?: boolean
  icon?: ReactElement
  action?: ReactElement
  className?: string
}) => {
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (props.close) window.parent.postMessage(`da_close`, '*')
  }, [props.close])

  return (
    <Box className={cx(classes.root, props.className)}>
      <Box className={classes.placeholder}>
        {props.icon || <img src="/image/data-source/data-source.png" className={classes.imageStub} alt="empty" />}
      </Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title} semiBold variant="h4">
          {props.title}
        </Typography>
        <Typography className={classes.subTitle} variant="h4">
          {props.subTitle}
        </Typography>
        {props.action}
      </Box>
    </Box>
  )
}

export default Empty
