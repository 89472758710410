import { ActivityItem } from '_core/hooks/useActivitiesSlots'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'

import Paths from 'Paths'

export type LocalActivityItem = {
  from: { id: string; name: string; email?: string; companyMd5?: string }[] // make sure email and companyMd5 are required
  to: { id: string; name: string; email?: string; companyMd5?: string }[] // make sure email and companyMd5 are required
  action?: 'inbound' | 'outbound' | 'meeting'
  companies: (string | undefined)[]
  detailsLink: string | null
}
const useActivitiesGroups = () => {
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy } = queryParams
  const getActionData = (obj: ActivityItem, time: string) => {
    const { LastInboundMsg, LastOutboundMsg, LastMeeting, NextFutureMeeting, LastCalGlobalKey, NextCalGlobalKey } = obj
    const map: { [key: string]: { action: 'inbound' | 'outbound' | 'meeting'; calGlobalKey?: string } } = {
      [LastInboundMsg]: { action: 'inbound' },
      [LastOutboundMsg]: { action: 'outbound' },
      [LastMeeting]: { action: 'meeting', calGlobalKey: LastCalGlobalKey },
      [NextFutureMeeting]: { action: 'meeting', calGlobalKey: NextCalGlobalKey }
    }

    return map[time]
  }

  const groupLists = (timeItems: ActivityItem[], time: string, MyUserKeyMd5: string) => {
    const merged = []
    const visited = new Set<string>()

    const addCurrent = (item: string, obj: ActivityItem, current: LocalActivityItem) => {
      const { UserKeyMd5, Contact, User, LastInboundMsg } = obj
      const { PersonMd5, UserKeyMd5: CUserKeyMd5 = '' } = Contact
      if (!visited.has(item)) {
        visited.add(item)

        const payload = {
          [CUserKeyMd5]: {
            fillMaps: () => {
              const { PersonNameText: name, BestEmailAddrText: email, BestJobCompanyMd5: companyMd5 } = Contact

              const companyDomain = email?.split('@')[1]

              ;(LastInboundMsg === time ? current.from : current.to).push({ id: item, name, email, companyMd5 })

              return companyDomain
            }
          },
          [PersonMd5]: {
            fillMaps: () => {
              const { PersonNameText: name, BestEmailAddrText: email, BestJobCompanyMd5: companyMd5 } = Contact
              const companyDomain = email?.split('@')[1]

              ;(LastInboundMsg === time ? current.from : current.to).push({ id: item, name, email, companyMd5 })

              return companyDomain
            }
          },
          [UserKeyMd5]: {
            fillMaps: () => {
              const { UserFullName: name, UserEmail: email, BestJobCompanyMd5: companyMd5 } = User

              const companyDomain = email?.split('@')[1]

              ;(LastInboundMsg === time ? current.to : current.from).push({ id: item, name, email, companyMd5 })

              return companyDomain
            }
          }
        }

        const { fillMaps } = payload[item]

        const domain = fillMaps()

        if (!current.companies.includes(domain)) {
          current.companies.push(domain)
        }

        const matches = timeItems.filter(
          ({ Contact: { PersonMd5, UserKeyMd5: CUserKeyMd5 }, UserKeyMd5 }) => PersonMd5 === item || CUserKeyMd5 === item || UserKeyMd5 === item
        )
        matches.forEach((match) => {
          const {
            Contact: { PersonMd5, UserKeyMd5: CUserKeyMd5 },
            UserKeyMd5
          } = match
          if (UserKeyMd5 !== item) addCurrent(UserKeyMd5, match, current)

          if (groupBy === 'Contributor' && CUserKeyMd5) {
            if (CUserKeyMd5 !== item) {
              addCurrent(CUserKeyMd5, match, current)
            }
          } else {
            if (PersonMd5 !== item) {
              addCurrent(PersonMd5, match, current)
            }
          }
        })
      }
    }

    for (const obj of timeItems) {
      const current: LocalActivityItem = { from: [], to: [], companies: [], detailsLink: null }

      const {
        UserKeyMd5,
        Contact: { PersonMd5, UserKeyMd5: CUserKeyMd5 }
      } = obj

      const { action, calGlobalKey } = getActionData(obj, time)

      addCurrent(UserKeyMd5, obj, current)
      if (groupBy === 'Contributor' && CUserKeyMd5) {
        addCurrent(CUserKeyMd5, obj, current)
      } else {
        addCurrent(PersonMd5, obj, current)
      }
      if (current.from && current.from.length > 0) {
        current.action = action
        const participants = [...current.from, ...current.to]
        const isParticipant = participants.findIndex(({ id }) => id === MyUserKeyMd5) > -1

        if (calGlobalKey) {
          const {
            pathname,
            params
          }: { pathname: string; params: Modify<ActivityEventPageParams, { participants?: string[]; companies?: string[] }> } = isParticipant
            ? { pathname: `${Paths._events}/${calGlobalKey}`, params: { idType: 'CalGlobalKey' } }
            : {
                pathname: `${Paths._activities}/event`,
                params: {
                  participants: participants.map((p) => p.email || p.id),
                  companies: current.companies.filter((c) => c) as string[],
                  startTime: time
                }
              }
          current.detailsLink = stringifyUrl(pathname, params)
        } else {
          const {
            pathname,
            params
          }: { pathname: string; params?: Modify<ActivityMessagePageParams, { participants?: string[]; companies?: string[] }> } = {
            pathname: `${Paths._activities}/message`,
            params: {
              participants: participants.map((p) => p.email || p.id),
              companies: current.companies.filter((c) => c) as string[],
              startTime: time
            }
          }
          current.detailsLink = stringifyUrl(pathname, params)
        }

        merged.push(current)
      }
    }

    return merged
  }

  return groupLists
}

export default useActivitiesGroups
