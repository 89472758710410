import { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { Switch } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { makeStyles } from 'tss-react/mui'

import ActivitiesEventPage from '_pages/activities/event'
import ActivitiesMessagePage from '_pages/activities/message'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import ActivitiesByCompany from '_core/components/ActivitiesByCompany'
import ActivitiesByPerson from '_core/components/ActivitiesByPerson'
import { GroupControllers, Heading } from '_core/components/ActivitiesList'
import ActivitiesUngrouped from '_core/components/ActivitiesUngrouped'
import Today, { PrevDateSwitch, NextDateSwitch, WeekDateTitle } from '_core/components/DateSwitcher'
import Filters from '_core/components/filters/Activities'
import Grid from '_core/components/grid'
import { Narrow, Wide, useWide } from '_core/components/layout'
import PrivateRoute from '_core/components/PrivateRoute'
import Widget from '_core/components/Widget'

import useActivitiesUserSettings from '_core/hooks/useActivitiesUserSettings'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { withErrors } from '_core/ErrorBoundary'
import UserSettings from '_core/UserSettings'

import { formatDate, getLocal, dateFormatURLQuery } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles<{ height?: number | string }>()((theme, { height }) => ({
  widget: {
    display: 'flex',
    position: 'relative',
    borderRadius: '4px',
    minHeight: '100%',
    height: 'calc(100% + 30px)',
    marginBottom: -30
  },
  filter: {
    padding: 0,
    margin: 0,
    flexGrow: 0,
    height: `calc(100vh - 89px)`,
    minHeight: height,
    borderRadius: '4px 0 0 4px',
    borderRight: `1px solid #f2f2f2`
  },
  content: {
    width: '100%',
    minHeight: '100%'
  },
  header: {
    borderRadius: '0 4px 0 0',
    width: '100%',
    marginBottom: 0,
    zIndex: 101
  },
  date: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: `0px ${theme.spacing(2)}`
    }
  },
  dateWrapper: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: 230
    }
  }
}))

const ActivitiesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const wide = useWide()

  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy = 'None', date } = queryParams

  const [contentLoading, setContentLoading] = useState(false)

  const { setInitial, reset, handleChange, loading, toggleOpen, opened } = useActivitiesUserSettings()

  const { classes } = useStyles({ height: '100%' })

  const formattedDate = date ? getLocal(date, dateFormatURLQuery) : getLocal()

  useEffect(() => {
    setMobileHeader('Activities')
  }, [setMobileHeader])

  const groupByParamsMap = {
    contributor: ActivitiesByPerson,
    contact: ActivitiesByPerson,
    company: ActivitiesByCompany,
    none: ActivitiesUngrouped
  }

  const Component = groupByParamsMap[groupBy.toLowerCase() as keyof typeof groupByParamsMap]

  const filtersProps = {
    disabled: loading,
    opened,
    contentLoading,
    queryData: queryParams,
    handleChange,
    toggleOpen,
    reset
  }

  const handleCustomItemSelect = (value: string[]) => {
    handleChange({ customList: value })
  }

  const setDay = (day: MomentType) => {
    handleChange({ date: formatDate(day) })
  }

  const onLoading = (loading: boolean) => {
    setContentLoading(loading)
  }

  const selectDateEl = (
    <Box className={classes.date}>
      <div>
        <PrevDateSwitch day={formattedDate} setDay={setDay} disablePR />
      </div>
      <div className={classes.dateWrapper}>
        <Today day={formattedDate} setDay={setDay} disablePL={wide} />
        <Wide>
          <div style={{ paddingRight: 16 }}>
            <WeekDateTitle day={formattedDate} />
          </div>
        </Wide>
        <Typography variant="h4" semiBold>
          {formatDate(formattedDate, 'ddd D, MMM')}
        </Typography>
      </div>
      <div>
        <NextDateSwitch day={formattedDate} setDay={setDay} disablePL={wide} />
      </div>
    </Box>
  )

  return (
    <Page>
      <UserSettings endpoint="/usersettings/activitiesfilter" setInitial={setInitial}>
        <Wide>
          <Widget scope="list" className={classes.widget}>
            <Widget sticky={58} scope="none" className={classes.filter}>
              <Filters {...filtersProps} />
            </Widget>
            <Box className={classes.content}>
              <Widget sticky={58} scope="none" className={classes.header}>
                <Grid.Heading title="Activities">
                  <Box display="flex" flex={1} alignItems="center">
                    {selectDateEl}
                    <GroupControllers handleChange={handleChange} disabled={loading} />
                  </Box>
                </Grid.Heading>
              </Widget>
              <Box pr={1}>
                <AutoSizer disableHeight>
                  {({ width }: { width: number }) => (
                    <Component onLoading={onLoading} width={width} onCustomSelect={handleCustomItemSelect} reset={reset} />
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </Widget>
        </Wide>
        <Narrow>
          <Heading
            handleChange={handleChange}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            filters={<Filters {...filtersProps} />}
          >
            {selectDateEl}
          </Heading>
          <AutoSizer disableHeight>
            {({ width }: { width: number }) => (
              <Component onLoading={onLoading} width={width} onCustomSelect={handleCustomItemSelect} reset={reset} />
            )}
          </AutoSizer>
        </Narrow>
      </UserSettings>
    </Page>
  )
}

const ActivitiesPages = () => {
  const { activitiesStreamEnabled, userActivitiesAccess } = usePrivateFeatures()
  const { isActivityTabVisible } = userActivitiesAccess || {}

  const hasAccess = activitiesStreamEnabled && isActivityTabVisible

  return (
    <Switch>
      <PrivateRoute path={`${Paths._activities}/message`} hasAccess={hasAccess} render={withErrors(ActivitiesMessagePage)} />
      <PrivateRoute path={`${Paths._activities}/event`} hasAccess={hasAccess} render={withErrors(ActivitiesEventPage)} />
      <PrivateRoute path={Paths._activities} hasAccess={hasAccess} render={withErrors(ActivitiesPage)} />
    </Switch>
  )
}

export default ActivitiesPages
