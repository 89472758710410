import React from 'react'

import { useParams } from 'react-router'

import { TeamContext } from '_core/context/TeamContext'

import EditPerson from '_core/components/EditPerson'

import DynamicEntity from '_core/DynamicEntity'

const EditPersonPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = React.useContext(TeamContext)

  if (!id) return <EditPerson newForm={true} />

  return (
    <>
      <DynamicEntity
        urls={[
          {
            key: 'person',
            url: `/people/${id}?teamNumber=${teamContextValue.teamNumber}&NumAliases=100&NumEmails=100&NumPhones=100&NumJobs=100&NumCompanies=100`
          },
          {
            key: 'jobs',
            url: `/personjobs/${id}?teamNumber=${teamContextValue.teamNumber}`
          }
        ]}
        id="person_edit"
        component={EditPerson}
      />
    </>
  )
}

export default EditPersonPage
