import React from 'react'

import { useParams } from 'react-router'

import { TeamContext } from '_core/context/TeamContext'

import EditCompany from '_core/components/EditCompany'

import DynamicEntity from '_core/DynamicEntity'

const EditCompanyPage = () => {
  const { id } = useParams<any>()
  const { teamContextValue } = React.useContext(TeamContext)

  if (!id) return <EditCompany newForm={true} />

  return (
    <>
      <DynamicEntity
        url={`/companies/${id}?teamNumber=${teamContextValue.teamNumber}&NumAliases=100&NumUrls=100`}
        id="company_edit"
        component={EditCompany}
      />
    </>
  )
}

export default EditCompanyPage
