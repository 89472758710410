import { Dispatch, SetStateAction } from 'react'

import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { formatDate, getLocal } from 'utils/Utils'

const today = getLocal()

type InitialParams = Modify<EventsPageParams, { checked?: IncludeEvents[] }>

const eventsSaveData = {
  endpoint: '/usersettings/homePage',
  getData: ({ checked = [], showPast, includeTags, excludeTags }: InitialParams): IEventsInit => ({
    appointments: checked.includes('appointments'),
    meetings: checked.includes('meetings'),
    recurringMeetings: checked.includes('recurringMeetings'),
    canceledMeetings: checked.includes('canceledMeetings'),
    accepted: checked.includes('accepted'),
    notResponded: checked.includes('notResponded'),
    tentativelyAccepted: checked.includes('tentativelyAccepted'),
    showPast: showPast ? JSON.parse(showPast) : showPast,
    includeTags: includeTags ? JSON.parse(includeTags) : includeTags,
    excludeTags: excludeTags ? JSON.parse(excludeTags) : excludeTags
  })
}

const useEventsUserSettings = ({ setLoading }: { setLoading: Dispatch<SetStateAction<boolean>> }) => {
  const { save } = useFilter()
  const { queryParams, updateQuery } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked: IncludeEvents[] }] }>(['checked'])

  const setInitial = ({ showPast, includeTags, excludeTags, ...data }: IEventsInit) => {
    const checked = (Object.keys(data) as IncludeEvents[]).filter((key) => data[key])
    updateQuery({
      date: formatDate(today),
      showPast: `${showPast}`,
      checked,
      includeTags: includeTags?.length ? JSON.stringify(includeTags) : '',
      excludeTags: excludeTags?.length ? JSON.stringify(excludeTags) : ''
    })
  }

  const handleChange = async (updates: typeof queryParams) => {
    setLoading(true)
    await save(eventsSaveData, { ...queryParams, ...updates })
    setLoading(false)
  }

  const reset = async () => {
    await handleChange({
      checked: [],
      showPast: `${true}`,
      includeTags: '',
      excludeTags: ''
    })
  }

  return { setInitial, handleChange, reset }
}

export default useEventsUserSettings
