import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { triggerDownloadXl, triggerDownload, convertToCsv } from 'utils/Utils'

export type DownloadBulkParams = {
  skip: string
  take: string
  searchTerm?: string
}

export type DownloadBulkType = {
  fileName: string
  requestBinary: (data: DownloadBulkParams) => Promise<Blob | undefined>
  shouldUnregister?: boolean
  defaultValues?: { count?: number; rows?: number }
}

const useDownloadBulk = ({ requestBinary, fileName, shouldUnregister = true, defaultValues }: DownloadBulkType) => {
  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit: handleFormSubmit,
    formState: { errors }
  } = useForm<{
    count: number
    rows: number
  }>({ mode: 'onChange', shouldUnregister, defaultValues: defaultValues || { count: 500, rows: 1 } })
  const { enqueueSnackbar } = useSnackbar()
  const { queryParams } = useSearchQuery<SearchParams>()

  const { keyword } = queryParams

  const handleSubmit = async () => {
    const { count, rows } = getValues()
    try {
      enqueueSnackbar('Your export is in progress. It may take a few moments to process.', {
        variant: 'default'
      })
      const skip = (rows - 1).toString()
      const take = count.toString()

      const fetchedData = await requestBinary({ skip, take, searchTerm: keyword })

      if (fetchedData) {
        if (fetchedData instanceof Blob) {
          triggerDownloadXl(fetchedData, fileName)
        } else {
          const csvData = convertToCsv(fetchedData)
          triggerDownload(csvData, fileName)
        }
      }
    } catch (error) {
      enqueueSnackbar('Encountered error while processing request', {
        variant: 'error'
      })
      console.log('ERROR DURING DATA FETCH', error)
    }
  }

  return {
    watch,
    setValue,
    register,
    getValues,
    handleSubmit: handleFormSubmit(handleSubmit),
    errors
  }
}

export default useDownloadBulk
