import { useCallback, useState } from 'react'

import useFilter from '_core/hooks/useFilter'

export const withinOptions = [
  { value: 'this_month', label: 'This month' },
  { value: 'this_quarter', label: 'This quarter' },
  { value: 'this_year', label: 'This year' }
] as const

type LocalActivityStatsPeriodType = (typeof withinOptions)[number]['value']
type RemoteActivityStatsPeriodType = 'Month' | 'Quarter' | 'Year'

const activitiesSaveData = {
  endpoint: '/usersettings/activitiesstatsperiod',
  getData: (updatedPeriod: LocalActivityStatsPeriodType) => {
    const periodMap: { [key in LocalActivityStatsPeriodType]: RemoteActivityStatsPeriodType } = {
      this_month: 'Month',
      this_quarter: 'Quarter',
      this_year: 'Year'
    }
    return { period: periodMap[updatedPeriod] }
  }
}

const useActivitiesStatsUserSettings = () => {
  const [period, setPeriod] = useState<LocalActivityStatsPeriodType>()

  const { save } = useFilter()

  const setInitial = useCallback((data: { period: RemoteActivityStatsPeriodType }) => {
    const periodMap: { [key in RemoteActivityStatsPeriodType]: LocalActivityStatsPeriodType } = {
      Month: 'this_month',
      Quarter: 'this_quarter',
      Year: 'this_year'
    }
    setPeriod(periodMap[data.period])
  }, [])

  const handleChange = (updatedPeriod: LocalActivityStatsPeriodType) => {
    save({ ...activitiesSaveData, getData: () => activitiesSaveData.getData(updatedPeriod) }, {})
    setPeriod(updatedPeriod)
  }

  return {
    setInitial,
    period,
    handleChange
  }
}

export default useActivitiesStatsUserSettings
