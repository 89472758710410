import { useState, useCallback, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

type InitialParams = Modify<EntityActivitiesPageParams, { isOpened: boolean }>

const activitiesSaveData = {
  endpoint: '/usersettings/useractivitiesfilter',
  getData: (queryParams: InitialParams): EntityActivitiesInit => {
    const { interaction: touchpointType = 'Any', sort = 'NewestToOldest', isOpened } = queryParams

    return {
      touchpointType,
      sort,
      isOpened
    }
  }
}

const useUserActivitiesUserSettings = () => {
  const wide = useWide()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<EntityActivitiesPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams] = useState<EntityActivitiesInit>()
  const [isOpened, setOpened] = useState<boolean>(false)

  const isReady = useRef<boolean>(false)

  const { name, from, to } = queryParams

  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<EntityActivitiesInit>()

  const getInitialParams = useCallback(
    (data: EntityActivitiesInit): InitialParams => {
      const { touchpointType, isOpened, sort = 'NewestToOldest' } = data

      return {
        name,
        from,
        to,
        interaction: touchpointType === 'Any' ? undefined : touchpointType,
        sort,
        isOpened
      }
    },
    [name, from, to]
  )

  const handleChange = (updates: Partial<typeof queryParams>, additionalParams?: { [key in 'isOpened']?: boolean } | undefined) => {
    if (params) {
      const { isOpened = params?.isOpened } = additionalParams || {}
      const newParams = activitiesSaveData.getData({ ...queryParams, ...updates, isOpened })
      save({ ...activitiesSaveData, getData: () => newParams }, { ...queryParams, ...updates })
      setParams(newParams)
    }
  }

  useEffect(() => {
    if (isReady.current && params) {
      const { isOpened, ...initialParams } = getInitialParams(params)
      if (wide) {
        setOpened(isOpened)
      }
      updateQuery(initialParams)
    }
  }, [search, updateQuery, params, getInitialParams, wide])

  const setInitial = (data: EntityActivitiesInit) => {
    if (wide) {
      setOpened(data.isOpened)
    }

    setParams(data)

    isReady.current = true
    setLoading(false)
  }

  const toggleOpen = () => {
    const newState = !isOpened
    setOpened(newState)
    if (wide) {
      setParams((prevState) => (prevState ? { ...prevState, isOpened: newState } : prevState))
      handleChange({}, { isOpened: newState })
    }
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/useractivitiesfilter' })

    if (defaultData) {
      if (wide) {
        setOpened(defaultData.isOpened)
      }
      setParams(defaultData)
      const { isOpened, ...defaultParams } = getInitialParams(defaultData)
      const newParams = activitiesSaveData.getData(getInitialParams(defaultData))
      await save({ ...activitiesSaveData, getData: () => newParams }, defaultParams)
      setLoading(false)
    }
  }

  return {
    setInitial,
    reset,
    handleChange,
    toggleOpen,
    loading,
    opened: isOpened,
    isReady: isReady.current
  }
}

export default useUserActivitiesUserSettings
