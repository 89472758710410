import React, { ComponentProps, ReactElement, useContext, useEffect, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, SelectChangeEvent } from '@mui/material'
import moment from 'moment'
import { Link, Route, Switch, useParams } from 'react-router-dom'

import RelationshipActivitiesPage from '_pages/relationships/[userId]/companies/[companyId]/activities'
import RelationshipContactsPage from '_pages/relationships/[userId]/companies/[companyId]/contacts'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Select from '_shared/forms/Select'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import ActivitiesStatsCard from '_core/components/ActivitiesStatsCard'
import Dashboard from '_core/components/Dashboard'
import { PickAColleagueRelationshipDialog, PickACompanyRelationshipDialog } from '_core/components/dialogs/RelationshipPicker'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { MDByLine, MDCompanyByLine2, MDPersonByLine2 } from '_core/components/MarketDataSummary'
import PrivateRoute from '_core/components/PrivateRoute'
import ProfileSummary from '_core/components/ProfileSummary'
import RelationshipScore from '_core/components/RelationshipScore'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelations from '_core/components/TouchpointRelations'
import Widget from '_core/components/Widget'

import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings, { withinOptions } from '_core/hooks/useActivitiesStatsUserSettings'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import useDialog from '_core/hooks/useDialog'
import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import UserSettings from '_core/UserSettings'

import { getLocal, dateFormatURLQuery } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const url = Paths._relationships

const dashboardLinks = (props: any) => [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: `${url}/${props.frm}/companies/${props.to}`,
    condition: true,
    icon: ['far', 'th']
  },
  {
    id: 'contacts',
    name: 'Contacts',
    link: `${url}/${props.frm}/companies/${props.to}/contacts`,
    condition: true,
    icon: ['far', 'user']
  }
]

const RelationHeader = (props: {
  main: { data: UserCompanyType[] } | undefined
  loading: boolean
  marketDataIntegration?: { enabled: boolean; showSimilarPeople: boolean }
}) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const wide = useWide()
  const { dialogContentProps, openDialog, closeDialog } = useDialog<{ type: 'user' | 'company' }>()
  const { type } = dialogContentProps || {}

  const { enabled: enabledMarketData } = props.marketDataIntegration || {}

  const mainData = props.main?.data?.[0]
  const {
    UserBestEmailAddress = '',
    UserFullName = '',
    UserKeyMd5,
    Score,
    CompanyNameText = '',
    CompanyMd5,
    BestUrlText,
    Introducers
  } = mainData || {}

  const {
    IntroducerBestJobTitleText,
    IntroducerBestJobMatchedUrlText,
    IntroducerBestJobMatchedCompanyName,
    IntroducerBestJobCorpLevelCompanyName,
    IntroducerBestJobCompanyMd5,
    IntroducerBestJobCurrentAsOf,
    IntroducerBestJobIsFormer,
    IntroducerBestPhoneText
  } = Introducers?.data[0] || {}

  const { marketData: userMarketData, getMarketData: getUserMarketData } = usePersonMarketData(UserFullName, UserBestEmailAddress, undefined, true)

  const { marketData: companyMarketData, getMarketData: getCompanyMarketData } = useCompanyMarketData(CompanyNameText, BestUrlText, true)

  useEffect(() => {
    if (!props.loading && enabledMarketData && !userMarketData) {
      getUserMarketData(teamNumber)
    }
  }, [enabledMarketData, userMarketData, props.loading, teamNumber])

  useEffect(() => {
    if (!props.loading && enabledMarketData && !companyMarketData) {
      getCompanyMarketData(teamNumber)
    }
  }, [enabledMarketData, companyMarketData, props.loading, teamNumber])

  const pickAColleageOpen = () => {
    openDialog({ type: 'user' })
  }

  const pickACompanyOpen = () => {
    openDialog({ type: 'company' })
  }

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'}>
        <Box display="flex" justifyContent="space-between">
          <Wide>
            <ProfileSummary
              userKey={UserBestEmailAddress}
              title={UserFullName || ''}
              loading={props.loading}
              introducers={null}
              profileLink={`${Paths._people}/${UserKeyMd5}`}
              actions={[
                (props.loading || (!props.loading && IntroducerBestPhoneText)) && (
                  <IconButton
                    icon={['far', 'phone']}
                    color="primary"
                    hint={`Make a call to ${IntroducerBestPhoneText}`}
                    disablePadding
                    component="a"
                    href={`tel:${IntroducerBestPhoneText?.substring(2)}`}
                    loading={!IntroducerBestPhoneText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && UserBestEmailAddress)) && (
                  <IconButton
                    icon={['far', 'envelope']}
                    color="primary"
                    hint={`Send an email to ${UserBestEmailAddress}`}
                    disablePadding
                    component="a"
                    href={`mailto:${UserBestEmailAddress}`}
                    loading={!UserBestEmailAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && UserFullName)) && (
                  <IconButton
                    icon={['fab', 'linkedin']}
                    color="primary"
                    hint="Show Linkedin profile"
                    component="a"
                    disablePadding
                    href={
                      userMarketData?.linkedin_url
                        ? `https://${userMarketData?.linkedin_url}`
                        : `https://www.linkedin.com/search/results/people/?keywords=${UserFullName}`
                    }
                    loading={!UserFullName}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((enabledMarketData && !userMarketData) || (!props.loading && userMarketData?.github_url)) && (
                  <IconButton
                    icon={['fab', 'github']}
                    color="primary"
                    hint="Show Github profile"
                    component="a"
                    disablePadding
                    href={`https://${userMarketData?.github_url}`}
                    loading={!userMarketData?.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!userMarketData} locationName={userMarketData?.location_name} />,
                    byline2: (
                      <MDPersonByLine2
                        marketData={userMarketData}
                        bestJobMatchedUrlText={IntroducerBestJobMatchedUrlText}
                        internalCompanyName={IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        bestJobTitleText={IntroducerBestJobTitleText}
                        bestJobCompanyMd5={IntroducerBestJobCompanyMd5}
                        bestJobCurrentAsOf={IntroducerBestJobCurrentAsOf}
                        bestJobIsFormer={IntroducerBestJobIsFormer}
                      />
                    )
                  }
                : {
                    enabledMarketData: false,
                    byline: IntroducerBestJobTitleText,
                    byline2: (
                      <Typography variant="body1" ellipsis color="primary.main">
                        <Link to={`${Paths._companies}/${IntroducerBestJobCompanyMd5}`}>
                          {IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        </Link>
                      </Typography>
                    )
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar name={UserFullName || ''} size="md" link={`${Paths._people}/${UserKeyMd5}`} userKey={UserBestEmailAddress} />
          </Narrow>
          <Box display="flex" alignItems="center" p={2}>
            <PickAColleagueRelationshipDialog.TriggerEl disabled={props.loading} open={pickAColleageOpen} />
          </Box>
          <RelationshipScore score={Score} />
          <Box display="flex" alignItems="center" p={2}>
            <PickACompanyRelationshipDialog.TriggerEl disabled={props.loading} open={pickACompanyOpen} />
          </Box>
          <Wide>
            <ProfileSummary
              flip
              url={BestUrlText}
              title={CompanyNameText}
              loading={props.loading}
              introducers={null}
              profileLink={`${Paths._companies}/${CompanyMd5}`}
              actions={[
                (props.loading || (!props.loading && BestUrlText && !enabledMarketData)) && (
                  <Skeleton condition={!BestUrlText} width="100px">
                    <Tooltip title={`Send an email to ${BestUrlText}`}>
                      <a href={`//${BestUrlText}`} target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(27, 149, 187, 0.87)' }}>
                        <FontAwesomeIcon icon={['far', 'external-link']} style={{ fontSize: 15, paddingRight: 4 }} color="rgba(27, 149, 187, 0.87)" />
                        {BestUrlText}
                      </a>
                    </Tooltip>
                  </Skeleton>
                ),
                !props.loading && BestUrlText && enabledMarketData && (
                  <IconButton
                    icon={['far', 'globe']}
                    color="primary"
                    hint="Show website"
                    disablePadding
                    component="a"
                    href={`//${BestUrlText}`}
                    loading={!BestUrlText}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                <IconButton
                  key="linkedin"
                  icon={['fab', 'linkedin']}
                  color="primary"
                  hint="Show Linkedin profile"
                  disablePadding
                  component="a"
                  href={
                    companyMarketData?.linkedin_url
                      ? `https://${companyMarketData.linkedin_url}`
                      : `https://www.linkedin.com/search/results/people/?keywords=${CompanyNameText}`
                  }
                  loading={!CompanyNameText}
                  rel="noopener noreferrer"
                  target="_blank"
                  size="small"
                />,
                ((enabledMarketData && !companyMarketData) || (!props.loading && companyMarketData?.twitter_url)) && (
                  <IconButton
                    icon={['fab', 'twitter']}
                    color="primary"
                    hint="Show Twitter profile"
                    component="a"
                    disablePadding
                    href={`https://${companyMarketData?.twitter_url}`}
                    loading={!companyMarketData?.twitter_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((enabledMarketData && !companyMarketData) || (!props.loading && companyMarketData?.facebook_url)) && (
                  <IconButton
                    icon={['fab', 'facebook']}
                    color="primary"
                    hint="Show Facebook profile"
                    component="a"
                    disablePadding
                    href={`https://${companyMarketData?.facebook_url}`}
                    loading={!companyMarketData?.facebook_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!companyMarketData} locationName={companyMarketData?.location?.name} />,
                    byline2: (
                      <MDCompanyByLine2
                        marketData={!!companyMarketData}
                        industry={companyMarketData?.industry}
                        employee_count={companyMarketData?.employee_count}
                      />
                    )
                  }
                : {
                    enabledMarketData: false
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar name={CompanyNameText || ''} size="md" link={`${Paths._companies}/${CompanyMd5}`} logoUrl={BestUrlText} />
          </Narrow>
        </Box>
      </Widget>
      <PickAColleagueRelationshipDialog isOpened={type === 'user'} close={closeDialog} />
      <PickACompanyRelationshipDialog isOpened={type === 'company'} close={closeDialog} />
    </>
  )
}

const Connected = ({ loading, stats }: { loading: boolean; stats: Partial<StatsType<{ CompanyMd5: string; UserKeyMd5: string }>> }) => (
  <TitleDescription
    title="Connected"
    description={
      loading || (!loading && stats?.FirstInboundMsgContactName) ? (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={['far', 'user']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
          <Skeleton condition={loading} width={120}>
            {!loading && stats?.FirstInboundMsgContactName ? stats?.FirstInboundMsgContactName : <></>}
          </Skeleton>
        </span>
      ) : (
        <span>No connection found</span>
      )
    }
    blurb={
      <Skeleton condition={loading} width={140}>
        {!loading && stats?.FirstInboundMsg ? moment(stats.FirstInboundMsg).format('DD MMMM YYYY') : <></>}
      </Skeleton>
    }
  />
)

const Info = (props: { totalRelationships?: number; total?: number; loading: boolean }) => (
  <TitleDescription
    title="Relationships"
    description={
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={['fas', 'chart-network']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
        <Skeleton condition={!props.totalRelationships} width={160}>
          {props.totalRelationships ? <>{props.totalRelationships} relationships</> : <></>}
        </Skeleton>
      </span>
    }
    blurb={
      <Skeleton condition={props.loading} width={140}>
        {!props.loading ? `Including ${props.total} former affiliation${props.total !== 1 ? 's' : ''}` : ``}
      </Skeleton>
    }
  />
)

const TopContacts = ({ total, loading, items }: { total?: number; loading: boolean; items?: UserCompanyContactListItem[] }) => {
  const { to, from } = useParams<{ to: string; from: string }>()
  const wide = useWide()

  return (
    <Widget>
      <Heading title="Contacts" count={total} icon={['far', 'user']} link={`${Paths._relationships}/${from}/companies/${to}/contacts`} />
      <Repeater
        direction="horizontal"
        component={Avatar}
        skeleton={{ size: wide ? 5 : 4, loading }}
        items={
          items?.slice(0, wide ? 5 : 4).map((contact: UserCompanyContactListItem) => ({
            name: contact.PersonNameText,
            score: contact.UserKnowsPersonScore,
            link: `${Paths._people}/${contact.PersonMd5}`,
            sidepanel: true
          })) || []
        }
      />
    </Widget>
  )
}

const LatestTouchpoints = ({ loading, stats }: { loading: boolean; stats: Partial<StatsType<{ CompanyMd5: string; UserKeyMd5: string }>> }) => (
  <Widget scope="stack">
    <Heading title="Latest interactions" icon={['far', 'handshake']} />
    <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={!loading ? TouchpointRelations(stats, 'Last') : []} />
  </Widget>
)

const ActivityStats = ({
  loading,
  isDetailedActivityFromStatsWidgetVisible,
  companyMd5,
  introducerMd5,
  userName,
  companyName
}: {
  loading: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  introducerMd5: string
  companyMd5: string
  userName: string
  companyName: string
}) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { period: withinValue, setInitial, handleChange } = useActivitiesStatsUserSettings()

  const rangeMap: { [key in (typeof withinOptions)[number]['value']]: string } = {
    this_month: getLocal().startOf('month').format(dateFormatURLQuery),
    this_quarter: getLocal().startOf('quarter').format(dateFormatURLQuery),
    this_year: getLocal().startOf('year').format(dateFormatURLQuery)
  }

  const fromDate = withinValue ? rangeMap[withinValue] : withinValue
  const toDate = getLocal().format(dateFormatURLQuery)

  const statsPayloads = useCompanyActivitiesPayloads(companyMd5, introducerMd5)

  const { stats, clearStats } = useActivitiesStats(
    introducerMd5 ? statsPayloads : null,
    useMemo(() => [companyMd5], [companyMd5]),
    fromDate,
    toDate
  )

  useEffect(() => {
    if (!introducerMd5 || !companyMd5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introducerMd5, companyMd5])

  const { meetings, inbound, outbound } = stats?.[0] || {}
  const count = (meetings?.count || 0) + (inbound?.count || 0) + (outbound?.count || 0)

  const handleRangeChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as { value: Exclude<typeof withinValue, undefined> }
    handleChange(value)
  }

  return (
    <UserSettings endpoint="/usersettings/activitiesstatsperiod" setInitial={setInitial}>
      <Widget scope={isDetailedActivityFromStatsWidgetVisible ? 'default' : 'stack'}>
        <Heading
          title="Activity stats"
          icon={['far', 'wave-pulse']}
          count={count}
          sidepanel
          link={
            isDetailedActivityFromStatsWidgetVisible && count
              ? stringifyUrl(`${Paths._relationships}/${from}/companies/${to}/activities`, {
                  from: fromDate,
                  to: toDate,
                  name: `${userName} and ${companyName}`
                })
              : ''
          }
        />
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Select
            size="small"
            variant="outlined"
            value={withinValue}
            options={[...withinOptions]}
            onChange={handleRangeChange}
            disabled={loading || !stats}
          />
        </Box>
        <Columns spacing={1}>
          {[
            { header: 'Inbound', amount: inbound?.count || 0 },
            { header: 'Outbound', amount: outbound?.count || 0 },
            { header: 'Meetings', amount: meetings?.count || 0 }
          ].map(({ header, amount }) => (
            <Column key={header} xs={4}>
              <ActivitiesStatsCard header={header} amount={amount} loading={loading || !stats} />
            </Column>
          ))}
        </Columns>
      </Widget>
    </UserSettings>
  )
}

const RelationshipDetails = (props: {
  loading: boolean
  isStatsWidgetVisible: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  main: { data: UserCompanyType[] } | undefined
  relationships: { data: UserCompanyContactListItem[]; total_item_count: number } | undefined
  former: { data: UserCompanyContactListItem[]; total_item_count: number } | undefined
}) => {
  const { main, relationships, former, isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = props

  if (!props.loading && (!main?.data.length || !relationships?.data.length)) return <Empty title="No relationship details found" />

  const mainData = main?.data?.[0]
  const { Stats, UserFullName = '', CompanyMd5 = '', CompanyNameText = '' } = mainData || {}
  const { UserKeyMd5 = '', ...restStatsData } = Stats || {}

  const userKeyMd5Keys: (keyof StatsType<{ CompanyMd5: string }>)[] = [
    'LastInboundMsgUserKeyMd5',
    'LastActivityUserKeyMd5',
    'LastOutboundMsgUserKeyMd5',
    'LastMeetingUserKeyMd5'
  ]

  const stats = {
    ...restStatsData,
    ...userKeyMd5Keys.reduce((acc, key) => ({ ...acc, [key]: UserKeyMd5 }), {})
  }

  return (
    <>
      <Wide>
        <Columns>
          {isStatsWidgetVisible && (
            <Column md={4}>
              <ActivityStats
                isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
                loading={props.loading}
                introducerMd5={UserKeyMd5}
                companyMd5={CompanyMd5}
                companyName={CompanyNameText}
                userName={UserFullName}
              />
              <LatestTouchpoints loading={props.loading} stats={stats} />
            </Column>
          )}
          <Column md={8} style={{ height: '100%' }}>
            <Columns>
              <Column md={6}>
                <Widget>
                  <Heading icon={['far', 'bolt']} title="Quick facts" />
                  <Box py={1} mt={1}>
                    <Connected loading={props.loading} stats={stats} />
                  </Box>
                  <Box py={1}>
                    <Info totalRelationships={relationships?.total_item_count} total={former?.total_item_count} loading={props.loading} />
                  </Box>
                </Widget>
              </Column>
              <Column md={6}>
                <TopContacts items={relationships?.data} total={relationships?.total_item_count} loading={props.loading} />
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Wide>
      <Narrow>
        <RelationHeader loading={props.loading} main={main} />
        {isStatsWidgetVisible && (
          <ActivityStats
            isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
            loading={props.loading}
            introducerMd5={UserKeyMd5}
            companyMd5={CompanyMd5}
            companyName={CompanyNameText}
            userName={UserFullName}
          />
        )}
        <Widget>
          <Heading icon={['far', 'bolt']} title="Quick facts" />
          <Box py={1} mt={1}>
            <Connected loading={props.loading} stats={stats} />
          </Box>
          <Box py={1}>
            <Info totalRelationships={relationships?.total_item_count} total={former?.total_item_count} loading={props.loading} />
          </Box>
        </Widget>
        <TopContacts items={relationships?.data} total={relationships?.total_item_count} loading={props.loading} />
        <LatestTouchpoints loading={props.loading} stats={stats} />
      </Narrow>
    </>
  )
}

const RelationshipUserCompany = (props: any) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { setMobileHeader } = React.useContext(LayoutContext)
  const { teamContextValue } = React.useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const wide = useWide()

  const { userActivitiesAccess } = usePrivateFeatures([from, to]) || {}
  const { isStatsWidgetVisible = false, isDetailedActivityFromStatsWidgetVisible = false } = userActivitiesAccess || {}

  React.useEffect(() => {
    setMobileHeader('Relationship Details')
  }, [setMobileHeader])

  const headerMemoUrls = useMemo(() => {
    return [
      { key: 'main', url: `/users/${from}/companies/${to}?TeamNumber=${teamNumber}&Take=3&IncludeStats=True` },
      {
        key: 'marketDataIntegration',
        url: '/usersettings/marketDataIntegration'
      }
    ]
  }, [from, to, wide, teamNumber])

  const contentMemoUrls = useMemo(() => {
    return [
      { key: 'main', url: `/users/${from}/companies/${to}?TeamNumber=${teamNumber}&Take=${wide ? 4 : 3}&IncludeStats=True` },
      {
        key: 'relationships',
        url: `/users/${from}/companies/${to}/people?TeamNumber=${teamNumber}&Take=${wide ? 4 : 3}&SortScoreType=InfluenceScore&SortBy=ScoreDesc`
      },
      { key: 'former', url: `/users/${from}/companies/${to}/people?TeamNumber=${teamNumber}&Take=1&EmployeeType=Former` }
    ]
  }, [from, to, wide, teamNumber])

  return (
    <>
      <Wide>
        <DynamicEntity urls={headerMemoUrls} component={RelationHeader} nativeBack={true} id="rel_details" />
      </Wide>
      <Switch>
        <Dashboard {...props} {...{ to, frm: from }} links={dashboardLinks}>
          <PrivateRoute
            hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
            exact
            path={`${url}/:from/:via/:to/activities`}
            component={RelationshipActivitiesPage}
          />
          <Route exact path={`${url}/:from/:via/:to/contacts`} component={RelationshipContactsPage} />

          <Route exact path={`${url}/:from/:via/:to`}>
            <DynamicEntity<{
              extraProps: {
                addprops: Pick<ComponentProps<typeof RelationshipDetails>, 'isStatsWidgetVisible' | 'isDetailedActivityFromStatsWidgetVisible'>
              }
            }>
              urls={contentMemoUrls}
              component={RelationshipDetails}
              nativeBack={true}
              id="rel_details_narrow"
              keepMounted
              addprops={{
                isStatsWidgetVisible,
                isDetailedActivityFromStatsWidgetVisible
              }}
              subHeader={(resp: { data: ({ main: { data: UserCompanyType[] } } | undefined)[] }) => {
                const { UserFullName, CompanyNameText } = resp.data[0]?.main.data[0] || {}
                const title = UserFullName && CompanyNameText ? `${UserFullName} and ${CompanyNameText}` : ''
                return (
                  <Typography title={title} variant="body1" style={{ maxWidth: '100%' }} noWrap ellipsis>
                    {title}
                  </Typography>
                )
              }}
            />
          </Route>
        </Dashboard>
      </Switch>
    </>
  )
}

export default RelationshipUserCompany
