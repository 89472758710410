import React, { ForwardedRef, forwardRef } from 'react'

import { ButtonProps as MuiButtonProps, ButtonTypeMap, Button as MuiButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export type DisablePaddingType = AddPrefix<'disable', 'Padding' | 'PY' | 'PX' | 'PR' | 'PL' | 'PT' | 'PB'>

const useStyles = makeStyles<{ bold?: boolean }>()((theme, { bold }) => ({
  label: {
    fontWeight: bold ? 600 : 500
  },
  rounded: {
    borderRadius: theme.spacing(3)
  },
  link: {
    verticalAlign: 'unset',
    '&:hover': {
      background: 'transparent'
    },
    '&.MuiButton-text': {
      textTransform: 'initial'
    }
  },
  widget: {
    background: '#EAF5FE',
    '&.Mui-disabled': {
      background: theme.palette.background.light
    },
    letterSpacing: '0.15px',
    '&.MuiButton-text': {
      textTransform: 'none'
    }
  },
  disablePadding: {
    margin: `-${theme.spacing(1)}`
  },
  disablePX: {
    marginRight: `-${theme.spacing(1)}`,
    marginLeft: `-${theme.spacing(1)}`
  },
  disablePL: {
    marginLeft: `-${theme.spacing(1)}`
  },
  disablePR: {
    marginRight: `-${theme.spacing(1)}`
  },
  disablePY: {
    marginTop: `-${theme.spacing(1)}`,
    marginBottom: `-${theme.spacing(1)}`
  },
  disablePT: {
    marginTop: `-${theme.spacing(1)}`
  },
  disablePB: {
    marginBottom: `-${theme.spacing(1)}`
  }
}))

type Variant = MuiButtonProps['variant'] | 'link' | 'widget'

export type ButtonProps<C extends React.ElementType = ButtonTypeMap['defaultComponent']> = Modify<
  MuiButtonProps<C, { component?: C; form?: 'default' | 'rounded'; bold?: boolean } & { [key in DisablePaddingType]?: boolean }>,
  { variant?: Variant }
>

export const disablePaddingsValues = ['disablePadding', 'disablePY', 'disablePX', 'disablePR', 'disablePL', 'disablePT', 'disablePB']

const ButtonInner = <C extends React.ElementType = ButtonTypeMap['defaultComponent']>(
  props: ButtonProps<C>,
  ref?: ForwardedRef<HTMLButtonElement>
) => {
  const { bold = true, variant = 'outlined', form, disablePadding, disablePY, disablePX, disablePR, disablePL, disablePT, disablePB, ...rest } = props

  const { classes, cx } = useStyles({ bold })
  return (
    <MuiButton
      ref={ref}
      {...rest}
      classes={{
        root: cx(
          classes.label,
          { [classes.rounded]: form === 'rounded', [classes.widget]: variant === 'widget' },
          disablePaddingsValues.map((key) => ({ [classes[key as keyof typeof classes]]: props[key] }))
        ),
        text: cx({ [classes.link]: variant === 'link' }),
        ...props.classes
      }}
      variant={variant === 'link' || variant === 'widget' ? 'text' : variant}
    >
      {props.children}
    </MuiButton>
  )
}

const Button = forwardRef(ButtonInner) as <C extends React.ElementType = ButtonTypeMap['defaultComponent']>(
  props: ButtonProps<C> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof ButtonInner>

export default Button
