import { Box, List, ListItem, ListItemText } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import { AvatarWrapper } from '_shared/Avatar'
import Typography from '_shared/Typography'

type SelectAccountProps = {
  list: any
  setIndex: (index: number) => void
}

type ItemType = {
  Name: string
  Email: string
  Website: string
}

type ItemProps = {
  index: number
  item: ItemType
  selectAccount: (index: number) => void
}

const Item = ({ index, item, selectAccount }: ItemProps) => {
  const handleClick = () => {
    selectAccount(index)
  }

  return (
    <ListItem disableGutters button onClick={handleClick}>
      <Box display={'flex'} flex="1" alignItems="center">
        <Box m={'9px 16px 0 3px'}>
          <AvatarWrapper name={item.Name} userKey={item.Email} logoUrl={item.Website} hideName size="sm" />
        </Box>
        <ListItemText
          primary={item.Name}
          secondary={
            <Box component="span" title={item.Email}>
              <Typography component="span" color="text.secondary">
                {item.Email || item.Website}
              </Typography>
            </Box>
          }
        />
      </Box>
    </ListItem>
  )
}

const SelectAccount = ({ list, setIndex }: SelectAccountProps) => {
  const { params } = useRouteMatch<SalesforceMatchParams>()
  const { entity: openedPage } = params
  const selectAccount = (index: number) => {
    setIndex(index)
  }

  return (
    <Box
      p={1}
      minHeight="calc(100vh - 28px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
      textAlign="center"
    >
      <Typography variant="h4" style={{ marginBottom: 8 }}>
        Pick {openedPage === 'contact' ? 'a' : 'an'} {openedPage}
      </Typography>
      <Typography color="text.secondary">Multiple {openedPage}s were found in Salesforce. Please pick one to proceed.</Typography>
      <List>
        {list.map((item: ItemType, index: number) => (
          <Item key={index} index={index} selectAccount={selectAccount} item={item} />
        ))}
      </List>
    </Box>
  )
}

export default SelectAccount
