import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem, { ProfileItemProps } from '_core/components/ProfileItem'

const useStyles = makeStyles()((theme) => ({
  heading: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginTop: theme.spacing(2)
  }
}))

const PickSuggestionsList = ({ suggestions, loading }: { suggestions?: ProfileItemProps[]; loading: boolean }) => {
  const { classes } = useStyles()

  return (
    <>
      <Typography variant="h4" className={classes.heading}>
        Suggestions
      </Typography>
      <Box mx={-2}>
        <Repeater items={suggestions || []} variant="list" component={ProfileItem} skeleton={{ size: 4, loading }} />
      </Box>
    </>
  )
}

export default PickSuggestionsList
