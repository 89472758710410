import React from 'react'

import { useHistory } from 'react-router-dom'

import CreateRequestForm from '_core/components/introductions/CreateRequestForm'

import { IntroductionRequest } from '_core/hooks/useIntroductionRequestForm'

import { post, put } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

import Paths from 'Paths'

const ReportRequestForm = ({ loading }: { loading: boolean }) => {
  const history = useHistory()

  const goToNextStep = (planUid: string) => {
    const requestUrl = `${Paths._introductions}/${planUid}`
    const nextStep = `${requestUrl}/sharing-settings?nextStep=${requestUrl}/add-participants`
    history.replace(`${requestUrl}/edit?nextStep=${nextStep}`)
    history.push(nextStep)
  }

  const create = async (formValues: IntroductionRequest) => {
    const { planUid, requester, reason, beneficiaryCompany, beneficiaryPerson, ...data } = formValues

    const res = await post<IntroductionPlanResp>('/prospecting/newplan', {
      requesterEmail: requester.email,
      requesterName: requester.name,
      reason: reason.value,
      beneficiaryCompanyUrl: beneficiaryCompany.website,
      beneficiaryCompanyName: beneficiaryCompany.name,
      beneficiaryCompanyMd5: beneficiaryCompany.md5,
      beneficiaryPersonEmail: beneficiaryPerson.email,
      beneficiaryPersonName: beneficiaryPerson.name,
      beneficiaryPersonMd5: beneficiaryPerson.md5,
      ...data
    })

    if (res) {
      await put<IntroductionPlanResp>('/prospecting/editplan', {
        planUid: res.planUid,
        closedOut: getLocal()
      })
      goToNextStep(res.planUid)
    }
  }

  return <CreateRequestForm loading={loading} save={create} beneficiaryUpdateAction="transform" />
}

export default ReportRequestForm
