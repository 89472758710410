import { useRef, useEffect, useState, useCallback } from 'react'
import 'abortcontroller-polyfill'

import { uuid as uid } from 'utils/demoUtils'
import { request } from 'utils/fetchUtils'

const useAbortableFetch = <T>() => {
  const abortRef = useRef<null | AbortController>(null)
  const requestActive = useRef<string | null>(null)
  const [{ result, loading }, setState] = useState<{ loading: boolean; result?: T }>({ loading: false })

  const forceAbort = useCallback(() => {
    if (abortRef.current) {
      abortRef.current.abort()
    }
    requestActive.current = null
  }, [])

  useEffect(() => {
    return forceAbort
  }, [forceAbort])

  const setResult = useCallback((data: typeof result) => {
    setState({ loading: false, result: data })
  }, [])

  const setLoading = useCallback(() => {
    setState({ loading: true })
  }, [])

  const fetchWithAbort = useCallback(
    async (reqData: { url: string; options?: RequestInit } | { url: string; options?: RequestInit }[]) => {
      const abortController = new AbortController()
      abortRef.current = abortController
      const activeRequestUID = uid()
      requestActive.current = activeRequestUID

      const urls: { url: string; options?: RequestInit }[] = Array.isArray(reqData) ? reqData : [reqData]
      setLoading()

      const promises = urls.map(async ({ url, options = {} }) => {
        return await request(url, { ...options, ...(abortRef.current ? { signal: abortRef.current.signal } : {}) })
      })

      const response = await Promise.all(promises)
      const data = (Array.isArray(reqData) ? response : response[0]) as typeof result
      if (requestActive.current === activeRequestUID) {
        setResult(data)
        return data
      }
    },
    [setLoading, setResult]
  )

  const reset = useCallback(() => {
    forceAbort()
    setResult(undefined)
    requestActive.current = null
  }, [forceAbort, setResult])

  return { loading, result, setForceLoading: setLoading, setResult, reset, fetchWithAbort, forceAbort }
}

export default useAbortableFetch
