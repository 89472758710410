import { useContext } from 'react'

import { RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'

import { GeneralSettingsContext, PageNames } from '_core/context/GeneralSettings'

import Radio from '_shared/forms/Radio'
import Typography from '_shared/Typography'

import Repeater from '_core/components/lists/Repeater'
import Settings from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const GeneralSettings = (props: any) => {
  const { isSaving, save: saveSettings } = useSaveSettings()
  const { register, getValues } = useForm({ mode: 'onChange', shouldUnregister: true })
  const { setHomePage } = useContext(GeneralSettingsContext)

  const save = async (action: SaveAction) => {
    const homePage = getValues('home_page')
    await saveSettings('/usersettings/general', { homePage }, action, 'General settings')
    setHomePage(`/${homePage}`)
  }

  return (
    <Settings isSaving={isSaving} initialLoading={props.loading} save={save}>
      <Typography variant="body1">Set my home screen to the following</Typography>
      <RadioGroup aria-label="home-page" name="home_page" defaultValue={props.homePage} style={{ paddingTop: 12 }}>
        <Repeater
          skeleton={{ size: 4, loading: false }}
          items={props.options?.map((pageName: PageNames) => ({
            ...register('home_page'),
            value: pageName,
            label: `${pageName}`
          }))}
          addprops={{ style: { padding: 0, marginTop: -6, color: '#979797', lineHeight: 1.75 } }}
          component={Radio}
        />
      </RadioGroup>
    </Settings>
  )
}

export default GeneralSettings
