import { useContext, useEffect } from 'react'

import { Route, Switch, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const baseURL = `${Paths._deals}/:dealId`

const Component = (props: any) => {
  const { setMobileHeader } = useContext(LayoutContext)
  const colleagues = props.loading
    ? props.Colleagues
    : [
        { UserKeyMd5: '4f5515e4fd62455f42620fe6130e4e20', UserEmail: 'marharyta@dotalign.com', UserFullName: 'Marharyta Aksonova' },
        { UserKeyMd5: '8e9020cd702459b111f77e758cb7258d', UserEmail: 'vince@dotalign.com', UserFullName: 'Vince Scafaria' },
        { UserKeyMd5: 'a1fe921a2b69bd63c6be18369b646942', UserEmail: 'jaspreet@dotalign.com', UserFullName: 'Jaspreet Bakshi' },
        { UserKeyMd5: 'mark.harrington@newsday.com', UserEmail: 'mark.harrington@newsday.com', UserFullName: 'Mark Harrington' }
      ]

  useEffect(() => {
    setMobileHeader(props.Synopsis, props.Synopsis ? false : true)
  }, [setMobileHeader, props.Synopsis])

  return (
    <Switch>
      <Route path={`${baseURL}/colleagues`}>
        <Topbar nativeBack sub={`Colleagues · ${colleagues?.length || 0}`} />
        <Widget scope="list">
          <Repeater
            direction="vertical"
            variant="list"
            component={ProfileItem}
            skeleton={{ size: 10, loading: props.loading }}
            items={colleagues?.map((item: any) => ({
              name: item.UserFullName,
              byline: item.UserEmail,
              userKey: item.UserKeyMd5,
              link: `${Paths._people}/${item.UserKeyMd5}`
            }))}
          />
        </Widget>
      </Route>
    </Switch>
  )
}

const DealPage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { dealId } = useParams<any>()

  return (
    <DynamicEntity
      id="deal"
      url={`/ourDeals?teamNumber=${teamContextValue.teamNumber}&DealKey=${dealId}`}
      component={Component}
      empty="No results found"
      emptySubtitle="No data"
    />
  )
}

export default DealPage
