type ITeamMember = {
  id: string
  name: string
  email: string
  credentials: TeamMemberCredential[]
} & SharingData

const useTransformTeamData = () => {
  const transformDefaultSharing = (defaultSharingOptions: SharingDataRes | undefined): SharingData | undefined => {
    if (!defaultSharingOptions) return

    return {
      isSharing: defaultSharingOptions.shareRelationships,
      isSharingContactInfo: defaultSharingOptions.shareContactInfo,
      isSharingInteractionStats: defaultSharingOptions.shareInteractionStats,
      isSharingContactInfoWithAdminsAndCurators: defaultSharingOptions.shareContactInfoWithAdminsAndCurators
    }
  }

  const transformTeam = (team: TeamDataRes): Team | undefined => {
    if (!team) {
      return
    }

    return {
      id: team.number,
      name: team.name,
      membersTotal: team.memberCount,
      isActive: team.isActive,
      membersShared: team.sharingMemberCount,
      defaultSharingOptions: transformDefaultSharing(team.defaultSharingOptions)
    }
  }

  const transformTeamList = (serverData?: TeamDataRes[]): Team[] => {
    if (!serverData) {
      return []
    }

    return serverData.map(transformTeam).filter((i): i is Team => !!i)
  }

  const transformTeamMember = (member: TeamMemberRes): ITeamMember => {
    return {
      id: member.userKeyMd5,
      name: member.name,
      email: member.emailAddress,
      isSharing: member.isSharing,
      isSharingContactInfo: member.isSharingContactInfo,
      isSharingInteractionStats: member.isSharingInteractionStats,
      isSharingContactInfoWithAdminsAndCurators: member.isSharingContactInfoWithAdminsAndCurators,
      credentials: member.credentials
    }
  }

  const transformTeamMembersList = (serverData: TeamMemberRes[] | undefined): ITeamMember[] | null => {
    if (!serverData) {
      return null
    }

    return serverData.map(transformTeamMember)
  }

  return {
    transformTeam,
    transformTeamList,
    transformTeamMembersList
  }
}

export default useTransformTeamData
