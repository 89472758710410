import React, { HTMLAttributes, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import ProfileItem from '_core/components/ProfileItem'

export type PersonOptionType = {
  name: string
  email: string
  jobTitle?: string
  company?: string
}

export type SuggestPersonOptionType = PersonOptionType & { label: string }

export const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: PersonOptionType | SuggestPersonOptionType) => {
  return (
    <li {...props} key={value.email || value.name}>
      {'label' in value ? (
        <Typography>{value.label}</Typography>
      ) : (
        <Box ml={-2} mr={-2}>
          <ProfileItem
            variant="expandable"
            name={value.name}
            userKey={value.email}
            byline={
              <Box maxWidth="100%">
                <Typography color="text.secondary" ellipsis>
                  {value.email}
                </Typography>
                {value.jobTitle && value.company && (
                  <Typography>
                    {value.jobTitle} at {value.company}
                  </Typography>
                )}
              </Box>
            }
          />
        </Box>
      )}
    </li>
  )
}

export const useCreatePersonOption = (closeDialog: () => void) => {
  const [createdOption, setCreatedOption] = useState<Pick<PersonOptionType, 'name' | 'email'>>()

  const closeCreateOptionDialog = () => {
    setCreatedOption(undefined)
    closeDialog()
  }

  return {
    createdOption,
    setCreatedOption,
    closeCreateOptionDialog
  }
}

const validateEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/i

const initCreatedOption = {
  name: '',
  email: ''
}

const errorMsg = 'Not valid value'

const CreatePersonOptionDialog = ({
  opened,
  close,
  submit,
  value
}: {
  opened: boolean
  close: () => void
  submit: (formValues: Pick<PersonOptionType, 'name' | 'email'>) => void
  value?: Pick<PersonOptionType, 'name' | 'email'>
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: initCreatedOption })

  useEffect(() => {
    if (value) {
      Object.entries(value).forEach(([fieldName, fieldValue]: any) => {
        setValue(fieldName, fieldValue)
      })
    }
  }, [setValue, value])

  const save = ({ name, email }: Pick<PersonOptionType, 'name' | 'email'>) => {
    submit({ name, email })
  }

  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Add a new person" />} maxWidth={420}>
      <DialogContent>
        <Box>
          <TextField
            {...register('name', {
              required: errorMsg,
              pattern: {
                value: /\S/,
                message: errorMsg
              }
            })}
            label="Name"
            fullWidth
            errorMessage={errors.name?.message}
          />
          <Box mt={1.5}>
            <TextField
              {...register('email', {
                required: errorMsg,
                pattern: {
                  value: validateEmail,
                  message: errorMsg
                }
              })}
              label="Email"
              fullWidth
              errorMessage={errors.email?.message}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary" disablePL>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit(save)} disabled={!!Object.keys(errors).length} disablePR>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreatePersonOptionDialog
