import { ReactElement, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import LeaveDialog from '_core/components/dialogs/Leave'

import useDialog from '_core/hooks/useDialog'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { actionButtons } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  buttonsContainer: actionButtons(theme)
}))

type IsDirtyProps = {
  isDirty: true
  leaveText?: string
}

type NoLeaveDialogProps = {
  isDirty?: false
  leaveText?: never
}

type NextStepFrameProps = {
  back: ReactElement | null
  next: ReactElement
  disabled?: boolean
  children: ReactNode
  reset?: () => void
} & (IsDirtyProps | NoLeaveDialogProps)

const NextStepFrame = ({ next, back, disabled, children, reset, isDirty }: NextStepFrameProps) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const handleClose = useSidepanelClose()
  const { classes } = useStyles()

  const leaveConfirm = () => {
    if (reset) {
      reset()
    }
    handleClose()
  }

  const cancel = () => {
    isDirty ? openDialog() : leaveConfirm()
  }

  return (
    <>
      {children}
      <Box className={classes.buttonsContainer}>
        {back}
        <Box mr={-1} ml="auto">
          <Button onClick={cancel} disabled={disabled} variant="text" color="secondary">
            Cancel
          </Button>
          {next}
        </Box>
      </Box>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={leaveConfirm} />
    </>
  )
}

export default NextStepFrame
