import { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

export type IntroducerItem = {
  id: string
  name: string
  byline: string
  byline2: string
  title: string
  score: number
  link: string
  company: {
    name: string
    link: string
    sidepanel: SidepanelType
  }
  sidepanel: SidepanelType
  lastInbound: string
  lastOutbound: string
  lastMeeting: string
  nextFutureMeeting: string
  variant: string
  tags: {
    IdProperty: string
    TagNames: {
      Md5: string
      Category: string
      Tag: string
    }[]
  }[]
}

const useIntroducersListPayloads = (items: IntroducerItem[]) => {
  const [withTagsList, setWithTagsList] = useState<{ identifier: string; tags: UngroupedTag[] }[]>([])
  const { payloads } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (items.length) {
      setWithTagsList(() =>
        items.map(({ id, tags }) => {
          return { identifier: id, tags: tags?.[0]?.TagNames.map(({ Category, Tag }) => ({ categoryName: Category, tagName: Tag })) || [] }
        })
      )
    }
  }, [items.length])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const identifiers = payloads.value.map((el: any) => el.identifier)
        const indexes = identifiers.map((identifier: string) => withTagsList?.findIndex((item) => item.identifier === identifier))
        const newItems = [...withTagsList]
        indexes.forEach((index: number) => {
          const updatedTags = payloads.value.find((el: any) => el.identifier === newItems[index]?.identifier)?.tags
          newItems[index] = {
            ...newItems[index],
            ...(updatedTags ? { tags: updatedTags } : {})
          }
        })
        setWithTagsList(newItems)
      }
    }
    if (payloads && payloads.action === 'SYNC_TAGS') {
      const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
      if (taggableType === 'people') {
        const renamed = withTagsList.map(({ identifier, tags }) => {
          const nonDeprecated = tags.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          return {
            identifier,
            tags: nonDeprecated.map(({ categoryName, tagName }) => {
              const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

              const { newTagName = tagName } =
                tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
              return { categoryName: newCategoryName, tagName: newTagName }
            })
          }
        })

        setWithTagsList(renamed)
      }
    }
    if (payloads && payloads.action === 'START_FILE_TRANSFER') {
      enqueueSnackbar(payloads.value.message, {
        autoHideDuration: 4000,
        variant: payloads.value.variant
      })
    }
  }, [payloads])

  return {
    withTagsList
  }
}

export default useIntroducersListPayloads
