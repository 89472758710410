import React from 'react'
import { Tabs, Tab, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ isNavBar?: boolean }>()((theme, { isNavBar }) => ({
  root: {
    width: '100%'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: theme.spacing(2)
  },
  indicator: {
    height: '2px'
  },
  switcherContainer: {
    marginBottom: isNavBar ? '' : theme.spacing(2),
    '& .MuiTabs-root': {
      minHeight: 24,
      alignItems: 'center'
    },
    '& .MuiTabs-scrollButtons': {
      width: '25px',
      height: '25px',
      border: '1px solid grey',
      borderRadius: '50%',
      transition: 'width 0.5s',
      '&[class*="disabled"]': {
        width: 0
      }
    }
  },
  tabsRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  tabItem: {
    marginRight: theme.spacing(3),
    paddingTop: '6px',
    paddingBottom: '6px',
    lineHeight: '24.5px',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '1.25px',
    minHeight: 24,
    minWidth: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    whiteSpace: 'nowrap',
    flowGrow: 1,
    '&:last-of-type': {
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      flowGrow: 0
    }
  }
}))

const TabSet = (props: TabSetProps) => {
  const { classes, cx } = useStyles({ isNavBar: props.isNavBar })
  const TabComponent = React.useMemo(
    () => (props.tabIndex >= 0 && props.tabs[props.tabIndex] ? props.tabs[props.tabIndex].component : undefined),
    [props.tabIndex, props.tabs]
  )
  const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== undefined) {
      props.setTabIndex(newValue)

      if (typeof props.onChange === 'function') {
        props.onChange(newValue)
      }
    }
  }

  return (
    <>
      <Grid item className={classes.switcherContainer}>
        <Tabs
          value={props.tabIndex > -1 ? props.tabIndex : false}
          onChange={changeTab}
          indicatorColor="primary"
          variant={props.isNavBar ? 'standard' : 'scrollable'}
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="primary"
          classes={{ indicator: classes.indicator, root: cx(classes.tabsRoot, props.className) }}
        >
          {props.tabs.map((tab: TabItem, index: number) => (
            <Tab className={cx(classes.tabItem, tab.className)} key={index} label={tab.label} disabled={props.disabled || tab.disabled} />
          ))}
        </Tabs>
      </Grid>
      {TabComponent && <TabComponent {...props.tabProps} />}
    </>
  )
}

export default TabSet

export interface TabSetProps {
  setTabIndex: (i: number) => void
  tabIndex: number
  tabs: TabItem[]
  tabProps?: any
  isNavBar?: boolean
  onChange?: (newTab: number) => void
  disabled?: boolean
  className?: string
}

export interface TabItem {
  label: string
  className?: string
  component?: (props: any) => any
  disabled?: boolean
  highlighted?: boolean
}
