import { useParams } from 'react-router-dom'

import EditPerson from '_core/components/EditPerson'

const AddPersonPage = () => {
  const params = useParams<{ new?: string }>()
  return <EditPerson newForm={!!params.new} />
}

export default AddPersonPage
