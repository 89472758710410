import React, { useContext, useEffect } from 'react'

import { Redirect } from 'react-router-dom'

import Page from '_shared/Page'

import { GridTypes } from '_core/components/grid'
import AddContactsForm from '_core/components/introductions/AddContactsForm'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type PageProps = {
  items: IntroductionContactResp[]
  loading: boolean
  setPageSize: GridTypes['setPageSize']
  total: number
} & RequestAccessType

const AddRequestContactsPage = (props: PageProps) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Add identified people')
  }, [setSubHeader])

  if (props.planUid && (!props.querierHasWriteAccess || props.closedOut)) {
    return <Redirect to={`${Paths._introductions}/${props.planUid}`} />
  }

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <AddContactsForm loading={props.loading} items={props.items} planUid={props.planUid} />
      </Narrow>
    </Page>
  )
}

export default AddRequestContactsPage
