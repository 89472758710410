import { useState, useEffect, useRef } from 'react'

import moment, { Moment as MomentType } from 'moment'

import { hour } from '_core/data/time'

import { getLocal } from 'utils/Utils'

const useEventTime = ({ startTimeUTC, endTimeUTC, isCancelled }: Partial<Pick<EventType, 'startTimeUTC' | 'endTimeUTC' | 'isCancelled'>>) => {
  const [startsIn, setStartsIn] = useState<MomentType | null>(null)
  const finishIn = !isCancelled ? Math.ceil(getLocal(endTimeUTC).diff(getLocal())) : -1
  const startedToday = getLocal().startOf('day').diff(getLocal(startTimeUTC).startOf('day'), 'days') === 0
  const interval = useRef<NodeJS.Timeout | null>(null)

  const clear = () => {
    setStartsIn(null)
    if (interval.current) {
      clearInterval(interval.current)
      interval.current = null
    }
  }

  useEffect(() => {
    return clear
  }, [startTimeUTC, endTimeUTC])

  useEffect(() => {
    if (finishIn < 0) {
      clear()
    }
  }, [finishIn])

  const timeoutHours = getLocal(startTimeUTC).subtract(1, 'hour').diff(getLocal(), 'hours')

  useEffect(() => {
    if (!startsIn && startTimeUTC && finishIn > 0) {
      const setTime = () => {
        const diff = getLocal(startTimeUTC).diff(getLocal(), 'seconds')
        setStartsIn(moment.utc(moment.duration(diff, 'seconds').asMilliseconds()))
      }
      setTime()

      const setIntrvl = () => {
        interval.current = setInterval(setTime, 1000)
      }

      if (timeoutHours < 12) {
        const timeout = getLocal(startTimeUTC).subtract(1, 'hour').diff(getLocal(), 'milliseconds')
        if (timeout >= hour) {
          setTimeout(setIntrvl, timeout)
        } else {
          setIntrvl()
        }
      }
    }
  }, [finishIn, startTimeUTC, startsIn, timeoutHours])

  return {
    startsIn: startsIn?.unix(),
    finishIn,
    startedToday
  }
}

export default useEventTime
