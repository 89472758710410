import { useCallback, useContext, useMemo } from 'react'

import * as _ from 'lodash'

import { AuditContext } from '_core/context/Audit'

const useAuditEntities = () => {
  const { entities, setEntities, activeId, setActiveId, isDirty, initial, primary } = useContext(AuditContext)
  const [inv, v] = useMemo(
    () =>
      _.partition(
        entities?.map((entity) => ({
          ...entity,
          isPrimary: entity.id === primary,
          isActive: entity.id === activeId,
          distilledIdentifiersKeys: [...new Set(entity.identifiers.map(({ distilledKeyMd5 }) => distilledKeyMd5))]
        })),
        { id: 'invalid' }
      ),
    [activeId, primary, entities]
  )

  const valid = useMemo(() => v || [], [v])
  const invalid = useMemo(() => inv || [], [inv])

  const setActive = useCallback(
    (id: string) => {
      setActiveId(id)
    },
    [setActiveId]
  )

  const addEntity = useCallback(
    (entity: Omit<AuditEntityState, 'identifiers'>) => {
      setEntities((prevState = []) => {
        const [prevInvalid, prevValid] = _.partition(prevState, { id: 'invalid' })
        return prevState.find(({ id }) => id === entity.id) ? prevState : [...prevValid, { ...entity, identifiers: [] }, ...prevInvalid]
      })
    },
    [setEntities]
  )

  const moveIdentifiers = useCallback(
    (newEntityId: string, items: AuditIdentifier[]) => {
      if (entities) {
        setEntities((prevState = []) => {
          const moveFromIndex = prevState.findIndex((entity) => entity.id === activeId)
          const moveToIndex = prevState.findIndex((entity) => entity.id === newEntityId)

          const newState = [...prevState]

          newState.splice(moveFromIndex, 1, {
            ...newState[moveFromIndex],
            identifiers: newState[moveFromIndex]?.identifiers.filter(({ md5 }) => !items.find((item) => item.md5 === md5)) || []
          })

          newState.splice(moveToIndex, 1, {
            ...newState[moveToIndex],
            identifiers: [...(newState[moveToIndex]?.identifiers || []), ...items]
          })

          return newState
        })

        setActive(newEntityId)
      }
    },
    [activeId, entities, setActive, setEntities]
  )

  const markIdentifierAsInvalid = useCallback(
    (identifier: AuditIdentifier, reason: string) => {
      const entityId = 'invalid'
      if (!entities?.find((entity) => entity.id === 'invalid')) {
        addEntity({ id: entityId, name: 'Invalid' })
      }
      moveIdentifiers(entityId, [{ ...identifier, invalidReason: reason }])
    },
    [addEntity, entities, moveIdentifiers]
  )

  const undoInvalid = useCallback(
    (identifiers: AuditIdentifier[]) => {
      if (primary) {
        const distilledGroups = identifiers.reduce(
          (acc, { distilledKeyMd5, ...rest }) => ({
            ...acc,
            [distilledKeyMd5]: distilledKeyMd5 in acc ? [...acc[distilledKeyMd5], { ...rest, distilledKeyMd5 }] : [{ ...rest, distilledKeyMd5 }]
          }),
          {} as { [key: AuditIdentifier['distilledKeyMd5']]: AuditIdentifier[] }
        )

        ;(Object.keys(distilledGroups) as AuditIdentifier['distilledKeyMd5'][]).forEach((distilledKeyMd5) => {
          moveIdentifiers(
            valid.find(({ distilledIdentifiersKeys }) => distilledIdentifiersKeys.includes(distilledKeyMd5))?.id || primary,
            distilledGroups[distilledKeyMd5].map(({ invalidReason, ...identifier }) => identifier)
          )
        })
      }
    },
    [valid, moveIdentifiers, primary]
  )

  const removeEntity = useCallback(
    (removeId: string) => {
      if (primary && entities) {
        const identifiers = entities.find(({ id }) => id === removeId)?.identifiers || []

        if (removeId === 'invalid') {
          undoInvalid(identifiers)
        } else {
          moveIdentifiers(
            primary,
            identifiers.map(({ invalidReason, ...identifier }) => identifier)
          )
        }

        setEntities((prevState = []) => prevState.filter(({ id }) => id !== removeId))
        if (activeId === removeId) {
          setActive(primary)
        }
      }
    },
    [activeId, entities, undoInvalid, moveIdentifiers, primary, setActive, setEntities]
  )

  const reset = useCallback(() => {
    setEntities(initial)
    if (primary) {
      setActive(primary)
    }
  }, [initial, primary, setActive, setEntities])

  const activeIndex = entities?.findIndex((entity) => entity.id === activeId)

  return {
    activeId,
    activeIndex: typeof activeIndex === 'number' ? activeIndex : -1,
    isInvalidActive: activeId === 'invalid',
    setActive,
    primary,
    valid,
    invalid,
    setEntities,
    addEntity,
    removeEntity,
    undoInvalid,
    reset,
    isDirty,
    markIdentifierAsInvalid,
    moveIdentifiers
  }
}

export default useAuditEntities
