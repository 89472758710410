import { ReactElement } from 'react'

import { Box } from '@mui/material'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'

import Heading from '_core/components/Heading'
import { TagsGroupedList } from '_core/components/TagsList'

const ManageTagsConfirmDialog = ({
  submit,
  creations,
  removals,
  renames,
  close
}: {
  submit: () => void
  creations?: GroupedTag[]
  removals?: GroupedTag[]
  renames?: Modify<
    GroupedTag,
    { categoryName: string | ReactElement; tagNames: Modify<GroupedTag['tagNames'][number], { tagName: string | ReactElement }>[] }
  >[]
  close: () => void
}) => {
  return (
    <Dialog open={!!creations || !!renames || !!removals} onClose={close} title={<DialogTitle title="The following updates are going to be saved" />}>
      <DialogContent>
        {[
          { items: creations, title: 'New tags' },
          { items: renames, title: 'Edited tags' },
          { items: removals, title: 'Removed tags' }
        ]
          .filter(({ items }) => items?.length)
          .map(({ items, title }, i) => (
            <Box key={i} mb={2}>
              <Heading title={title} />
              <TagsGroupedList items={items} />
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disablePR>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ManageTagsConfirmDialog
