import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Button from './Button'

const useStyles = makeStyles()((theme) => ({
  endIcon: {
    borderLeft: `1px solid ${theme.palette.hover.primary}`,
    paddingLeft: theme.spacing(1)
  }
}))

const Select = (props: any & { form?: 'default' | 'rounded' }) => {
  const { classes } = useStyles()
  return (
    <Button {...props} classes={{ endIcon: classes.endIcon }} startIcon={props.startIcon} endIcon={props.endIcon}>
      {props.children}
    </Button>
  )
}

export default Select
