import { Control, useController } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { ReasonType } from '_core/hooks/useIntroductionReason'
import useSidebar from '_core/hooks/useSidebar'

import { actionButtons } from 'AppTheme'

import Paths from 'Paths'

export const useStyles = makeStyles()((theme) => ({
  actionButtons: actionButtons(theme),
  actionStickyButtons: {
    ...actionButtons(theme, 'sticky'),
    zIndex: 1
  },
  infiniteLoader: {
    position: 'relative',
    bottom: 50
  },
  buttons: {
    marginBottom: -77,
    [theme.breakpoints.up('sidepanel')]: {
      marginBottom: -57
    }
  },
  wrapper: {
    marginBottom: 77,
    [theme.breakpoints.up('sidepanel')]: {
      marginBottom: 57
    }
  },
  contactsWrapper: {
    paddingBottom: 64,
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 146px)',
    [theme.breakpoints.up('sidepanel')]: {
      minHeight: 'calc(100vh - 153px)'
    },
    [theme.breakpoints.up('phone')]: {
      minHeight: 'calc(100vh - 125px)'
    }
  },
  title: {
    letterSpacing: '0.1px'
  },
  status: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.active
  },
  memo: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.darker
  },
  profileItem: {
    padding: 0
  },
  icon: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  heading: {
    margin: 0
  },
  avatarTitle: {
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  cardProfileItem: {
    height: 'auto',
    minHeight: 'auto',
    padding: 0
  },
  menuList: {
    '& > li': {
      whiteSpace: 'normal'
    }
  },
  empty: {
    margin: `${theme.spacing(2)} 0`
  },
  group: {
    marginBottom: theme.spacing(2)
  },
  select: {
    marginTop: theme.spacing(1.5)
  },
  cardAction: {
    paddingTop: theme.spacing(0.5)
  },
  line: {
    borderColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  }
}))

export type BeneficiaryType = 'person' | 'company'
export type IntroductionRequest = {
  planUid: string
  summary: string
  description: string
  reason: ReasonType
  requester: {
    name: string
    email: string
    md5?: string
  }
  beneficiaryCompany: {
    name: string
    website: string
    md5: string
  }
  beneficiaryPerson: {
    name: string
    email: string
    md5: string
  }
}

export const errorMsg = 'This field is required'

const useIntroductionRequestForm = (control: Control<IntroductionRequest, any>, beneficiaryType: BeneficiaryType) => {
  const { close } = useSidebar()
  const sidepanel = isSidepanel()
  const history = useHistory()
  const handleClose = () => (sidepanel ? close() : history.push(Paths._introductions))

  const { field: reasonField } = useController({
    name: 'reason',
    control,
    rules: {
      validate: (value) => !!value.value || errorMsg
    }
  })
  const { field: requesterField } = useController({ name: 'requester', control, rules: { validate: (value) => !!value.name || errorMsg } })
  const { field: beneficiaryPersonField } = useController({
    name: 'beneficiaryPerson',
    control,
    rules: { validate: (value) => (beneficiaryType === 'company' ? true : !!value.name || errorMsg) }
  })
  const { field: beneficiaryCompanyField } = useController({
    name: 'beneficiaryCompany',
    control,
    rules: { validate: (value) => (beneficiaryType === 'person' ? true : !!value.name || errorMsg) }
  })

  return { handleClose, beneficiaryPersonField, beneficiaryCompanyField, requesterField, reasonField }
}

export default useIntroductionRequestForm
