import React, { useEffect, useContext } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { Switch, Route } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import UploadAgent from '_core/components/UploadAgent'
import UploadSuccess from '_core/components/UploadSuccess'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const agent = 'companies'
const UploadCompaniesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { isAdminOrCurator } = useAdminOrCuratorCheck()

  useEffect(() => {
    setMobileHeader('Upload companies')
  }, [setMobileHeader])

  if (typeof isAdminOrCurator !== 'boolean') {
    return (
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack />
        <Box height="calc(100vh - 88px)">
          <Switch>
            <Route path={`${Paths._companies}/upload/success`}>
              <UploadSuccess agent={agent} />
            </Route>
            <Route>
              <UploadAgent agent={agent} />
            </Route>
          </Switch>
        </Box>
      </Narrow>
      )
    </Page>
  )
}

export default UploadCompaniesPage
