import { useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

const initState = {
  displayName: '',
  emailAddress: ''
}

export type PersonType = typeof initState

export const transformResult = (users: { [key: string]: any }[]) => {
  const transformed = users.map((user) => ({
    displayName: user.displayName,
    emailAddress: user.emailAddress
  }))

  return transformed[0]
}

const useActiveDirectoryPersonData = (email: string) => {
  const [person, setPerson] = useState(initState)
  const [loading, setLoading] = useState(false)
  const { fetchWithAbort } = useAbortableFetch<{ users: { [key: string]: any }[] }>()

  useEffect(() => {
    if (email) {
      ;(async () => {
        setLoading(true)
        const res = await fetchWithAbort({ url: `/teams/principals?searchTerm=${email}` })
        if (res) {
          const details = transformResult(res.users)
          setPerson(details)
          setLoading(false)
        }
      })()
    }
  }, [email])

  return {
    person,
    loading,
    setPerson
  }
}

export default useActiveDirectoryPersonData
