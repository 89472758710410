import { useEffect, useState } from 'react'

import { useWide } from '_core/components/layout'

import useSearchQuery from '_core/hooks/useSearchQuery'

export const timeColumnWidth = 126
export const actionButtonColWidth = { fullWidth: 200, miniWidth: 55 }
export const maxSlidesLength = 4

const useActivitiesSlidesToShow = (listLength: number, containerWidth: number) => {
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList?: string[] }] }>(['customList'])
  const { customList = [], groupBy, view } = queryParams
  const customView = groupBy === 'Contributor' || view === 'Custom'
  const [sToShow, setSlidesToShow] = useState<number>(maxSlidesLength)

  const slidesToShow = customView && listLength > sToShow ? sToShow + 0.2 : sToShow

  const upLgXL = useWide('lgXl')
  const upLg = useWide('lg')
  const upMd = useWide()
  const upTablet = useWide('tablet')
  const upSmall = useWide('sm')

  const customPicked = customList.length > 0

  const isAdditionalAddButtonColumnShown = Boolean(customPicked && upMd && customView)
  const isIcon = upMd ? customList.length >= Math.ceil(slidesToShow) : customList.length !== 0
  const actionButtonWidth = isIcon ? actionButtonColWidth.miniWidth : actionButtonColWidth.fullWidth

  const calculateColumnWidth = () => {
    if (isAdditionalAddButtonColumnShown) {
      const allColumnsAreInViewport = listLength <= slidesToShow
      const devideOn = listLength < slidesToShow ? listLength * (maxSlidesLength / (listLength + 0.97)) : slidesToShow

      const basicColumnWidth = containerWidth / devideOn
      return allColumnsAreInViewport ? basicColumnWidth - actionButtonWidth / devideOn : basicColumnWidth
    }
    return containerWidth / slidesToShow
  }

  const columnWidth = calculateColumnWidth()

  const getColumnSize = (index: number, columnLength: number) => {
    if (!isAdditionalAddButtonColumnShown) {
      return columnWidth
    }
    return index === columnLength - 1 ? columnWidth + actionButtonWidth : columnWidth
  }

  useEffect(() => {
    const getSlidesToShow = () => {
      if (upLgXL) {
        return maxSlidesLength
      } else if (upLg) {
        return maxSlidesLength - 1
      } else if (upMd) {
        return maxSlidesLength - 2
      } else if (upTablet) {
        return maxSlidesLength - 3
      } else if (upSmall) {
        return 2
      } else {
        return 1
      }
    }

    setSlidesToShow(getSlidesToShow())
  }, [upLgXL, upLg, upMd, upTablet, upSmall])

  return {
    customPicked,
    slidesToShow,
    columnWidth,
    getColumnSize,
    isAddButtonIcon: isIcon
  }
}

export default useActivitiesSlidesToShow
