import React from 'react'

import { Grid, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  header: {
    margin: `${theme.spacing(1)} 0px`,
    paddingLeft: theme.spacing(2),
    width: '100%'
  },
  title: {
    fontSize: 14,
    color: '#A7A7A7',
    fontWeight: 600
  },
  dateColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4)
  }
}))

const NavItems = () => {
  const { classes } = useStyles()
  return (
    <Grid container className={classes.header} spacing={2}>
      <Grid item xs={2} className={classes.title}>
        Field
      </Grid>
      <Grid item xs={5} className={classes.title}>
        Value
      </Grid>
      <Grid item xs={3} classes={{ root: classes.title }}>
        <Box className={classes.dateColumn}>Last modified</Box>
      </Grid>
      <Grid item xs={2} className={classes.title}>
        Source
      </Grid>
    </Grid>
  )
}

export default NavItems
