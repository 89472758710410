import React, { HTMLAttributes } from 'react'

import { Box } from '@mui/material'

import { SuggestCompanyOptionType } from '_core/components/dialogs/CreateCompanyOptionDialog'
import { SuggestPersonOptionType } from '_core/components/dialogs/CreatePersonOptionDialog'
import { getStatusIcon } from '_core/components/introductions/ContactItem'
import ProfileItem from '_core/components/ProfileItem'
import { StatusLabel } from '_core/components/StatusChip'

export type PersonOption = { name: string; email: string }
export type CompanyOption = { name: string; website: string }

const getSuggestedType = (value: string): 'email' | 'website' | 'name' => {
  if (value.match(/.+@.+\..+/) || value.includes('@')) {
    return 'email'
  }

  if (value.match(/.+\..+/)) {
    return 'website'
  }

  return 'name'
}

export const transformPersonOption = (inputValue: string): SuggestPersonOptionType => {
  const type = getSuggestedType(inputValue)

  return {
    label: `Add "${inputValue}"`,
    name: type === 'name' ? inputValue : '',
    email: type === 'email' ? inputValue : ''
  }
}

export const transformCompanyOption = (inputValue: string): SuggestCompanyOptionType => {
  const type = getSuggestedType(inputValue)

  return {
    label: `Add "${inputValue}"`,
    name: type === 'name' ? inputValue : '',
    website: type === 'website' ? inputValue : ''
  }
}

export const renderPersonOption = (props: HTMLAttributes<HTMLLIElement>, value: PersonOption | { name: string; md5: string }) => {
  const email = 'email' in value ? value.email : ''
  return (
    <li {...props} key={'email' in value ? value.email : value.md5}>
      <Box ml={-2} mr={-2}>
        <ProfileItem name={value.name} userKey={email} byline={email} />
      </Box>
    </li>
  )
}

export type StatusOption = { label: string; value: string }
export const renderStatusOption = (props: HTMLAttributes<HTMLLIElement>, data: StatusOption | null) => {
  if (!data) {
    return null
  }

  const { value, label } = data
  const { icon } = getStatusIcon(value)

  return (
    <li {...props}>
      <StatusLabel label={label} icon={icon} />
    </li>
  )
}
