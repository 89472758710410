import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Empty from '_core/components/Empty'

import Paths from 'Paths'

const useStyles = makeStyles()(() => ({
  noAccessBlock: {
    maxWidth: 540,
    margin: '0 auto'
  },
  linkBtn: {
    whiteSpace: 'nowrap'
  }
}))

const NoAccess = () => {
  const { classes } = useStyles()
  return (
    <Empty
      className={classes.noAccessBlock}
      icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
      title="Mailbox is not accessible"
      subTitle={
        <>
          Please contact your IT administrator to ensure that your mailbox is enabled for access via Microsoft Graph or reach out to{' '}
          <Button<'a'> disablePadding href={`mailto:team@dotalign.com`} component="a" variant="link" bold={false}>
            team@dotalign.com
          </Button>{' '}
          to get further assistance.
          <br />
          <br />
          You can also set a different screen as your home page in{' '}
          <Button<typeof Link> disablePadding variant="link" bold={false} component={Link} to={Paths._settings} className={classes.linkBtn}>
            Settings &nbsp;
            <FontAwesomeIcon icon={['far', 'chevron-right']} style={{ fontSize: 10 }} />
            &nbsp; General
          </Button>
        </>
      }
    />
  )
}

export default NoAccess
