import { ChangeEvent, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import useCollaboratorsAccess, { Colleague, Team } from '_core/hooks/useCollaboratorsAccess'

import { post } from 'utils/httpUtils'

import Paths from 'Paths'

const useAccessDetailsForm = (request: IntroductionRequestResp) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const { teamAccesses, appUserAccesses, setTeamAccesses, setAppUserAccesses, initTeamsAccesses, initUserAccesses } = useCollaboratorsAccess(
    request?.appUserAccesses,
    request?.teamAccesses,
    fetching
  )
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const plan = request?.plan
  const chosenUsersEmails = appUserAccesses?.map((user) => user.email.toLowerCase())
  const chosenTeamNumbers = teamAccesses?.map((team) => team.teamNumber)

  const saveCollaborators = async () => {
    setFetching(true)
    const transformedUserAccesses =
      appUserAccesses?.map((user) => ({
        appUserKey: user.email,
        canRead: user.canRead,
        canWrite: user.canWrite
      })) || []

    const transformedTeamAccesses =
      teamAccesses?.map((team) => ({
        teamNumber: team.teamNumber,
        canRead: team.canRead,
        canWrite: team.canWrite
      })) || []

    const removedUserAccesses = initUserAccesses.current
      .filter((email) => !appUserAccesses?.find((user) => user.email === email))
      .map((email) => ({
        appUserKey: email,
        canRead: false,
        canWrite: false
      }))

    const removedTeamAccesses = initTeamsAccesses.current
      .filter((teamNumber) => !teamAccesses?.find((team) => team.teamNumber === teamNumber))
      .map((teamNumber) => ({
        teamNumber,
        canRead: false,
        canWrite: false
      }))

    await post<{ appUserAccesses: { [key: string]: any }[]; teamAccesses: { [key: string]: any }[] }>('/prospecting/planaccess', {
      planUid: plan.planUid,
      appUserAccesses: [...transformedUserAccesses, ...removedUserAccesses],
      teamAccesses: [...transformedTeamAccesses, ...removedTeamAccesses]
    })

    setFetching(false)
    enqueueSnackbar('The collaborators list has been updated')
    history.push(`${Paths._introductions}/${plan.planUid}`)
  }

  const handleUserPick = (event: ChangeEvent<{}>, value: Colleague[]) => setAppUserAccesses(value)

  const handleTeamPick = (event: ChangeEvent<{}>, value: Team[]) => setTeamAccesses(value)

  const removeUserAccess = async (email: string) => {
    setAppUserAccesses((prevState) => prevState?.filter((user) => user.email !== email))
  }

  const removeTeamAccess = async (teamNumber: number) => {
    setTeamAccesses((prevState) => prevState?.filter((team) => team.teamNumber !== teamNumber))
  }

  const handleSelectUserAccessLevel = (e: any, email: string) => {
    const value = e.target?.value

    if (appUserAccesses && value) {
      const updatedIndex = appUserAccesses.findIndex((person) => person.email === email)
      const updatedAccess = [...appUserAccesses]
      updatedAccess[updatedIndex] = {
        ...updatedAccess[updatedIndex],
        canRead: value === 'canRead' || value === 'canWrite',
        canWrite: value === 'canWrite'
      }

      setAppUserAccesses(updatedAccess)
    }
  }

  const handleSelectTeamAccessLevel = (e: any, teamNumber: number) => {
    const value = e.target?.value

    if (teamAccesses && value) {
      const updatedIndex = teamAccesses.findIndex((team) => team.teamNumber === teamNumber)
      const updatedAccess = [...teamAccesses]
      updatedAccess[updatedIndex] = {
        ...updatedAccess[updatedIndex],
        canRead: value === 'canRead' || value === 'canWrite',
        canWrite: value === 'canWrite'
      }

      setTeamAccesses(updatedAccess)
    }
  }

  return {
    appUserAccesses,
    teamAccesses,
    fetching,
    chosenUsersEmails,
    chosenTeamNumbers,
    handleUserPick,
    removeUserAccess,
    handleSelectUserAccessLevel,
    handleTeamPick,
    removeTeamAccess,
    handleSelectTeamAccessLevel,
    saveCollaborators
  }
}

export default useAccessDetailsForm
