import React from 'react'

import { Fab, FabProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  floatingButton: {
    position: 'fixed',
    bottom: '20px',
    right: '30px',
    zIndex: 10,
    '& > span': {
      marginBottom: '3px'
    }
  }
}))

const FloatingButton = ({ className, children, color = 'secondary', ...props }: FabProps) => {
  const { classes, cx } = useStyles()

  return (
    <Fab
      classes={{
        root: cx(classes.floatingButton, className)
      }}
      color={color}
      {...props}
    >
      {children}
    </Fab>
  )
}

export default FloatingButton
