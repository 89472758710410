export const addMissedProtocol = (url) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')

  if (!/^(?:f|ht)tps?:\/\//.test(newUrl)) {
    return `https://${url}`
  }
  return newUrl
}

export const getRidOfProtocol = (url) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')
  const result = (newUrl.match(/^(ht)tps?:\/\/(.*)/) || [])[2]
  return result
}

export const validUrl = (url) => {
  return url && /^[A-Z0-9.-]+\.[A-Z]{2,4}\/?$/i.test(url)
}

export const validEmail = (email) => {
  return email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export const getCharacterPosition = (str, ch, limit) => {
  return str?.split(ch, limit).join(ch)?.length
}
