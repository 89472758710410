import { useEffect, useMemo } from 'react'

import { IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'

import { SelectTagsActions, SelectTagsContent } from '_core/components/SelectTagsForm'

import { TaggableType } from '_core/hooks/useLookup'
import useTagsAssign from '_core/hooks/useTagsAssign'

const FilterTriggerEl = ({ handleClick, disabled }: { handleClick: () => void; disabled: boolean }) => {
  return <IconButton disablePR size="small" icon={['far', 'edit']} hint="Pick tags" onClick={handleClick} disabled={disabled} />
}

const SelectTagsDialog = ({
  appliedTags,
  taggableType,
  isOpened,
  manageTagsLink,
  close,
  submit
}: {
  appliedTags?: TagsSearchParam
  taggableType: keyof typeof TaggableType
  isOpened: boolean
  manageTagsLink: string
  close: () => void
  submit: (updatedTags: TagsSearchParam) => Promise<void | undefined>
}) => {
  const { items, initialTagsProvided, activeTags, totalActive, isReady, itemsLoading, clearSelected, toggleActive, setFilteredTags, reset, isDirty } =
    useTagsAssign({
      taggableType,
      loading: false,
      items: useMemo(() => appliedTags?.map(({ name, value }) => ({ categoryName: name, tagName: value })) || [], [appliedTags])
    })

  useEffect(() => {
    if (isOpened) {
      reset()
    }
  }, [isOpened, reset])

  const handleSubmit = () => {
    if (activeTags) {
      const transformedTags = activeTags.map(({ categoryName, tagName }) => ({ name: categoryName, value: tagName }))
      submit(transformedTags)
      close()
    }
  }

  return (
    <Dialog maxWidth={450} height={400} open={isOpened} onClose={close} title={<DialogTitle title="Select tags" />}>
      <DialogContent>
        <SelectTagsContent
          items={items}
          initialTagsProvided={initialTagsProvided}
          isReady={isReady}
          loading={itemsLoading}
          manageTagsLink={manageTagsLink}
          toggleActive={toggleActive}
          setFilteredTags={setFilteredTags}
          onManageTagsClick={close}
        />
      </DialogContent>
      <DialogActions>
        <SelectTagsActions
          clearSelected={clearSelected}
          disabledApply={!isDirty}
          handleSubmit={handleSubmit}
          totalActive={totalActive}
          handleCancel={close}
        />
      </DialogActions>
    </Dialog>
  )
}

SelectTagsDialog.FilterTriggerEl = FilterTriggerEl
export default SelectTagsDialog
