import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

interface INamePageProps {
  loading: boolean
  Aliases: any
  companyName: string
  setTotalAliases: any
}

const NamePage = ({ loading, Aliases, companyName, setTotalAliases }: INamePageProps) => {
  const { id } = useParams<{ id: string }>()

  useEffect(() => setTotalAliases(Aliases?.total_item_count), [Aliases, setTotalAliases])

  const submitInvalid = async (identity: string, value: number) => {
    return post(`/companies/disqualify?Identity=${identity}&Reason=${nameReason[value]}`)
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 6, loading }}
      addprops={{ style: { paddingLeft: 5 } }}
      items={Aliases?.data?.map((name: any) => ({
        title: '',
        description: (
          <IdentifierLabel
            name={companyName}
            identity={name.CoNameAlias}
            type="CompanyName"
            style={{ paddingBottom: 8 }}
            submit={submitInvalid}
            sourceLink={`${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`}
            auditLink={`${Paths._companies}/${id}/audit`}
          />
        ),
        blurb: name.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(name.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No aliases were found"
    />
  )
}

export default NamePage
