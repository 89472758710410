import { useContext, useEffect, useState, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import Empty from '_core/components/Empty'
import Filters, { Controller } from '_core/components/filters/Interactions'
import Heading from '_core/components/Heading'
import Interactions from '_core/components/Interactions'
import { Wide, Narrow, Column, Columns, useWide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Preview from '_core/components/Preview'
import SearchInput from '_core/components/SearchInput'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useInteractionsUserSettings from '_core/hooks/useInteractionsUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { checkOutlook } from '_core/helpers/outlook'
import UserSettings from '_core/UserSettings'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles<{ minHeight: number }>()((theme, { minHeight }) => ({
  page: {
    display: 'flex',
    flexFlow: 'column'
  },
  columns: {
    height: 'calc(100% - 54px)',
    '& > .MuiGrid-root': {
      height: '100%'
    }
  },
  search: {
    '& .MuiTextField-root': {
      width: '100% !important'
    }
  },
  previewColumn: {
    flex: 1,
    borderLeft: '1px solid #f2f2f2'
  },
  wrapper: {
    border: '1px solid #f2f2f2',
    minHeight: 'calc(100vh - 380px)',
    height: `calc(${minHeight}px - 54px)`
  },
  input: {
    maxWidth: 'calc(100% - 43px)',
    marginRight: theme.spacing(0.5)
  },
  noAccessBlock: {
    maxWidth: 540,
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    margin: '50% auto',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto'
    }
  }
}))

type ErrorResult = {
  results: {
    errorMessage: string
  }
}

type InteractionsType = Extract<IncludeInteractions, 'inbound' | 'outbound'>
const Component = ({ id, onLoading }: { id: string; onLoading: (value: boolean, result: ErrorResult) => void }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { setSubHeader } = useContext(LayoutContext)
  const wide = useWide()
  const { queryParams } = useSearchQuery<InteractionsPageParams, { modifyProps: [{ checked: InteractionsType[] }] }>(['checked'])

  const { checked = [] } = queryParams

  useEffect(() => {
    setSubHeader(`Interactions`)
  }, [])

  return (
    <DynamicEntity
      url={
        id
          ? `/interactions?TeamNumber=${teamContextValue.teamNumber}&personMd5=${id}&Inbound=${checked.includes(
              'inbound'
            )}&Outbound=${checked.includes('outbound')}`
          : null
      }
      list={true}
      infinite={true}
      forceNarrow={true}
      search={true}
      component={Interactions}
      onLoading={onLoading}
      empty="No results found"
      emptySubtitle="No interactions found"
      id="person_interactions"
      autoHideOnScroll
      {...(wide && { scrollableTarget: 'interactions_timeline' })}
    />
  )
}

const UserInteractions = ({ PersonMd5, minHeight }: { PersonMd5: string; minHeight: number }) => {
  const { classes, cx } = useStyles({ minHeight })
  const hasSidebar = isSidepanel() || checkOutlook()
  const wide = useWide()
  const { autoHideClassName } = useAutoHideOnScrollStyles(!wide)
  const { search } = useLocation()
  const [opened, setOpened] = useState(wide)
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const anchorRef = useRef<HTMLElement | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { isReady, setInitial, handleChange } = useInteractionsUserSettings()

  const toggle = () => {
    setOpened((prev) => !prev)
  }

  const onLoading = (loading: boolean, result: ErrorResult) => {
    setContentLoading(loading)
    const { errorMessage } = result?.results || {}
    if (errorMessage) {
      setErrorMessage(errorMessage)
    }
  }

  if (errorMessage)
    return (
      <Page variant="core" className={classes.page}>
        <Wide>
          <Heading title="Interactions" icon={['far', 'envelope']} variant="main" />
        </Wide>
        <Narrow>
          <Topbar nativeBack />
        </Narrow>
        <Empty
          className={classes.noAccessBlock}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      </Page>
    )

  return (
    <UserSettings setInitial={setInitial} endpoint={!search ? '/usersettings/interactionsfilter' : ''}>
      <Page variant="core">
        <Wide>
          <Heading title="Interactions" icon={['far', 'envelope']} variant="main" />
          <div className={classes.wrapper}>
            <SearchInput disabled={!isReady} placeholder="Search for interactions" variant="underlined" />
            <Columns spacing={0} className={classes.columns}>
              <Column md={3}>
                <Filters opened={opened} contentLoading={contentLoading} disabled={!isReady} handleChange={handleChange} />
              </Column>
              <Column md={5}>
                <Component id={PersonMd5} onLoading={onLoading} />
              </Column>
              <Column md={4} className={classes.previewColumn}>
                <Preview empty="Please click on an interaction to see its details." />
              </Column>
            </Columns>
          </div>
        </Wide>
        <Narrow>
          <Topbar nativeBack autoHideOnScroll />
          <Widget ref={anchorRef} className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
            <Box display="flex">
              <SearchInput disabled={!isReady} placeholder="Search for interactions" variant="collapsed" opened wrapperClassName={classes.input} />
              <Controller disabled={!isReady} opened={opened} toggleOpen={toggle} />
            </Box>
            <Filters opened={opened} contentLoading={contentLoading} disabled={!isReady} anchorEl={anchorRef.current} handleChange={handleChange} />
          </Widget>
          <Component id={PersonMd5} onLoading={onLoading} />
        </Narrow>
      </Page>
    </UserSettings>
  )
}

export default UserInteractions
