import { useRef, ReactElement, isValidElement, useEffect, useContext } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import useSidepanel from '_core/hooks/useSidepanel'

import { ReactComponent as PointIcon } from '_core/icons/point.svg'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles()(() => ({
  iframe: {
    border: 0
  },
  text: {
    opacity: 0.42,
    textAlign: 'center'
  }
}))

type PreviewType = {
  name?: 'preview'
  scrolling?: 'no' | 'yes'
  height?: string | number
  background?: string
  openedUrl?: string
  empty?: string | ReactElement
}

const Preview = (props: PreviewType) => {
  const { openedUrl } = useSidepanel('preview')
  const { setPreviewHistory } = useContext(LayoutContext)
  const { classes } = useStyles()
  const iframeRef = useRef<any>()

  useEffect(() => {
    setPreviewHistory([])
  }, [setPreviewHistory])

  if (window.top !== window.self) {
    return null
  }

  const { name = 'preview', scrolling = 'yes', background, height = '100%' } = props
  const isPreviewHidden: boolean = openedUrl === '/blank'
  const empty = props.empty !== undefined ? props.empty : 'Please click on a person or company to see their profile'

  const iframe = iframeRef?.current

  const handleLoading = () => {
    if (openedUrl && iframe && iframe.contentWindow) {
      // TODO: Layout useffect listener runs after we send message, find a better solution
      setTimeout(() => {
        iframe.contentWindow?.postMessage(`${name}:${openedUrl}`, iframe.src)
      }, 300)
    }
  }

  return (
    <>
      <iframe
        src="/blank"
        title="preview"
        name={name}
        ref={iframeRef}
        scrolling={scrolling}
        className={classes.iframe}
        onLoad={handleLoading}
        style={{ width: isPreviewHidden ? 0 : '100%', height: isPreviewHidden ? 0 : height, background: background || '#fff' }}
      />
      {isPreviewHidden && (
        <>
          {isValidElement(empty) && empty}
          {typeof empty === 'string' && (
            <Box textAlign="center" py="50%" px={2} width={1} height={1} boxSizing="border-box">
              <PointIcon />
              <Typography classes={{ root: classes.text }}>{empty}</Typography>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default Preview
