import { ChangeEvent } from 'react'

import Typography from '_shared/Typography'

import Filters from '_core/components/filters'

import useControlsStyles from './styles'

const IncludeControl = (props: {
  toggle: (e: ChangeEvent<{ name: unknown }>) => void
  disabled: boolean
  value: string[]
  options: { name: string; label: string }[]
  label?: string
  subLabel?: string
}) => {
  const { label, subLabel, toggle, disabled, value, options } = props
  const { classes } = useControlsStyles()

  return (
    <>
      {label && (
        <Typography variant="h4" semiBold classes={{ root: classes.header }}>
          {label}
        </Typography>
      )}
      {subLabel && (
        <Typography variant="body1" classes={{ root: classes.header }}>
          {subLabel}
        </Typography>
      )}
      {options.map((item) => (
        <Filters.Checkbox
          key={item.name}
          checked={!!value?.includes(item.name)}
          name={item.name}
          onChange={toggle}
          label={item.label}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default IncludeControl
