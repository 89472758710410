import { useMemo } from 'react'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

const useContactActivitiesPayloads = (personId: string, contributorId?: string) => {
  const { result: userProfileData } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { results: userProfileResults } = userProfileData || {}
  const userCompany = userProfileResults?.UserKey.split('@')[1]

  const payloads = useMemo(
    () =>
      userCompany
        ? {
            meetings: {
              pagingBy: 'FocusedPersonId',
              focusedRole: 'Any',
              focusPersonIds: [personId],
              ...(contributorId ? { person2Ids: [contributorId] } : {})
            },
            inbound: {
              pagingBy: 'FocusedPersonId',
              focusedRole: 'Sender',
              focusPersonIds: [personId],
              ...(contributorId ? { person2Ids: [contributorId] } : {})
            },
            outbound: {
              pagingBy: 'Person2Id',
              focusedRole: 'Sender',
              ...(contributorId ? { focusPersonIds: [contributorId] } : { focusCompanyIds: [userCompany] }),
              person2Ids: [personId]
            }
          }
        : null,
    [userCompany, personId, contributorId]
  )

  return payloads
}

export default useContactActivitiesPayloads
