export default function tranformDemoData(result: any) {

  let textResult = result
  const demo_keys = (window.localStorage.getItem('demo_keys') || '').split('\n').filter(entry => entry)
  const char = '×'

  if (!demo_keys || demo_keys.length === 0) {
    return textResult
  }

  textResult = textResult.replace(/"(PersonNameText|CompanyNameText|KnowerNameText|IntroducerName|FirstInboundMsgUserName|FirstInboundMsgContactName|FirstMeetingContactName|FirstMeetingUserName|FirstOutboundMsgContactName|FirstOutboundMsgUserName|LastInboundMsgContactName|LastInboundMsgUserName|LastMeetingContactName|LastMeetingUserName|LastOutboundMsgContactName|LastOutboundMsgUserName|UserName|BestKnowerNameText|AnchorEmployeeNameText|CorpLevelCompanyName|name|displayName|identifierText|highText|lowText|UserFullName|BestJobMatchedCompanyName|BestJobCorpLevelCompanyName)":"(.*?)"/igm, (substring: string, key: any, matched: any) => {
    const parts = matched.split(' ')
    if (!demo_keys.find((line: any) => matched.indexOf(line.split('=')[0]) > -1))
      return `"${key}":"${parts.map((p: any) => `${p[0]}${new Array(p.length).join(char)}`).join(' ')}"`
    return substring
  })

  textResult = textResult.replace(/"(BestEmailAddrText|AddressText|emailAddress|userKey)":"(.*?)@/igm, (substring: string, key: any, matched: any) => {
    if (!demo_keys.find((line: any) => matched.indexOf(line.split('=')[0]) > -1))
      return `"${key}":"${matched[0]}${new Array(matched.split('').length).join(char)}@`
    return substring
  })

  textResult = textResult.replace(/"(StandardizedPhoneNumber|BestPhoneText)":"(.*?)"/igm, (substring: string, key: any, matched: any) => {
    return `"${key}":"${matched.replace(matched.substr(2, 4), '5555')}"`
  })

  textResult = textResult.replace(/"(BestUrlText|CoUrl)":"(.*?)\.(.*?)"/igm, (substring: string, key: any, matched: any, matched2: any) => {
    if (!demo_keys.find((line: any) => matched.indexOf(line.split('=')[0]) > -1))
      return `"${key}":"${matched[0]}${new Array(matched.split('').length).join(char)}.${new Array(matched2.split('').length + 1).join(char)}"`
    return substring
  })

  demo_keys.forEach((line: any) => {
    const parts = line.split('=')
    textResult = textResult.replace(
      new RegExp(parts[0], 'gim'),
      parts[1]
    )
  })

  return textResult
}

export function uuid() {

  let dt = new Date().getTime()

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16)
  })

  return uuid
}