import React, { PropsWithChildren } from 'react'

import { TableContainer, Table, TableRow, TableCell, Paper, TableBody, TableHead } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const tableStyles = makeStyles()((theme) => ({
  tableRoot: {
    [theme.breakpoints.down('md')]: {
      '& tr:first-of-type th:first-of-type': {
        borderBottom: '1px solid #DADADA'
      },
      '& tbody td:first-of-type': {
        fontWeight: 'bold',
        '&:before': {
          fontWeight: 'normal'
        }
      }
    }
  },
  tableContainer: {
    border: '1px solid #DADADA'
  },
  tableHeader: {
    background: theme.palette.background.darker
  }
}))

const rowStyles = makeStyles()((theme) => ({
  columnHeaderRow: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  tableCellRoot: {
    borderBottom: '1px solid #DADADA',
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      '&&': {
        display: 'block',
        textAlign: 'right',
        paddingRight: theme.spacing(2),
        minHeight: '20px',
        '&:last-of-type': {
          borderBottom: '4px #ECEEF0 solid'
        },
        '&::before': {
          /*aria-label has no advantage, it won't be read inside a table*/
          content: 'attr(data-label)',
          float: 'left'
        }
      }
    }
  },
  row: {
    [theme.breakpoints.up('md')]: {
      '&:last-of-type > td, &:last-of-type > th': {
        borderBottom: 'none'
      }
    }
  }
}))

type DotTableDataItem = any

interface RowComponentProps {
  row: any
  columns: DotColumn[]
  CellComponent?: any
}

export const DotTable = (
  props: PropsWithChildren<{
    rowComponent: React.FC<RowComponentProps>
    headerComponent?: React.ReactNode
    data: DotTableDataItem[]
    columns: DotColumn[]
  }>
) => {
  const { classes } = tableStyles()
  const {
    classes: { tableCellRoot, columnHeaderRow }
  } = rowStyles()

  return (
    <>
      <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            {props.headerComponent && (
              <TableRow classes={{ root: classes.tableHeader }}>
                <TableCell classes={{ root: tableCellRoot }} colSpan={4}>
                  {props.headerComponent}
                </TableCell>
              </TableRow>
            )}
            <TableRow classes={{ root: columnHeaderRow }}>
              {props.columns.map((column) => (
                <Cell key={column.id} classes={{ root: tableCellRoot }} column={column}>
                  {column.label}
                </Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <props.rowComponent key={`${row.category}_${index}`} row={row} columns={props.columns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function ComponentProxy(props: PropsWithChildren<any>) {
  return <>{props.children}</>
}

export const DotHeader = (p: any) => ComponentProxy(p)

export const Cell = (props: PropsWithChildren<{ column: DotColumn; row: any } & any>) => {
  const CellComponent = props.column.component || TableCell

  return <CellComponent {...props}>{props.children}</CellComponent>
}
export const DotTableRow = ({ row, columns, CellComponent = Cell }: RowComponentProps) => {
  const { classes } = rowStyles()

  return (
    <TableRow classes={{ root: classes.row }}>
      {columns.map((column, index) => (
        <CellComponent key={`${column.id}_${index}`} column={column} classes={{ root: classes.tableCellRoot }} row={row} data-label={column.label}>
          {row[column.id]}
        </CellComponent>
      ))}
    </TableRow>
  )
}

export const RightAlignedTableCell = (props: PropsWithChildren<any>) => {
  return (
    <TableCell {...props} align="right" data-label={props.column.label}>
      {props.children}
    </TableCell>
  )
}

export interface DotColumn {
  id: string
  label: string
  component?: any
}
