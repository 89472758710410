import React from 'react'
import { withStyles } from 'tss-react/mui'
import { Container as MuiContainer } from '@mui/material'

const Container = withStyles(MuiContainer, (theme) => ({
  root: {
    padding: '30px',
    [theme.breakpoints.up('md')]: {
      padding: '30px 0'
    }
  }
}))

const UnauthenticatedPage = () => <Container maxWidth='lg'>Logging in...</Container>

export default UnauthenticatedPage