import React from 'react'

import { useRouteMatch } from 'react-router-dom'

import EditGroupPage from '_pages/teams/[id]/associations/group/[id]/edit'
import EditUserPage from '_pages/teams/[id]/associations/user/[id]/edit'

import Paths from 'Paths'

const AssociationsPage = () => {
  const matchEditGroup = useRouteMatch(`${Paths._teamProfile}/associations/group/:entityId/edit`)
  const matchEditPerson = useRouteMatch(`${Paths._teamProfile}/associations/user/:entityId/edit`)

  return (
    <>
      {matchEditGroup && <EditGroupPage />}
      {matchEditPerson && <EditUserPage />}
    </>
  )
}

export default AssociationsPage
