import { ChangeEvent } from 'react'

import { makeStyles } from 'tss-react/mui'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { post } from 'utils/httpUtils'

import Filters from './index'

const saveLatestChecked = (checked: string[]) => {
  post('/searchfilter', {
    entities: checked.map((c) => `${c[0].toUpperCase()}${c.slice(1)}`)
  })
}

type SearchFiltersType = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  anchorEl?: HTMLElement | null
}

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid #f2f2f2`
    }
  }
}))

const SearchFilters = ({ opened, contentLoading, disabled, anchorEl }: SearchFiltersType) => {
  const { classes } = useStyles()
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: Entities[] }] }>(['entities'])
  const { entities = [] } = queryParams
  const isChecked = (entity: Entities) => !!entities?.includes(entity)

  const toggleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: toggledEl } = e.target
    const checked = entities.includes(toggledEl as Entities)
      ? entities.filter((el: Entities) => el !== toggledEl)
      : [...entities, toggledEl as Entities]
    saveLatestChecked(checked)
    updateQuery({ entities: checked })
  }

  return (
    <Filters opened={opened} contentLoading={contentLoading} anchorEl={anchorEl} className={classes.container}>
      <>
        <Filters.Checkbox checked={isChecked('people')} onChange={toggleChecked} name="people" label="People" disabled={disabled} />
        <Filters.Checkbox checked={isChecked('companies')} onChange={toggleChecked} name="companies" label="Companies" disabled={disabled} />
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default SearchFilters
