import { sortMap, statsOptions } from '_core/components/sort'

const useSortStatsEndpointParams = (sort?: ScoreType | StatSortType) => {
  const sortBy = [
    {
      value: 'Stats',
      condition: sort && statsOptions.includes(sort as StatSortType)
    },
    { value: 'ScoreDesc', condition: sort && sort === 'ScoreDesc' },
    { value: 'ScoreAsc', condition: sort && sort === 'ScoreAsc' }
  ].find((sort) => sort.condition)?.value

  const statSortAsc = sort ? statsOptions.filter((opt) => opt.includes('Asc')).includes(sort as StatSortType) : []
  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const statsParams = [
    { name: 'StatSortOldestToNewest', value: statSortAsc.toString() },
    { name: 'StatSortType', value: sortByField }
  ]

  const params: { name: string; value?: string }[] = [
    { name: 'SortBy', value: sortBy || 'ScoreDesc' },
    ...(sortBy && sortBy === 'Stats' ? statsParams : [])
  ]

  return params
}

export default useSortStatsEndpointParams
