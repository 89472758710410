import { useState, ChangeEvent } from 'react'

import { Box, RadioGroup } from '@mui/material'

import Checkbox from '_shared/forms/Checkbox'
import Radio from '_shared/forms/Radio'

import Settings from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const LinkedInSettings = (props: { loading: boolean; items: { uploadOnBehalfOf: LinkedInUploadPermission } }) => {
  const [settingsData, setSettingsData] = useState<{
    uploadOnBehalfOf: LinkedInUploadPermission
  }>(props.items)

  const { isSaving, save: saveSettings } = useSaveSettings()

  const { uploadOnBehalfOf } = settingsData || {}

  const off = uploadOnBehalfOf && uploadOnBehalfOf === 'Off'

  const save = (action: SaveAction) => saveSettings('/adminsettings/linkedInUpload', settingsData, action, 'LinkedIn settings')

  const toggleAllow = () => {
    setSettingsData((prevState) => ({ ...prevState, uploadOnBehalfOf: prevState.uploadOnBehalfOf === 'Off' ? 'Admins' : 'Off' }))
  }

  const toggleLevel = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target
    setSettingsData((prevState) => ({ ...prevState, uploadOnBehalfOf: name as LinkedInUploadPermission }))
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      <Checkbox
        disabled={isSaving}
        checked={off === false}
        onChange={toggleAllow}
        name="onBehalfOf"
        label="Allow users to upload linked connection on behalf of others"
      />
      <Box marginLeft={4} display="flex" flexDirection="column">
        <RadioGroup onChange={toggleLevel}>
          <Radio
            disabled={isSaving || typeof off !== 'boolean' || off === true}
            checked={uploadOnBehalfOf === 'Admins'}
            name="Admins"
            label="Only admins can upload on behalf of others"
          />
          <Radio
            disabled={isSaving || typeof off !== 'boolean' || off === true}
            checked={uploadOnBehalfOf === 'Anyone'}
            name="Anyone"
            label="Any user can upload on behalf of others"
          />
        </RadioGroup>
      </Box>
    </Settings>
  )
}

export default LinkedInSettings
