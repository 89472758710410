import React, { SyntheticEvent, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'

import CreatePersonOptionDialog, {
  PersonOptionType,
  renderOption,
  SuggestPersonOptionType,
  useCreatePersonOption
} from '_core/components/dialogs/CreatePersonOptionDialog'
import Empty from '_core/components/Empty'
import { transformPersonOption } from '_core/components/introductions/options'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useDialog from '_core/hooks/useDialog'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

import { actionButtons } from 'AppTheme'

const useStyles = makeStyles<{ sidepanel: boolean }>()((theme, { sidepanel }) => ({
  actionStickyButtons: {
    ...actionButtons(theme, 'sticky'),
    zIndex: 1
  },
  infiniteLoader: {
    position: 'relative',
    bottom: 50
  },
  widget: {
    minHeight: 'calc(100vh - 137px)',
    [theme.breakpoints.up('sidepanel')]: {
      minHeight: `calc(100vh - ${sidepanel ? 145 : 117}px)`
    }
  },
  list: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`
  }
}))

const AddPrivilegedPeople = (props: {
  handleSave: (addedPeople: PersonOptionType[]) => void
  sidepanel: boolean
  loadOptions: (searchTerm: string, value?: PersonOptionType[] | null) => Promise<PersonOptionType[] | undefined>
  forceAbort: () => void
}) => {
  const [isSaving, setSaving] = useState(false)
  const {
    inputValue,
    value,
    open,
    options,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    optionsLoading,
    updateValue: updatePeople
  } = useAsyncCombobox<PersonOptionType, true>({
    initialValue: [],
    loadOptions: props.loadOptions,
    forceAbort: props.forceAbort
  })
  const people = value || []
  const peopleEmails = people.map((person) => person.email.toLowerCase())
  const filterOptions = (options: PersonOptionType[]) =>
    options.filter((option) => option.name && option.email && !peopleEmails.includes(option.email.toLowerCase()))
  const filterWithSuggest = useSuggestOptionCreation<PersonOptionType, SuggestPersonOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformPersonOption
  })
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)
  const { closeCreateOptionDialog, createdOption, setCreatedOption } = useCreatePersonOption(closeDialog)
  const { classes } = useStyles({ sidepanel: props.sidepanel })

  const handlePersonSelect = (e: SyntheticEvent<Element, Event>, people: PersonOptionType[] | SuggestPersonOptionType[]) => {
    const person = people[people.length - 1]
    if (person) {
      if ('label' in person) {
        openDialog()
        setCreatedOption({
          name: person.name,
          email: person.email
        })
      } else {
        handleValueChange(e, people)
        close()
      }
    }
  }

  const createPersonOptionSubmit = (createdOption: PersonOptionType) => {
    updatePeople([
      ...people,
      {
        name: createdOption.name,
        email: createdOption.email
      }
    ])
    closeCreateOptionDialog()
  }

  const removePerson = (email: string) => {
    updatePeople(people.filter((person) => person.email !== email))
  }

  const handleSave = () => {
    setSaving(true)
    props.handleSave(people)
  }

  const items = people.map((person) => {
    return {
      name: person.name,
      userKey: person.email,
      byline: person.email,
      icons: (
        <IconButton
          hint="Remove"
          icon={['far', 'times'] as IconProp}
          disabled={isSaving}
          onClick={() => removePerson(person.email)}
          size="small"
          disablePR
        />
      )
    }
  })

  return (
    <Narrow>
      <Widget className={classes.widget}>
        <Combobox<PersonOptionType, true, boolean>
          multiple
          autoFocus
          open={open}
          inputValue={inputValue}
          loading={optionsLoading}
          options={options}
          value={people}
          filterOptions={filterWithSuggest}
          placeholder="Add person"
          onChange={handlePersonSelect}
          onInputChange={handleInputChange}
          onClose={handleClose}
          onOpen={handleOpen}
          onFocus={handleFocus}
          getOptionLabel={(option) => option.name}
          renderOption={renderOption}
        />
        <Repeater
          direction="vertical"
          variant="list"
          component={ProfileItem}
          skeleton={{ size: 10, loading: false }}
          items={items}
          className={classes.list}
          empty={
            <Box mt={2}>
              <Empty subTitle="No people" icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />} />
            </Box>
          }
        />
      </Widget>
      <CreatePersonOptionDialog opened={isDialogOpened} close={closeCreateOptionDialog} submit={createPersonOptionSubmit} value={createdOption} />
      <Box className={classes.actionStickyButtons}>
        <Button variant="text" onClick={handleSave} disabled={!people.length || isSaving} disablePR>
          Save
        </Button>
      </Box>
    </Narrow>
  )
}

export default AddPrivilegedPeople
