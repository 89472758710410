import { useEffect, useState } from 'react'

import ReactGA from 'react-ga4'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'

import { apiHost } from 'config'

const usePageTracking = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    fetch(`${apiHost}/api/anon/general`)
      .then((resp: any) => resp.json())
      .then((resp: any) => {
        const config = (name: any) => ({ name, send_page_view: false, page_title: 'Hidden', page_path: '/hidden' })
        const measurements = [] //resp.allowDataCollection ? [{ trackingId: "G-0KN5SEGDQZ", gaOptions: config("falcon") }] : []

        if (!resp.customGaId) return

        measurements.push({ trackingId: resp.customGaId, gaOptions: config('falcon_tenant') })
        ReactGA.initialize(measurements, { legacyDimensionMetric: false })

        setInitialized(true)
      })
      .catch((err: any) => {
        console.log('/api/adminsettings/general API error', err)
      })
  }, [])

  useEffect(() => {
    if (initialized && location.pathname !== '/blank') {
      let page = location.pathname + location.search
      let title = window.document.title.split(' - ')[1] || ''

      const personProfile = matchPath(page, {
        path: ['/people/:id/*', '/people/:id']
      })

      const companyProfile = matchPath(page, {
        path: ['/companies/:id/*', '/companies/:id']
      })

      if (personProfile) {
        page = page.replace((personProfile.params as any).id, 'ID')
        title = 'Person Profile'
      }

      if (companyProfile) {
        page = page.replace((companyProfile.params as any).id, 'ID')
        title = 'Company Profile'
      }

      ReactGA.send({ hitType: 'pageview', page, title })
    }
  }, [initialized, location])
}

export default usePageTracking
