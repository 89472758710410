import { forwardRef, useRef, useEffect, ReactNode, CSSProperties, useCallback, UIEventHandler } from 'react'

import * as _ from 'lodash'

const useWindowScroll = <T,>(
  windowScroll = false,
  sendProps: T = {} as T,
  containerProps?: { containerStyle?: CSSProperties; onContainerScroll?: UIEventHandler<HTMLDivElement> }
) => {
  const ref = useRef<any>()
  const sizeMap = useRef<{ [key: string]: number }>({})
  const containerRef = useRef<HTMLDivElement>()

  const { containerStyle, onContainerScroll } = containerProps || {}

  const outerElementType = useCallback(
    forwardRef(
      (
        { onScroll, children }: { onScroll: (props: { [key: string]: { [key: string]: number } }) => void; children: ReactNode },
        reactWindowRef: any
      ) => {
        const refSetter = (ref: HTMLDivElement) => {
          reactWindowRef(ref)
          containerRef.current = ref
        }

        useEffect(() => {
          const handleWindowScroll = _.throttle(() => {
            if (!(onScroll instanceof Function)) {
              return
            }
            const { clientWidth, clientHeight, scrollLeft, scrollTop, scrollHeight, scrollWidth } = document.documentElement
            onScroll({
              currentTarget: {
                clientHeight,
                clientWidth,
                scrollLeft,
                scrollTop: scrollTop - (containerRef.current ? containerRef.current.getBoundingClientRect().top + scrollTop : 0),
                scrollHeight,
                scrollWidth
              }
            })
          }, 100)
          window.addEventListener('scroll', handleWindowScroll)
          return () => {
            window.removeEventListener('scroll', handleWindowScroll)
          }
        }, [onScroll])

        reactWindowRef.current = document.documentElement
        return (
          <div style={{ position: 'relative', ...containerStyle }} ref={refSetter} onScroll={onContainerScroll}>
            {children}
          </div>
        )
      }
    ),
    [containerStyle]
  )

  const setSize = (index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    ref.current.resetAfterIndex(index)
  }

  const getItemSize = (index: number) => sizeMap.current[index] || 50

  return {
    ref,
    itemData: {
      setSize,
      sizeMap,
      ...sendProps
    },
    itemSize: getItemSize,
    ...(windowScroll ? { outerElementType } : {})
  }
}

export default useWindowScroll
