import React from 'react'
import { AccountCircle } from '@mui/icons-material'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import Typography from '_shared/Typography'

import AvatarMenu, { useStyles, IOption } from './AvatarMenu'

const AccountMenu = (props: RouteComponentProps & { options: any[]; currentUser: any; profilePic: any; activeTab: string }) => {
  const { classes } = useStyles()

  const history = useHistory()
  const options: IOption[] = [
    props.currentUser && {
      id: 'username',
      label: (
        <Box>
          <Box>{props.currentUser.name}</Box>
          <Box position="relative" top={-4}>
            <Typography className={classes.text} variant="body1" color="text.secondary" title={props.currentUser.username}>
              {props.currentUser.username}
            </Typography>
          </Box>
        </Box>
      ),
      icon: () =>
        props.profilePic ? (
          <img src={props.profilePic} className={classes.pic} alt="Profile" />
        ) : (
          <AccountCircle color="secondary" className={classes.profile} />
        ),
      callback: () => {
        history.push('/people/' + props.currentUser.username)
      }
    },
    ...props.options
  ]

  return <AvatarMenu id="long-menu" options={options} profilePic={props.profilePic} activeTab={props.activeTab} />
}

export default withRouter(AccountMenu)
