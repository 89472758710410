import { IconButton, Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Typography from '_shared/Typography'

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => {
  return <IconButton onClick={open} icon={['far', 'undo']} style={{ fontSize: 16 }} disabled={disabled} />
}

const ResetDialog = ({ isOpened, close, undo }: { isOpened: boolean; close: () => void; undo: () => void }) => {
  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title="Reset changes" />}>
      <DialogContent>
        <Typography>Clicking yes will reset your changes. Proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          No
        </Button>
        <Button variant="text" onClick={undo} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ResetDialog.TriggerEl = TriggerEl

export default ResetDialog
