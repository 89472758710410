import { useMemo } from 'react'

import { useParams } from 'react-router'

import EditGroupAssociations from '_core/components/team/EditGroupAssociations'

import { apiBase, MatchAssociationsType } from '_core/hooks/useTeam'

import DynamicEntity from '_core/DynamicEntity'

const EditGroupPage = () => {
  const { id: teamId } = useParams<MatchAssociationsType>()

  const memoUrls = useMemo(
    () => [
      {
        key: 'groups',
        url: `${apiBase}/${teamId}/membershipCredentials?roleTypes=Member&roleTypes=Reader&roleTypes=Curator`,
        merge: true
      },
      {
        key: 'team',
        url: `${apiBase}/${teamId}`
      },
      {
        key: 'roles',
        url: '/teams/roles'
      }
    ],
    [teamId]
  )

  return <DynamicEntity urls={memoUrls} component={EditGroupAssociations} id="edit_group_associations" />
}

export default EditGroupPage
