import React, { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { useParams } from 'react-router-dom'

import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import InfiniteList from '_core/components/market-data/InfiniteList'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { CSuiteType } from '_core/hooks/useCSuite'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface CSuiteProps {
  website: string
  people: CSuiteType | null
  setPeople: (arg: CSuiteType | null) => void
  loading: boolean
  teamNumber: number
}

const CSuite = ({ website, people, setPeople, teamNumber, ...props }: CSuiteProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort } = useAbortableFetch<{ [key: string]: any }>()

  const load = props.loading || loading

  useEffect(() => {
    setSubHeader(`C Suite ${people?.total ? `· ${people.total}` : ''}`)
  }, [people?.total])

  const getPeople = async (loadMore?: boolean) => {
    try {
      setLoading(true)
      const res = await fetchWithAbort({
        url: mergeUrlWithParams(`/companies/${website}/cSuite`, {
          titlecase: 'true',
          teamNumber: teamNumber.toString(),
          ...(loadMore && people?.scroll_token && { scrollToken: people.scroll_token })
        })
      })
      if (res) {
        setPeople({
          id,
          website,
          data: loadMore && people?.data ? [...people.data, ...res.data] : res.data,
          scroll_token: res.scroll_token || '',
          total: res.total
        })
      }
    } catch (e: any) {
      setPeople({
        id,
        website,
        data: [],
        scroll_token: '',
        total: 0,
        error: e?.status === 500 ? 'No records were found' : e?.error
      })
      console.log('error during fetching CSuits', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (website && !people && !load && teamNumber) {
      getPeople()
    }
  }, [website, people, load, teamNumber])

  const loadMore = () => getPeople(true)

  const items = people?.data?.map((person: { [key: string]: any }) => {
    return {
      name: person.full_name,
      score: person.personMd5 ? person.score : undefined,
      link: person.personMd5
        ? `${Paths._people}/${person.personMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.full_name}`, {
            email: person.work_email,
            company: person.job_company_name
          }),
      byline: person.job_title || '',
      byline2: person.job_start_date ? `from ${moment(person.job_start_date).format('MM/YYYY')}` : '',
      sidepanel: true
    }
  })

  const hasMore = Boolean(people?.data?.length && people.total > people.data.length && people?.scroll_token)

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <InfiniteList loading={loading} next={loadMore} dataLength={people?.total || 0} hasMore={hasMore}>
          <Repeater
            virtualized
            direction="vertical"
            variant="list"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !people }}
            items={items || []}
            empty={<Empty title={people?.error || 'No records were found'} />}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default CSuite
