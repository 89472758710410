import { ComponentProps } from 'react'

import { useMediaQuery } from '@mui/material'

import DefaultProfileSummary from '_core/components/DefaultProfileSummary'
import MarketDataSummary from '_core/components/MarketDataSummary'
import MiniProfileSummary from '_core/components/MiniProfileSummary'
import Widget from '_core/components/Widget'

const ProfileSummary = ({
  enabledMarketData,
  introducers,
  ...profileProps
}: { enabledMarketData: boolean } & { introducers: ComponentProps<typeof MiniProfileSummary>['introducers'] | null } & ProfileSummaryProps) => {
  const minicard = useMediaQuery('(max-height:160px)')

  if (introducers && minicard) {
    return (
      <Widget scope="none">
        <MiniProfileSummary title={profileProps.title} score={profileProps.score} loading={profileProps.loading} introducers={introducers} />
      </Widget>
    )
  }

  return (
    <>
      {!enabledMarketData && <DefaultProfileSummary {...profileProps} />}
      {enabledMarketData && <MarketDataSummary {...profileProps} />}
    </>
  )
}

export default ProfileSummary
