import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

const Content = <Typography style={{ maxWidth: '220px' }}>You can add contributors and other members to this team by clicking here.</Typography>

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Add members',
    content: Content,
    target: '.addMembers-onboarding'
  }
]

export default steps
