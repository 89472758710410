import React from 'react'

import Box from '@mui/material/Box'
import { makeStyles } from 'tss-react/mui'

import ExpandableDetails, { ExpandableDetailsProps, ItemsType } from '_core/components/ExpandableDetails'
import ProfileLinkBox from '_core/components/ProfileLinkBox'

import { formatDateTime, formatDateTimeFromNow } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  requester: {
    paddingTop: theme.spacing(0.5)
  }
}))

type Person = {
  name: string
  email: string
  link: string
}

const ExpandableRequestDetails = (
  props: Omit<ExpandableDetailsProps, 'rows'> & {
    updated: string
    requester: Person
    introducer?: Person
    assignee?: Person
    closedOut?: string
  }
) => {
  const { classes } = useStyles()
  const { requester, closedOut, updated, assignee, introducer } = props

  const closedRow: ItemsType = [{ field: '', value: formatDateTime(closedOut), title: 'Closed', icon: ['far', 'calendar-check'] }]

  const items: ItemsType = [
    { field: 'byActivity', value: formatDateTimeFromNow(updated, 5), label: formatDateTime(updated), title: 'Updated', icon: ['far', 'calendar-clock'] },
    ...(closedOut ? closedRow : [])
  ]

  const contentStart = <ProfileLinkBox name={requester.name} userKey={requester.email} link={requester.link} className={classes.requester} />

  const Content = (
    <>
      <Box mt={0.5}>
        <ProfileLinkBox
          heading="Assignee"
          name={assignee?.name || ''}
          userKey={assignee?.email || ''}
          link={assignee?.link || ''}
          empty="No assignee"
        />
      </Box>
      <Box mt={0.5}>
        <ProfileLinkBox
          heading="Introducer"
          name={introducer?.name || ''}
          userKey={introducer?.email || ''}
          link={introducer?.link || ''}
          empty="No introducer"
        />
      </Box>
    </>
  )

  return (
    <ExpandableDetails
      items={items}
      content={Content}
      isExpandedView={props.isExpandedView}
      sortByField={props.sortByField}
      collapsedSize={28}
      contentStart={contentStart}
    />
  )
}

export default ExpandableRequestDetails
