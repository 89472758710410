import { TaggableType } from '_core/hooks/useLookup'

const transformTags = (url: string) => {
  const tags = url ? JSON.parse(url) : url
  if (tags?.length) {
    return tags.flatMap((tag: { name: string; value: string }) => `${tag.name}:${tag.value}`)
  }
}

const nameParams = {
  people: {
    include: 'HavingPersonTags',
    exclude: 'LackingPersonTags'
  },
  companies: {
    include: 'HavingCompanyTags',
    exclude: 'LackingCompanyTags'
  },
  meetings: {
    include: 'HavingMeetingTags',
    exclude: 'LackingMeetingTags'
  }
}

type Param = { name: string; value: string }

const useTagsEndpointParams = (type: keyof typeof TaggableType, includeTags?: string, excludeTags?: string) => {
  const inclTagsParams = includeTags && { name: nameParams[type].include, value: transformTags(includeTags) }
  const exclTagsParams = excludeTags && { name: nameParams[type].exclude, value: transformTags(excludeTags) }

  return [inclTagsParams, exclTagsParams].filter((item): item is Param => !!item)
}

export default useTagsEndpointParams
