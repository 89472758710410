import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import AssociationsEdit from '_core/components/team/AssociationsEdit'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import useTeam, { RenamedRole, MatchAssociationsType } from '_core/hooks/useTeam'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface IGroup {
  credentialType: string
  displayName: string
  id: string
  isAccessProhibited?: boolean
  lastSyncDateTime: string
  roleType: Role
}

interface IEditGroupProps {
  loading: boolean
  data: IGroup[]
  roles: Role[]
  team: TeamDataRes
}

const EditGroupAssociations = ({ data: groups, roles, loading, team }: IEditGroupProps) => {
  const params = useParams<MatchAssociationsType>()
  const { id: teamId, entityId: groupId } = params
  const [load, setLoad] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const currentGroup = groups?.filter((el: IGroup) => el.id === groupId)[0]
  const [currentAccess, setCurrentAccess] = useState<boolean>(false)
  const [currentRole, setCurrentRole] = useState<RenamedRole>()
  const { updateParent } = useSidepanelPayloads()
  const { setMobileHeader } = React.useContext(LayoutContext)
  const { editGroupRole, renameContributorRole, renameMemberRole } = useTeam()

  useEffect(() => {
    setMobileHeader('Edit group associations')
  }, [setMobileHeader])

  useEffect(() => {
    if (currentGroup?.roleType && currentGroup?.isAccessProhibited !== undefined) {
      setCurrentRole(renameMemberRole(currentGroup.roleType))
      setCurrentAccess(!currentGroup.isAccessProhibited)
    }
  }, [currentGroup?.roleType, currentGroup?.isAccessProhibited])

  const handleRole = (role: RenamedRole) => {
    setCurrentRole(role)
  }

  const handleAccess = (checked: boolean) => {
    setCurrentAccess(checked)
  }

  const save = () => {
    if (currentRole) {
      setLoad(true)
      editGroupRole(teamId, groupId, renameContributorRole(currentRole), !currentAccess)
        .then(() => {
          updateParent({ action: 'RELOAD_LIST', value: 'associations' })
          setLoad(false)
          setSuccess(true)
        })
        .catch((error) => {
          setLoad(false)
          console.log(error)
        })
    }
  }

  return (
    <AssociationsEdit
      name={currentGroup?.displayName}
      load={load}
      loading={loading}
      success={success}
      teamName={team?.name}
      teamId={teamId}
      handleRole={handleRole}
      currentRole={currentRole}
      currentAccess={currentAccess}
      handleAccess={handleAccess}
      roles={roles}
      save={save}
    />
  )
}

export default EditGroupAssociations
