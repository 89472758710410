import React from 'react'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import CompanyData from '_core/components/market-data/CompanyData'
import ReportIncorrectData from '_core/components/market-data/Report'
import Topbar from '_core/components/Topbar'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

interface CompanyMarketDataProps {
  error: string
  company: { [key: string]: any } | null
}

const CompanyMarketData = ({ error, company }: CompanyMarketDataProps) => (
  <>
    <Topbar nativeBack />
    {!error && (
      <>
        <Widget>
          <UnknownProfileSummary title={company?.name} userKey={company?.website} url={company?.website} loading={!company?.name} />
        </Widget>
        <Widget>
          <Heading underlined title="Details" icon={['fas', 'chart-column']} count={0} />
          <CompanyData
            id={company?.id}
            name={company?.name}
            location={company?.location}
            founded={company?.founded}
            website={company?.website}
            employeeCount={company?.employee_count}
            facebookUrl={company?.facebook_url}
            twitterUrl={company?.twitter_url}
            linkedinUrl={company?.linkedin_url}
            industry={company?.industry}
            type={company?.type}
            tags={company?.tags}
            summary={company?.summary}
          />
        </Widget>
      </>
    )}
    {error && <Empty title={error} />}
    <ReportIncorrectData marketData={JSON.stringify(company)} />
  </>
)

export default CompanyMarketData
