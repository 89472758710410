import React from 'react'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Typography from '_shared/Typography'

type PropsTypes = {
  selected: {
    id: string
    name: string
  }
  openedName: string
  handleClose: () => void
  handleSubmit: (id: string) => void
  message?: string
}

const UnsavedChangesWarning = (props: PropsTypes) => {
  const { selected, handleClose, handleSubmit, openedName, message } = props

  const submit = () => {
    handleSubmit(selected.id)
  }

  return (
    <Dialog
      open={!!selected?.id}
      onClose={handleClose}
      title={<DialogTitle title="You have unsaved changes" titleIcon={['fas', 'exclamation-triangle']} titleIconFont={16} />}
    >
      <DialogContent>
        <Typography>
          <span
            dangerouslySetInnerHTML={{
              __html:
                message ||
                `Are you sure you want to open <strong>${selected.name}</strong> without saving changes for <strong>${openedName}</strong>?`
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose} color="secondary">
          No
        </Button>
        <Button variant="text" onClick={submit} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnsavedChangesWarning
