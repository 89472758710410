import config from '../config'
import { getToken } from '../Auth'
import tranformDemoData from './demoUtils'

export const defaultSettings = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

export class InvalidTokenError extends Error {}

export async function request<T>(input: RequestInfo, init?: RequestInit, binary?: boolean): Promise<T | undefined> {
  let transformedInput: any
  const settings = await config()
  const demo = window.localStorage.getItem('demo_enabled') === 'true'

  if (typeof input === 'string') {
    if (input.indexOf('http') === 0) {
      transformedInput = input
    } else {
      transformedInput = `${settings?.apiPrefix}${input}`
    }
  } else {
    transformedInput = input
  }
  const options = (token: string) => {
    const result = {
      ...defaultSettings,
      ...init
    }

    result.headers = {
      ...result.headers,
      Authorization: `Bearer ${token}`
    }

    return result
  }

  try {
    const resp = await getToken()

    const response = await fetch(transformedInput, options(resp.accessToken))

    if (!binary) {
      const text = await response.text()
      let textResult = text
      if (demo) {
        textResult = tranformDemoData(textResult)
      }

      if (response.ok) {
        try {
          const jsonData = JSON.parse(textResult) as T
          return jsonData
        } catch {
          return
        }
      } else {
        const errorObj = {
          error: textResult && response.status === 404 ? JSON.parse(textResult)?.error?.message || response?.statusText : response?.statusText,
          status: response.status
        }
        throw errorObj
      }
    } else {
      const blob = await response.blob()
      if (response.ok) {
        return blob as unknown as T
      } else {
        const errorObj = {
          error: response.statusText,
          status: response.status
        }
        throw errorObj
      }
    }
  } catch (err: any) {
    if (err.status === 503) {
      window.location.href = '/maintenance.html'
    }

    if (err.error && err.error.name !== 'AbortError' && !(err instanceof InvalidTokenError)) {
      throw err
    }
  }
}
