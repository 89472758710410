import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardContent, Box, Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from 'tss-react/mui'

import { Step } from '_core/context/Onboarding'

import { Button, IconButton } from '_shared/buttons'
import Card from '_shared/Card'
import Typography from '_shared/Typography'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  card: {
    marginBottom: theme.spacing(1)
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiTypography-root': {
      lineHeight: 1.2,
      opacity: 0.87
    }
  },
  icon: {
    paddingRight: theme.spacing(2),
    '& > svg': {
      color: theme.palette.warning.main
    }
  },
  content: {
    maxWidth: 220,
    [theme.breakpoints.up('phone')]: {
      maxWidth: 300
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 340
    }
  }
}))

const Info = () => {
  const { classes } = useStyles()

  return (
    <Card variant="outlined" round className={classes.card}>
      <CardContent classes={{ root: classes.info }}>
        <Box className={classes.icon}>
          <FontAwesomeIcon icon={['fas', 'lock']} />
        </Box>
        <Typography semiBold>You can view only those events that you are a part of</Typography>
      </CardContent>
    </Card>
  )
}

const Content = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.content}>
      <Info />
      <Typography>
        Using the events page, you can easily see the people and companies you&apos;re going to meet today, or any day, and take quick actions to help
        make you more productive.
        <br />
        <br />
        You can also change your start page by going{' '}
        <Button<typeof Link> variant="link" disablePadding component={Link} target="_blank" to={Paths._settings}>
          Settings &nbsp;
          <FontAwesomeIcon icon={['far', 'chevron-right']} style={{ fontSize: 11 }} />
          &nbsp; General
        </Button>
        .
      </Typography>
    </Box>
  )
}

const ChipStyled = withStyles(Chip, (theme) => ({
  root: {
    lineHeight: '18px',
    color: theme.palette.warning.main,
    fontWeight: 600,
    marginRight: theme.spacing(2)
  }
}))

const HeadingActions = (
  <Box display="flex" alignItems="center" justifyContent="flex-end">
    <ChipStyled variant="outlined" label="new" size="small" />
    <IconButton
      disablePadding
      component="a"
      href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
      target="_blank"
      size="small"
      icon={['far', 'question-circle']}
      hint="Learn more about events and interactions"
    />
  </Box>
)

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Events',
    content: <Content />,
    headingActions: HeadingActions,
    target: '.events-onboarding'
  }
]

export default steps
