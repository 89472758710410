import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import PersonMarketData from '_core/components/market-data/PersonMarketData'
import Topbar from '_core/components/Topbar'

import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type PageProps = {
  teamNumber: number
  getPersonDetails: (name: string, email?: string) => { [key: string]: any } | undefined
}

const CSuitePage = ({ getPersonDetails, teamNumber }: PageProps) => {
  const { cSuiteId: name } = useParams<{ id: string; cSuiteId: string }>()
  const { queryParams } = useSearchQuery<any>()
  const { email, company } = queryParams
  const { marketData, setMarketData, getMarketData, error } = usePersonMarketData(name, email, company)
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Details')
  }, [])

  useEffect(() => {
    if (!marketData && name && (email || company)) {
      const details = getPersonDetails(name, email)
      if (details) {
        setMarketData(details)
      } else {
        getMarketData(teamNumber)
      }
    }
  }, [name, marketData, email, company, teamNumber])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <PersonMarketData error={error} person={marketData} urlType="companies" />
      </Narrow>
    </Page>
  )
}

export default CSuitePage
