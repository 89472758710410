import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

type RemoveTeamGroupDialogProps = {
  isOpen: boolean
  loading: boolean
  group: any
  close: () => void
  remove: () => void
  team: Team
}

const RemoveTeamGroupDialog = (props: RemoveTeamGroupDialogProps) => {
  const { isOpen, close, remove, team, group, loading } = props

  if (!group || !team) return null

  return (
    <Dialog open={isOpen} onClose={close} loading={loading} title={<DialogTitle title="Remove group and contributors?" />}>
      <DialogContent>
        <Typography>
          Do you want to remove contributors of the group <b>{group.displayName}</b> from team <b>{team.name}</b>? Note that users who are a part of
          other groups in this team will not be removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={remove} disablePR disabled={loading}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveTeamGroupDialog
