import { ReactNode, createContext, useEffect, useMemo } from 'react'

import { matchPath, useLocation } from 'react-router-dom'

import { ActivitiesAccessData } from '_core/hooks/useActivitiesAccess'
import useAdmin from '_core/hooks/useAdmin'
import { IntroductionAccessData } from '_core/hooks/useIntroductionsAccess'
import { ConfigType, usePrivateFeaturesData } from '_core/hooks/usePrivateFeatures'
import { useDataVisibility } from '_core/hooks/useUserDataVisibility'

import Paths from 'Paths'

type ShownMenuItems = 'events' | 'activities' | 'apiConsole' | 'introductions'

type ContextType = {
  hiddenNavbarItemsLoading: boolean
  updateDataVisibility: (data: UserDataVisibilityParams | undefined) => void
  updateUserActivitiesAccess: (activitiesAccessData: ActivitiesAccessData) => void
  setPrivateFeatures: (data: ConfigType | undefined) => void
  updateUserIntroductionsAccess: (introductionAccessData: IntroductionAccessData) => void
  hiddenNavbarItems: ShownMenuItems[]
}

export const NavBarMenuContext = createContext({} as ContextType)

const controlledFeaturesPaths = [
  Paths._adminSettings,
  Paths._introductions,
  Paths._activities,
  `${Paths._people}/:id`,
  `${Paths._companies}/:id`,
  Paths._relationships
]
const dataVisibilityPaths = [Paths._people, Paths._companies, Paths._events]

const NavBarMenuProvider = (props: { children: ReactNode }) => {
  const { pathname } = useLocation()
  const { dataVisibility, dataVisibilityLoading, updateDataVisibility, checkDataVisibility } = useDataVisibility()
  const {
    loading: privateFeaturesLoading,
    introductionsEnabled,
    activitiesStreamEnabled,
    isUserIntroductionsVisible,
    userActivitiesAccess,
    updateUserActivitiesAccess,
    updateUserIntroductionsAccess,
    setPrivateFeatures,
    activitiesAccessResult,
    introductionsAccessResult,
    checkPrivateFeatures
  } = usePrivateFeaturesData()

  useEffect(() => {
    ;(async () => {
      const matchControlledFeaturesPaths = matchPath(pathname, { path: controlledFeaturesPaths })
      if (!matchControlledFeaturesPaths) {
        checkPrivateFeatures()
      }

      const matchDataVisibilityPaths = matchPath(pathname, { path: dataVisibilityPaths })
      if (!matchDataVisibilityPaths) {
        updateDataVisibility(await checkDataVisibility())
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!privateFeaturesLoading && typeof activitiesStreamEnabled === 'boolean' && typeof introductionsEnabled === 'boolean') {
      setPrivateFeatures({ activityStream: activitiesStreamEnabled, introductions: introductionsEnabled })
      if (activitiesAccessResult) {
        updateUserActivitiesAccess(activitiesAccessResult)
      }
      if (introductionsAccessResult) {
        updateUserIntroductionsAccess(introductionsAccessResult)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateFeaturesLoading])

  const admin = useAdmin()
  const { showEventsTab, restrictAPIConsoleAccess } = dataVisibility || {}
  const { isActivityTabVisible } = userActivitiesAccess || {}

  const menuItemsToHide: { itemId: ShownMenuItems; condition: boolean }[] = [
    { itemId: 'activities', condition: !activitiesStreamEnabled || !isActivityTabVisible },
    { itemId: 'events', condition: !showEventsTab },
    { itemId: 'apiConsole', condition: restrictAPIConsoleAccess === true && !admin },
    { itemId: 'introductions', condition: !introductionsEnabled || !isUserIntroductionsVisible }
  ]

  const loading = dataVisibilityLoading || privateFeaturesLoading || typeof admin === 'undefined'
  const hiddenNavbarItems = menuItemsToHide.filter(({ condition }) => !!condition).map(({ itemId }) => itemId)

  const ctx = useMemo(
    () => ({
      hiddenNavbarItemsLoading: loading,
      hiddenNavbarItems,
      updateDataVisibility,
      updateUserActivitiesAccess,
      updateUserIntroductionsAccess,
      setPrivateFeatures
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hiddenNavbarItems, loading]
  )

  return <NavBarMenuContext.Provider value={ctx}>{props.children}</NavBarMenuContext.Provider>
}

export default NavBarMenuProvider
