import { Link } from 'react-router-dom'

import Typography from '_shared/Typography'

import Paths from 'Paths'

const ManualEditSuccessMessage = () => {
  return (
    <>
      <Typography>Thank you!</Typography>
      <Typography style={{ paddingTop: '16px' }}>Your changes will be incorporated after the next analytics run.</Typography>
      <Typography style={{ paddingTop: '16px' }}>
        You can view edits on
        <Link to={Paths._manualEdits} style={{ color: '#2E9CBD', textDecoration: 'underline', padding: '0 8px', whiteSpace: 'nowrap' }}>
          Manual Edits
        </Link>
        page.
      </Typography>
    </>
  )
}

export default ManualEditSuccessMessage
