import React, { isValidElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

const styles = makeStyles()((theme) => ({
  byline: {
    color: 'rgba(0,0,0,0.6)',
    lineHeight: 1.1,
    fontSize: 13
  },
  link: {
    color: theme.palette.primary.main,
    lineHeight: 1.1,
    fontSize: 13
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(8)
  },
  container: {
    marginLeft: theme.spacing(2),
    '& > p': {
      fontSize: 13,
      lineHeight: 1.1
    },
    '& > div': {
      paddingTop: 0
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: 'calc(100% - 52px - 16px)'
  },
  name: {
    fontSize: 17,
    marginBottom: 0,
    lineHeight: 1.1
  },
  network: {
    fontSize: 13,
    lineHeight: 1.2,
    color: 'rgba(0,0,0,0.8)',
    display: 'block',
    maxHeight: theme.spacing(6),
    overflow: 'hidden',
    boxSizing: 'border-box'
  }
}))

const MiniProfileSummary = ({
  score,
  title,
  byline,
  byline2,
  loading,
  introducers,
  companyContacts,
  url
}: Pick<ProfileSummaryProps, 'score' | 'title' | 'byline' | 'byline2' | 'loading' | 'url'> & {
  introducers: PersonType['Introducers'] | CompanyType['Introducers'] | PersonType['Introducers'] | CompanyType['Introducers']
  companyContacts?: string
}) => {
  const { classes } = styles()
  const introsTotal = introducers?.total_item_count || 0

  const intros = introducers?.data ? [...introducers.data].slice(0, 2) : []
  const introLast = introsTotal <= 2 ? intros.pop() : null
  const introAnd = introLast ? introLast.IntroducerName : `${introsTotal - 2} other colleague${introsTotal - 2 !== 1 ? 's' : ''}`

  return (
    <Box>
      <Box className={classes.wrapper}>
        <AvatarWrapper name={loading ? '' : title} score={score} size="md" mode="dark" logoUrl={url} userKey="" hideName />
        <Box className={classes.container}>
          {(loading || title) && (
            <Skeleton condition={!title} width="100%">
              <Typography variant="h4" className={classes.name}>
                {title || 'Placeholder Name'}
              </Typography>
            </Skeleton>
          )}

          {(loading || byline) && (
            <Skeleton condition={loading} width="80%" height={20}>
              {isValidElement(byline) && byline}
              {typeof byline === 'string' && (
                <Typography variant="body1" className={classes.byline}>
                  {byline}
                </Typography>
              )}
            </Skeleton>
          )}

          {(loading || byline2) && (
            <Skeleton condition={loading} width="70%" height={20}>
              {byline2}
            </Skeleton>
          )}
        </Box>
      </Box>

      <Box px={2} pt={0.5} pb={2} mx={-1} minHeight={60}>
        <Typography variant="body1" className={classes.network}>
          <FontAwesomeIcon icon={['far', 'project-diagram']} size="sm" color="#1684A7" style={{ marginRight: 5 }} />
          {intros.map((intro) => intro.IntroducerName).join(', ')}&nbsp;
          {introsTotal > 1 ? 'and ' : ''}
          {introAnd} know{introsTotal === 1 ? 's' : ''}&nbsp;
          {companyContacts}
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export default MiniProfileSummary
