import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { SidepanelNarrow, SidepanelWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfilePreview from '_core/components/ProfilePreview'
import { ActionButtons, Header, Login, NavItems, Row, SelectAccount } from '_core/components/salesforce'

import { FailedSaleforceLoginUserInfo, useSalesforce, useSalesforceUserInfo, SalesforceFormData } from '_core/hooks/useSalesforce'

import TabSet from 'SharedComponents/TabSet'

const Preview = (props: { loading: boolean } & Partial<Pick<SalesforceAccountType, 'owner'>>) => {
  const { watch } = useFormContext()
  const data = props.loading ? {} : watch()

  const { Name, Website } = Object.keys(data).reduce(
    (acc, field: keyof typeof data) => {
      const { selectedSource, selectedValue } = data?.[field] || {}
      const { value } = selectedValue[selectedSource] || {}
      acc[field] = value
      return acc
    },
    {} as { [key in keyof typeof data]: SalesforceData['value'] }
  )

  return <ProfilePreview {...props} {...{ Name }} Websites={[Website]} />
}

const Component = ({ loading, data }: { loading: boolean; data?: SalesforceAccountDataType[number] }) => {
  const { params } = useRouteMatch<SalesforceMatchParams>()
  const { account, properties = [] } = data || {}
  const { url: sourceUrl = '', owner, id } = account || {}
  const { rows, methods, submit, isSaving } = useSalesforce(properties)
  const [tabIndex, setTabIndex] = useState(0)
  const { userInfoLoading, loggedInUserData } = useSalesforceUserInfo(loading)

  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue)
  }

  const handleSubmit = (data: SalesforceFormData) => {
    submit(id, data, params.entity)
  }

  const { current: memoRows } = useRef(rows)
  const tabs = useMemo(
    () => [
      {
        label: 'ACCOUNT',
        component: () => (
          <Box minHeight="calc(100vh - 160px)" marginTop={-1}>
            <Repeater direction="vertical" variant="border" disablePadding component={Row} skeleton={{ size: 5, loading }} items={memoRows} />
          </Box>
        )
      },
      {
        label: 'PREVIEW',
        component: () => <Preview {...{ loading, owner }} />
      }
    ],
    [loading, memoRows, owner]
  )

  return (
    <FormProvider {...methods}>
      <Header loading={loading || userInfoLoading} sourceUrl={sourceUrl} loggedInUserData={loggedInUserData} />
      <SidepanelWide>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container>
            <Grid item xs={9} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 168px)' }}>
              <NavItems />
              <Box paddingLeft={2}>
                <Repeater direction="vertical" variant="border" disablePadding component={Row} skeleton={{ size: 5, loading }} items={rows} />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Preview {...{ loading, owner }} />
            </Grid>
          </Grid>
          <ActionButtons loading={loading} isSaving={isSaving} />
        </form>
      </SidepanelWide>
      <SidepanelNarrow>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Box minHeight={{ xs: 'calc(100vh - 152px)', sidepanel: 'calc(100vh - 132px)' }}>
            <TabSet tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={handleTabChange} disabled={loading} />
          </Box>
          <ActionButtons loading={loading} isSaving={isSaving} />
        </form>
      </SidepanelNarrow>
    </FormProvider>
  )
}

const Account = (props: { loading: boolean; salesforceData: SalesforceAccountDataType | FailedSaleforceLoginUserInfo }) => {
  const { params } = useRouteMatch<SalesforceMatchParams>()
  const history = useHistory()
  const { pathname } = useLocation()
  const { salesforceData = [] } = !props.loading ? props : {}
  const { login } = useSalesforceUserInfo(props.loading)

  const allowHistoryPush = Array.isArray(salesforceData) && salesforceData.length === 1
  useEffect(() => {
    if (!params.index && allowHistoryPush) {
      history.replace(`${pathname}/0`)
    }
  }, [params.id, params.index, history, allowHistoryPush, pathname])

  if ('errorCode' in salesforceData) {
    return (
      <Box height="80vh">
        <Login handleLogin={() => login(pathname)} />
      </Box>
    )
  }

  const accountsArr = salesforceData.map(({ properties }) => {
    const result = properties.reduce(
      (acc, { values, id }) => {
        acc[id] = values.find(({ source }: { source: string }) => source === 'Salesforce')?.data[0].value
        return acc
      },
      {} as { [key in SalesforceAccountProperty['id']]: SalesforceData['value'] | undefined }
    )
    return result
  })

  const selectAccount = (index: number) => {
    history.push(`${pathname}/${index}`)
  }

  return (
    <>
      {props.loading && <Component loading={props.loading} />}
      {!props.loading && params.index && <Component loading={props.loading} data={salesforceData[+params.index]} />}
      {!props.loading && !params.index && <SelectAccount setIndex={selectAccount} list={accountsArr} />}
    </>
  )
}

export default Account
