import React from 'react'

import Picker, { PickerProps, PickerButton } from '_core/components/Picker'

import { entityLabel } from '_core/data/entityLabel'

const usePicker = (props: PickerProps) => {
  const { entity, onSearchClick, unsaved, name } = props
  const [keyword, setKeyword] = React.useState<string>()
  const [selected, setSelectedId] = React.useState<any>(null)

  const handleKeyword = (e: any) => {
    setKeyword(e.target.value)
  }

  const handleSelect = (md5: string) => {
    setSelectedId(md5)
  }

  const clear = () => {
    setSelectedId(null)
    setKeyword('')
  }

  return {
    keyword,
    selected,
    clear,
    Picker: <Picker {...props} {...{ entityLabel: entityLabel[entity], handleKeyword, keyword }} {...(props.getLink || { handleSelect })} />,
    PickerButton: <PickerButton {...{ entity, onSearchClick, unsaved, name }} />
  }
}

export default usePicker
