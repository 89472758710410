import { ExpandableContent, ExpandableDetailsProps, ItemsType } from '_core/components/ExpandableDetails'

import { formatDateTimeFromNow } from 'utils/Utils'

const StatsContent = ({
  isExpandedView,
  sortByField,
  lastInbound,
  lastOutbound,
  lastMeeting,
  nextFutureMeeting
}: {
  lastInbound: string
  lastOutbound: string
  lastMeeting: string
  nextFutureMeeting: string
} & Pick<ExpandableDetailsProps, 'sortByField' | 'isExpandedView'>) => {
  const formatDate = (value: string) => formatDateTimeFromNow(value, 5)

  const items: ItemsType = [
    { field: 'lastInboundMsg', value: formatDate(lastInbound), title: 'Last inbound', icon: ['fas', 'message-arrow-down'] },
    { field: 'lastOutboundMsg', value: formatDate(lastOutbound), title: 'Last outbound', icon: ['fas', 'message-arrow-up'] },
    { field: 'lastMeeting', value: formatDate(lastMeeting), title: 'Last meeting', icon: ['far', 'calendar-alt'] },
    { field: 'nextFutureMeeting', value: formatDate(nextFutureMeeting), title: 'Next meeting', icon: ['far', 'calendar-alt'] }
  ]

  return <ExpandableContent items={items} isExpandedView={isExpandedView} sortByField={sortByField} />
}

export default StatsContent
