import { useEffect } from 'react'

import { logout } from 'Auth'

const Logout = () => {
  useEffect(() => {
    for (const key in window.localStorage) {
      if (key.includes('msal')) window.localStorage.removeItem(key)
    }
    logout()
  }, [])

  return <></>
}

export default Logout
