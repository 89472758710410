import { useState, ChangeEvent } from 'react'

import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const EmailSettings = (props: any) => {
  const {
    classes: { field, itemWrapper },
    cx
  } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })
  const { isSaving, save: saveSettings } = useSaveSettings()

  const [settingsData, setSettingsData] = useState<any>(props.items)

  const handleAlertsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      teamMembershipAlerts: e.target.checked
    })
  }

  const handleDigestChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      digestEmail: e.target.checked
    })
  }

  const handleDigestFrequencyChange = (e: SelectChangeEvent) => {
    setSettingsData({
      ...settingsData,
      digestFrequency: e.target.value
    })
  }

  const save = (action: SaveAction) => saveSettings('/usersettings/email', settingsData, action, 'User email settings')

  return (
    <Settings isSaving={isSaving} initialLoading={props.loading} save={save}>
      {settingsData && (
        <>
          <Checkbox
            disabled={isSaving}
            checked={settingsData.teamMembershipAlerts}
            onChange={handleAlertsChange}
            name="teamMembershipAlerts"
            label="Send me a notification when I am added or removed from a team"
          />
          <Checkbox
            disabled={isSaving}
            checked={settingsData.digestEmail}
            onChange={handleDigestChange}
            name="teamMembershipDigest"
            label={
              <>
                Send me a
                <Select
                  disabled={isSaving}
                  value={settingsData.digestFrequency}
                  onChange={handleDigestFrequencyChange}
                  name="teamMembershipDigestFrequency"
                  classes={{ select: field }}
                  variant="standard"
                >
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </Select>
                email digest with summary statistics and activity
              </>
            }
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
          />
        </>
      )}
    </Settings>
  )
}

export default EmailSettings
