import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { widgetSubTitle } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0,0,0,0.87)'
  },
  heading: widgetSubTitle(theme),
  additional: {
    fontSize: 13
  }
}))

const TitleDescription = (props: {
  title: JSX.Element | string
  description: JSX.Element | string
  blurb?: JSX.Element | string
  blurb2?: JSX.Element | string
}) => {
  const {
    classes: { root, heading, additional }
  } = useStyles()
  const { title, description, blurb, blurb2 } = props
  const loading = !title && !description

  return (
    <div className={root}>
      <Skeleton condition={loading} height={20} width={120}>
        {title && (
          <Typography variant="body1" className={heading} component="div">
            {title}
          </Typography>
        )}
      </Skeleton>
      <Skeleton condition={loading}>
        <Typography variant="body1" component="div">
          {description || (!title && 'Much longer placeholder for descriptions')}
        </Typography>
      </Skeleton>
      {(loading || blurb) && (
        <Skeleton condition={loading}>
          <Typography variant="body1" color="text.secondary">
            {blurb || '23 September 2020'}
          </Typography>
        </Skeleton>
      )}
      {(loading || blurb2) && (
        <Skeleton condition={loading}>
          <Typography variant="body1" color="text.secondary" className={additional}>
            {blurb2 || '23 September 2020'}
          </Typography>
        </Skeleton>
      )}
    </div>
  )
}

export default TitleDescription
