import { useState } from 'react'

export type FiltersType = {
  minimumEmployeeCount: string
  maximumEmployeeCount: string
  industries: string[]
  countries: string[]
  companyType: 'Private' | 'Public' | ''
}

type ResponseType = { scroll_token: string; total: number; error?: string }
export type CompaniesType = { id: string; website: string; data: { [key: string]: any }[]; filters?: FiltersType } & ResponseType

export const useSimilarCompanies = () => {
  const [similar, setSimilar] = useState<CompaniesType | null>(null)
  const [similarWidget, setSimilarWidget] = useState<CompaniesType | null>(null)

  const getCompanyDetails = (name: string, website?: string) => {
    return [...(similar?.data ? similar.data : []), ...(similarWidget?.data ? similarWidget.data : [])].find(
      (item: { [key: string]: any }) => item.name === name || (website && item.website === website)
    )
  }

  return {
    similar,
    similarWidget,
    setSimilar,
    setSimilarWidget,
    getCompanyDetails
  }
}
