import { useContext, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { isSidepanel } from '_pages/sidebar'

import { routeMap } from '_core/components/salesforce/data'

import { validUrl, validEmail } from '_core/helpers/string'

import { get, post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type SalesforceUserInfo = {
  userId: string
  name: string
  emailAddress: string
  profileUrl: string
  profilePictureUrl: string
  organizationId: string
  organizationUrl: string
}

export type FailedSaleforceLoginUserInfo = { errorCode: number; errorMessage: string }

export type SalesforceFormData = {
  [key: string]: {
    selectedSource: 'Salesforce' | 'DotAlign'
    selectedValue: { [key in SalesforceValue['source']]: SalesforceData }
  }
}

type SchemaShape = {
  selectedSource: yup.StringSchema
  selectedValue: yup.ObjectSchema<{
    DotAlign?: yup.ObjectSchema<{ value: yup.StringSchema }>
  }>
}

const rules: {
  [key in SalesforceContactProperty['id'] | SalesforceAccountProperty['id']]?: (displayName: string) => yup.StringSchema<string | null | undefined>
} = {
  Email: (displayName) => yup.string().test('is-email-valid', `${displayName} must be a valid email address`, (value) => validEmail(value)),
  Website: (displayName) => yup.string().test('is-url-valid', `${displayName} must be a valid url`, (value) => validUrl(value))
}

const getShape = (items: (SalesforceContactProperty | SalesforceAccountProperty)[]) =>
  items.reduce(
    (acc, item) => {
      const { id, displayName, required } = item
      const specificRule = rules[id]

      acc[id] = yup.object().shape({
        selectedSource: yup.string(),
        selectedValue: yup.object().when('selectedSource', {
          is: (value: string) => value === 'DotAlign' && (specificRule || required),
          then: yup.object().shape({
            DotAlign: yup.object().shape({
              value: specificRule
                ? required
                  ? specificRule(displayName).required(`${displayName} is required`).nullable()
                  : specificRule(displayName)
                : yup.string().required(`${displayName} is required`).nullable()
            })
          }),
          otherwise: yup.object().shape({
            DotAlign: yup.object().shape({ value: yup.string().notRequired().nullable() })
          })
        })
      })

      return acc
    },
    {} as { [key in SalesforceContactProperty['id'] | SalesforceAccountProperty['id']]: yup.ObjectSchema<SchemaShape> }
  )

export const useSalesforce = (items: (SalesforceContactProperty | SalesforceAccountProperty)[]) => {
  const history = useHistory()
  const [isSaving, setSaving] = useState<boolean>(false)

  const formatId = (id: string) => id.split('.').join('')
  const transformedItems = items.reduce(
    (acc, item: (typeof items)[number]) => {
      const { displayName: fieldName, id, values } = item
      const sortedSources = [...values.sort((f, s) => (s.source === f.source ? 0 : s.source < f.source ? -1 : 1))]
      const sources = sortedSources.map(({ source: value, data }) => {
        const options = data !== null ? data.filter(({ value }) => !!value) : []
        return { value, options }
      })

      const formattedId = formatId(id)
      acc[formattedId] = {
        id,
        field: formattedId,
        fieldName,
        sources
      }
      return acc
    },
    {} as {
      [key: string]: {
        id: (typeof items)[number]['id']
        field: string
        fieldName: (typeof items)[number]['displayName']
        sources: {
          value: string
          options: SalesforceData[]
        }[]
      }
    }
  )

  const defaultValues = items.reduce((acc, item: (typeof items)[number]) => {
    const { id, values } = item
    const selectedValue = values.reduce(
      (acc, { source: value, data: options }) => {
        acc[value] = options?.[0] || {}
        return acc
      },
      {} as { [key in SalesforceValue['source']]: SalesforceData }
    )

    const { value: salesforceValue } = selectedValue.Salesforce

    const formattedId = formatId(id)
    acc[formattedId] = {
      selectedSource: salesforceValue ? 'Salesforce' : 'DotAlign',
      selectedValue
    }
    return acc
  }, {} as SalesforceFormData)

  const methods = useForm({
    defaultValues,
    // @ts-expect-error ts(2322): to fix package bug
    resolver: yupResolver(yup.object().shape(getShape(items))),
    mode: 'all'
  })

  const submit = (id: string | undefined, data: typeof defaultValues, value: 'contact' | 'account') => {
    const result = items.reduce(
      (acc, { id }) => {
        const formattedId = formatId(id)
        const { selectedSource, selectedValue } = data[formattedId]
        const { value } = selectedValue[selectedSource]
        acc[id] = value
        return acc
      },
      {} as { [key: string]: SalesforceData['value'] }
    )

    setSaving(true)
    post(`/Salesforce/${value}`, { id, ...result })
      .then((data: any) => {
        const name = value === 'contact' ? `${data.firstname} ${data.lastname}` : data.name
        setSaving(false)
        history.push({
          pathname: 'success',
          state: {
            name,
            sourceUrl: data.url
          }
        })
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return {
    rows: Object.values(transformedItems),
    submit,
    methods,
    isSaving
  }
}

export const getExecutorPage = (type: keyof typeof routeMap) => routeMap[type]

const getExecutorUrl = (path: string) => {
  const [, , type, id] = path ? path.split('/') : []
  return type && id ? `/${getExecutorPage(type as keyof typeof routeMap)}/${id}` : '/'
}

export const useSalesforceUserInfo = (loading?: boolean) => {
  const [userInfoLoading, setUserInfoLoading] = useState(false)
  const [loggedInUserData, setLoggedInUserData] = useState<Partial<SalesforceUserInfo | FailedSaleforceLoginUserInfo>>({})
  const { sidepanelHistory } = useContext(LayoutContext)
  const { protocol, host } = window.location
  const sidepanel = isSidepanel('sidepanel')

  const sidepanelExecutorUrl = getExecutorUrl(sidepanelHistory[0]?.pathname)

  useEffect(() => {
    if (!loading) {
      setUserInfoLoading(true)
      get<SalesforceUserInfo>('/Salesforce/userInfo')
        .then((data) => {
          if (data) {
            setLoggedInUserData(data)
          }
        })
        .finally(() => setUserInfoLoading(false))
    }
  }, [loading])

  const loginUrl = (path: string) =>
    `${process.env.REACT_APP_DOTALIGN_API_HOST || ''}/login-salesforce?ReturnUrl=${encodeURIComponent(`${protocol}//${host}${path}`)}`
  const logoutUrl = (path: string) =>
    `${process.env.REACT_APP_DOTALIGN_API_HOST || ''}/logout-salesforce?ReturnUrl=${encodeURIComponent(`${protocol}//${host}${path}`)}`

  const login = (pathname: string) => {
    if (sidepanel) {
      window.parent.location.href = loginUrl(sidepanelExecutorUrl)
    }
    if (!sidepanel) {
      window.location.href = loginUrl(pathname)
    }
  }

  const logout = (pathname: string) => {
    if (sidepanel) {
      window.parent.location.href = logoutUrl(sidepanelExecutorUrl)
    }
    if (!sidepanel) {
      const executorUrl = getExecutorUrl(pathname)
      window.location.href = logoutUrl(executorUrl)
    }
  }

  return {
    userInfoLoading,
    loggedInUserData,
    login,
    logout
  }
}
