import { useMemo } from 'react'

import { useParams } from 'react-router'

import EditUserAssociations from '_core/components/team/EditUserAssociations'

import { apiBase, MatchAssociationsType } from '_core/hooks/useTeam'

import DynamicEntity from '_core/DynamicEntity'

const EditUserPage = () => {
  const { id: teamId, entityId } = useParams<MatchAssociationsType>()
  const userKey = decodeURIComponent(entityId)

  const memoUrls = useMemo(
    () => [
      {
        key: 'user',
        url: `${apiBase}/${teamId}/members/${userKey}?roleTypes=Member&credentialTypes=OwnsEmailAddress&credentialTypes=ActiveDirectoryTeamMember`
      },
      {
        key: 'userRole',
        url: `${apiBase}/${teamId}/members/${userKey}/role`
      },
      {
        key: 'roles',
        url: '/teams/roles'
      }
    ],
    [teamId, userKey]
  )

  return <DynamicEntity urls={memoUrls} component={EditUserAssociations} id="edit_user_associations" />
}

export default EditUserPage
