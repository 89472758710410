import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import useTeam from '_core/hooks/useTeam'

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    flex: 1,
    minHeight: '52px',
    marginLeft: theme.spacing(2)
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18
  }
}))

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => {
  const { classes } = useStyles()
  return (
    <Tooltip title="Remove users from a team" disabled={disabled}>
      <IconButton disabled={disabled} color="primary" onClick={open} style={{ width: '42px' }}>
        <FontAwesomeIcon className={classes.icon} icon={['far', 'user-minus']} />
      </IconButton>
    </Tooltip>
  )
}

const RemoveTeamContributorsDialog = ({
  isOpened,
  close,
  items,
  dropSelected,
  teamId,
  reload,
  loading,
  setLoading
}: {
  isOpened: boolean
  close: () => void
  items: any[]
  dropSelected: any
  reload: any
  teamId: string
  loading: boolean
  setLoading: (val: boolean) => void
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { removeTeamMembers } = useTeam()

  const closeHandler = () => {
    close()
    setLoading(false)
  }

  const removeSelectedUsers = () => {
    setLoading(true)
    const userKeys = items.map((item: any) => item.email)
    removeTeamMembers(`${teamId}`, userKeys).then(() => {
      dropSelected()
      enqueueSnackbar(`The ${items.length === 1 ? 'contributor is' : 'contributors are'} successfully removed`)
      closeHandler()
      reload()
    })
  }

  return (
    <Dialog open={isOpened} onClose={close} loading={loading} title={<DialogTitle title="Remove From Team?" />}>
      <DialogContent>
        <Typography>Removing a user withdraws their data from the team and causes that user to lose access to the team’s data.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeHandler} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={removeSelectedUsers} disablePR>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveTeamContributorsDialog.TriggerEl = TriggerEl

export default RemoveTeamContributorsDialog
