import { useEffect, useState } from 'react'

const useLocalStorage = (key: string, initialValue: any): [any, (value: any) => void] => {
  const [storedValue, setStoredValue] = useState<any>()

  useEffect(() => {
    ;(() => {
      try {
        const item = window.localStorage.getItem(key)
        setStoredValue(item ? item : initialValue)
      } catch (error) {
        console.log(error)
        setStoredValue(initialValue)
      }
    })()
  }, [initialValue, key])

  const setValue = (value: any) => {
    try {
      setStoredValue(value)
      window.localStorage.setItem(key, value)
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
