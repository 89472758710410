import React, { SyntheticEvent, FocusEvent, useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'

import { Narrow } from '_core/components/layout'
import { AddLink } from '_core/components/Picker'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import useCompanyForm from '_core/hooks/useCompanyForm'
import usePicker from '_core/hooks/usePicker'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { validUrl } from '_core/helpers/string'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  actions: actionButtons(theme),
  form: {
    height: `calc(100% - 60px)`,
    marginBottom: 53
  },
  updated: {
    background: 'rgb(248, 253, 255)'
  },
  closeButton: {
    marginTop: theme.spacing(2)
  }
}))

export const EditForm = (props: {
  url: string
  name: string
  setName: (name: string) => void
  setUrl: (url: string) => void
  updated: { [key in 'name' | 'url']?: boolean }
  nameOptions?: string[]
  urlOptions?: string[]
}) => {
  const { classes, cx } = useStyles()
  const { url, name, setName, setUrl, updated, nameOptions, urlOptions } = props
  const [touched, setTouched] = useState<{ [key in 'name' | 'url']?: boolean }>({})

  const touch = (name: keyof typeof touched) => {
    return setTouched((prevState) => ({ ...prevState, [name]: true }))
  }

  const handleNameChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const name = newValue === null ? '' : newValue
    setName(name)
    touch('name')
  }

  const handleUrlChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const url = newValue === null ? '' : newValue

    setUrl(url)
    touch('url')
  }

  const { message: urlErrorMessage = '' } =
    [
      { condition: !url, message: 'This field is required' },
      { condition: !validUrl(url), message: 'This field is invalid' }
    ].find(({ condition }) => touched.url && !!condition) || {}

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement
    touch(target.name as keyof typeof touched)
  }

  return (
    <>
      <Box p={2} className={cx({ [classes.updated]: updated.name })}>
        <Combobox
          name="name"
          label="Company name"
          icon={['far', 'briefcase']}
          freeSolo
          autoFocus
          defaultValue={name}
          value={name}
          options={nameOptions || []}
          forcePopupIcon={!!nameOptions?.length}
          errorMessage={touched.name && !name ? 'This field is required' : ''}
          onInputChange={handleNameChange}
          onChange={handleNameChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box p={2} className={cx({ [classes.updated]: updated.url })}>
        <Combobox
          name="url"
          label="Primary website"
          icon={['far', 'link']}
          freeSolo
          defaultValue={url}
          value={url}
          options={urlOptions || []}
          forcePopupIcon={!!urlOptions?.length}
          errorMessage={urlErrorMessage}
          onInputChange={handleUrlChange}
          onBlur={handleBlur}
        />
      </Box>
    </>
  )
}

const EditCompany = (
  props: { newForm: boolean; loading?: boolean } & Partial<Pick<CompanyType, 'CompanyNameText' | 'BestUrlText' | 'Aliases' | 'Urls'>>
) => {
  const params = useParams<{ id: string; new?: string }>()
  const { classes } = useStyles()

  const { setMobileHeader } = useContext(LayoutContext)
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(!!props.loading)
  const handleClose = useSidepanelClose(`${Paths._companies}/${params.id}`)
  const { Form, save, ...formData } = useCompanyForm({ name: props.CompanyNameText, url: props.BestUrlText })
  const { name, setName, allowSubmit } = formData

  const getLink = (md5: string) => `${Paths._companies}/${md5}/edit`
  const { Picker, PickerButton, keyword } = usePicker({ entity: 'companies', addButton: AddLink, getLink, name, unsaved: allowSubmit })

  const picked = props.newForm || params.id

  React.useEffect(() => {
    typeof keyword === 'string' && setName(keyword)
  }, [keyword])

  React.useEffect(() => {
    setMobileHeader(!picked ? 'Pick or add company' : params.id ? 'Edit company' : 'Add company')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picked])

  React.useEffect(() => {
    if (!params.id && !props.newForm) setSuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const submit = async () => {
    setLoading(true)
    try {
      await save()
      setSuccess(true)
    } finally {
      setLoading(false)
    }
  }

  if (success)
    return (
      <Success>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Link to={'/companies/add'}>
            <Button variant="outlined" startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}>
              Add/Edit another company
            </Button>
          </Link>
          <Button variant="text" onClick={handleClose} color="secondary" className={classes.closeButton}>
            Close
          </Button>
        </Box>
      </Success>
    )

  return (
    <>
      {loading && (
        <Box display="flex" alignItems="center" justifyContent="center" height={320}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Narrow>
            <Topbar sub={picked && name} nativeBack={true} action={params.id ? PickerButton : null} />
          </Narrow>
          <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
            {!picked && Picker}
            {picked && (
              <Box className={classes.form}>
                <Form
                  {...formData}
                  nameOptions={props.Aliases?.data?.map((alias) => alias.CoNameAlias).filter((name) => !!name)}
                  urlOptions={props.Urls?.data?.map((alias) => alias.CoUrl).filter((url) => !!url)}
                />
                <Box className={classes.actions}>
                  <Button variant="text" disabled={!allowSubmit} onClick={submit} disablePR>
                    Save
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default EditCompany
