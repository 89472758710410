import React from 'react'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '_shared/Dialog'
import Typography from '_shared/Typography'

type RemoveTeamMemberProps = {
  isOpen: boolean
  loading: boolean
  credential: any
  close: (event: any) => void
  remove: (event: any) => void
  team: Team
}

const RemoveTeamMember = (props: RemoveTeamMemberProps) => {
  const { isOpen, close, remove, team, credential, loading } = props

  if (!credential || !team) return <></>

  return (
    <Dialog open={isOpen} loading={loading} onClose={close} title={<DialogTitle title="Remove member?" />}>
      <DialogContent>
        <Typography>
          Do you want to remove <b>{credential.displayName}</b> from team <b>{team.name}</b>? Note that users who are a part of other groups in this
          team will not be removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={remove} disablePR>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveTeamMember
