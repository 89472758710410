import { useState } from 'react'

import { useLocation } from 'react-router-dom'

import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

const interactionsSaveData = {
  endpoint: '/usersettings/interactionsfilter',
  getData: ({ checked = [] }: { checked?: InteractionsType[] }) => ({
    inbound: checked.includes('inbound'),
    outbound: checked.includes('outbound')
  })
}

const useInteractionsUserSettings = () => {
  const { queryParams, updateQuery } = useSearchQuery<InteractionsPageParams, { modifyProps: [{ checked: InteractionsType[] }] }>(['checked'])
  const { search } = useLocation()
  const [isReady, setIsReady] = useState<boolean>(!!search)
  const { save } = useFilter()

  const setInitial = (data: { [key in InteractionsType]: boolean }) => {
    const checked = (Object.keys(data) as InteractionsType[]).filter((key) => data[key])
    setIsReady(true)

    updateQuery({ checked })
  }

  const handleChange = (updates: typeof queryParams) => {
    save(interactionsSaveData, { ...queryParams, ...updates })
  }

  return {
    isReady,
    setInitial,
    handleChange
  }
}

export default useInteractionsUserSettings
