import React from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

interface ITab {
  id: string
  label: string
  component: () => JSX.Element
}

const useStyles = makeStyles()((theme) => ({
  outlinedDetails: {
    display: 'block',
    padding: `0 ${theme.spacing(0.5)} ${theme.spacing(2)}`,
    [theme.breakpoints.up('sidepanel')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`
    }
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    padding: `${theme.spacing(2)} 0`
  }
}))

const SettingsAccordion = ({ tabs }: { tabs: ITab[] }) => {
  const [expanded, setExpanded] = React.useState<string>(tabs[0].id)
  const { classes } = useStyles()

  const handleChange = (id: string) => setExpanded(id)

  return (
    <>
      {tabs.map(({ label, id, component: Component }: ITab) => {
        const isExpanded = expanded === id

        return (
          <Accordion onChange={() => handleChange(id)} expanded={isExpanded} key={id} variant="outlined">
            <AccordionSummary
              expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" disablePadding />}
              aria-label="Expand"
              aria-controls={`${id}-content`}
              id={`${id}-header`}
            >
              <Typography ellipsis semiBold className={classes.title} color={isExpanded ? 'primary.main' : 'text.secondary'}>
                {label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.outlinedDetails }}>{Component && <Component />}</AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default SettingsAccordion
