import { ChangeEvent } from 'react'

import { RadioGroup } from '@mui/material'

import Typography from '_shared/Typography'

import Filters from '_core/components/filters'

import useControlsStyles from './styles'

type PerspectiveProps<T = PerspectiveType> = {
  value?: T
  disabled: boolean
  handleChange: (e: ChangeEvent<{ name: string; value: unknown }>) => void
  options: { value: T; label: string }[]
}

const Perspective = <T,>(props: PerspectiveProps<T>) => {
  const { classes } = useControlsStyles()
  const { options, value, disabled, handleChange } = props

  return (
    <>
      <Typography variant="h4" semiBold classes={{ root: classes.header }}>
        Show
      </Typography>
      <RadioGroup name="perspective" value={value || ''} onChange={handleChange}>
        {options.map((item) => (
          <Filters.Radio disabled={disabled} key={item.label} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </>
  )
}

export default Perspective
