import { useCallback, useContext, useMemo } from 'react'

import { AuditContext } from '_core/context/Audit'
import { TeamContext } from '_core/context/TeamContext'

import useAuditEntities from '_core/hooks/useAuditEntities'

import { uuid as uid } from 'utils/demoUtils'
import { post } from 'utils/httpUtils'

type PInfo = {
  personInfo: Partial<PersonInfo>
  companyInfo?: never
  md5?: never
}

type CInfo = {
  companyInfo: Partial<CompanyInfo>
  personInfo?: never
  md5?: never
}

type AuditTuplesPayload = {
  anchorMd5: AuditEntity['id']
  name: AuditEntity['name']
  entities: {
    name: AuditEntity['name']
    identifiers: Omit<AuditIdentifier, 'invalidReason'>[]
    entityInfo: { md5: string; personInfo?: never; companyInfo?: never } | PInfo | CInfo
  }[]
  invalidIdentifiers: {
    reasonForBeingInvalid: string
    identifier: Omit<AuditIdentifier, 'invalidReason'>
  }[]
}

type RemoteAuditTuples = {
  groupings: {
    grouping: {
      TaggableTypeName: string
      entityIdentifiers: string[]
    }
    teamNumber: number
    anomalousTuples: AuditAnomalousTuple[]
  }[]
}

const useAuditTuples = () => {
  const { valid, invalid } = useAuditEntities()

  const { teamContextValue } = useContext(TeamContext)
  const { tuples, setTuples, checkedTuples: checked, setCheckedTuples: setChecked } = useContext(AuditContext)

  const memoTuples = useMemo(
    () => tuples?.map((anomalousTuples) => anomalousTuples.map((tuple) => ({ ...tuple, isActive: checked.includes(tuple.uid) }))),
    [tuples, checked]
  )

  const memoSplits = useMemo(
    () =>
      memoTuples
        ?.slice(1)
        .flat()
        .filter(({ isActive }) => isActive)
        .map(({ highText, highMd5, lowText, lowMd5 }) => ({ identity1: highText || highMd5, identity2: lowText || lowMd5 })),
    [memoTuples]
  )

  const requestTuples = async (entity: 'people' | 'companies') => {
    const payload: AuditTuplesPayload = {
      anchorMd5: valid[0]?.id,
      name: valid[0]?.name,
      entities: valid.map(({ isNew, id, name, identifiers, distilledIdentifiersKeys, isActive, isPrimary, ...entityInfo }) => ({
        name,
        identifiers: identifiers.map(({ invalidReason, ...identifier }) => identifier),
        entityInfo: {
          ...(isNew ? (entity === 'people' ? { personInfo: { name, ...entityInfo } } : { companyInfo: { name, ...entityInfo } }) : { md5: id })
        }
      })),
      invalidIdentifiers: invalid.length
        ? invalid[0].identifiers.map(({ invalidReason = '', ...identifier }) => ({
            reasonForBeingInvalid: invalidReason,
            identifier
          }))
        : []
    }
    const result = await post<RemoteAuditTuples>(`/${entity}/auditrequests?TeamNumber=${teamContextValue.teamNumber}`, payload)
    if (result) {
      const tuples = result.groupings.map(({ anomalousTuples }) => anomalousTuples.map((tuple) => ({ ...tuple, uid: uid(), isActive: true })))

      const originalEntityEmpty = !payload.entities[0].identifiers.length
      if (originalEntityEmpty) {
        tuples.unshift([])
      }

      setTuples(tuples)
      setChecked(tuples?.flat().map(({ uid }) => uid))
      return tuples
    }
  }

  const clearTuples = useCallback(() => setTuples(undefined), [setTuples])

  const toggleSplit = useCallback((id: string) => {
    setChecked((prevState) => (prevState.includes(id) ? prevState.filter((chId) => chId !== id) : [...prevState, id]))
  }, [])

  const toggleSplitAll = useCallback(
    (entityIndex: number) => {
      if (tuples) {
        const tuplesIds = tuples[entityIndex].map(({ uid }) => uid)
        const active = tuplesIds.filter((uid) => checked.includes(uid))

        setChecked((prevState) => {
          const filtered = prevState.filter((id) => !active.includes(id))
          return active.length === tuplesIds.length ? filtered : [...filtered, ...tuplesIds]
        })
      }
    },
    [checked, setChecked, tuples]
  )

  return {
    tuples: memoTuples,
    splits: memoSplits,
    toggleSplit,
    toggleSplitAll,
    requestTuples,
    clearTuples
  }
}

export default useAuditTuples
