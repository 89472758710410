import { useContext, useEffect, useMemo, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'

import AuditProvider from '_core/context/Audit'
import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AuditReview from '_core/components/audit/AuditReview'
import AuditSuccess from '_core/components/audit/AuditSuccess'
import AuditTopBarActions from '_core/components/audit/AuditTopBarActions'
import AuditTuples from '_core/components/audit/AuditTuples'
import AuditIdentifiers from '_core/components/audit/CompaniesAuditIdentifiers'
import LeaveDialog from '_core/components/dialogs/Leave'
import InnerDialog from '_core/components/InnerDialog'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAuditEntities from '_core/hooks/useAuditEntities'
import useAuditSubmit from '_core/hooks/useAuditSubmit'
import useAuditTuples from '_core/hooks/useAuditTuples'
import useDialog from '_core/hooks/useDialog'
import usePicker from '_core/hooks/usePicker'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Component = (props: { username: string; loading: boolean }) => {
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)
  const [loading, setLoading] = useState<boolean>(false)
  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  const [step, setStep] = useState<number>(0)
  const [searchOpened, setSearchOpened] = useState<boolean>(false)

  const { isDirty, reset, valid, invalid, primary, setActive } = useAuditEntities()
  const { tuples, splits, requestTuples } = useAuditTuples()

  const submit = useAuditSubmit({ entity: 'companies', invalid, valid, splits })
  const handleClose = useSidepanelClose()

  const openSearch = () => {
    setSearchOpened(true)
  }

  const closeSearch = () => {
    setSearchOpened(false)
  }

  const { Picker: Search, PickerButton } = usePicker({
    entity: 'companies',
    onSearchClick: isDirty ? openDialog : openSearch,
    getLink: (md5: string) => `${Paths._companies}/${md5}/audit`
  })

  useEffect(() => {
    setMobileHeader('Audit identities')
    setSubHeader(props.username)
  }, [setMobileHeader, setSubHeader, props.username])

  const next = (step = 1) => {
    if (primary) {
      setActive(primary)
      setStep((prev) => prev + step)
    }
  }

  const back = (step = 1) => {
    setStep((prev) => prev - step)
  }

  const openTuples = async () => {
    setLoading(true)
    const tuplesGroups = await requestTuples('companies')

    next(tuplesGroups?.length === 1 || !tuplesGroups?.[0].length ? 2 : 1)
    setLoading(false)
  }

  const reviewGoBack = () => {
    back(tuples?.length === 1 || !tuples?.[0].length ? 2 : 1)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const isOk = await submit()
    if (isOk) {
      setStep(3)
    }
    setLoading(false)
  }

  const handleReset = () => {
    reset()
    setStep(0)
  }

  if (loading) {
    return (
      <Box py={14} display="flex" justifyContent="center">
        <CircularProgress size="24px" color="secondary" />
      </Box>
    )
  }

  return (
    <>
      {step !== 3 && (
        <Topbar nativeBack={true} action={props.username ? <AuditTopBarActions reset={handleReset} pickerButton={PickerButton} /> : null} />
      )}
      <Widget scope="list">
        {searchOpened ? (
          <InnerDialog close={closeSearch}>{Search}</InnerDialog>
        ) : (
          <>
            {step === 0 && <AuditIdentifiers userName={props.username} loading={props.loading} next={openTuples} reset={handleReset} />}
            {step === 1 && <AuditTuples next={next} back={back} reset={handleReset} />}
            {step === 2 && <AuditReview submit={handleSubmit} back={reviewGoBack} reset={handleReset} />}
            {step === 3 && <AuditSuccess openSearch={openSearch} label="Choose another company" close={handleClose} />}
          </>
        )}
      </Widget>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={handleClose} />
    </>
  )
}

const Wrapper = (props: { auditInfo: AuditInfo; CompanyNameText: string; loading: boolean }) => {
  const { auditInfo, loading, CompanyNameText: username } = props

  return (
    <AuditProvider auditInfo={auditInfo}>
      <Component loading={loading} username={username} />
    </AuditProvider>
  )
}

const CompanyAuditPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)

  const memoUrls = useMemo(
    () => [
      { key: 'auditInfo', url: `/companies/${id}/auditinfo?teamNumber=${teamContextValue.teamNumber}` },
      { key: 'subTitle', url: `/companies/${id}?teamNumber=${teamContextValue.teamNumber}`, single: true, merge: true }
    ],
    [id, teamContextValue.teamNumber]
  )

  return (
    <Page>
      <DynamicEntity urls={memoUrls} component={Wrapper} empty="Profile not found" nativeBack={true} id="audit_profile" />
    </Page>
  )
}

export default CompanyAuditPage
