import { useParams } from 'react-router-dom'

import EditCompany from '_core/components/EditCompany'

const AddCompanyPage = () => {
  const params = useParams<{ new: string }>()
  return <EditCompany newForm={!!params.new} />
}

export default AddCompanyPage
