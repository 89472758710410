import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import RelationshipUserCompany from './[userId]/companies/[companyId]'
import RelationshipUserContact from './[userId]/people/[personId]'

const RelationshipsPage = (props: any) => {
  const { via } = useParams<{ via: 'companies' | 'people' }>()

  return (
    <Page>
      {via === 'companies' && <RelationshipUserCompany {...props} />}
      {via === 'people' && <RelationshipUserContact {...props} />}
    </Page>
  )
}

export default RelationshipsPage
