import React from 'react'

import { Box } from '@mui/material'

import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

import { HelpLink } from '_core/components/PrivilegedHeading'

const HeadingActions = (
  <Box display="flex" alignItems="center" justifyContent="flex-end">
    <HelpLink />
  </Box>
)

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Restricted companies',
    content: (
      <Typography style={{ maxWidth: '220px' }}>
        The visibility of companies on this list is restricted to privileged users only. Other users will not see these companies or the people who
        work there, in any context within the DotAlign application.
      </Typography>
    ),
    headingActions: HeadingActions,
    target: '.privileged-companies-onboarding',
    placement: 'bottom'
  },
  {
    disableBeacon: true,
    title: 'Privileged users',
    content: (
      <Typography style={{ maxWidth: '230px' }}>
        The users listed here are allowed to see restricted companies, the people who work in those companies and other details.
      </Typography>
    ),
    headingActions: HeadingActions,
    target: '.privileged-members-onboarding',
    placement: 'bottom'
  },
  {
    disableBeacon: true,
    title: 'Exceptions',
    content: (
      <Typography style={{ maxWidth: '220px' }}>
        Contacts list here will be visible to non-privileged users even if those contacts work at a restricted company.
      </Typography>
    ),
    headingActions: HeadingActions,
    target: '.privileged-contacts-onboarding',
    placement: 'bottom'
  }
]

export default steps
