import { SyntheticEvent } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import Combobox, { ChipsPickList } from '_shared/forms/Combobox'

import { FiltersType } from '_core/hooks/useSimilarPeople'

import { actionButtons } from 'AppTheme'

import SimilarFieldPicker, { SimilarFieldType } from './controls/SimilarFieldPicker'

import Filters from './index'

const useStyles = makeStyles<{ sidepanel: boolean }>()((theme, { sidepanel }) => ({
  container: {
    overflowY: 'visible',
    padding: 0
  },
  wrapper: {
    padding: `0 ${theme.spacing(2)}`,
    minHeight: `calc(100vh - ${sidepanel ? 257 : 229}px)`
  },
  block: {
    paddingBottom: theme.spacing(1.5)
  },
  buttonContainer: actionButtons(theme, 'sticky')
}))

export type SimilarPeopleFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  anchorEl?: HTMLElement | null
  filters?: FiltersType | null
  updateFilters: (updatedFilters: FiltersType) => void
  reset: () => void
  applyFilters: () => void
}
type MultipleFieldType = SimilarFieldType | 'jobTitleLevels'

const companySizes = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10000', '10001+']
const jobTitleLevelsOptions = ['Cxo', 'Director', 'Entry', 'Manager', 'Owner', 'Partner', 'Senior', 'Training', 'Unpaid', 'Vp']

const SimilarPeopleFilters = (props: SimilarPeopleFiltersType) => {
  const { opened, disabled, anchorEl, total, contentLoading, updateFilters, filters, reset, applyFilters } = props
  const sidepanel = isSidepanel()
  const { classes } = useStyles({ sidepanel })

  const handlePickerChange = (newValue: string | null, field: 'companySize') => {
    if (filters) {
      const newFilter = { [field]: newValue || '' }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  const handleMultiplePickerChange = (newValue: string[], field: MultipleFieldType) => {
    if (filters) {
      const newFilter = { [field]: newValue }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  const handleRemove = (value: string | null, field: MultipleFieldType) => {
    if (filters && value) {
      const newFilter = { [field]: filters[field].filter((v: string) => v !== value) }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  return (
    <Filters
      opened={opened}
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={reset}
      anchorEl={anchorEl || null}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.block}>
          <JobTitleLevelsPicker
            value={filters?.jobTitleLevels}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.block}>
          <SimilarFieldPicker
            field="jobTitles"
            label="Job title"
            placeholder="E.g. Chief executive officer"
            disabled={disabled}
            icon={['far', 'briefcase']}
            value={filters?.jobTitles}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
          />
        </Box>
        <Box className={classes.block}>
          <SimilarFieldPicker
            field="jobTitleRoles"
            label="Job area"
            icon={['far', 'briefcase']}
            placeholder="E.g. Operations"
            value={filters?.jobTitleRoles}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
            loadOnFocus
          />
        </Box>
        <Box className={classes.block}>
          <SimilarFieldPicker
            field="jobTitleSubroles"
            label="Job sub-area"
            icon={['far', 'briefcase']}
            placeholder="E.g. Product"
            value={filters?.jobTitleSubroles}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
            loadOnFocus
          />
        </Box>
        <Box className={classes.block}>
          <SimilarFieldPicker
            field="countries"
            label="Countries"
            icon={['fas', 'location-dot']}
            placeholder="E.g. United States"
            value={filters?.countries}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.block}>
          <SimilarFieldPicker
            field="industries"
            label="Current company's industry"
            icon={['fas', 'industry']}
            placeholder="E.g. Real Estate"
            value={filters?.industries}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.block}>
          <Combobox<string, false, boolean>
            label="Company size"
            options={companySizes}
            disabled={disabled}
            value={filters?.companySize || null}
            onChange={(e, value) => handlePickerChange(value, 'companySize')}
            disableClearable={!filters?.companySize}
            blurOnSelect
          />
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button onClick={applyFilters} disabled={disabled || contentLoading} variant="text" disablePR>
          Apply
        </Button>
      </Box>
    </Filters>
  )
}

const JobTitleLevelsPicker = ({
  disabled,
  value,
  handleChange,
  handleDelete
}: {
  disabled: boolean
  handleDelete: (value: string, fieldName: 'jobTitleLevels') => void
  handleChange: (v: string[], fieldName: 'jobTitleLevels') => void
  value?: string[]
}) => {
  const handleValueChange = (e: SyntheticEvent<Element, Event>, v: string[]) => {
    handleChange(v, 'jobTitleLevels')
  }

  return (
    <>
      <Combobox<string, true, true>
        label="Seniority level"
        placeholder="E.g. Cxo"
        options={jobTitleLevelsOptions}
        disabled={disabled}
        value={value || []}
        onChange={handleValueChange}
        blurOnSelect
        multiple
        disableClearable
        filterSelectedOptions
      />
      <ChipsPickList
        items={
          value?.map((level: string) => ({
            label: level,
            handleDelete: () => handleDelete(level, 'jobTitleLevels')
          })) || []
        }
        disabled={disabled}
      />
    </>
  )
}

export { Controller } from './index'
export default SimilarPeopleFilters
