import { ReactElement, ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, IconButton, InputAdornment, TextField, Box, RadioGroup } from '@mui/material'
import { FieldErrors, UseFormGetValues, UseFormRegister, Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'
import Tooltip from '_shared/Tooltip'

const useStyles = makeStyles()((theme) => ({
  form: {
    '& > div:first-of-type .MuiFormControl-root': {
      marginTop: theme.spacing(1)
    }
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: theme.spacing(3),
    '& input': {
      lineHeight: 1.5
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18
  }
}))

export const templates: { value: 'default' | 'upload'; label: string }[] = [
  { value: 'default', label: 'Detailed' },
  { value: 'upload', label: 'Compact' }
]

export const DownloadTemplateControl = ({ control }: { control: Control<{ template: (typeof templates)[number]['value'] }> }) => {
  return (
    <Box mb={2}>
      <Controller
        name="template"
        control={control}
        render={({ field }: { field: ControllerRenderProps<{ template: (typeof templates)[number]['value'] }, 'template'> }) => (
          <RadioGroup value={field.value} onChange={field.onChange}>
            {templates.map(({ value, label }) => (
              <Radio key={value} value={value} label={label} />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  )
}

const TriggerEl = ({ open, disabled, className }: { open: () => void; className?: string; disabled: boolean }) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title="Bulk download" disabled={disabled}>
      <IconButton disabled={disabled} color="primary" className={cx(classes.container, className)} onClick={open}>
        <FontAwesomeIcon className={classes.icon} icon={['far', 'download']} />
      </IconButton>
    </Tooltip>
  )
}

type DownloadBulkDialogProps = {
  totalRows: number
  children?: ReactNode
  templateControl?: ReactElement
  isOpened: boolean
  close: () => void
  register: UseFormRegister<{
    count: number
    rows: number
  }>
  getValues: UseFormGetValues<{
    count: number
    rows: number
  }>
  errors: FieldErrors<{
    count: number
    rows: number
  }>
  handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

const DownloadBulkDialog = (props: DownloadBulkDialogProps) => {
  const { classes } = useStyles()

  const maxRowsRequestLimit = 10000
  const { isOpened, close, totalRows, templateControl, handleSubmit, register, errors, getValues } = props

  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title="Export to Excel" />}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          {templateControl}
          <FormControl style={{ display: 'flex' }}>
            <TextField
              autoFocus
              variant="outlined"
              label="Total rows"
              {...register('count', {
                required: true,
                valueAsNumber: true,
                max: maxRowsRequestLimit,
                min: 1
              })}
              error={!!errors.count}
              helperText={
                errors.count
                  ? getValues().count < 1
                    ? `Export at least 1 row`
                    : `Export cannot exceed ${maxRowsRequestLimit} rows`
                  : `${totalRows} rows available`
              }
              type="number"
              className={classes.input}
              onKeyPress={(e: any) => {
                if ((e.charCode < 48 || e.charCode > 57) && e.charCode !== 13) e.preventDefault()
              }}
            />
          </FormControl>
          <FormControl style={{ display: 'flex' }}>
            <TextField
              variant="outlined"
              label="Starting at"
              InputProps={{
                startAdornment: <InputAdornment position="start">Row</InputAdornment>
              }}
              {...register('rows', {
                required: true,
                valueAsNumber: true,
                max: totalRows,
                min: 1
              })}
              error={!!errors.rows}
              helperText={
                errors.rows
                  ? getValues().rows < 1
                    ? `Start at row 1 or higher`
                    : `Only ${totalRows} rows available`
                  : `Available range of rows from 1 to ${totalRows}`
              }
              type="number"
              className={classes.input}
              onKeyPress={(e: any) => {
                if ((e.charCode < 48 || e.charCode > 57) && e.charCode !== 13) e.preventDefault()
              }}
            />
          </FormControl>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="text" type="submit" disabled={!!(errors.count || errors.rows)} disablePR>
            Export
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

DownloadBulkDialog.TriggerEl = TriggerEl

export default DownloadBulkDialog
