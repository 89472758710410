import { ComponentProps } from 'react'

import DownloadBulkDialog from '_core/components/dialogs/DownloadBulk'

import useDialog from '_core/hooks/useDialog'
import useDownloadBulk, { DownloadBulkParams } from '_core/hooks/useDownloadBulk'

type DownloadBulkControlProps = {
  disabled: boolean
  download: { fileName: string; requestBinary: (data: DownloadBulkParams) => Promise<Blob | undefined> }
} & Pick<ComponentProps<typeof DownloadBulkDialog>, 'totalRows' | 'templateControl'>

const DownloadBulkControl = (props: DownloadBulkControlProps) => {
  const { totalRows, disabled, download } = props

  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  const { register, getValues, errors, handleSubmit } = useDownloadBulk(download)

  const onSubmit = (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    closeDialog()
    return handleSubmit(e)
  }

  return (
    <>
      <DownloadBulkDialog.TriggerEl open={openDialog} disabled={disabled} />
      <DownloadBulkDialog
        totalRows={totalRows}
        register={register}
        getValues={getValues}
        errors={errors}
        handleSubmit={onSubmit}
        isOpened={isDialogOpened}
        close={closeDialog}
      />
    </>
  )
}

export default DownloadBulkControl
