import { useContext, ComponentProps, useMemo, ReactElement } from 'react'

import {
  identifierColumn,
  anchorIdentifierColumn,
  preferredNameColumn,
  token1Column,
  token2Column,
  preferredEmailColumn,
  preferredPhoneColumn,
  assertionColumn,
  assertedByColumn,
  dateColumn,
  companyColumn,
  actionColumn
} from '_pages/manual-edits/columns'
import { invalidReasonMap } from '_pages/manual-edits/data'
import { InformAffiliationType } from '_pages/people/[id]/affiliations'

import { TeamContext } from '_core/context/TeamContext'

import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import InformAboutPhoneNumberDialog, { InformPhoneType } from '_core/components/dialogs/InformAboutPhoneNumber'
import { ManualEditRowItem } from '_core/components/dialogs/ManualEditUndo'
import { jobTitleColumn } from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import ManualEditsList from '_core/components/ManualEditsList'

import useDialog from '_core/hooks/useDialog'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams, del } from 'utils/httpUtils'

import Paths from 'Paths'

export type ManualEditType = {
  teamNumber: number
  rootCreated: string
  rootLastModified: string
  graph: {
    namedGraphUri: string
    userKey: string
    userSecurityPartition: string
  }
  entity: string
  subject: {
    entityKey: string
    subjectUri: string
    subjectMd5: string
    isValid: boolean
  }
  rowNumber: number
  total: number
  propValues: {
    key: string
    value: {
      dataType: string
      valueBinary?: string
      valueEnum?: keyof typeof invalidReasonMap
      valueInt?: number
      lastModified: string
      referenceText?: string
    }
  }[]
  sourceTally: number
  negated: boolean
  affirmed: boolean
  manuallyAssertedWhen: string
}

export const saveData = {
  endpoint: '/usersettings/personmanualeditsfilters',
  getData: (params: ManualEditsPageParams & { isOpened: boolean }): ManualEditsInit => {
    const { contributor, rowsPerPage, entityType, isOpened } = params

    return {
      type: entityType || 'PersonTuple',
      contributorKey: contributor,
      rowsPerPage: +(rowsPerPage || '10') as RowPerPageOptionsType,
      isOpened
    }
  }
}

export const resetEndpoint = '/usersettings/default/personmanualeditsfilters'

type PManualEditsListProps = {
  items: ManualEditType[]
} & Pick<ComponentProps<typeof ManualEditsList>, 'total' | 'setPageSize' | 'columns' | 'paging' | 'loading' | 'confirmDelete'> & {
    getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) =>
      | {
          value: ManualEditRowItem['assertion']
          label: string
        }
      | undefined
  }

const PManualEditsList = (
  props:
    | ({ loading: true } & Modify<PManualEditsListProps, { items: Partial<PManualEditsListProps['items']> }>)
    | ({ loading: false } & Required<PManualEditsListProps>)
) => {
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType } = queryParams

  const { items, total, setPageSize, columns, paging, loading, getAssertion } = props
  const {
    dialogContentProps: openedAffiliationDialog,
    openDialog: openAffiliationDialog,
    closeDialog: closeAffiliationDialog,
    successMode: affiliationSuccessMode,
    openSuccess: openAffiliationSuccess
  } = useDialog<InformAffiliationType>()
  const {
    dialogContentProps: openedPhoneDialog,
    openDialog: openPhoneDialog,
    closeDialog: closePhoneDialog,
    successMode: phoneSuccessMode,
    openSuccess: openPhoneSuccess
  } = useDialog<InformPhoneType>()

  const { value: identifier1Key, md5Key: identifier1Md5Key = '' } =
    [
      { value: 'HighMd5', md5Key: 'valueBinary', condition: entityType === 'PersonTuple' },
      { value: 'PersonMd5', md5Key: 'valueBinary', condition: entityType !== 'PersonTuple' }
    ].find(({ condition }) => !!condition) || {}

  const { value: identifier2Key, md5Key: identifier2Md5Key = '' } =
    [
      { value: 'LowMd5', md5Key: 'valueBinary', condition: entityType === 'PersonTuple' },
      { value: 'PersonCompleteName', md5Key: 'valueRefdMd5', condition: entityType === 'PersonPreferredName' },
      { value: 'EmailAddress', md5Key: 'valueRefdMd5', condition: entityType === 'PersonEmail' },
      { value: 'PhoneNumber', md5Key: 'valueRefdMd5', condition: entityType === 'PersonPhone' },
      { value: 'CompanyMd5', md5Key: 'valueBinary', condition: entityType === 'PersonJob' }
    ].find(({ condition }) => !!condition) || {}

  const itms = useMemo(
    () =>
      !loading && entityType
        ? items.map((item, index) => {
            const { sourceTally, propValues, graph, manuallyAssertedWhen, rootLastModified, rootCreated } = item
            const { value: assertionValue, label: assertionLabel = '' } = getAssertion({ propValues, sourceTally }) || {}

            const identifier1 = propValues.find(({ key }) => key === identifier1Key)?.value
            const identifier2 = propValues.find(({ key }) => key === identifier2Key)?.value

            const identifier1Md5 = identifier1?.[identifier1Md5Key as keyof typeof identifier1]?.toString() || ''
            const identifier2Md5 = identifier2?.[identifier2Md5Key as keyof typeof identifier2]?.toString() || ''

            const { referenceText: jobTitle = '' } = propValues.find(({ key }) => key === 'MatchedJobTitle')?.value || {}
            return {
              id: `${index}`,
              identifier1Md5,
              identifier1RefText: identifier1?.referenceText || '',
              identifier2Md5,
              identifier2RefText: identifier2?.referenceText || '',
              title: jobTitle,
              assertion: assertionValue,
              assertionLabel,
              assertedBy: graph.userKey,
              date: manuallyAssertedWhen || rootLastModified || rootCreated,
              editLink: `${Paths._people}/${identifier1Md5}/edit`,
              auditLink: `${Paths._people}/${identifier1Md5}/audit`,
              openAffiliationDialog,
              openPhoneDialog
            }
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  )

  const confirmDelete = (payload: ManualEditRowItem[]) => {
    return del<{ success: boolean; errorMessage?: string }>(
      `/teams/${teamContextValue.teamNumber}/manualedits`,
      payload.map(({ identifier1RefText, assertion, identifier2RefText }) => ({
        identity1: identifier1RefText,
        identity2: identifier2RefText,
        assertion,
        entity: 'Person'
      }))
    )
  }

  return (
    <>
      <ManualEditsList
        items={itms}
        columns={columns}
        confirmDelete={confirmDelete}
        {...(loading ? { loading } : { total, setPageSize, paging, loading })}
      />
      <AffiliationInformDialog
        close={closeAffiliationDialog}
        openSuccess={openAffiliationSuccess}
        success={affiliationSuccessMode}
        opened={!!openedAffiliationDialog}
        {...(openedAffiliationDialog || {})}
      />
      <InformAboutPhoneNumberDialog
        isOpened={!!openedPhoneDialog}
        close={closePhoneDialog}
        successMode={phoneSuccessMode}
        openSuccess={openPhoneSuccess}
        {...(openedPhoneDialog || {})}
      />
    </>
  )
}

const PeopleManualEdits = (props: {
  filters: ReactElement
  setInitial: (resp: ManualEditsInit) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  total: number | undefined
  onLoading: (loading: boolean, result: { results: ManualEditType[]; total_item_count: number } | undefined) => void
  disabledSearch: boolean
}) => {
  const { teamContextValue } = useContext(TeamContext)

  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType, rowsPerPage, contributor = '' } = queryParams

  const {
    assertionFilter,
    columns = [identifierColumn, assertionColumn, assertedByColumn, dateColumn, actionColumn],
    getAssertion = () => undefined
  } = useMemo(
    () =>
      [
        {
          columns: [identifierColumn, assertionColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'PersonDisqualifier',
          getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) => {
            const { valueEnum: disqualifierReason } = propValues.find(({ key }) => key === 'DisqualifierReason')?.value || {}

            if (disqualifierReason && sourceTally < 0) {
              const assertion: { value: ManualEditRowItem['assertion']; label: string } = {
                value: disqualifierReason,
                label: invalidReasonMap[disqualifierReason]
              }
              return assertion
            } else if (!disqualifierReason && sourceTally > 0) {
              const assertion: { value: ManualEditRowItem['assertion']; label: string } = {
                value: 'Requalified',
                label: 'requalified'
              }
              return assertion
            }
          }
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, preferredNameColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'Affirmed',
          condition: entityType === 'PersonPreferredName',
          getAssertion: (): { value: ManualEditRowItem['assertion']; label: string } => ({
            value: entityType,
            label: 'preferred name'
          })
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, preferredEmailColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'Affirmed',
          condition: entityType === 'PersonEmail',
          getAssertion: (): { value: ManualEditRowItem['assertion']; label: string } => ({
            value: entityType,
            label: 'preferred email'
          })
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, companyColumn, jobTitleColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'PersonJob',
          getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) => {
            const hasNotCurrentJobKey = !!propValues.find(({ key }) => key === 'LikelyNotCurrentAsOf')

            const assertionOpts: { value: ManualEditRowItem['assertion']; label: string; condition: boolean }[] = [
              {
                value: 'Affiliated',
                label: 'affiliated',
                condition: sourceTally > 0 && !hasNotCurrentJobKey
              },
              {
                value: 'NoLongerAffiliated',
                label: 'no longer affiliated',
                condition: sourceTally > 0 && !!hasNotCurrentJobKey
              },
              {
                value: 'NeverAffiliated',
                label: 'never worked here',
                condition: sourceTally < 0
              }
            ]

            return assertionOpts.find(({ condition }) => !!condition)
          }
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, preferredPhoneColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'PersonPhone',
          getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) => {
            const isCurrentPhone = !!propValues.find(({ key }) => key === 'CurrentAsOf')

            const assertionOpts: { value: ManualEditRowItem['assertion']; label: string; condition: boolean }[] = [
              { value: 'CurrentPersonPhone', label: 'current', condition: isCurrentPhone && sourceTally > 0 },
              { value: 'InvalidPersonPhone', label: 'invalid', condition: !isCurrentPhone && sourceTally < 0 },
              {
                value: 'NotCurrentPersonPhone',
                label: 'not current',
                condition: !isCurrentPhone && sourceTally > 0
              }
            ]
            return assertionOpts.find(({ condition }) => !!condition)
          }
        },
        {
          columns: [token1Column, assertionColumn, token2Column, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'PersonTuple',
          getAssertion: ({ sourceTally }: { sourceTally: ManualEditType['sourceTally']; propValues: ManualEditType['propValues'] }) => {
            const assertionOpts: { value: ManualEditRowItem['assertion']; label: string; condition: boolean }[] = [
              {
                value: 'Split',
                label: invalidReasonMap.Split,
                condition: sourceTally < 0
              },
              {
                value: 'Merge',
                label: invalidReasonMap.Merge,
                condition: sourceTally > 0
              }
            ]

            return assertionOpts.find(({ condition }) => !!condition)
          }
        }
      ].find(({ condition }) => !!condition),
    [entityType]
  ) || {}

  const url =
    teamContextValue.teamNumber && entityType
      ? mergeUrlWithParams('/teams/extractents', {
          TeamNumber: `${teamContextValue.teamNumber}`,
          Entity: entityType,
          JustThisContributorKey: contributor,
          AssertionFilter: assertionFilter
        })
      : null

  return (
    <UserSettings endpoint="/usersettings/personmanualeditsfilters" setInitial={props.setInitial}>
      <GridPageFrame
        stickFilters
        loading={typeof props.total !== 'number'}
        filterHeight={100}
        gridTitle="People"
        searchPlaceholder="Search for manual edit"
        filters={props.filters}
        disabledSearch={props.disabledSearch}
        heading={<></>}
        component={
          <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof PManualEditsList>, 'columns' | 'getAssertion'> } }>
            url={url}
            infinite
            list
            keepMounted
            search
            autoHideOnScroll
            addprops={{ columns, getAssertion }}
            pageSize={+(rowsPerPage || '10')}
            onLoading={props.onLoading}
            component={PManualEditsList}
            updatePageSize={props.onPageSizeChange}
            empty="No edits found"
            emptySubtitle="There are no person related manual edits"
            id="people_manual_edits"
          />
        }
      />
    </UserSettings>
  )
}

export default PeopleManualEdits
