import { useEffect } from 'react'

import useSessionStorage from '_core/hooks/useSessionStorage'

import { get } from 'utils/httpUtils'

const useCreateAccount = (isLoggedIn: boolean) => {
  const [loginEntry, setLoginEntry] = useSessionStorage('login_entry', false)

  useEffect(() => {
    if (!loginEntry && isLoggedIn) {
      get(`/../login-azuread`).then(() => {
        setLoginEntry(true)
      })
    }
  }, [loginEntry, isLoggedIn])
}

export default useCreateAccount
