import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '24px'
    }
  },
  title: {
    color: theme.palette.text.hint
  }
}))

export const TableHeader = ({ title }: { title: string }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <Typography variant="h4" semiBold className={classes.title}>
        {title}
      </Typography>
    </Box>
  )
}
