import React from 'react'

import { EventMessage, EventType, InteractionType } from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'

import { closeDialog, signInO365 } from 'office-apis-helpers'

declare global {
  interface Window {
    backupHistoryFunctions: any
    Office: any
  }
}

const dialog = window.location.search.indexOf('isDialog') > -1

const Outlook = () => {
  const frame = React.useRef(null)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [token, setToken] = React.useState<string>('')
  const { instance, accounts } = useMsal()
  const [url, setUrl] = React.useState<string>('/teams')
  const { login, error } = useMsalAuthentication(InteractionType.Silent)

  React.useEffect(() => {
    // if (window.location.href.indexOf('/outlook') > -1) {
    window.backupHistoryFunctions = {}
    window.backupHistoryFunctions.pushState = window.history.pushState
    window.backupHistoryFunctions.replaceState = window.history.replaceState

    const script = document.createElement('script')
    const body = document.getElementsByTagName('body')[0]
    script.src = '//appsforoffice.microsoft.com/lib/1.1/hosted/office.debug.js'
    body.appendChild(script)
    script.addEventListener('load', () => {
      Office.initialize = () => {
        setLoaded(true)
      }

      window.history.pushState = window.backupHistoryFunctions.pushState
      window.history.replaceState = window.backupHistoryFunctions.replaceState

      function loadProfile(item: any) {
        const lastAccountId = localStorage.getItem('lastAccountId')
        const profileUrl =
          '/people/' +
          encodeURIComponent(lastAccountId && item.from.emailAddress === lastAccountId ? item.to[0].emailAddress : item.from.emailAddress)
        if (item && item.from && item.from.emailAddress) {
          setUrl(profileUrl)
        }
      }

      function selectedMailItemChanged() {
        if (Office.context.mailbox.item != null) {
          loadProfile(Office.context.mailbox.item)
        } else {
          Office.context.mailbox.removeHandlerAsync(
            Office.EventType.ItemChanged,
            {
              handler: selectedMailItemChanged
            } as Office.AsyncContextOptions,
            function () {
              Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, selectedMailItemChanged)
            }
          )
        }
      }

      Office.onReady(function () {
        if (Office.context.mailbox) {
          Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, selectedMailItemChanged)
          if (Office.context.mailbox.item) {
            loadProfile(Office.context.mailbox.item)
          }
        }
      })
    })
    // }
  }, [])

  React.useEffect(() => {
    if (!loaded) return

    const auth = async () => {
      instance.addEventCallback((message: EventMessage) => {
        // Update UI or interact with EventMessage here
        console.log(dialog, 'OUTLOOK.TSX', message)

        if (message.eventType === EventType.LOGIN_SUCCESS) {
          Office.context.ui.messageParent(JSON.stringify({ status: 'success', result: (message.payload as any).accessToken }))
        }
      })

      instance.handleRedirectPromise().then((response) => {
        console.log('REDIRECT PROMISSE', response)
      })

      if (!dialog && accounts.length === 0) {
        await signInO365(
          '/outlook/login',
          (x: any) => console.log(x),
          (x: any) => setToken(x),
          (x: any) => console.log('ERROR', x)
        )
      } else {
        if (error) {
          login(InteractionType.Redirect)
        }
      }
    }
    auth()
  }, [loaded, token])

  React.useEffect(() => {
    if (accounts.length > 0 && window.location.search.indexOf('isDialog') === -1) {
      localStorage.setItem('lastAccountId', accounts?.[0]?.username || '')
      const iframe = frame.current as any
      iframe.contentWindow.postMessage(`url:${url}`, iframe.src)
    } else {
      closeDialog()
    }
  }, [accounts])

  React.useEffect(() => {
    const iframe = frame.current as any
    if (iframe) iframe.contentWindow.postMessage(`url:${url}`, iframe.src)
  }, [frame, url])

  if (!token && accounts.length === 0) return <div>Use the popup dialog to authenticate</div>

  return (
    <>
      URL: {url}
      iframe
      <iframe src={url} ref={frame} style={{ width: '100%', height: '100vh', border: 0 }} />
    </>
  )
}

export default Outlook
