import { useCallback } from 'react'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

import Combobox, { ChipsPickList } from '_shared/forms/Combobox'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useGeneralLookUp, GeneralFieldType } from '_core/hooks/useLookup'

export type SimilarFieldType = 'industries' | 'countries' | 'jobTitles' | 'jobTitleRoles' | 'jobTitleSubroles'

const SimilarFieldPicker = ({
  disabled,
  value,
  loadOnFocus,
  field,
  label,
  icon,
  placeholder,
  handleDelete,
  handleChange
}: {
  disabled: boolean
  value?: string[]
  loadOnFocus?: boolean
  field: SimilarFieldType
  label: string
  icon: [IconPrefix, IconName]
  placeholder: string
  handleDelete: (value: string, fieldName: SimilarFieldType) => void
  handleChange: (v: string[], fieldName: SimilarFieldType) => void
}) => {
  const { lookUpGeneral, forceAbort } = useGeneralLookUp()

  const fieldNamesMap: { [key in typeof field]: GeneralFieldType } = {
    industries: 'Industry',
    countries: 'Country',
    jobTitles: 'Title',
    jobTitleRoles: 'Role',
    jobTitleSubroles: 'SubRole'
  }

  const lookUpFieldType = fieldNamesMap[field]

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox<
    string,
    true
  >({
    loadOnFocus: !!loadOnFocus,
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpGeneral(lookUpFieldType, searchTerm)
        if (result) {
          return result.map(({ name }) => name)
        }
      },
      [lookUpFieldType, lookUpGeneral]
    ),
    forceAbort
  })

  const handleValueChange = (e: React.SyntheticEvent<Element, Event>, v: string[]) => {
    handleChange(v, field)
  }

  return (
    <>
      <Combobox<string, true>
        multiple
        forcePopupIcon={!!loadOnFocus}
        label={label}
        placeholder={placeholder}
        icon={icon}
        inputValue={inputValue}
        open={open}
        loading={optionsLoading}
        options={options}
        disabled={disabled}
        value={value || []}
        onChange={handleValueChange}
        onClose={handleClose}
        onOpen={handleOpen}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
      />
      <ChipsPickList items={value?.map((name) => ({ label: name, handleDelete: () => handleDelete(name, field) })) || []} disabled={disabled} />
    </>
  )
}

export default SimilarFieldPicker
