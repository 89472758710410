import React, { forwardRef } from 'react'

import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'

import { ellipsis } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  bold: {
    fontWeight: 700
  },
  semiBold: {
    fontWeight: 600
  },
  regular: {
    fontWeight: 400
  },
  ellipsis: ellipsis
}))

export type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'subtitle1' | 'caption' | 'subtext'
type TypographyProps<C> = Modify<MuiTypographyProps, { variant?: Variant; component?: C }> & {
  bold?: boolean
  semiBold?: boolean
  ellipsis?: boolean
}

const Typography = forwardRef(<C extends React.ElementType>(p: TypographyProps<C>, ref: any) => {
  const { bold, semiBold, className, variant, ellipsis, color, ...props } = p
  const { classes, cx } = useStyles()

  return (
    <MuiTypography
      ref={ref}
      className={cx(
        {
          [classes.regular]: !bold && !semiBold,
          [classes.bold]: bold,
          [classes.semiBold]: semiBold,
          [classes.ellipsis]: ellipsis
        },
        className
      )}
      color={color || 'text.primary'}
      variant={variant}
      {...props}
    >
      {props.children}
    </MuiTypography>
  )
})

export default Typography
