import { useState, ChangeEvent, useEffect } from 'react'

import { Box } from '@mui/material'

import { IconButton } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import TextField from '_shared/forms/TextField'
import Popover from '_shared/Popover'
import Typography from '_shared/Typography'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const SettingsPopover = ({ text }: { text: string }) => {
  const {
    classes: { icon, popover }
  } = useStyles()

  return (
    <Popover
      placement="bottom"
      className={popover}
      maxWidth={400}
      triggerElement={<IconButton classes={{ root: icon }} icon={['far', 'question-circle']} />}
    >
      <Box p={2}>
        <Typography>{text}</Typography>
      </Box>
    </Popover>
  )
}

type SettingsData = {
  blockNonBizPersonPush: boolean
  ensurePushPersonNames: boolean
  minP2pScore: number | string
  pushOnlyIfRelevant: boolean
  relevantAnyMsgDaysAgo: number | string
  relevantInteractionDaysAgo: number | string
}

type DataProcessingSettingsProps = {
  loading: boolean
  items?: SettingsData
}

const DataProcessingSettings = (props: DataProcessingSettingsProps) => {
  const [settingsData, setSettingsData] = useState<SettingsData>()
  const {
    classes: { field, filtersContent, subItem, tiny, subItemWrapper, itemWrapper }
  } = useStyles()

  useEffect(() => {
    if (!settingsData && props.items) {
      setSettingsData(props.items)
    }
  }, [props.items, settingsData])

  const { isSaving, save: saveSettings } = useSaveSettings()

  const save = (action: SaveAction) => {
    saveSettings('/adminsettings/push', settingsData, action, 'Filters for admin data processing')
  }

  /*  const handleSharePersonalContacts = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, blockNonBizPersonPush: e.target.checked } : prevState))
  }*/

  const handleIsFiltersAppliedToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, pushOnlyIfRelevant: e.target.checked } : prevState))
  }

  const handleMinScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, minP2pScore: e.target.value ? +e.target.value : '' } : prevState))
  }

  const handleNoReciprocityDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, relevantAnyMsgDaysAgo: e.target.value ? +e.target.value : '' } : prevState))
  }

  const handleRelationshipDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, relevantInteractionDaysAgo: e.target.value ? +e.target.value : '' } : prevState))
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      {settingsData ? (
        <Box width="100%">
          {/*   <Box display="flex" alignItems="center">
            <Checkbox
              checked={settingsData.blockNonBizPersonPush}
              name="share_personal_contacts_enabled"
              onChange={handleSharePersonalContacts}
              label="Don't share personal contacts with team"
            />
            <SettingsPopover text="If a contact only has a personal email address, their information will not be made available at the team level." />
          </Box>*/}
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={settingsData.pushOnlyIfRelevant}
              name="filters_enabled"
              onChange={handleIsFiltersAppliedToggle}
              label="Apply filters to what individual contributors push to the team composite"
            />
            <SettingsPopover
              text="Push filter settings - data gathered for a contributor is analyzed in an area specific to that contributor and then
                pushed to the team area to create a composite across all contributors. These settings allow some filtration on what data is pushed,
                with an eye toward reducing noise at the team composite level."
            />
          </Box>
          <Box marginLeft={4} className={filtersContent}>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">Stale relationship (in days)</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData.pushOnlyIfRelevant}
                  inputProps={{
                    className: tiny
                  }}
                  classes={{ root: field }}
                  type="number"
                  value={settingsData.relevantInteractionDaysAgo}
                  onChange={handleRelationshipDurationChange}
                />
                <SettingsPopover
                  text="Stale relationship (in days) - the number of days of no interactions before a person is removed from the team view.
                    Imagine you've had some back-and-forth interactions with a person, but the last of those was over 3 years ago. After that time
                    of no interaction, that person will be removed from view."
                />
              </Box>
            </Box>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">No reciprocity (in days)</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData.pushOnlyIfRelevant}
                  classes={{ root: field }}
                  inputProps={{
                    className: tiny
                  }}
                  type="number"
                  value={settingsData.relevantAnyMsgDaysAgo}
                  onChange={handleNoReciprocityDurationChange}
                />
                <SettingsPopover
                  text="No reciprocity (in days) – the number of days after which a person with whom there has been no reciprocity
              (i.e., either they send an email that was not replied to, or a contributor send them an email and they did not respond to it),
              is removed from the team level view. Imagine a person sending a contributor a marketing or sales email that the contributor has
              never responded to. If no subsequent interaction has happened, that person will be removed from the team view after one year."
                />
              </Box>
            </Box>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">Minimum score setting</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData?.pushOnlyIfRelevant}
                  classes={{ root: field }}
                  inputProps={{
                    className: tiny
                  }}
                  type="number"
                  value={settingsData?.minP2pScore}
                  onChange={handleMinScoreChange}
                />
                <SettingsPopover text="Minimum score setting - the minimum scored relationship that should be pushed to the team composite." />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <p>Data processing settings were not found</p>
      )}
    </Settings>
  )
}

export default DataProcessingSettings
