import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import 'abortcontroller-polyfill'

import { updateQuerystring, parseUrl } from '_core/helpers/browser'

import { request } from 'utils/fetchUtils'

const useSearchEndpoints = (urls?: any[], search?: string) => {
  const [result, setResult] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [append, setAppend] = useState<boolean>(false)
  const [skip, setSkip] = useState<number | string>()
  const location = useLocation()
  const { page, rowsPerPage }: any = parseUrl(location.search)
  const keyword = new URLSearchParams(location.search).get('keyword')

  const more = () => {
    setAppend(true)
    setSkip(result?.skip_token ? result.skip_token : result?.page_end || 0)
  }

  const reload = () => {
    setSkip(undefined)
    setAppend(false)
  }

  const updateUrl = (url: string) => {
    let endpoint = url
    if (!skip && page && rowsPerPage) {
      endpoint = updateQuerystring(url, 'Take', page * rowsPerPage)
    }
    if (search) endpoint = updateQuerystring(endpoint, 'SearchTerm', search || undefined)
    if (append && result) endpoint = updateQuerystring(endpoint, typeof skip === 'number' ? 'Skip' : 'SkipToken', skip)
    else {
      setLoading(true)
      setResult(null)
    }
    return endpoint
  }

  useEffect(() => {
    reload()
  }, [urls, search])

  useEffect(() => {
    let requestActive = true
    const abortController = new AbortController()

    if (urls) {
      const requests = urls.map((endpoint: any) =>
        request<any>(updateUrl(endpoint.url), { signal: abortController.signal }).then((resp) => {
          return (endpoint.result = resp)
        })
      )

      Promise.all(requests).then(() => {
        if (requestActive) {
          const r = urls
            .map((t: any) => (!t.merge ? { [t.key]: t.result } : (t.single && t.result.data?.[0]) || t.result))
            .reduce(
              (acc, cur) => {
                const data = typeof acc.data !== 'undefined' ? acc.data.concat(cur.data) : cur.data
                const total_item_count =
                  typeof cur.total_item_count === 'number' ? (acc.total_item_count || 0) + cur.total_item_count : cur.total_item_count
                const result = { ...acc, ...cur, data, total_item_count }
                return result
              },
              { data: [], total_item_count: 0, page_end: 0, are_more: false }
            )

          const page_end = urls
            .map((t: any) => (t.result && t.result.page_end ? t.result.page_end : 0))
            .reduce((prev, current) => (current > prev ? current : prev), 0)

          setResult({
            data: append && result.data?.[0]?.data ? [{ ...r, data: [...result.data[0].data, ...r.data] }] : [r],
            total_item_count: r.total_item_count,
            page_end,
            are_more: !!urls.find((t) => t.result.are_more)
          })
          setLoading(false)
        } else {
          return null
        }
      })
    }

    return () => {
      requestActive = false
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urls, append, skip, search])

  return {
    result,
    loading: loading || (typeof search === 'string' && search !== (keyword || '')),
    more,
    reload,
    append,
    setAppend
  }
}

export default useSearchEndpoints
