import React, { useState } from 'react'
import { Box, Popover } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
    popover: {
      pointerEvents: 'none',
      marginTop: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(1)
    }
  })
)

interface PopoverTooltipWrapperParams {
  children: any;
  tooltipText?: string;
  show?: boolean;
}

export function PopoverTooltipWrapper({ children, tooltipText = 'Evidence not available', show = true }: PopoverTooltipWrapperParams) {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (<div>
    <Box
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup='true'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {children}
      {show === true &&
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        <Box py={'10px'} px={2}>
          <Typography>{tooltipText}</Typography>
        </Box>
      </Popover>
      }
    </Box>
  </div>)
}