import React from 'react'

import { Box } from '@mui/material'

interface ContactTableHeaderProps {
  icon?: string
  name: string
  email?: string
  iconComponent?: JSX.Element
}

const ContactTableHeader = ({ icon, name, email, iconComponent }: ContactTableHeaderProps) => {
  return (
    <>
      <Box flexDirection={'row'} display="flex">
        <Box display="flex" alignItems="center">
          {!!icon && <img src={icon} alt="" />}
          {!!iconComponent && iconComponent}
        </Box>
        <Box marginLeft={1} style={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
          {name}
        </Box>
        {!!email && (
          <>
            <Box mx={1}>&middot;</Box>
            <Box>{email}</Box>
          </>
        )}
      </Box>
    </>
  )
}

export default ContactTableHeader
