import { useState, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

export enum ScrollDirection {
  up = 'up',
  down = 'down'
}

const THRESHOLD = 6

const useScrollDirection = (active: boolean) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.up)
  const { pathname } = useLocation()

  const blocking = useRef(false)
  const prevScrollY = useRef(0)

  useEffect(() => {
    setScrollDirection(ScrollDirection.up)
  }, [pathname])

  useEffect(() => {
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection = scrollY > prevScrollY.current ? ScrollDirection.down : ScrollDirection.up
        setScrollDirection(newScrollDirection)
        prevScrollY.current = scrollY > 0 ? scrollY : 0
      }
      prevScrollY.current = window.pageYOffset
      blocking.current = false
    }

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true
        window.requestAnimationFrame(updateScrollDirection)
      }
    }

    if (active) {
      window.addEventListener('wheel', onScroll)
      window.addEventListener('touchmove', onScroll)
    }

    return () => {
      window.removeEventListener('wheel', onScroll)
      window.removeEventListener('touchmove', onScroll)
    }
  }, [scrollDirection, active])

  return scrollDirection
}

export default useScrollDirection
