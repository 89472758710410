import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type ActivitiesVisibilityLevel = 'Off' | 'AdminsOnly' | 'AllUsers'
export type ActivitiesVisibility = {
  statsWidgetVisibility: ActivitiesVisibilityLevel
  detailedActivityFromStatsWidgetVisibility: ActivitiesVisibilityLevel
  activityTabVisibility: ActivitiesVisibilityLevel
}
export type ActivitiesUserAccess = 'isStatsWidgetVisible' | 'isDetailedActivityFromStatsWidgetVisible' | 'isActivityTabVisible'

export type ActivitiesAccessData = [ActivitiesVisibility, ProfileType]

const useActivitiesAccess = () => {
  const { fetchWithAbort, result, setResult } = useAbortableFetch<ActivitiesAccessData>()
  const [visibility, profile] = result || []

  const checkWhetherVisible = (visibilityLevel: ActivitiesVisibilityLevel) => {
    if (visibilityLevel === 'Off') {
      return false
    }
    return !!(visibilityLevel === 'AllUsers' || (visibilityLevel === 'AdminsOnly' && profile?.IsAdmin))
  }

  const userAccess = visibility
    ? {
        isStatsWidgetVisible: checkWhetherVisible(visibility.statsWidgetVisibility),
        isDetailedActivityFromStatsWidgetVisible: checkWhetherVisible(visibility.detailedActivityFromStatsWidgetVisibility),
        isActivityTabVisible: checkWhetherVisible(visibility.activityTabVisibility)
      }
    : visibility

  const getUserActivitiesAccess = () => {
    return fetchWithAbort([{ url: '/usersettings/activities' }, { url: '/me/profile' }])
  }

  const updateUserActivitiesAccess = (activitiesAccessUpdateData: ActivitiesAccessData) => {
    setResult(activitiesAccessUpdateData)
  }

  return {
    activitiesAccessResult: result,
    userActivitiesAccess: userAccess,
    getUserActivitiesAccess,
    updateUserActivitiesAccess
  }
}

export default useActivitiesAccess
