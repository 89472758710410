import React, { ReactElement, SyntheticEvent, forwardRef, useEffect, useRef, useState } from 'react'

import { VariableSizeGrid as Grid, VariableSizeGridProps, VariableSizeList as List, VariableSizeListProps } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import IconButton from '_shared/buttons/Icon'

const defaultEasing = (delta: number) => {
  return delta
}

export const easeInOutCubic = (t: number) => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'inline-flex'
  },
  arrowButton: {
    zIndex: 1,
    display: 'flex',
    '&:hover': {
      background: 'unset'
    }
  },
  prevButton: {
    alignItems: 'center',
    marginRight: '-39px',
    background: 'linear-gradient(-270deg, rgb(255, 255, 255) 8.36%, rgba(255, 255, 255, 0.74) 51.67%, rgba(255, 255, 255, 0) 100%)'
  },
  nextButton: {
    alignItems: 'center',
    marginLeft: '-39px',
    background: 'linear-gradient(270deg, rgb(255, 255, 255) 8.36%, rgba(255, 255, 255, 0.74) 51.67%, rgba(255, 255, 255, 0) 100%)'
  },
  invisible: {
    visibility: 'hidden'
  },
  noClick: {
    pointerEvents: 'none'
  }
}))

export const PrevButton = ({ onClick, className }: { onClick: (e: SyntheticEvent) => void; className?: string }) => {
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.prevButton, classes.arrowButton, className)}>
      <IconButton onClick={onClick} icon={['far', 'chevron-left']} size="small" />
    </div>
  )
}

export const NextButton = ({ onClick, className }: { onClick: (e: SyntheticEvent) => void; className?: string }) => {
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.nextButton, classes.arrowButton, className)}>
      <IconButton onClick={onClick} icon={['far', 'chevron-right']} size="small" />
    </div>
  )
}

const VirtalizedListCarousel = forwardRef(
  (
    props: {
      arrowsVisibility?: 'always' | 'none' | 'auto'
      slideIndex: number | undefined
      duration: number
      easing?: typeof defaultEasing
      slidesToShow: number
      itemCount: number
      prevArrow: ReactElement
      nextArrow: ReactElement
      getColumnSize: (index: number, total: number) => number
    } & Modify<VariableSizeListProps, { width: number; layout: 'horizontal'; itemSize?: never }>,
    ref: any
  ) => {
    const { classes, cx } = useStyles()
    const {
      duration = 500,
      easing = defaultEasing,
      arrowsVisibility = 'auto',
      slideIndex,
      width,
      slidesToShow,
      prevArrow,
      nextArrow,
      itemCount,
      getColumnSize,
      ...listProps
    } = props

    const [scrollOffsetInitial, setScrollOffsetInitial] = useState(0)

    const roundedUpSlidesToShow = Math.floor(slidesToShow)
    const scrollOffsetFinal = typeof slideIndex === 'number' ? slideIndex * getColumnSize(slideIndex, itemCount) : 0

    const isAnimating = scrollOffsetInitial !== scrollOffsetFinal

    const animate = (animationStartTime: number, scrollOffsetFinal: number, scrollOffsetInitial: number) => {
      requestAnimationFrame(() => {
        const now = performance.now()

        const ellapsed = now - animationStartTime
        const scrollDelta = scrollOffsetFinal - scrollOffsetInitial
        const easedTime = easing(Math.min(1, ellapsed / duration))
        const scrollOffset = scrollOffsetInitial + scrollDelta * easedTime

        ref.current?.scrollTo(scrollOffset)

        if (ellapsed < duration) {
          animate(animationStartTime, scrollOffsetFinal, scrollOffsetInitial)
        } else {
          setScrollOffsetInitial(scrollOffsetFinal)
        }
      })
    }

    const initAnimation = (scrollOffsetFinal: number) => {
      if (typeof slideIndex === 'number') {
        animate(performance.now(), scrollOffsetFinal, scrollOffsetInitial)
      }
    }

    useEffect(() => {
      if (typeof slideIndex === 'number') {
        initAnimation(scrollOffsetFinal)
      }
    }, [scrollOffsetFinal, slideIndex])

    const itemSize = (index: number) => {
      return getColumnSize(index, itemCount)
    }

    return (
      <div className={classes.wrapper}>
        {arrowsVisibility !== 'none' && roundedUpSlidesToShow < itemCount ? (
          <div
            className={cx(classes.arrowButton, {
              [classes.noClick]: isAnimating,
              [classes.invisible]: arrowsVisibility !== 'always' && (slideIndex || 0) === 0
            })}
          >
            {prevArrow}
          </div>
        ) : (
          <></>
        )}
        <List {...listProps} itemCount={itemCount} width={width} itemSize={itemSize} ref={ref} />
        {arrowsVisibility !== 'none' && roundedUpSlidesToShow < itemCount ? (
          <div
            className={cx(classes.arrowButton, {
              [classes.noClick]: isAnimating,
              [classes.invisible]: arrowsVisibility !== 'always' && (slideIndex || 0) === itemCount - roundedUpSlidesToShow
            })}
          >
            {nextArrow}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }
)

const VirtalizedGridCarousel = forwardRef(
  (
    props: {
      slideIndex: number | undefined
      duration: number
      easing?: typeof defaultEasing
      slidesToShow: number
      prevArrow?: ReactElement
      nextArrow?: ReactElement
      columnCount: number
      arrowsVisibility?: 'always' | 'none' | 'auto'
      getColumnSize: (index: number, total: number) => number
      scrollOffsetInitial?: number
    } & Modify<VariableSizeGridProps, { columnWidth?: never }>,
    ref: any
  ) => {
    const {
      duration = 500,
      easing = defaultEasing,
      arrowsVisibility = 'auto',
      slideIndex,
      width,
      slidesToShow,
      prevArrow,
      nextArrow,
      columnCount,
      getColumnSize,
      ...gridProps
    } = props

    const { classes, cx } = useStyles()

    const [scrollOffsetInitial, setScrollOffsetInitial] = useState(0)
    const scrollOffsetFinal = typeof slideIndex === 'number' ? slideIndex * getColumnSize(slideIndex, columnCount) : 0

    const isAnimating = scrollOffsetInitial !== scrollOffsetFinal
    const roundedUpSlidesToShow = Math.floor(slidesToShow)

    const animate = (animationStartTime: number, scrollOffsetFinal: number, scrollOffsetInitial: number) => {
      requestAnimationFrame(() => {
        const now = performance.now()

        const ellapsed = now - animationStartTime
        const scrollDelta = scrollOffsetFinal - scrollOffsetInitial
        const easedTime = easing(Math.min(1, ellapsed / duration))
        const scrollOffset = scrollOffsetInitial + scrollDelta * easedTime

        ref.current?.scrollTo({
          scrollLeft: scrollOffset
        })

        if (ellapsed < duration) {
          animate(animationStartTime, scrollOffsetFinal, scrollOffsetInitial)
        } else {
          setScrollOffsetInitial(scrollOffsetFinal)
        }
      })
    }

    const initAnimation = (scrollOffsetFinal: number) => {
      if (typeof slideIndex === 'number') {
        animate(performance.now(), scrollOffsetFinal, scrollOffsetInitial)
      }
    }

    useEffect(() => {
      if (typeof slideIndex === 'number') {
        initAnimation(scrollOffsetFinal)
      }
    }, [slideIndex, scrollOffsetFinal])

    const columnWidth = (index: number) => {
      return getColumnSize(index, columnCount)
    }

    return (
      <div className={classes.wrapper}>
        {arrowsVisibility !== 'none' && roundedUpSlidesToShow < columnCount ? (
          <div
            className={cx(classes.arrowButton, {
              [classes.noClick]: isAnimating,
              [classes.invisible]: arrowsVisibility !== 'always' && (slideIndex || 0) === 0
            })}
          >
            {prevArrow}
          </div>
        ) : (
          <></>
        )}
        <Grid {...gridProps} key={columnCount} columnCount={columnCount} width={width} columnWidth={columnWidth} ref={ref} />
        {arrowsVisibility !== 'none' && roundedUpSlidesToShow < columnCount ? (
          <div
            className={cx(classes.arrowButton, {
              [classes.noClick]: isAnimating,
              [classes.invisible]: arrowsVisibility !== 'always' && (slideIndex || 0) === columnCount - roundedUpSlidesToShow
            })}
          >
            {nextArrow}
          </div>
        ) : (
          <></>
        )}{' '}
      </div>
    )
  }
)

export { VirtalizedListCarousel, VirtalizedGridCarousel }
