import React, { useContext, useEffect } from 'react'

import { Box } from '@mui/material'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import LinkedInDataUpload from '_core/components/LinkedInDataUpload'
import Topbar from '_core/components/Topbar'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const UploadDataSourcesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Import LinkedIn connections')
  }, [])

  return (
    <Page variant="core">
      <Narrow>
        <Topbar />
        <Box pb={7.25}>
          <LinkedInDataUpload />
        </Box>
      </Narrow>
    </Page>
  )
}

export default UploadDataSourcesPage
