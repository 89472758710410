import * as _ from 'lodash'

export const apiHost =
  process.env.NODE_ENV === 'development' && process.env.REACT_APP_DOTALIGN_API_HOST && window.location.protocol !== 'https:'
    ? process.env.REACT_APP_DOTALIGN_API_HOST
    : ''

const localSettings = {
  settingsApi: `${apiHost}${(window as any)?.GlobalConfig?.SettingsApi || '/api/me/settings'}`,
  apiPrefix: `${apiHost}${(window as any)?.GlobalConfig?.Api || `/api`}`
}

const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode

export default _.memoize(async function () {
  const result = await fetch(localSettings.settingsApi)
    .then((data: any) => data.json())
    .catch((error) => {
      const errorObj = {
        requestInfo: `settingsApi`,
        error: error
      }
      throw errorObj
    })

  if (!result) {
    window.location.href = '/maintenance.html'
    return null
  } else {
    return {
      tenantId: result.TenantId,
      resourceId: result.ClientId,
      clientId: result.ClientId,
      apiPrefix: localSettings.apiPrefix,
      msalConfiguration: {
        auth: {
          clientId: result.ClientId,
          authority: `https://login.microsoftonline.com/${result.TenantId}`,
          redirectUri: `${window.location.origin}/aad-auth`,
          navigateToLoginRequestUrl: false
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        },
        system: {
          allowNativeBroker: false
        }
      }
    }
  }
})
