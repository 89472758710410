import React, { useCallback, useState, useEffect, useContext, MouseEvent, HTMLAttributes } from 'react'

import { Alert, Box, RadioGroup, Link } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useForm, useWatch, Controller, Control, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import IconButton from '_shared/buttons/Icon'
import Combobox from '_shared/forms/Combobox'
import Radio from '_shared/forms/Radio'
import Select from '_shared/forms/Select'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import NextStepFrame from '_core/components/NextStepFrame'
import ProfileItem from '_core/components/ProfileItem'
import Success from '_core/components/Success'
import { Dragzone } from '_core/components/upload/UploadingContent'
import { FILE_UPLOADING_STATUS, UploadingProgressBar } from '_core/components/upload/UploadingProgressBar'
import Widget from '_core/components/Widget'

import useAdmin from '_core/hooks/useAdmin'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import { useLookUpContributors } from '_core/hooks/useLookup'
import useSidebar from '_core/hooks/useSidebar'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { postRaw } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  contributor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  profileItem: {
    padding: 0
  },
  controller: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box'
  },
  confirmText: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  hide: {
    display: 'none'
  },
  selectPopover: {
    maxHeight: 200
  }
}))

async function sendFile(userKey: string, file: any, callback: (response: any) => void) {
  const formData = new FormData()
  formData.append('connectionsCsv', file, file.name)

  return postRaw(`/users/${userKey}/linkedin`, formData)
    .then(callback)
    .catch((error) => {
      throw error
    })
}

type DataSourceUploadProps = {
  submit: (callback: () => void) => void
  back?: () => void
  behalfOf: BehalfOf
}

const DataSourceUpload = ({ submit, back, behalfOf }: DataSourceUploadProps) => {
  const { classes, cx } = useStyles()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<FILE_UPLOADING_STATUS>()
  const [error, setError] = useState<string>('')
  const [confirm, setConfirm] = useState<boolean>(behalfOf === 'me')

  const {
    control,
    setValue,
    formState: { isDirty }
  } = useForm<FormValues>({ mode: 'onChange' })

  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const UserKey = userKeyResult?.results.UserKey

  const teamNum = useWatch({ control, name: 'teamNum' })
  const contributor = useWatch({ control, name: 'contributor' })

  const userKey = behalfOf === 'me' ? UserKey : contributor?.emailAddress

  const accessibleFormats = ['csv', 'xls', 'xlsx', 'zip']
  const disabledSubmit = !!error || !file || !userKey

  useEffect(() => {
    setValue('contributor', undefined)
    setFile(null)
  }, [teamNum])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(function (file: File) {
      setFile(file)
      setError('')

      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

      if (accessibleFormats.indexOf(fileExtension) === -1) {
        setUploadStatus(FILE_UPLOADING_STATUS.FILE_HAS_ERROR)
        setError(`The file type is wrong (${fileExtension}). Please upload CSV, XLS or LinkedIn ZIP file.`)
      } else {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          setUploadStatus(FILE_UPLOADING_STATUS.FILE_IS_READY_FOR_UPLOAD)
        }
        reader.readAsBinaryString(file)
      }
    })
  }, [])

  const dragZoneState = useDropzone({ onDrop })

  const uploadFile = () => {
    if (userKey && file) {
      setUploadStatus(FILE_UPLOADING_STATUS.FILE_IS_UPLOADING)
      return sendFile(userKey, file, () => {
        setFile(null)
        setUploadStatus(FILE_UPLOADING_STATUS.FILE_HAD_UPLOADED)
      })
    }
  }

  const handleSubmit = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault()
    setConfirm(true)
    if (file) {
      return submit(uploadFile)
    }
  }

  const handleBack = () => {
    if (back) {
      setConfirm(false)
      back()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <NextStepFrame
        next={
          <Button type="submit" variant="text" color="secondary" disabled={disabledSubmit}>
            {confirm ? 'Submit' : 'Next'}
          </Button>
        }
        back={
          back ? (
            <Button onClick={handleBack} variant="text" color="secondary">
              Back
            </Button>
          ) : null
        }
        isDirty={isDirty || !!file}
      >
        <Box className={cx(classes.controller, classes.confirmText, { [classes.hide]: !(confirm && behalfOf === 'contributor') })}>
          <Typography>Please confirm that you are uploading file </Typography>{' '}
          {file && (
            <Box my={1}>
              <UploadingProgressBar fileName={file.name} status={uploadStatus} errorMessage={error} />{' '}
            </Box>
          )}
          <Typography>on behalf of</Typography>&nbsp;<Typography semiBold>{contributor?.name}</Typography>{' '}
          <Typography semiBold>({userKey})</Typography>
        </Box>

        <Box className={cx({ [classes.hide]: confirm && behalfOf === 'contributor' })}>
          {behalfOf === 'contributor' ? <ContributorForm control={control} setValue={setValue} /> : null}
          <Box className={classes.controller}>
            <Dragzone uploadStatus={uploadStatus} dragZoneState={dragZoneState} file={file} error={error} accessibleFormats={accessibleFormats} />
          </Box>
        </Box>
      </NextStepFrame>
    </form>
  )
}

type BehalfOf = 'me' | 'contributor'

type Step = 'behalfOf' | 'uploadFile' | 'confirm' | 'success'

type ContributorFormProps = {
  control: Control<FormValues>
  setValue: UseFormReturn['setValue']
}

type FormValues = { teamNum?: number; contributor?: TeamMemberRes }

const ContributorForm = ({ control, setValue }: ContributorFormProps) => {
  const { classes, cx } = useStyles()
  const { teamContextValue } = useContext(TeamContext)

  const { result: teamResult } = useEntityEndpoint<{ results: TeamDataRes[] } | null>('/teams')
  const { lookupContributors, forceAbort } = useLookUpContributors()

  const loadOptions = useCallback(
    (searchTerm: string) => {
      return lookupContributors(searchTerm)
    },
    [lookupContributors]
  )

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const teamNum = useWatch({ control, name: 'teamNum' })
  const contributor = useWatch({ control, name: 'contributor' })

  const teams = teamResult?.results.map(({ number, name }) => ({ value: number, label: name }))

  const initialTeamNum = teams?.[0].value

  useEffect(() => {
    if (initialTeamNum) {
      setValue('teamNum', teamContextValue.teamNumber)
    }
  }, [initialTeamNum])

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: TeamMemberRes) => (
    <li {...props}>
      <ProfileItem name={value.name} userKey={value.emailAddress} byline={value.emailAddress} />
    </li>
  )

  const showCombobox = () => {
    setValue('contributor', undefined)
  }

  return (
    <>
      <Controller
        name="teamNum"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            label="Pick team"
            options={teams || []}
            disabled={!teams}
            value={value}
            onChange={onChange}
            className={classes.controller}
            MenuProps={{ classes: { paper: classes.selectPopover } }}
            fullWidth
          />
        )}
      />
      {!contributor && (
        <Controller
          name="contributor"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box className={classes.controller}>
              <Combobox<TeamMemberRes>
                open={open}
                inputValue={inputValue}
                options={options}
                loading={optionsLoading}
                disabled={!teamNum}
                label="Pick contributor"
                placeholder="Search for contributors"
                icon={['fas', 'industry']}
                value={value}
                onChange={(e, value) => onChange(value)}
                getOptionLabel={(option: TeamMemberRes) => option.name || ''}
                renderOption={renderOption}
                onInputChange={handleInputChange}
                onOpen={handleOpen}
                onClose={handleClose}
                onFocus={handleFocus}
                filterOptions={filterOptions}
              />
            </Box>
          )}
        />
      )}
      {contributor && (
        <Box className={cx(classes.contributor, classes.controller)}>
          <ProfileItem name={contributor.name} userKey={contributor.emailAddress} byline={contributor.emailAddress} className={classes.profileItem} />
          <IconButton color="primary" hint="Change contributor" icon={['far', 'times']} onClick={showCombobox} size="small" />
        </Box>
      )}
    </>
  )
}

const LinkedInDataUpload = () => {
  const [step, setStep] = useState<Step>('behalfOf')
  const { close } = useSidebar()
  const { updateParent } = useSidepanelPayloads()
  const { classes } = useStyles()

  const { control } = useForm<{ behalfOf: BehalfOf }>({
    defaultValues: { behalfOf: 'me' },
    mode: 'onChange'
  })

  const behalfOf = useWatch({ control, name: 'behalfOf' })

  const admin = useAdmin()
  const { result: uploadPermissionResult } = useEntityEndpoint<{ results: { uploadOnBehalfOf: LinkedInUploadPermission } } | null>(
    '/usersettings/linkedInUpload'
  )

  const { uploadOnBehalfOf: uploadPermission } = uploadPermissionResult?.results || {}

  const loading = typeof admin !== 'boolean' || !uploadPermission
  const uploadFileIsInitialStep = !loading && ((uploadPermission === 'Admins' && !admin) || uploadPermission === 'Off')

  useEffect(() => {
    if (uploadFileIsInitialStep) {
      setStep('uploadFile')
    }
  }, [uploadFileIsInitialStep])

  const switchStep = (newStep: Step) => {
    setStep(newStep)
  }

  const submitFileUpload = async (submit: () => void) => {
    if (behalfOf === 'me' || step === 'confirm') {
      await submit()
      updateParent({ action: 'RELOAD_LIST', value: ['data-sources', 'data-uploads'] })
      switchStep('success')
    } else {
      switchStep('confirm')
    }
  }

  return (
    <>
      <Widget>
        <Alert severity="info">
          <Typography>
            Click{' '}
            <Link
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.dotalign.com/article/0ntsibvt4n-upload-your-linked-in-connections"
            >
              here
            </Link>{' '}
            to learn more about downloading LinkedIn connections
          </Typography>
        </Alert>
      </Widget>

      {step === 'behalfOf' && (
        <form onSubmit={() => switchStep('uploadFile')}>
          <NextStepFrame
            back={null}
            next={
              <Button type="submit" variant="text" color="secondary">
                Next
              </Button>
            }
          >
            <Controller
              name="behalfOf"
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioGroup classes={{ root: classes.controller }} onChange={onChange} value={value}>
                  <Skeleton condition={loading} width="100%" height="40px">
                    <Radio value="me" label="Upload my connections" />
                  </Skeleton>
                  <Skeleton condition={loading} width="100%" height="40px">
                    <Radio value="contributor" label="Upload connections on behalf of someone else" />
                  </Skeleton>
                </RadioGroup>
              )}
            />
          </NextStepFrame>
        </form>
      )}

      {(step === 'uploadFile' || step === 'confirm') && behalfOf && (
        <DataSourceUpload
          behalfOf={behalfOf as BehalfOf}
          submit={submitFileUpload}
          {...(!uploadFileIsInitialStep ? { back: () => switchStep(step === 'confirm' ? 'uploadFile' : 'behalfOf') } : {})}
        />
      )}

      {step === 'success' && (
        <Success variant="centered" text="Your file successfully uploaded!">
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={4}>
            <Button variant="text" onClick={close} color="secondary" style={{ marginTop: 16 }}>
              Close
            </Button>
          </Box>
        </Success>
      )}
    </>
  )
}

export default LinkedInDataUpload
