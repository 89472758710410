import React, { useContext, useEffect, useState, Dispatch, SetStateAction, useCallback } from 'react'

import { Box, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import TeamMembersList from '_pages/teams/[id]/members'
import AdminSettingsWidget, { ContributorSettingsWidget } from '_pages/teams/[id]/settings'

import AddTeamMemberDialog, { AddTeamMemberDialogProps } from '_core/components/dialogs/AddTeamMember'
import { Narrow, Wide } from '_core/components/layout'
import GroupsWidget from '_core/components/team/GroupsWidget'
import OtherRolesWidget from '_core/components/team/OtherRolesWidget'
import TeamCard from '_core/components/team/TeamCard'
import MembersWidget from '_core/components/team/TeamMembersWidget'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import useOnboarding from '_core/hooks/useOnboarding'
import useTransformTeamData from '_core/hooks/useTransformTeamData'
import useUserMembership from '_core/hooks/useUserMembership'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  widgetsPanel: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('laptop')]: {
      paddingLeft: theme.spacing(4.5)
    }
  },
  content: {
    minHeight: 'calc(100vh - 25px)',
    backgroundColor: theme.palette.background.light
  }
}))

export const AddMember = ({ admin, team, reload, loading, setLoading }: Omit<AddTeamMemberDialogProps, 'isOpened' | 'close'>) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  const handleClose = () => {
    closeDialog()
    setLoading(false)
  }

  return (
    <>
      {admin && <AddTeamMemberDialog.TriggerEl disabled={loading} open={openDialog} />}
      <AddTeamMemberDialog team={team} isOpened={isDialogOpened} close={handleClose} reload={reload} loading={loading} setLoading={setLoading} />
    </>
  )
}

export type TeamProfileProps = {
  items?: { [key: string]: any }[]
  loading: boolean
  reload: boolean
  setReload: Dispatch<SetStateAction<boolean>>
  teamId: number
}

const TeamProfile = (props: TeamProfileProps) => {
  const [team, setTeam] = useState<Team>()
  const [loading, setLoading] = useState<boolean>(false)
  const [reloadContributors, setReloadContributors] = useState<boolean>(false)
  const admin = useAdmin()
  const { contributor, userRelations, userMembership } = useUserMembership(!props.loading ? props.teamId : 0)
  const { setMobileHeader } = useContext(LayoutContext)
  const { classes } = useStyles()

  const { teamInfo, groups, members, otherRoles } = !props.loading ? props : ({} as any)
  const { teamId, setReload } = props
  const { startOnboarding } = useOnboarding()
  const { transformTeam } = useTransformTeamData()

  useEffect(() => {
    if (admin && !props.loading) {
      startOnboarding(Paths._teamProfile)
    }
  }, [admin, props.loading])

  useEffect(() => {
    if (reloadContributors) {
      setReloadContributors(false)
    }
  }, [reloadContributors])

  useEffect(() => {
    if (teamInfo && !props.loading) {
      setTeam(transformTeam(teamInfo))
    }
  }, [teamInfo, props.loading])

  useEffect(() => {
    if (team?.name) {
      setMobileHeader(team.name)
    } else {
      setMobileHeader('')
    }
  }, [team])

  const handleChange = useCallback(() => setReload(true), [setReload])
  const handleContributorsChange = useCallback(() => setReloadContributors(true), [setReloadContributors])

  const WidgetsPanel = (
    <>
      {admin && <AdminSettingsWidget setTeam={setTeam} loading={props.loading || loading} team={team} />}
      {contributor && (
        <ContributorSettingsWidget
          team={team}
          userRelations={userRelations}
          userMembership={userMembership}
          loading={props.loading || loading}
          handleChange={handleContributorsChange}
        />
      )}
      <GroupsWidget team={team} admin={admin} items={groups} handleChange={handleChange} loading={props.loading || loading} />
      <OtherRolesWidget team={team} admin={admin} items={otherRoles} handleChange={handleChange} loading={props.loading || loading} />
    </>
  )

  return (
    <>
      <Wide>
        <Grid container style={{ minHeight: 'calc(100vh - 120px)' }}>
          <Grid item md={9} style={{ display: 'flex', flexDirection: 'column' }}>
            <TeamCard partial team={team} userRelations={userRelations} admin={admin} reload={handleChange} loading={props.loading || loading} />
            <Box mt={2} mb={2} style={{ flex: 1 }}>
              <TeamMembersList
                reload={reloadContributors || props.reload}
                admin={admin}
                team={team}
                handleChange={handleChange}
                setLoading={setLoading}
                loading={props.loading || loading}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <div className={classes.widgetsPanel}>{WidgetsPanel}</div>
          </Grid>
        </Grid>
      </Wide>
      <Narrow>
        <Grid container className={classes.content}>
          <Grid item style={{ width: '100%' }}>
            <TeamCard partial team={team} userRelations={userRelations} admin={admin} reload={handleChange} loading={props.loading || loading} />
            <MembersWidget items={members?.data} teamId={teamId} total={members?.total_item_count} loading={props.loading || loading} />
            {WidgetsPanel}
          </Grid>
        </Grid>
        {team && <AddMember admin={admin} team={team} reload={handleChange} setLoading={setLoading} loading={props.loading || loading} />}
      </Narrow>
    </>
  )
}

export default TeamProfile
