import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogContent, DialogActions, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

const TriggerEl = ({ open, loading }: { open: () => void; loading: boolean }) => (
  <IconButton onClick={open} icon={['fas', 'trash']} disablePY color="primary" size="small" hint="delete" loading={loading} />
)

const DeleteMessageDialog = ({
  isOpened,
  close,
  handleDelete,
  loading
}: {
  isOpened: boolean
  close: () => void
  loading: boolean
  handleDelete: () => void
}) => {
  return (
    <Dialog open={isOpened} onClose={close} loading={loading} title={<DialogTitle title="Delete message?" />}>
      <DialogContent>
        <Typography>Are you sure you want to delete the message?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          No
        </Button>
        <Button variant="text" onClick={handleDelete} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteMessageDialog.TriggerEl = TriggerEl

export default DeleteMessageDialog
