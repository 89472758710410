import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'

const useStyles = makeStyles()((theme) => ({
  success: { color: theme.palette.success.main },
  warning: { color: theme.palette.warning.main },
  error: { color: theme.palette.error.main },
  primary: { color: theme.palette.secondary.main },
  default: { color: theme.palette.text.secondary },
  wrapper: {
    cursor: 'default',
    padding: 0
  },
  icon: {
    fontSize: 16
  }
}))

type StatusNameType = 'pending' | 'incorporated' | 'rejected'
export type StatusType = { name: StatusNameType; title?: string }
export type StatusIconProps = { icon: IconProp; title?: string; className?: string; iconClassName?: string }

const StatusIcon = ({ status, ...props }: { status: StatusType; className?: string; iconClassName?: string }) => {
  const { name, title } = status

  return (
    <>
      {name === 'pending' && <WarningStatusIcon icon={['far', 'clock']} title={title || name} {...props} />}
      {name === 'incorporated' && <SuccessStatusIcon icon={['fas', 'circle-check']} title={title || name} {...props} />}
      {name === 'rejected' && <ErrorStatusIcon icon={['fas', 'times']} title={title || name} {...props} />}
    </>
  )
}

export const SuccessStatusIcon = (props: StatusIconProps) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title={props.title}>
      <Box className={cx(classes.wrapper, props.className)}>
        <FontAwesomeIcon icon={props.icon} className={cx(classes.icon, classes.success, props.iconClassName)} />
      </Box>
    </Tooltip>
  )
}

export const ErrorStatusIcon = (props: StatusIconProps) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title={props.title}>
      <Box className={cx(classes.wrapper, props.className)}>
        <FontAwesomeIcon icon={props.icon} className={cx(classes.icon, classes.error, props.iconClassName)} />
      </Box>
    </Tooltip>
  )
}

export const WarningStatusIcon = (props: StatusIconProps) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title={props.title}>
      <Box className={cx(classes.wrapper, props.className)}>
        <FontAwesomeIcon icon={props.icon} className={cx(classes.icon, classes.warning, props.iconClassName)} />
      </Box>
    </Tooltip>
  )
}

export const PrimaryStatusIcon = (props: StatusIconProps) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title={props.title}>
      <Box className={cx(classes.wrapper, props.className)}>
        <FontAwesomeIcon icon={props.icon} className={cx(classes.icon, classes.primary, props.iconClassName)} />
      </Box>
    </Tooltip>
  )
}

export const DefaultStatusIcon = (props: StatusIconProps) => {
  const { classes, cx } = useStyles()

  return (
    <Tooltip title={props.title}>
      <Box className={cx(classes.wrapper, props.className)}>
        <FontAwesomeIcon icon={props.icon} className={cx(classes.icon, classes.default, props.iconClassName)} />
      </Box>
    </Tooltip>
  )
}

export default StatusIcon
