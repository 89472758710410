import { HTMLAttributes, useCallback, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton } from '@mui/material'
import { StaticContext } from 'react-router'
import { RouteComponentProps, Link, useRouteMatch, useHistory, Redirect } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import { useSidepanelWide } from '_core/components/layout'
import ProfileItem from '_core/components/ProfileItem'
import Success from '_core/components/Success'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpCompanies, useLookUpPeople } from '_core/hooks/useLookup'
import { getExecutorPage } from '_core/hooks/useSalesforce'
import useSidebar from '_core/hooks/useSidebar'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type LocationState = {
  name: string
  type: 'contact' | 'account'
  sourceUrl: string
}

const useStyles = makeStyles()((theme) => ({
  autoCompleteWrapper: {
    '& .MuiInputBase-input': {
      '& > div': {
        padding: '6.5px 13.5px',
        '& > p': {
          bottom: 'unset'
        }
      }
    }
  },
  icon: {
    opacity: 0.6
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}))

const url = Paths._salesforce

type OType = {
  id: string
  value: string
  label: string
  email?: string
  company?: {
    name: string
  }
  url?: string
}

const getOptionLabel = (option: OType) => option.label

const PostSave = (props: RouteComponentProps<{}, StaticContext, LocationState>) => {
  const wide = useSidepanelWide()
  const { classes } = useStyles()
  const { params } = useRouteMatch<SalesforceMatchParams>()
  const { close: sidebarClose } = useSidebar()
  const history = useHistory()
  const { sidepanelHistory } = useContext(LayoutContext)

  const { entity, id } = params
  const { location } = props
  const { name, sourceUrl } = location.state || {}
  const sidepanel = isSidepanel('sidepanel')

  const { lookupPeople, forceAbort: forcePeopleAbort } = useLookUpPeople()
  const { lookupCompanies, forceAbort: forceCompaniesAbort } = useLookUpCompanies()

  const entitiesMap = {
    contact: { lookUp: lookupPeople, forceAbort: forcePeopleAbort },
    account: { lookUp: lookupCompanies, forceAbort: forceCompaniesAbort }
  }

  const { lookUp, forceAbort } = entitiesMap[entity]

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox<OType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const data = await lookUp(searchTerm)
        if (data) {
          return data.map((result) => {
            if ('PersonMd5' in result) {
              return {
                id: result.PersonMd5,
                value: result.BestJobTitleText || '',
                label: result.PersonNameText,
                email: result.BestEmailAddrText,
                company: {
                  name: result.BestJobMatchedCompanyName || result.BestJobCorpLevelCompanyName || ''
                }
              }
            }
            return {
              id: result.CompanyMd5,
              value: result.BestUrlText,
              label: result.CompanyNameText,
              url: result.BestUrlText
            }
          })
        }
      },
      [lookUp]
    ),
    forceAbort
  })

  useEffect(() => {
    if (value) {
      history.push(`${url}/${entity}/${value.id}`)
    }
  }, [entity, history, value])

  if (!name) {
    return <Redirect to={`${url}/${entity}/${id}`} />
  }

  const searchEntity = getExecutorPage(entity)

  const close = () => {
    if (sidepanel && wide) {
      sidebarClose()
    }

    if (sidepanel && !wide) {
      const [, , type, id] = sidepanelHistory[0].pathname.split('/')
      history.push(type && id ? `/${getExecutorPage(type)}/${id}` : '/')
    }

    if (!sidepanel) {
      history.push(`/${searchEntity}/${id}`)
    }
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OType) => {
    return (
      <li {...props}>
        <ProfileItem name={option.label} byline={option.value} byline2={option.company?.name} userKey={option.email} logoUrl={option.url} />
      </li>
    )
  }

  return (
    <Success
      variant="centered"
      text={
        <Typography variant="h4" className={classes.title}>
          {name}{' '}
          {sourceUrl && (
            <IconButton classes={{ root: classes.icon }} component={Link} to={{ pathname: sourceUrl }} target="_blank">
              <FontAwesomeIcon icon={['far', 'external-link']} style={{ width: 14, height: 14 }} />
            </IconButton>
          )}
          was saved into Salesforce as {entity === 'contact' ? 'a Contact' : 'an Account'}
        </Typography>
      }
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box className={classes.autoCompleteWrapper} width="100%" maxWidth={310}>
          <Combobox
            autoFocus
            inputValue={inputValue}
            open={open}
            options={options}
            value={value}
            placeholder="Search"
            loading={optionsLoading}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={handleValueChange}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
            filterOptions={filterOptions}
          />
        </Box>
        <>
          <Typography variant="h4" style={{ margin: '0px 5px' }}>
            or
          </Typography>
          <Button variant="text" onClick={close}>
            Done
          </Button>
        </>
      </Box>
    </Success>
  )
}

export default PostSave
