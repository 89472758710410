import { useCallback } from 'react'

import { GridCellParams, GridRowParams } from '@mui/x-data-grid-pro'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useTransformTeamData from '_core/hooks/useTransformTeamData'

import { get, mergeUrlWithParams, post, postBinary, put } from 'utils/httpUtils'

export const apiBase = '/teams'

export type RenamedRole = 'Contributor' | 'Curator' | 'Reader'
export type MatchAssociationsType = {
  id: string
  entity: 'group' | 'user'
  entityId: string
}
export type TeamCreationData = {
  teamName: string
} & SharingDataRes

export type TeamUpdateData = {
  teamNumber: number
} & TeamCreationData

type TeamSharingAndIdUpdatedData = {
  teamNumber: number
} & SharingDataRes

const useTeam = () => {
  const { transformTeam } = useTransformTeamData()

  const addTeam = async (data: TeamCreationData) => {
    const response = await post<TeamDataRes>(apiBase, {
      teamName: data.teamName,
      defaultShareLevel: {
        shareRelationships: data.shareRelationships,
        shareContactInfo: data.shareContactInfo,
        shareInteractionStats: data.shareInteractionStats,
        shareContactInfoWithAdminsAndCurators: data.shareContactInfoWithAdminsAndCurators
      }
    })
    if (response) {
      return transformTeam(response)
    }
  }

  const checkIsDirectTeamMember = (params: GridRowParams | GridCellParams) => {
    return params.row.credentials.length
      ? !params.row.credentials.filter((c: TeamMemberCredential) => c.credentialType !== 'OwnsEmailAddress').length
      : true
  }

  const setTeamIndexing = (teamID: string, isActive: boolean): Promise<{ isActive: boolean } | undefined> => {
    return put<{ isActive: boolean }>(`${apiBase}/${teamID}/settings`, { isActive })
  }

  const addTeamMembers = (teamID: string, accounts: { users: any[]; groups: any[] }): Promise<void> => {
    return post(`${apiBase}/${teamID}/members`, {
      emailAddressesWithRoles: accounts.users,
      credentialsWithRoles: accounts.groups
    })
  }

  const removeTeamMembers = (teamID: string, UserKeys: string[]): Promise<void> => {
    return post(`${apiBase}/${teamID}/removedmembers`, { UserKeys })
  }

  const link = {
    Company: '/companies/leadsxl',
    Person: '/people/leadsxl'
  }

  const getLeadsList = (
    teamNumber: string,
    entityType: 'Company' | 'Person',
    ids: string[],
    excludeFormerRoles?: boolean
  ): Promise<Blob | undefined> => {
    return postBinary(mergeUrlWithParams(`${link[entityType]}`, { teamNumber }), {
      // shared
      Ids: ids,
      IncludeStats: true,
      SortBy: 'ScoreDesc',
      NumAliases: 5,
      // applies to companies
      NumUrls: 5,
      NumEmployees: 5,
      ExcludeFormerRoles: excludeFormerRoles || false,
      ...(entityType === 'Company' && { WithCompanyTags: true }),
      // applies to people
      NumIntroducers: 5,
      NumEmails: 5,
      NumPhones: 3,
      NumJobs: 3,
      ...(entityType === 'Person' && { WithPersonTags: true })
    })
  }

  const editSharingStatus = async (body: TeamSharingAndIdUpdatedData) => {
    const data = await post('/me/settings/sharing', body)
    return data
  }

  const updateTeam = async (data: TeamUpdateData) => {
    const response = await put<TeamDataRes>(`${apiBase}/${data.teamNumber}`, {
      teamName: data.teamName,
      defaultShareLevel: {
        shareRelationships: data.shareRelationships,
        shareContactInfo: data.shareContactInfo,
        shareInteractionStats: data.shareInteractionStats,
        shareContactInfoWithAdminsAndCurators: data.shareContactInfoWithAdminsAndCurators
      }
    })
    if (response) {
      return transformTeam(response)
    }
  }

  const removeTeam = (teamID: string) => {
    return post(`${apiBase}/${teamID}/delete`, {
      deleteConfirmation: 'DELETE'
    })
  }

  const saveToLSRemovedTeams = (id: number) => {
    const list = JSON.parse(localStorage.getItem('removed_teams') || '[]')
    localStorage.setItem('removed_teams', JSON.stringify([...list, id]))
  }

  const editGroupRole = (teamNumber: string, groupId: string, role: Role, prohibitAppAccess?: boolean): Promise<void> => {
    return post(`${apiBase}/${teamNumber}/adgrouprole`, {
      adGroupId: groupId,
      role,
      ...(role === 'Member' && { prohibitAppAccess })
    })
  }

  const editUserRole = (teamNumber: number, userKey: string, role: Role, prohibitAppAccess?: boolean): Promise<void> => {
    return post(`${apiBase}/${teamNumber}/userrole`, {
      userKey,
      role,
      ...(role === 'Member' && { prohibitAppAccess })
    })
  }

  const renameMemberRole = (role: Role): RenamedRole => (role === 'Member' ? 'Contributor' : role)
  const renameContributorRole = (role: RenamedRole): Role => (role === 'Contributor' ? 'Member' : role)

  const getRenamedRoles = async (): Promise<RenamedRole[] | undefined> => {
    const roles = await get<Role[]>(`${apiBase}/roles`)
    return roles?.map(renameMemberRole)
  }

  return {
    removeTeamMembers,
    editSharingStatus,
    addTeam,
    updateTeam,
    addTeamMembers,
    setTeamIndexing,
    getLeadsList,
    checkIsDirectTeamMember,
    removeTeam,
    saveToLSRemovedTeams,
    editGroupRole,
    editUserRole,
    renameMemberRole,
    renameContributorRole,
    getRenamedRoles
  }
}

export const useTeamList = () => {
  const { fetchWithAbort, result, loading, setForceLoading, forceAbort } = useAbortableFetch<TeamDataRes[]>()
  const { transformTeamList } = useTransformTeamData()

  const getTeamsList = useCallback(async () => {
    const result = await fetchWithAbort({ url: apiBase })
    return transformTeamList(result)
  }, [fetchWithAbort])

  return { loading, setForceLoading, forceAbort, getTeamsList, teamsList: transformTeamList(result) }
}

export const useUserRelations = () => {
  const { fetchWithAbort, result, loading, setForceLoading, forceAbort } = useAbortableFetch<UserTeamRelations[]>()

  const getUserRelations = useCallback(async () => {
    const result = await fetchWithAbort({ url: '/memberships' })
    return result
  }, [fetchWithAbort])

  return { loading, setForceLoading, forceAbort, getUserRelations, userRelations: result }
}

export default useTeam
