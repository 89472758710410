import React, { useCallback, useEffect, useState } from 'react'

import { Container } from '@mui/material'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { useTeamList } from '_core/hooks/useTeam'

import Paths from 'Paths'

type ITeamContextValue = {
  teamNumber: number
}

type ITeamContext = {
  setTeamContext: (value: ITeamContextValue) => void
  teamContextValue: ITeamContextValue
  teamFetchCounter: number
  updateTeamFetchCounter: () => void
}

const useStyles = makeStyles()((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: '0px!important',
    [theme.breakpoints.up('md')]: {
      padding: '30px!important',
      marginTop: 0
    }
  }
}))

export const TeamContext = React.createContext<ITeamContext>({
  setTeamContext: () => {},
  teamContextValue: {
    teamNumber: 1
  },
  teamFetchCounter: 0,
  updateTeamFetchCounter: () => {}
})

const TeamContextProvider = (props: React.ComponentProps<any>) => {
  const {
    classes: { container }
  } = useStyles()

  const history = useHistory()
  const { pathname } = useLocation()

  const [teamContextValue, setTeamContextValue] = useState<ITeamContextValue>({ teamNumber: -1 })
  const [teamFetchCounter, setTeamFetchCounter] = useState<number>(1)
  const { getTeamsList } = useTeamList()

  const getDefaultTeamContextValue = async () => {
    const rehydratedValue = JSON.parse(localStorage.getItem('TEAM_NUMBER') as string)

    if (rehydratedValue) {
      return rehydratedValue
    } else {
      const teams = await getTeamsList()

      if (teams.length > 0) {
        return { teamNumber: teams[0].id }
      } else {
        return { teamNumber: 0 }
      }
    }
  }

  useEffect(() => {
    const setTeamNumber = async () => {
      const value = await getDefaultTeamContextValue()
      setTeamContextValue(value)
      localStorage.setItem('TEAM_NUMBER', JSON.stringify(value))
    }
    setTeamNumber()

    const checkStorageUpdates = (event: any) => {
      if (event.storageArea?.TEAM_NUMBER) {
        const teamValueLS = JSON.parse(event.storageArea?.TEAM_NUMBER)

        setTeamContextValue((prevState) => {
          if (teamValueLS && teamValueLS.teamNumber > -1 && prevState?.teamNumber !== teamValueLS.teamNumber) {
            return teamValueLS
          } else {
            return prevState
          }
        })
      }
    }

    window.addEventListener('storage', checkStorageUpdates)

    return () => {
      window.removeEventListener('storage', checkStorageUpdates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!teamContextValue.teamNumber) {
      history.replace(Paths._welcome)
    }
  }, [teamContextValue, history, pathname])

  const setTeamNumberCallback = useCallback((value: ITeamContextValue) => {
    setTeamContextValue(value)
    localStorage.setItem('TEAM_NUMBER', JSON.stringify(value))
  }, [])

  const updateTeamFetchCounter = () => setTeamFetchCounter((i) => ++i)

  if (teamContextValue.teamNumber === -1) {
    return <Container className={container}>Detecting your team...</Container>
  }

  const teamContext: ITeamContext = {
    setTeamContext: setTeamNumberCallback,
    teamContextValue,
    teamFetchCounter,
    updateTeamFetchCounter
  }

  return <TeamContext.Provider value={teamContext}>{props.children}</TeamContext.Provider>
}

export default TeamContextProvider
