import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import Paths from 'Paths'
import { UnauthenticatedTemplate, AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { BrowserAuthError, InteractionType } from '@azure/msal-browser'
import useCreateAccount from '_core/hooks/useCreateAccount'
import UnauthenticatedPage from 'Auth/UnauthenticatedPage'

const Login = () => {
  const loginType =
    window.location.pathname !== '/outlook' ? (window.self !== window.top ? InteractionType.Popup : InteractionType.Redirect) : InteractionType.Silent

  const { login, error, result } = useMsalAuthentication(loginType, { prompt: 'login' })

  useCreateAccount(Boolean(result))

  useEffect(() => {
    const handleLogin = async () => {
      if (error && window.location.pathname !== '/outlook') {
        try {
          await login(loginType)
        } catch (error) {
          if (error instanceof BrowserAuthError && error.errorCode === 'popup_window_error') {
            const type: keyof CustomErrorType = 'popupBlockedError'
            throw { type, error }
          } else {
            throw { type: 'signInError', error }
          }
        }
      }
    }
    handleLogin()
  }, [error, loginType, login])

  return (
    <>
      <AuthenticatedTemplate>
        <Redirect to={Paths._home} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
    </>
  )
}
export default Login
