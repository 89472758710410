import React, { isValidElement, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()(() => ({
  success: {
    margin: '150px auto'
  },
  successCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    '& .checkmark': {
      right: 0
    }
  }
}))

export const successAnimation = (
  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
)

const Success = ({ children, text, variant }: { children?: ReactNode; text?: string | ReactNode; variant?: 'centered' }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx({ [classes.success]: !variant, [classes.successCentered]: variant === 'centered' })}>
      <>{successAnimation}</>
      <Box p={4} textAlign="center">
        {isValidElement(text) && text}
        {(!text || typeof text === 'string') && (
          <Typography variant="h4">{text || 'Thank you! Your changes will be incorporated after the next analytics run.'}</Typography>
        )}
      </Box>
      {children}
    </div>
  )
}

export default Success
