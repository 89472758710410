import { ReactNode, RefObject } from 'react'

import useSidepanelLink, { SidepanelLinkProps } from '_core/hooks/useSidepanelLink'

const SidepanelLink = (
  props: SidepanelLinkProps & { sidepanel?: SidepanelType; linkRef?: RefObject<HTMLAnchorElement>; linkProps: any; children: ReactNode }
) => {
  const {
    linkProps: { to, index, ...tagProps },
    sidepanel = true,
    linkRef
  } = props
  const { handleClick, className } = useSidepanelLink(props)

  return (
    <>
      {to && (
        <span ref={linkRef} className={className} onClick={(e) => handleClick(e, sidepanel, { to, index })} {...{ ...tagProps }}>
          {props.children}
        </span>
      )}
      {!to && props.children}
    </>
  )
}

export default SidepanelLink
