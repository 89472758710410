import React, { isValidElement, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Collapse } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { ellipsis } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    paddingTop: theme.spacing(0.5)
  },
  title: {
    paddingTop: '4px',
    whiteSpace: 'nowrap',
    letterSpacing: '0.1px',
    maxWidth: '100%',
    ...ellipsis
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sidepanel')]: {
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
    }
  },
  spaceTop: {
    [theme.breakpoints.up('sidepanel')]: {
      paddingTop: theme.spacing(1)
    }
  },
  spaceBottom: {
    [theme.breakpoints.up('sidepanel')]: {
      paddingBottom: theme.spacing(1)
    }
  }
}))

export type ItemsType = { field: string; value?: string; label?: string; title: string; icon: IconProp }[]

type ExpandableContentProps = {
  isExpandedView: boolean
  sortByField?: GridSortItem['field']
}

export type ExpandableDetailsProps = {
  items?: ItemsType
  content?: ReactNode
  contentStart?: ReactNode
  contentEnd?: ReactNode
  collapsedSize?: number
} & ExpandableContentProps

export const ExpandableContent = ({ isExpandedView, items, sortByField }: ExpandableContentProps & { items: ItemsType }) => {
  const { classes } = useStyles()

  return (
    <>
      {items.map(({ field: rField, title, value, label, icon }) => (
        <Box key={rField}>
          {isExpandedView && (
            <Typography className={classes.title} color="text.secondary" semiBold>
              {title}
            </Typography>
          )}
          {(isExpandedView || sortByField === rField) &&
            (value ? (
              <Tooltip title={label || value} placement="bottom-start">
                <Box className={classes.item}>
                  <FontAwesomeIcon color="#979797" size="sm" icon={icon} style={{ paddingRight: 8 }} />
                  <Typography ellipsis>{value}</Typography>
                </Box>
              </Tooltip>
            ) : (
              <Box className={classes.item}>
                <FontAwesomeIcon color="#979797" size="sm" icon={icon} style={{ paddingRight: 8 }} />
                <Typography>No data</Typography>
              </Box>
            ))}
        </Box>
      ))}
    </>
  )
}

const ExpandableDetails = ({ isExpandedView, collapsedSize, items, content, contentStart, contentEnd, sortByField }: ExpandableDetailsProps) => {
  const { classes, cx } = useStyles()

  return (
    <Collapse in={isExpandedView} collapsedSize={collapsedSize}>
      <Box className={classes.wrapper}>
        {isValidElement(contentStart) && <>{contentStart}</>}
        <Box className={cx(classes.gridWrapper, { [classes.spaceTop]: !!contentStart, [classes.spaceBottom]: !!contentEnd })}>
          {items && <ExpandableContent items={items} sortByField={sortByField} isExpandedView={isExpandedView} />}
          {isValidElement(content) && <>{content}</>}
        </Box>
        {isValidElement(contentEnd) && <>{contentEnd}</>}
      </Box>
    </Collapse>
  )
}

export default ExpandableDetails
