import React, { useContext, useEffect, useMemo } from 'react'

import Page from '_shared/Page'

import CreateRequestForm from '_core/components/introductions/CreateRequestForm'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const CreateRequestPage = () => {
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Create request')
  }, [setMobileHeader])

  useEffect(() => {
    setSubHeader('New')
  }, [setSubHeader])

  const memoUrls = useMemo(
    () => [
      {
        key: 'meUser',
        url: '/me/profile'
      }
    ],
    []
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <DynamicEntity urls={memoUrls} component={CreateRequestForm} keepMounted id="create_introd_request" />
      </Narrow>
    </Page>
  )
}

export default CreateRequestPage
