import { useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { GeneralSettingsContext } from '_core/context/GeneralSettings'

const HomePage = () => {
  const history = useHistory()
  const { homePage } = useContext(GeneralSettingsContext)

  useEffect(() => {
    if (homePage) {
      history.replace(homePage)
    }
  }, [history, homePage])

  return <>Detecting home page...</>
}

export default HomePage
