import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'

import SidepanelLink from '_core/components/SidepanelLink'

import { widgetSubTitle } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  light: {
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '21px'
  },
  bold: {
    color: theme.palette.primary.main,
    ...widgetSubTitle(theme)
  }
}))

type NameLinkProps = {
  url: string
  sidepanel?: SidepanelType
  name: string
  preposition?: string
  className?: string
  variant?: 'light' | 'bold'
  onClick?: () => void
}

const NameLink = ({ url, sidepanel = true, name, preposition, className, variant = 'bold', onClick }: NameLinkProps) => {
  const { classes, cx } = useStyles()

  return (
    <SidepanelLink linkProps={{ to: url }} sidepanel={sidepanel} onClick={onClick}>
      <Tooltip title={name}>
        {preposition ? (
          <span>
            {preposition}&nbsp;
            <span className={cx({ [classes.light]: variant === 'light', [classes.bold]: variant === 'bold' }, className)}>{name}</span>
          </span>
        ) : (
          <span className={cx({ [classes.light]: variant === 'light', [classes.bold]: variant === 'bold' }, className)}>{name}&nbsp;</span>
        )}
      </Tooltip>
    </SidepanelLink>
  )
}

export default NameLink
