import { useContext, useEffect, useMemo } from 'react'

import { Box } from '@mui/material'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsList from '_core/components/TagsList'
import TagsSuccessBody, { UpdateTagsSuccessLayout } from '_core/components/TagsSuccess'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { TaggableType } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { groupTags } from '_core/hooks/useTagsManager'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Component = ({ loading, items }: { loading: boolean; items: UngroupedTag[] }) => {
  const { eventId } = useParams<{ eventId: string }>()
  const { queryParams } = useSearchQuery<{ name: string; appointmentUID: string }>()
  const { name, appointmentUID } = queryParams || {}

  return (
    <TagsList
      loading={loading}
      items={groupTags(items)}
      closeLink={`${Paths._events}/${eventId}`}
      addTagLink={mergeUrlWithParams(`${Paths._events}/${eventId}/tags/edit`, {
        name,
        appointmentUID
      })}
    />
  )
}

const EventTagsPage = () => {
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)
  const { queryParams } = useSearchQuery<{ name: string; appointmentUID: string }>()
  const { name, appointmentUID } = queryParams || {}
  const { eventId } = useParams<{ eventId: string }>()
  const successUrl = `${Paths._events}/${eventId}/tags/success`
  const successPage = useRouteMatch(successUrl)
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)

  const url = useMemo(() => {
    return `/tags/categorizedtags?identifier=${appointmentUID}&taggableType=${TaggableType.meetings}&teamNumber=${teamNumber}`
  }, [appointmentUID, teamNumber])

  useEffect(() => {
    setMobileHeader('Tags')
  }, [setMobileHeader])

  useEffect(() => {
    setSubHeader(name)
  }, [setSubHeader, name])

  const Action = (
    <SidepanelLink linkProps={{ to: mergeUrlWithParams(`${Paths._events}/${eventId}/tags/edit`, { name, appointmentUID }) }}>
      <IconButton icon={['far', 'edit']} hint="Edit tags" color="primary" size="small" />
    </SidepanelLink>
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack action={successPage ? null : Action} />
        <Widget>
          <Switch>
            <Route path={successUrl}>
              <Box height="calc(100vh - 127px)">
                <UpdateTagsSuccessLayout>
                  <TagsSuccessBody
                    showTagsLink={mergeUrlWithParams(`${Paths._events}/${eventId}/tags`, { name, appointmentUID })}
                    closeLink={`${Paths._events}/${eventId}`}
                  />
                </UpdateTagsSuccessLayout>
              </Box>
            </Route>
            <Route>
              <DynamicEntity url={url} component={Component} list nativeBack keepMounted id="meetings_tags" />
            </Route>
          </Switch>
        </Widget>
      </Narrow>
    </Page>
  )
}

export default EventTagsPage
