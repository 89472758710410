import { ChangeEvent, memo, ReactNode, SyntheticEvent, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutocompleteChangeReason, Box, Chip, FilterOptionsState, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import TextField from '_shared/forms/TextField'
import Tooltip from '_shared/Tooltip'

import LeaveDialog from '_core/components/dialogs/Leave'
import Empty from '_core/components/Empty'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import InternalTag from '_core/components/InternalTag'

import useDialog from '_core/hooks/useDialog'
import { TaggableType } from '_core/hooks/useLookup'

const useStyles = makeStyles()((theme) => ({
  chipRoot: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    '& div > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  editIcon: {
    '& svg': {
      width: 12,
      height: 12
    }
  },
  noOptions: {
    display: 'none'
  }
}))

export interface UpdateTagsProps {
  type: keyof typeof TaggableType
  create: boolean
  setCreate: (arg: boolean) => void
  keys: string[]
  items?: any[]
  loading?: boolean
  names: string
}

export const RenamedCategory = ({ oldCategoryName, newCategoryName }: { oldCategoryName: string; newCategoryName: string }) => {
  return (
    <Box display="flex" alignItems="center">
      <s>{oldCategoryName}</s>
      <Box mx={1.5}>
        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="sm" />{' '}
      </Box>
      {newCategoryName}
    </Box>
  )
}

export const RenamedTag = ({ oldTagName, newTagName }: { oldTagName: string; newTagName: string }) => {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center">
      <InternalTag label={<s>{oldTagName}</s>} sx={{ backgroundColor: theme.palette.text.disabled }} />
      <Box mx={1.5}>
        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="sm" />
      </Box>
      <InternalTag label={newTagName} />
    </Box>
  )
}

const TagChip = ({
  categoryKey,
  tagKey,
  tagName,
  disabled,
  handleEditClick,
  removeTag
}: {
  categoryKey: string
  tagKey: string
  tagName: string
  disabled: boolean
  handleEditClick: (tagKey: string, tagName: string) => void
  removeTag: (categoryKey: string, tagKey: string) => void
}) => {
  const { classes } = useStyles()

  const onEditClick = () => {
    handleEditClick(tagKey, tagName)
  }

  return (
    <Chip
      key={tagKey}
      label={
        <Box key={tagKey} display="flex" alignItems="center">
          <Tooltip title={tagName}>
            <span>{tagName}</span>
          </Tooltip>
          <IconButton
            disableHover
            disablePR
            size="small"
            icon={['far', 'edit']}
            hint="Edit tag value"
            onClick={onEditClick}
            className={classes.editIcon}
          />
        </Box>
      }
      onDelete={() => removeTag(categoryKey, tagKey)}
      disabled={disabled}
      classes={{ root: classes.chipRoot }}
      deleteIcon={<FontAwesomeIcon icon={['far', 'times']} />}
    />
  )
}

export const CategoryTagsPair = memo(
  (props: {
    categoryKey: string
    categoryName: string
    disabled: boolean
    isCategoryDuplicated: boolean
    isCategoryNew: boolean
    editCategory: (categoryKey: string, categoryNewValue: string) => void
    addTag: (categoryKey: string, newTagName: string) => void
    removeTag: (categoryKey: string, tagKey: string) => void
    editTag: (categoryKey: string, tagKey: string, newTagName: string) => void
    removeCategory: (categoryKey: string) => void
    tagNames: {
      tagKey: string
      tagName: string
    }[]
  }) => {
    const {
      categoryKey,
      categoryName,
      tagNames,
      isCategoryDuplicated,
      isCategoryNew,
      disabled,
      editCategory,
      addTag,
      removeTag,
      editTag,
      removeCategory: handleCategoryDelete
    } = props

    const [editTagKey, setEditTagKey] = useState('')
    const [tagInputValue, setTagInputValue] = useState('')

    const duplicatedTagIndex = tagNames.findIndex(({ tagName }) => tagName.toLowerCase() === tagInputValue.toLowerCase())
    const isTagValueDuplicated = duplicatedTagIndex > -1
    const isTagValueDisabled = disabled || !categoryName
    const [isCategoryTouched, setCategoryTouched] = useState(false)
    const [isTagFieldTouched, setTagFieldTouched] = useState(false)

    useEffect(() => {
      if (!tagInputValue) {
        setEditTagKey('')
      }
    }, [tagInputValue])

    const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
      setCategoryTouched(true)
      editCategory(categoryKey, e.target.value)
    }

    const handleEditClick = (tagKey: string, tagName: string) => {
      setEditTagKey(tagKey)
      setTagInputValue(tagName)
    }

    const handleTagInputValueChange = (e: SyntheticEvent<Element, Event>, newValue: string) => {
      setTagFieldTouched(true)
      setTagInputValue(newValue)
    }

    const handleTagValueChange = (
      e: SyntheticEvent<Element, Event>,
      newValue: (string | { inputValue: string; value: string })[],
      reason: AutocompleteChangeReason
    ) => {
      const newTag = !newValue.length ? '' : typeof newValue[0] === 'string' ? newValue[0] : newValue[0].inputValue
      setTagInputValue('')
      if (isTagValueDuplicated || reason === 'removeOption' || reason === 'clear') {
        return
      }

      if (editTagKey) {
        editTag(categoryKey, editTagKey, newTag)
      } else {
        addTag(categoryKey, newTag)
      }
    }

    const suggestTagValueCreate = (
      options: { inputValue: string; value: string }[],
      state: FilterOptionsState<{ inputValue: string; value: string }>
    ) => {
      const { inputValue } = state
      const trimmedInputValue = inputValue.trim()
      const isInputValueEmpty = trimmedInputValue === ''

      setTagInputValue(trimmedInputValue.trim())
      return isInputValueEmpty || isTagValueDuplicated ? [] : [{ inputValue, value: editTagKey ? `Edit to "${inputValue}"` : `Add "${inputValue}"` }]
    }

    const onCategoryBlur = () => {
      setCategoryTouched(true)
    }

    const onTagFieldBlur = () => {
      setTagFieldTouched(true)
    }

    const { message: categoryErrorMessage } =
      [
        { condition: !categoryName, message: 'Tag name can not be empty' },
        { condition: isCategoryDuplicated, message: 'Duplicate tag name' }
      ].find(({ condition }) => condition) || {}

    const { message: tagErrorMessage } =
      [
        { condition: !tagNames.length && !tagInputValue, message: 'Tag value can not be empty' },
        {
          condition: editTagKey ? isTagValueDuplicated && editTagKey !== tagNames[duplicatedTagIndex].tagKey : isTagValueDuplicated,
          message: 'Tag value already exists'
        }
      ].find(({ condition }) => condition) || {}

    return (
      <Box display="grid" gridTemplateColumns="1fr auto" alignItems="start" pb={2}>
        <Box display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap="8px">
          <TextField
            fullWidth
            value={categoryName}
            autoFocus={isCategoryNew}
            placeholder={`${isCategoryNew ? 'New' : ''} Name`}
            disabled={disabled}
            errorMessage={disabled || !isCategoryTouched ? '' : categoryErrorMessage}
            onChange={handleCategoryChange}
            onBlur={onCategoryBlur}
          />
          <Box display="flex" flexDirection="column">
            <Combobox
              multiple
              autoFocus={!!editTagKey}
              options={[]}
              placeholder={`${isCategoryNew ? 'New' : ''} Value`}
              icon={['far', 'tag']}
              disabled={isTagValueDisabled}
              autoSelect
              autoHighlight
              forcePopupIcon={false}
              disableClearable={false}
              inputValue={tagInputValue}
              value={[]}
              freeSolo // to enable edit mode
              onBlur={onTagFieldBlur}
              onChange={handleTagValueChange}
              onInputChange={handleTagInputValueChange}
              filterOptions={suggestTagValueCreate}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
              renderOption={(props, option: { inputValue: string; value: string }) => <li {...props}>{option.value}</li>}
              errorMessage={isTagValueDisabled || !isTagFieldTouched ? '' : tagErrorMessage}
            />
            <Box display="flex" flexWrap="wrap" mt={0.5} mb={-0.5}>
              {tagNames.map(({ tagKey, tagName }) => {
                return (
                  <TagChip
                    key={tagKey}
                    categoryKey={categoryKey}
                    tagKey={tagKey}
                    tagName={tagName}
                    removeTag={removeTag}
                    handleEditClick={handleEditClick}
                    disabled={disabled}
                  />
                )
              })}
            </Box>
          </Box>
        </Box>
        <IconButton
          icon={['far', 'times']}
          onClick={() => handleCategoryDelete(categoryKey)}
          size="small"
          disabled={disabled}
          disablePR
          hint="Remove category"
        />
      </Box>
    )
  }
)

const ManageTags = (props: {
  children: ReactNode
  isTagsFormDirty: boolean
  isTagsFormValid: boolean
  handleReset: () => void
  onSubmit: () => void
  onClose: () => void
  createCategory: () => void
  loading: boolean
  showEmpty: boolean
  hasInitTagsSpecified: boolean
}) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  const { isTagsFormDirty, isTagsFormValid, children, handleReset, onSubmit, onClose, createCategory, showEmpty, hasInitTagsSpecified, loading } =
    props

  const handleClose = () => {
    if (isTagsFormDirty && !isDialogOpened) {
      openDialog()
    } else {
      onClose()
    }
  }

  return (
    <FormLayout>
      <FormLayoutContent>
        {!loading && showEmpty && (
          <Empty
            title={hasInitTagsSpecified ? 'You have removed all tags. Click SAVE to finish.' : 'No tags have been specified yet.'}
            subTitle={hasInitTagsSpecified ? 'You can also add new tags by clicking the button below.' : ''}
            icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['far', 'tags']} />}
            action={
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={createCategory} startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}>
                  Add tags
                </Button>
              </Box>
            }
          />
        )}
        {(loading || !showEmpty) && (
          <>
            {children}
            <Button
              onClick={createCategory}
              variant="link"
              disabled={loading}
              startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}
            >
              Add tag
            </Button>
          </>
        )}
      </FormLayoutContent>
      <FormLayoutActions>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Button variant="text" onClick={handleReset} color="secondary" disabled={loading || !isTagsFormDirty}>
            Reset
          </Button>
          <Box>
            <Button variant="text" onClick={handleClose} disabled={loading} color="secondary">
              Close
            </Button>
            <Button variant="text" onClick={onSubmit} disablePR disabled={loading || !isTagsFormDirty || !isTagsFormValid}>
              Save
            </Button>
          </Box>
        </Box>
      </FormLayoutActions>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={handleClose} />
    </FormLayout>
  )
}

export default ManageTags
