import { useState } from 'react'

import { Box, TextFieldProps } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import { IconButtonProps } from '_shared/buttons/Icon'
import DatePicker from '_shared/DatePicker'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'

import { formatDate, formatDateFromNow, getLocal } from 'utils/Utils'

const useStyles = makeStyles()(() => ({
  move: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 13
  }
}))

export const PrevDateSwitch = ({
  day,
  setDay,
  showText,
  disablePR
}: { day: MomentType; setDay: (val: MomentType) => void; showText?: boolean } & Pick<IconButtonProps, 'disablePR'>) => {
  const { classes } = useStyles()
  const yesterday = getLocal(day).subtract(1, 'days')
  return (
    <Box className={classes.move}>
      <IconButton
        size="small"
        classes={{ root: classes.icon }}
        onClick={() => setDay(yesterday)}
        icon={['far', 'chevron-left']}
        disablePR={disablePR}
      />
      {showText && <Typography>{formatDate(yesterday, 'ddd D MMM')}</Typography>}
    </Box>
  )
}

export const NextDateSwitch = ({
  day,
  setDay,
  showText,
  disablePL
}: { day: MomentType; setDay: (val: MomentType) => void; showText?: boolean } & Pick<IconButtonProps, 'disablePL'>) => {
  const { classes } = useStyles()
  const tomorrow = getLocal(day).add(1, 'days')
  return (
    <Box className={classes.move} justifyContent="flex-end">
      {showText && <Typography>{formatDate(tomorrow, 'ddd D MMM')}</Typography>}
      <IconButton
        size="small"
        classes={{ root: classes.icon }}
        onClick={() => setDay(tomorrow)}
        icon={['far', 'chevron-right']}
        disablePL={disablePL}
      />
    </Box>
  )
}

export const WeekDateTitle = ({ day }: { day: MomentType }) => {
  const titles = ['today', 'yesterday', 'tomorrow']
  const title = formatDateFromNow(day, 2)
  return <Heading title={titles.indexOf(title) > -1 ? title : 'date'} variant="none" />
}

const Today = ({ day, setDay, disablePL }: { day: MomentType; setDay: (val: MomentType) => void } & Pick<IconButtonProps, 'disablePL'>) => {
  const [open, setOpen] = useState<boolean>(false)

  const openPicker = () => {
    setOpen(true)
  }

  const closePicker = () => {
    setOpen(false)
  }

  const handleDateChange = (date: unknown) => {
    if (date) {
      setDay(getLocal(date))
      closePicker()
    }
  }

  return (
    <DatePicker
      open={open}
      value={day}
      onClose={closePicker}
      views={['year', 'month', 'day']}
      onChange={handleDateChange}
      renderInput={({ inputRef }: TextFieldProps) => (
        <IconButton size="small" onClick={openPicker} ref={inputRef} icon={['far', 'calendar-alt']} disablePL={disablePL} />
      )}
    />
  )
}

export default Today
