import React, { forwardRef, Ref, SyntheticEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, MenuItem, Menu } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import Typography from '_shared/Typography'

import SidepanelLink from '../SidepanelLink'

const useStyles = makeStyles<{ disablePadding: boolean; sidepanel: boolean }, 'verticalItem' | 'horizontalItem'>()(
  (theme, { disablePadding, sidepanel }, classes) => ({
    verticalItem: {
      minWidth: theme.spacing(6),
      padding: !disablePadding ? `${theme.spacing(1)} 0` : 0
    },
    horizontalItem: {
      padding: !disablePadding ? (sidepanel ? `0 ${theme.spacing(2)}` : `0 ${theme.spacing(1)}`) : 0,
      width: sidepanel ? theme.spacing(8) : '100%',
      minWidth: theme.spacing(6),
      maxWidth: theme.spacing(10),
      '&:nth-of-type(4)': {
        display: 'none',
        [theme.breakpoints.up(375)]: {
          display: 'flex',
          justifyContent: 'center'
        }
      },
      '&:nth-of-type(5)': {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          justifyContent: 'center'
        }
      }
    },
    list: {
      padding: 0,
      margin: `0 !important`
    },
    listItemEven: {
      background: theme.palette.background.darker
    },
    default: {},
    border: {
      background: theme.palette.background.light,
      borderBottom: '1px solid #f2f2f2',
      padding: disablePadding ? 0 : `${theme.spacing(1)} 0`,
      margin: `0 !important`,
      '&:last-child': {
        borderBottom: 0
      }
    },
    numbered: {
      position: 'relative',
      paddingLeft: '0 !important',
      margin: `0 ${theme.spacing(2)} 0 ${theme.spacing(8)} !important`,
      borderBottom: '1px #ECEEF0 solid',
      '&:before': {
        counterIncrement: 'list-number',
        content: 'counter(list-number)',
        position: 'absolute',
        left: theme.spacing(-5),
        top: theme.spacing(2.5),
        background: '#1684A7',
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
        borderRadius: '100%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: theme.spacing(1.25),
        lineHeight: 1
      }
    },
    highlight: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.hover.secondary
      }
    },
    button: {
      background: 'transparent none',
      border: 0,
      padding: 0,
      width: '100%',
      cursor: 'pointer'
    },
    space: {
      marginBottom: theme.spacing(1),
      background: theme.palette.background.light,
      borderRadius: 4,
      '& > a > .MuiBox-root': {
        borderRadius: 4
      }
    },
    menuHeader: {
      boxSizing: 'border-box',
      textAlign: 'center',
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(1.5)}`
    },
    menuTitle: {
      lineHeight: '26px',
      fontSize: '16px'
    },
    menuDescription: {
      fontSize: '14px',
      lineHeight: '16px',
      maxWidth: 137,
      margin: '0px auto',
      opacity: 0.6
    },
    card: {
      transition: 'padding 0.25s ease-in, margin 0.25s ease-in, background 0.25s ease-in',
      border: '1px solid #ECEEF0',
      flex: '1 1 0%',
      overflow: 'hidden',
      borderRadius: theme.spacing(1),
      background: theme.palette.background.darker,
      padding: 0,
      [`&.${classes.verticalItem}`]: {
        margin: !disablePadding ? `${theme.spacing(1)} 0` : 0
      },
      [`&.${classes.horizontalItem}`]: {
        margin: sidepanel ? `0 ${theme.spacing(2)}` : `0 ${theme.spacing(1)}`
      }
    }
  })
)

interface CommonProps {
  item: any
  direction?: 'vertical' | 'horizontal'
  component: React.FC<any>
  addprops?: { style?: { [key: string]: string | number } }
  disabled?: boolean
  virtualized?: boolean
  disablePadding?: boolean
}

interface ItemProps {
  variant?: Exclude<ItemVariant, 'list'>
  index?: number
}

interface IndexProps {
  variant: Extract<ItemVariant, 'list'>
  index: number
}

const sidepanel = isSidepanel()

const Item = forwardRef((props: CommonProps & (ItemProps | IndexProps), ref: Ref<HTMLDivElement>) => {
  const { item, direction, variant, addprops, virtualized, index, disablePadding } = props
  const { classes, cx } = useStyles({ disablePadding: disablePadding || variant === 'list', sidepanel })
  const itemType = direction === 'horizontal' ? classes.horizontalItem : classes.verticalItem
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { link, ...linkItem } = item

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      ref={ref}
      className={cx(itemType, {
        [classes.list]: variant === 'list' && !virtualized,
        [classes.listItemEven]: variant === 'list' && index ? index % 2 : false,
        [classes.space]: variant === 'space',
        [classes.border]: variant === 'border',
        [classes.numbered]: variant === 'numbered',
        [classes.card]: variant === 'card',
        [classes.highlight]: item.highlightOnHover,
        [classes.default]: (!variant || variant === 'homepage') && direction !== 'horizontal'
      })}
      {...addprops}
    >
      {item.menu && (
        <>
          <button className={classes.button} aria-controls="item-menu" aria-haspopup="true" onClick={handleClick} disabled={props.disabled}>
            <props.component {...item} />
          </button>
          <Menu
            id="item-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {item.menu.title && (
              <Box className={classes.menuHeader}>
                <Typography classes={{ root: classes.menuTitle }} bold>
                  {item.menu.title}
                </Typography>
                {item.menu.description && <Typography classes={{ root: classes.menuDescription }}>{item.menu.description}</Typography>}
              </Box>
            )}
            {item.menu.actions.map((menuItem: any) => (
              <span key={menuItem.name}>
                {menuItem.externalLink && (
                  <MenuItem>
                    <Link to={{ pathname: menuItem.externalLink }} target="_blank">
                      <Box display="flex" alignItems="center">
                        <FontAwesomeIcon icon={menuItem.icon} style={{ marginRight: 8, width: 24 }} />
                        <Box>{menuItem.name}</Box>
                      </Box>
                    </Link>
                  </MenuItem>
                )}
                {menuItem.link && (
                  <MenuItem onClick={handleClose}>
                    <SidepanelLink
                      linkProps={{ to: menuItem.link, style: { width: '100%' } }}
                      sidepanel={menuItem.sidepanel}
                      onClick={handleClose}
                      window={menuItem.window}
                    >
                      <Box display="flex" alignItems="center">
                        <FontAwesomeIcon icon={menuItem.icon} style={{ marginRight: 8, width: 24 }} />
                        <Box>{menuItem.name}</Box>
                      </Box>
                    </SidepanelLink>
                  </MenuItem>
                )}
                {menuItem.action && (
                  <MenuItem
                    onClick={(e: SyntheticEvent) => {
                      menuItem.action(menuItem.value, e)
                      handleClose()
                    }}
                    key={menuItem.name}
                  >
                    <Box display="flex" alignItems="center">
                      <FontAwesomeIcon icon={menuItem.icon} style={{ marginRight: 8, width: 24 }} />
                      <Box>{menuItem.name}</Box>
                    </Box>
                  </MenuItem>
                )}
              </span>
            ))}
          </Menu>
        </>
      )}
      {!item.menu && (
        <>
          {item.link &&
            (item.sidepanel ? (
              <SidepanelLink linkProps={{ to: item.link, index }} sidepanel={item.sidepanel}>
                <props.component {...linkItem} />
              </SidepanelLink>
            ) : (
              <Link to={item.link} component={item.component}>
                <props.component {...linkItem} />
              </Link>
            ))}
          {!item.link && <props.component {...item} />}
        </>
      )}
    </Box>
  )
})

export default Item

export type ItemVariant = 'homepage' | 'list' | 'numbered' | 'border' | 'space' | 'card'
