import { useRef } from 'react'

const useLatestSearchValue = () => {
  const inputValueRef = useRef<string>()
  const { current: inputValue } = inputValueRef

  return {
    inputValue,
    inputValueRef
  }
}

export default useLatestSearchValue
