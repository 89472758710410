import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, ButtonGroup, InputAdornment } from '@mui/material'
import { DateValidationError } from '@mui/x-date-pickers/internals'
import { Moment } from 'moment'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '_shared/buttons'
import DatePicker from '_shared/DatePicker'
import FormLabel from '_shared/forms/FormLabel'
import TextField from '_shared/forms/TextField'

import Widget from '_core/components/Widget'

import { useStyles } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { put } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type OutcomeType = {
  rating: boolean
  date: Moment | null
  usd: number
}

const ratingOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
]

const getInteger = (value: string) => {
  const numericString = value.replace(/\D/g, '')
  const numericValue = parseInt(numericString)
  return numericValue || 0
}

const getMonetaryValueStr = (value: number) =>
  value
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
    .substring(1)

const Outcome = ({ request, ...props }: { request: IntroductionRequestResp; loading: boolean }) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const history = useHistory()
  const { querierIsLtnManager, queriedByAppUserKey } = request || {}
  const plan = request?.plan || {}
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [outcome, setOutcome] = useState<OutcomeType>({
    rating: true,
    usd: 0,
    date: getLocal()
  })
  const { updateParent } = useSidepanelPayloads()
  const { control, setValue } = useForm<{ memo: string }>({ mode: 'onTouched' })
  const memoValue = useWatch({ control, name: 'memo' })
  const handleClose = useSidepanelClose(Paths._introductions)
  const { classes } = useStyles()

  const isCreator = plan.planUid && queriedByAppUserKey === plan.creatorDotAppUserKey

  useEffect(() => {
    if (plan.outcomeEditorAppUserKey) {
      setOutcome({
        usd: plan.outcomeUsd,
        rating: plan.outcomeRating !== -1,
        date: getLocal(plan.outcomeDate)
      })
      setValue('memo', plan.outcomeMemo)
    }
  }, [plan.outcomeEditorAppUserKey])

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  if (plan.planUid && !querierIsLtnManager && !isCreator) {
    history.replace(`${Paths._introductions}/${id}`)
  }

  const onMonetaryValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setOutcome((prevState) => ({ ...prevState, usd: getInteger(value) }))
  }

  const onDateChange = (date: any) => {
    setOutcome((prevState) => {
      return { ...prevState, date: getLocal(date) }
    })
  }

  const handleDateError = (reason: DateValidationError) => {
    if (reason) {
      setError(reason)
    } else {
      setError('')
    }
  }

  const save = async () => {
    setLoading(true)
    const resp = await put<IntroductionPlanResp>('/prospecting/planoutcome', {
      planUid: id,
      editorUserKey: request.queriedByAppUserKey,
      outcomeUsd: outcome.usd,
      outcomeMemo: memoValue,
      outcomeRating: outcome.rating ? 1 : -1,
      outcomeDate: outcome.date
    })
    if (resp) {
      updateParent({
        action: 'UPDATE_INTRODUCTION_DETAILS',
        value: {
          planUid: id,
          fieldName: 'outcome',
          value: {
            usd: resp.outcomeUsd,
            rating: resp.outcomeRating
          }
        }
      })
    }
    setLoading(false)
    history.push(`${Paths._introductions}/${id}`)
  }

  const disabled = loading || props.loading

  const setRating = (rating: boolean) => setOutcome((prevState) => ({ ...prevState, rating }))

  return (
    <>
      <Widget className={classes.wrapper}>
        <Box>
          <FormLabel label="Was the request successful?" />
          <ButtonGroup classes={{ root: classes.group }} color="primary" size="small" disableElevation disableRipple disabled={disabled}>
            {ratingOptions.map((option: any) => (
              <Button key={option.label} variant={option.value === outcome.rating ? 'contained' : 'outlined'} onClick={() => setRating(option.value)}>
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box mb={1.5}>
          <Controller
            name="memo"
            control={control}
            render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
              <TextField
                label="Memo"
                name="memo"
                disabled={disabled}
                value={value}
                onChange={onChange}
                multiline
                errorMessage={isDirty && !value ? 'This field is required' : ''}
                rows={3}
                fullWidth
              />
            )}
          />
        </Box>
        <Box mb={1.5}>
          <DatePicker
            disabled={disabled}
            label="Date"
            value={outcome.date}
            onChange={onDateChange}
            onError={handleDateError}
            size="medium"
            ActionBar={null}
            fullWidth
            disableFuture
          />
        </Box>
        <TextField
          label="Monetary value (optional)"
          name="usd"
          disabled={disabled}
          value={getMonetaryValueStr(outcome.usd)}
          onChange={onMonetaryValueChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
              </InputAdornment>
            )
          }}
        />
      </Widget>
      <Box className={classes.actionButtons}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePL>
          Close
        </Button>
        <Button onClick={save} disabled={disabled || Boolean(!memoValue || error)} variant="text" disablePR>
          Save
        </Button>
      </Box>
    </>
  )
}

export default Outcome
