import React, { ChangeEvent, useCallback, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Redirect } from 'react-router-dom'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import Select from '_shared/forms/Select'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import { renderPersonOption } from '_core/components/introductions/options'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import useAccessDetailsForm from '_core/hooks/useAccessDetailsForm'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { Colleague, Team } from '_core/hooks/useCollaboratorsAccess'
import { useStyles } from '_core/hooks/useIntroductionRequestForm'
import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import { useTeamList } from '_core/hooks/useTeam'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const rightsOptions = [
  { label: 'Editor', value: 'canWrite' },
  { label: 'Reader', value: 'canRead' }
]

type AddCollaboratorsProps = {
  request: IntroductionRequestResp
  loading: boolean
}

const AddCollaborators = ({ request, loading }: AddCollaboratorsProps) => {
  const {
    appUserAccesses,
    teamAccesses,
    fetching,
    chosenUsersEmails,
    chosenTeamNumbers,
    handleUserPick,
    removeUserAccess,
    handleSelectUserAccessLevel,
    handleTeamPick,
    removeTeamAccess,
    handleSelectTeamAccessLevel,
    saveCollaborators
  } = useAccessDetailsForm(request)
  const { setMobileHeader } = useContext(LayoutContext)
  const handleClose = useSidepanelClose(Paths._introductions)
  const { classes } = useStyles()

  const plan = request?.plan || {}

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  const load = loading || !teamAccesses || !appUserAccesses || fetching
  const isCreator = plan.planUid && request.queriedByAppUserKey === plan.creatorDotAppUserKey

  if (plan.planUid && !isCreator) {
    return <Redirect to={`${Paths._introductions}/${plan.planUid}`} />
  }

  return (
    <>
      <Widget>
        <ColleaguePicker accesses={appUserAccesses} onChange={handleUserPick} disabled={load} chosenUsersEmails={chosenUsersEmails || []} />
        <Repeater
          direction="vertical"
          variant="card"
          empty={
            <Box mt={2}>
              <Empty subTitle="No colleagues" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} />
            </Box>
          }
          component={ProfileItem}
          skeleton={{ size: 2, loading: loading || !appUserAccesses }}
          items={
            appUserAccesses
              ? appUserAccesses.map((user) => ({
                  variant: 'expandable',
                  name: user.name,
                  byline: user.email,
                  userKey: user.email,
                  icons: <IconButton icon={['far', 'times']} disabled={load} onClick={() => removeUserAccess(user.email)} size="small" disablePR />,
                  byline3: (
                    <Select
                      name="user"
                      disabled={load}
                      options={rightsOptions}
                      onChange={(e) => handleSelectUserAccessLevel(e, user.email)}
                      value={user.canWrite ? 'canWrite' : 'canRead'}
                      fullWidth
                      size="small"
                      className={classes.cardAction}
                    />
                  )
                }))
              : []
          }
        />
      </Widget>
      <Widget className={classes.wrapper}>
        <TeamPicker accesses={teamAccesses} onChange={handleTeamPick} disabled={load} chosenTeamNumbers={chosenTeamNumbers || []} />
        <Repeater
          direction="vertical"
          variant="card"
          empty={
            <Box mt={2}>
              <Empty subTitle="No teams" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users-class']} style={{ color: '#A7A7A7' }} />} />
            </Box>
          }
          component={ProfileItem}
          skeleton={{ size: 2, loading: loading || !teamAccesses }}
          items={
            teamAccesses
              ? teamAccesses.map((team) => ({
                  variant: 'expandable',
                  name: team.teamName,
                  byline: (
                    <SidepanelLink linkProps={{ to: `${Paths._teams}/${team.teamNumber}/members` }}>
                      <Tooltip title="See the members list">
                        <Typography>{`${team.teamMembers} member${team.teamMembers > 1 ? 's' : ''}`}</Typography>
                      </Tooltip>
                    </SidepanelLink>
                  ),
                  icons: (
                    <IconButton disabled={load} icon={['far', 'times']} onClick={() => removeTeamAccess(team.teamNumber)} size="small" disablePR />
                  ),
                  byline3: (
                    <Select
                      name="team"
                      disabled={load}
                      options={rightsOptions}
                      onChange={(e) => handleSelectTeamAccessLevel(e, team.teamNumber)}
                      value={team.canWrite ? 'canWrite' : 'canRead'}
                      fullWidth
                      size="small"
                      className={classes.cardAction}
                    />
                  )
                }))
              : []
          }
        />
      </Widget>
      <Box className={classes.actionButtons}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePL>
          Close
        </Button>
        <Button onClick={saveCollaborators} variant="text" disabled={load} disablePR>
          Save
        </Button>
      </Box>
    </>
  )
}

const ColleaguePicker = ({
  disabled,
  accesses,
  onChange,
  chosenUsersEmails
}: {
  disabled: boolean
  accesses: Colleague[] | undefined
  onChange: (event: ChangeEvent<{}>, value: Colleague[]) => void
  chosenUsersEmails: string[]
}) => {
  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<Colleague, true>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpActiveDirectoryMembers(searchTerm)
        if (result) {
          return result.users.map((user) => ({
            name: user.displayName,
            email: user.emailAddress,
            canRead: true,
            canWrite: false
          }))
        }
      },
      [lookUpActiveDirectoryMembers]
    ),
    forceAbort
  })

  return (
    <Combobox
      multiple
      label="Colleagues"
      placeholder="Search for colleagues"
      icon={['far', 'search']}
      value={accesses || []}
      options={options}
      open={open}
      loading={optionsLoading}
      inputValue={inputValue}
      onChange={onChange}
      renderOption={renderPersonOption}
      disabled={disabled}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      onClose={handleComboboxClose}
      onFocus={handleFocus}
      getOptionLabel={(option) => option.name}
      filterOptions={(options: Colleague[]) => options.filter((v) => v.email && !chosenUsersEmails.includes(v.email.toLowerCase()))}
    />
  )
}

const TeamPicker = ({
  disabled,
  accesses,
  onChange,
  chosenTeamNumbers
}: {
  disabled: boolean
  accesses: Team[] | undefined
  onChange: (event: ChangeEvent<{}>, value: Team[]) => void
  chosenTeamNumbers: number[]
}) => {
  const { forceAbort, getTeamsList } = useTeamList()
  const loadOnFocus = true

  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<Team, true>({
    loadOnFocus,
    loadOptions: useCallback(async () => {
      const result = await getTeamsList()
      if (result) {
        return result.map((team) => ({
          teamNumber: team.id,
          teamName: team.name,
          teamMembers: team.membersTotal,
          canRead: true,
          canWrite: false
        }))
      }
    }, [getTeamsList]),
    forceAbort
  })

  return (
    <Combobox
      label="Teams"
      multiple
      forcePopupIcon={loadOnFocus}
      disabled={disabled}
      placeholder="Search for teams"
      icon={['far', 'search']}
      value={accesses || []}
      options={options}
      open={open}
      loading={optionsLoading}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      onClose={handleComboboxClose}
      onFocus={handleFocus}
      getOptionLabel={(option) => option.teamName}
      filterOptions={(options: Team[]) => options.filter((v) => !chosenTeamNumbers?.includes(v.teamNumber))}
    />
  )
}

export default AddCollaborators
