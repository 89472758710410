import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import AvatarGroup from '_shared/AvatarGroup'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  font: {
    fontSize: '12px'
  },
  groupWrapper: {
    marginTop: '5px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  }
}))

export type MembersWidgetProps = {
  items: { [key: string]: any }[]
  loading: boolean
  total: number
  variant?: 'widget' | 'groupAvatars'
  teamId: number
}

const MembersWidget = ({ variant = 'widget', teamId, loading, items, total }: MembersWidgetProps) => {
  const { classes } = useStyles()

  return (
    <>
      {variant === 'groupAvatars' && (
        <AvatarGroup
          alignRight
          max={4}
          size="xs"
          sidepanel={true}
          seeAllLink={`${Paths._teams}/${teamId}/members`}
          skeleton={{ size: 4, loading }}
          hideName={true}
          className={classes.groupWrapper}
          total={total}
          items={items?.map((contributor: any) => ({
            name: contributor.name,
            userKey: contributor.userKeyPlainText,
            link: `${Paths._people}/${contributor.userKeyMd5}`
          }))}
          empty={
            <Typography color="text.secondary" className={classes.font}>
              No contributors
            </Typography>
          }
        />
      )}

      {variant === 'widget' && (
        <Widget scope="none" style={{ borderTop: '8px #ECEEF0 solid', borderRadius: 0 }}>
          <Heading title="Contributors" icon={['far', 'user']} count={total || undefined} link={`${Paths._teams}/${teamId}/members`} />
          <Repeater
            direction="horizontal"
            component={Avatar}
            skeleton={{ size: 5, loading }}
            items={items?.slice(0, 5).map((contributor: any) => ({
              name: contributor.name,
              userKey: contributor.userKeyPlainText,
              link: `${Paths._people}/${contributor.userKeyMd5}`
            }))}
          />
        </Widget>
      )}
    </>
  )
}

export default MembersWidget
