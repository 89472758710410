import React, { useContext, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import EditRequestForm from '_core/components/introductions/EditRequestForm'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const EditRequestPage = () => {
  const { id } = useParams<{ id: string }>()

  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Edit request')
  }, [setSubHeader])

  const memoUrls = useMemo(
    () => [
      {
        key: 'request',
        url: `/prospecting/plandetail?planUid=${id}`
      },
      {
        key: 'reasons',
        url: '/prospecting/reasons'
      }
    ],
    [id]
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <DynamicEntity urls={memoUrls} component={EditRequestForm} keepMounted id="edit_request" />
      </Narrow>
    </Page>
  )
}

export default EditRequestPage
