import React, { SyntheticEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Drawer, DrawerProps, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import SidepanelLink from '_core/components/SidepanelLink'

interface DrawerMenuProps {
  open: boolean
  toggleDrawer: () => void
  items: { [key: string]: any }[]
  anchor?: DrawerProps['anchor']
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('sidepanel')]: {
      paddingBottom: 0
    }
  },
  item: {
    padding: 0,
    '& > *:first-of-type': {
      padding: theme.spacing(1.5),
      width: '100%'
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 24
  }
}))

const DrawerMenu = ({ open, toggleDrawer, items, anchor = 'bottom' }: DrawerMenuProps) => {
  const { classes } = useStyles()

  return (
    <Drawer open={open} onClose={toggleDrawer} anchor={anchor} classes={{ paper: classes.wrapper }}>
      {items.map((item: { [key: string]: any }) => {
        const Content = (
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={item.icon} className={classes.icon} />
            <Typography style={{ maxWidth: 'calc(100vw - 56px)' }} ellipsis>
              {item.name}
            </Typography>
          </Box>
        )

        if (item.component) {
          return (
            <MenuItem key={item.name} onClick={toggleDrawer} classes={{ root: classes.item }}>
              {item.component}
            </MenuItem>
          )
        }

        if (item.action) {
          return (
            <MenuItem
              classes={{ root: classes.item }}
              onClick={(e: SyntheticEvent) => {
                item.action(e)
                toggleDrawer()
              }}
              key={item.name}
            >
              {Content}
            </MenuItem>
          )
        }

        if (item.externalLink) {
          return (
            <MenuItem key={item.name} onClick={toggleDrawer} classes={{ root: classes.item }}>
              <Link to={{ pathname: item.externalLink }} target="_blank">
                {Content}
              </Link>
            </MenuItem>
          )
        }

        if (item.link) {
          return (
            <MenuItem key={item.name} onClick={toggleDrawer} classes={{ root: classes.item }}>
              <SidepanelLink linkProps={{ to: item.link, style: { width: '100%' } }} sidepanel={item.sidepanel}>
                {Content}
              </SidepanelLink>
            </MenuItem>
          )
        }
      })}
    </Drawer>
  )
}

export default DrawerMenu
