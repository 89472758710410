import { useState, useEffect } from 'react'

type ResultType = {
  isOpen: boolean
  openedUrl: string
  openUrl: (val: string, window?: Window) => void
}

export const getSidepanelUrl = () => window.localStorage.getItem('sidepanel') || '/blank'

const useSidepanel = (sidepanel: SidepanelType = true): ResultType => {
  const [openedUrl, setUrl] = useState<string>('/blank')
  const name = typeof sidepanel === 'boolean' ? 'sidepanel' : sidepanel
  const iframe = window.document.querySelector(`iframe[name=${name}]`) as any

  useEffect(() => {
    if (name === 'sidepanel') {
      setUrl(getSidepanelUrl())
      const storageEventHandler = () => setUrl(getSidepanelUrl())
      window.addEventListener('storage', storageEventHandler, false)
      return () => {
        window.removeEventListener('storage', storageEventHandler, false)
      }
    } else {
      const receiveMessage = (event: any) => {
        if (event.data) {
          if (typeof event.data === 'string' && event.data.indexOf(`${name}_show:`) > -1) {
            const url = event.data.replace(`${name}_show:`, '')
            setUrl(url)
          }
        }
      }
      window.addEventListener('message', receiveMessage, false)

      return () => window.removeEventListener('message', receiveMessage, false)
    }
  }, [name])

  const openUrl = (link: string, window = iframe?.contentWindow) => {
    setUrl(link)
    if (window) {
      window.postMessage(`${name}:${link}`, '*')
    }
  }

  return { isOpen: openedUrl !== '/blank', openedUrl, openUrl }
}

export default useSidepanel
