import React, { ReactElement, useRef } from 'react'

import { TooltipProps, Tooltip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette.background.light,
    opacity: 0,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);',
    maxWidth: 'calc(100vw - 50px)',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'auto'
  }
}))

const Popover = ({
  maxWidth,
  maxHeight,
  ...props
}: { triggerElement: ReactElement; maxWidth?: number; maxHeight?: number | string } & Pick<TooltipProps, 'children' | 'placement' | 'className'>) => {
  const triggerElRef = useRef<HTMLSpanElement>(null)

  const { classes, cx } = useStyles()

  return (
    <Tooltip classes={{ tooltip: cx(classes.root, props.className) }} placement={props.placement} title={props.children}>
      <span ref={triggerElRef}>{props.triggerElement}</span>
    </Tooltip>
  )
}

export default Popover
