import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import useSidebar from '_core/hooks/useSidebar'

const useSidepanelClose = (goToUrl?: string) => {
  const history = useHistory()
  const { close } = useSidebar()

  const handleClose = () => {
    if (isSidepanel('sidepanel')) {
      close()
    } else {
      goToUrl ? history.push(goToUrl) : history.goBack()
    }
  }

  return handleClose
}

export default useSidepanelClose
