import { ReactElement, ReactNode, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import Typography from '_shared/Typography'

import SelectTagsDialog from '_core/components/dialogs/SelectTags'
import useControlsStyles from '_core/components/filters/controls/styles'
import { TagsGroupedList } from '_core/components/TagsList'

import useDialog from '_core/hooks/useDialog'
import { TaggableType, useLookUpTagValues } from '_core/hooks/useLookup'
import { groupTags } from '_core/hooks/useTagsManager'

type TagsControlProps = {
  disabled: boolean
  handleChange: (params: { [key: string]: string }) => Promise<void | undefined>
  appliedTags?: TagsSearchParam
  tagsListEl: ReactElement
  taggableType: keyof typeof TaggableType
  manageTagsLink: string
}

const TagsControlHeader = ({
  children,
  disabled,
  hanldeEditTagsClick
}: {
  children: ReactNode
  disabled: boolean
  hanldeEditTagsClick: () => void
}) => {
  const { classes } = useControlsStyles()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.header}>
      {children}
      <SelectTagsDialog.FilterTriggerEl handleClick={hanldeEditTagsClick} disabled={disabled} />
    </Box>
  )
}

export const IncludeTagsControl = ({ appliedTags, taggableType, manageTagsLink, tagsListEl, disabled, handleChange }: TagsControlProps) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)

  const updateFilters = (updatedTags: TagsSearchParam) => handleChange({ includeTags: JSON.stringify(updatedTags) })

  return (
    <>
      <TagsControlHeader disabled={disabled} hanldeEditTagsClick={openDialog}>
        <Typography variant="h4" semiBold>
          Include tags
        </Typography>
      </TagsControlHeader>
      {tagsListEl}
      <SelectTagsDialog
        isOpened={isDialogOpened}
        taggableType={taggableType}
        manageTagsLink={manageTagsLink}
        appliedTags={appliedTags}
        submit={updateFilters}
        close={closeDialog}
      />
    </>
  )
}

export const ExcludeTagsControl = ({ appliedTags, taggableType, manageTagsLink, tagsListEl, disabled, handleChange }: TagsControlProps) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)

  const updateFilters = (updatedTags: TagsSearchParam) => handleChange({ excludeTags: JSON.stringify(updatedTags) })

  return (
    <>
      <TagsControlHeader disabled={disabled} hanldeEditTagsClick={openDialog}>
        <Typography variant="h4" semiBold>
          Exclude tags
        </Typography>
      </TagsControlHeader>
      {tagsListEl}
      <SelectTagsDialog
        isOpened={isDialogOpened}
        manageTagsLink={manageTagsLink}
        taggableType={taggableType}
        appliedTags={appliedTags}
        submit={updateFilters}
        close={closeDialog}
      />
    </>
  )
}

const TagsControl = ({
  taggableType,
  appliedInclude,
  appliedExclude,
  disabled,
  blockClassName,
  handleChange,
  manageTagsLink
}: {
  taggableType: keyof typeof TaggableType
  manageTagsLink: string
  appliedInclude?: TagsSearchParam
  appliedExclude?: TagsSearchParam
  disabled: boolean
  blockClassName: string
  handleChange: (params: { [key: string]: string | string[] }) => Promise<void | undefined>
}) => {
  const { lookUpTagValues, loading: tagsOptionsLoading } = useLookUpTagValues(taggableType)
  const [tagsData, setTagsData] = useState<{ categoryName: string; tagName: string; entityCount: number }[]>()

  useEffect(() => {
    ;(async () => {
      const tagsData = await lookUpTagValues()
      if (tagsData) {
        setTagsData(tagsData)
      }
    })()
  }, [lookUpTagValues])

  return (
    <>
      <Box className={blockClassName}>
        <IncludeTagsControl
          appliedTags={appliedInclude}
          taggableType={taggableType}
          disabled={disabled || tagsOptionsLoading}
          handleChange={handleChange}
          manageTagsLink={manageTagsLink}
          tagsListEl={
            <TagsGroupedList
              items={
                tagsData
                  ? groupTags(
                      tagsData
                        .filter(
                          ({ categoryName, tagName }) => !!appliedInclude?.find(({ name, value }) => name === categoryName && value === tagName)
                        )
                        .map(({ categoryName, tagName, entityCount }) => ({ categoryName, tagName: `${tagName} (${entityCount})` }))
                    )
                  : tagsData
              }
            />
          }
        />
      </Box>
      <Box className={blockClassName}>
        <ExcludeTagsControl
          appliedTags={appliedExclude}
          taggableType={taggableType}
          disabled={disabled || tagsOptionsLoading}
          handleChange={handleChange}
          manageTagsLink={manageTagsLink}
          tagsListEl={
            <TagsGroupedList
              items={
                tagsData
                  ? groupTags(
                      tagsData
                        .filter(
                          ({ categoryName, tagName }) => !!appliedExclude?.find(({ name, value }) => name === categoryName && value === tagName)
                        )
                        .map(({ categoryName, tagName, entityCount }) => ({ categoryName, tagName: `${tagName} (${entityCount})` }))
                    )
                  : tagsData
              }
            />
          }
        />
      </Box>
    </>
  )
}

export default TagsControl
