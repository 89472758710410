import React, { HTMLAttributes, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import ProfileItem from '_core/components/ProfileItem'

export type CompanyOptionType = {
  name: string
  website: string
}

export type SuggestCompanyOptionType = CompanyOptionType & { label: string }

export const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: CompanyOptionType | SuggestCompanyOptionType) => {
  return (
    <li {...props} key={value.website || value.name}>
      {'label' in value ? (
        <Typography>{value.label}</Typography>
      ) : (
        <Box ml={-2} mr={-2}>
          <ProfileItem name={value.name} logoUrl={value.website} byline={value.website} />
        </Box>
      )}
    </li>
  )
}

export const useCreateCompanyOption = (closeDialog: () => void) => {
  const [createdOption, setCreatedOption] = useState<CompanyOptionType>()

  const closeCreateOptionDialog = () => {
    setCreatedOption(undefined)
    closeDialog()
  }

  return {
    createdOption,
    setCreatedOption,
    closeCreateOptionDialog
  }
}

const validateWebsite = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/i

const initCreatedOption = {
  name: '',
  website: ''
}

const errorMsg = 'Not valid value'

const CreateCompanyOptionDialog = ({
  opened,
  close,
  submit,
  value
}: {
  opened: boolean
  close: () => void
  submit: (formValues: CompanyOptionType) => void
  value?: CompanyOptionType
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: initCreatedOption })

  useEffect(() => {
    if (value) {
      Object.entries(value).forEach(([fieldName, fieldValue]: any) => {
        setValue(fieldName, fieldValue)
      })
    }
  }, [setValue, value])

  const save = (formValues: CompanyOptionType) => {
    submit(formValues)
  }

  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Add a new company" />} maxWidth={420}>
      <DialogContent>
        <Box>
          <TextField
            {...register('name', {
              required: errorMsg,
              pattern: {
                value: /\S/,
                message: errorMsg
              }
            })}
            label="Name"
            fullWidth
            errorMessage={errors.name?.message}
          />
          <Box mt={1.5}>
            <TextField
              {...register('website', {
                required: errorMsg,
                pattern: {
                  value: validateWebsite,
                  message: errorMsg
                }
              })}
              label="Website"
              fullWidth
              errorMessage={errors.website?.message}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary" disablePL>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit(save)} disabled={!!Object.keys(errors).length} disablePR>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCompanyOptionDialog
