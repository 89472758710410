import { CSSProperties, ReactElement, ReactNode } from 'react'

import { TimelineOppositeContent } from '@mui/lab'
import MUITimeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { SkeletonProps } from '_shared/Skeleton'
import Typography from '_shared/Typography'

import EmptyText from '_core/components/EmptyText'

import Item from './Item'

const useStyles = makeStyles<{ loading?: boolean }>()((theme, { loading }) => ({
  widget: {
    margin: `-${theme.spacing(2)}`
  },
  root: {
    padding: 0,
    marginBottom: 0,
    marginTop: 0
  },
  item: {
    padding: `0px ${theme.spacing(2)}`,
    boxSizing: 'border-box',
    '&:before': {
      content: 'none'
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
      '& .MuiTimelineSeparator-root': {
        marginBottom: 0
      },
      '& .MuiTimelineContent-root': {
        borderBottom: 'none'
      },
      '& .MuiTimelineConnector-root': {
        display: loading ? 'block' : 'none'
      }
    }
  },
  dot: {
    padding: 2,
    marginBottom: 0,
    marginTop: 0,
    alignSelf: 'unset',
    boxShadow: 'none'
  },
  warningDot: {
    backgroundColor: theme.palette.warning.main
  },
  cancelledDot: {
    backgroundColor: theme.palette.text.secondary
  },
  hiddenDot: {
    border: 'none',
    padding: 0
  },
  connector: {
    width: 0,
    background: 'none',
    borderRight: `0.5px solid`,
    opacity: 0.7,
    marginRight: 0.5,
    borderColor: loading ? '#ccc' : theme.palette.primary.main
  },
  content: {
    overflow: 'auto',
    padding: 0,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(0.5)
  },
  highLight: {
    '&, &:hover': {
      background: theme.palette.hover.secondary
    }
  },
  underlined: {
    padding: '6px 0px',
    borderBottom: `1px solid #eceef0`
  },
  separator: {
    zIndex: 2,
    display: 'flex',
    alignContent: 'center',
    minWidth: 24,
    margin: `36px 0px -36px`
  },
  skip: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    color: 'rgba(0,0,0,0.5)',
    fontSize: 13
  },
  timeRange: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    zIndex: 1,
    background: 'rgba(244 244 244 / 60%)',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  }
}))

type TimeLineItemProps = {
  index: number
  loading: SkeletonProps['loading']
  item: any
  addprops?: any
  component: React.FC<any>
  oppositeComponent?: ReactElement
  underlined?: boolean
  fromSkip?: number
  toSkip?: number
  style?: CSSProperties
  disablePadding?: boolean
}

type TimelineProps = {
  items: TimeLineItemProps['item'][]
  skeleton: SkeletonProps
  component: TimeLineItemProps['component']
  underlined?: TimeLineItemProps['underlined']
  addprops?: TimeLineItemProps['addprops']
  skip?: [from: number, to: number]
  empty?: string
}

export const TimeContainer = ({ children, className }: { children: ReactNode; className?: string }) => {
  const { classes, cx } = useStyles({})
  return <Typography classes={{ root: cx(classes.timeRange, className) }}>{children}</Typography>
}

export const TimeLineItem = ({
  index,
  loading,
  item,
  addprops,
  component,
  oppositeComponent,
  underlined,
  fromSkip = 0,
  toSkip = 0,
  style,
  disablePadding
}: TimeLineItemProps) => {
  const { classes, cx } = useStyles({ loading })
  const { dot, hiddenDot, warningDot, cancelledDot } = classes

  return (
    <TimelineItem classes={{ root: cx(classes.item, { [classes.highLight]: item.isSelected }, item.className) }} style={style}>
      {oppositeComponent}
      <TimelineSeparator classes={{ root: classes.separator }}>
        <TimelineDot classes={{ root: cx(dot, { [hiddenDot]: loading, [warningDot]: item.warned, [cancelledDot]: item.cancelled }) }} color="primary">
          {item.dotIcon}
        </TimelineDot>
        <TimelineConnector classes={{ root: classes.connector }} />
      </TimelineSeparator>
      <TimelineContent classes={{ root: cx(classes.content, { [classes.underlined]: underlined }) }}>
        <Item index={index} addprops={addprops} item={item} component={component} disablePadding={disablePadding} />
        {!loading && index + 1 === fromSkip && !!(toSkip - fromSkip) && (
          <Typography classes={{ root: classes.skip }}>...{toSkip - fromSkip} more events</Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  )
}

export const TimelineList = ({ items, underlined, component, skeleton, addprops, skip, empty }: TimelineProps) => {
  const { loading } = skeleton
  const fromSkip = skip ? (items?.length <= skip[0] ? items?.length - 1 : skip[0]) : 0
  const toSkip = skip ? (items?.length > skip[1] && skip[1] > skip[0] ? skip[1] : items?.length - 1) : 0

  const shownItems = skip ? items?.slice(0, fromSkip).concat(items?.slice(toSkip)) : items
  const renderItems = loading ? new Array(skeleton.size).fill({}) : shownItems || []

  if (!loading && (!items || items.length === 0) && empty) return <EmptyText text={empty} />

  if (!skeleton.loading && (!items || items.length === 0) && empty) return <EmptyText text={empty} />

  return (
    <>
      {renderItems.map((item: any, i: number) => (
        <TimeLineItem
          key={i}
          item={item}
          index={i}
          underlined={underlined}
          fromSkip={fromSkip}
          toSkip={toSkip}
          addprops={addprops}
          component={component}
          loading={skeleton.loading}
        />
      ))}
    </>
  )
}

const Timeline = ({
  children,
  loading,
  scope = 'none',
  className,
  position
}: {
  children: ReactNode
  loading: boolean
  scope?: 'widget' | 'none'
  className?: string
  position?: 'left' | 'right'
}) => {
  const { classes, cx } = useStyles({ loading })

  return (
    <Box className={cx(className, scope !== 'none' ? classes[scope] : '')}>
      <MUITimeline classes={{ root: cx(className, classes.root) }} position={position}>
        {children}
      </MUITimeline>
    </Box>
  )
}

export { TimelineOppositeContent }

export default Timeline
