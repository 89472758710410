import React, { ComponentProps, ReactElement } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, TypographyProps } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridValueGetterParams, GridRowId, getGridStringOperators } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Popover from '_shared/Popover'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AffiliationPopover } from '_core/components/Affiliation'
import { IsPrivateRelationshipBudge } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import SidepanelLink from '_core/components/SidepanelLink'
import StatusIcon from '_core/components/StatusIcon'
import TagsGroup from '_core/components/TagsGroup'

import { groupTags } from '_core/hooks/useTagsManager'

import { formatDateTime, formatDateFromNow, getLocal } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  status: {
    textAlign: 'center',
    width: '100%'
  },
  fitContentWidth: {
    maxWidth: 'fit-content'
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px',
    minWidth: '16px'
  }
}))

export const TextCell = React.memo(({ value, bold, color }: { value: string; bold?: boolean; color?: TypographyProps['color'] }) => {
  const { classes } = useStyles()
  return (
    <Tooltip title={value}>
      <Typography color={color || 'inherit'} bold={bold} ellipsis classes={{ root: classes.fitContentWidth }}>
        {value}
      </Typography>
    </Tooltip>
  )
})

export const TextWithIconCell = React.memo(
  ({ text, bold, textColor, icon }: { text: string; icon: IconProp; bold?: boolean; textColor?: TypographyProps['color'] }) => {
    const { classes } = useStyles()
    return (
      <Tooltip title={text}>
        <Box display="flex" alignItems="center">
          <FontAwesomeIcon icon={icon} className={classes.icon} />
          <Typography color={textColor || 'inherit'} bold={bold} ellipsis classes={{ root: classes.fitContentWidth }}>
            {text}
          </Typography>
        </Box>
      </Tooltip>
    )
  }
)

export const DescriptionCell = React.memo(({ value }: { value: string }) => (
  <Popover placement="bottom" maxWidth={400} triggerElement={<Typography ellipsis>{value}</Typography>}>
    <Typography>{value}</Typography>
  </Popover>
))

export const TwoLinedCell = ({
  title,
  byline1,
  alignment
}: {
  title: ReactElement | string
  byline1: ReactElement | string
  alignment?: 'flex-end' | 'center'
}) => {
  const { classes } = useStyles()
  return (
    <Box display="flex" flexDirection="column" alignItems={alignment || 'flex-start'} lineHeight="1.2" width={'calc(100% - 24px)'} overflow="hidden">
      {typeof title === 'string' ? <TextCell value={title} /> : title}
      {typeof byline1 === 'string' ? (
        <Tooltip title={byline1}>
          <Typography classes={{ root: classes.fitContentWidth }} style={{ fontSize: 14 }} color="textSecondary" ellipsis>
            {byline1}
          </Typography>
        </Tooltip>
      ) : (
        byline1
      )}
    </Box>
  )
}

export const SidepanelLinkCell = React.memo(
  (props: { onClick: (id: GridRowId) => void; id: GridRowId; value: string; link: string; sidepanel: SidepanelType }) => {
    const { onClick, id, value, link, sidepanel } = props
    const { classes } = useStyles()

    return (
      <SidepanelLink linkProps={{ to: link, className: classes.link }} sidepanel={sidepanel} onClick={() => onClick(id)}>
        <TextCell value={value} />
      </SidepanelLink>
    )
  }
)

export const DateCell = React.memo(({ value, label }: { value: string; label: string }) => (
  <>
    {value ? (
      <Tooltip title={value}>
        <Typography color="text.secondary" semiBold>
          {label}
        </Typography>
      </Tooltip>
    ) : null}
  </>
))

export const EmailCell = React.memo(({ value }: { value: string }) => (
  <Tooltip title={value}>
    <Typography color="primary.main" ellipsis>
      <a href={`mailto:${value}`} onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}>
        {value}
      </a>
    </Typography>
  </Tooltip>
))

export const ActionCell = React.memo(({ action }: { action: ReactElement }) => <>{action}</>)

export const NameCell = React.memo((params: GridRenderCellParams) => {
  const { classes } = useStyles()

  return (
    <Box width={1} display="grid" alignItems="center" gridTemplateColumns="minmax(0, auto) 1fr" height="100%">
      {params.row.name && (
        <>
          <SidepanelLink
            linkProps={{ to: params.row.linkAlt || params.row.link, className: classes.link }}
            sidepanel={params.row.sidepanel}
            onClick={() => params.row.onClick(params.id)}
          >
            <Box display="grid" alignItems="center" gridTemplateColumns="1fr minmax(0px, auto)">
              <AvatarWrapper name={params.value.toString()} userKey={params.row.email} logoUrl={params.row.logoUrl} size="xs" mode="dark" hideName />
              <Box ml={1}>
                <TextCell value={params.row.name} />
              </Box>
            </Box>
          </SidepanelLink>
          <Box display="flex" alignItems="center">
            {params.row.formerJob && (
              <Box ml={1.5}>
                <AffiliationPopover workExperience={params.row.workExperience} icon={['far', 'address-book']} />
              </Box>
            )}
            {params.row.isPrivateRelations && <IsPrivateRelationshipBudge userName={params.row.name} />}
            {params.row.tags && (
              <Box ml={1.5}>
                <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                  items={params.row.tags}
                  tagComponent={InternalTagPopover}
                  renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={params.row.showAllTagsLink} />}
                      items={params.row.tags ? groupTags(params.row.tags) : []}
                    />
                  )}
                  addTagTriggerEl={<AddTagTriggerEl link={params.row.editTagsLink} hasAny={!!params.row.tags?.length} />}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
})

export const nameColumn: GridColDef = {
  field: 'name',
  headerName: 'Name',
  width: 250,
  minWidth: 250,
  flex: 1,
  hideable: false,
  pinnable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <NameCell {...params} />
}

export const jobTitleColumn: GridColDef = {
  field: 'title',
  headerName: 'Job title',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.title} />
}

export const companyColumn: GridColDef = {
  field: 'company',
  headerName: 'Company',
  width: 200,
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { company = {}, onClick } = row
    return <SidepanelLinkCell value={company.name} id={id} onClick={onClick} link={company.link} sidepanel={company.sidepanel} />
  }
}

export const keyContactColumn: GridColDef = {
  field: 'keyContact',
  headerName: 'Key contact',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { keyContact = {}, onClick } = row
    return <SidepanelLinkCell value={keyContact.name} id={id} onClick={onClick} link={keyContact.link} sidepanel={keyContact.sidepanel} />
  }
}

export const bestIntroducerColumn: GridColDef = {
  field: 'bestIntroducer',
  headerName: 'Best introducer',
  width: 200,
  minWidth: 120,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { bestIntroducer = {}, onClick } = row
    return <SidepanelLinkCell value={bestIntroducer.name} id={id} onClick={onClick} link={bestIntroducer.link} sidepanel={bestIntroducer.sidepanel} />
  }
}

export const scoreColumn: GridColDef = {
  field: 'score',
  headerName: 'Score',
  align: 'right',
  headerAlign: 'right',
  width: 110,
  minWidth: 100,
  type: 'number',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.score} />
}

export const emailColumn: GridColDef = {
  field: 'email',
  headerName: 'Email',
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <EmailCell value={params.row.email} />
}

export const phoneColumn: GridColDef = {
  field: 'phone',
  headerName: 'Phone',
  width: 180,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.phone} />
}

export const dateColumn: GridColDef = {
  field: 'date',
  headerName: 'Date',
  width: 195,
  minWidth: 100,
  flex: 0.7,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { date } = params.row
    const formatted = formatDateTime(date)
    return date ? <DateCell value={formatted} label={formatted} /> :
      <Typography color="text.secondary">no date</Typography>
  }
}

export const actionButtonColumn: GridColDef = {
  field: 'actionButton',
  headerName: 'Action',
  align: 'center',
  headerAlign: 'center',
  width: 300,
  minWidth: 200,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <ActionCell action={params.row.actionButton} />
}

const NameWithEmailCell = React.memo((params: GridRenderCellParams) => {
  const { email } = params.row
  return (
    <TwoLinedCell
      title={<NameCell {...params} />}
      byline1={
        <Tooltip title={email}>
          <Typography color="text.secondary" ellipsis>
            <a href={`mailto:${email}`} onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}>
              {email}
            </a>
          </Typography>
        </Tooltip>
      }
    />
  )
})

export const nameWithEmailColumn: GridColDef = {
  ...nameColumn,
  renderCell: (params: GridRenderCellParams) => <NameWithEmailCell {...params} />
}

export const lastInboundColumn: GridColDef = {
  field: 'lastInboundMsg',
  headerName: 'Last inbound',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastInbound && <DateCell value={formatDateTime(params.row.lastInbound)} label={formatDateFromNow(params.row.lastInbound, 3)} />
}

export const lastOutboundColumn: GridColDef = {
  field: 'lastOutboundMsg',
  headerName: 'Last outbound',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastOutbound && <DateCell value={formatDateTime(params.row.lastOutbound)} label={formatDateFromNow(params.row.lastOutbound, 3)} />
}

export const lastMeetingColumn: GridColDef = {
  field: 'lastMeeting',
  headerName: 'Last meeting',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastMeeting && <DateCell value={formatDateTime(params.row.lastMeeting)} label={formatDateFromNow(params.row.lastMeeting, 3)} />
}

export const nextFutureMeetingColumn: GridColDef = {
  field: 'nextFutureMeeting',
  headerName: 'Next meeting',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: true,
  renderCell: (params: GridRenderCellParams) =>
    params.row.nextFutureMeeting && (
      <DateCell value={formatDateTime(params.row.nextFutureMeeting)} label={formatDateFromNow(params.row.nextFutureMeeting, 3)} />
    )
}

const StatusCell = React.memo((params: GridRenderCellParams) => {
  const { classes } = useStyles()
  return (
    <Box width="100%">
      <StatusIcon status={params.row.status} className={classes.status} />
    </Box>
  )
})

export const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  width: 100,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <StatusCell {...params} />
}

export const websiteColumn: GridColDef = {
  field: 'website',
  headerName: 'Website',
  width: 200,
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { website } = params.row
    return (
      website && (
        <Tooltip title={website}>
          <Typography color="primary.main" ellipsis>
            <a
              href={`https://${website}`}
              target="_blank"
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
              rel="noreferrer"
            >
              {website}
            </a>
          </Typography>
        </Tooltip>
      )
    )
  }
}

export const editorColumn: GridColDef = {
  field: 'editor',
  headerName: 'Updated By',
  width: 160,
  minWidth: 160,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { id, editor, onClick, editorLink, sidepanel } = params.row
    return editor ? (
      <SidepanelLinkCell value={editor} id={id} onClick={onClick} link={editorLink} sidepanel={sidepanel} />
    ) : (
      <Typography color="text.secondary">not specified</Typography>
    )
  }
}
