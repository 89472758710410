import { useState, ReactElement } from 'react'

import { Box, Collapse } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Skeleton, { SkeletonProps } from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Repeater from '_core/components/lists/Repeater'

type TextContentProps = {
  skeleton: SkeletonProps
  body?: EventType['body']
  minLength?: number
  maxLength?: number
  showMore?: (val: { expanded: boolean; toggleExpanded: () => void }) => ReactElement
  className?: string
  ellipsis?: boolean
}

const useStyles = makeStyles()(() => ({
  text: {
    padding: 0,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    userSelect: 'text'
  }
}))

const TextContent = (props: TextContentProps) => {
  const { classes } = useStyles()
  const [expanded, setExpanded] = useState<boolean>(false)

  const summary = props.body?.slice(0, props.minLength)
  const details = summary ? props.body?.slice(summary.length, props.maxLength) : ''

  if (props.skeleton?.loading) {
    return (
      <Repeater
        className={props.className}
        skeleton={props.skeleton}
        items={[summary]}
        component={() => (
          <Skeleton condition={props.skeleton?.loading} width="100%">
            <Typography>placeholder</Typography>
          </Skeleton>
        )}
      />
    )
  }

  const toggleExpanded = () => {
    setExpanded((prevState: boolean) => !prevState)
  }

  return (
    <>
      {props.body && (
        <Box className={props.className}>
          <Typography classes={{ root: classes.text }}>{summary}</Typography>
          <Collapse in={expanded}>
            <Typography classes={{ root: classes.text }}>{details}</Typography>
          </Collapse>
          {props.ellipsis && <>...&nbsp;</>}
          {props.showMore && props.showMore({ expanded, toggleExpanded })}
        </Box>
      )}
    </>
  )
}

export default TextContent
