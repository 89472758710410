import { useEffect, useState } from 'react'

import { SelectChangeEvent } from '@mui/material'

const useSharingLevel = (getUserSharing: () => SharingDataRes | undefined) => {
  const [userSharing, setUserSharing] = useState<SharingDataRes | undefined>()
  const [sharingContactInfoLevel, setSharingContactInfoLevel] = useState(2)

  useEffect(() => updateSharingContactInfoLevel(), [userSharing])

  useEffect(() => {
    setUserSharing(getUserSharing())
  }, [getUserSharing])

  const updateSharingContactInfoLevel = () => {
    if (userSharing) {
      const { shareContactInfo, shareContactInfoWithAdminsAndCurators } = userSharing
      if (!shareContactInfo && !shareContactInfoWithAdminsAndCurators) {
        setSharingContactInfoLevel(1)
      }
      if (shareContactInfo && shareContactInfoWithAdminsAndCurators) {
        setSharingContactInfoLevel(2)
      }
      if (!shareContactInfo && shareContactInfoWithAdminsAndCurators) {
        setSharingContactInfoLevel(3)
      }
    }
  }

  const handleInteractionClick = async () => {
    setUserSharing((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          shareRelationships: true,
          shareInteractionStats: !prevState.shareInteractionStats
        }
      }
    })
  }

  const handleSharingClick = async () => {
    if (userSharing) {
      setUserSharing((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            shareRelationships: !prevState.shareRelationships,
            shareContactInfo: prevState.shareRelationships ? false : prevState.shareContactInfo,
            shareInteractionStats: prevState.shareRelationships ? false : prevState.shareInteractionStats,
            shareContactInfoWithAdminsAndCurators: prevState.shareContactInfoWithAdminsAndCurators
              ? false
              : prevState.shareContactInfoWithAdminsAndCurators
          }
        }
      })
    }
  }

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    switch (event.target.value) {
      case 1:
        setUserSharing((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              shareContactInfo: false,
              shareContactInfoWithAdminsAndCurators: false
            }
          }
        })
        setSharingContactInfoLevel(1)
        break
      case 2:
        setUserSharing((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              shareRelationships: true,
              shareContactInfo: true,
              shareContactInfoWithAdminsAndCurators: true
            }
          }
        })
        setSharingContactInfoLevel(2)
        break
      case 3:
        setUserSharing((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              shareRelationships: true,
              shareContactInfo: false,
              shareContactInfoWithAdminsAndCurators: true
            }
          }
        })
        setSharingContactInfoLevel(3)
    }
  }

  return {
    sharingContactInfoLevel,
    userSharing,
    handleInteractionClick,
    handleSelect,
    handleSharingClick
  }
}

export default useSharingLevel
