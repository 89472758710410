import { useState } from 'react'

type ResponseType = { scroll_token: string; total: number; error?: string }
export type CSuiteType = { id: string; website: string; data: { [key: string]: any }[] } & ResponseType

export const useCSuite = () => {
  const [cSuite, setCSuite] = useState<CSuiteType | null>(null)

  const getPersonDetails = (name: string, email?: string) =>
    cSuite?.data?.find((item: { [key: string]: any }) => item.full_name === name || (email && item.work_email === email))

  return {
    cSuite,
    setCSuite,
    getPersonDetails
  }
}
