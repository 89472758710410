import React from 'react'

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip'

const Tooltip = ({
  children,
  title,
  disabled,
  placement
}: {
  disabled?: boolean
} & Pick<TooltipProps, 'placement' | 'children' | 'title'>) => {
  if (disabled) {
    return children
  }

  return (
    <MuiTooltip placement={placement} title={title}>
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
