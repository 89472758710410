import { isValidElement, SyntheticEvent } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'

import { SummaryContent, SummaryItemProps, itemHeight } from '_core/components/SummaryItem'

export const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: itemHeight,
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  expandable: {
    height: 'auto'
  },
  default: {
    height: itemHeight
  },
  itemContent: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '48px minmax(0, 100%) minmax(0, auto)'
  },
  wrapper: {
    marginLeft: theme.spacing(2)
  },
  highLight: {
    background: theme.palette.hover.secondary
  },
  pointer: {
    cursor: 'pointer'
  }
}))

const ProfileItem = ({ name, byline3, userKey, logoUrl, isSelected, className, variant, onClick, ...props }: ProfileItemProps) => {
  const { classes, cx } = useStyles()

  return (
    <Box
      className={cx(
        classes.item,
        {
          [classes.expandable]: variant === 'expandable',
          [classes.default]: !variant,
          [classes.highLight]: isSelected,
          [classes.pointer]: !!onClick
        },
        className
      )}
      onClick={onClick}
    >
      <Box className={classes.itemContent}>
        <AvatarWrapper name={name} userKey={userKey} size="sm" logoUrl={logoUrl} hideName />
        <SummaryContent {...props} title={name} className={classes.wrapper} />
      </Box>
      {isValidElement(byline3) && (
        <Box ml={8} maxWidth="100%" overflow="hidden">
          {byline3}
        </Box>
      )}
    </Box>
  )
}

export default ProfileItem

export type ProfileItemProps = {
  name: string
  userKey?: string
  logoUrl?: string
  isSelected?: boolean
  onClick?: (e: SyntheticEvent) => void
} & Omit<SummaryItemProps, 'title'>
