import { useState, useCallback, useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

import { put } from 'utils/httpUtils'

export const useDataVisibility = () => {
  const [dataVisibilitySaving, setDataVisibilitySaving] = useState(false)
  const { fetchWithAbort, result, setResult, loading, reset } = useAbortableFetch<UserDataVisibilityParams>()

  const checkDataVisibility = async () => {
    reset()
    return await fetchWithAbort({ url: '/usersettings/datavisibility' })
  }

  const saveDataVisibility = useCallback(async (payload: UserDataVisibilityParams) => {
    setDataVisibilitySaving(true)
    try {
      return await put<UserDataVisibilityParams>('/adminsettings/datavisibility', payload)
    } finally {
      setDataVisibilitySaving(false)
    }
  }, [])

  return {
    dataVisibility: result,
    dataVisibilityLoading: loading,
    dataVisibilitySaving,
    saveDataVisibility,
    checkDataVisibility,
    updateDataVisibility: setResult
  }
}

const useUserDataVisibility = (refetchDeps: string[] = []) => {
  const { updateDataVisibility } = useNavBarMenuItemsShown()
  const { checkDataVisibility, ...dataVisibility } = useDataVisibility()

  useEffect(() => {
    ;(async () => {
      updateDataVisibility(await checkDataVisibility())
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchDeps)

  return dataVisibility
}

export default useUserDataVisibility
