import React, { useEffect, useState } from 'react'

import { Container, CircularProgress, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import CreateTeam from '_core/components/CreateTeam'
import Empty from '_core/components/Empty'
import { Narrow, Wide } from '_core/components/layout'
import TeamCard from '_core/components/team/TeamCard'

import useAdmin from '_core/hooks/useAdmin'
import { useUserRelations } from '_core/hooks/useTeam'
import useTransformTeamData from '_core/hooks/useTransformTeamData'

const useStyles = makeStyles()((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  space: {
    paddingBottom: theme.spacing(3)
  },
  teamsList: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      margin: '0 -2vw 0 -2vw'
    },
    '&:after': {
      content: '""',
      flex: 'auto'
    }
  },
  teamsList__wrapper: {
    backgroundColor: theme.palette.background.darker,
    minHeight: 'calc(100vh - 60px)'
  },
  teamsItem: {
    width: '100%',
    paddingTop: 20,
    '&:first-of-type': {
      paddingTop: 0
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      margin: '2vw',
      width: 'calc(33.33% - 4vw)'
    }
  },
  empty: {
    marginTop: '2vw',
    paddingTop: theme.spacing(7.5),
    paddingBottom: theme.spacing(7.5)
  },
  placeholder: {
    width: '100%',
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      cursor: 'pointer'
    }
  },
  nopad: {
    padding: `0px ${theme.spacing(2.5)}`,
    '&:last-of-type': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
      '&:last-of-type': {
        paddingBottom: 0
      }
    }
  },
  teamWrapper: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(6)
    }
  },
  subtitleWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`
    },
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  }
}))

interface ITeamListProps {
  items: TeamDataRes[]
  loading: boolean
  total: number
}

const TeamsList = ({ items, loading }: ITeamListProps) => {
  const [teams, setTeams] = useState<Team[]>()
  const [myTeams, setMyTeams] = useState<Team[]>()
  const [otherTeams, setOtherTeams] = useState<Team[]>()
  const [removedTeamList, setRemovedTeamList] = useState<any[]>([])
  const [load, setLoad] = useState<boolean>(false)
  const admin = useAdmin()
  const { getUserRelations, userRelations } = useUserRelations()
  const { transformTeamList } = useTransformTeamData()
  const { classes, cx } = useStyles()

  useEffect(() => {
    ;(async () => {
      const list = JSON.parse(localStorage.getItem('removed_teams') || '[]')
      setRemovedTeamList(list)
      await getUserRelations()
    })()
  }, [])

  useEffect(() => {
    if (teams?.length) {
      const ids = teams.map((team) => team.id)

      if (removedTeamList.length) {
        const updated = removedTeamList.filter((prevId: number) => ids.includes(prevId))
        localStorage.setItem('removed_teams', JSON.stringify(updated))
      }
    }

    if (!loading && !load && !teams?.length && removedTeamList.length) {
      localStorage.setItem('removed_teams', JSON.stringify([]))
    }
  }, [teams])

  useEffect(() => {
    if (Array.isArray(teams) && userRelations) {
      setMyTeams(teams.filter(filterMyTeams).filter(filterRemovedTeams))
      setOtherTeams(teams.filter(filterOtherTeams).filter(filterRemovedTeams))
    }
  }, [teams, userRelations, removedTeamList])

  useEffect(() => {
    if (!loading && items) {
      setTeams(transformTeamList(items))
    }
  }, [items])

  const filterMyTeams = (team: Team) => {
    if (!userRelations || !userRelations.length) return false
    return Boolean(userRelations.find((relation) => relation.teamNumber === team.id))
  }

  const filterOtherTeams = (team: Team) => {
    if (!userRelations || !userRelations.length) return true
    return !userRelations.find((relation) => relation.teamNumber === team.id)
  }

  const filterRemovedTeams = (team: Team) => !removedTeamList.includes(team.id)

  const NoTeams = <Empty title="No results" className={classes.empty} />

  return (
    <Box className={classes.teamsList__wrapper}>
      <Wide>
        <div className={cx(classes.heading, admin && classes.space)}>
          <Typography variant="h1">Teams</Typography>
          {admin && <CreateTeam setLoading={setLoad} />}
        </div>
      </Wide>
      {admin && (
        <Narrow>
          <CreateTeam setLoading={setLoad} variant="fab" />
        </Narrow>
      )}

      {(loading || load) && (
        <div className={classes.placeholder}>
          <CircularProgress color="secondary" />
        </div>
      )}

      {!loading && !load && Array.isArray(myTeams) && (
        <>
          <Box className={classes.teamWrapper}>
            <Box className={classes.subtitleWrapper}>
              <Typography variant="h3" color="text.hint" semiBold>
                My teams
              </Typography>
              <Typography color="text.secondary">Teams to which you are contributing data, or have Reader/Curator access</Typography>
            </Box>
            <Container maxWidth="lg" className={classes.nopad}>
              {myTeams.length ? (
                <Box className={classes.teamsList}>
                  {myTeams.map((team: Team) => (
                    <Box className={classes.teamsItem} key={team.id}>
                      <TeamCard team={team} userRelations={userRelations} admin={admin} isContributor />
                    </Box>
                  ))}
                </Box>
              ) : (
                <>{NoTeams}</>
              )}
            </Container>
          </Box>
          {admin && Array.isArray(otherTeams) && (
            <Box className={classes.teamWrapper}>
              <Box className={classes.subtitleWrapper}>
                <Typography variant="h3" color="text.hint" semiBold>
                  Other teams
                </Typography>
                <Typography color="text.secondary">Teams which you are not a member of, but have access to</Typography>
              </Box>

              <Container maxWidth="lg" className={classes.nopad}>
                {otherTeams.length ? (
                  <Box className={classes.teamsList}>
                    {otherTeams.map((team: Team) => (
                      <Box className={classes.teamsItem} key={team.id}>
                        <TeamCard team={team} admin={admin} userRelations={userRelations} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <>{NoTeams}</>
                )}
              </Container>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default TeamsList
