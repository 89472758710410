import { useEffect, useState } from 'react'

import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'

const useIconsUpdate = (icons: [IconPrefix, IconName][], speed: number = 1000) => {
  const [iconIndex, setIconIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIconIndex((prevState) => (prevState === icons.length - 1 ? 0 : prevState + 1))
    }, speed)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return icons[iconIndex]
}

export default useIconsUpdate
