import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Typography from '_shared/Typography'

const ReportIncorrectDataDialog = ({ opened, close, marketData }: { opened: boolean; close: () => void; marketData: string }) => {
  const lineBreak = '\r\n'
  const href =
    'mailto:team@dotalign.com?subject=Report incorrect data&body=' +
    encodeURIComponent('------ Please do not change anything below this line -----' + lineBreak + lineBreak) +
    encodeURIComponent(marketData)

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    window.location.href = href
    close()
  }

  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Report incorrect data?" />}>
      <DialogContent>
        <Typography>
          DotAlign works with its partners to deliver the most accurate and up to date market data. However, occasionally, there may be errors,
          inaccuracies or missing information. Please take a moment to send us a report so we can take corrective action.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleClick} variant="text" disablePR>
          Report
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportIncorrectDataDialog
