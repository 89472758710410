import React, { useContext } from 'react'

import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import TeamModal from '_core/components/dialogs/Team'

import useDialog from '_core/hooks/useDialog'
import useTeam, { TeamCreationData } from '_core/hooks/useTeam'

import Paths from 'Paths'

interface IAddTeam {
  setLoading: (val: boolean) => void
  variant?: 'default' | 'fab'
}

const CreateTeam = ({ setLoading, variant = 'default' }: IAddTeam) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const { updateTeamFetchCounter, teamContextValue, setTeamContext } = useContext(TeamContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { addTeam } = useTeam()

  const handleAddTeam = async (data: TeamCreationData) => {
    setLoading(true)
    try {
      const newTeam = await addTeam(data)
      if (newTeam) {
        enqueueSnackbar(`Team "${data.teamName}" has been created`)
        updateTeamFetchCounter()
        if (!teamContextValue.teamNumber) {
          setTeamContext({ teamNumber: newTeam.id })
        }
        history.push(`${Paths._teams}/${newTeam.id}`)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <TeamModal.TriggerCreateEl variant={variant} open={openDialog} />
      <TeamModal isOpen={isDialogOpened} close={closeDialog} handleAddClick={handleAddTeam} mode="creating" />
    </>
  )
}

export default CreateTeam
