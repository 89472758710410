import React, { useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputProps, TextFieldProps } from '@mui/material'
import { LocalizationProvider, TimePickerProps } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker/TimePicker'
import { Moment } from 'moment'
import { withStyles } from 'tss-react/mui'

import TextField, { IInputBox } from '_shared/forms/TextField'

const OpenPickerIcon = () => <FontAwesomeIcon icon={['far', 'clock']} style={{ fontSize: 14 }} />

const TimePicker = ({
  placeholder,
  fullWidth,
  size,
  views,
  errorMessage,
  ...props
}: Omit<TimePickerProps<Moment, Moment>, 'renderInput'> &
  Pick<InputProps, 'size' | 'fullWidth' | 'placeholder'> &
  Pick<IInputBox, 'errorMessage'>) => {
  const renderDefaultInput = useCallback(
    (params: TextFieldProps) => (
      <TextField {...params} errorMessage={errorMessage} size={size || 'small'} placeholder={placeholder} fullWidth={fullWidth} />
    ),
    [size, placeholder, fullWidth, errorMessage]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Picker {...props} components={{ OpenPickerIcon }} renderInput={renderDefaultInput} views={views || ['hours', 'minutes']} />
    </LocalizationProvider>
  )
}

const Picker = withStyles(MuiTimePicker, () => ({
  root: {
    '.MuiClock-amButton, .MuiClock-pmButton': {
      '& > span': {
        lineHeight: '17px'
      }
    }
  }
}))

export default TimePicker
