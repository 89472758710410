export const invalidReasonMap = {
  Split: 'not associated',
  Merge: 'associated',
  AutomatedSender: 'junk/invalid',
  CommonName: 'common name',
  JunkName: 'junk/invalid',
  SpamDomain: 'junk/invalid',
  PersonalDomain: 'personal domain',
  SharedEmail: 'shared email address',
  SharedDomain: 'shared domain'
}

export const cEntityTypes: { value: ManualEditsEntityType; label: string }[] = [
  {
    value: 'CompanyDisqualifier',
    label: 'Disqualifier'
  },
  {
    value: 'CompanyPreferredName',
    label: 'Preferred name'
  },
  {
    value: 'CompanyPreferredUrl',
    label: 'Preferred url'
  },
  {
    value: 'CompanyTuple',
    label: 'Tuple'
  }
]

export const pEntityTypes: { value: ManualEditsEntityType; label: string }[] = [
  {
    value: 'PersonDisqualifier',
    label: 'Disqualifier'
  },
  {
    value: 'PersonPreferredName',
    label: 'Preferred name'
  },
  {
    value: 'PersonEmail',
    label: 'Preferred email'
  },
  {
    value: 'PersonTuple',
    label: 'Tuple'
  },
  { value: 'PersonJob', label: 'Job' },
  { value: 'PersonPhone', label: 'Phone' }
]
