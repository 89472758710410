import { useContext } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAdmin from '_core/hooks/useAdmin'
import useUserMembership from '_core/hooks/useUserMembership'

const useAdminOrCuratorCheck = () => {
  const { teamContextValue } = useContext(TeamContext)
  const admin = useAdmin()
  const { curator } = useUserMembership(admin === false ? teamContextValue.teamNumber : 0)

  const isAdminOrCurator = admin === false ? curator : admin

  return {
    admin,
    curator,
    isAdminOrCurator
  }
}

export default useAdminOrCuratorCheck
