import { isSidepanel } from '_pages/sidebar'
import React, { useEffect } from 'react'

export const LayoutContext = React.createContext<ILayoutContext>({
  loading: false,
  mobileHeader: '',
  subHeader: '',
  sidepanelHistory: [] as Array<any>,
  previewHistory: [],
  rootHistory: [],
  historyIndex: 0,
  searchAnchorEl: null,
  setMobileHeader: () => {},
  setSubHeader: () => {},
  setSidepanelHistory: () => {},
  setPreviewHistory: () => {},
  setRootHistory: () => {},
  setHistoryIndex: () => {},
  setSearchAnchorEl: () => null
})

const LayoutContextProvider = (props: React.ComponentProps<any>) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [mobileHeader, setMobileHeader] = React.useState<string>('')
  const [subHeader, setSubHeader] = React.useState<string>('')
  const [searchAnchorEl, setSearchAnchorEl] = React.useState<null | HTMLElement>(null)

  const [sidepanelHistory, setSidepanelHistory] = React.useState<Array<any>>(JSON.parse(localStorage.getItem('sidepanel_history') || '[]'))
  const [previewHistory, setPreviewHistory] = React.useState<HistoryType[]>([])
  const [rootHistory, setRootHistory] = React.useState<HistoryType[]>([])
  const [historyIndex, setHistoryIndex] = React.useState<number>(-1)

  const { pathname, search } = sidepanelHistory[historyIndex] || {}
  useEffect(() => {
    if (pathname && historyIndex > -1 && isSidepanel('sidepanel')) {
      localStorage.setItem('sidepanel', `${pathname}${search}`)
    }
  }, [historyIndex, pathname, search])

  const setMobileHeaderHandler = (header: string, loading: boolean = false) => {
    setMobileHeader(header)
    setLoading(loading)
  }

  const setSidepanelHistoryHandler = (value: Array<any>) => {
    localStorage.setItem('sidepanel_history', JSON.stringify(value))
    setSidepanelHistory(value)
  }

  const resultingValue: ILayoutContext = {
    loading,
    mobileHeader,
    subHeader,
    sidepanelHistory,
    previewHistory,
    rootHistory,
    historyIndex,
    searchAnchorEl,
    setMobileHeader: setMobileHeaderHandler,
    setSubHeader,
    setSidepanelHistory: setSidepanelHistoryHandler,
    setPreviewHistory,
    setRootHistory,
    setHistoryIndex,
    setSearchAnchorEl
  }

  React.useEffect(() => {
    window.document.title = `DotAlign${mobileHeader ? ` - ${mobileHeader}` : ``}`
  }, [mobileHeader])

  return <LayoutContext.Provider value={resultingValue}>{props.children}</LayoutContext.Provider>
}

export default LayoutContextProvider

export type HistoryType = {
  pathname: string
  search: string
  state: string
}

interface ILayoutContext {
  loading: boolean
  mobileHeader: string
  subHeader: string
  sidepanelHistory: Array<any>
  previewHistory: Array<HistoryType>
  rootHistory: Array<HistoryType>
  historyIndex: number
  searchAnchorEl: null | HTMLElement
  setMobileHeader: (value: string, loading?: boolean) => void
  setSubHeader: (value: string) => void
  setSidepanelHistory: (value: Array<any>) => void
  setPreviewHistory: React.Dispatch<React.SetStateAction<HistoryType[]>>
  setRootHistory: React.Dispatch<React.SetStateAction<HistoryType[]>>
  setHistoryIndex: React.Dispatch<React.SetStateAction<number>>
  setSearchAnchorEl: (value: null | HTMLElement) => void
}
