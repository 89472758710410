import React from 'react'

import Page from '_shared/Page'

import AnalyzeLeads from '_core/components/AnalyzeLeads'

const AnalyzeLeadsPage = () => {
  return (
    <Page>
      <AnalyzeLeads />
    </Page>
  )
}

export default AnalyzeLeadsPage
