import { useState } from 'react'

import { put } from 'utils/httpUtils'

export type SaveAction = (value: string, variant?: 'error' | 'default') => void

const useSaveSettings = () => {
  const [isSaving, setSaving] = useState<boolean>(false)
  const save = async <T>(url: string, payload: T, action?: SaveAction, actionText?: string, reset?: () => void) => {
    try {
      setSaving(true)
      await put(url, payload)
      if (action && actionText) {
        action(actionText)
      }
      if (reset) {
        reset()
      }
    } catch {
      if (action && actionText) {
        action(actionText, 'error')
      }
    } finally {
      setSaving(false)
    }
  }

  return { isSaving, save }
}

export default useSaveSettings
