import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

const LeaveDialog = ({ opened, close, text, confirm }: { opened: boolean; close: () => void; text?: string; confirm: () => void }) => {
  const handleYes = () => {
    close()
    confirm()
  }
  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Leave page?" />}>
      <DialogContent>
        <Typography>{text || 'You will loose your changes if you click Yes.'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close}>
          No
        </Button>
        <Button variant="text" onClick={handleYes} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LeaveDialog
