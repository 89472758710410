import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

interface IUrlPageProps {
  loading: boolean
  Urls: any
  companyName: string
  setTotalDomains: any
}

const UrlPage = ({ loading, Urls, companyName, setTotalDomains }: IUrlPageProps) => {
  const { id } = useParams<{ id: string }>()
  useEffect(() => setTotalDomains(Urls?.total_item_count), [Urls, setTotalDomains])

  const submitInvalid = async (identity: string, value: number) => {
    return post(`/companies/disqualify?Identity=${identity}&Reason=${nameReason[value]}`)
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 6, loading }}
      addprops={{ style: { paddingLeft: 5 } }}
      items={Urls?.data?.map((name: any) => ({
        title: '',
        description: (
          <IdentifierLabel
            name={companyName}
            identity={name.CoUrl}
            type="DomainUrl"
            submit={submitInvalid}
            sourceLink={`${Paths._companies}/${id}/domainSources/${name.CoUrl}`}
            auditLink={`${Paths._companies}/${id}/audit`}
          />
        ),
        blurb: name.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(name.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No domains were found"
    />
  )
}

export default UrlPage
