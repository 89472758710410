import { useContext, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { ActiveOnboarding, OnboardingContext } from '_core/context/Onboarding'

const useOnboarding = (continuous?: boolean) => {
  const { setActive, active, queue, setQueue } = useContext(OnboardingContext)
  const { pathname } = useLocation()
  const sidepanel = isSidepanel()

  const cancelOnboarding = () => {
    if (!continuous) {
      setQueue([])
      setActive(undefined)
    }
  }

  useEffect(() => {
    return cancelOnboarding
  }, [pathname, sidepanel])

  const getCompleted = () => {
    const completed: ActiveOnboarding[] = JSON.parse(window.localStorage.getItem('completed_onboarding') || '[]')
    return completed
  }

  const checkCompleted = (toCheckValue: ActiveOnboarding) => {
    const completed = getCompleted()
    return { isCompleted: completed.includes(toCheckValue), completed }
  }

  const markAsCompleted = () => {
    if (active) {
      const { isCompleted, completed } = checkCompleted(active)
      if (!isCompleted) {
        const updated = [...completed, active]
        window.localStorage.setItem('completed_onboarding', JSON.stringify(updated))
      }
    }
  }

  useEffect(() => {
    const executeNextAction = () => {
      if (queue.length > 0 && !active) {
        const action = queue[0]
        setActive(action)
      }
    }
    executeNextAction()
  }, [queue.length, active])

  const startOnboarding = (value: ActiveOnboarding) => {
    const { isCompleted } = checkCompleted(value)
    if (!isCompleted) {
      setQueue((prevState) => [...prevState, value])
    }
  }

  const completeOnboarding = () => {
    markAsCompleted()
    setQueue((prevState) => prevState.filter((el) => el !== active))
    setActive(undefined)
  }

  return {
    active,
    startOnboarding,
    completeOnboarding
  }
}

export default useOnboarding
