import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { BeneficiaryUpdateActionsType } from '_core/components/dialogs/UpdateBeneficiaryDialog'
import RequestForm, { defaultReason } from '_core/components/introductions/RequestForm'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { IntroductionRequest } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'

import Paths from 'Paths'

const CreateRequestForm = ({
  meUser,
  loading,
  ...props
}: {
  loading: boolean
  meUser?: ProfileType
  save?: (formValues: IntroductionRequest) => void
  beneficiaryUpdateAction?: BeneficiaryUpdateActionsType
}) => {
  const { fetchWithAbort, result } = useAbortableFetch<{ data: PersonType[] }>()
  const { teamContextValue } = useContext(TeamContext)
  const { updateParent } = useSidepanelPayloads()
  const history = useHistory()

  const myProfile = result?.data[0]

  useEffect(() => {
    if (meUser && teamContextValue.teamNumber) {
      fetchWithAbort({
        url: `/people/${meUser.UserKey || meUser.UserKeyMd5}?teamNumber=${teamContextValue.teamNumber}`
      })
    }
  }, [fetchWithAbort, meUser, teamContextValue.teamNumber])

  const userCompanyName = myProfile?.BestJobMatchedCompanyName || myProfile?.BestJobCorpLevelCompanyName || ''
  const request = {
    planUid: '',
    summary: '',
    description: '',
    reason: defaultReason,
    requester: {
      name: meUser?.UserName || '',
      email: meUser?.UserKey || ''
    },
    beneficiaryCompany: {
      name: userCompanyName,
      website: userCompanyName ? myProfile?.BestJobMatchedUrlText || '' : '',
      md5: userCompanyName ? myProfile?.BestJobCompanyMd5 || '' : ''
    },
    beneficiaryPerson: {
      name: '',
      email: '',
      md5: ''
    }
  }

  const goToNextStep = (planUid: string) => {
    const requestUrl = `${Paths._introductions}/${planUid}`
    const nextStep = `${requestUrl}/sharing-settings?nextStep=${requestUrl}`
    history.replace(`${requestUrl}/edit?nextStep=${nextStep}`)
    history.push(nextStep)
  }

  const save = async (formValues: IntroductionRequest) => {
    const { planUid, requester, reason, beneficiaryCompany, beneficiaryPerson, ...data } = formValues

    const res = await post<IntroductionPlanResp>('/prospecting/newplan', {
      requesterEmail: requester.email,
      requesterName: requester.name,
      reason: reason.value,
      beneficiaryCompanyUrl: beneficiaryCompany.website,
      beneficiaryCompanyName: beneficiaryCompany.name,
      beneficiaryCompanyMd5: beneficiaryCompany.md5,
      beneficiaryPersonEmail: beneficiaryPerson.email,
      beneficiaryPersonName: beneficiaryPerson.name,
      beneficiaryPersonMd5: beneficiaryPerson.md5,
      ...data
    })

    if (res?.planUid) {
      updateParent({ action: 'RELOAD_LIST', value: 'introductions' })
      goToNextStep(res.planUid)
    }
  }

  return (
    <RequestForm
      request={myProfile && request}
      loading={loading || Boolean(!myProfile && meUser)}
      save={props.save || save}
      actionLabel="Next"
      beneficiaryUpdateAction={props.beneficiaryUpdateAction}
    />
  )
}

export default CreateRequestForm
