import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import AddParticipantsForm from '_core/components/introductions/AddParticipantsForm'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

const AddIntroductionParticipantsPage = () => {
  const { id } = useParams<{ id: string }>()

  const memoUrls = useMemo(
    () => [
      {
        key: 'request',
        url: `/prospecting/plandetail?planUid=${id}`
      },
      {
        key: 'statusOptions',
        url: '/prospecting/introducerstatuses'
      }
    ],
    [id]
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <DynamicEntity urls={memoUrls} component={AddParticipantsForm} keepMounted id="add_introductions" />
      </Narrow>
    </Page>
  )
}

export default AddIntroductionParticipantsPage
