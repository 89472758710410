import React from 'react'

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Card from '_shared/Card'
import Popover from '_shared/Popover'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { FileTransformedProps } from '_core/components/data-uploads/types'
import StatusIcon, { DefaultStatusIcon } from '_core/components/StatusIcon'

import { formatDateTime } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2)
  },
  spaceTop: {
    paddingTop: theme.spacing(1)
  },
  spaceBottom: {
    paddingBottom: theme.spacing(1)
  },
  spaceRight: {
    marginRight: theme.spacing(1)
  },
  name: {
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'start',
    maxWidth: '100%'
  },
  value: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'start',
    maxWidth: '100%'
  },
  badgeSkelly: {
    boxSizing: 'border-box',
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    minWidth: theme.spacing(2.5),
    minHeight: theme.spacing(2.5)
  },
  iconWrapper: {
    padding: theme.spacing(1),
    width: 38.5,
    textAlign: 'center',
    boxSizing: 'border-box'
  },
  icon: {
    fontSize: 18
  }
}))

const DataUploadsListItem = (props: DataUploadsListItemProps) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()

  return (
    <Card variant="outlined" round className={classes.card}>
      <Box>
        <Box display="grid" gridTemplateColumns="minmax(0, 100%) auto" height="22px">
          <Skeleton condition={!props.date} width="100px">
            <Tooltip title={formatDateTime(props.date)} placement="bottom-start">
              <Typography className={classes.value} color="text.secondary" semiBold ellipsis>
                {formatDateTime(props.date)}
              </Typography>
            </Tooltip>
          </Skeleton>
          <Box display="flex" alignItems="center" mt={-1} mr={-1}>
            <Skeleton
              condition={!props.fileName && !props.status}
              variant="circular"
              width={theme.spacing(2.5)}
              height={theme.spacing(2.5)}
              className={cx(classes.badgeSkelly, classes.spaceRight)}
            >
              {props.fileTypeIcon && (
                <DefaultStatusIcon icon={props.fileTypeIcon} title={props.fileType} className={classes.iconWrapper} iconClassName={classes.icon} />
              )}
            </Skeleton>
            <Skeleton
              condition={!props.fileName && !props.status}
              variant="circular"
              width={theme.spacing(2.5)}
              height={theme.spacing(2.5)}
              className={classes.badgeSkelly}
            >
              {props.status && <StatusIcon status={props.status} className={classes.iconWrapper} iconClassName={classes.icon} />}
            </Skeleton>
          </Box>
        </Box>
        <Box>
          <Skeleton condition={!props.title && !props.fileName} width="180px" height="22px">
            <Tooltip title={props.title} placement="bottom-start">
              <Typography className={classes.name} semiBold ellipsis>
                {props.title || 'No title specified'}
              </Typography>
            </Tooltip>
          </Skeleton>
        </Box>

        {props.storedAt && props.storedAs && props.fileName ? (
          <Button variant="link" onClick={props.downloadHandler} style={{ padding: 0, maxWidth: '100%', height: '22px' }}>
            <Tooltip title={`download ${props.fileName}`} placement="bottom-start">
              <Typography className={classes.value} ellipsis>
                {props.fileName}
              </Typography>
            </Tooltip>
          </Button>
        ) : (
          <Skeleton condition={!props.fileName} width="150px" height="22px">
            <Tooltip title={props.fileName} placement="bottom-start">
              <Typography className={classes.value} ellipsis>
                {props.fileName}
              </Typography>
            </Tooltip>
          </Skeleton>
        )}

        {props.uploader?.link ? (
          <Link to={props.uploader.link} style={{ textDecoration: 'none' }}>
            <Tooltip title={props.uploader?.name} placement="bottom-start">
              <Typography className={classes.value} color="text.secondary" ellipsis>
                {props.uploader?.name}
              </Typography>
            </Tooltip>
          </Link>
        ) : (
          <Skeleton condition={!props.uploader?.name} width="150px" height="22px">
            <Tooltip title={props.uploader?.name} placement="bottom-start">
              <Typography className={classes.value} ellipsis>
                {props.uploader?.name}
              </Typography>
            </Tooltip>
          </Skeleton>
        )}

        {props.description && (
          <Popover
            placement="bottom-start"
            triggerElement={
              <Typography className={classes.value} color="text.secondary" ellipsis>
                {props.description}
              </Typography>
            }
          >
            <Typography>{props.description}</Typography>
          </Popover>
        )}
      </Box>
    </Card>
  )
}

export default DataUploadsListItem

export type DataUploadsListItemProps = {
  isSelected?: boolean
} & FileTransformedProps
