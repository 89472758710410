import { ChangeEvent } from 'react'

import { SelectChangeEvent, RadioGroup } from '@mui/material'

import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import Filters from '_core/components/filters'
import { touchpointDateTypeOptions as whereOptions, interactionsOptions } from '_core/components/filters/data'

import useControlsStyles from './styles'

const TouchpointControl = (props: {
  where?: TouchpointDateType
  interaction?: Capitalize<IncludeInteractions>
  handleInteractionChange: (e: ChangeEvent<{ name: string; value: unknown }>) => void
  handleWhereChange: (e: SelectChangeEvent<unknown>) => void
  disabled: boolean
}) => {
  const { classes, cx } = useControlsStyles()
  const { where, interaction, disabled, handleInteractionChange, handleWhereChange } = props
  const interactionsOpts = where === 'Next' ? interactionsOptions.filter((int) => int.value === 'Meeting') : interactionsOptions

  return (
    <>
      <Typography variant="h4" semiBold classes={{ root: cx(classes.label, classes.header) }} component="label">
        Where the&nbsp;
        <Select
          fullWidth
          size="small"
          variant="standard"
          value={where}
          name="where"
          options={whereOptions}
          onChange={handleWhereChange}
          className={classes.select}
          disabled={disabled}
        />
      </Typography>
      <RadioGroup name="interaction" value={!disabled ? interaction || 'Any' : ''} onChange={handleInteractionChange}>
        {interactionsOpts.map((item) => (
          <Filters.Radio key={item.value} disabled={disabled} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </>
  )
}

export default TouchpointControl
