import React, { ForwardedRef, forwardRef, ReactNode, Ref } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton as MuiIconButton, IconButtonTypeMap, IconButtonProps as MuiIconButtonProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'

import { DisablePaddingType, disablePaddingsValues } from './Button'

const useStyles = makeStyles()((theme) => ({
  skeleton: {
    margin: theme.spacing(1),
    minWidth: 25,
    maxWidth: 25
  },
  box: {
    maxWidth: 'fit-content'
  },
  disablePadding: {
    margin: `-${theme.spacing(1.5)}`
  },
  disablePX: {
    marginRight: `-${theme.spacing(1.5)}`,
    marginLeft: `-${theme.spacing(1.5)}`
  },
  disablePL: {
    marginLeft: `-${theme.spacing(1.5)}`
  },
  disablePR: {
    marginRight: `-${theme.spacing(1.5)}`
  },
  disablePY: {
    marginTop: `-${theme.spacing(1.5)}`,
    marginBottom: `-${theme.spacing(1.5)}`
  },
  disablePT: {
    marginTop: `-${theme.spacing(1.5)}`
  },
  disablePB: {
    marginBottom: `-${theme.spacing(1.5)}`
  },
  disableHover: {
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

type Icon = {
  icon: IconProp
  children?: never
}

type ChildIcon = {
  icon?: never
  children: ReactNode
}

export type IconButtonProps<C extends React.ElementType = IconButtonTypeMap['defaultComponent']> = MuiIconButtonProps<
  C,
  { component?: C; loading?: boolean; hint?: string } & {
    [key in DisablePaddingType]?: boolean
  } & { disableHover?: boolean } & (Icon | ChildIcon)
>

const IconButtonInner = <C extends React.ElementType = IconButtonTypeMap['defaultComponent']>(
  props: IconButtonProps<C>,
  ref?: ForwardedRef<HTMLDivElement>
) => {
  const { classes, cx } = useStyles()
  const {
    icon,
    hint,
    loading,
    className,
    disablePadding,
    disablePY,
    disablePX,
    disablePR,
    disablePL,
    disablePT,
    disablePB,
    disableHover,
    children,
    ...iconButtonProps
  } = props

  return (
    <Box
      ref={ref}
      className={cx(
        classes.box,
        { [classes.disableHover]: disableHover },
        disablePaddingsValues.map((key) => ({ [classes[key as keyof typeof classes]]: props[key] })),
        className
      )}
    >
      <Skeleton condition={loading} variant="circular" height={25} width={25} classes={{ root: classes.skeleton }}>
        <Tooltip title={hint} disabled={loading || iconButtonProps.disabled}>
          <MuiIconButton {...iconButtonProps}>{icon ? <FontAwesomeIcon icon={icon} /> : children}</MuiIconButton>
        </Tooltip>
      </Skeleton>
    </Box>
  )
}

const IconButton = forwardRef(IconButtonInner) as <C extends React.ElementType = IconButtonTypeMap['defaultComponent']>(
  props: IconButtonProps<C> & { ref?: ForwardedRef<HTMLDivElement> | Ref<HTMLDivElement> }
) => ReturnType<typeof IconButtonInner>

export default IconButton
