import { Fragment, useEffect } from 'react'

import { FormControl, RadioGroup, Grid, Box } from '@mui/material'
import { Controller, useWatch, useFormContext, Control } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'

import { SidepanelWide, SidepanelNarrow } from '_core/components/layout'

import { SalesforceFormData } from '_core/hooks/useSalesforce'

import { formatDate, formatDateFromNow } from 'utils/Utils'

import Option from './Option'

const FORMAT = 'MMM DD, YYYY'

type SourceType = { name: string; value: SalesforceValue['source']; options: SalesforceData[] }

type SalesForceRowProps = {
  field: string
  fieldName: string
  sources: SourceType[]
}

export const formatCreationDate = (date: moment.MomentInput) => {
  return formatDateFromNow(date, 5, FORMAT)
}

const useStyles = makeStyles<{ hasDifference?: boolean }>()((theme, { hasDifference }) => ({
  container: {
    boxSizing: 'border-box',
    padding: `${theme.spacing(2)}`,
    width: '100%',
    margin: `-8px 0px`,
    background: hasDifference ? '#f8fdff' : '',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(1)} 0px ${theme.spacing(2)}`
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40
  },
  dateColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4)
  },
  field: {
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textTransform: 'capitalize'
  },
  time: {
    justifyContent: 'flex-end',
    width: '100%',
    fontSize: 12,
    lineHeight: '15px',
    color: '#A7A7A7',
    fontWeight: 600,
    marginLeft: 33,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'unset'
    }
  }
}))

const Date = ({
  control,
  field,
  source
}: {
  control: Control<SalesforceFormData>
  field: keyof SalesforceFormData
  source: SalesforceValue['source']
}) => {
  const { classes } = useStyles({})
  const data = useWatch<SalesforceFormData, `${typeof field}.selectedValue.${typeof source}`>({
    control,
    name: `${field}.selectedValue.${source}`
  })

  return (
    <Grid key={source} item classes={{ root: classes.item, item: classes.time }}>
      <Skeleton width="100%" condition={!data?.lastEvidenceDate && !source}>
        {!!data?.lastEvidenceDate && (
          <Tooltip title={formatDate(data?.lastEvidenceDate, FORMAT)}>
            <Box className={classes.dateColumn}>{formatCreationDate(data?.lastEvidenceDate)}</Box>
          </Tooltip>
        )}
      </Skeleton>
    </Grid>
  )
}

const Row = ({ field, fieldName, sources = Array(2).fill({}) }: SalesForceRowProps) => {
  const {
    control,
    trigger,
    watch,
    formState: { touchedFields, dirtyFields }
  } = useFormContext<SalesforceFormData>()
  const data = watch()
  const selectedSource = useWatch({
    control,
    name: `${field}.selectedSource`
  })
  const isOptionsDifferent = !sources.map((source) => data[field]?.selectedValue?.[source.value]?.value).every((val, i, arr) => val === arr[0])
  const { classes } = useStyles({ hasDifference: !!(isOptionsDifferent && touchedFields[field] && dirtyFields[field]?.selectedValue) })

  useEffect(() => {
    trigger()
  }, [selectedSource, trigger])

  const RGroup = ({ field: fField }: { field?: { value: string; onChange: () => void } }) => {
    const { value, onChange } = fField || {}
    return (
      <RadioGroup value={value} onChange={onChange}>
        {sources.map(({ value, ...rest }: SourceType, i: number) => (
          <Fragment key={value || i}>
            {value ? (
              <Option source={value} field={field} {...rest} />
            ) : (
              <Skeleton width="100%" height="40px" condition={true}>
                {null}
              </Skeleton>
            )}
          </Fragment>
        ))}
      </RadioGroup>
    )
  }

  return (
    <>
      <SidepanelWide>
        <Grid container className={classes.container} spacing={2}>
          <Grid xs={2} container item direction="column">
            <Grid item classes={{ root: classes.item, item: classes.field }}>
              <Skeleton width="100%" condition={!fieldName}>
                {fieldName}
              </Skeleton>
            </Grid>
            <Grid item />
          </Grid>

          <Grid xs={5} container item direction="column">
            <FormControl component="fieldset">
              {field ? <Controller name={`${field}.selectedSource`} control={control} render={(props) => <RGroup {...props} />} /> : <RGroup />}
            </FormControl>
          </Grid>

          <Grid xs={3} container item direction="column">
            {sources.map((source: SourceType, i: number) => (
              <Date key={source.value || i} field={field} source={source.value} control={control} />
            ))}
          </Grid>

          <Grid xs={2} container item direction="column">
            {sources.map((source: SourceType, i: number) => (
              <Grid key={source.value || i} item classes={{ root: classes.item }}>
                <Skeleton width="100%" condition={!source.value}>
                  {source.value}
                </Skeleton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </SidepanelWide>
      <SidepanelNarrow>
        <Grid container className={classes.container}>
          <Grid item classes={{ root: classes.item, item: classes.field }}>
            <Skeleton width="100px" condition={!fieldName}>
              {fieldName}
            </Skeleton>
          </Grid>

          <Grid container item direction="column">
            <FormControl component="fieldset">
              {field ? <Controller name={`${field}.selectedSource`} control={control} render={(props) => <RGroup {...props} />} /> : <RGroup />}
            </FormControl>
          </Grid>
        </Grid>
      </SidepanelNarrow>
    </>
  )
}

export default Row
