import React, { useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Redirect, useHistory } from 'react-router-dom'

import { Button, IconButton } from '_shared/buttons'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useAssignee, { AssigneeType } from '_core/hooks/useAssignee'
import { useStyles } from '_core/hooks/useIntroductionRequestForm'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AssigneeProps = {
  loading: boolean
  request: IntroductionRequestResp
  managers: AssigneeType[]
}

const Assignee = ({ request, managers = [], loading }: AssigneeProps) => {
  const plan = request?.plan || {}
  const { assignee, setAssignee, loading: assigneeLoading, assign, removeAssignee } = useAssignee(plan.planUid, plan.assignedToAppUserKey)
  const { setMobileHeader } = useContext(LayoutContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { classes } = useStyles()

  const detailsLink = `${Paths._introductions}/${plan.planUid}`

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  if (plan.planUid && (!request.querierIsLtnManager || !plan.readyForReview)) {
    return <Redirect to={detailsLink} />
  }

  const load = loading || assigneeLoading

  const items = managers.map((manager) => ({
    name: manager.displayName,
    variant: 'expandable',
    userKey: manager.emailAddress,
    byline: manager.emailAddress,
    icons: (
      <>
        {assignee.emailAddress === manager.emailAddress ? (
          <IconButton
            hint="Dismiss"
            disabled={load}
            onClick={() => setAssignee({ displayName: '', emailAddress: '' })}
            icon={['fas', 'check-circle']}
            color="success"
            disablePR
          />
        ) : (
          <IconButton hint="Assign" disabled={load} onClick={() => setAssignee(manager)} icon={['fas', 'check-circle']} color="secondary" disablePR />
        )}
      </>
    )
  }))

  const saveHandler = assignee.displayName ? assign : removeAssignee
  const goToDetails = () => history.push(detailsLink)

  const save = async () => {
    await saveHandler()
    enqueueSnackbar('Assignee has been updated')
    goToDetails()
  }

  return (
    <>
      <Widget scope="card">
        <Repeater direction="vertical" variant="card" component={ProfileItem} skeleton={{ size: 2, loading }} items={items} empty="No managers" />
      </Widget>
      <Box className={classes.actionButtons}>
        <Button onClick={goToDetails} variant="text" color="secondary" disablePL>
          Cancel
        </Button>
        <Button onClick={save} variant="text" disabled={load} disablePR>
          Apply
        </Button>
      </Box>
    </>
  )
}

export default Assignee
