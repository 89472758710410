import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Tooltip from '_shared/Tooltip'

import useTeam from '_core/hooks/useTeam'

import { triggerDownloadXl } from 'utils/Utils'

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 42
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18
  }
}))

const Download = (props: DownloadProps) => {
  const { classes } = useStyles()
  const { teamContextValue } = useContext(TeamContext)
  const { leadsList, leadType, disabled = false } = props
  const { enqueueSnackbar } = useSnackbar()
  const { getLeadsList } = useTeam()

  const handleIconClick = async () => {
    enqueueSnackbar('Analysis is in progress. Download should begin shortly.', {
      variant: 'default'
    })

    try {
      const fetchedData = await getLeadsList(teamContextValue.teamNumber.toString(), leadType || 'Company', leadsList)
      if (fetchedData) {
        // Trigger a download
        triggerDownloadXl(fetchedData, leadType)
      } else {
        setTimeout(() => {
          enqueueSnackbar('Analysis complete. No matches found.', {
            variant: 'default'
          })
        }, 5000)
      }
    } catch (error) {
      enqueueSnackbar('Encountered error while processing request', {
        variant: 'error'
      })
      console.log('ERROR DURING DATA FETCH', error)
    }
  }

  return (
    <Tooltip title="Download selected items" disabled={disabled}>
      <IconButton className={classes.container} onClick={handleIconClick} disabled={disabled} color="primary">
        <FontAwesomeIcon className={classes.icon} icon={['far', 'file-download']} />
      </IconButton>
    </Tooltip>
  )
}

export default Download

type DownloadProps = {
  leadsList: string[]
  leadType: 'Company' | 'Person'
  disabled?: boolean
}
