import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

const Content = <Typography style={{ maxWidth: '125px' }}>Click here to show/hide the filters.</Typography>

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Show/hide filter',
    content: Content,
    target: '.expand-filter-onboarding'
  }
]

export default steps
