import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import RequestDetails from '_core/components/introductions/RequestDetails'
import { Narrow } from '_core/components/layout'

import DynamicEntity from '_core/DynamicEntity'

const RequestPage = () => {
  const { id } = useParams<{ id: string }>()

  const memoUrls = useMemo(
    () => [
      {
        key: 'request',
        url: `/prospecting/plandetail?planUid=${id}`
      }
    ],
    [id]
  )

  return (
    <Page>
      <Narrow>
        <DynamicEntity urls={memoUrls} component={RequestDetails} id="request_details" keepMounted />
      </Narrow>
    </Page>
  )
}

export default RequestPage
