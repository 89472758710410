import { useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type ReasonType = { id: number; value: string; label: string }

const useIntroductionReason = (loading?: boolean) => {
  const { fetchWithAbort, result: reasons } = useAbortableFetch<ReasonType[]>()

  useEffect(() => {
    if (!loading) {
      fetchWithAbort({ url: '/prospecting/reasons' })
    }
  }, [loading])

  const getReason = (value: string) => reasons?.find((r: ReasonType) => r.value === value)?.label || ''

  return getReason
}

export default useIntroductionReason
