import { useCallback, useEffect, useRef, useState } from 'react'

import { Moment as MomentType } from 'moment'
import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'
import { sortMap } from '_core/components/sort/Deals'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { formatDate, getLocal, dateFormatURLQuery } from 'utils/Utils'

type InitialParams = ModifiedDealsPageParams & { isOpened: boolean }

const dealsSaveData = {
  endpoint: '/usersettings/dealsfilter',
  getData: (params: InitialParams): IDealsInit => {
    const { rowsPerPage, sort = 'ClosedDateDesc', viewMode, where, period, stages, roles, types, from, to, days, isOpened } = params
    const sortData = Object.keys(sortMap)
      .filter((key) => {
        const { asc, desc } = sortMap[key]
        return [asc, desc].includes(sort)
      })
      .reduce(
        (acc, key) => ({
          ...acc,
          sort: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
          direction: sort?.includes('Asc') ? 'Ascending' : 'Descending'
        }),
        {}
      ) as { [key in 'sort' | 'direction']: IDealsInit[key] }

    const optionalParams = [
      {
        fromDate: getLocal(from, dateFormatURLQuery).startOf('day').toISOString(),
        condition: from
      },
      { toDate: getLocal(to, dateFormatURLQuery).endOf('day').toISOString(), condition: to },
      { stages, condition: stages },
      { roles, condition: roles },
      { types, condition: types }
    ]
      .filter(({ condition }) => condition)
      .reduce((acc, { condition, ...param }) => ({ ...acc, ...param }), {})

    return {
      rowsPerPage: +(rowsPerPage || '20') as RowPerPageOptionsType,
      expandedView: viewMode === 'expanded',
      dateFilterType: where || 'Closed',
      dateConditionType: period || 'Anytime',
      dayCount: +(days || '7') as DaysOptions,
      isOpened,
      ...sortData,
      ...optionalParams
    }
  }
}

const useDealsUsersSettings = () => {
  const wide = useWide()
  const { queryParams, updateQuery } = useSearchQuery<
    DealsPageParams,
    { modifyProps: [{ roles: IncludeDeals[]; stages: IncludeDeals[]; types: IncludeDeals[] }] }
  >(['roles', 'stages', 'types'])
  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<IDealsInit>()
  const { search } = useLocation()
  const [range, setRange] = useState<Pick<InitialParams, 'from' | 'to'>>()

  const [isReady, setIsReady] = useState<boolean>(!!search)
  const [loading, setLoading] = useState<boolean>(true)
  const [opened, setOpened] = useState<InitialParams['isOpened']>(false)
  const initialOpened = useRef<InitialParams['isOpened']>(opened)

  useEffect(() => {
    const { from, to } = queryParams
    if (isReady && !range && (from || to)) {
      setRange({ from, to })
    }
  }, [isReady, queryParams, range])

  const getInitialParams = useCallback((data: IDealsInit): InitialParams => {
    const {
      rowsPerPage,
      expandedView,
      sort,
      direction,
      dateFilterType,
      stages = [],
      roles = [],
      types = [],
      dayCount,
      fromDate,
      toDate,
      dateConditionType,
      isOpened
    } = data
    const days: NumberToString<DaysOptions> = `${dayCount}`

    const optionalParams = [
      { from: getLocal(fromDate).format(dateFormatURLQuery), condition: fromDate },
      { to: getLocal(toDate).format(dateFormatURLQuery), condition: toDate },
      { period: dateConditionType, condition: dateConditionType !== 'Anytime' }
    ]
      .filter(({ condition }) => condition)
      .reduce((acc, { condition, ...param }) => ({ ...acc, ...param }), {})

    return {
      rowsPerPage: `${rowsPerPage || 20}`,
      viewMode: expandedView ? 'expanded' : 'collapsed',
      sort: sortMap[`${sort.charAt(0).toLowerCase()}${sort.slice(1)}`][direction === 'Ascending' ? 'asc' : 'desc'],
      where: dateFilterType,
      stages,
      roles,
      types,
      days,
      isOpened,
      ...optionalParams
    }
  }, [])

  const setInitial = useCallback(
    (data: IDealsInit) => {
      const { days, isOpened, ...params } = getInitialParams(data)
      if (wide) {
        setOpened(isOpened)
      }
      initialOpened.current = isOpened
      updateQuery({ ...params, ...(params.period && params.period !== 'Custom' ? { days } : {}) })
      setRange({ from: params.from, to: params.to })
      setLoading(false)
      setIsReady(true)
    },
    [updateQuery, getInitialParams, setLoading, setOpened, wide]
  )

  const getDealsSaveData = (additionalParams: { isOpened: InitialParams['isOpened'] }) => ({
    ...dealsSaveData,
    getData: (params: typeof queryParams) => dealsSaveData.getData({ ...params, ...additionalParams })
  })

  const handleChange = (updates: typeof queryParams, additionalParams?: { isOpened: boolean }) => {
    const { isOpened = wide ? opened : initialOpened.current } = additionalParams || {}
    save(getDealsSaveData({ isOpened }), { ...queryParams, ...updates })
  }

  const handleDateChange = ({ picker, value }: { picker: 'from' | 'to'; value: MomentType | null }) => {
    setRange((prev) => ({ ...prev, [picker]: value ? formatDate(value, dateFormatURLQuery) : null }))
  }

  const getEmptyRelatedParams = (keys: string[]) => {
    return keys.reduce((acc, item) => ({ ...acc, [item]: undefined }), {} as { [key: string]: undefined })
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/dealsfilter' })
    if (defaultData) {
      const { isOpened, ...params } = getInitialParams(defaultData)
      const periodRelatedParams = ['from', 'to', 'days', 'period']
      const unCustomRelatedParams = ['days']

      const clearParams = [
        { condition: !params.period, relatedParams: getEmptyRelatedParams(periodRelatedParams) },
        { condition: params.period === 'Custom', relatedParams: getEmptyRelatedParams(unCustomRelatedParams) }
      ]
        .filter(({ condition }) => condition)
        .reduce((acc, { relatedParams }) => ({ ...acc, ...relatedParams }), {})

      const saveParams = {
        ...params,
        ...clearParams
      }

      await save(getDealsSaveData({ isOpened }), saveParams)
      setOpened(isOpened)
      setRange({ from: params.from, to: params.to })
      setLoading(false)
      return saveParams
    }
  }

  const toggleOpen = () => {
    const isOpened = !opened
    setOpened(isOpened)
    if (wide) {
      handleChange({}, { isOpened })
    }
  }

  return {
    setInitial,
    handleChange,
    handleDateChange,
    range,
    reset,
    loading,
    opened,
    toggleOpen,
    disabledControls: !range
  }
}

export default useDealsUsersSettings
