import { memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import { Button } from '_shared/buttons'

import Success from '_core/components/Success'

const AuditSuccess = memo((props: { openSearch: () => void; close: () => void; label: string }) => {
  return (
    <Success>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Button startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />} onClick={props.openSearch}>
          {props.label}
        </Button>

        <Button variant="text" onClick={props.close} color="secondary" style={{ marginTop: 16 }}>
          Close
        </Button>
      </Box>
    </Success>
  )
})

export default AuditSuccess
