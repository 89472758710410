import React, { useContext } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import { PostSave } from '_core/components/salesforce'

import DynamicEntity from '_core/DynamicEntity'

import Paths from 'Paths'

import Account from './[id]/account'
import Contact from './[id]/contact'

const AccountPage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { params } = useRouteMatch<SalesforceMatchParams>()

  return (
    <Page>
      <DynamicEntity
        urls={[
          { url: `/Salesforce/account/${params.id}?teamNumber=${teamContextValue.teamNumber}`, key: 'salesforceData' },
          { url: `/companies/${params.id}?teamNumber=${teamContextValue.teamNumber}`, key: 'companyData', merge: true, single: true }
        ]}
        component={Account}
        empty="No account data found"
        id="save_to_salesforce_account"
      />
    </Page>
  )
}

const ContactPage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { params } = useRouteMatch<SalesforceMatchParams>()

  return (
    <Page>
      <DynamicEntity
        urls={[
          { url: `/Salesforce/people/${params.id}?teamNumber=${teamContextValue.teamNumber}`, key: 'salesforceData' },
          { url: `/people/${params.id}?teamNumber=${teamContextValue.teamNumber}`, key: 'personData', merge: true, single: true }
        ]}
        component={Contact}
        empty="No contact data found"
        id="save_to_salesforce_contact"
      />
    </Page>
  )
}

const url = `${Paths._salesforce}`
const SalesForcePage = () => {
  const { params } = useRouteMatch<SalesforceMatchParams>()

  const renderComponent = (page: string) => {
    switch (page) {
      case 'account':
        return AccountPage
      case 'contact':
        return ContactPage
      default:
        return () => null
    }
  }

  return (
    <Page>
      <Switch>
        <Route exact path={`${url}/:entity/:id/:index?/success`} component={PostSave} />
        <Route exact path={`${url}/:entity/:id/:index?`} component={renderComponent(params.entity)} />
      </Switch>
    </Page>
  )
}

export default SalesForcePage
