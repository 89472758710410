import { useContext } from 'react'

import { checkOutlook } from '_core/helpers/outlook'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type Variants = 'root' | 'preview' | 'extension' | 'sidepanel' | 'outlook'

type HistoryMapType = {
  [key in Variants]: { history: { [key: string]: any }[]; setHistory: any }
}

const useAppHistory = () => {
  const ctx = useContext(LayoutContext)

  const historyMap: HistoryMapType = {
    preview: { history: ctx.previewHistory, setHistory: ctx.setPreviewHistory },
    root: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    extension: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    outlook: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    sidepanel: { history: ctx.sidepanelHistory, setHistory: ctx.setSidepanelHistory }
  }

  const wName = window.name as Variants
  const key = historyMap[wName] ? wName : ((checkOutlook() ? 'outlook' : 'root') as Variants)
  const { history: appHistory = [], setHistory: setAppHistory } = historyMap[key] || {}

  return {
    appHistory,
    setAppHistory
  }
}

export default useAppHistory
