import useAutoHideOnScrollStyles from './useStyles'

const AutoHideOnScroll = (props: any) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)

  return <div className={autoHideClassName}>{props.children}</div>
}

export { useAutoHideOnScrollStyles }
export default AutoHideOnScroll
