type DayOption = {
  label: string
  value: DaysOptions
}

type Option = {
  label: string
  value: PeriodOptionsType | 'Anytime'
}

type EventNameType = Extract<IncludeEvents, 'meetings' | 'recurringMeetings' | 'appointments' | 'canceledMeetings'>
type StatusNameType = Extract<IncludeEvents, 'accepted' | 'tentativelyAccepted' | 'notResponded'>

export const daysOptions: DayOption[] = [
  { value: 7, label: '7 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 120, label: '120 days' },
  { value: 365, label: '365 days' }
]

export const wasOptions: Option[] = [
  {
    value: 'Within',
    label: 'Within the last'
  },
  {
    value: 'After',
    label: 'Older than'
  },
  { value: 'Anytime', label: 'Any time' }
]

export const scheduledOptions: Option[] = [
  { value: 'Within', label: 'Within the next' },
  { value: 'After', label: 'After' },
  { value: 'Anytime', label: 'Any time' }
]

export const touchpointDateTypeOptions: { value: TouchpointDateType; label: string }[] = [
  { value: 'Latest', label: 'latest' },
  { value: 'First', label: 'first' },
  { value: 'Next', label: 'next' }
]

export const interactionsOptions: { value: Capitalize<IncludeInteractions> | 'Any'; label: string }[] = [
  { value: 'Inbound', label: 'Inbound from them' },
  { value: 'Outbound', label: 'Outbound to them' },
  { value: 'Meeting', label: 'Meeting with them' },
  { value: 'Any', label: 'Activity of any type with them' }
]

export const messagesOptions: { name: InteractionsType; label: string }[] = [
  { name: 'inbound', label: 'Inbound' },
  { name: 'outbound', label: 'Outbound' }
]

export const andOptions: { name: AndOptions; label: string }[] = [{ name: 'noFutureScheduled', label: 'A future meeting has not been scheduled' }]

export const eventTypesOptions: { name: EventNameType; label: string }[] = [
  { name: 'meetings', label: 'Meetings' },
  { name: 'recurringMeetings', label: 'Recurring meetings' },
  { name: 'appointments', label: 'Appointments' },
  { name: 'canceledMeetings', label: 'Cancelled meetings' }
]

export const eventStatusesOptions: { name: StatusNameType; label: string }[] = [
  { name: 'accepted', label: 'Accepted' },
  { name: 'tentativelyAccepted', label: 'Tentative' },
  { name: 'notResponded', label: 'Not responded' }
]

export const interactionsHelpText = 'Only interaction data from contributors that are sharing it can be used to filter people and companies.'

export const dateFilterOptions: { value: DealDateFilterType; label: string }[] = [
  { value: 'Engaged', label: 'Engaged' },
  { value: 'Announced', label: 'Announced' },
  { value: 'Closed', label: 'Closed' },
  { value: 'Renewal', label: 'Of Renewal' },
  { value: 'Edited', label: 'Edited' }
]
