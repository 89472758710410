import React, { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { isString } from 'lodash'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'

import { checkOutlook } from '_core/helpers/outlook'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles<{ height: number }>()((theme, { height }) => ({
  root: {
    padding: 10,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'sticky',
    height: height,
    zIndex: 100,
    width: '100%',
    background: theme.palette.background.light
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: 1,
    justifyContent: 'center',
    maxWidth: 'calc(100% - 100px)',
    width: '100%'
  },
  h4: {
    width: '100%',
    textAlign: 'center'
  },
  subHeader: {
    width: '100%',
    textAlign: 'center',
    '& > span': {
      display: 'block',
      margin: '0 auto'
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  skeleton: {
    margin: '0 auto'
  }
}))

const goback = (id: any, location: any) => {
  if (!id || !location) return '.'

  return `${location.pathname.split(id)[0]}${id}`
}

type TopBarProps = {
  title?: string
  sub?: string | ReactElement
  nativeBack?: boolean | string
  action?: ReactElement | null
  autoHideOnScroll?: boolean
  height?: number
}

const defaultHeight = 60

export const TopbarHeader = ({ title, loading = false, sub: subTitle }: Pick<TopBarProps, 'title' | 'sub'> & { loading?: boolean }) => {
  const {
    classes: { subHeader, h4, skeleton }
  } = useStyles({ height: defaultHeight })

  const subIsString = isString(subTitle)

  return (
    <>
      {(title || loading) && (
        <Tooltip title={title}>
          <Typography variant="h4" className={h4} ellipsis semiBold>
            <Skeleton classes={{ root: skeleton }} width={100} height={24} condition={loading}>
              {title}
            </Skeleton>
          </Typography>
        </Tooltip>
      )}
      {!loading && subTitle && (
        <>
          {subIsString ? (
            <Typography title={subTitle} variant="body1" noWrap className={subHeader}>
              {subTitle}
            </Typography>
          ) : (
            subTitle
          )}
        </>
      )}
    </>
  )
}

const Topbar = (props: TopBarProps) => {
  const { title, sub, nativeBack, action, autoHideOnScroll, height = defaultHeight } = props
  const { mobileHeader, subHeader: subHeaderValue, loading } = React.useContext(LayoutContext)
  const { id } = useParams<any>()
  const location = useLocation()
  const sidebar = isSidepanel() || checkOutlook()

  const {
    classes: { root, link, header },
    cx
  } = useStyles({ height })
  const history = useHistory()
  const { autoHideClassName } = useAutoHideOnScrollStyles(!!autoHideOnScroll, height)
  const showBack = !sidebar && window.name !== 'extension' && window.name !== 'outlook'

  const handleBack = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (nativeBack) {
      e.stopPropagation()
      typeof nativeBack === 'string' ? history.push(nativeBack) : history.go(-1)
      e.preventDefault()
    }
  }

  return (
    <Box className={cx(root, { [autoHideClassName]: autoHideOnScroll })} style={{ top: sidebar ? 28 : 0 }}>
      {showBack ? (
        <Link to={goback(id, location)} className={link} onClick={handleBack}>
          <FontAwesomeIcon icon={['fas', 'arrow-left']} size="1x" style={{ padding: 16, opacity: 0.6 }} />
        </Link>
      ) : (
        <Box style={{ width: 46 }} />
      )}
      <Box className={header}>
        <TopbarHeader title={title || mobileHeader} sub={sub || subHeaderValue} loading={loading} />
      </Box>
      <Box display="flex">{action}</Box>
      {!action && (
        <span onClick={() => window.history.go(1)} style={{ visibility: 'hidden' }} className={link}>
          <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" style={{ padding: 16 }} />
        </span>
      )}
    </Box>
  )
}

export default Topbar
