import React, { Dispatch, SetStateAction, useMemo } from 'react'

import { Box } from '@mui/material'
import { Redirect, useParams } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import Grid from '_core/components/grid'
import { Narrow, Wide, useWide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import SearchInput from '_core/components/SearchInput'
import TeamMembers, { TeamMembersProps } from '_core/components/team/TeamMembers'
import { AddMember } from '_core/components/team/TeamProfile'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useTransformTeamData from '_core/hooks/useTransformTeamData'

import DynamicEntity from '_core/DynamicEntity'
import { checkOutlook } from '_core/helpers/outlook'

import { get, mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

type TeamMembersListProps = {
  handleChange: () => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  team?: Team
  admin: boolean | undefined
  reload: boolean
}

const payload = {
  roleTypes: 'Member',
  credentialTypes: ['ActiveDirectoryTeamMember', 'OwnsEmailAddress'],
  userTypes: 'StandardUser'
}

const TeamMembersList = ({ handleChange, loading, setLoading, team, admin, reload }: TeamMembersListProps) => {
  const { id: teamId } = useParams<any>()
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const { rowsPerPage = '10', keyword } = queryParams
  const wide = useWide()
  const { autoHideClassName } = useAutoHideOnScrollStyles(!wide)
  const hasSidebar = isSidepanel() || checkOutlook()
  const isMembersPage = useRouteMatch(`${Paths._teamProfile}/members`)
  const { transformTeamMembersList } = useTransformTeamData()

  const onPageSizeChange = (pageSize: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage: pageSize })
  }

  const url = useMemo(() => mergeUrlWithParams(`${Paths._teams}/${teamId}/members`, payload), [teamId])

  const download = useMemo(
    () => ({
      requestUrl: async ({ skip, take }: DownloadBulkParams) => {
        const response = await get<{ data: TeamMemberRes[] }>(mergeUrlWithParams(`${Paths._teams}/${teamId}/members`, { ...payload, skip, take }))
        return transformTeamMembersList(response?.data)
      },
      fileName: 'team_members'
    }),
    [teamId]
  )

  const Members = (
    <DynamicEntity<{ extraProps: { addprops: Pick<TeamMembersProps, 'handleChange' | 'load' | 'setLoading' | 'download'> } }>
      id="members_list"
      pageSize={+rowsPerPage}
      component={TeamMembers}
      addprops={{ handleChange, load: loading, setLoading, download }}
      url={!loading && !reload ? url : null}
      empty="No contributors found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      updatePageSize={onPageSizeChange}
      list
      infinite
      search
      nativeBack
      keepMounted
      autoHideOnScroll
    />
  )

  return (
    <>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
        <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
          <SearchInput placeholder="Search for contributors" variant="collapsed" opened />
        </Widget>
        {Members}
      </Narrow>
      <Wide>
        {isMembersPage ? (
          <Redirect to={`${Paths._teams}/${teamId}`} />
        ) : (
          <Box display="flex" height="100%" mt={2} minHeight="calc(100vh - 260px)" style={{ backgroundColor: '#FFFFFF' }}>
            <Grid>
              <Grid.Heading title="Contributors" icon={['far', 'user']}>
                <Box display="flex" alignItems="center">
                  <SearchInput placeholder="Search for contributors" variant="collapsed" opened />
                  {team && <AddMember admin={admin} team={team} reload={handleChange} loading={loading} setLoading={setLoading} />}
                </Box>
              </Grid.Heading>
              {Members}
            </Grid>
          </Box>
        )}
      </Wide>
    </>
  )
}

export default TeamMembersList
