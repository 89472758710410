import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { get } from 'utils/httpUtils'
import { dateFormat } from 'utils/Utils'

moment.updateLocale(moment.locale(), { invalidDate: 'no date' })

const useStyles = makeStyles()((theme) => ({
  icon: {
    borderRadius: '100%',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  heading: {
    display: 'flex',
    lineHeight: '24px'
  },
  date: {
    marginLeft: theme.spacing(4)
  }
}))

const TriggerEl = ({ open }: { open: () => void }) => (
  <IconButton
    style={{ fontSize: '17px', width: '40px', height: '40px' }}
    icon={['far', 'clipboard-list-check']}
    hint="View indexing information"
    onClick={open}
  />
)

const IndexingDialog = (props: { close: () => void; name?: string; teamId?: string; id?: string }) => {
  const [stats, setStats] = useState<any>()
  const [loading, setLoading] = useState(true)
  const { classes } = useStyles()

  useEffect(() => {
    if (props.id) {
      setLoading(true)
      get(`/teams/${props.teamId}/members/${props.id}`).then((resp: any) => {
        setStats(resp)
        setLoading(false)
      })
    }
  }, [props.id, props.teamId])

  return (
    <Dialog open={!!props.id} onClose={props.close} title={<DialogTitle title={props.name || ''} />}>
      <DialogContent>
        <Typography variant="h4" semiBold>
          Last pushed
        </Typography>
        <Typography variant="body1" color="text.secondary" style={{ marginBottom: 16 }}>
          <Skeleton condition={loading} width="80px">
            {!loading && moment(stats.analyzeAndPushLastSuccess).format('MM/DD/YYYY hh:mm:ss A')}
          </Skeleton>
        </Typography>

        <Typography variant="h4" semiBold>
          Status
        </Typography>
        <Box mt={1} mb={2}>
          <Typography variant="h4" className={classes.heading}>
            <Box className={classes.icon}>
              <FontAwesomeIcon size="sm" icon={['far', 'envelope']} />
            </Box>
            <>Emails&nbsp;</>
            <Skeleton condition={loading} width="120px">
              {!loading && (stats.indexingEmailMessagesRetrievedAllItemsEver ? `completed` : `in progress`)}
              {!loading && ` (${stats.tallyEmailMessageCount})`}
            </Skeleton>
          </Typography>
          <Typography variant="body1" color="text.secondary" className={classes.date}>
            <Skeleton condition={loading} width="200px">
              {!loading &&
                `${moment(stats.tallyEmailMessageMinTime).format(dateFormat)} - ${moment(stats.tallyEmailMessageMaxTime).format(dateFormat)}`}
            </Skeleton>
          </Typography>
        </Box>

        <Box mt={1} mb={2}>
          <Typography variant="h4" className={classes.heading}>
            <Box className={classes.icon}>
              <FontAwesomeIcon size="sm" icon={['far', 'calendar-alt']} />
            </Box>
            <>Calendar events&nbsp;</>
            <Skeleton condition={loading} width="120px">
              {!loading && (stats.indexingCalendarEntriesRetrievedAllItemsEver ? `completed` : `in progress`)}
              {!loading && ` (${stats.tallyCalendarMeetingCount})`}
            </Skeleton>
          </Typography>
          <Typography variant="body1" color="text.secondary" className={classes.date}>
            <Skeleton condition={loading} width="200px">
              {!loading &&
                `${moment(stats.tallyCalendarMeetingMinTime).format(dateFormat)} - ${moment(stats.tallyCalendarMeetingMaxTime).format(dateFormat)}`}
            </Skeleton>
          </Typography>
        </Box>

        <Box mt={1} mb={2}>
          <Typography variant="h4" className={classes.heading}>
            <Box className={classes.icon}>
              <FontAwesomeIcon size="sm" icon={['far', 'address-card']} />
            </Box>
            <>Contact cards&nbsp;</>
            <Skeleton condition={loading} width="120px">
              {!loading && (stats.indexingContactCardsRetrievedAllItemsEver ? `completed` : `in progress`)}
              {!loading && ` (${stats.tallyContactCardCount})`}
            </Skeleton>
          </Typography>
          <Typography variant="body1" color="text.secondary" className={classes.date}>
            <Skeleton condition={loading} width="200px">
              {!loading &&
                `${moment(stats.tallyEmailMessageMinTime).format(dateFormat)} - ${moment(stats.tallyEmailMessageMaxTime).format(dateFormat)}`}
            </Skeleton>
          </Typography>
        </Box>

        {!loading && stats.fileSources && (
          <Typography variant="h4" semiBold>
            LinkedIn
          </Typography>
        )}
        {!loading &&
          stats.fileSources &&
          stats.fileSources.map((fileSource: any, i: number) => (
            <Box mt={1} mb={2} key={i}>
              <Typography variant="h4" className={classes.heading}>
                <Box className={classes.icon}>
                  <FontAwesomeIcon color="#1684A7" size="sm" icon={['fab', 'linkedin']} />
                </Box>
                {fileSource.fileName}
              </Typography>
              <Typography variant="body1" color="text.secondary" className={classes.date}>
                {moment(fileSource.uploadTimeUtc).format(dateFormat)} - {fileSource.indexingComplete ? 'completed' : 'in progress'}
              </Typography>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.close} color="secondary" disablePR>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IndexingDialog.TriggerEl = TriggerEl

export default IndexingDialog
