import { useState, useEffect } from 'react'

import { EditForm } from '_core/components/EditPerson'

import { validUrl, validEmail } from '_core/helpers/string'

import { post } from 'utils/httpUtils'

const usePersonForm = (
  person:
    | Partial<
        Pick<
          PersonType,
          | 'PersonNameText'
          | 'BestEmailAddrText'
          | 'BestPhoneText'
          | 'BestPhoneType'
          | 'BestJobTitleText'
          | 'BestJobMatchedCompanyName'
          | 'BestJobCorpLevelCompanyName'
        >
      >
    | undefined
) => {
  const {
    PersonNameText: pName = '',
    BestEmailAddrText: pEmail = '',
    BestPhoneText: pPhone = '',
    BestPhoneType: pPhoneType = 'Work' as PhoneType,
    BestJobTitleText: pJobTitle = '',
    BestJobMatchedCompanyName: pCompanyName = '',
    BestJobCorpLevelCompanyName: pCompanyName2 = ''
  } = person || {}
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [phoneType, setPhoneType] = useState<PhoneType>()
  const [job, setJob] = useState<string>()
  const [company, setCompany] = useState<string>()
  const [companyUrl, setCompanyUrl] = useState<string>()
  const [newCompany, setNewCompany] = useState<boolean>()

  const setInitial = () => {
    setName(pName)
    setEmail(pEmail)
    setPhone(pPhone)
    setPhoneType(pPhoneType)
    setJob(pJobTitle)
    setCompany(pCompanyName || pCompanyName2)
  }

  useEffect(() => {
    setInitial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updated: { [key in 'name' | 'email' | 'phone' | 'phoneType' | 'job' | 'company']: boolean } = {
    name: name !== pName,
    email: email !== pEmail,
    phone: phone !== pPhone,
    phoneType: phoneType !== pPhoneType,
    job: job !== pJobTitle,
    company: company !== pCompanyName && company !== pCompanyName2
  }

  const allowSubmit = !!(
    name &&
    validEmail(email?.trim()) &&
    (Object.getOwnPropertyNames(updated) as (keyof typeof updated)[]).filter((prop) => updated[prop]).length > 0 &&
    (!newCompany || !company || validUrl(companyUrl?.trim()))
  )

  const isReady = typeof name === 'string' && typeof email === 'string'

  const save = async () => {
    const companyData: { name: string | undefined; url?: string } = { name: company }
    if (companyUrl) companyData.url = companyUrl

    const phoneKeyMap: Record<PhoneType, string> = {
      Work: 'workPhone',
      Mobile: 'mobilePhone',
      Home: 'homePhone',
      Fax: 'faxNumber'
    }

    const phoneData = phoneType ? { [phoneKeyMap[phoneType]]: phone } : {}
    const data = { name, emailAddress: email, company: companyData, jobTitle: job, isCurrentJob: true, ...phoneData }
    await post('/people', { contacts: [data] })
    setInitial()
  }

  return {
    name: name || '',
    email: email || '',
    phone: phone || '',
    phoneType: phoneType || 'Work',
    job: job || '',
    company: company || '',
    companyUrl: companyUrl || '',
    newCompany: newCompany || false,
    setName,
    setEmail,
    setPhone,
    setPhoneType,
    setJob,
    setCompany,
    setCompanyUrl,
    setNewCompany,
    allowSubmit,
    updated,
    save,
    isReady,
    Form: isReady ? EditForm : () => null
  }
}

export default usePersonForm
