import { useContext, useEffect, useState, useMemo } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { OptionType } from '_shared/forms/Select'

import useEntityEndpoint from './useEntityEndpoint'

const useDealsFiltersOptions = () => {
  const { teamContextValue } = useContext(TeamContext)
  const [stageOptions, setStageOptions] = useState<OptionType[]>()
  const [roleOptions, setRoleOptions] = useState<OptionType[]>([])
  const [typeOptions, setTypeOptions] = useState<OptionType[]>([])

  const options = useMemo(
    () => [
      { key: 'stages', setState: setStageOptions },
      { key: 'roles', setState: setRoleOptions },
      { key: 'types', setState: setTypeOptions }
    ],
    []
  )

  const urls = useMemo(
    () => options.map(({ key }) => ({ key, url: `/dealmetadata/${key}?TeamNumber=${teamContextValue.teamNumber}` })),
    [options, teamContextValue.teamNumber]
  )

  const { result } = useEntityEndpoint<{ data: { [key in 'roles' | 'stages' | 'types']: { name: string }[] }[] }>(null, urls)

  useEffect(() => {
    const res = result?.data[0]
    if (res && !stageOptions) {
      const setOptions = async (opts: typeof options) => {
        for (const opt of opts) {
          const resultOpts = [...res[opt.key as keyof typeof res]]
          resultOpts.unshift({ name: 'Any' })
          opt.setState(resultOpts.map((option: { name: string }) => ({ value: option.name, label: option.name })))
        }
      }

      setOptions(options)
    }
  }, [options, result, stageOptions])

  return {
    stages: stageOptions || [],
    roles: roleOptions,
    types: typeOptions
  }
}

export default useDealsFiltersOptions
