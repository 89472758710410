import { GridSortDirection, GridSortItem } from '@mui/x-data-grid-pro'

import Sort, { SortProps } from './index'

type SortMapType = {
  [key in GridSortItem['field']]: { [key in Exclude<GridSortDirection, undefined | null>]: DealsSortType }
}

export const sortMap: SortMapType = {
  engagedDate: {
    asc: 'EngagedDateAsc',
    desc: 'EngagedDateDesc'
  },
  closedDate: {
    asc: 'ClosedDateAsc',
    desc: 'ClosedDateDesc'
  },
  renewalDate: {
    asc: 'RenewalDateAsc',
    desc: 'RenewalDateDesc'
  },
  ourRole: {
    asc: 'OurRoleAsc',
    desc: 'OurRoleDesc'
  },
  stage: {
    asc: 'StageAsc',
    desc: 'StageDesc'
  },
  type: {
    asc: 'TypeAsc',
    desc: 'TypeDesc'
  }
}

export type DealsSortProps = {
  items: {
    label: string
    field: string
  }[]
  value?: DealsSortType
  update: (val: DealsSortType) => void
} & Omit<SortProps, 'children'>

const DealsSort = (props: DealsSortProps) => {
  const appliedSort = Object.keys(sortMap).find((key) => sortMap[key].asc === props.value || sortMap[key].desc === props.value)

  const handleClick = (field: string) => {
    const newSort = props.value === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc
    if (newSort) props.update(newSort)
  }

  return (
    <Sort className={props.className} collapsed={props.collapsed}>
      {props.items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={props.value === sortMap[item.field].desc}
          onClick={() => handleClick(item.field)}
        />
      ))}
    </Sort>
  )
}

export { Controller } from './index'
export default DealsSort
