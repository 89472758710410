import React, { ReactElement, ReactNode, ForwardedRef, forwardRef } from 'react'

import { Select as MUISelect, MenuItem, SelectProps, Box, FormLabel } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export type OptionType = {
  label: string | ReactElement
  value: number | string
}

type OptionsProps = { options: OptionType[]; children?: never } | { options?: never; children: ReactNode }

type SelectorProps = {
  options: OptionType[]
  label?: ReactNode
  variant?: 'standard' | 'outlined'
  size?: 'small' | 'medium'
} & Omit<SelectProps, 'variant' | 'label' | 'children' | 'size' | 'classes'>

const useStyles = makeStyles<{ fullWidth: SelectorProps['fullWidth'] }>()((theme, { fullWidth }) => ({
  root: {
    '& > div': {
      width: '100%'
    }
  },
  container: {
    margin: 0,
    width: fullWidth ? '100%' : '150px',
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  small: {
    '&&': {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
    }
  },
  medium: {
    '&&': {
      padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`
    }
  }
}))

const SelectInner = (props: SelectorProps & OptionsProps, ref: ForwardedRef<Element>) => {
  const { size = 'medium', variant = 'outlined', options, MenuProps, disabled, className, label, children, fullWidth, value, ...sProps } = props
  const { classes, cx } = useStyles({ fullWidth })

  return (
    <Box
      className={cx(classes.root, className || classes.container, {
        [classes.labelWrapper]: !!label
      })}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <MUISelect
        {...sProps}
        disabled={disabled}
        variant={variant}
        value={value || ''}
        ref={ref}
        classes={{
          select: cx({
            [classes.small]: size === 'small' && variant === 'outlined',
            [classes.medium]: size === 'medium' && variant === 'outlined'
          })
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          disableScrollLock: true,
          ...MenuProps
        }}
      >
        {options
          ? options.map((option: OptionType) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          : children}
      </MUISelect>
    </Box>
  )
}

const Select = forwardRef(SelectInner) as (props: SelectorProps & OptionsProps & { ref?: ForwardedRef<Element> }) => ReturnType<typeof SelectInner>
export default Select
