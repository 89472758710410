import { useMemo } from 'react'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

const useCompanyActivitiesPayload = (companyId: string, contributorId?: string) => {
  const { result: userProfileData } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { results: userProfileResults } = userProfileData || {}
  const userCompany = userProfileResults?.UserKey.split('@')[1]

  const payloads = useMemo(
    () =>
      userCompany
        ? {
            meetings: {
              pagingBy: 'FocusedCompanyId',
              focusedRole: 'Any',
              focusCompanyIds: [companyId],
              ...(contributorId ? { person2Ids: [contributorId] } : {})
            },
            inbound: {
              pagingBy: 'FocusedCompanyId',
              focusedRole: 'Sender',
              focusCompanyIds: [companyId],
              ...(contributorId ? { person2Ids: [contributorId] } : {})
            },
            outbound: {
              pagingBy: 'Company2Id',
              focusedRole: 'Sender',
              ...(contributorId ? { focusPersonIds: [contributorId] } : { focusCompanyIds: [userCompany] }),
              company2Ids: [companyId]
            }
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCompany, companyId, contributorId]
  )

  return payloads
}

export default useCompanyActivitiesPayload
