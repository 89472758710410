import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import Outcome from '_core/components/introductions/Outcome'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

const RequestOutcomePage = () => {
  const { id } = useParams<{ id: string }>()

  const memoUrls = useMemo(() => [{ key: 'request', url: `/prospecting/plandetail?planUid=${id}` }], [id])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack sub="Request outcome" />
        <DynamicEntity urls={memoUrls} id="request_outcome" component={Outcome} keepMounted />
      </Narrow>
    </Page>
  )
}

export default RequestOutcomePage
