import { HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect } from 'react'

import { SelectChangeEvent, Box, AutocompleteInputChangeReason } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Combobox from '_shared/forms/Combobox'
import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpContributors } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { get } from 'utils/httpUtils'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-h4, & .MuiTypography-body1': {
      marginBottom: theme.spacing(1)
    }
  },
  header: {
    marginBottom: theme.spacing(1)
  }
}))

export type ManualEditsFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  toggleOpen: () => void
  reset: () => Promise<ManualEditsPageParams | undefined>
  anchorEl?: HTMLElement | null
  handleChange: (params: ManualEditsPageParams) => void
  entityOptions: { value: ManualEditsEntityType; label: string }[]
}

const ManualEditsFilters = (props: ManualEditsFiltersType) => {
  const { classes } = useStyles()
  const { total, opened, contentLoading, anchorEl, handleChange: handleFiltersChange, entityOptions, toggleOpen } = props
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType, contributor } = queryParams
  const { teamContextValue } = useContext(TeamContext)

  const { lookupContributors, forceAbort } = useLookUpContributors()

  const loadOptions = useCallback(
    (searchTerm: string) => {
      return lookupContributors(searchTerm)
    },
    [lookupContributors]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    updateInputValue,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const disabled = props.disabled || !!(!inputValue && contributor)

  useEffect(() => {
    if (!inputValue && contributor) {
      const setInitialContributor = async () => {
        const data = await get<TeamMemberRes>(`/teams/${teamContextValue.teamNumber}/members/${contributor}`)
        updateInputValue(data?.name || contributor)
      }

      setInitialContributor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributor])

  useEffect(() => {
    handleFiltersChange({ contributor: value?.userKeyMd5 })
  }, [value])

  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as { value: ManualEditsPageParams['entityType'] }
    handleFiltersChange({ entityType: value })
  }

  const handleContributorInputChange = (e: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    // Ignore inputChange caused by initial input value setting
    if (!e && reason === 'reset' && !value) {
      return
    }

    handleInputChange(e, value, reason)
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: TeamMemberRes | null) => {
    return value ? (
      <li {...props}>
        <ProfileItem name={value.name} userKey={value.emailAddress} byline={value.emailAddress} />
      </li>
    ) : (
      <></>
    )
  }

  const handleReset = async () => {
    const { contributor = '' } = (await props.reset()) || {}
    updateInputValue(contributor)
  }

  return (
    <Filters
      opened={opened}
      hideBorder
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={handleReset}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
    >
      <Box className={classes.block}>
        <Typography variant="h4" semiBold className={classes.header}>
          Type
        </Typography>
        <Select
          size="small"
          name="entityType"
          disabled={disabled || !entityOptions.length}
          options={entityOptions}
          value={entityType || entityOptions[0]?.value}
          onChange={handleSelect}
          fullWidth
        />
      </Box>
      <Box className={classes.block}>
        <Typography variant="h4" semiBold className={classes.header}>
          Pick contributor
        </Typography>
        <Combobox<TeamMemberRes>
          open={open}
          loading={optionsLoading}
          options={options}
          value={value}
          inputValue={inputValue}
          placeholder="Search for contributors"
          icon={['fas', 'industry']}
          disabled={disabled}
          onChange={handleValueChange}
          getOptionLabel={(option) => option?.name || ''}
          renderOption={renderOption}
          onInputChange={handleContributorInputChange}
          onOpen={handleOpen}
          onClose={handleClose}
          onFocus={handleFocus}
          filterOptions={filterOptions}
        />
      </Box>
    </Filters>
  )
}

export { Controller } from './index'
export default ManualEditsFilters
