import React from 'react'

import { Box } from '@mui/material'

import { AvatarWrapper } from '_shared/Avatar'
import FormLabel from '_shared/forms/FormLabel'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import SidepanelLink from '_core/components/SidepanelLink'

const ProfileLinkBox = ({
  heading,
  loading,
  name,
  userKey,
  logoUrl,
  empty,
  ...props
}: {
  name: string
  userKey?: string
  logoUrl?: string
  heading?: string
  empty?: string
  hideName?: boolean
  loading?: boolean
  link: string
  className?: string
}) => {
  return (
    <Box className={props.className}>
      {heading && <FormLabel label={heading} />}
      {!loading && !name && empty ? (
        <Typography>{empty}</Typography>
      ) : (
        <Box display="flex" alignItems="center" overflow="hidden">
          <AvatarWrapper name={loading ? '' : name} userKey={userKey} logoUrl={logoUrl} size="xs" hideName />
          {(loading || !props.hideName) && (
            <Box pl={1} maxWidth="calc(100% - 32px)">
              <Skeleton condition={loading} width={80} height={24}>
                <SidepanelLink linkProps={{ to: props.link }} sidepanel>
                  <Typography color="primary" ellipsis>
                    {name}
                  </Typography>
                </SidepanelLink>
              </Skeleton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ProfileLinkBox
