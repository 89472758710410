import { ReactElement } from 'react'

import { RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Button } from '_shared/buttons'
import IconButton, { IconButtonProps } from '_shared/buttons/Icon'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'

import { emailReason, getReasonLabel, nameReason } from '_core/components/dialogs/InformAboutIdentity'

type AuditInvalidDialogProps = {
  data: AuditIdentifier | null
  submit: (data: AuditIdentifier, reason: string) => void
  multipleLabel: ReactElement
  close: () => void
}

const TriggerEl = ({ open, classes }: { open: () => void; classes: IconButtonProps['classes'] }) => (
  <IconButton classes={classes} disablePR onClick={open} icon={['far', 'ban']} hint="Mark current identifier as invalid" />
)

const AuditInvalidDialog = (props: AuditInvalidDialogProps) => {
  const { register, watch } = useForm({ mode: 'onChange', shouldUnregister: true })

  const { data, submit, multipleLabel, close } = props

  const isEmail = data?.type === 'EmailAddress'

  const submitInvalid = () => {
    if (!data) return
    const opt = parseInt(watch().opt)
    const reason = (isEmail ? emailReason : nameReason)[opt]
    if (reason) {
      submit(data, reason)
    }
  }

  const { junk = '', shared = '' } = data ? getReasonLabel(data.type) : {}

  return (
    <Dialog open={!!data} onClose={close} title={<DialogTitle title={data?.text || ''} titleOverflow="ellipsis" />}>
      <DialogContent>
        <RadioGroup aria-label="invalidReasonOpened" name="opt" style={{ marginBottom: 16 }}>
          <Radio {...register('opt')} value="1" label={!isEmail ? multipleLabel : shared} />
          <Radio {...register('opt')} value="2" label={junk} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button variant="text" onClick={submitInvalid} disabled={!watch().opt} disablePR>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AuditInvalidDialog.TriggerEl = TriggerEl

export default AuditInvalidDialog
