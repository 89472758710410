import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles<{ wide: boolean }>()((theme, { wide }) => ({
  wrapper: {
    boxSizing: 'content-box',
    padding: `${theme.spacing(4)} ${theme.spacing(wide ? 8 : 4)}`,
    border: '2px #eee solid',
    borderRadius: 10,
    width: '100%',
    maxWidth: 240,
    margin: `${theme.spacing(wide ? 3 : 2)} ${theme.spacing(wide ? 2 : 0.5)}`
  },
  outlook: {
    width: '100%',
    maxWidth: '96px',
    margin: `${theme.spacing(4)} 0`
  },
  chrome: {
    width: '100%',
    maxWidth: '96px',
    margin: `${theme.spacing(4)} ${theme.spacing(1)}`
  },
  edge: {
    width: '100%',
    maxWidth: '112px',
    margin: `${theme.spacing(4)} ${theme.spacing(1)}`
  }
}))

export const Apps = () => {
  const { setMobileHeader } = React.useContext(LayoutContext)
  const wide = useWide()
  const { classes } = useStyles({ wide })

  const deployment: string = window.location.hostname + (window.location.port ? ':' + window.location.port : '')

  const download = () => {
    fetch('/office365Manifest.xml', {
      method: 'GET'
    })
      .then((response) => response.text())
      .then((text) => {
        const result = text.replace(/\{\{DEPLOYMENT\}\}/gi, deployment)
        let file
        const properties = { type: 'application/xml' }
        try {
          file = new File([result], 'office365Manifest.xml', properties)
        } catch (e) {
          file = new Blob([result], properties)
        }
        const url = window.URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = url
        a.download = 'office365Manifest.xml'
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
      })
  }

  React.useEffect(() => {
    setMobileHeader('Apps')
  }, [setMobileHeader])

  return (
    <Page>
      <Widget scope="none">
        <Box mx={{ md: 5 }} my={{ md: 3 }}>
          {wide && <Typography variant="h1">Apps</Typography>}
          <Box display="flex" flexDirection={wide ? 'row' : 'column'} justifyContent="center" alignItems="center">
            <Box className={classes.wrapper}>
              <Box display="flex" flexDirection="column" alignItems="center" minHeight="160px">
                <img src="/image/apps/outlook.png" className={classes.outlook} />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                DotAlign&apos;s Outlook add-in brings the DotAlign experience right inside your Outlook, whether you use the desktop app or the
                web app.
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box my={2}>
                  <Link href="https://help.dotalign.com/article/g5uudm4esb-set-up-dot-align-for-outlook" target="_blank" rel="noopener noreferrer">
                    <Button variant="text">Learn more</Button>
                  </Link>
                </Box>
                <Button onClick={download} startIcon={<FontAwesomeIcon icon={['fas', 'cloud-download-alt']} style={{ fontSize: 14 }} />}>
                  Download Manifest
                </Button>
              </Box>
            </Box>
            <Box className={classes.wrapper}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" minHeight="160px">
                <img src="/image/apps/chrome.png" className={classes.chrome} />
                <img src="/image/apps/edge.png" className={classes.edge} />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                DotAlign&apos;s Chrome and Edge extension brings the DotAlign experience into Salesforce and LinkedIn, and can also be added to
                your inhouse CRM and apps.
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box my={2}>
                  <Link
                    href="https://help.dotalign.com/article/7k79lvtild-using-the-dot-align-cloud-extension-for-chrome"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="text">Learn more</Button>
                  </Link>
                </Box>
                <Link
                  href="https://chrome.google.com/webstore/detail/dotalign-cloud/ofahkjbhcadlldpoahogjikmdoibbejh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="text">Go to the Chrome Web Store</Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Widget>
    </Page>
  )
}

export default Apps
