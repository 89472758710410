import React from 'react'

import Joyride, { TooltipRenderProps } from 'react-joyride'
import { makeStyles } from 'tss-react/mui'

import { ActiveOnboarding, Step } from '_core/context/Onboarding'

import { Button } from '_shared/buttons'
import Card, { CardContent, CardActions } from '_shared/Card'

import Heading from '_core/components/Heading'
import eventsSteps from '_core/components/onboarding/Events'
import filterbarSteps from '_core/components/onboarding/Filterbar'
import introductionsSteps from '_core/components/onboarding/Introductions'
import privilegedCompaniesSteps from '_core/components/onboarding/PrivilegedCompanies'
import sidebarSteps from '_core/components/onboarding/Sidebar'
import teamProfileSteps from '_core/components/onboarding/TeamProfile'

import useOnboarding from '_core/hooks/useOnboarding'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    '& .MuiCardContent-root': {
      paddingBottom: theme.spacing(1),
      '&, & *': {
        verticalAlign: 'unset'
      }
    }
  },
  actions: {
    paddingTop: 0,
    justifyContent: 'flex-end'
  }
}))

const stepsMap: { [key in ActiveOnboarding]: Step[] } = {
  [Paths._events]: eventsSteps,
  [Paths._teamProfile]: teamProfileSteps,
  [Paths._introductions]: introductionsSteps,
  [DataAdminSubPaths.privilegedCompanies]: privilegedCompaniesSteps,
  sidebar: sidebarSteps,
  filterbar: filterbarSteps
}

type TooltipProps = {
  step: Step
} & Omit<TooltipRenderProps, 'step'>

const Tooltip = ({ step, isLastStep, tooltipProps, continuous, primaryProps, backProps, index, size }: TooltipProps) => {
  const { classes } = useStyles()
  const { content, title, headingActions, primaryLabel } = step
  const { completeOnboarding } = useOnboarding(continuous)

  return (
    <Card {...tooltipProps} className={classes.wrapper}>
      <CardContent>
        <Heading underlined title={title} action={headingActions} />
        {content}
      </CardContent>
      <CardActions classes={{ root: classes.actions }}>
        {index > 0 && (
          <Button variant="text" color="secondary" {...backProps} onClick={backProps.onClick}>
            {backProps.title}
          </Button>
        )}
        <Button variant="text" color="primary" {...primaryProps} onClick={isLastStep ? completeOnboarding : primaryProps.onClick}>
          {primaryLabel || (continuous ? `${primaryProps.title} (${index + 1}/${size})` : 'ok')}
        </Button>
      </CardActions>
    </Card>
  )
}

const Onboarding = () => {
  const { active } = useOnboarding()

  if (!active) {
    return null
  }

  const steps = stepsMap[active]

  return (
    <Joyride
      disableScrollParentFix
      disableCloseOnEsc
      disableOverlayClose
      run={!!active}
      steps={steps}
      continuous={steps.length > 1}
      styles={{
        options: {
          zIndex: 1100
        }
      }}
      tooltipComponent={Tooltip}
    />
  )
}

export default Onboarding
