import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography, { Variant } from '_shared/Typography'

const useStyles = makeStyles<{ type: SharingBarProps['type'] }>()((theme, { type }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  shareIcon: {
    marginTop: '5px',
    marginRight: '12px',
    fontSize: 15,
    cursor: 'default',
    color: type === 'team' ? theme.palette.text.secondary : theme.palette.secondary.main,
    '&:last-of-type': {
      marginRight: 0
    }
  },
  shareIconEnabled: {
    marginTop: '5px',
    marginRight: '10px',
    color: theme.palette.success.main,
    fontSize: 20
  }
}))

type SharingBarProps = {
  userSharing?: SharingData
  variant?: Variant
  className?: string
  text?: string
  type?: 'team'
}

const SharingBar = (props: SharingBarProps) => {
  const { classes } = useStyles({ type: props.type })
  const { userSharing, variant, className, text } = props

  return (
    <Box className={classes.container}>
      {text && userSharing?.isSharing && (
        <Typography style={{ marginRight: '16px' }} color="text.secondary">
          {text}
        </Typography>
      )}

      <Typography variant={variant} className={className} color="text.secondary">
        {!userSharing?.isSharing && <>Not sharing</>}

        {userSharing?.isSharing && (
          <FontAwesomeIcon icon={['fas', 'check-circle']} title="Share relationships with team members" className={classes.shareIcon} />
        )}

        {userSharing?.isSharingContactInfo && (
          <FontAwesomeIcon icon={['far', 'address-card']} title="Share contact info with team members" className={classes.shareIcon} />
        )}

        {userSharing?.isSharingInteractionStats && (
          <FontAwesomeIcon icon={['far', 'comment']} title="Share interaction details with team members" className={classes.shareIcon} />
        )}

        {userSharing?.isSharingContactInfoWithAdminsAndCurators && (
          <FontAwesomeIcon icon={['fas', 'address-card']} title="Share contact info with admins and curators" className={classes.shareIcon} />
        )}
      </Typography>
    </Box>
  )
}

export default SharingBar
