import { useState, ChangeEvent, SetStateAction, Dispatch, memo, ReactNode, useCallback, Fragment } from 'react'

import { Box, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { invalidReasonMap } from '_pages/manual-edits/data'

import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import AuditInvalidDialog from '_core/components/dialogs/AuditInvalid'
import AuditValidDialog from '_core/components/dialogs/AuditValid'
import { Collapsed as SearchInput } from '_core/components/SearchInput'

import useAuditEntities from '_core/hooks/useAuditEntities'

import { ellipsis } from 'AppTheme'

import AuditMoveDialog from '../dialogs/AuditMove'

const useStyles = makeStyles()((theme) => ({
  textFieldWrapper: {
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  groupsList: {
    width: '100%',
    height: 'calc(100% - 105px - 8px)',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 105px)'
    }
  },
  formControlRoot: {
    flex: 1,
    maxWidth: `calc(100% + 11px)`,
    marginRight: 0
  },
  formControlLabel: {
    maxWidth: `calc(100% - 40px)`
  },
  listItemSecondaryAction: {
    paddingRight: 0
  },
  listItemContainer: {
    '&:hover > .MuiListItem-root': {
      maxWidth: 'calc(100% - 97px)'
    },
    '&:hover > .MuiListItemSecondaryAction-root': {
      opacity: 1,
      pointerEvents: 'all'
    }
  },
  secondaryAction: {
    display: 'flex',
    opacity: 0,
    pointerEvents: 'none',
    marginRight: `-${theme.spacing(1)}`
  },
  textField: {
    width: '100%'
  },
  adorned: {
    color: '#A7A7A7'
  },
  icon: {
    fontSize: 15,
    width: 31,
    height: 31,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  identifiersList: {
    '& > .MuiListItem-container': {
      '&:first-of-type .MuiListItemText-root': {
        marginTop: 0
      },
      '&:last-of-type .MuiListItemText-root': {
        marginBottom: 0
      }
    }
  },
  title: {
    lineHeight: theme.spacing(5)
  },
  typeText: ellipsis
}))

export type IdentityProps = {
  isInvalidActive: boolean
  loading: boolean
  typesMap: { [key: string]: string }
  openInvalid: (data: AuditIdentifier) => void
  openValid: (data: AuditIdentifier) => void
  openSources: (data: Pick<AuditIdentifier, 'text' | 'type'>) => void
  openAddForm: (data: AuditIdentifier) => void
  addFormOpened: boolean
} & AuditIdentifier

export const Identity = memo((props: IdentityProps) => {
  const { classes } = useStyles()
  const {
    isInvalidActive,
    loading,
    text,
    md5,
    type,
    equivalentText,
    equivalentMd5,
    distilledKeyMd5,
    distilledKeyText,
    typesMap,
    invalidReason,
    addFormOpened,
    openInvalid,
    openValid,
    openAddForm,
    openSources
  } = props
  const identifier = { text, md5, type, equivalentText, equivalentMd5, distilledKeyMd5, distilledKeyText }

  const onMarkAsInvalidClick = () => {
    openInvalid(identifier)
  }

  const onMarkAsValidClick = () => {
    openValid(identifier)
  }

  const onCreateEntityClick = () => {
    openAddForm(identifier)
  }

  const onOpenSourcesClick = () => {
    openSources({ text, type })
  }

  const reason = invalidReason as keyof typeof invalidReasonMap
  return (
    <ListItem dense disablePadding classes={{ container: classes.listItemContainer, secondaryAction: classes.listItemSecondaryAction }}>
      <Box width={1}>
        <Skeleton condition={loading}>
          <Tooltip title={text}>
            <ListItemText
              secondaryTypographyProps={{ variant: 'h5' }}
              classes={{ secondary: classes.typeText }}
              primary={<Typography ellipsis>{text || 'very long placeholder'}</Typography>}
              secondary={!isInvalidActive ? typesMap[type as keyof typeof typesMap] : invalidReasonMap[reason]}
            />
          </Tooltip>
        </Skeleton>
      </Box>

      {!loading && (
        <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
          {!isInvalidActive && (
            <>
              <IconButton
                classes={{ root: classes.icon }}
                disabled={addFormOpened}
                onClick={onOpenSourcesClick}
                icon={['fas', 'list-tree']}
                hint="Sources"
              />
              <IconButton
                classes={{ root: classes.icon }}
                disabled={addFormOpened}
                onClick={onCreateEntityClick}
                icon={['far', 'user-plus']}
                hint="Create entity"
              />
              <AuditInvalidDialog.TriggerEl classes={{ root: classes.icon }} open={onMarkAsInvalidClick} />
            </>
          )}
          {isInvalidActive && <AuditValidDialog.TriggerEl classes={{ root: classes.icon }} open={onMarkAsValidClick} />}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
})

type IdentifiersListProps = {
  checked: boolean
  toggleSelectAll: () => void
  children: ReactNode
}

const IdentifiersList = (props: IdentifiersListProps) => {
  const { children, checked, toggleSelectAll } = props
  const { isInvalidActive } = useAuditEntities()
  const { classes, cx } = useStyles()

  const label = (
    <Typography variant="h4" semiBold classes={{ root: cx({ [classes.title]: isInvalidActive }) }}>
      Identifiers
    </Typography>
  )

  return (
    <>
      <ListItem dense>
        {isInvalidActive && label}
        {!isInvalidActive && <Checkbox checked={checked} onChange={toggleSelectAll} label={label} />}
      </ListItem>
      {children}
    </>
  )
}

const AuditIdentifiers = (props: {
  openMove: () => void
  setChecked: Dispatch<SetStateAction<AuditIdentifier[]>>
  moveTitle: string
  items: AuditIdentifier[]
  loading: boolean
  checked: AuditIdentifier['md5'][]
  renderIdentity: (props: AuditIdentifier) => JSX.Element
}) => {
  const { classes, cx } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })
  const { isInvalidActive } = useAuditEntities()

  const { openMove, setChecked, moveTitle, checked, loading, renderIdentity } = props

  const [searchValue, setSearchValue] = useState<string>('')
  const isDisabled = !checked.length

  const filteredItemsBySearch = props.items.reduce(
    (acc, { text, distilledKeyMd5 }) => (text?.toLowerCase().includes(searchValue.toLowerCase()) ? [...acc, distilledKeyMd5] : acc),
    [] as AuditIdentifier['distilledKeyMd5'][]
  )
  const filteredGroupedItems = props.items.filter(({ distilledKeyMd5 }) => filteredItemsBySearch.includes(distilledKeyMd5))

  const items = loading ? props.items : filteredGroupedItems

  const distilledGroups = items.reduce(
    (acc, { distilledKeyMd5, ...rest }) => ({
      ...acc,
      [distilledKeyMd5]: distilledKeyMd5 in acc ? [...acc[distilledKeyMd5], { ...rest, distilledKeyMd5 }] : [{ ...rest, distilledKeyMd5 }]
    }),
    {} as { [key: AuditIdentifier['distilledKeyMd5']]: AuditIdentifier[] }
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const handleClearInput = () => {
    setSearchValue('')
  }

  const toggleSelectAll = () => {
    setChecked((prevState) => (prevState.length === filteredGroupedItems.length ? [] : filteredGroupedItems))
  }

  const toggleSelect = useCallback(
    (itms: AuditIdentifier[]) => {
      setChecked((prevState) =>
        prevState.find(({ md5 }) => itms[0].md5 === md5)
          ? prevState.filter(({ md5 }) => !itms.find((item) => md5 === item.md5))
          : [...prevState, ...itms]
      )
    },
    [setChecked]
  )

  return (
    <>
      <Box className={classes.textFieldWrapper}>
        <SearchInput opened placeholder="Search identifiers" value={searchValue} onChange={handleInputChange} clearInput={handleClearInput} />
        {!isInvalidActive && <AuditMoveDialog.TriggerEl hint={moveTitle} open={openMove} disabled={isDisabled} classes={{ root: classes.icon }} />}
      </Box>

      <IdentifiersList toggleSelectAll={toggleSelectAll} checked={!!checked.length && checked.length === items.length}>
        {!isInvalidActive && (
          <List className={classes.groupsList} dense disablePadding>
            {(Object.keys(distilledGroups) as (keyof typeof distilledGroups)[]).map((key) => (
              <Box width={1} key={key}>
                <ListItem>
                  <Checkbox
                    classes={{
                      ...checkboxClasses,
                      root: cx(checkboxClasses.root, classes.formControlRoot),
                      label: cx(checkboxClasses.label, classes.formControlLabel)
                    }}
                    onChange={() => toggleSelect(distilledGroups[key])}
                    checked={checked.includes(distilledGroups[key][0].md5)}
                    label={
                      <Box width={1}>
                        <Box mt={loading ? -0.6 : -1}>
                          <Card variant="outlined">
                            <CardContent>
                              <List dense disablePadding className={classes.identifiersList}>
                                {distilledGroups[key].map((item, i) => (
                                  <Fragment key={item.md5 || i}>
                                    {renderIdentity({
                                      type: item.type,
                                      md5: item.md5,
                                      invalidReason: item.invalidReason,
                                      text: item.text,
                                      equivalentMd5: item.equivalentMd5,
                                      equivalentText: item.equivalentText,
                                      distilledKeyMd5: item.distilledKeyMd5,
                                      distilledKeyText: item.distilledKeyText
                                    })}
                                  </Fragment>
                                ))}
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        )}
        {isInvalidActive && (
          <List className={classes.groupsList} dense disablePadding>
            {(Object.keys(distilledGroups) as (keyof typeof distilledGroups)[]).map((key) => (
              <Box width={1} key={key}>
                <ListItem>
                  <Box width={1}>
                    <Card variant="outlined">
                      <CardContent>
                        <List dense disablePadding className={classes.identifiersList}>
                          {distilledGroups[key].map((item, i) => (
                            <Fragment key={item.md5 || i}>
                              {renderIdentity({
                                type: item.type,
                                md5: item.md5,
                                invalidReason: item.invalidReason,
                                text: item.text,
                                equivalentMd5: item.equivalentMd5,
                                equivalentText: item.equivalentText,
                                distilledKeyMd5: item.distilledKeyMd5,
                                distilledKeyText: item.distilledKeyText
                              })}
                            </Fragment>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Box>
                </ListItem>
              </Box>
            ))}
          </List>
        )}
      </IdentifiersList>
    </>
  )
}

export default AuditIdentifiers
