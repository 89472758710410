import { useState, useEffect, ReactNode, ChangeEvent } from 'react'

import { useMsal } from '@azure/msal-react'
import { Box, RadioGroup, Radio, FormControlLabel, TextField, ButtonGroup } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'

import { statusMap, Main } from '_core/components/Event'

import { post, del } from 'utils/httpUtils'
import { formatDate, formatTime } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  applyFor: {
    display: 'flex',
    alignItems: 'center'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  radioGroup: {
    marginLeft: `-${theme.spacing(1.5)}`
  },
  group: {
    marginBottom: theme.spacing(2)
  },
  controlLabel: {
    margin: 0
  },
  externalIcon: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    fontSize: 13,
    '& svg': {
      opacity: 0.6
    }
  },
  note: {
    marginTop: theme.spacing(1)
  }
}))

export enum PopupKind {
  STATUS_UPDATE = 'statusUpdate',
  REMOVE_EVENT = 'removeEvent'
}

export enum Repeated {
  all = 'all',
  one = 'one'
}

type RemoveProps = {
  item: Pick<
    EventType,
    'location' | 'onlineMeetingJoinUrl' | 'sourceKey' | 'seriesMasterKey' | 'startTimeUTC' | 'endTimeUTC' | 'subject' | 'webLink' | 'responseStatus'
  > & { organizer: LocalEventParticipantsWithPeople }
  isOpen: boolean
  close: () => void
  confirmStatus: (val: EventStatusKeys) => void
}

type UpdateProps = {
  item: Pick<
    EventType,
    'location' | 'onlineMeetingJoinUrl' | 'sourceKey' | 'seriesMasterKey' | 'startTimeUTC' | 'endTimeUTC' | 'subject' | 'webLink' | 'responseStatus'
  > & { organizer: LocalEventParticipantsWithPeople }
  repeated?: Repeated
  status: EventStatusKeys
  isOpen: boolean
  close: () => void
  confirmStatus: (val: EventStatusKeys) => void
  updateStatus: (val: EventStatusKeys) => void
  setRepeated: (val: Repeated) => void
}

const Title = (props: { startTimeUTC: string; endTimeUTC: string; webLink: string }) => {
  return (
    <DialogTitle
      title={formatDate(props.startTimeUTC, 'dddd, Do MMMM YYYY')}
      subtitle={`${formatTime(props.startTimeUTC)} - ${formatTime(props.endTimeUTC)}`}
      titleLinkIcon={['far', 'external-link']}
      titleLink={props.webLink}
      titleLinkTooltip={'View in outlook'}
    />
  )
}

const Content = (
  props: {
    children: ReactNode
    onClose: () => void
    organizer: LocalEventParticipantsWithPeople
  } & Pick<EventType, 'subject' | 'location' | 'onlineMeetingJoinUrl'>
) => {
  const { classes } = useStyles()
  return (
    <DialogContent>
      <Main
        className={classes.main}
        subject={props.subject}
        location={props.location}
        onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
        organizer={props.organizer}
        closePopup={props.onClose}
      />
      {props.children}
    </DialogContent>
  )
}

const ApplyFor = ({ register, defaultValue }: UseFormReturn & { defaultValue: Repeated }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.applyFor}>
      <RadioGroup aria-label="repeated" name="repeated" defaultValue={defaultValue} classes={{ root: classes.radioGroup }}>
        <FormControlLabel {...register('repeated')} value={Repeated.one} className={classes.controlLabel} control={<Radio />} label="This event" />
        <FormControlLabel
          {...register('repeated')}
          value={Repeated.all}
          className={classes.controlLabel}
          control={<Radio />}
          label="All events in the series"
        />
      </RadioGroup>
    </Box>
  )
}

export const RemovePopup = ({ isOpen, close, item, confirmStatus }: RemoveProps) => {
  const { accounts } = useMsal()
  const loggedInUser = accounts[0]
  const formApi = useForm({ shouldUnregister: true })
  const { watch } = formApi
  const [loading, setLoading] = useState<boolean>(false)

  const submit = async () => {
    setLoading(true)
    const isOrganizer = item?.organizer?.BestEmailAddrText === loggedInUser.username
    const key = watch('repeated') === Repeated.all && item.seriesMasterKey ? item.seriesMasterKey : item.sourceKey
    try {
      isOrganizer ? await post(`/Events/${key}/cancel`) : await del(`/Events/${key}`)
      confirmStatus('Declined')
    } finally {
      setLoading(false)
      close()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      loading={loading}
      title={<Title webLink={item?.webLink} startTimeUTC={item?.startTimeUTC} endTimeUTC={item?.endTimeUTC} />}
    >
      <Content
        subject={item?.subject}
        location={item?.location}
        onlineMeetingJoinUrl={item?.onlineMeetingJoinUrl}
        organizer={item?.organizer}
        onClose={close}
      >
        {item?.seriesMasterKey && <ApplyFor {...formApi} defaultValue={Repeated.one} />}
      </Content>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disablePR>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const UpdateStatusPopup = ({ item, repeated, status, isOpen, close, confirmStatus, setRepeated, updateStatus }: UpdateProps) => {
  const { classes } = useStyles()
  const formApi = useForm({ shouldUnregister: true })
  const { watch } = formApi
  const [loading, setLoading] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')

  useEffect(() => {
    setNote('')
  }, [isOpen])

  useEffect(() => {
    if (isOpen && item?.responseStatus && !status) {
      confirmStatus(item.responseStatus.responseType)
    }
  }, [confirmStatus, item?.responseStatus, status, isOpen])

  const submit = async () => {
    setLoading(true)
    const { endpoint, id } = statusMap[status as EventStatusKeys]
    try {
      const repeated = watch('repeated')
      const key = repeated === Repeated.all && item.seriesMasterKey ? item.seriesMasterKey : item.sourceKey
      await post(`/Events/${key}/${endpoint}?comment=${note}`)
      confirmStatus(id)
      setRepeated(repeated as Repeated)
    } finally {
      close()
      setLoading(false)
    }
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value)
  }

  const statusesButtons: EventStatusKeys[] = ['Accepted', 'TentativelyAccepted', 'Declined']

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      loading={loading}
      title={<Title webLink={item?.webLink} startTimeUTC={item?.startTimeUTC} endTimeUTC={item?.endTimeUTC} />}
    >
      <Content
        subject={item?.subject}
        location={item?.location}
        onlineMeetingJoinUrl={item?.onlineMeetingJoinUrl}
        organizer={item?.organizer}
        onClose={close}
      >
        <ButtonGroup classes={{ root: classes.group }} color="primary" size="small" disableElevation disableRipple>
          {statusesButtons.map((key) => (
            <Button key={key} variant={statusMap[key].id === status ? 'contained' : 'outlined'} onClick={() => updateStatus(key)}>
              {statusMap[key].action}
            </Button>
          ))}
        </ButtonGroup>
        {item?.seriesMasterKey && repeated && <ApplyFor {...formApi} defaultValue={repeated} />}
        <TextField
          value={note}
          onChange={onChange}
          multiline
          variant="outlined"
          fullWidth
          rows={5}
          placeholder="Add note..."
          classes={{ root: classes.note }}
        />
      </Content>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disablePR>
          Respond
        </Button>
      </DialogActions>
    </Dialog>
  )
}
