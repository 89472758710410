import { GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'

import Sort, { SortProps } from './index'

type SortMapType = {
  [key in GridSortItem['field']]: { [key in Exclude<GridSortDirection, undefined | null>]: IntroductionsSortType }
}

export const sortMap: SortMapType = {
  byActivity: {
    asc: 'ByActivityAsc',
    desc: 'ByActivityDesc'
  },
  byCreated: {
    asc: 'ByCreatedAsc',
    desc: 'ByCreatedDesc'
  }
}

export const getSortByField = (sort?: IntroductionsSortType) =>
  Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

export const getSortModels = (sort?: IntroductionsSortType) =>
  (Object.keys(sortMap) as (keyof typeof sortMap)[]).map((sortKey) => ({
    field: sortKey,
    sort: (sort === sortMap[sortKey].asc ? 'asc' : 'desc') as GridSortDirection
  }))

export const updateSort = (model: GridSortModel, sort: IntroductionsSortType | undefined, updateSort: any) => {
  if (model?.length) {
    const { field, sort: newSort } = model[0]
    if (newSort && sort !== sortMap[field][newSort]) {
      const sort = sortMap[field][newSort]
      updateSort(sort)
    }
  }
}

export type IntroductionsSortProps = {
  sort?: IntroductionsSortType
  updateSort: (val: IntroductionsSortType) => void
} & Omit<SortProps, 'children'>

const items = [
  { field: 'byActivity', label: 'Last activity' },
  { field: 'byCreated', label: 'Created' }
]

const IntroductionsSort = (props: IntroductionsSortProps) => {
  const appliedSort = Object.keys(sortMap).find((key) => sortMap[key].asc === props.sort || sortMap[key].desc === props.sort)

  const handleClick = (field: string) => {
    const newSort = props.sort === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc
    if (newSort) props.updateSort(newSort)
  }

  return (
    <Sort className={props.className} collapsed={props.collapsed}>
      {items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={props.sort === sortMap[item.field].desc}
          onClick={() => handleClick(item.field)}
        />
      ))}
    </Sort>
  )
}

export { Controller } from './index'
export default IntroductionsSort
