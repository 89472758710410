import React, { useState, useContext, useRef, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, IconButton, CircularProgress, Box, SelectChangeEvent, useMediaQuery } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Link, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AnalyzeLeadsProps, companyPlaceholder, IAnalyzeLeadsProps, LeadTypeEnum, leadTypes, personPlaceholder } from '_pages/analyze-leads/types'
import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Select from '_shared/forms/Select'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import AnalyzeLeadsHeader from '_core/components/AnalyzeLeadsHeader'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import useLocalStorage from '_core/hooks/useLocalStorage'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useTeam from '_core/hooks/useTeam'

import { triggerDownloadXl } from 'utils/Utils'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'
import TextFieldHelper from 'SharedComponents/TextFieldHelper'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 'calc(100% - 43px)',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 54px - 16px)'
    }
  },
  container: {
    height: 'calc(100vh - 61px - 54px - 20px)',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sidepanel')]: {
      height: 'calc(100vh - 61px - 54px)'
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 61px - 85px)'
    }
  },
  leadsForm: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 0',
    [theme.breakpoints.up('md')]: {
      padding: '8px 0 0 0'
    }
  },
  placeholder: {
    color: theme.palette.text.hint,
    position: 'absolute',
    whiteSpace: 'pre-wrap',
    lineHeight: '1.2',
    zIndex: -1,
    left: '14px',
    top: '16px'
  },
  textarea: {
    height: '100%',
    '& textarea': {
      height: '100%',
      minHeight: '200px'
    },
    '& textarea:not(:placeholder-shown)': {
      background: 'white'
    },
    '& textarea:not(:empty)': {
      background: 'white'
    }
  },
  buttonContainer: actionButtons(theme),
  title: {
    fontWeight: 500,
    display: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'inherit'
    }
  },
  undoIcon: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
    marginRight: 42,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: '0',
      right: '0'
    }
  },
  undoIconSuccess: {
    display: 'none'
  },
  wrapper: {
    backgroundColor: theme.palette.background.light,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    padding: '15px'
  },
  innerWrapper: {
    border: '1px solid #ddd',
    borderBottom: '1px solid transparent',
    borderRadius: '4px',
    height: '100px',
    marginTop: '15px'
  }
}))

const ButtonUndo = ({ styles, handleReset }: any) => (
  <Box display="flex" alignItems="center" margin="-10px 0px">
    <Tooltip title="Reset">
      <IconButton onClick={handleReset} classes={{ root: styles ? styles : '' }}>
        <FontAwesomeIcon icon={['far', 'undo']} style={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  </Box>
)

const MiniAnalyzeLeads: React.FC<any> = ({ leadTypes, handleSelect, leadType, hasFullWidth }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Select options={leadTypes} onChange={handleSelect} value={leadType} fullWidth={hasFullWidth} />
      <Box className={classes.innerWrapper}></Box>
    </Box>
  )
}

type LeadType = '' | LeadTypeEnum.company | LeadTypeEnum.person

const AnalyzeLeads: React.FC<AnalyzeLeadsProps> = () => {
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const leadsRef = useRef<HTMLTextAreaElement>(null)
  const { classes } = useStyles()
  const location = useLocation()
  useEffect(() => {
    setMobileHeader('Analyze Leads')
    setSubHeader('')
  }, [setMobileHeader, setSubHeader])

  const isSuccessPage = location.pathname.includes(`${Paths._analyzeLeads}/success`)
  const sidepanel = isSidepanel()

  const handleReset = () => {
    if (leadsRef.current) leadsRef.current.value = ''
  }

  return (
    <div className={classes.container}>
      {sidepanel ? (
        <Topbar nativeBack action={isSuccessPage ? null : <ButtonUndo handleReset={handleReset} />} />
      ) : (
        <AnalyzeLeadsHeader
          buttonUndo={<ButtonUndo handleReset={handleReset} styles={isSuccessPage ? classes.undoIconSuccess : classes.undoIcon} />}
        />
      )}
      <Switch>
        <Route path={`${Paths._analyzeLeads}/success`} component={AnalyzeLeadsSuccess} />
        <Route exact path={Paths._analyzeLeads} component={() => <AnalyzeLeadsComponent leadsRef={leadsRef} />} />
      </Switch>
    </div>
  )
}

const AnalyzeLeadsComponent: React.FC<IAnalyzeLeadsProps> = ({ leadsRef }) => {
  const { teamContextValue } = useContext(TeamContext)
  const [excludeFormerEmployees, setExcludeFormerEmployees] = useLocalStorage('excludeFormerEmployees', false)
  const [leadType, setLeadType] = useState<LeadType>(LeadTypeEnum.company)
  const { enqueueSnackbar } = useSnackbar()
  const [leadsValue, setLeadsValue] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { classes } = useStyles()
  const minicard = useMediaQuery('(max-height:180px)')
  const { getLeadsList } = useTeam()

  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    setLeadType(event.target.value as LeadType)
  }

  const handleSubmit = async () => {
    setLoading(true)
    enqueueSnackbar('Analysis is in progress. Download should begin shortly.', {
      variant: 'default'
    })

    try {
      const idsArray = leadsValue.split('\n').filter((leadValue) => leadValue.trim())
      const fetchedData = await getLeadsList(
        teamContextValue.teamNumber.toString(),
        leadType || LeadTypeEnum.company,
        idsArray,
        excludeFormerEmployees
      )
      if (fetchedData) {
        // Trigger a download
        const onclickHandler = () => {
          history.push(`${Paths._analyzeLeads}/success`)
        }
        triggerDownloadXl(fetchedData, leadType, onclickHandler)
      } else {
        setTimeout(() => {
          enqueueSnackbar('Analysis complete. No matches found.', {
            variant: 'default'
          })
        }, 5000)
      }
    } catch (error) {
      enqueueSnackbar('Encountered error while processing request', {
        variant: 'error'
      })
      console.log('ERROR DURING DATA FETCH', error)
    }
  }

  if (minicard) return <MiniAnalyzeLeads handleSelect={handleSelect} leadTypes={leadTypes} leadType={leadType} hasFullWidth />

  return (
    <Box className={classes.root}>
      {!loading && (
        <form onSubmit={handleSubmit} className={classes.leadsForm}>
          <Select options={leadTypes} onChange={handleSelect} value={leadType} fullWidth />
          <Box flex="1" width="100%" mt="24px" position="relative" height="100%">
            <TextFieldHelper
              textFieldProps={{
                name: 'leads',
                variant: 'outlined',
                multiline: true,
                fullWidth: true,
                className: classes.textarea,
                placeholder: ' ',
                InputProps: { style: { height: '100%' } },
                inputProps: {
                  ref: leadsRef,
                  style: { height: '100%', overflow: 'scroll-y' }
                },
                onChange: (e: any) => setLeadsValue(e.target.value)
              }}
              max={1000}
              seed={(val) => val.split('\n').filter((leadValue) => leadValue.trim()).length}
              hint={`Enter one ${
                leadType === LeadTypeEnum.company ? LeadTypeEnum.company.toLowerCase() : LeadTypeEnum.person.toLowerCase()
              } per line`}
            />
            <Box className={classes.placeholder}>{leadType === LeadTypeEnum.person ? personPlaceholder : companyPlaceholder}</Box>
          </Box>
          {leadType === LeadTypeEnum.company && (
            <Box display="flex" mt={1} alignItems="center">
              <Checkbox checked={excludeFormerEmployees === 'true'} onChange={(e) => setExcludeFormerEmployees(e.target.checked.toString())} />
              <div>
                <Typography variant="body1">Exclude former employees</Typography>
              </div>
            </Box>
          )}
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleSubmit}
              disabled={!leadsValue.trim() || !leadType || leadsValue.split('\n').filter((leadValue) => leadValue.trim()).length > 1000}
              variant="text"
              disablePR
            >
              Download
            </Button>
          </div>
        </form>
      )}

      {loading && (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size="70px" />
        </Box>
      )}
    </Box>
  )
}

const AnalyzeLeadsSuccess = () => {
  const handleClose = useSidepanelClose(Paths._home)

  return (
    <Success text="The download is complete!" variant="centered">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Link to={Paths._analyzeLeads}>
          <Button startIcon={<FontAwesomeIcon icon={['far', 'search']} style={{ fontSize: 14 }} />}>Analyze more leads</Button>
        </Link>
        <Button variant="text" onClick={handleClose} color="secondary" style={{ marginTop: 16 }}>
          Close
        </Button>
      </Box>
    </Success>
  )
}

export default AnalyzeLeads
