import React, { useContext, useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'

import Empty from '_core/components/Empty'
import { Controller } from '_core/components/filters'
import Filters from '_core/components/filters/SimilarCompanies'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import InfiniteList from '_core/components/market-data/InfiniteList'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { CompaniesType, FiltersType } from '_core/hooks/useSimilarCompanies'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface SimilarCompaniesProps {
  marketData: { [key: string]: any } | null
  website: string
  similar: CompaniesType | null
  setSimilar: (arg: CompaniesType | null) => void
  teamNumber: number
  loading: boolean
}

const SimilarCompanies = ({ marketData, similar, setSimilar, teamNumber, ...props }: SimilarCompaniesProps) => {
  const { queryParams, updateQuery } = useSearchQuery<any>()
  const [openedFilters, setOpenedFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<FiltersType | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const notAppliedFilters = useRef<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort } = useAbortableFetch<{ [key: string]: any }>()

  const load = loading || props.loading
  const { employee_count, industry, location, type, website: marketDataWebsite } = marketData || {}
  const website = marketDataWebsite || props.website
  const enoughFilters = marketData && website

  useEffect(() => {
    setSubHeader(`Similar Companies ${similar?.total ? `· ${similar.total}` : ''}`)
  }, [similar?.total])

  useEffect(() => {
    if (queryParams.clearSearch && setSimilar && !props.loading) {
      updateQuery({ clearSearch: null })
      setSimilar(null)
      setFilters(null)
    }
    if (queryParams.openedFilters === 'true' && !props.loading) {
      updateQuery({ openedFilters: null })
      setOpenedFilters(true)
    }
  }, [queryParams.clearSearch, queryParams.openedFilters, setSimilar, props.loading])

  useEffect(() => {
    const storedFilters = id && similar?.id && similar?.id === id && !!similar?.filters
    if (!filters && storedFilters && !queryParams.clearSearch) {
      setFilters(similar.filters)
      return
    }

    if (marketData && !filters) {
      setInitialFilters()
      return
    }

    if (filters && notAppliedFilters.current && !openedFilters) {
      notAppliedFilters.current = false
      if (storedFilters) {
        setFilters(similar.filters)
        return
      }
      if (marketData) {
        setInitialFilters()
        return
      }
    }
  }, [filters, similar?.id, similar?.filters, id, marketData, queryParams.clearSearch, notAppliedFilters.current, openedFilters])

  const setNoSimilar = (persistFilters?: boolean, error?: string) =>
    setSimilar({
      id,
      website,
      data: [],
      scroll_token: '',
      total: 0,
      error: error || 'Not enough market data is available for an effective search filter',
      ...(persistFilters && filters && { filters })
    })

  const getSimilar = async (persistFilters?: boolean, loadMore?: boolean) => {
    if (filters && teamNumber) {
      try {
        setLoading(true)
        const res = await fetchWithAbort({
          url: mergeUrlWithParams('/companies/similarCompanies', {
            website,
            titlecase: 'true',
            teamNumber: teamNumber.toString(),
            ...(loadMore && similar?.scroll_token && { scrollToken: similar.scroll_token }),
            ...filters
          })
        })
        if (res) {
          setSimilar({
            id,
            website,
            data: loadMore && similar?.data ? [...similar.data, ...res.data] : res.data,
            scroll_token: res.scroll_token || '',
            total: res.total,
            ...(persistFilters && { filters })
          })
        }
      } catch (e: any) {
        setNoSimilar(persistFilters, e?.status === 500 ? 'No records were found' : e?.error)
        console.log('error during fetching similar companies', e)
      } finally {
        if (persistFilters) {
          notAppliedFilters.current = false
        }
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (filters && !similar && !load) {
      enoughFilters ? getSimilar() : setNoSimilar()
    }
  }, [load, similar, filters, enoughFilters])

  const items = similar?.data?.map((company: { [key: string]: any }) => {
    return {
      name: company.name,
      score: company.companyMd5 ? company.score : undefined,
      logoUrl: company.website,
      link: company.companyMd5
        ? `${Paths._companies}/${company.companyMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
            website: company.website
          }),
      sidepanel: true,
      byline: company.website,
      byline2: company.industry
    }
  })

  const setInitialFilters = () => {
    setFilters({
      minimumEmployeeCount: employee_count ? Math.round(Number(employee_count) / 2).toString() : '',
      maximumEmployeeCount: employee_count ? Math.round(Number(employee_count) * 1.5).toString() : '',
      industries: industry ? [industry] : [],
      countries: enoughFilters ? [location?.country || 'United States'] : [],
      companyType: enoughFilters ? type || 'Private' : ''
    })
  }

  const updateFilters = (updatedFilters: FiltersType) => {
    notAppliedFilters.current = true
    setFilters(updatedFilters)
  }

  const toggleOpen = () => setOpenedFilters((prevState: boolean) => !prevState)
  const applyFilters = () => {
    const emptyFilters =
      !!filters &&
      Object.keys(filters)?.every((key: string) => {
        const typedKey = key as keyof FiltersType
        return Array.isArray(filters[typedKey]) ? !filters[typedKey].length : !filters[typedKey]
      })
    emptyFilters ? setNoSimilar(true) : getSimilar(true)
  }
  const loadMore = () => getSimilar(true, true)
  const reset = () => {
    notAppliedFilters.current = true
    setInitialFilters()
  }

  const hasMore = Boolean(similar?.data?.length && similar.total > similar.data.length && similar?.scroll_token)

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <Controller opened={openedFilters} toggleOpen={toggleOpen} disabled={!filters} variant="text" ref={anchorRef} />
        <Filters
          opened={openedFilters}
          disabled={!filters}
          contentLoading={load}
          anchorEl={anchorRef.current}
          total={similar?.total || 0}
          filters={filters}
          updateFilters={updateFilters}
          reset={reset}
          applyFilters={applyFilters}
        />
        <InfiniteList loading={loading} next={loadMore} dataLength={similar?.total || 0} hasMore={hasMore}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            empty={<Empty title={similar?.error || 'No records were found'} />}
            items={items || []}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default SimilarCompanies
