import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import PersonActivitiesList, { Heading } from '_core/components/ContactActivitiesList'
import Empty from '_core/components/Empty'
import Filters from '_core/components/filters/PersonActivities'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useCompanyActivities from '_core/hooks/useCompanyActivities'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useUserActivitiesUserSettings from '_core/hooks/useUserActivitiesUserSettings'

import UserSettings from '_core/UserSettings'

import { dateFormatURLQuery, formatDate, getLocal } from 'utils/Utils'

const CompanyActivitiesPage = () => {
  const { id } = useParams<{ id: string }>()
  const { queryParams } = useSearchQuery<EntityActivitiesPageParams>()
  const { name, from, to, sort } = queryParams

  const {
    setInitial,
    reset,
    handleChange,
    loading: userSettingsLoading,
    toggleOpen,
    opened,
    isReady: isUserSettingsReady
  } = useUserActivitiesUserSettings()
  const { loading, activities, hasMore, more, reload } = useCompanyActivities(isUserSettingsReady, id)

  const onSettingsChange = (updates: Partial<EntityActivitiesPageParams>, additionalParams?: { [key in 'isOpened']?: boolean }) => {
    if ('interaction' in updates || 'sort' in updates) {
      reload()
    }
    handleChange(updates, additionalParams)
  }

  const contentLoading = !isUserSettingsReady || !activities || loading

  const filtersProps = {
    disabled: userSettingsLoading,
    opened,
    contentLoading,
    queryData: queryParams,
    handleChange: onSettingsChange,
    toggleOpen,
    reset
  }

  const toggleSort = () => {
    onSettingsChange({ sort: sort === 'NewestToOldest' ? 'OldestToNewest' : 'NewestToOldest' })
  }

  return (
    <Page>
      <Narrow>
        <UserSettings endpoint="/usersettings/useractivitiesfilter" setInitial={setInitial}>
          <Topbar
            nativeBack={true}
            title={name}
            sub={`${formatDate(getLocal(from, dateFormatURLQuery))} - ${formatDate(getLocal(to, dateFormatURLQuery))}`}
          />
          <Heading
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, toggle: toggleSort }}
            filters={<Filters {...filtersProps} />}
          />
          {!contentLoading && !activities.length && <Empty title="No activities found" />}
          {(contentLoading || activities.length > 0) && (
            <InfiniteScroll
              loading={contentLoading}
              dataLength={activities?.length || 0}
              next={more}
              refreshFunction={reload}
              hasMore={hasMore}
              scrollableTarget="activities_list"
            >
              <PersonActivitiesList activities={activities} />
            </InfiniteScroll>
          )}
        </UserSettings>
      </Narrow>
    </Page>
  )
}

export default CompanyActivitiesPage
