import { useEffect, useContext, useMemo } from 'react'

import { Route, Switch, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'

import LeaveDialog from '_core/components/dialogs/Leave'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import { Narrow } from '_core/components/layout'
import { SelectTagsActions, SelectTagsContent } from '_core/components/SelectTagsForm'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsSuccessBody, { UpdateTagsSuccessLayout } from '_core/components/TagsSuccess'
import Topbar from '_core/components/Topbar'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import { TaggableType } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useTagsAssign from '_core/hooks/useTagsAssign'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Component = ({ items: appliedTags, loading: appliedTagsLoading }: { items: UngroupedTag[]; loading: boolean }) => {
  const admin = useAdmin()

  const { eventId } = useParams<{ eventId: string }>()
  const { queryParams } = useSearchQuery<{ name: string; appointmentUID: string }>()
  const { name, appointmentUID } = queryParams || {}

  const { items, totalActive, initialTagsProvided, isReady, itemsLoading, clearSelected, toggleActive, setFilteredTags, handleSave, isDirty } =
    useTagsAssign({
      taggableType: 'meetings',
      items: appliedTags,
      loading: appliedTagsLoading,
      sidepanel: 'preview'
    })

  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const hasInitTagsSpecified = !!items?.length

  const manageTagsLink = `${Paths._tags}/meetings`

  useEffect(() => {
    setMobileHeader(appliedTagsLoading ? '' : hasInitTagsSpecified ? 'Edit tags' : 'Add tags', appliedTagsLoading)
  }, [setMobileHeader, hasInitTagsSpecified, appliedTagsLoading])

  useEffect(() => {
    setSubHeader(name)
  }, [setSubHeader, name])

  const closeSidepanel = useSidepanelClose(`${Paths._events}/${eventId}`)

  const handleSubmit = () => {
    handleSave([appointmentUID], mergeUrlWithParams(`${Paths._events}/${eventId}/tags/edit/success`, { name, appointmentUID }))
  }

  const handleCancel = () => {
    isDirty ? openDialog() : closeSidepanel()
  }

  return (
    <FormLayout>
      <Topbar
        nativeBack
        {...(admin
          ? {
              action: (
                <SidepanelLink linkProps={{ to: manageTagsLink }}>
                  <IconButton icon={['far', 'money-check-pen']} size="small" hint="Manage tags" />
                </SidepanelLink>
              )
            }
          : {})}
      />
      <FormLayoutContent>
        <SelectTagsContent
          isReady={isReady}
          initialTagsProvided={initialTagsProvided}
          loading={itemsLoading}
          manageTagsLink={manageTagsLink}
          items={items}
          toggleActive={toggleActive}
          setFilteredTags={setFilteredTags}
        />
      </FormLayoutContent>
      <FormLayoutActions>
        <SelectTagsActions
          clearSelected={clearSelected}
          handleSubmit={handleSubmit}
          totalActive={totalActive}
          disabledApply={!isDirty}
          handleCancel={handleCancel}
        />
      </FormLayoutActions>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={closeSidepanel} />
    </FormLayout>
  )
}

const EditTagsPage = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { queryParams } = useSearchQuery<{ name: string; appointmentUID: string }>()
  const { name, appointmentUID } = queryParams || {}
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)

  const successUrl = `${Paths._events}/${eventId}/tags/edit/success`

  const url = useMemo(() => {
    return `/tags/categorizedtags?identifier=${appointmentUID}&taggableType=${TaggableType.meetings}&teamNumber=${teamNumber}`
  }, [appointmentUID, teamNumber])

  return (
    <Page>
      <Narrow>
        <Switch>
          <Route path={successUrl}>
            <Topbar nativeBack />
            <UpdateTagsSuccessLayout>
              <TagsSuccessBody
                showTagsLink={mergeUrlWithParams(`${Paths._events}/${eventId}/tags`, { name, appointmentUID })}
                closeLink={`${Paths._events}/${eventId}`}
              />
            </UpdateTagsSuccessLayout>
          </Route>
          <Route>
            <DynamicEntity url={url} id="edit_tags" component={Component} list keepMounted />
          </Route>
        </Switch>
      </Narrow>
    </Page>
  )
}

export default EditTagsPage
