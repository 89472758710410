import { ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

const useStyles = makeStyles()((theme) => ({
  bar: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    minWidth: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
  }
}))

const InnerDialog = (props: { close?: () => void; children: ReactNode }) => {
  const {
    classes: { bar, closeButton }
  } = useStyles()

  return (
    <>
      {props.close && (
        <div className={bar}>
          <Button variant="text" onClick={props.close} color="inherit" className={closeButton}>
            <FontAwesomeIcon icon={['far', 'times']} style={{ fontSize: 13 }} />
          </Button>
        </div>
      )}
      {props.children}
    </>
  )
}

export default InnerDialog
