import React, { useState, useCallback, HTMLAttributes, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Combobox from '_shared/forms/Combobox'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import UnsavedChangesWarning from '_core/components/dialogs/UnsavedChangesWarning'
import { SidepanelWide, SidepanelNarrow } from '_core/components/layout'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpBothPeopleCompanies } from '_core/hooks/useLookup'

import Paths from 'Paths'

import AccountMenu from './AccountMenu'
import { salesorceRoute } from './data'

type HeaderProps = {
  sourceUrl: string
  loading: boolean
  loggedInUserData: any
}

const useStyles = makeStyles()((theme) => ({
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 0,
    wordBreak: 'break-word',
    maxWidth: '100%',
    width: 'fit-content'
  },
  icon: {
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  autoCompleteWrapper: {
    '& .MuiInputBase-input': {
      '& > div': {
        padding: '6.5px 13.5px',
        '& > p': {
          bottom: 'unset'
        }
      }
    }
  },
  subTitle: {
    fontSize: 14,
    maxWidth: '100%',
    width: 'fit-content'
  }
}))

const url = Paths._salesforce

const initSelected = {
  id: '',
  name: ''
}

type SelectedType = {
  id: string
  name: string
  type?: 'contact' | 'account'
}
type OType = {
  id: string
  value: string
  label: string
  email?: string
  type: 'people' | 'companies'
  phone?: string
  phoneType?: PhoneType
  userKey?: string
  company?: {
    name: string
  }
  url?: string
  logoUrl?: string
}

const transformData = (sortedData?: (PeopleListItem | CompaniesListItem)[]) => {
  return sortedData?.map((result) => {
    if ('PersonMd5' in result) {
      return {
        id: result.PersonMd5,
        value: result.BestJobTitleText || '',
        label: result.PersonNameText,
        email: result.BestEmailAddrText,
        type: 'people' as OType['type'],
        phone: result.BestPhoneText,
        phoneType: result.BestPhoneType,
        userKey: result.BestEmailAddrText,
        company: {
          name: result.BestJobMatchedCompanyName || result.BestJobCorpLevelCompanyName || ''
        }
      }
    }
    return {
      id: result.CompanyMd5,
      value: result.BestUrlText,
      label: result.CompanyNameText,
      type: 'companies' as OType['type'],
      url: result.BestUrlText,
      logoUrl: result.BestUrlText
    }
  })
}

const getOptionLabel = (option: { [key: string]: any }) => option.label

const USearch = ({ onSelect }: { onSelect: (value: OType) => void }) => {
  const { lookupBothPeopleCompanies, forceAbort } = useLookUpBothPeopleCompanies()
  const { classes } = useStyles()
  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox<OType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookupBothPeopleCompanies(searchTerm)
        if (result) {
          return transformData(result)
        }
      },
      [lookupBothPeopleCompanies]
    ),
    forceAbort
  })

  useEffect(() => {
    if (value) {
      onSelect(value)
    }
  }, [value])

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OType) => (
    <li {...props}>
      <ProfileItem
        name={option.label}
        byline={option.value || option.url}
        byline2={option.company?.name}
        logoUrl={option.logoUrl}
        userKey={option.userKey}
      />{' '}
    </li>
  )

  return (
    <Box className={classes.autoCompleteWrapper}>
      <Combobox
        loading={optionsLoading}
        placeholder="Search"
        value={value}
        inputValue={inputValue}
        open={open}
        options={options}
        onClose={handleClose}
        onOpen={handleOpen}
        onFocus={handleFocus}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        autoFocus
      />
    </Box>
  )
}

const Header = ({ loading, sourceUrl, loggedInUserData }: HeaderProps) => {
  const {
    formState: { isDirty },
    watch
  } = useFormContext()

  const data: Record<string, any> = watch()

  const { Name, Email, Website } = Object.keys(data).reduce(
    (acc, field) => {
      const { selectedSource, selectedValue = {} } = data[field] || {}
      const { value } = selectedValue[selectedSource] || {}
      acc[field as keyof typeof data] = value
      return acc
    },
    {} as Record<string, any>
  )

  const history = useHistory()
  const { classes, cx } = useStyles()

  const [selectedEntity, setSelectedEntity] = useState<SelectedType>(initSelected)
  const [isSearchExpanded, setSearchExpanded] = useState<boolean>(false)

  const toggleSearchExpand = () => {
    setSearchExpanded((prevState) => !prevState)
  }

  const goToSelected = (id: string) => {
    history.push(`${url}/${selectedEntity.type}/${id}`)
    setWarningClose()
  }

  const handleSelect = (selected: OType) => {
    if (selected?.id) {
      const type = salesorceRoute[selected.type]
      isDirty ? setSelectedEntity({ id: selected.id, name: selected.label, type }) : history.push(`${url}/${type}/${selected.id}`)
    }
  }

  const setWarningClose = () => {
    setSelectedEntity(initSelected)
  }

  return (
    <>
      <SidepanelWide>
        <Box p={2} display="grid" alignItems="center" gridTemplateColumns="minmax(0, 1fr) auto auto" height={86} boxSizing="border-box">
          <Box display="flex" alignItems="center">
            <Box mx={1}>
              <Skeleton condition={loading} width={300} height={20}>
                <Tooltip title={Name}>
                  <Typography variant="h4" className={classes.name} ellipsis color={`text.${Name ? 'primary' : 'disabled'}`}>
                    {Name || 'Name not specified'}
                  </Typography>
                </Tooltip>
              </Skeleton>
              <Skeleton condition={loading} width={250} height={20}>
                <Tooltip title={Website || Email}>
                  <Typography variant="body1" className={classes.subTitle} ellipsis color={`text.${Website || Email ? 'hint' : 'disabled'}`}>
                    {'Email' in data && (Email || 'Email address not specified')}
                    {'Website' in data && (Website || 'Website url not specified')}
                  </Typography>
                </Tooltip>
              </Skeleton>
            </Box>
            {!loading && sourceUrl && (
              <Tooltip title="View in salesforce">
                <IconButton classes={{ root: classes.icon }} component={Link} to={{ pathname: sourceUrl }} target="_blank">
                  <FontAwesomeIcon icon={['far', 'external-link']} style={{ width: 14, height: 14 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {isSearchExpanded && (
            <Box width={310}>
              <UnsavedChangesWarning selected={selectedEntity} openedName={Name} handleClose={setWarningClose} handleSubmit={goToSelected} />
              <USearch onSelect={handleSelect} />
            </Box>
          )}
          {!loading && (
            <Box>
              <Tooltip title="search">
                <IconButton classes={{ root: classes.icon }} onClick={toggleSearchExpand}>
                  <FontAwesomeIcon icon={['far', 'search']} size="xs" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Learn more about DotAlign's Salesforce integration">
                <IconButton
                  classes={{ root: classes.icon }}
                  component={Link}
                  to={{ pathname: 'https://help.dotalign.com/article/m3agr0chbj-save-to-salesforce' }}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={['far', 'question-circle']} size="xs" />
                </IconButton>
              </Tooltip>
              <AccountMenu
                currentUser={{
                  name: loggedInUserData.name,
                  username: loggedInUserData.emailAddress,
                  profileLink: loggedInUserData.profileUrl,
                  userId: loggedInUserData.userId,
                  organizationId: loggedInUserData.organizationId,
                  organizationUrl: loggedInUserData.organizationUrl
                }}
                profilePic={loggedInUserData?.profilePictureUrl}
              />
            </Box>
          )}
        </Box>
      </SidepanelWide>
      <SidepanelNarrow>
        <Box p={2}>
          <Box display="grid" alignItems="center" gridTemplateColumns="minmax(0, 1fr) auto">
            <Box>
              <Skeleton condition={loading} width={200} height={20}>
                <Tooltip title={Name}>
                  <Typography variant="h4" className={cx({ [classes.name]: !!Name })} ellipsis color={`text.${Name ? 'primary' : 'secondary'}`}>
                    {Name || 'Not specified'}
                  </Typography>
                </Tooltip>
              </Skeleton>

              <Skeleton condition={loading} width={150} height={20}>
                <Tooltip title={Website || Email}>
                  <Typography
                    variant="body1"
                    className={cx({ [classes.subTitle]: Website || Email })}
                    ellipsis
                    color={`text.${Website || Email ? 'hint' : 'secondary'}`}
                  >
                    {Website || Email || 'Not specified'}
                  </Typography>
                </Tooltip>
              </Skeleton>
            </Box>
            {!loading && (
              <Box display="flex" alignItems="center">
                <Tooltip title="search">
                  <IconButton classes={{ root: classes.icon }} onClick={toggleSearchExpand}>
                    <FontAwesomeIcon icon={['far', 'search']} size="xs" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Learn more about DotAlign's Salesforce integration">
                  <IconButton
                    classes={{ root: classes.icon }}
                    component={Link}
                    to={{ pathname: 'https://help.dotalign.com/article/m3agr0chbj-save-to-salesforce' }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['far', 'question-circle']} size="xs" />
                  </IconButton>
                </Tooltip>
                <Box marginLeft={1}>
                  <AccountMenu
                    currentUser={{
                      name: loggedInUserData.name,
                      username: loggedInUserData.emailAddress,
                      profileLink: loggedInUserData.profileUrl,
                      userId: loggedInUserData.userId,
                      organizationId: loggedInUserData.organizationId,
                      organizationUrl: loggedInUserData.organizationUrl
                    }}
                    profilePic={loggedInUserData?.profilePictureUrl}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {isSearchExpanded && (
            <Box width={1} marginTop={2}>
              <UnsavedChangesWarning selected={selectedEntity} openedName={Name} handleClose={setWarningClose} handleSubmit={goToSelected} />
              <USearch onSelect={handleSelect} />
            </Box>
          )}
        </Box>
      </SidepanelNarrow>
    </>
  )
}

export default Header
