import { useState } from 'react'

const useSelectedInfoHandler = (type: 'people' | 'companies') => {
  const [selected, setSelected] = useState<any[]>([])

  const handleSelectedInfo = (entities: any[], selectedItems: string[] = selected) => {
    const info = entities
      .filter((entity: any) => selectedItems.includes(entity.id))
      .map((entity: any) => {
        if (type === 'people') {
          const { id, name, email, isPrivateRelations } = entity
          return { id, name, email, isPrivateRelations }
        }
        if (type === 'companies') {
          const { id, name } = entity
          return { id, name }
        }
      })
    setSelected(info)
  }

  const selectedIDs = selected.map((entity) => entity.id)
  const selectedNames = selected.map((entity) => entity.name)

  const selectedUserKeys = type === 'people' ? selected.filter((entity) => entity.email).map((entity) => entity.email) : []
  const allSelectedArePrivate = type === 'people' ? selected.every((entity) => entity.isPrivateRelations === true) : false
  const allSelectedArePublic = type === 'people' ? selected.every((entity) => entity.isPrivateRelations === false) : false

  return {
    selected,
    setSelected,
    selectedUserKeys,
    selectedIDs,
    selectedNames,
    allSelectedArePrivate,
    allSelectedArePublic,
    handleSelectedInfo
  }
}

export default useSelectedInfoHandler
