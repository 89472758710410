import { ReactNode } from 'react'

import { Box, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import Widget from '_core/components/Widget'

import { actionButtons } from 'AppTheme'

const useStyles = makeStyles<Partial<{ sidepanel: boolean }> | void>()((theme, params) => ({
  wrapper: {
    boxSizing: 'border-box',
    height: `calc(100vh - ${params?.sidepanel ? 145.5 : 117.5}px - ${theme.spacing(3)})`,
    overflowY: 'auto',
    padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}`,
    marginTop: theme.spacing(2)
  },
  actions: actionButtons(theme)
}))

const Content = ({ children }: { children: ReactNode }) => {
  const sidepanel = isSidepanel()
  const { classes } = useStyles({ sidepanel })
  return (
    <Widget style={{ padding: 0 }}>
      <Container disableGutters classes={{ root: classes.wrapper }}>
        {children}
      </Container>
    </Widget>
  )
}

const Actions = ({ children }: { children: ReactNode }) => {
  const { classes } = useStyles()
  return <Box className={classes.actions}>{children}</Box>
}

const FormLayout = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}

export { Content as FormLayoutContent, Actions as FormLayoutActions }
export default FormLayout
