import { forwardRef } from 'react'

import { FormControlLabel, FormControlLabelProps, Radio as MuiRadio } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: 0
  },
  label: {
    marginTop: 9,
    flex: 1
  }
}))

const Radio = forwardRef((props: Omit<FormControlLabelProps, 'control' | 'classes'>, ref) => {
  const { classes } = useStyles()

  return <FormControlLabel inputRef={ref} {...props} control={<MuiRadio />} classes={{ root: classes.root, label: classes.label }} />
})

export default Radio
