export const destroyAnimation = (el) => {
  el.parentNode.style.overflow = 'hidden'
  el.style.maxHeight = '300px'
  setTimeout(() => {
    el.style.transition = 'all 500ms ease-in'
    el.style.transform = 'translateX(100%) scale(0.1)'
    el.style.opacity = 0
    el.style.maxHeight = 0
    el.style.padding = 0
    setTimeout(() => {
      el.style.display = 'none'
      el.parentNode.style.overflow = ''
    }, 500)
  }, 100)
}
