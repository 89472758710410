import { makeStyles } from 'tss-react/mui'

import useScrollDirection, { ScrollDirection } from '_core/hooks/useScrollDirection'

const useStyles = makeStyles<{ scrollDirection: ScrollDirection; height: number }>()((theme, { scrollDirection, height = 60 }) => ({
  root: {
    transform: scrollDirection === ScrollDirection.up ? 'translateY(0px)' : `translateY(-${height}px)`,
    transition: 'transform 0.5s ease'
  }
}))

const useAutoHideOnScrollStyles = (active: boolean, height = 60) => {
  const scrollDirection = useScrollDirection(active)
  const { classes } = useStyles({ scrollDirection, height })
  const styles = {
    transform: scrollDirection === ScrollDirection.up ? 'translateY(0px)' : `translateY(-${height}px)`,
    transition: 'transform 0.5s ease'
  }

  return { autoHideStyles: styles, autoHideClassName: classes.root }
}

export default useAutoHideOnScrollStyles
