import React, { useContext, useEffect, useMemo } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { Button } from '_shared/buttons'
import Page from '_shared/Page'

import IntroductionDetails, { IntroductionProps } from '_core/components/introductions/IntroductionDetails'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import { useStyles } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const IntroductionPage = () => {
  const { id, contactId } = useParams<{ id: string; contactId: string }>()
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Introduction timeline')
  }, [setSubHeader])

  const memoUrls = useMemo(
    () => [
      {
        key: 'introduction',
        url: `/prospecting/targetedcontacts?PlanUids=${id}&EntityIdentifiers=${contactId}&WithEvents=true&ClosedFilter=All`
      },
      {
        key: 'statuses',
        url: `/prospecting/introducerstatuses`
      }
    ],
    [id, contactId]
  )

  return (
    <Page>
      <Narrow>
        <DynamicEntity urls={memoUrls} component={Component} id="introd_details" keepMounted />
      </Narrow>
    </Page>
  )
}

const Component = (props: IntroductionProps) => {
  const handleClose = useSidepanelClose(Paths._introductions)
  const { classes } = useStyles()

  return (
    <>
      <Topbar nativeBack />
      <IntroductionDetails {...props} />
      <Box className={classes.actionButtons}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePR>
          Close
        </Button>
      </Box>
    </>
  )
}

export default IntroductionPage
