import { useState } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import Checkbox from '_shared/forms/Checkbox'
import Typography from '_shared/Typography'

import Settings from '_core/components/settings'

import { SaveAction } from '_core/hooks/useSaveSettings'

const DemoSettings = () => {
  const [isSaving, setSaving] = useState<boolean>(false)
  const { register, getValues } = useForm({ mode: 'onChange', shouldUnregister: true })
  const defaultKeys = window.localStorage.getItem('demo_keys') || `dotalign.com=mycompany.com\nDotAlign=MyCompany\n`
  const defaultEnabled = window.localStorage.getItem('demo_enabled') === 'true'

  const save = (action: SaveAction) => {
    setSaving(true)
    window.localStorage.setItem('demo_keys', getValues().demo_keys)
    window.localStorage.setItem('demo_enabled', getValues().demo_enabled)
    action('User demo settings')
    setTimeout(() => {
      setSaving(false)
      window.location.reload()
    }, 1000)
  }

  return (
    <Settings isSaving={isSaving} save={save}>
      {window.localStorage.getItem('demo_mode') === 'true' && (
        <Box>
          <Checkbox {...register('demo_enabled')} defaultChecked={defaultEnabled} name="demo_enabled" label="Turn on demo mode" />
          <Typography color="text.secondary" style={{ marginTop: 8, marginBottom: 16 }}>
            In the text box below, add values in the format <b>old=new</b>, one per line. The new values will be used to replace the old ones. All
            other text will be obfuscated.
          </Typography>
          <textarea
            {...register('demo_keys')}
            rows={8}
            name="demo_keys"
            style={{ width: '100%', fontSize: '14px', lineHeight: 1.5, fontFamily: '"Source Sans Pro","Open Sans",sans-serif' }}
            defaultValue={defaultKeys}
          ></textarea>
        </Box>
      )}
    </Settings>
  )
}

export default DemoSettings
