import { sortMap } from '_core/components/sort'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { put } from 'utils/httpUtils'

type SaveData = {
  endpoint: string
  getData: (params: any) => {}
}

const useFilter = () => {
  const { queryParams, updateQuery } = useSearchQuery<any>()

  const save = <T,>({ endpoint, getData }: SaveData, params: any) => {
    const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === params.sort || sortMap[key].desc === params.sort)

    updateQuery({
      ...params,
      page: null,
      excludeEmpty: sortByField && sortMap[sortByField].excludeEmpty ? queryParams.excludeEmpty : null
    })
    return put<T>(endpoint, getData(params))
  }

  return {
    save
  }
}
export default useFilter
