import { useCallback, useState } from 'react'

type DefaultProps<T> = {
  isDialogOpened: boolean
  dialogContentProps: T
  openDialog: (params?: T) => void
  hideDialog: (params?: T) => void
  closeDialog: () => void
  successMode: boolean
  openSuccess: () => void
}

function useDialog<T = undefined>(): DefaultProps<T | undefined>
function useDialog<T>(initialContent: T): DefaultProps<T>

function useDialog<T>(initialContent?: T) {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const [contentProps, setContentProps] = useState<typeof initialContent | T>(initialContent)

  const [successMode, setSuccessMode] = useState<boolean>(false)

  const open = useCallback((params?: T) => {
    setSuccessMode(false)
    setIsOpened(true)
    if (params) {
      setContentProps(params)
    }
  }, [])

  const close = useCallback(() => {
    setIsOpened(false)
    if (contentProps) {
      setContentProps(initialContent)
    }
  }, [contentProps, initialContent])

  const hide = () => {
    setIsOpened(false)
  }

  const openSuccess = () => {
    setSuccessMode(true)
  }

  return {
    openDialog: open,
    closeDialog: close,
    hideDialog: hide,
    isDialogOpened: isOpened,
    dialogContentProps: contentProps,
    successMode,
    openSuccess
  }
}

export default useDialog
