import React, { useContext } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'

import { useSidepanelWide } from '_core/components/layout'

import { getExecutorPage } from '_core/hooks/useSalesforce'
import useSidebar from '_core/hooks/useSidebar'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles()((theme) => ({
  container: actionButtons(theme, 'sticky'),
  saveText: {
    color: '#212121',
    opacity: 0.6,
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(2.5)}`,
    letterSpacing: 0.5
  }
}))

type ActionButtonsProps = {
  loading: boolean
  isSaving: boolean
}

const ActionButtons = ({ loading, isSaving }: ActionButtonsProps) => {
  const wide = useSidepanelWide()
  const history = useHistory()
  const {
    watch,
    formState: { errors, isDirty }
  } = useFormContext()
  const { params } = useRouteMatch<SalesforceMatchParams>()
  const { entity } = params
  const { close: sidebarClose } = useSidebar()
  const { classes } = useStyles()
  const { sidepanelHistory } = useContext(LayoutContext)
  const sidepanel = isSidepanel('sidepanel')

  const hasSalesforceData = !!Object.values(watch()).find(({ selectedValue }) => selectedValue.Salesforce.value)
  const isSaveAllowed = !loading && !Object.keys(errors).length && (isDirty || !hasSalesforceData)

  const handleCancel = () => {
    const { id } = params
    if (sidepanel && wide) {
      sidebarClose()
    }

    if (sidepanel && !wide) {
      const [, , type, id] = sidepanelHistory[0].pathname.split('/')
      history.push(type && id ? `/${getExecutorPage(type)}/${id}` : '/')
    }

    if (!sidepanel) {
      history.push(`/${getExecutorPage(entity)}/${id}`)
    }
  }

  return (
    <Box className={classes.container}>
      <Button variant="text" onClick={handleCancel} color="secondary" disabled={loading}>
        Cancel
      </Button>
      <Button variant="text" type="submit" startIcon={isSaving && <CircularProgress size={15} />} disabled={!isSaveAllowed} disablePR>
        Save
      </Button>
    </Box>
  )
}

export default ActionButtons
