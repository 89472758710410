import React, { useContext, useState, SyntheticEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import { SidepanelWide, SidepanelNarrow } from '_core/components/layout'

import useTeam from '_core/hooks/useTeam'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginTop: theme.spacing(2)
  }
}))

const TriggerEl = ({ open, loading, className }: { open: (e: SyntheticEvent) => void; loading: boolean; className: string }) => {
  return (
    <>
      <SidepanelWide>
        <Button
          color="primary"
          disabled={loading}
          startIcon={<FontAwesomeIcon icon={['far', 'times']} style={{ fontSize: 14 }} />}
          onClick={open}
          className={className}
        >
          Remove team
        </Button>
      </SidepanelWide>
      <SidepanelNarrow>
        <IconButton
          color="primary"
          hint="Remove team"
          loading={loading}
          icon={['far', 'times']}
          style={{ fontSize: 14, width: 38 }}
          onClick={open}
          className={className}
        />
      </SidepanelNarrow>
    </>
  )
}

const RemoveTeamModal = ({ isOpen, close, team }: RemoveTeamModalProps) => {
  const [confirmation, setConfirmation] = useState('')
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { updateTeamFetchCounter } = useContext(TeamContext)

  const { removeTeam, saveToLSRemovedTeams } = useTeam()

  const { classes } = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setConfirmation(value)
    if (value.toLowerCase().trim() === team.name.toLowerCase()) {
      setIsConfirmed(true)
    } else {
      setIsConfirmed(false)
    }
  }

  const handleRemoveTeam = (e: SyntheticEvent) => {
    setLoading(true)
    removeTeam(`${team.id}`)
      .then(() => {
        saveToLSRemovedTeams(team.id)
        updateTeamFetchCounter()
        setLoading(false)
        close(e)
        enqueueSnackbar(`The team "${team.name}" has been deleted successfully`)
        history.push(Paths._teams)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <Dialog open={isOpen} onClose={close} loading={loading} title={<DialogTitle title={`Delete team "${team.name}"?`} />}>
      <DialogContent>
        <Typography>Are you sure you would like to delete the team? This action cannot be undone.</Typography>
        <Typography>Please type in the team name below to continue.</Typography>
        <TextField
          id="removing-team-confirmation"
          placeholder="Team name"
          value={confirmation}
          className={classes.textField}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={handleRemoveTeam} disablePR disabled={!isConfirmed || loading}>
          Delete now
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type RemoveTeamModalProps = {
  isOpen: boolean
  close: (e: SyntheticEvent) => void
  team: Team
}

RemoveTeamModal.TriggerEl = TriggerEl

export default RemoveTeamModal
