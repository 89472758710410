import React, { ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  }
}))

interface InfiniteListProps {
  next: () => void
  children: ReactNode
  dataLength: number
  hasMore: boolean
  loading: boolean
}

const InfiniteList = ({ dataLength, next, hasMore, loading, children }: InfiniteListProps) => {
  const { classes } = useStyles()

  return (
    <>
      {children}
      {hasMore && !loading && (
        <Box className={classes.wrapper}>
          <Button fullWidth variant="link" onClick={next} endIcon={<FontAwesomeIcon icon={['far', 'arrow-rotate-right']} style={{ fontSize: 14 }} />}>
            Show more
          </Button>
        </Box>
      )}
      {!!dataLength && loading && (
        <Box className={classes.wrapper}>
          <CircularProgress color="secondary" />
        </Box>
      )}
    </>
  )
}

export default InfiniteList
