import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DefaultSuccessAction, DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { del } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    flex: 1,
    minHeight: '52px',
    marginLeft: theme.spacing(2)
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18
  }
}))

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => {
  const { classes } = useStyles()
  return (
    <Tooltip title="Delete requests" disabled={disabled}>
      <IconButton disabled={disabled} color="primary" onClick={open} style={{ width: '42px' }}>
        <FontAwesomeIcon className={classes.icon} icon={['far', 'trash']} />
      </IconButton>
    </Tooltip>
  )
}

const DeleteIntroductionRequestDialog = ({
  isOpened,
  closeDialog,
  reload,
  items,
  openSuccess,
  successMode
}: {
  isOpened: boolean
  closeDialog: () => void
  items: any[]
  successMode: boolean
  openSuccess: () => void
  reload?: () => void
}) => {
  const [fetching, setFetching] = useState(false)
  const handleClose = useSidepanelClose(Paths._introductions)
  const wide = useWide()

  const remove = async () => {
    setFetching(true)
    const res = await del('/prospecting/plan', items)
    if (res) {
      openSuccess()
      setFetching(false)
    }
  }

  const closeHandler = () => {
    if (reload) {
      reload()
    }
    closeDialog()
    if (!wide) {
      handleClose()
    }
  }

  return (
    <Dialog
      open={isOpened}
      success={successMode}
      onClose={successMode ? closeHandler : closeDialog}
      loading={fetching}
      title={
        <DialogTitle
          title={`Delete the selected ${items.length === 1 ? 'request' : 'requests'}?`}
          titleLink="https://help.dotalign.com"
          titleLinkTooltip="Learn more about request deleting"
        />
      }
    >
      <DialogContent successContent={`The ${items.length === 1 ? 'request is' : 'requests are'} successfully deleted`}>
        <Typography>Request is not visible on the main screen any more.</Typography>
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={closeHandler} />}>
        <Button variant="text" onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={remove} disablePR disabled={!items.length}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteIntroductionRequestDialog.TriggerEl = TriggerEl

export default DeleteIntroductionRequestDialog
