const latestKeys = ['LastInboundMsg', 'LastOutboundMsg', 'LastMeeting']

export const hasLatest = (resp) => latestKeys.filter((k) => Object.prototype.hasOwnProperty.call(resp, k)).length > 0

export const latestTouchpointKey = (resp) => {
  const matches = Object.keys(resp)
    .map((t) => ({ key: t, value: resp[t] }))
    .filter((item) => latestKeys.indexOf(item.key) > -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))
  return matches.length > 0 ? matches[0].key : ''
}

export const firstKeys = ['FirstInboundMsg', 'FirstOutboundMsg', 'FirstMeeting']

export const hasFirst = (resp) => firstKeys.filter((k) => Object.prototype.hasOwnProperty.call(resp, k)).length > 0

export const firstTouchpointKey = (resp) => {
  const matches = Object.keys(resp)
    .map((t) => ({ key: t, value: resp[t] }))
    .filter((item) => firstKeys.indexOf(item.key) > -1)
    .sort((a, b) => (a.value > b.value ? 1 : -1))
  return matches.length > 0 ? matches[0].key : ''
}

export const nextKeys = ['NextFutureMeeting']

export const hasNext = (resp) => nextKeys.filter((k) => Object.prototype.hasOwnProperty.call(resp, k)).length > 0

export const nextTouchpointKey = (resp) => {
  const matches = Object.keys(resp)
    .map((t) => ({ key: t, value: resp[t] }))
    .filter((item) => nextKeys.indexOf(item.key) > -1)
    .sort((a, b) => (a.value > b.value ? 1 : -1))
  return matches.length > 0 ? matches[0].key : ''
}
