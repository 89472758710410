import React from 'react'

import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'

import { getStatusIcon } from '_core/components/introductions/ContactItem'

import { formatDate } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  oppositeContent: {
    flex: 0.2,
    paddingLeft: 0
  },
  date: {
    display: 'inline-block',
    paddingTop: theme.spacing(1)
  },
  content: {
    paddingRight: 0,
    maxWidth: '100%',
    overflow: 'hidden'
  },
  icon: {
    fontSize: 14,
    width: 14,
    maxWidth: 14
  },
  iconWrapper: {
    width: 20,
    textAlign: 'center'
  },
  dotSkeleton: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75)
  },
  defaultDot: {
    backgroundColor: theme.palette.secondary.light
  },
  successDot: {
    backgroundColor: theme.palette.success.light
  },
  errorDot: {
    backgroundColor: theme.palette.error.light
  },
  warningDot: {
    backgroundColor: theme.palette.warning.light
  }
}))

type ActivityItemProps = {
  date: string
  label: React.ReactNode
  hideConnector: boolean
  loading: boolean
  status: string
}

const ActivityItem = ({ date, label, hideConnector, loading, status }: ActivityItemProps) => {
  const { classes, cx } = useStyles()
  const { color, icon: Icon } = getStatusIcon(status)

  return (
    <TimelineItem>
      <TimelineOppositeContent classes={{ root: classes.oppositeContent }} color="textSecondary">
        <Skeleton condition={loading} width={60} height={24}>
          {!loading && <span className={classes.date}>{formatDate(date)}</span>}
        </Skeleton>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <Skeleton condition={loading} variant="circular" width={26} height={26} className={classes.dotSkeleton}>
          <TimelineDot
            classes={{
              root: cx({
                [classes.defaultDot]: color === 'secondary',
                [classes.successDot]: color === 'success',
                [classes.errorDot]: color === 'error',
                [classes.warningDot]: color === 'warning'
              })
            }}
          >
            <Icon iconClassName={classes.icon} className={classes.iconWrapper} />
          </TimelineDot>
        </Skeleton>
        {!hideConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent classes={{ root: classes.content }}>
        <Skeleton condition={loading} width={150} height={24}>
          {!loading && label}
        </Skeleton>
      </TimelineContent>
    </TimelineItem>
  )
}

export default ActivityItem
