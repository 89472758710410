import { makeStyles } from 'tss-react/mui'

import Filters from '_core/components/filters'

import useSearchQuery from '_core/hooks/useSearchQuery'

type InteractionsType = Extract<IncludeInteractions, 'inbound' | 'outbound'>

export const controls: { name: InteractionsType; label: string }[] = [
  { name: 'inbound', label: 'Inbound' },
  { name: 'outbound', label: 'Outbound' }
]

type InteractionsFilterTypes = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  anchorEl?: HTMLElement | null
  handleChange: (params: Modify<InteractionsPageParams, { checked?: InteractionsType[] }>) => void
}

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid #f2f2f2`
    }
  }
}))

const InteractionsFilter = ({ opened, contentLoading, disabled, anchorEl, handleChange }: InteractionsFilterTypes) => {
  const { classes } = useStyles()
  const { queryParams } = useSearchQuery<InteractionsPageParams, { modifyProps: [{ checked: InteractionsType[] }] }>(['checked'])

  const { checked = [] } = queryParams

  const toggle = (e: any) => {
    const { name } = e.target
    handleChange({ checked: checked.includes(name) ? checked.filter((v) => v !== name) : [...checked, name] })
  }

  return (
    <Filters opened={opened} contentLoading={contentLoading} anchorEl={anchorEl} className={classes.container}>
      <>
        {controls.map((item) => (
          <Filters.Checkbox
            key={item.name}
            name={item.name}
            label={item.label}
            onChange={toggle}
            checked={!!checked.includes(item.name)}
            disabled={disabled}
          />
        ))}
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default InteractionsFilter
