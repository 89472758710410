import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  }
}))

const TagsGroup = <T,>({
  items,
  title,
  showIcon = false,
  max = 1,
  tagComponent: TagComponent,
  renderShowAll,
  addTagTriggerEl,
  className
}: {
  items: T[] | undefined
  max?: number
  tagComponent: React.FC<{ tagData: T }>
  renderShowAll?: (props: { extraTagsAmount: number }) => ReactElement
  addTagTriggerEl?: ReactElement
  title?: ReactElement | null
  showIcon?: boolean
  className?: string
}) => {
  const total = items?.length
  const extraTags = total ? total - max : 0
  const { classes } = useStyles()

  return (
    <Box>
      {!!items?.length && title}
      <Box display="flex" alignItems="center" maxWidth="100%" mt={title ? 0.5 : 0} className={className}>
        {showIcon && !!items?.length && <FontAwesomeIcon size="sm" icon={['fas', 'tag']} className={classes.byLineIcon} />}
        {!!items?.length && (
          <>
            <Box display="flex">
              {items.slice(0, max)?.map((tag, i: number) => (
                <Box mr={1} key={i}>
                  <TagComponent tagData={tag} />
                </Box>
              ))}
            </Box>
            {extraTags > 0 && renderShowAll && <Box mr={1}>{renderShowAll({ extraTagsAmount: extraTags })}</Box>}
          </>
        )}
        {addTagTriggerEl}
      </Box>
    </Box>
  )
}

export default TagsGroup
