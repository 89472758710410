import { ComponentProps, Ref, forwardRef } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment, TextField as MUITextField, Box, TextFieldProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import FormLabel from './FormLabel'

const useStyles = makeStyles()((theme) => ({
  smallInput: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
  },
  icon: {
    fontSize: 14
  },
  helperText: {
    lineHeight: theme.spacing(2),
    letterSpacing: '0.25px'
  },
  hostPrefixLight: {
    '& > .MuiTypography-root': {
      color: theme.palette.text.disabled
    }
  },
  hostPrefix: {
    display: 'block',
    marginRight: -11,
    height: 'calc(100% - 2px)'
  }
}))

export interface IInputBox {
  icon?: IconProp
  iconEnd?: IconProp
  errorMessage?: string
  label?: string | React.ReactNode
}

const TextField = forwardRef(
  (
    {
      label,
      icon,
      iconEnd,
      errorMessage,
      error,
      helperText,
      InputProps,
      inputProps,
      size,
      className,
      ...props
    }: IInputBox & Omit<TextFieldProps, 'variant' | 'label' | 'title'>,
    ref?: Ref<HTMLDivElement>
  ) => {
    const { classes, cx } = useStyles()

    return (
      <Box flex={1}>
        {label && <FormLabel label={label} />}
        <MUITextField
          ref={ref}
          variant="outlined"
          className={className}
          error={!!errorMessage || error}
          inputProps={{
            ...inputProps,
            className: cx({ [classes.smallInput]: size === 'small' }, inputProps?.className)
          }}
          InputProps={{
            ...InputProps,
            startAdornment: icon ? (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={icon} className={classes.icon} />
              </InputAdornment>
            ) : (
              InputProps?.startAdornment || null
            ),
            endAdornment: iconEnd ? (
              <InputAdornment position="end">
                <FontAwesomeIcon icon={iconEnd} className={classes.icon} />
              </InputAdornment>
            ) : (
              InputProps?.endAdornment || null
            )
          }}
          {...props}
        />
        {(helperText || errorMessage) && (
          <Box mt={0.5} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(0, auto))">
            {helperText && (
              <Tooltip title={helperText}>
                <Typography classes={{ root: classes.helperText }} color="text.secondary">
                  {helperText || ''}
                </Typography>
              </Tooltip>
            )}
            {errorMessage && (
              <Tooltip title={errorMessage}>
                <Typography align="right" classes={{ root: classes.helperText }} color="error.main">
                  {errorMessage}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    )
  }
)

export const URLTextField = forwardRef((props: ComponentProps<typeof TextField>, ref: Ref<HTMLDivElement>) => {
  const { classes, cx } = useStyles()
  return (
    <TextField
      {...props}
      ref={ref}
      InputProps={{
        startAdornment: (
          <InputAdornment classes={{ root: cx(classes.hostPrefix, { [classes.hostPrefixLight]: props.disabled }) }} position="start">
            https://
          </InputAdornment>
        )
      }}
    />
  )
})

export default TextField
