import React, { ReactNode, ReactElement } from 'react'

import { Box, Card } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  wrapper: {
    position: 'relative'
  },
  card: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box'
  }
}))

type OverlayProps = {
  children: ReactNode
  isOpen: boolean
  overlayContent: ReactElement
  className?: string
}

const Overlay = ({ children, isOpen, overlayContent, className }: OverlayProps) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.wrapper, className)}>
      {isOpen && <Card className={classes.card}>{overlayContent}</Card>}
      {children}
    </Box>
  )
}

export default Overlay
