import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'

import Tooltip from '_shared/Tooltip'

const SendEmailToSelected = ({ disabled, recipients }: { disabled: boolean; recipients: string[] }) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    window.location.href = `mailto:${recipients.join(';')}`
  }

  return (
    <Tooltip title="Send an email to the selected people" disabled={disabled}>
      <IconButton onClick={handleClick} disabled={disabled} color="primary">
        <FontAwesomeIcon icon={['far', 'envelope']} style={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  )
}

export default SendEmailToSelected
