import { SyntheticEvent, useState } from 'react'

import { RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogContent, DialogTitle, DialogActions, DefaultSuccessAction } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'

import ManualEditSuccessMessage from '_core/components/ManualEditSuccessMessage'
import SidepanelLink from '_core/components/SidepanelLink'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

import Paths from 'Paths'

type AffiliationOptions = 'CurrentToggler' | 'NeverWorkedHere' | 'Edit' | 'StillWorkHere'

const useStyles = makeStyles()((theme) => ({
  informIcon: {
    marginLeft: theme.spacing(1)
  }
}))

const TriggerEl = ({ open, className }: { open: (e: SyntheticEvent) => void; className?: string }) => {
  const { classes, cx } = useStyles()
  return (
    <IconButton
      onClick={open}
      disablePR
      classes={{ root: cx(classes.informIcon, className) }}
      hint="Inform us about this affiliation"
      icon={['far', 'times']}
      size="small"
    />
  )
}

const AffiliationInformDialog = (props: {
  personName?: string
  personId?: string
  companyName?: string
  isFormerJob?: boolean
  isInvalidJob?: boolean
  currentAsOf?: string
  success: boolean
  opened: boolean
  openSuccess: () => void
  close: () => void
}) => {
  const { register, handleSubmit, watch } = useForm<{ affiliationOption: AffiliationOptions }>({ mode: 'onChange', shouldUnregister: true })
  const {
    companyName = '',
    personName,
    personId = '',
    isFormerJob = false,
    isInvalidJob = false,
    currentAsOf = '',
    close,
    success,
    opened,
    openSuccess
  } = props
  const [isSaving, setSaving] = useState<boolean>(false)

  const submit = async ({ affiliationOption }: { affiliationOption: AffiliationOptions }) => {
    const endpoint = `/personjobs/${personId}`
    setSaving(true)

    const params: { IsCurrent: string; CompanyName: string; DaysAgoBelievedToStillWorkHere?: string; NeverWorkedHere: string } = {
      IsCurrent: `${affiliationOption === 'StillWorkHere' || (affiliationOption === 'CurrentToggler' && isFormerJob)}`,
      CompanyName: companyName,
      NeverWorkedHere: `${affiliationOption === 'NeverWorkedHere'}`
    }

    if (affiliationOption !== 'NeverWorkedHere' && !isFormerJob) {
      params.DaysAgoBelievedToStillWorkHere = currentAsOf ? `${getLocal().diff(getLocal(currentAsOf), 'days')}` : '0'
    }

    await post(mergeUrlWithParams(endpoint, params))
    openSuccess()
    setSaving(false)
  }

  const affiliationOption = watch('affiliationOption')
  return (
    <Dialog loading={isSaving} open={opened} success={success} onClose={props.close} title={<DialogTitle title={companyName} />}>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent successContent={<ManualEditSuccessMessage />}>
          <RadioGroup name="affiliationOption">
            <Radio
              {...register('affiliationOption')}
              value="CurrentToggler"
              label={
                <>
                  This affiliation is {isFormerJob ? '' : 'not'} current for <b>{personName}</b> {isFormerJob ? '' : 'anymore'}
                </>
              }
            />
            {!isInvalidJob && (
              <Radio
                {...register('affiliationOption')}
                value="NeverWorkedHere"
                label={
                  <>
                    <b>{personName}</b> never worked at <b>{companyName}</b>
                  </>
                }
              />
            )}
            {!!isInvalidJob && (
              <Radio
                {...register('affiliationOption')}
                value="StillWorkHere"
                label={
                  <>
                    This affiliation is still current for <b>{personName}</b>
                  </>
                }
              />
            )}
            <Radio {...register('affiliationOption')} value="Edit" label={<>I would like to edit the details of this affiliation</>} />
          </RadioGroup>
        </DialogContent>
        <DialogActions successActions={<DefaultSuccessAction close={close} />}>
          <Button variant="text" color="secondary" onClick={close}>
            Cancel
          </Button>
          {affiliationOption === 'Edit' && (
            <SidepanelLink linkProps={{ to: `${Paths._people}/${personId}/edit` }}>
              <Button variant="text" color="primary" onClick={close} disablePR>
                Next
              </Button>
            </SidepanelLink>
          )}
          {affiliationOption !== 'Edit' && (
            <Button variant="text" color="primary" type="submit" disablePR disabled={!affiliationOption}>
              Next
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

AffiliationInformDialog.TriggerEl = TriggerEl

export default AffiliationInformDialog
