import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import { Button, IconButton } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import FloatingMenuButton, { IMenuAction } from '_shared/buttons/FloatingMenuButton'

import { Middle, Narrow, Wide, WideStrict } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

export type GridHeadingButtonType = IMenuAction & { condition?: boolean }

const GridHeadingButtons = ({ actions }: { actions: GridHeadingButtonType[] }) => {
  if (!actions.length) return null

  const singleAction = actions.length === 1 ? actions[0] : null

  return (
    <>
      <Wide>
        <WideStrict>
          <Box display="flex" gap={2}>
            {actions.map(({ label, link, icon }) => (
              <SidepanelLink linkProps={{ to: link }} sidepanel={true} key={link}>
                <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} />}>
                  {label}
                </Button>
              </SidepanelLink>
            ))}
          </Box>
        </WideStrict>
        <Middle>
          {singleAction ? (
            <SidepanelLink linkProps={{ to: singleAction.link }} sidepanel={true}>
              <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={singleAction.icon} style={{ fontSize: 14 }} />}>
                {singleAction.label}
              </Button>
            </SidepanelLink>
          ) : (
            <Box display="flex">
              {actions.map(({ label, link, icon }) => (
                <SidepanelLink linkProps={{ to: link }} sidepanel={true} key={link}>
                  <IconButton color="primary" size="small" hint={label} icon={icon} />
                </SidepanelLink>
              ))}
            </Box>
          )}
        </Middle>
      </Wide>
      <Narrow>
        {singleAction ? (
          <SidepanelLink linkProps={{ to: singleAction.link }}>
            <FloatingButton>
              <FontAwesomeIcon icon={singleAction.icon} size="lg" />
            </FloatingButton>
          </SidepanelLink>
        ) : (
          <FloatingMenuButton actions={actions} />
        )}
      </Narrow>
    </>
  )
}

export default GridHeadingButtons
