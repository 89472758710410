import { useState, SetStateAction, Dispatch, useCallback } from 'react'

function useCurrentSlideIndex(): {
  slideIndex: number | undefined
  updateSlideIndex: Dispatch<SetStateAction<number | undefined>>
}

function useCurrentSlideIndex(initial: number): {
  slideIndex: number
  updateSlideIndex: Dispatch<SetStateAction<number>>
}

function useCurrentSlideIndex(initial?: number) {
  const [slideIndex, setSlideIndex] = useState(initial)

  const updateSlideIndex = useCallback(
    (newValue: number) => {
      setSlideIndex(newValue)
    },
    [setSlideIndex]
  )

  if (typeof initial === 'number') {
    return {
      slideIndex: slideIndex || initial,
      updateSlideIndex
    }
  }

  return {
    slideIndex,
    updateSlideIndex: setSlideIndex
  }
}

export default useCurrentSlideIndex
