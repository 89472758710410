import { createContext, ReactNode, useEffect, useState } from 'react'

type ContextType = {
  successMode: boolean
  setSuccessMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const DialogContext = createContext({} as ContextType)

const DialogProvider = (props: { children: ReactNode; successMode?: boolean }) => {
  const [successMode, setSuccessMode] = useState<boolean>(false)

  useEffect(() => {
    if (typeof props.successMode === 'boolean') {
      setSuccessMode(props.successMode)
    }
  }, [props.successMode])

  const ctx = {
    successMode,
    setSuccessMode
  }

  return <DialogContext.Provider value={ctx}>{props.children}</DialogContext.Provider>
}

export default DialogProvider
