import { useState, useEffect } from 'react'

import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import IconButton, { IconButtonProps } from '_shared/buttons/Icon'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'

import { EntitiesList, EntitiesListProps } from '_core/components/audit/AuditEntities'

const useStyles = makeStyles()((theme) => ({
  exchange: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))

const TriggerEl = ({
  open,
  disabled,
  hint,
  classes: outerClasses
}: {
  open: () => void
  disabled: boolean
  hint: string
  classes: IconButtonProps['classes']
}) => {
  const { classes, cx } = useStyles()

  return (
    <IconButton
      classes={{ root: cx(outerClasses?.root, classes.exchange) }}
      disabled={disabled}
      onClick={open}
      icon={['far', 'exchange']}
      hint={hint}
    />
  )
}

const AuditMoveDialog = (props: { open: boolean; close: () => void; entities: EntitiesListProps['items']; move: (newEntity: string) => void }) => {
  const [moveTo, setMoveTo] = useState<string>()

  useEffect(() => {
    if (props.open) {
      setMoveTo(undefined)
    }
  }, [props.open])

  const move = () => {
    if (moveTo) {
      props.move(moveTo)
      props.close()
    }
  }

  const setActive = (id: string) => {
    setMoveTo(id)
  }

  return (
    <Dialog open={props.open} onClose={props.close} title={<DialogTitle title="Move to" />}>
      <DialogContent>
        <EntitiesList items={props.entities.map((entity) => ({ ...entity, isActive: moveTo === entity.id }))} setActive={setActive} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={move} disablePR disabled={!moveTo}>
          Move
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AuditMoveDialog.TriggerEl = TriggerEl

export default AuditMoveDialog
