import React, { useContext, useEffect, useRef, useState } from 'react'

import { Drawer } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

import { useWide } from '_core/components/layout'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  sidepanel: {
    marginTop: '60px',
    marginRight: 16,
    height: `calc(100vh - 60px - 16px)`,
    background: 'transparent',
    border: 0,
    boxShadow: '0 0 24px rgba(0,0,0,0.2)',
    borderRadius: 8,
    width: width
  },
  iframe: {
    height: '100%',
    width: '100%',
    border: 0,
    background: theme.palette.background.light
  }
}))

const Sidepanel = (props: { width: number; updateWidth: (pathname: string) => void }) => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const el = useRef<any>()
  const wide = useWide()
  const { setSidepanelHistory } = useContext(LayoutContext)
  const url = window.localStorage.getItem('sidepanel')
  const [initialUrl] = useState(url)
  const { classes } = useStyles({ width: props.width })

  const storageEventHandler = () => {
    const pathname = window.localStorage.getItem('sidepanel')
    setOpen(Boolean(pathname))
  }

  useEffect(() => {
    window.addEventListener('storage', storageEventHandler, false)
    return () => {
      window.removeEventListener('storage', storageEventHandler, false)
    }
  }, [])

  const handleLoading = () => {
    if (url && el.current && el.current.contentWindow) {
      el.current.contentWindow.postMessage(`sidepanel:${url}`, el.current.src)
      props.updateWidth(url)
      const idx = url.indexOf('?')
      const [pathname, search = ''] = idx > -1 ? [url.slice(0, idx), url.slice(idx)] : [url]

      setSidepanelHistory([{ pathname, search }])
    }
  }

  return (
    <Drawer
      open={wide && open}
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.sidepanel
      }}
      SlideProps={{
        onEntering: (node: any) => {
          node.style.transition = theme.transitions.create(['transform', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: 225
          })
        }
      }}
    >
      <iframe
        src={initialUrl ? initialUrl : '/blank'}
        title="sidepanel"
        name="sidepanel"
        onLoad={handleLoading}
        className={classes.iframe}
        ref={el}
      ></iframe>
    </Drawer>
  )
}

export default Sidepanel
