import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'

import { latestTouchpointKey, firstTouchpointKey, nextTouchpointKey } from '_core/helpers/touchpoints'

import Paths from 'Paths'

const TouchpointSummary = (stats: any) => {
  const lastkey = stats ? latestTouchpointKey(stats) : ''
  const firstkey = stats ? firstTouchpointKey(stats) : ''
  const nextkey = stats ? nextTouchpointKey(stats) : ''

  return (
    stats &&
    [
      nextkey && {
        title: 'Upcoming',
        description: (
          <>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
            <NameLink url={`${Paths._people}/${stats[`${nextkey}${'UserKeyMd5'}`]}`} name={stats[`${nextkey}${'UserName'}`]} preposition="with" />
          </>
        ),
        blurb: moment(stats[nextkey]).format('DD MMMM YYYY')
      },
      lastkey && {
        title: 'Latest',
        description: (
          <>
            <FontAwesomeIcon
              icon={lastkey === 'LastMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
              size="sm"
              color="#1B95BB"
              style={{ marginRight: '8px' }}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${lastkey}UserKeyMd5`]}`}
              name={stats[`${lastkey}UserName`]}
              preposition={lastkey === 'LastMeeting' ? 'met' : lastkey === 'LastInboundMsg' ? 'to' : 'from'}
            />
          </>
        ),
        blurb: moment(stats[lastkey]).format('DD MMMM YYYY')
      },
      firstkey && {
        title: 'First',
        description: (
          <>
            <FontAwesomeIcon
              icon={firstkey === 'FirstMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
              size="sm"
              color="#1B95BB"
              style={{ marginRight: '8px' }}
            />
            <NameLink
              url={`${Paths._people}/${stats[`${firstkey}UserKeyMd5`]}`}
              name={stats[`${firstkey}UserName`]}
              preposition={firstkey === 'FirstMeeting' ? 'met' : firstkey === 'FirstInboundMsg' ? 'to' : 'from'}
            />
          </>
        ),
        blurb: moment(stats[firstkey]).format('DD MMMM YYYY')
      }
    ].filter((key) => key)
  )
}

export default TouchpointSummary
