import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { useWide } from '_core/components/layout'

import usePresetScroll from '_core/hooks/usePresetScroll'
import useSidepanel from '_core/hooks/useSidepanel'

export type SidepanelLinkProps = {
  onClick?: () => void
  window?: Window
  preventClickOnSame?: boolean
  strict?: boolean
}

const useStyles = makeStyles()(() => ({
  link: {
    cursor: 'pointer',
    maxWidth: '100%'
  }
}))

const useSidepanelLink = (props: SidepanelLinkProps | void) => {
  const wide = useWide()
  const location = useLocation()
  const history = useHistory()
  const { saveScroll } = usePresetScroll()
  const { openedUrl } = useSidepanel()
  const { classes } = useStyles()
  const { preventClickOnSame = true, strict = false } = props || {}

  const handleClick = (e: any, sidepanel: SidepanelType, { index, to }: any) => {
    e.stopPropagation()
    const shouldPreventClick = preventClickOnSame

    if (shouldPreventClick) {
      const isSamePathStrict = strict && to === openedUrl
      const isSamePathNonStrict = !strict && to.split('?')[0] === openedUrl.split('?')[0]

      if (isSamePathStrict || isSamePathNonStrict) {
        return
      }
    }

    const name = typeof sidepanel === 'string' ? sidepanel : 'sidepanel'
    if (!wide) {
      saveScroll(index)
      if (isSidepanel('sidepanel')) {
        window.parent.postMessage(`sidepanel_link_click:${to}`, '*')
      } else {
        const link = wide ? `${location.pathname}?sidepanel=${to}` : to
        history.push(link)
      }
    }

    const iframe = (props?.window || window).document.querySelector<HTMLIFrameElement>(`iframe[name=${name}]`)
    if (e.shiftKey) {
      history.push(to)
      if (props?.onClick) props.onClick()
      e.preventDefault()
      return
    }

    if (wide && sidepanel && iframe) {
      if (typeof sidepanel === 'boolean') {
        window.postMessage(`sidepanel_open:${to}`, '*')
      }
      iframe.contentWindow?.postMessage(`${name}:${to}`, iframe.src)
      if (props?.onClick) props.onClick()
      e.preventDefault()
    }
  }

  return { handleClick, className: classes.link }
}

export default useSidepanelLink
