import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, LinearProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  error: {
    '& .MuiLinearProgress-root, .MuiLinearProgress-barColorPrimary': {
      background: theme.palette.error.main
    },
    '& .filename': {
      color: theme.palette.error.main
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start'
  },
  errorIcon: {
    fontSize: 21,
    color: theme.palette.error.main
  },
  regularIcon: {
    fontSize: 21,
    color: theme.palette.text.secondary
  }
}))

export enum FILE_UPLOADING_STATUS {
  FILE_IS_READY_FOR_UPLOAD,
  FILE_IS_UPLOADING,
  FILE_HAD_UPLOADED,
  FILE_HAS_ERROR
}

export interface UploadingProgressBarProps {
  fileName: string
  status?: FILE_UPLOADING_STATUS
  errorMessage?: string
}

export const UploadingProgressBar = (props: UploadingProgressBarProps) => {
  const { classes, cx } = useStyles()

  const isError = props.status === FILE_UPLOADING_STATUS.FILE_HAS_ERROR

  return (
    <Box className={cx({ [classes.root]: true, [classes.error]: isError })}>
      <Box mr={1} mt={props.fileName && props.status !== FILE_UPLOADING_STATUS.FILE_IS_READY_FOR_UPLOAD ? 0.5 : 0}>
        <FontAwesomeIcon icon={['fad', 'file']} className={isError ? classes.errorIcon : classes.regularIcon} />
      </Box>
      <Box width="100%">
        <Typography className="filename">{props.fileName}</Typography>

        {props.status === FILE_UPLOADING_STATUS.FILE_IS_UPLOADING && <LinearProgress variant="indeterminate" style={{ width: '100%' }} />}

        {props.status === FILE_UPLOADING_STATUS.FILE_HAS_ERROR && (
          <LinearProgress className="filename" variant="determinate" value={100} style={{ width: '100%' }} />
        )}

        {props.errorMessage && <Typography color="error.main">{props.errorMessage}</Typography>}
      </Box>
    </Box>
  )
}
