import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Button, { ButtonProps, DisablePaddingType } from '_shared/buttons/Button'
import IconButton, { IconButtonProps } from '_shared/buttons/Icon'
import Dialog, { DialogTitle, DialogActions, DialogContent, DefaultSuccessAction } from '_shared/Dialog'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { useSidepanelWide } from '_core/components/layout'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'
import { ellipsisText } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  publicIcon: {
    cursor: 'default',
    marginLeft: theme.spacing(1.5)
  }
}))

const ellipsisUserName = (name: string) => ellipsisText(name, 12)

const UpdateRelationshipPrivacyContent = ({ successText, text }: { successText: string; text: JSX.Element }) => {
  return (
    <DialogContent
      successContent={
        <>
          <Typography mb={2}>Thank you!</Typography>
          <Typography mb={2}>{successText}</Typography>
          <Typography>
            You can view your private relationships by going to{' '}
            <Link to={`${Paths._manualEdits}/privaterelationships`} target="_blank" style={{ color: '#2E9CBD', whiteSpace: 'nowrap' }}>
              Manual Edits
            </Link>
            .
          </Typography>
        </>
      }
    >
      {text}
    </DialogContent>
  )
}

export const IsPrivateRelationshipBudge = ({ userName }: { userName: string }) => {
  const { classes } = useStyles()

  return (
    <Tooltip title={`Your relationship with ${userName} is private`}>
      <Box className={classes.publicIcon}>
        <FontAwesomeIcon icon={['fas', 'lock']} style={{ fontSize: 16, color: '#E69847' }} />
      </Box>
    </Tooltip>
  )
}

const MarkPublicButton = ({ disabled, onClick }: Pick<ButtonProps, 'disabled' | 'onClick'>) => {
  return (
    <Button disabled={disabled} onClick={onClick} startIcon={<FontAwesomeIcon icon={['far', 'lock-open']} style={{ fontSize: 14 }} />}>
      Share relationship
    </Button>
  )
}

const MarkPrivateButton = ({ disabled, onClick }: Pick<ButtonProps, 'disabled' | 'onClick'>) => {
  return (
    <Button disabled={disabled} onClick={onClick} startIcon={<FontAwesomeIcon icon={['far', 'lock']} style={{ fontSize: 14 }} />}>
      Mark as private
    </Button>
  )
}

const MarkPublicIconButton = ({
  disabled,
  style,
  size,
  hint,
  onClick,
  ...disablePaddings
}: Pick<IconButtonProps, 'hint' | 'onClick' | 'style' | 'size' | 'disabled' | DisablePaddingType>) => {
  return (
    <IconButton
      hint={hint || 'Share relationship'}
      onClick={onClick}
      disabled={disabled}
      icon={['far', 'lock-open']}
      size={size}
      style={style}
      color="primary"
      {...disablePaddings}
    />
  )
}

const MarkPrivateIconButton = ({
  disabled,
  style,
  size,
  hint,
  onClick,
  ...disablePaddings
}: Pick<IconButtonProps, 'hint' | 'onClick' | 'style' | 'size' | 'disabled' | DisablePaddingType>) => {
  return (
    <IconButton
      hint={hint || 'Mark relationship as private'}
      onClick={onClick}
      disabled={disabled}
      icon={['far', 'lock']}
      size={size}
      style={style}
      color="primary"
      {...disablePaddings}
    />
  )
}

interface IMarkPublicDialogProps {
  opened: boolean
  close: () => void
  openSuccess: () => void
  success: boolean
  userName?: string
  userKeys: string[]
  update?: () => void
  handleRefresh?: (val: boolean) => void
  setPrivateRelationship?: (val: boolean) => void
}

const MarkPublicDialog = ({
  success,
  openSuccess,
  opened,
  close,
  userName,
  userKeys,
  update,
  handleRefresh,
  setPrivateRelationship
}: IMarkPublicDialogProps) => {
  const [loading, setLoading] = useState(false)
  const { updateParent } = useSidepanelPayloads()
  const wide = useSidepanelWide()
  const { enqueueSnackbar } = useSnackbar()

  const title =
    userKeys.length > 1
      ? 'Mark selected relationships as shared?'
      : `Share your relationship ${userName ? `with ${!wide && userName.includes('@') ? ellipsisUserName(userName) : userName}` : ''}?`

  const updateData = () => {
    if (setPrivateRelationship) {
      updateParent({
        action: 'UPDATE_RELATIONSHIP_PRIVACY',
        value: {
          isPrivateRelations: false,
          userKey: userKeys[0]
        }
      })
      setPrivateRelationship(false)
    }
    if (update) update()
  }

  const makePublic = () => {
    setLoading(true)
    if (userKeys.length) {
      post('/privacy/notprivate', userKeys)
        .then(() => {
          openSuccess()
          updateData()
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      close()
      enqueueSnackbar(`The sharing relationship status with ${userName || 'selected people'} has been not updated`, { variant: 'error' })
    }
  }

  const makePublicText = (
    <>
      <Typography mb={2}>
        Shared relationships are visible to your colleagues. Data that you choose to share about your relationships is also visible to your
        colleagues.
      </Typography>
      <Typography>
        You can view your private relationships by going to{' '}
        <Link to={`${Paths._manualEdits}/privaterelationships`} target="_blank" style={{ color: '#2E9CBD', whiteSpace: 'nowrap' }}>
          Manual Edits
        </Link>
        .
      </Typography>
    </>
  )

  const successText = `Your ${
    userKeys.length > 1 ? 'relationships with selected people were marked' : `relationship ${userName ? `with ${userName}` : ''} was marked`
  } as shared.`

  const handleClose = () => {
    handleRefresh && success && handleRefresh(true)
    close()
  }

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      loading={loading}
      success={success}
      title={<DialogTitle title={title} titleIcon={['fas', 'lock-open']} />}
    >
      <UpdateRelationshipPrivacyContent successText={successText} text={makePublicText} />
      <DialogActions successActions={<DefaultSuccessAction close={handleClose} />}>
        <Button variant="text" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={makePublic} disablePR>
          {wide ? (userKeys.length > 1 ? 'Share relationships' : 'Share relationship') : 'Share'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IMarkPrivateDialogProps {
  opened: boolean
  close: () => void
  success: boolean
  openSuccess: () => void
  userName?: string
  userKeys: string[]
  update?: () => void
  setPrivateRelationship?: (val: boolean) => void
}

const MarkPrivateDialog = ({ opened, success, openSuccess, close, userName, userKeys, update, setPrivateRelationship }: IMarkPrivateDialogProps) => {
  const [loading, setLoading] = useState(false)
  const { updateParent } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()
  const wide = useSidepanelWide()

  const title = `${
    userKeys.length > 1
      ? 'Mark selected relationships as private?'
      : `Mark your relationship ${userName ? `with ${!wide && userName.includes('@') ? ellipsisUserName(userName) : userName}` : ''} as private?`
  }`

  const updateData = () => {
    if (setPrivateRelationship) {
      updateParent({
        action: 'UPDATE_RELATIONSHIP_PRIVACY',
        value: {
          isPrivateRelations: true,
          userKey: userKeys[0]
        }
      })
      setPrivateRelationship(true)
    }
    if (update) update()
  }

  const makePrivate = () => {
    setLoading(true)
    if (userKeys.length) {
      post(`/privacy/private`, userKeys)
        .then(() => {
          openSuccess()
          updateData()
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      close()
      enqueueSnackbar(`The sharing relationship status with ${userName || 'selected people'} has been not updated`, { variant: 'error' })
    }
  }

  const makePrivateText = (
    <>
      <Typography mb={2}>
        Colleagues will not be able to see your relationship with a person you mark as private. Your data about that person will also not be shared
        with your colleagues.
      </Typography>
      <Typography>
        You can view your private relationships by going to{' '}
        <Link to={`${Paths._manualEdits}/privaterelationships`} target="_blank" style={{ color: '#2E9CBD', whiteSpace: 'nowrap' }}>
          Manual Edits
        </Link>
        .
      </Typography>
    </>
  )

  const successText = `Your ${
    userKeys.length > 1 ? 'relationships with selected people were marked' : `relationship ${userName ? `with ${userName}` : ''} was marked`
  } as private`

  return (
    <Dialog open={opened} onClose={close} loading={loading} success={success} title={<DialogTitle title={title} titleIcon={['fas', 'lock']} />}>
      <UpdateRelationshipPrivacyContent successText={successText} text={makePrivateText} />
      <DialogActions successActions={<DefaultSuccessAction close={close} />}>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={makePrivate} disablePR>
          Mark as private
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MarkPrivateDialog.TriggerIcon = MarkPrivateIconButton
MarkPrivateDialog.TriggerButton = MarkPrivateButton

MarkPublicDialog.TriggerIcon = MarkPublicIconButton
MarkPublicDialog.TriggerButton = MarkPublicButton

export { MarkPrivateDialog, MarkPublicDialog }
