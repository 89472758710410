import React, { ReactNode } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import { useWide } from '_core/components/layout'
import Widget from '_core/components/Widget'

import { SaveAction } from '_core/hooks/useSaveSettings'

export const useStyles = makeStyles()((theme) => ({
  section: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)} ${theme.spacing(3)}`
    },
    '& h5, & p': {
      fontSize: '14px'
    },
    '& h5': {
      marginBottom: 0
    }
  },
  subsection: {
    color: theme.palette.text.secondary,
    lineHeight: '23px',
    marginLeft: theme.spacing(4),
    fontSize: 14,
    '& > div': {
      display: 'inline-flex'
    }
  },
  field: {
    '&&': {
      margin: `0 ${theme.spacing(0.5)}`
    }
  },
  icon: {
    fontSize: '17px',
    marginRight: '-8px',
    '&[type="button"]': {
      cursor: 'auto'
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 0
    }
  },
  itemWrapper: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  filtersContent: {
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0
    },
    '& input': {
      textAlign: 'center'
    }
  },
  subItemWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  subItem: {
    display: 'grid',
    marginTop: `-${theme.spacing(1)}`,
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'start',
    '& .MuiOutlinedInput-root': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      '& .MuiOutlinedInput-root': {
        marginTop: 0,
        marginBottom: 0
      }
    },
    '& > p': {
      marginTop: theme.spacing(0.75),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        marginRight: theme.spacing(1.5)
      }
    }
  },
  error: {
    color: theme.palette.error.main,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0.5)
    }
  },
  popover: {
    width: '80%'
  },
  tiny: {
    width: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 4px'
    }
  }
}))

type SettingsProps = {
  initialLoading?: boolean
  isSaving: boolean
  children: ReactNode
  save: (value: SaveAction) => void
  saveDisabled?: boolean
}

const Settings = (props: SettingsProps) => {
  const {
    classes: { section }
  } = useStyles()
  const isWide = useWide()

  const { enqueueSnackbar } = useSnackbar()

  const handleSave = () => {
    props.save((subject, variant = 'default') => {
      enqueueSnackbar(`${subject} have ${variant === 'error' ? 'not' : ''} been updated.`, {
        variant
      })
    })
  }

  return (
    <>
      {props.initialLoading && (
        <Box paddingX={{ xs: 2, sidepanel: 0 }}>
          <CircularProgress size="24px" color="secondary" />
        </Box>
      )}
      {!props.initialLoading && (
        <>
          <Widget scope="outlined">
            <Box className={section}>{props.children}</Box>
          </Widget>
          <Widget scope="none" style={!isWide && { paddingBottom: 0 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mx={-2}>
              {props.isSaving && <CircularProgress size="24px" style={{ marginRight: 16 }} />}
              <Button disabled={props.isSaving || props.saveDisabled} onClick={handleSave} variant="contained">
                Save
              </Button>
            </Box>
          </Widget>
        </>
      )}
    </>
  )
}

export default Settings
