import React from 'react'
import { Grid, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import MobileNavBarTitle from './MobileNavBarTitle'

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.light,
    borderBottom: '1px solid  #f2f2f2',
    height: 60,
    width: '100%',
    display: 'block'
  },
  container: {
    padding: theme.spacing(0, 0.75),
    flexWrap: 'nowrap'
  },
  gutterSection: {
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  centerSection: {
    flexGrow: 1,
    height: 60,
    display: 'flex',
    alignItems: 'center'
  },
  emptyLeftComponent: {
    maxWidth: '100%',
    marginLeft: '16px'
  }
}))

interface MobileNavBarProps {
  rightComponent?: JSX.Element
  leftComponent?: JSX.Element
}

const MobileNavBar = (props: MobileNavBarProps) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <Box className={classes.root}>
        <Grid container classes={{ root: classes.container }}>
          {props.leftComponent !== undefined && (
            <Grid item className={classes.gutterSection}>
              <Box>{props.leftComponent}</Box>
            </Grid>
          )}

          <Grid
            item
            className={cx(classes.centerSection, {
              [classes.emptyLeftComponent]: props.leftComponent === undefined
            })}
          >
            <MobileNavBarTitle />
          </Grid>

          {props.rightComponent !== undefined && (
            <Grid item className={classes.gutterSection}>
              {props.rightComponent}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default MobileNavBar
