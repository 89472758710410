import React from 'react'

import { GridSortDirection, GridSortItem } from '@mui/x-data-grid-pro'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Sort, { SortProps } from './index'

export const sortMap: {
  [key in GridSortItem['field']]: { [key in Exclude<GridSortDirection, undefined | null>]: UploadsSortType }
} = {
  date: {
    asc: 'DateAsc',
    desc: 'DateDesc'
  },
  file_name: {
    asc: 'FileNameAsc',
    desc: 'FileNameDesc'
  },
  file_type: {
    asc: 'FileTypeAsc',
    desc: 'FileTypeDesc'
  }
}

type DataUploadsSortProps = {
  items: {
    label: string
    field: string
  }[]
} & Omit<SortProps, 'children'>

const DataUploadsSorting = (props: DataUploadsSortProps) => {
  const { queryParams, updateQuery } = useSearchQuery<DataUploadsPageParams>()
  const { sort } = queryParams

  const appliedSort = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const updateSort = (field: any) => {
    const newSort = sort === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc

    if (queryParams.sort) {
      updateQuery({ ...queryParams, sort: newSort })
    }
  }

  return (
    <Sort collapsed={props.collapsed} className={props.className}>
      {props.items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={sort === sortMap[item.field].desc}
          onClick={() => updateSort(item.field)}
        />
      ))}
    </Sort>
  )
}

export { Controller } from './index'
export default DataUploadsSorting
