import React, { useState } from 'react'

import { Box, RadioGroup } from '@mui/material'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'

export type BeneficiaryUpdateActionsType = 'clear' | 'keep' | 'transform'

export const useUpdateBeneficiary = (closeDialog: () => void, initAction?: BeneficiaryUpdateActionsType) => {
  const [beneficiaryUpdateAction, setBeneficiaryUpdateAction] = useState<BeneficiaryUpdateActionsType>(initAction || 'transform')

  const closeUpdateBeneficiaryDialog = () => {
    setBeneficiaryUpdateAction('transform')
    closeDialog()
  }

  return {
    beneficiaryUpdateAction,
    setBeneficiaryUpdateAction,
    closeUpdateBeneficiaryDialog
  }
}

const UpdateBeneficiaryDialog = ({
  opened,
  close,
  submit,
  value,
  setDialogValue,
  loading,
  beneficiary
}: {
  beneficiary: string
  opened: boolean
  loading: boolean
  close: () => void
  submit: (event: any) => void
  value: BeneficiaryUpdateActionsType
  setDialogValue: (v: BeneficiaryUpdateActionsType) => void
}) => {
  const toggleOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target
    setDialogValue(name as BeneficiaryUpdateActionsType)
  }

  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="Beneficiary" />} maxWidth={420}>
      <DialogContent>
        <Box>
          <RadioGroup onChange={toggleOption}>
            {beneficiary && <Radio disabled={loading} checked={value === 'clear'} name="clear" label="Clear it" />}
            <Radio disabled={loading} checked={value === 'keep'} name="keep" label={`Keep it ${beneficiary ? '' : 'empty'}`} />
            <Radio disabled={loading} checked={value === 'transform'} name="transform" label="Change to the requester's company" />
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary" disablePL>
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disabled={loading} disablePR>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateBeneficiaryDialog
