import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { request } from 'utils/fetchUtils'

type UserSettingsType = {
  endpoint: string
  setInitial: (result: any) => void
}

const UserSettings = ({ endpoint, setInitial, children }: PropsWithChildren<UserSettingsType>) => {
  const { search } = useLocation<any>()
  const initialized = useRef<boolean>(false)

  useEffect(() => {
    if (!initialized.current && endpoint) {
      let requestActive = true
      const abortController = new AbortController()
      request<any>(endpoint, { signal: abortController.signal }).then((resp) => {
        if (requestActive && resp) {
          initialized.current = true
          setInitial(resp)
        } else {
          return null
        }
      })

      return () => {
        requestActive = false
        abortController.abort()
      }
    }
  }, [endpoint, search, setInitial])

  return <>{children}</>
}

export default UserSettings
