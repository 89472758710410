import { useContext, useEffect } from 'react'

import Page from '_shared/Page'

import TeamsList from '_core/components/team/TeamsList'

import { apiBase } from '_core/hooks/useTeam'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const TeamsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Teams')
  }, [])

  return (
    <Page>
      <DynamicEntity url={apiBase} component={TeamsList} list={true} id="teams_list" keepMounted />
    </Page>
  )
}

export default TeamsPage
