import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import AuditColumns from '_core/components/audit/AuditLayout'
import { sourceTypesMap } from '_core/components/audit/AuditSources'
import InnerDialog from '_core/components/InnerDialog'
import Repeater from '_core/components/lists/Repeater'

import { ellipsis } from 'AppTheme'

const useStyles = makeStyles()(() => ({
  userKey: {
    maxWidth: 'calc(100% - 81px)',
    ...ellipsis
  }
}))

const TupleSources = (props: Pick<AuditAnomalousTuple, 'lowText' | 'highText' | 'sources'> & { close: () => void }) => {
  const { lowText, highText, sources, close } = props
  const { classes } = useStyles()

  return (
    <InnerDialog close={close}>
      <AuditColumns.Column
        heading={
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FontAwesomeIcon icon={['far', 'object-ungroup']} size="2x" color="rgba(0,0,0,0.38)" />
            </Grid>
            <Grid item xs={10}>
              <b style={{ wordBreak: 'break-all' }}>{lowText}</b> is directly associated with <b style={{ wordBreak: 'break-all' }}>{highText}</b>{' '}
              based on evidence in the following sources.
            </Grid>
          </Grid>
        }
      >
        <Repeater
          direction="vertical"
          variant="border"
          component={(item: AuditAnomalousTupleSource) => {
            const { icon, text } = item.sourceType ? sourceTypesMap[item.sourceType] : { icon: ['far', 'user'] as IconProp, text: 'data' }
            return (
              <div style={{ paddingLeft: '20px', paddingRight: '20px', fontSize: 14, display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={icon} size="sm" style={{ color: 'rgba(0,0,0,0.4)', marginRight: 12 }} />
                <Tooltip title={item.userKey}>
                  <Typography classes={{ root: classes.userKey }}>{item.userKey}&apos;s</Typography>
                </Tooltip>
                &nbsp;
                <Typography>{text.toLowerCase()}</Typography>
              </div>
            )
          }}
          skeleton={{ size: 10, loading: false }}
          items={sources}
        />
      </AuditColumns.Column>
    </InnerDialog>
  )
}

export default TupleSources
