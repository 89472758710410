import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { TableHeader } from '_pages/data-sources/components/TableHeader'

import { Button } from '_shared/buttons'

import { Wide } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '24px'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
    fontSize: '16px'
  }
}))

export const FileSourceTitle = ({ initOpen, loading }: { initOpen: boolean; loading: boolean }) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const { classes } = useStyles()

  useEffect(() => {
    !loading && initOpen && linkRef?.current && linkRef.current.click()
  }, [initOpen, linkRef?.current, loading])

  return (
    <Box className={classes.root}>
      <TableHeader title="LinkedIn connections" />
      <Wide>
        <Box className={classes.buttonContainer}>
          <SidepanelLink linkProps={{ to: `${Paths.dataSources}/upload` }} sidepanel={true} linkRef={linkRef}>
            <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={['far', 'cloud-arrow-up']} style={{ fontSize: 14 }} />}>
              UPLOAD
            </Button>
          </SidepanelLink>
        </Box>
      </Wide>
    </Box>
  )
}
