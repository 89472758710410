import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react'

import { Box, ButtonGroup } from '@mui/material'
import { useForm } from 'react-hook-form'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import FormLabel from '_shared/forms/FormLabel'
import TextField from '_shared/forms/TextField'

import CreateCompanyOptionDialog, {
  CompanyOptionType,
  renderOption as renderSuggestCompanyOption,
  SuggestCompanyOptionType,
  useCreateCompanyOption
} from '_core/components/dialogs/CreateCompanyOptionDialog'
import CreatePersonOptionDialog, {
  PersonOptionType,
  renderOption as renderSuggestPersonOption,
  SuggestPersonOptionType,
  useCreatePersonOption
} from '_core/components/dialogs/CreatePersonOptionDialog'
import UpdateBeneficiaryDialog, { BeneficiaryUpdateActionsType, useUpdateBeneficiary } from '_core/components/dialogs/UpdateBeneficiaryDialog'
import { renderPersonOption, transformCompanyOption, transformPersonOption } from '_core/components/introductions/options'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useDialog from '_core/hooks/useDialog'
import { ReasonType } from '_core/hooks/useIntroductionReason'
import useIntroductionRequestForm, { BeneficiaryType, errorMsg, IntroductionRequest, useStyles } from '_core/hooks/useIntroductionRequestForm'
import { useLookUpActiveDirectoryMembers, useLookUpMarketDataCompanies, useLookUpMarketDataPeople } from '_core/hooks/useLookup'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

type RequestFormProps = {
  loading: boolean
  save: (formValues: IntroductionRequest) => void
  request: IntroductionRequest | undefined
  actionLabel?: string
  beneficiaryUpdateAction?: BeneficiaryUpdateActionsType
}
type BeneficiaryTypeOption = { value: BeneficiaryType; label: string }
type BeneficiaryCompanyValue = CompanyOptionType | SuggestCompanyOptionType
type BeneficiaryPersonValue = PersonOptionType | SuggestPersonOptionType
type PersonOption = Pick<PersonOptionType, 'name' | 'email'>

type BeneficiaryPickerProps = {
  loading: boolean
  remove: () => void
  setType: (val: BeneficiaryType) => void
  errorMessage?: string
}

export const defaultReason = { id: 0, label: '', value: '' }
const beneficiaryTypeOptions: BeneficiaryTypeOption[] = [
  { value: 'company', label: 'Company' },
  { value: 'person', label: 'Person' }
]
const defaultValues: IntroductionRequest = {
  summary: '',
  description: '',
  planUid: '',
  reason: defaultReason,
  requester: { name: '', email: '' },
  beneficiaryPerson: { name: '', email: '', md5: '' },
  beneficiaryCompany: { name: '', website: '', md5: '' }
}

const RequestForm = ({ request, loading, save, ...props }: RequestFormProps) => {
  const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>('company')

  const {
    dialogContentProps: openedDialog,
    openDialog,
    closeDialog
  } = useDialog<'createPersonOption' | 'createCompanyOption' | 'updateBeneficiary' | null>(null)

  const {
    closeCreateOptionDialog: closeCreatePersonOptionDialog,
    createdOption: createdPersonOption,
    setCreatedOption: setCreatedPersonOption
  } = useCreatePersonOption(closeDialog)
  const {
    closeCreateOptionDialog: closeCreateCompanyOptionDialog,
    createdOption: createdCompanyOption,
    setCreatedOption: setCreatedCompanyOption
  } = useCreateCompanyOption(closeDialog)
  const { closeUpdateBeneficiaryDialog, beneficiaryUpdateAction, setBeneficiaryUpdateAction } = useUpdateBeneficiary(
    closeDialog,
    props.beneficiaryUpdateAction
  )
  const { loading: fetching, fetchWithAbort } = useAbortableFetch<{
    data: {
      BestJobMatchedUrlText: string
      BestJobCompanyMd5: string
      BestJobCorpLevelCompanyName: string
      BestJobMatchedCompanyName: string
    }[]
  }>()
  const { teamContextValue } = useContext(TeamContext)
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues })
  const { handleClose, beneficiaryPersonField, beneficiaryCompanyField, requesterField, reasonField } = useIntroductionRequestForm(
    control,
    beneficiaryType
  )
  const { classes } = useStyles()

  useEffect(() => {
    if (request) {
      Object.entries(request).forEach(([fieldName, fieldValue]: any) => {
        setValue(fieldName, fieldValue)
      })
    }
  }, [setValue, request])

  useEffect(() => {
    setBeneficiaryType(beneficiaryPersonField.value.name ? 'person' : 'company')
  }, [beneficiaryPersonField.value.name, beneficiaryCompanyField.value.name])

  const beneficiary = beneficiaryType === 'company' ? beneficiaryCompanyField.value.name || '' : beneficiaryPersonField.value.name || ''

  useEffect(() => {
    if (Object.keys(errors).length && beneficiary) {
      const fields = Object.keys(errors)
      if (fields.includes('beneficiaryPerson') && beneficiaryType === 'company') {
        clearErrors('beneficiaryPerson')
      }
      if (fields.includes('beneficiaryCompany') && beneficiaryType === 'person') {
        clearErrors('beneficiaryCompany')
      }
    }
  }, [beneficiaryType, beneficiary, Object.keys(errors).length])

  const createPersonOptionSubmit = (createdOption: PersonOption) => {
    beneficiaryPersonField.onChange({
      name: createdOption.name,
      email: createdOption.email || '',
      md5: ''
    })
    closeCreatePersonOptionDialog()
  }

  const createCompanyOptionSubmit = (createdOption: CompanyOptionType) => {
    beneficiaryCompanyField.onChange({
      name: createdOption.name,
      website: createdOption.website || '',
      md5: ''
    })
    closeCreateCompanyOptionDialog()
  }

  const handleUpdateBeneficiarySubmit = async () => {
    if (beneficiaryUpdateAction === 'clear') {
      beneficiaryType === 'company' ? removeBeneficiaryCompany() : removeBeneficiaryPerson()
    }

    if (beneficiaryUpdateAction === 'transform' && requesterField.value.email) {
      const res = await fetchWithAbort({
        url: `/people/${requesterField.value.email}?teamNumber=${teamContextValue.teamNumber}`
      })
      if (res?.data?.[0]) {
        const requester = res.data[0]
        const requesterCompanyName = requester.BestJobMatchedCompanyName || requester.BestJobCorpLevelCompanyName || ''

        removeBeneficiaryPerson()
        beneficiaryCompanyField.onChange({
          name: requesterCompanyName,
          website: requesterCompanyName ? requester.BestJobMatchedUrlText || '' : '',
          md5: requesterCompanyName ? requester.BestJobCompanyMd5 || '' : ''
        })
      }
    }
    closeUpdateBeneficiaryDialog()
  }

  const onBeneficiaryPersonChange = (event: SyntheticEvent, value: BeneficiaryPersonValue) => {
    if ('label' in value) {
      openDialog('createPersonOption')
      setCreatedPersonOption({
        name: value.name,
        email: value.email
      })
    } else {
      beneficiaryPersonField.onChange({
        name: value.name,
        email: value.email,
        md5: ''
      })
    }
  }

  const onRequesterChange = (event: SyntheticEvent, value: PersonOption) => {
    openDialog('updateBeneficiary')
    requesterField.onChange({
      name: value.name,
      email: value.email,
      md5: ''
    })
  }

  const onBeneficiaryCompanyChange = (event: SyntheticEvent, value: BeneficiaryCompanyValue) => {
    if ('label' in value) {
      openDialog('createCompanyOption')
      setCreatedCompanyOption({
        name: value.name,
        website: value.website
      })
    } else {
      beneficiaryCompanyField.onChange({
        name: value.name,
        website: value.website,
        md5: ''
      })
    }
  }

  const removeRequester = () => {
    requesterField.onChange({
      name: '',
      email: '',
      md5: ''
    })
  }

  const removeBeneficiaryPerson = () => {
    beneficiaryPersonField.onChange({
      name: '',
      email: '',
      md5: ''
    })
  }

  const removeBeneficiaryCompany = () => {
    beneficiaryCompanyField.onChange({
      name: '',
      website: '',
      md5: ''
    })
  }

  const onReasonChange = (e: SyntheticEvent, newValue: ReasonType) => {
    reasonField.onChange(newValue)
  }

  return (
    <>
      <Widget className={classes.wrapper}>
        <RequesterPicker loading={loading} value={requesterField.value} onChange={onRequesterChange} remove={removeRequester} />
        <Box>
          <FormLabel label="Beneficiary" />
          {beneficiaryType === 'company' && (
            <BeneficiaryCompanyPicker
              loading={loading}
              value={beneficiaryCompanyField.value}
              remove={removeBeneficiaryCompany}
              onChange={onBeneficiaryCompanyChange}
              setType={setBeneficiaryType}
              errorMessage={errors.beneficiaryPerson?.message || errors.beneficiaryCompany?.message}
            />
          )}
          {beneficiaryType === 'person' && (
            <BeneficiaryPersonPicker
              loading={loading}
              value={beneficiaryPersonField.value}
              remove={removeBeneficiaryPerson}
              onChange={onBeneficiaryPersonChange}
              setType={setBeneficiaryType}
              errorMessage={errors.beneficiaryPerson?.message || errors.beneficiaryCompany?.message}
            />
          )}
        </Box>
        <Box mb={1.5}>
          <ReasonPicker loading={loading} value={reasonField.value} onChange={onReasonChange} errorMessage={errors.reason?.message} />
        </Box>
        <Box mb={1.5}>
          <TextField
            {...register('summary', {
              required: errorMsg,
              pattern: {
                value: /\S/,
                message: errorMsg
              }
            })}
            disabled={loading}
            label="Headline"
            multiline
            rows={2}
            fullWidth
            placeholder="e.g. Priority AmLAW sales outreach"
            errorMessage={errors.summary?.message}
          />
        </Box>
        <Box mb={1.5}>
          <TextField {...register('description')} disabled={loading} label="Description (optional)" id="Description" multiline rows={3} fullWidth />
        </Box>
      </Widget>
      <CreatePersonOptionDialog
        opened={openedDialog == 'createPersonOption'}
        close={closeCreatePersonOptionDialog}
        submit={createPersonOptionSubmit}
        value={createdPersonOption}
      />
      <CreateCompanyOptionDialog
        opened={openedDialog == 'createCompanyOption'}
        close={closeCreateCompanyOptionDialog}
        submit={createCompanyOptionSubmit}
        value={createdCompanyOption}
      />
      <UpdateBeneficiaryDialog
        beneficiary={beneficiary}
        loading={fetching}
        opened={openedDialog == 'updateBeneficiary'}
        close={closeUpdateBeneficiaryDialog}
        submit={handleUpdateBeneficiarySubmit}
        value={beneficiaryUpdateAction}
        setDialogValue={setBeneficiaryUpdateAction}
      />
      <Box className={classes.actionButtons}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePL>
          Close
        </Button>
        <Button onClick={handleSubmit(save)} disabled={!!Object.keys(errors).length || loading} variant="text" disablePR>
          {props.actionLabel || 'Save'}
        </Button>
      </Box>
    </>
  )
}

const RequesterPicker = ({
  loading,
  value,
  errorMessage,
  onChange,
  remove
}: {
  value: PersonOption
  loading: boolean
  onChange: (event: SyntheticEvent, value: PersonOption) => void
  remove: () => void
  errorMessage?: string
}) => {
  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()
  const { classes } = useStyles()

  const { name, email } = value
  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<PersonOption>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpActiveDirectoryMembers(searchTerm)
        if (result) {
          return result.users.map((user) => ({
            name: user.displayName,
            email: user.emailAddress
          }))
        }
      },
      [lookUpActiveDirectoryMembers]
    ),
    forceAbort
  })

  return (
    <Box>
      <FormLabel label="Requester" />
      {(name || loading) && (
        <ProfileItem
          name={name || ''}
          userKey={email}
          byline={email}
          score=""
          className={classes.profileItem}
          icons={
            <IconButton
              disabled={loading}
              color="primary"
              hint="Change contributor"
              icon={['far', 'times']}
              onClick={remove}
              size="small"
              disablePR
            />
          }
        />
      )}
      {!name && !loading && (
        <Box mb={1.5}>
          <Combobox<PersonOption, false, true>
            placeholder="Search for requester"
            icon={['far', 'search']}
            value={value}
            options={options}
            open={open}
            loading={optionsLoading}
            inputValue={inputValue}
            onChange={onChange}
            renderOption={renderPersonOption}
            disabled={loading}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            onClose={handleComboboxClose}
            onFocus={handleFocus}
            getOptionLabel={(option) => option.name}
            errorMessage={errorMessage}
            disableClearable
          />
        </Box>
      )}
    </Box>
  )
}

const ReasonPicker = ({
  value,
  onChange,
  loading,
  errorMessage
}: {
  value: ReasonType
  loading: boolean
  onChange: (e: SyntheticEvent, newValue: ReasonType) => void
  errorMessage?: string
}) => {
  const { fetchWithAbort, forceAbort } = useAbortableFetch<ReasonType[]>()

  const lookUpReasons = useCallback(async () => {
    const result = await fetchWithAbort({ url: '/prospecting/reasons' })
    return result
  }, [fetchWithAbort])

  const loadOnFocus = true

  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<ReasonType>({
    loadOnFocus,
    loadOptions: useCallback(async () => {
      const result = await lookUpReasons()
      if (result) {
        return result
      }
    }, [lookUpReasons]),
    forceAbort
  })

  return (
    <Combobox<ReasonType, false, true>
      label="Category"
      forcePopupIcon={loadOnFocus}
      placeholder="Search for category"
      icon={['far', 'search']}
      value={value}
      options={options}
      open={open}
      loading={optionsLoading}
      inputValue={inputValue}
      onChange={onChange}
      disabled={loading}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      onClose={handleComboboxClose}
      onFocus={handleFocus}
      errorMessage={errorMessage}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      disableClearable
      blurOnSelect
    />
  )
}

const BeneficiaryTypeSwitcher = ({ type, setType }: { type: BeneficiaryType; setType: (val: BeneficiaryType) => void }) => {
  const { classes } = useStyles()

  return (
    <ButtonGroup classes={{ root: classes.group }} color="primary" size="small" disableElevation disableRipple>
      {beneficiaryTypeOptions.map((option) => (
        <Button key={option.label} variant={option.value === type ? 'contained' : 'outlined'} onClick={() => setType(option.value)}>
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}

const BeneficiaryPersonPicker = ({
  loading,
  value,
  onChange,
  remove,
  errorMessage,
  setType
}: BeneficiaryPickerProps & { value: PersonOptionType; onChange: (event: SyntheticEvent, value: BeneficiaryPersonValue) => void }) => {
  const { lookUpMarketDataPeople, forceAbort } = useLookUpMarketDataPeople()
  const filterOptions = (options: PersonOptionType[]) => options.filter((option) => option.name && option.email)

  const { classes } = useStyles()

  const { name, email } = value
  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<PersonOptionType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpMarketDataPeople(searchTerm)
        if (result) {
          return result.map((person) => ({
            name: person.full_name,
            email: person.work_email,
            jobTitle: person.job_title,
            company: person.job_company_name
          }))
        }
      },
      [lookUpMarketDataPeople]
    ),
    forceAbort
  })

  const filterWithSuggest = useSuggestOptionCreation<PersonOptionType, SuggestPersonOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformPersonOption
  })

  return (
    <>
      {name && (
        <ProfileItem
          name={name}
          userKey={email}
          byline={email}
          score=""
          className={classes.profileItem}
          icons={
            <IconButton
              disabled={loading}
              color="primary"
              hint="Change beneficiary"
              icon={['far', 'times']}
              onClick={remove}
              size="small"
              disablePR
            />
          }
        />
      )}

      {!name && !loading && (
        <>
          <BeneficiaryTypeSwitcher type="person" setType={setType} />
          <Box mb={1.5}>
            <Combobox<PersonOptionType, false, true>
              placeholder="Search for person"
              icon={['far', 'search']}
              value={value}
              options={options}
              open={open}
              loading={optionsLoading}
              inputValue={inputValue}
              onChange={onChange}
              renderOption={renderSuggestPersonOption}
              filterOptions={filterWithSuggest}
              disabled={loading}
              onInputChange={handleInputChange}
              onOpen={handleOpen}
              onClose={handleComboboxClose}
              onFocus={handleFocus}
              getOptionLabel={(option) => option.name || ''}
              errorMessage={errorMessage}
              disableClearable
            />
          </Box>
        </>
      )}
    </>
  )
}

const BeneficiaryCompanyPicker = ({
  loading,
  value,
  onChange,
  remove,
  errorMessage,
  setType
}: BeneficiaryPickerProps & { value: CompanyOptionType; onChange: (event: SyntheticEvent, value: BeneficiaryCompanyValue) => void }) => {
  const { classes } = useStyles()

  const { lookUpMarketDataCompanies, forceAbort } = useLookUpMarketDataCompanies()

  const { name, website } = value
  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<CompanyOptionType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpMarketDataCompanies(searchTerm)
        if (result) {
          return result.map((company) => ({
            name: company.name,
            website: company.website
          }))
        }
      },
      [lookUpMarketDataCompanies]
    ),
    forceAbort
  })

  const filterOptions = (options: CompanyOptionType[]) => options.filter((option) => option.name && option.website)

  const filterWithSuggest = useSuggestOptionCreation<CompanyOptionType, SuggestCompanyOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformCompanyOption
  })

  return (
    <>
      {(name || loading) && (
        <ProfileItem
          name={name || ''}
          logoUrl={website}
          byline={website}
          score=""
          className={classes.profileItem}
          icons={
            <IconButton
              disabled={loading}
              color="primary"
              hint="Change beneficiary"
              icon={['far', 'times']}
              onClick={remove}
              size="small"
              disablePR
            />
          }
        />
      )}
      {!name && !loading && (
        <>
          <BeneficiaryTypeSwitcher type="company" setType={setType} />
          <Box mb={1.5}>
            <Combobox<CompanyOptionType, false, true>
              placeholder="Search for company"
              icon={['far', 'search']}
              value={value}
              options={options}
              open={open}
              loading={optionsLoading}
              inputValue={inputValue}
              onChange={onChange}
              renderOption={renderSuggestCompanyOption}
              filterOptions={filterWithSuggest}
              disabled={loading}
              onInputChange={handleInputChange}
              onOpen={handleOpen}
              onClose={handleComboboxClose}
              onFocus={handleFocus}
              getOptionLabel={(option) => option.name || ''}
              errorMessage={errorMessage}
              disableClearable
            />
          </Box>
        </>
      )}
    </>
  )
}

export default RequestForm
