import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Popover } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import { EditItemIdentifiers } from '_core/components/EditItem'
import Grid, { DataGrid, GridTypes } from '_core/components/grid'
import { actionButtonColumn, scoreColumn } from '_core/components/grid/columns'
import Heading from '_core/components/Heading'
import { Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import SearchInput from '_core/components/SearchInput'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { get, mergeUrlWithParams } from 'utils/httpUtils'

interface IPerson {
  id: string
  score: string
  identifiers?: {
    main: string
    other: any[]
  }
  isPrivateRelations: boolean
  actionButton: JSX.Element
}

type PrivateListProps = {
  items: { [key: string]: any }[]
  loading: boolean
  selected: string
  total: number
  updateSort: (val: ScoreType) => void
  setPageSize: GridTypes['setPageSize']
  sort?: ScoreType
  paging: GridTypes['paging']
  forceNarrow?: boolean
  enableDownloading: boolean | null
  setReload: Dispatch<SetStateAction<boolean>>
}

const scoreCentredColumn: GridColDef = {
  ...scoreColumn,
  align: 'center',
  headerAlign: 'center'
}

const IdentifierCell = React.memo((params: GridRenderCellParams) => {
  const others = params.row.identifiers?.other || []
  return (
    <Box>
      <Typography variant="h4" style={{ maxWidth: params.colDef.width || 300 - 16 }} ellipsis>
        {params.row.identifiers?.main}
      </Typography>
      <Box style={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
        {!!others.length &&
          others.slice(0, 1).map((name: any) => (
            <Typography color="text.secondary" ellipsis key={name}>
              {name}
            </Typography>
          ))}
        {others.length > 1 && <OtherIdentifiers items={others.slice(1)} />}
      </Box>
    </Box>
  )
})

const identifierColumn: GridColDef = {
  field: 'identifier',
  headerName: 'Person',
  width: 300,
  minWidth: 150,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <IdentifierCell {...params} />
}

const useStyles = makeStyles()(() => ({
  widget: {
    position: 'relative',
    display: 'flex',
    minHeight: 'calc(100vh - 275px)'
  }
}))

const PrivateList = (props: PrivateListProps) => {
  const [list, setList] = useState<IPerson[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const { updateQuery } = useSearchQuery()
  const {
    isDialogOpened,
    dialogContentProps: openedDialog,
    openDialog,
    hideDialog,
    successMode,
    openSuccess
  } = useDialog<{ userKeys: string[]; userName?: string }>({
    userKeys: []
  })

  const transformData = (data: any) => {
    if (data.length) {
      const names: string[] = []
      const fullNames: string[] = []
      const emails: string[] = []

      data.forEach((el: any) => {
        if (el.Type === 'PersonTwoPartName') names.push(el.Text)
        if (el.Type === 'PersonCompleteName') fullNames.push(el.Text)
        if (el.Type === 'EmailAddress') emails.push(el.Text)
      })

      if (names[0]) {
        const [, ...rest] = names
        return {
          main: names[0],
          other: [...emails, ...rest, ...fullNames]
        }
      }

      if (!names[0] && fullNames[0]) {
        const [, ...rest] = fullNames
        return {
          main: fullNames[0],
          other: [...emails, ...rest]
        }
      }

      if (!names[0] && !fullNames[0] && emails[0]) {
        const [, ...rest] = emails
        return {
          main: emails[0],
          other: [...rest]
        }
      }
    }
  }

  const wide = useWide()

  const removePrivate = () => setList((prevState: any) => prevState.filter((person: any) => person.id !== openedDialog?.userKeys[0]))

  useEffect(() => {
    setList(
      props.items?.map((person: any) => {
        const data = transformData(person.Identifiers[0].Aliases)
        return {
          id: person.ContactMd5,
          score: person?.ScorePoints || '-',
          identifiers: data,
          isPrivateRelations: person?.MarkedPrivate,
          actionButton: wide ? (
            <MarkPublicDialog.TriggerButton onClick={() => openDialog({ userKeys: [person.ContactMd5], userName: data?.main })} />
          ) : (
            <MarkPublicDialog.TriggerIcon
              onClick={() => openDialog({ userKeys: [person.ContactMd5], userName: data?.main })}
              hint={`Mark relationship with ${data?.main} as public`}
              size="small"
            />
          )
        }
      })
    )
  }, [props.items, wide])

  const updateSort = (model: GridSortModel) => {
    if (model?.length) {
      const sort = model[0].sort === 'asc' ? 'ScoreAsc' : 'ScoreDesc'
      updateQuery({ sort })
    }
  }

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const MarkSelectedAsPublic = (
    <MarkPublicDialog.TriggerIcon
      onClick={() => openDialog({ userKeys: selected })}
      hint="Mark selected as shared"
      disabled={!selected.length || !list.length}
      style={{ fontSize: 18, maxWidth: 42 }}
    />
  )

  const allowedForAll = [MarkSelectedAsPublic]
  const allowedIfDownloadingEnabled: any[] = []
  const controls = props.enableDownloading ? [...allowedIfDownloadingEnabled, ...allowedForAll] : allowedForAll

  return (
    <>
      <Wide>
        <DataGrid
          rows={list}
          rowHeight={62}
          columns={[identifierColumn, scoreCentredColumn, actionButtonColumn]}
          controls={typeof props.enableDownloading === 'boolean' ? controls : []}
          setSortModel={updateSort}
          onSelect={handleSelect}
          loading={props.loading}
          setPageSize={props.setPageSize}
          checkboxSelection={!!controls.length}
          paging={props.paging}
          total={props.total}
        />
      </Wide>
      <Narrow>
        <Widget>
          <Box marginTop={-2} marginBottom={-1}>
            <Repeater
              direction="vertical"
              variant="homepage"
              component={EditItemIdentifiers}
              skeleton={{ size: 10, loading: props.loading }}
              items={list}
              empty="No private relationships found"
            />
          </Box>
        </Widget>
      </Narrow>
      <MarkPublicDialog
        success={successMode}
        openSuccess={openSuccess}
        opened={isDialogOpened}
        close={hideDialog}
        {...openedDialog}
        {...(wide ? { handleRefresh: props.setReload } : { update: removePrivate })}
      />
    </>
  )
}

const PrivateRelationshipsList = () => {
  const [userKey, setUserKey] = useState<string>()
  const [reload, setReload] = useState<boolean>(false)
  const { queryParams } = useSearchQuery<GridParams>()
  const { sort, keyword } = queryParams
  const enableDownloading = useEnableDownloading()
  const { classes } = useStyles()

  useEffect(() => {
    get<ProfileType>('/me/profile').then((resp) => {
      if (resp) {
        setUserKey(resp.UserKey)
      }
    })
  }, [])

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const url =
    userKey && !reload
      ? mergeUrlWithParams('/privacy', { contributorKey: userKey, scope: 'ContributorMeOnlyComposite', SortBy: sort || 'ScoreDesc' })
      : null

  const List = (
    <DynamicEntity<{ extraProps: { addprops: Pick<PrivateListProps, 'enableDownloading' | 'setReload'> } }>
      url={url}
      addprops={{ enableDownloading, setReload }}
      component={PrivateList}
      list
      infinite
      keepMounted
      search
      empty="No private relationships found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="private_relationships"
    />
  )

  return (
    <Page>
      <Wide>
        <Widget scope="list" className={classes.widget}>
          <Grid>
            <Grid.Heading title="Private relationships">
              <Box display="flex" alignItems="center">
                <Box mx={2}>
                  <SearchInput placeholder="Search for people" variant="collapsed" opened />
                </Box>
              </Box>
            </Grid.Heading>
            {List}
          </Grid>
        </Widget>
      </Wide>
      <Narrow>{List}</Narrow>
    </Page>
  )
}

export default PrivateRelationshipsList

export const OtherIdentifiers = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box position="relative" width="40px" height="100%">
      <Tooltip title="See more identifiers">
        <IconButton
          onClick={handleClick}
          aria-describedby={id}
          color="primary"
          style={{ marginLeft: '8px', padding: '8px', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
        >
          <FontAwesomeIcon icon={['far', 'chevron-down']} style={{ fontSize: '14px' }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box p={2}>
          <Heading title="Additional person identifiers" icon={['far', 'address-card']} count={props.items.length} />
          <Widget scope="list">
            {props.items?.map((name: any) => (
              <Typography key={name} color="text.secondary" ellipsis>
                {name}
              </Typography>
            ))}
          </Widget>
        </Box>
      </Popover>
    </Box>
  )
}
