import { ReactNode } from 'react'

import { Box } from '@mui/material'

import { Button } from '_shared/buttons'

import SidepanelLink from '_core/components/SidepanelLink'
import Success from '_core/components/Success'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

export const AddTagsSuccessLayout = ({ children }: { children: ReactNode }) => {
  return <Success text="The tags were added successfully!">{children}</Success>
}
export const UpdateTagsSuccessLayout = ({ children }: { children: ReactNode }) => {
  return <Success text="The tags were updated successfully!">{children}</Success>
}
export const RemoveTagsSuccessLayout = ({ children }: { children: ReactNode }) => {
  return <Success text="The tags were removed successfully!">{children}</Success>
}

const TagsSuccessBody = ({ showTagsLink, closeLink }: { showTagsLink?: string; closeLink: string }) => {
  const handleClose = useSidepanelClose(closeLink)
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      {showTagsLink && (
        <SidepanelLink linkProps={{ to: showTagsLink }} sidepanel>
          <Button variant="text" color="primary">
            Show tags
          </Button>
        </SidepanelLink>
      )}
      <Button variant="text" onClick={handleClose} color="secondary">
        Close
      </Button>
    </Box>
  )
}

export default TagsSuccessBody
