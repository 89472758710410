import { useEffect, useState, useMemo, useContext } from 'react'

import { Box } from '@mui/material'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import { EmailSettings, DemoSettings, GeneralSettings } from '_core/components/settings/user'
import SettingsAccordion from '_core/components/SettingsAccordion'
import Widget from '_core/components/Widget'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'
import TabSet from 'SharedComponents/TabSet'

export const UserSettings = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setMobileHeader('Settings')
  }, [setMobileHeader])

  const handleTabChange = (newTab: number) => {
    setTabIndex(newTab)
  }

  // prettier-ignore
  const generalUrls = useMemo( () => [
    { url: '/usersettings/homepageoptions', key: 'options' },
    { url: '/usersettings/general', key: 'homePage', merge: true, single: true }
  ], [])

  const init = [
    {
      id: 'general',
      label: 'GENERAL',
      component: () => <DynamicEntity urls={generalUrls} component={GeneralSettings} id="general_settings" />
    },
    {
      id: 'email',
      label: 'EMAIL',
      component: () => (
        <DynamicEntity
          url={`/usersettings/email`}
          component={EmailSettings}
          empty="Email related settings not found"
          list={true}
          id="email_settings"
        />
      )
    },
    {
      id: 'demo',
      label: 'DEMO',
      component: () => <DemoSettings />
    }
  ]

  const tabs = window.localStorage.getItem('demo_mode') === 'true' ? init : init.filter(({ label }: { label: string }) => label !== 'DEMO')

  return (
    <Page>
      <Widget scope="none">
        <Wide>
          <Box mx={5} my={3} minHeight={'calc(100vh - 215px)'}>
            <Typography variant="h1" style={{ marginBottom: 16 }}>
              Settings
            </Typography>
            <TabSet tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={handleTabChange} />
          </Box>
        </Wide>
        <Narrow>
          <SettingsAccordion tabs={tabs} />
        </Narrow>
      </Widget>
    </Page>
  )
}
