import React, { PropsWithChildren } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip as MUIChip, ChipProps } from '@mui/material'
import { GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

import CollapseContainer from '../CollapseContainer'

const useStyles = makeStyles()((theme) => ({
  button: {
    borderRadius: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    borderBottom: `1px solid #f2f2f2`,
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 12
  },
  btnLabel: {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16
  },
  endIcon: {
    width: 13,
    height: 17,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: `-${theme.spacing(1)}`
  },
  chip: {
    display: 'flex',
    flexDirection: 'row-reverse',
    borderRadius: 20,
    height: 38,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main
    },
    '& .MuiChip-icon': {
      color: theme.palette.background.light,
      marginLeft: `-${theme.spacing(1)}`,
      marginRight: theme.spacing(2)
    }
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.light
    },
    '& .MuiChip-icon': {
      marginLeft: 0,
      marginRight: 0
    }
  },
  chipIcon: {
    fontSize: theme.spacing(1.75),
    transition: 'all 0.2s'
  },
  chipLabel: {
    fontSize: 14,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  active: {
    visibility: 'visible',
    width: theme.spacing(1.75)
  },
  inactive: {
    visibility: 'hidden',
    width: 0,
    margin: 0
  }
}))

interface ISortProps {
  collapsed: boolean
  className?: string
}

interface ISortIconProps {
  collapsed: boolean
  toggleCollapsed: () => void
  disabled?: boolean
  className?: string
  variant?: 'text' | 'icon'
}

export const Controller = ({ collapsed, toggleCollapsed, className, disabled, variant = 'icon' }: ISortIconProps) => {
  const { classes, cx } = useStyles()

  if (variant === 'icon') {
    return (
      <IconButton
        disableRipple
        color={collapsed ? 'secondary' : 'primary'}
        hint={collapsed ? 'Show sorting' : 'Hide sorting'}
        size="small"
        onClick={toggleCollapsed}
        icon={['far', 'bars-filter']}
        className={className}
        disabled={disabled}
      />
    )
  }

  return (
    <Button
      variant="text"
      disableRipple
      disabled={disabled}
      classes={{ root: cx(classes.button, classes.btnLabel), endIcon: classes.endIcon }}
      fullWidth
      onClick={toggleCollapsed}
      color={collapsed ? 'secondary' : 'primary'}
      endIcon={
        <FontAwesomeIcon icon={['far', collapsed ? 'bars-filter' : 'times']} style={{ width: 14, height: collapsed ? 14 : 17, color: 'inherit' }} />
      }
    >
      <Typography variant="h4" semiBold>
        Sorting
      </Typography>
    </Button>
  )
}

export type SortProps = PropsWithChildren<ISortProps>

const Chip = ({ active, desc, ...props }: { active: boolean; desc: boolean } & ChipProps) => {
  const { classes, cx } = useStyles()
  return (
    <MUIChip
      {...props}
      color={active ? 'primary' : 'secondary'}
      size="medium"
      classes={{
        root: classes.chip,
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
        label: classes.chipLabel
      }}
      icon={
        <FontAwesomeIcon
          icon={desc ? ['far', 'arrow-down'] : ['far', 'arrow-up']}
          className={cx(classes.chipIcon, {
            [classes.active]: active,
            [classes.inactive]: !active
          })}
        />
      }
    />
  )
}

const Sort = ({ collapsed, children, className }: SortProps) => {
  return (
    <CollapseContainer collapsed={collapsed} className={className} title="Sort">
      <Box display="flex" flexWrap="wrap" mt={1.5} mb={-0.5}>
        {children}
      </Box>
    </CollapseContainer>
  )
}

Sort.Chip = Chip
export default Sort

export const sortMap: {
  [key in GridSortItem['field']]: {
    asc: StatSortType | ScoreType
    desc: StatSortType | ScoreType
    excludeEmpty: boolean
  }
} = {
  score: {
    asc: 'ScoreAsc',
    desc: 'ScoreDesc',
    excludeEmpty: false
  },
  lastInboundMsg: {
    asc: 'LastInboundAsc',
    desc: 'LastInboundDesc',
    excludeEmpty: false
  },
  lastOutboundMsg: {
    asc: 'LastOutboundAsc',
    desc: 'LastOutboundDesc',
    excludeEmpty: false
  },
  lastMeeting: {
    asc: 'LastMeetingAsc',
    desc: 'LastMeetingDesc',
    excludeEmpty: false
  },
  nextFutureMeeting: {
    asc: 'NextFutureAsc',
    desc: 'NextFutureDesc',
    excludeEmpty: true
  }
}

export const statsOptions: StatSortType[] = [
  'LastInboundDesc',
  'LastInboundAsc',
  'LastOutboundDesc',
  'LastOutboundAsc',
  'LastMeetingDesc',
  'LastMeetingAsc',
  'NextFutureAsc',
  'NextFutureDesc'
]

const { lastInboundMsg, lastOutboundMsg, lastMeeting, nextFutureMeeting, score } = sortMap

export const getSortModels = (sort: any): GridSortModel => [
  { field: 'lastInboundMsg', sort: sort === lastInboundMsg.asc ? 'asc' : 'desc' },
  { field: 'lastOutboundMsg', sort: sort === lastOutboundMsg.asc ? 'asc' : 'desc' },
  { field: 'lastMeeting', sort: sort === lastMeeting.asc ? 'asc' : 'desc' },
  { field: 'nextFutureMeeting', sort: sort === nextFutureMeeting.asc ? 'asc' : 'desc' },
  { field: 'score', sort: sort === score.asc ? 'asc' : 'desc' }
]
