import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '2px',
    [theme.breakpoints.up('md')]: {
      padding: '30px 40px'
    }
  }
}))

const DotCard = (props: { children: React.ReactNode }) => {
  const { classes } = useStyles()

  return <Box className={classes.root}>{props.children}</Box>
}

export default DotCard
