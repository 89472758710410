import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.darker,
    borderRadius: theme.spacing(0.5)
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  }
}))

const TriggerEl = ({ handleClick }: { handleClick: () => void }) => (
  <Button variant="contained" onClick={handleClick} startIcon={<FontAwesomeIcon icon={['fas', 'play']} style={{ fontSize: 14 }} />}>
    watch video
  </Button>
)

const Video = ({ link }: { link: string }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      <iframe src={link} className={classes.iframe} allow="autoplay; fullscreen; picture-in-picture; clipboard-write" allowFullScreen></iframe>
    </Box>
  )
}

Video.TriggerEl = TriggerEl

export default Video
