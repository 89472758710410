import { CSSProperties } from 'react'

import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import parsePhoneNumber from 'libphonenumber-js'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import CopyButton from '_core/components/CopyButton'
import InformAboutPhoneNumberDialog from '_core/components/dialogs/InformAboutPhoneNumber'
import SidepanelLink from '_core/components/SidepanelLink'

import useDialog from '_core/hooks/useDialog'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center'
    }
  },
  label: {
    marginLeft: '-9px',
    marginRight: 0
  },
  icon: {
    fontSize: 13
  }
}))

interface IPersonInfo {
  name: string
  id: string
}

interface IPhoneNumber {
  phoneType: PhoneType
  number: any
  optionsIcon?: boolean
  person?: IPersonInfo
  style?: CSSProperties
}

export const phoneTypeIcon = (type: PhoneType): [IconPrefix, IconName] => {
  switch (type) {
    case 'Work':
      return ['far', 'phone-office']
    case 'Mobile':
      return ['far', 'mobile-alt']
    case 'Fax':
      return ['fas', 'fax']
    default:
      return ['far', 'phone']
  }
}

const PhoneNumber = ({ phoneType, number, person, optionsIcon = true, style }: IPhoneNumber) => {
  const { isDialogOpened, openDialog, closeDialog, successMode, openSuccess } = useDialog()

  const {
    classes: { iconWrapper, icon }
  } = useStyles()
  const { id } = useParams<{ id: string }>()

  const phoneNumber = number && parsePhoneNumber(number)?.formatInternational()

  return (
    <>
      <Skeleton condition={!number} width="100%" height={20}>
        <Typography variant="body1" component="div" className={iconWrapper} style={style}>
          <Box>
            <a href={`tel:${number}`}>
              <FontAwesomeIcon icon={phoneTypeIcon(phoneType)} size="sm" color="#1B95BB" style={{ marginRight: '8px', width: '14px' }} />
              {phoneNumber}
            </a>
          </Box>
          <Box>
            <SidepanelLink linkProps={{ to: `${Paths._people}/${id}/phoneSources/${number}` }}>
              <IconButton icon={['far', 'list-tree']} classes={{ root: icon }} hint="Sources" size="small" disablePY disablePL />
            </SidepanelLink>

            <CopyButton text={number} disablePY />

            {optionsIcon && <InformAboutPhoneNumberDialog.TriggerEl open={openDialog} />}
          </Box>
        </Typography>
      </Skeleton>

      <InformAboutPhoneNumberDialog
        isOpened={isDialogOpened}
        phoneNumber={phoneNumber}
        close={closeDialog}
        successMode={successMode}
        personName={person?.name || ''}
        personId={person?.id || ''}
        openSuccess={openSuccess}
      />
    </>
  )
}

export default PhoneNumber
