import { useState, ChangeEvent } from 'react'

import { Box } from '@mui/material'

import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'
import TextField from '_shared/forms/TextField'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const EmailAlearts = (props: any) => {
  const {
    classes: { subsection, field, itemWrapper },
    cx
  } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })
  const [settingsData, setSettingsData] = useState<any>(props.items)

  const { isSaving, save: saveSettings } = useSaveSettings()

  const handleadminEmailSettingsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      sendEmailAlerts: e.target.checked
    })
  }

  const handleAdminMembershipAlertsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      teamMembershipAlerts: e.target.checked
    })
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      senderEmailAddress: e.target.value
    })
  }

  const handleDelayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      teamMembershipAlertDelayDays: e.target.value
    })
  }

  const save = (action: SaveAction) => saveSettings('/adminsettings/email', settingsData, action, 'Email alert settings')

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      {settingsData && (
        <>
          <Checkbox
            disabled={isSaving}
            checked={settingsData.sendEmailAlerts}
            onChange={handleadminEmailSettingsChange}
            name="adminEmailSettings"
            label="Enable email alerts"
          />
          <Box className={subsection}>
            Use
            <TextField
              size="small"
              disabled={isSaving}
              onChange={handleEmailChange}
              defaultValue={settingsData.senderEmailAddress}
              classes={{ root: field }}
            />
            to send email
          </Box>
          <Checkbox
            disabled={isSaving}
            checked={settingsData.teamMembershipAlerts}
            onChange={handleAdminMembershipAlertsChange}
            name="adminMembershipDigest"
            label="Send team membership alerts"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
          />
          <Box className={subsection}>
            Delay team membership notifications by
            <TextField
              size="small"
              disabled={isSaving}
              style={{ width: 30, textAlign: 'center' }}
              onChange={handleDelayChange}
              defaultValue={settingsData.teamMembershipAlertDelayDays}
              classes={{ root: field }}
            />
            days
          </Box>
        </>
      )}
    </Settings>
  )
}

export default EmailAlearts
